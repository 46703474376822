import { Fragment, useEffect, useReducer } from "react";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import MyHospitalsSelector, {
  ALL_HOSPITALS,
  MY_HOSPITALS,
} from "./MyHospitalsSelector";
import {
  canShowHospitalSelector,
  getGraphColours,
  getReportableStudies,
} from "./Utils";
import PropTypes from "prop-types";
import Responsive from "react-responsive";
import ReportingCheckbox from "./ReportingCheckbox";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import MyStudiesSelector, { ALL_STUDIES } from "../common/MyStudiesSelector";
import SurgeonSelector, { ALL_SURGEONS } from "../common/SurgeonSelector";
import SimpleProcedureTypeSelector, {
  ALL_PROCEDURE_TYPES,
} from "../common/SimpleProcedureTypeSelector";
import GenericSelector from "../common/GenericSelector";
import { isEmptyOrNull } from "../../Utils";
import {
  loadHospitals,
  loadProcedureTypes,
  loadStudies,
} from "../../actions/CommonActions";
import DatePicker from "react-datepicker";
import { useOnUpdate } from "../CustomHooks";
import BarChart from "./BarChart";

const BarReport = ({
  mapColours = (series, index) => getGraphColours()[index],
  isCumulativeByDefault = false,
  useStudySelector = true,
  useProcedureTypeSelector = false,
  useStudyCollectionSelector = false,
  subtitle = null,
  fetchData,
  id,
  legendHovers,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const hospitals = useSelector((state) => state.hospitals);
  const studies = useSelector((state) => state.studies);
  const procedureTypes = useSelector((state) => state.procedureTypes);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      startDate: Moment().startOf("month").subtract(1, "years").format(),
      endDate: Moment().format(),
      dataset: { data: [] },
      rawData: [],
      hospital: ALL_HOSPITALS,
      procedureType: ALL_PROCEDURE_TYPES,
      procedureTypeIdFilter: [],
      studyIdFilter: [],
      isCumulative: isCumulativeByDefault,
      study: ALL_STUDIES,
      studyCollection: null,
      surgeon: ALL_SURGEONS,
      isLoading: false,
    },
  );

  useEffect(() => {
    hospitals.length === 0 && dispatch(loadHospitals());
    studies.length === 0 && dispatch(loadStudies());
    procedureTypes.length === 0 && dispatch(loadProcedureTypes());
  }, [dispatch]);

  useEffect(() => {
    if (useStudyCollectionSelector) {
      // If we're using a study collection selector, we can't default to All Studies and have to pick an initial collection
      const filteredStudies = applyStudyFilter(
        getReportableStudies(studies, user),
      );
      if (filteredStudies.length > 0) {
        setState({ studyCollection: filteredStudies[0].studyCollections[0] });
        studyChanged(filteredStudies[0]);
      }
    } else {
      updateData();
    }
  }, []);

  useOnUpdate(() => {
    updateData();
  }, [
    state.surgeon,
    state.study,
    state.hospital,
    state.procedureType,
    state.studyCollection,
    state.startDate,
    state.endDate,
    state.isCumulative,
  ]);

  useOnUpdate(() => {
    studies.length > 0 &&
      setState({
        study: studies[0],
        studyCollection: studies[0].studyCollections[0],
      });
  }, [studies]);

  const userHasHospitalStudiesWithMinimumAge = () => {
    return (
      hospitals.length > 0 &&
      hospitals.some((h) => h.studiesWithMinimumAge != null)
    );
  };

  const hospitalChanged = (hospital) => {
    // Exactly one hospital selected //
    if (hospital !== ALL_HOSPITALS && hospital !== MY_HOSPITALS) {
      let newProcedureTypeIdFilter = procedureTypeFilterFromStudies(
        hospital.studies,
      );
      let newProcedureType =
        state.procedureType != null &&
        newProcedureTypeIdFilter.includes(state.procedureType.id)
          ? state.procedureType
          : ALL_PROCEDURE_TYPES;
      if (newProcedureTypeIdFilter.length === 1) {
        newProcedureType = procedureTypes.find(
          (pt) => pt.id === newProcedureTypeIdFilter[0],
        );
      }
      let newStudy = state.study;
      let newStudyCollection = state.studyCollection;
      let newStudyIdFilter = [];

      let hospitalStudies = hospital.studies;
      // We have to cope with two different kinds of hospital DTO
      if (userHasHospitalStudiesWithMinimumAge()) {
        hospitalStudies = hospital.studiesWithMinimumAge;
      }

      if (hospitalStudies) {
        if (hospitalStudies.length === 1) {
          newStudy = studies.find((s) => s.id === hospitalStudies[0].studyId);
          newStudyCollection = newStudy.studyCollections[0];
        } else {
          // if the selected study is not a user-linked study set newStudy to allStudies //
          if (!studies.find((s) => !!state.study && s.id === state.study.id)) {
            newStudy = ALL_STUDIES;
          }
        }
        if (hospitalStudies.length > 0) {
          newStudyIdFilter = studies
            .filter((s) =>
              hospitalStudies.map((s1) => s1.studyId).includes(s.id),
            )
            .map((s2) => s2.id);
        }
      }

      setState({
        hospital: hospital,
        procedureTypeIdFilter: newProcedureTypeIdFilter.filter((n) => n),
        procedureType: newProcedureType,
        studyIdFilter: newStudyIdFilter.filter((n) => n),
        study: newStudy,
        studyCollection: newStudyCollection,
      });
    } else {
      setState({
        hospital: hospital,
        studyIdFilter: [],
        procedureTypeIdFilter: [],
      });
    }
  };

  const studyChanged = (study) => {
    // Exactly one study selected //
    if (study !== ALL_STUDIES) {
      let newProcedureTypeIdFilter = procedureTypeFilterFromStudy(study.id);
      let newProcedureType;
      if (newProcedureTypeIdFilter.length === 1) {
        newProcedureType = procedureTypes.find(
          (pt) => pt.id === newProcedureTypeIdFilter[0],
        );
      } else {
        // If the currently selected procedureType is not in the newProcedureTypeIdFilter, set to all procedure types
        if (!newProcedureTypeIdFilter.includes(state.procedureType.id)) {
          newProcedureType = ALL_PROCEDURE_TYPES;
        } else {
          newProcedureType = state.procedureType;
        }
      }
      setState({
        study: study,
        studyCollection: study.studyCollections[0],
        procedureType: newProcedureType,
        procedureTypeIdFilter: newProcedureTypeIdFilter.filter((n) => n)
          ? newProcedureTypeIdFilter
          : [],
      });
    } else {
      setState({
        study: study,
        procedureTypeIdFilter: [],
      });
    }
  };

  const procedureTypeChanged = (procedureType) => {
    studies.map((s) => procedureTypeFilterFromStudy(s));

    // First just get a newStudyIdFilter based on the hospital
    let newStudyIdFilter = [];
    if (state.hospital !== ALL_HOSPITALS && state.hospital !== MY_HOSPITALS) {
      if (!!state.hospital.studies) {
        newStudyIdFilter = state.hospital.studies.map((hs) => hs.id);
      }
    } else {
      newStudyIdFilter = studies.map((s) => s.id);
    }
    if (procedureType !== ALL_PROCEDURE_TYPES) {
      newStudyIdFilter = newStudyIdFilter.filter((studyId) => {
        let ns = studies.find((s) => s.id === studyId);
        return !!ns && ns.procedureTypeIds.includes(procedureType.id);
      });
      let newStudy =
        !!state.study && newStudyIdFilter.includes(state.study.id)
          ? state.study
          : ALL_STUDIES;
      if (newStudyIdFilter.length === 1) {
        newStudy = studies.find((s) => s.id === newStudyIdFilter[0]);
      }
      setState({
        procedureType: procedureType,
        study: newStudy,
        studyIdFilter: newStudyIdFilter.filter((n) => n), // remove nulls/zeros
      });
    } else {
      setState({
        procedureType: procedureType,
        studyIdFilter: newStudyIdFilter.filter((n) => n),
      });
    }
  };

  const procedureTypeFilterFromStudy = (studyId) => {
    let found = studies.find((s) => s.id === studyId);
    if (found) {
      return found.procedureTypeIds;
    } else {
      return procedureTypes.map((pt) => pt.id);
    }
  };

  const procedureTypeFilterFromStudies = (studyIds) => {
    let intersection = new Set();
    if (studyIds) {
      studies
        .flatMap((s) => (studyIds.includes(s.id) ? s.procedureTypeIds : []))
        .forEach((pt) => intersection.add(pt));
    }
    return [...intersection];
  };

  const getHospitalIdFilter = () => {
    if (state.study == null || state.study.id === ALL_STUDIES.id) {
      return hospitals.map((h) => h.id);
    } else if (hospitals.length > 0) {
      return hospitals
        .filter((h) => {
          let hospitalStudies = h.studies;
          if (userHasHospitalStudiesWithMinimumAge()) {
            hospitalStudies = h.studiesWithMinimumAge;
          }
          return (
            hospitalStudies.length > 0 &&
            hospitalStudies.map((hs) => hs.studyId).includes(state.study.id)
          );
        })
        .map((h) => h.id);
    }
    return [];
  };

  const getStudySelection = () => {
    if (state.study == null || state.study.id === ALL_STUDIES.id) {
      return null;
    } else {
      return state.study.id;
    }
  };

  const getProcedureTypeSelection = () => {
    if (
      useProcedureTypeSelector &&
      !!procedureTypes &&
      procedureTypes.length > 0
    ) {
      if (
        state.procedureType == null ||
        state.procedureType.id === ALL_PROCEDURE_TYPES.id
      ) {
        if (
          !!state.procedureTypeIdFilter &&
          state.procedureTypeIdFilter.length > 0
        ) {
          return state.procedureTypeIdFilter.sort((a, b) => a - b).join();
        } else {
          return procedureTypes
            .map((pt) => pt.id)
            .sort((a, b) => a - b)
            .join();
        }
      } else {
        return state.procedureType.id;
      }
    } else {
      return null;
    }
  };

  const processData = (response) => {
    let result = {};
    result.title = response.data.title;
    result.xAxisLabel = response.data.xaxisLabel;
    result.yAxisLabel = state.isCumulative
      ? `Cumulative ${response.data.yaxisLabel.toLocaleLowerCase()}`
      : response.data.yaxisLabel;
    result.rawYAxisLabel = response.data.yaxisLabel;
    result.isMonthlyData = response.data.isMonthlyData;
    result.data = {};
    result.rawData = {};
    result.cumulativeData = {};
    result.legendHovers = legendHovers;
    result.seriesOrder = response.data.seriesOrder;
    result.seriesOrder.forEach((valueSet) => {
      const rawValues = [];
      const cumulativeValues = [];
      Object.keys(response.data.data[valueSet]).forEach((key, index) => {
        cumulativeValues.push({
          x: new Moment(key, "YYYY-MM-DD").valueOf(),
          y:
            index > 0
              ? cumulativeValues[cumulativeValues.length - 1].y +
                response.data.data[valueSet][key]
              : response.data.data[valueSet][key],
        });
        rawValues.push({
          x: new Moment(key, "YYYY-MM-DD").valueOf(),
          y: response.data.data[valueSet][key],
        });
      });
      // If only a single data point present - insert NULL values plus one day/month in order to get x-axis labels
      // displaying.
      if (rawValues.length === 1) {
        cumulativeValues.push({
          x: new Moment(cumulativeValues[0].x, "x")
            .add(1, result.isMonthlyData ? "months" : "days")
            .valueOf(),
          y: null,
        });
        rawValues.push({
          x: new Moment(rawValues[0].x, "x")
            .add(1, result.isMonthlyData ? "months" : "days")
            .valueOf(),
          y: null,
        });
      }

      result.rawData[valueSet] = rawValues;
      result.cumulativeData[valueSet] = cumulativeValues;
      if (state.isCumulative) {
        result.data[valueSet] = cumulativeValues;
      } else {
        result.data[valueSet] = rawValues;
      }
    });
    setState({ dataset: result });
  };

  const setCumulative = (isNowCumulative) => {
    const result = state.dataset;
    result.seriesOrder.forEach((valueSet) => {
      if (isNowCumulative) {
        result.data[valueSet] = result.cumulativeData[valueSet];
        result.yAxisLabel =
          "Cumulative " + result.rawYAxisLabel.toLocaleLowerCase();
      } else {
        result.data[valueSet] = result.rawData[valueSet];
        result.yAxisLabel = result.rawYAxisLabel;
      }
    });

    setState({ dataset: result, isCumulative: isNowCumulative });
  };

  const updateData = () => {
    setState({ isLoading: true });
    if (user.surgeonIds != null && user.surgeonIds.length > 0) {
      // Hospital administrator linked to studies
      let payloadSurgeonIds;
      if (state.surgeon != null && state.surgeon !== ALL_SURGEONS) {
        payloadSurgeonIds = state.surgeon.id;
      } else {
        payloadSurgeonIds = null;
      }
      fetchData({
        surgeonIds: payloadSurgeonIds,
        studyIds: getStudySelection() == null ? [] : getStudySelection(),
        procedureTypeIds: getProcedureTypeSelection(),
        hospitalIds: [],
        studyCollectionId:
          state.studyCollection == null ? null : state.studyCollection.id,
        startDate: state.startDate,
        endDate: state.endDate,
      })
        .then((response) => processData(response))
        .finally(() => setState({ isLoading: false }));
    } else if (state.hospital.id === ALL_HOSPITALS.id) {
      fetchData({
        studyIds: getStudySelection() == null ? [] : getStudySelection(),
        procedureTypeIds: getProcedureTypeSelection(),
        hospitalIds: [],
        studyCollectionId:
          state.studyCollection == null ? null : state.studyCollection.id,
        startDate: state.startDate,
        endDate: state.endDate,
      })
        .then((response) => processData(response))
        .finally(() => setState({ isLoading: false }));
    } else if (state.hospital.id === MY_HOSPITALS.id) {
      const payload = {
        studyIds: getStudySelection() == null ? [] : getStudySelection(),
        hospitalIds: hospitals.map((h) => h.id).join(),
        procedureTypeIds: getProcedureTypeSelection(),
        studyCollectionId:
          state.studyCollection == null ? null : state.studyCollection.id,
        startDate: state.startDate,
        endDate: state.endDate,
      };
      fetchData(payload)
        .then((response) => processData(response))
        .finally(() => setState({ isLoading: false }));
    } else {
      const payload = {
        studyIds: getStudySelection() == null ? [] : getStudySelection(),
        procedureTypeIds: getProcedureTypeSelection(),
        hospitalIds: [state.hospital.id].join(),
        studyCollectionId:
          state.studyCollection == null ? null : state.studyCollection.id,
        startDate: state.startDate,
        endDate: state.endDate,
      };
      fetchData(payload)
        .then((response) => processData(response))
        .finally(() => setState({ isLoading: false }));
    }
  };

  const applyProcedureTypeFilter = () => {
    if (
      !!state.procedureTypeIdFilter &&
      state.procedureTypeIdFilter.length > 0
    ) {
      let filteredProcedureTypes = procedureTypes.filter((procedureType) =>
        state.procedureTypeIdFilter.includes(procedureType.id),
      );
      if (!!filteredProcedureTypes && filteredProcedureTypes.length > 0) {
        return filteredProcedureTypes.map((pt) => pt.id);
      }
    }
    return procedureTypes.map((pt) => pt.id);
  };

  const applyStudyFilter = (someStudies) => {
    let someStudiesFiltered = someStudies;
    if (!isEmptyOrNull(user.hospitalStudies)) {
      someStudiesFiltered = someStudies.filter((s) =>
        user.hospitalStudies.map((hs) => hs.studyId).includes(s.id),
      );
    }
    return someStudiesFiltered.filter(
      (study) =>
        !state.studyIdFilter ||
        state.studyIdFilter.length < 1 ||
        state.studyIdFilter.includes(study.id),
    );
  };

  const getSelectMenu = () => {
    const showHospitals = canShowHospitalSelector(user, hospitals);
    const showStudies =
      useStudySelector &&
      applyStudyFilter(getReportableStudies(studies, user, state.hospital))
        .length >= 1;
    let selectors = [];
    if (showStudies) {
      selectors.push(
        <MyStudiesSelector
          options={applyStudyFilter(
            getReportableStudies(studies, user, state.hospital),
          )}
          value={state.study}
          useAllStudies={!useStudyCollectionSelector}
          onChange={(value) => studyChanged(value)}
        />,
      );
    }
    if (user.surgeonIds != null && user.surgeonIds.length > 0) {
      selectors.push(
        <SurgeonSelector
          value={state.surgeon}
          useAllSurgeons={true}
          onChange={(value) => setState({ surgeon: value })}
        />,
      );
    }
    if (
      useStudyCollectionSelector &&
      !isEmptyOrNull(studies) &&
      state.study != null &&
      state.study !== ALL_STUDIES
    ) {
      selectors.push(
        <GenericSelector
          options={state.study.studyCollections}
          selected={state.studyCollection}
          searchable={false}
          changeCallback={(value) => setState({ studyCollection: value })}
        />,
      );
    }
    if (useProcedureTypeSelector) {
      selectors.push(
        <SimpleProcedureTypeSelector
          value={state.procedureType}
          onChange={(value) => procedureTypeChanged(value)}
          autoFocus={false}
          filterByProcedureTypeIds={applyProcedureTypeFilter()}
          includeAllProcedureTypes
        />,
      );
    }
    if (showHospitals) {
      selectors.push(
        <MyHospitalsSelector
          value={state.hospital}
          onChange={(value) => hospitalChanged(value)}
          hospitalIdFilter={getHospitalIdFilter()}
        />,
      );
    }

    if (selectors.length === 1) {
      return (
        <Row className={"my-1"}>
          <Col>{selectors[0]}</Col>
        </Row>
      );
    } else if (selectors.length === 2) {
      return (
        <Row className={"my-1"}>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            {selectors[0]}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            {selectors[1]}
          </Col>
        </Row>
      );
    } else if (selectors.length === 3) {
      return (
        <Fragment>
          <Row className={"my-1"}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              {selectors[0]}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              {selectors[1]}
            </Col>
          </Row>
          <Row className={"my-1"}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {selectors[2]}
            </Col>
          </Row>
        </Fragment>
      );
    } else if (selectors.length === 4) {
      return (
        <Fragment>
          <Row className={"my-1"}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              {selectors[0]}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              {selectors[1]}
            </Col>
          </Row>
          <Row className={"my-1"}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              {selectors[2]}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              {selectors[3]}
            </Col>
          </Row>
        </Fragment>
      );
    } else {
      return null;
    }
  };

  const setStartMonth = (value) => {
    const startOfMonth =
      value == null
        ? null
        : Moment(value, "DD/MM/YYYY", true).startOf("month").format();
    setState({ startDate: startOfMonth });
  };

  const setEndOfMonth = (value) => {
    const endOfMonth =
      value == null
        ? null
        : Moment(value, "DD/MM/YYYY", true).endOf("month").format();
    setState({ endDate: endOfMonth });
  };

  const hasData = () => {
    if (state.dataset && state.dataset.data) {
      return Object.keys(state.dataset.data).some((key) => {
        return state.dataset.data[key].length > 0;
      });
    }
    return false;
  };

  return (
    <div className={"bar-report"}>
      <h3 className={"report-title"}>{state.dataset.title}</h3>
      {subtitle != null && subtitle}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
          <ReportingCheckbox
            checked={state.isCumulative}
            id={id}
            label={"Show Cumulative"}
            tooltip={"Show cumulative data aggregated over time."}
            callback={() => setCumulative(!state.isCumulative)}
          />
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} xl={3}>
          <FormGroup inline>
            <Label>
              <b>Start Month</b>
            </Label>
            <DatePicker
              selected={
                state.startDate == null
                  ? null
                  : Moment(state.startDate).toDate()
              }
              dateFormat="MM/yyyy"
              placeholderText={"Start Month..."}
              showMonthYearPicker
              onChange={(value) => setStartMonth(value)}
            />
          </FormGroup>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} xl={3}>
          <FormGroup inline>
            <Label>
              <b>End Month</b>
            </Label>
            <DatePicker
              selected={
                state.endDate == null ? null : Moment(state.endDate).toDate()
              }
              dateFormat="MM/yyyy"
              placeholderText={"End Month..."}
              showMonthYearPicker
              onChange={(value) => setEndOfMonth(value)}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={3} className={"mt-4"}>
          <Button
            color={"primary"}
            onClick={() => setState({ startDate: null, endDate: null })}
          >
            Clear Date Filter
          </Button>
        </Col>
      </Row>

      {getSelectMenu()}
      {state.isLoading ? (
        <Row
          className={"justify-content-center align-items-center"}
          style={{ height: "400px" }}
        >
          <i>Loading data...</i>
        </Row>
      ) : hasData() ? (
        <Fragment>
          <Responsive minWidth={376}>
            <BarChart
              dataset={state.dataset}
              stacked={true}
              mapColours={mapColours}
            />
          </Responsive>
          <Responsive maxWidth={375}>
            <BarChart
              xAxisTicks={2}
              dataset={state.dataset}
              stacked={true}
              mapColours={mapColours}
            />
          </Responsive>
        </Fragment>
      ) : (
        <Row
          className={"justify-content-center align-items-center"}
          style={{ height: "400px" }}
        >
          <i>No data was found. Please try another selection.</i>
        </Row>
      )}
    </div>
  );
};

BarReport.propTypes = {
  fetchData: PropTypes.func.isRequired,
  mapColours: PropTypes.func,
  legendHovers: PropTypes.object,
  isCumulativeByDefault: PropTypes.bool,
  id: PropTypes.string.isRequired,
  useStudySelector: PropTypes.bool,
  useProcedureTypeSelector: PropTypes.bool,
  useStudyCollectionSelector: PropTypes.bool,
  subtitle: PropTypes.element,
};

export default BarReport;

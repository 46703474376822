import { Fragment } from "react";
import PropTypes from "prop-types";
import { getDateValue } from "./Utils";
import { FormFeedback, FormText, Row } from "reactstrap";
import "./CrfType.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerIosInput from "../common/DatePickerIosInput";
import Moment from "moment";
import { isEmptyOrNull } from "../../Utils";
import classnames from "classnames";
import CrfBaseQuestion from "./CrfBaseQuestion";

const dateFormatTwo = "DD/MM/YYYY";

const CrfDateQuestion = ({
  question = null,
  answers = [],
  index = -1,
  onChange = () => null,
  disabled = true,
  showValidationErrors = false,
  crfId,
  crfProcId,
  crfType,
  onConfirm,
  dataQueries,
}) => {
  const dateIsValid = (value) => {
    return Moment(value).isValid();
  };

  const parseFieldValue = (answer) => {
    return Moment(answer[0].dateValue).format(dateFormatTwo);
  };

  const crfComponent = () => {
    const answersForThisQuestions = answers.filter(
      (answer) => answer.questionId === question.id,
    );
    return (
      <Fragment>
        <Row
          key={`choice-${question.id}-index-${index}`}
          className={"crf-type g-0"}
        >
          <DatePicker
            selected={
              answersForThisQuestions.length === 1
                ? getDateValue(answersForThisQuestions[0].dateValue)
                : null
            }
            onChange={(value) => {
              if (
                (!question.required && value === null) ||
                dateIsValid(value)
              ) {
                onChange(question.id, value);
              }
            }}
            disabled={disabled}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="scroll"
            dateFormat={"dd/MM/yyyy"}
            scrollableYearDropdown={true}
            placeholderText={"dd/mm/yyyy"}
            popperPlacement={"top-start"}
            fixedHeight
            customInput={<DatePickerIosInput />}
            className={classnames("date-field", {
              invalid:
                showValidationErrors &&
                answersForThisQuestions.length === 0 &&
                question.required,
            })}
          />{" "}
        </Row>
        <Row
          key={`error-message-${question.id}-index-${index}`}
          className={"crf-type g-0"}
        >
          {answersForThisQuestions.length === 1 &&
            getDateValue(answersForThisQuestions[0].dateValue) == null &&
            !isEmptyOrNull(answersForThisQuestions[0].dateValue) && (
              <FormText className={"crf-type"}>
                {" "}
                Please ensure dates are in the DD/MM/YYYY format
              </FormText>
            )}
          {showValidationErrors &&
            answersForThisQuestions.length === 0 &&
            question.required && (
              <FormFeedback style={{ display: "block" }}>
                This field is required
              </FormFeedback>
            )}
        </Row>
      </Fragment>
    );
  };

  return (
    <CrfBaseQuestion
      question={question}
      onConfirm={onConfirm}
      answers={answers}
      index={index}
      onChange={onChange}
      crfProcId={crfProcId}
      dataQueries={dataQueries}
      crfType={crfType}
      crfId={crfId}
      parseFieldValue={parseFieldValue}
      crfComponent={crfComponent()}
    ></CrfBaseQuestion>
  );
};

CrfDateQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  dataQueries: PropTypes.arrayOf(PropTypes.object),
  crfId: PropTypes.number,
  onConfirm: PropTypes.func.isRequired,
  crfType: PropTypes.object,
  crfProcId: PropTypes.number,
};

export default CrfDateQuestion;

import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import ButtonBar from "../common/ButtonBar";

const OptionalModal = ({
  isOpen = false,
  procedureType = "",
  side = "",
  numberOfQuestions = 0,
  procedureId = 0,
  noCallback,
  yesCallback,
}) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Answer Optional Questions?</ModalHeader>
      <ModalBody>
        Would you like to answer an additional {numberOfQuestions}{" "}
        <b>(optional)</b> questions about your{" "}
        {side == null ? "unknown side" : side.toLowerCase()}{" "}
        {procedureType == null
          ? "unknown procedureType"
          : procedureType.toLowerCase()}
        ?
        <ButtonBar
          buttons={[
            <Button color={"primary"} onClick={() => yesCallback(procedureId)}>
              Yes
            </Button>,
            <Button color={"primary"} onClick={() => noCallback(procedureId)}>
              No
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

OptionalModal.propTypes = {
  isOpen: PropTypes.bool,
  yesCallback: PropTypes.func.isRequired,
  noCallback: PropTypes.func.isRequired,
  procedureType: PropTypes.string,
  side: PropTypes.string,
  numberOfQuestions: PropTypes.number,
  procedureId: PropTypes.number,
};

export default OptionalModal;

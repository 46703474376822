import PropTypes from "prop-types";
import { FormFeedback, Input, Row } from "reactstrap";
import "./CrfType.css";
import { isDecimalQuestion, isIntegerQuestion } from "./Utils";
import CrfBaseQuestion from "./CrfBaseQuestion";

const CrfNumberQuestion = ({
  question = null,
  answers = [],
  index = -1,
  onChange = () => null,
  showValidationErrors = false,
  disabled = true,
  crfId,
  crfType,
  onConfirm,
  dataQueries,
  crfProcId,
}) => {
  const getAnswersForThisQuestion = (answers, question) => {
    return answers.filter((answer) => answer.questionId === question.id);
  };

  const coalesceIntegerValue = (value) => {
    if (Number.isNaN(parseInt(value))) {
      return value;
    } else {
      return parseInt(value);
    }
  };

  const coalesceDecimalValue = (value) => {
    if (Number.isNaN(parseFloat(value))) {
      return value;
    } else {
      return parseFloat(value);
    }
  };

  const getIntegerInput = () => {
    const answersForThisQuestion = getAnswersForThisQuestion(answers, question);
    const minValue =
      question.integerValidations.filter(
        (iv) => iv.crfIntegerValidationTypeId === 1,
      ).length === 1
        ? question.integerValidations.filter(
            (iv) => iv.crfIntegerValidationTypeId === 1,
          )[0].value
        : null;
    const maxValue =
      question.integerValidations.filter(
        (iv) => iv.crfIntegerValidationTypeId === 2,
      ).length === 1
        ? question.integerValidations.filter(
            (iv) => iv.crfIntegerValidationTypeId === 2,
          )[0].value
        : null;
    return (
      <Input
        type={"number"}
        disabled={disabled}
        onChange={(event) =>
          onChange(question.id, coalesceIntegerValue(event.target.value))
        }
        min={minValue}
        max={maxValue}
        value={
          answersForThisQuestion.length === 1
            ? answersForThisQuestion[0].integerValue
            : ""
        }
      />
    );
  };

  const getDecimalInput = () => {
    const answersForThisQuestion = getAnswersForThisQuestion(answers, question);
    const minValue =
      question.decimalValidations.filter(
        (dv) => dv.crfDecimalValidationTypeId === 1,
      ).length === 1
        ? question.decimalValidations.filter(
            (dv) => dv.crfDecimalValidationTypeId === 1,
          )[0].value
        : null;
    const maxValue =
      question.decimalValidations.filter(
        (dv) => dv.crfDecimalValidationTypeId === 2,
      ).length === 1
        ? question.decimalValidations.filter(
            (dv) => dv.crfDecimalValidationTypeId === 2,
          )[0].value
        : null;
    let step =
      question.decimalValidations.filter(
        (dv) => dv.crfDecimalValidationTypeId === 3,
      ).length === 1
        ? question.decimalValidations.filter(
            (dv) => dv.crfDecimalValidationTypeId === 3,
          )[0].value
        : null;
    if (step != null) {
      step = Math.pow(10, -1 * step);
    }
    return (
      <Input
        type={"number"}
        disabled={disabled}
        onChange={(event) =>
          onChange(question.id, coalesceDecimalValue(event.target.value))
        }
        min={minValue}
        max={maxValue}
        step={step}
        value={
          answersForThisQuestion.length === 1
            ? answersForThisQuestion[0].decimalValue
            : ""
        }
      />
    );
  };

  const parseFieldValue = (answer, question) => {
    if (isDecimalQuestion(question)) {
      return answer[0].decimalValue;
    } else if (isIntegerQuestion(question)) {
      return answer[0].integerValue;
    }
  };

  const crfComponent = () => {
    return (
      <Row
        key={`number-input-${question.id}-index-${index}`}
        className={"crf-question g-0"}
      >
        {isIntegerQuestion(question) && getIntegerInput()}
        {isDecimalQuestion(question) && getDecimalInput()}
        {showValidationErrors &&
          getAnswersForThisQuestion(answers, question).length === 0 &&
          question.required && (
            <FormFeedback style={{ display: "block" }}>
              This field is required
            </FormFeedback>
          )}
      </Row>
    );
  };

  return (
    <CrfBaseQuestion
      question={question}
      onConfirm={onConfirm}
      answers={answers}
      index={index}
      onChange={onChange}
      crfProcId={crfProcId}
      dataQueries={dataQueries}
      crfType={crfType}
      crfId={crfId}
      parseFieldValue={parseFieldValue}
      crfComponent={crfComponent()}
    ></CrfBaseQuestion>
  );
};

CrfNumberQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  showValidationErrors: PropTypes.bool,
  dataQueries: PropTypes.arrayOf(PropTypes.object),
  crfId: PropTypes.number,
  onConfirm: PropTypes.func.isRequired,
  crfType: PropTypes.object,
};

export default CrfNumberQuestion;

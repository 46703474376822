import { createAction } from "redux-actions";
import * as Types from "./Types";

export const loadCrfDateValidationTypes = createAction(
  Types.LOAD_CRF_DATE_VALIDATION_TYPES,
);
export const crfDateValidationTypesLoaded = createAction(
  Types.CRF_DATE_VALIDATION_TYPES_LOADED,
);
export const loadCrfDateValidationOperators = createAction(
  Types.LOAD_CRF_DATE_VALIDATION_OPERATORS,
);
export const crfDateValidationOperatorsLoaded = createAction(
  Types.CRF_DATE_VALIDATION_OPERATORS_LOADED,
);
export const loadCrfDateTimeValidationTypes = createAction(
  Types.LOAD_CRF_DATETIME_VALIDATION_TYPES,
);
export const crfDateTimeValidationTypesLoaded = createAction(
  Types.CRF_DATETIME_VALIDATION_TYPES_LOADED,
);
export const loadCrfDateTimeValidationOperators = createAction(
  Types.LOAD_CRF_DATETIME_VALIDATION_OPERATORS,
);
export const crfDateTimeValidationOperatorsLoaded = createAction(
  Types.CRF_DATETIME_VALIDATION_OPERATORS_LOADED,
);
export const loadCrfQuestionTypes = createAction(Types.LOAD_CRF_QUESTION_TYPES);
export const crfQuestionTypesLoaded = createAction(
  Types.CRF_QUESTION_TYPES_LOADED,
);
export const loadCrfStringValidationOperators = createAction(
  Types.LOAD_CRF_STRING_VALIDATION_OPERATORS,
);
export const crfStringValidationOperatorsLoaded = createAction(
  Types.CRF_STRING_VALIDATION_OPERATORS_LOADED,
);
export const loadCrfIntegerValidationTypes = createAction(
  Types.LOAD_CRF_INTEGER_VALIDATION_TYPES,
);
export const crfIntegerValidationTypesLoaded = createAction(
  Types.CRF_INTEGER_VALIDATION_TYPES_LOADED,
);
export const loadCrfDecimalValidationTypes = createAction(
  Types.LOAD_CRF_DECIMAL_VALIDATION_TYPES,
);
export const crfDecimalValidationTypesLoaded = createAction(
  Types.CRF_DECIMAL_VALIDATION_TYPES_LOADED,
);

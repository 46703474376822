import { Fragment, useEffect, useReducer } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import {
  getStartCase,
  isAoaUser,
  isEmptyOrNull,
  isHospitalAdministratorRoleId,
  isHospitalAdministratorUser,
  isHospitalStudyCoordinatorRoleId,
  isHospitalStudyCoordinatorUser,
} from "../../Utils";
import TextareaAutosize from "react-autosize-textarea";
import {
  assigneeNeedsUsers,
  canChooseAssignee,
  CLOSED_DATA_QUERY_STATUS_ID,
  CRF_FIELD_TYPE_ID,
  DUPLICATE_CRF_TYPE_ID,
  DUPLICATE_PATIENT_TYPE_ID,
  DUPLICATE_PROCEDURE_COLLECTION_QUERY_TYPE_ID,
  DUPLICATE_PROCEDURE_TYPE_ID,
  getDataQueryCrfsTable,
  getDataQueryPatientsTable,
  getDataQueryProcedureCollectionsTable,
  getDataQueryProceduresTable,
  isAnsweredDataQuery,
  isClosedDataQuery,
  isInProgressDataQuery,
  isOpenDataQuery,
  isValidDuplicatePatientQueryWithTwoPatients,
  OPEN_DATA_QUERY_STATUS_ID,
  PATIENT_FIELD_TYPE_ID,
  PROCEDURE_FIELD_TYPE_ID,
  userCanAssignTaskTo,
} from "./Utils";
import { useDispatch, useSelector } from "react-redux";
import SimpleRoleSelector from "../common/SimpleRoleSelector";
import {
  getDataQuery,
  getHospitalAdministrators,
  getHospitalStudyCoordinators,
  updateDataQuery,
} from "../../api/DataQuery";
import {
  finishLoading,
  loadDataQueryStatuses,
  loadRoles,
  startLoading,
} from "../../actions/CommonActions";
import Moment from "moment";
import GenericSelector from "../common/GenericSelector";
import ButtonBar from "../common/ButtonBar";
import { FaLock, FaQuestionCircle } from "react-icons/fa";
import "./ViewDataQuery.css";
import PatientMergeModal from "../management/patient/PatientMergeModal";

const ViewDataQuery = ({ dataQueryId, saveCallback, cancelCallback }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);
  const dataQueries = useSelector((state) => state.dataQueries);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      comment: "",
      isPublic: false,
      error: null,
      dataQuery: null,
      originalDataQuery: null,
      modalComponent: null,
      assigneeUsers: null,
      showMergeModal: false,
      showMergeButton: true,
    },
  );

  useEffect(() => {
    if (common.roles.length === 0) {
      dispatch(loadRoles());
    }
    if (dataQueries.statuses.length === 0) {
      dispatch(loadDataQueryStatuses());
    }
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [dataQueries]);

  useEffect(() => {
    if (userCanAssignTaskTo(user, getCurrentAssignee())) {
      checkAssigneeUsers();
    }
  }, [state.dataQuery]);

  const loadData = () => {
    dispatch(startLoading());
    setState({ modalComponent: null });
    getDataQuery(dataQueryId)
      .then((response) => {
        let crfCompletableRolesByType = buildCrfAssignableRolesUsingCrfTypes(
          response.data,
        );
        setState({
          crfRolesProvided: crfCompletableRolesByType,
          dataQuery: response.data,
          originalDataQuery: response.data,
          assignedToId: response.data.assignedToId,
          statusId: setStatusId(response.data.assignedToId),
        });
      })
      .finally(() => dispatch(finishLoading()));
  };

  const saveAfterMerge = (mergedPatientId) => {
    let delPatIdx = state.dataQuery.patients.findIndex(
      (pat) => pat.id === mergedPatientId,
    );
    let _dataQuery = state.dataQuery;
    _dataQuery.patients[delPatIdx] = {
      firstName: "Not Found",
      lastName: "Not Found",
      postcode: "Not Found",
      dateOfBirth: null,
      deceased: "Not Found",
      middleNames: "Not Found",
      id: mergedPatientId,
    };
    setState({
      showMergeModal: false,
      showMergeButton: false,
      dataQuery: _dataQuery,
    });
  };

  const canSave = (dataQuery) => {
    // Comment is not empty
    return (
      !isEmptyOrNull(state.comment) &&
      // Has to be assigned to a role
      dataQuery.assignedToId != null &&
      // Has to have a status
      dataQuery.statusId != null &&
      // Either the current user can't choose assignees,
      (!canChooseAssignee(user) ||
        // or they can, and they've chosen one that doesn't need non-empty assignee users,
        !assigneeNeedsUsers(getCurrentAssignee()) ||
        // or they can choose assignees, and they have chosen one that needs assignee users and the list is not empty
        (!!state.assigneeUsers &&
          state.assigneeUsers.length > 0 &&
          [5, 9].includes(dataQuery.assignedToId) &&
          hasAtLeastOnePublicComment()))
    );
  };

  const save = () => {
    dispatch(startLoading());
    const payload = {
      assignedToId: canChooseAssignee(user)
        ? state.dataQuery.assignedToId
        : null,
      statusId: state.dataQuery.statusId,
      text: state.comment,
      public: showPublicCheckbox() ? state.isPublic : true,
    };
    updateDataQuery(dataQueryId, payload)
      .then(saveCallback)
      .catch((error) => {
        if (error?.response?.data && typeof error.response.data === "string") {
          setState({ error: error.response.data });
        }
      })
      .finally(() => dispatch(finishLoading()));
  };

  const getStatusBadge = (comment) => {
    if (isOpenDataQuery(comment)) {
      return <Badge color={"danger"}>Open</Badge>;
    } else if (isAnsweredDataQuery(comment)) {
      return <Badge color={"success"}>Answered</Badge>;
    } else if (isClosedDataQuery(comment)) {
      return <Badge color={"secondary"}>Closed</Badge>;
    } else if (isInProgressDataQuery(comment)) {
      return <Badge color={"warning"}>In Progress</Badge>;
    } else {
      return null;
    }
  };

  const getAssignedToBadge = (comment) => {
    const assignedToIndex = common.roles.findIndex(
      (role) => role.id === comment.assignedToId,
    );
    const assignedTo =
      assignedToIndex < 0 ? null : common.roles[assignedToIndex];
    return (
      <Badge color={"primary"}>{assignedTo ? assignedTo.name : null}</Badge>
    );
  };

  const getCommentTable = (comments) => {
    if (comments == null || comments.length === 0) {
      return null;
    }
    return (
      <Fragment>
        <Row>
          <Col>
            <h5>Comments</h5>
          </Col>
        </Row>
        <Table responsive striped>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Status</th>
              <th>{"Assigned\u00a0To"}</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {comments.map((comment, index) => {
              const date = Moment(comment.dateCreated).format(
                "DD/MM/YYYY @ h:mm\u00a0a",
              );

              // Convert line breaks from multi-line text area into <br> elements
              const body = comment.text.split("\n").map((item, key) => {
                return (
                  <span key={"line-break-" + key}>
                    {item}
                    <br />
                  </span>
                );
              });

              return (
                <tr key={"comment-row-" + index}>
                  <td>
                    {date}
                    {!comment.public && (
                      <span className={"ms-2"}>
                        <FaLock id={"lock-icon-" + index} />
                        <UncontrolledTooltip
                          placement="right"
                          target={"lock-icon-" + index}
                        >
                          Comment hidden from external users (e.g. Hospital
                          Administrators)
                        </UncontrolledTooltip>
                      </span>
                    )}
                  </td>
                  <td>{comment.createdBy}</td>
                  <td>{getStatusBadge(comment)}</td>
                  <td>{getAssignedToBadge(comment)}</td>
                  <td>{body}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  };

  const getCurrentStatus = () => {
    if (state.dataQuery == null) {
      return null;
    } else {
      const currentStatusIndex = dataQueries.statuses.findIndex(
        (status) => status.id === state.dataQuery.statusId,
      );
      return currentStatusIndex < 0
        ? null
        : dataQueries.statuses[currentStatusIndex];
    }
  };

  const getCurrentAssignee = () => {
    if (state.dataQuery == null) {
      return null;
    } else {
      const currentAssignedToIndex = common.roles.findIndex(
        (role) => role.id === state.dataQuery.assignedToId,
      );
      return currentAssignedToIndex < 0
        ? null
        : common.roles[currentAssignedToIndex];
    }
  };

  const checkAssigneeUsers = () => {
    if (
      (isHospitalAdministratorRoleId(state.dataQuery.assignedToId) ||
        isHospitalStudyCoordinatorRoleId(state.dataQuery.assignedToId)) &&
      canChooseAssignee(user)
    ) {
      dispatch(startLoading());
      let payload = {};
      if (isSinglePatientQuery()) {
        payload.patientIds = state.dataQuery.patient.id;
      } else if (isSingleProcedureQuery()) {
        payload.procedureIds = state.dataQuery.procedure.id;
      } else if (isMultiPatientQuery()) {
        payload.patientIds = state.dataQuery.patients
          .map((patient) => patient.id)
          .join(",");
      } else if (isMultiProcedureQuery()) {
        payload.procedureIds = state.dataQuery.procedures
          .map((procedure) => procedure.id)
          .join(",");
      } else if (isSingleCrfQuery()) {
        payload.patientIds = state.dataQuery.patient.id;
        payload.crfIds = state.dataQuery.crfIds;
      } else if (isMultiCrfQuery()) {
        if (isHospitalStudyCoordinatorRoleId(state.dataQuery.assignedToId)) {
          payload.crfIds = state.dataQuery.crfs.map((crf) => crf.id).join(",");
        } else {
          payload.patientIds = state.dataQuery.patients
            .map((patient) => patient.id)
            .join(",");
        }
      } else if (isMultiProcedureCollectionQuery()) {
        payload.patientIds = state.dataQuery.patients
          .map((patient) => patient.id)
          .join(",");
      }
      if (isHospitalAdministratorRoleId(state.dataQuery.assignedToId)) {
        getHospitalAdministrators(payload)
          .then((response) =>
            setState({ assigneeUsers: response.data, error: null }),
          )
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            } else {
              setState({ error: "An error occurred" });
            }
          })
          .finally(() => dispatch(finishLoading()));
      } else if (
        isHospitalStudyCoordinatorRoleId(state.dataQuery.assignedToId)
      ) {
        getHospitalStudyCoordinators(payload)
          .then((response) =>
            setState({ assigneeUsers: response.data, error: null }),
          )
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            } else {
              setState({ error: "An error occurred" });
            }
          })
          .finally(() => dispatch(finishLoading()));
      }
    } else {
      setState({ assigneeUsers: null });
    }
  };

  const setCurrentAssignee = (value) => {
    // If the current user is not a Hospital Administrator, and they are setting the assignee to Hospital Administrator, auto-set the status to Open
    if (
      !isHospitalAdministratorUser(user) &&
      value != null &&
      isHospitalAdministratorRoleId(value.id)
    ) {
      setState({
        dataQuery: {
          ...state.dataQuery,
          assignedToId: value.id,
          statusId: OPEN_DATA_QUERY_STATUS_ID,
        },
      });
    } else {
      setState({
        dataQuery: {
          ...state.dataQuery,
          assignedToId: value.id,
        },
      });
    }
  };

  const setStatusId = (assignedToId) => {
    if (assignedToId === null) {
      assignedToId = state.dataQuery.assignedToId;
    }
    // If the current user is not a Hospital Administrator, and they are setting the assignee to Hospital Administrator, auto-set the status to Open
    if (
      !isHospitalAdministratorUser(user) &&
      isHospitalAdministratorRoleId(assignedToId)
    ) {
      return OPEN_DATA_QUERY_STATUS_ID;
    }
  };

  const isMulti = () => {
    return isLoaded() && state.originalDataQuery.multi;
  };

  const isSinglePatientQuery = () => {
    return (
      isLoaded() &&
      !isMulti() &&
      state.originalDataQuery.typeId === PATIENT_FIELD_TYPE_ID
    );
  };

  const isSingleProcedureQuery = () => {
    return (
      isLoaded() &&
      !isMulti() &&
      state.originalDataQuery.typeId === PROCEDURE_FIELD_TYPE_ID
    );
  };

  const isSingleCrfQuery = () => {
    return (
      isLoaded() &&
      !isMulti() &&
      state.originalDataQuery.typeId === CRF_FIELD_TYPE_ID
    );
  };

  const isMultiPatientQuery = () => {
    return (
      isLoaded() && state.originalDataQuery.typeId === DUPLICATE_PATIENT_TYPE_ID
    );
  };

  const isMultiProcedureQuery = () => {
    return (
      isLoaded() &&
      state.originalDataQuery.typeId === DUPLICATE_PROCEDURE_TYPE_ID
    );
  };

  const isMultiCrfQuery = () => {
    return (
      isLoaded() && state.originalDataQuery.typeId === DUPLICATE_CRF_TYPE_ID
    );
  };

  const isMultiProcedureCollectionQuery = () => {
    return (
      isLoaded() &&
      state.originalDataQuery.typeId ===
        DUPLICATE_PROCEDURE_COLLECTION_QUERY_TYPE_ID
    );
  };

  const isReadOnly = () => {
    return isLoaded() && isClosedDataQuery(state.originalDataQuery);
  };

  const isLoaded = () => {
    return state.originalDataQuery != null;
  };

  const hasAtLeastOnePublicComment = () => {
    return (
      state.isPublic ||
      state.originalDataQuery.comments.some((comment) => comment.public)
    );
  };

  const showPublicCheckbox = () => {
    return !isHospitalAdministratorUser(user);
  };

  const getInstructions = () => {
    return (
      <Alert color={"warning"}>
        The AOANJRR has requested some further information about the patient(s)
        below. Please read the existing comments, investigate and provide a
        (mandatory) comment in the text box below. If you are working on
        resolving the query you can set the status to{" "}
        <i>
          <b>In Progress</b>
        </i>{" "}
        and add a short comment before saving. If you believe that your comment
        answers the query, you can set the status to{" "}
        <i>
          <b>Answered</b>
        </i>{" "}
        and it will disappear from your task queue. If you are still
        investigating further, you can leave the status at{" "}
        <i>
          <b>Open</b>
        </i>
        .
      </Alert>
    );
  };

  const getButtons = () => {
    const buttons = [];
    if (!isReadOnly()) {
      buttons.push(
        <Button
          color={"primary"}
          disabled={!canSave(state.dataQuery)}
          onClick={save}
        >
          Save
        </Button>,
      );
    }
    if (
      !isEmptyOrNull(state.dataQuery) &&
      !isEmptyOrNull(user) &&
      state.dataQuery.typeId === DUPLICATE_PATIENT_TYPE_ID &&
      isAoaUser(user) &&
      state.showMergeButton
    ) {
      let showMergeButton = isValidDuplicatePatientQueryWithTwoPatients(
        state.dataQuery.patients,
      );
      buttons.push(
        <Button
          color={"primary"}
          disabled={
            !showMergeButton ||
            state.dataQuery.statusId === CLOSED_DATA_QUERY_STATUS_ID
          }
          hidden={
            !showMergeButton ||
            state.dataQuery.statusId === CLOSED_DATA_QUERY_STATUS_ID
          }
          onClick={() => setState({ showMergeModal: true })}
        >
          Merge
        </Button>,
      );
    }
    buttons.push(
      <Button onClick={cancelCallback}>
        {isReadOnly() ? "Close" : "Cancel"}
      </Button>,
    );
    return buttons;
  };

  const getNonEmptyId = (objects) => {
    return objects.filter((obj) => obj.id !== null);
  };

  /**
   * Filter the only roles that are completable in all the crfs
   * @param validatedCrfs
   * @returns {*|AsyncIterator|Iterator|any[]}
   */
  const buildCrfAssignableRolesUsingCrfTypes = (validatedCrfs) => {
    let toReturn = [{ roleId: 1 }, { roleId: 4 }];
    if (validatedCrfs.multi) {
      if (!isEmptyOrNull(validatedCrfs.crfs)) {
        let crfs2Compare = isEmptyOrNull(validatedCrfs.crfs)
          ? 0
          : validatedCrfs.crfs.length;
        // flatMap of all completableRoles (filter out nulls that come when a CRF is removed)
        let allCompletableRoles = validatedCrfs.crfs
          .filter((c) => c.procedureId != null)
          .flatMap((crf) => crf.completableRoles);
        // initialise the roleCount for every completable role in the validated CRFs
        let rolesCount = {};
        allCompletableRoles.forEach((cr) => {
          rolesCount[cr.roleId] = 0;
        });
        // create a count of each completable role as it is found in each CRF
        validatedCrfs.crfs.forEach((crf) => {
          if (!isEmptyOrNull(crf.procedureId)) {
            if (!isEmptyOrNull(crf.completableRoles)) {
              crf.completableRoles.forEach((cr) => {
                rolesCount[cr.roleId]++;
              });
            }
          } else {
            crfs2Compare--;
          }
        });
        // roles to include will only be those that are present in every CRF that has a procedureId
        let roleIdsToInclude = Object.keys(rolesCount).filter(
          (k) => rolesCount[k] === crfs2Compare,
        );
        let alreadySeen = [];
        allCompletableRoles.forEach((cr) => {
          if (
            !!cr &&
            !!cr.roleId &&
            roleIdsToInclude.includes(cr.roleId.toString()) &&
            !alreadySeen.includes(cr.roleId)
          ) {
            toReturn.push(cr);
            alreadySeen.push(cr.roleId);
          }
        });
        return toReturn;
      } else {
        return toReturn;
      }
    } else {
      return validatedCrfs.crf == null ||
        validatedCrfs.crf.completableRoles == null
        ? toReturn
        : validatedCrfs.crf.completableRoles.concat(toReturn);
    }
  };

  const callUserCanAssignTaskTo = (role) => {
    if (
      (isMultiCrfQuery() || isSingleCrfQuery()) &&
      !isEmptyOrNull(state.crfRolesProvided)
    ) {
      return state.crfRolesProvided.map((cr) => cr.roleId).includes(role.id);
    } else {
      return isLoaded()
        ? userCanAssignTaskTo(user, role, state.originalDataQuery.typeId)
        : userCanAssignTaskTo(user, role, null);
    }
  };

  return (
    <Modal isOpen={true} size={"lg"} className={"view-data-query"}>
      <ModalHeader toggle={cancelCallback}>View Data Query</ModalHeader>
      <ModalBody>
        {(isHospitalAdministratorUser(user) ||
          isHospitalStudyCoordinatorUser(user)) &&
          getInstructions()}
        {!isMulti() && (
          <Fragment>
            <Row>
              <Col xs={3}>
                <b>Type</b>
              </Col>
              {isLoaded() && <Col>{state.dataQuery.attribute}</Col>}
            </Row>
            <Row>
              <Col xs={3}>
                <b>Value When Queried</b>
              </Col>
              {isLoaded() && (
                <Col>
                  {isEmptyOrNull(state.dataQuery.value) ? (
                    <i>Empty</i>
                  ) : (
                    state.dataQuery.value
                  )}
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={3}>
                <b>Current Status</b>
              </Col>
              {isLoaded() && (
                <Col>{getStatusBadge(state.originalDataQuery)}</Col>
              )}
            </Row>
          </Fragment>
        )}
        <Row className={"mb-3"}>
          <Col xs={3}>
            <b>Current Assignee</b>
          </Col>
          {isLoaded() && (
            <Col>{getAssignedToBadge(state.originalDataQuery)}</Col>
          )}
        </Row>
        {isMulti() && (
          <Row className={"mb-3"}>
            <Col xs={3}>
              <b>Type</b>
            </Col>
            <Col>
              {getStartCase(
                state.dataQuery.attribute === "procedureCollections"
                  ? "Procedure Collections"
                  : state.dataQuery.attribute,
              )}{" "}
              Duplicate Data Query
            </Col>
          </Row>
        )}
        {isMultiPatientQuery() &&
          getDataQueryPatientsTable(
            state.dataQuery.patients,
            loadData,
            (modal) => setState({ modalComponent: modal }),
          )}
        {isSinglePatientQuery() &&
          getDataQueryPatientsTable(
            [state.dataQuery.patient],
            loadData,
            (modal) => setState({ modalComponent: modal }),
          )}
        {isMultiProcedureQuery() &&
          getDataQueryProceduresTable(
            state.dataQuery.patients[0],
            state.dataQuery.procedures,
            loadData,
            (modal) => setState({ modalComponent: modal }),
            null,
          )}
        {isSingleProcedureQuery() &&
          getDataQueryProceduresTable(
            state.dataQuery.patient,
            [state.dataQuery.procedure],
            loadData,
            (modal) => setState({ modalComponent: modal }),
            null,
          )}
        {isSingleCrfQuery() &&
          getDataQueryCrfsTable(
            state.dataQuery.patient,
            state.dataQuery.procedure,
            [state.dataQuery.crf],
            loadData,
            (modal) => setState({ modalComponent: modal }),
          )}
        {isMultiCrfQuery() &&
          getDataQueryCrfsTable(
            isEmptyOrNull(state.dataQuery.patients[0])
              ? []
              : state.dataQuery.patients[0],
            isEmptyOrNull(state.dataQuery.procedures[0])
              ? []
              : state.dataQuery.procedures[0],
            state.dataQuery.crfs,
            loadData,
            (modal) => setState({ modalComponent: modal }),
          )}
        {isMultiProcedureCollectionQuery() &&
          getDataQueryProcedureCollectionsTable(
            isEmptyOrNull(getNonEmptyId(state.dataQuery.patients))
              ? []
              : getNonEmptyId(state.dataQuery.patients)[0],
            isEmptyOrNull(getNonEmptyId(state.dataQuery.procedures))
              ? []
              : getNonEmptyId(state.dataQuery.procedures)[0],
            state.dataQuery.procedureCollections,
            loadData,
            (modal) => setState({ modalComponent: modal }),
          )}
        {isLoaded() && getCommentTable(state.dataQuery.comments)}
        {!isReadOnly() && (
          <Fragment>
            <Row className={"my-3"}>
              <Col xs={12}>Comment*</Col>
              <Col xs={12}>
                <TextareaAutosize
                  type={"textarea"}
                  className={"free-text-multi"}
                  value={state.comment}
                  onChange={(event) =>
                    setState({ comment: event.target.value })
                  }
                />
              </Col>
            </Row>
            {showPublicCheckbox() && (
              <Row className={"mb-3"}>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name={"isPublic"}
                        onChange={() => setState({ isPublic: !state.isPublic })}
                        checked={state.isPublic}
                      />
                      {" Public"}
                    </Label>
                    <FaQuestionCircle
                      id={"is-public-icon"}
                      className={"tooltip-icon"}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target="is-public-icon"
                    >
                      Show comment to external users (e.g. Hospital
                      Administrators)
                    </UncontrolledTooltip>
                  </FormGroup>
                </Col>
              </Row>
            )}
            {canChooseAssignee(user) && (
              <Fragment>
                <Row>
                  <Col xs={3} className={"my-auto text-end"}>
                    Assign To*
                  </Col>
                  <Col xs={9}>
                    <SimpleRoleSelector
                      value={getCurrentAssignee()}
                      clearable={false}
                      searchable={false}
                      onChange={setCurrentAssignee}
                      filter={callUserCanAssignTaskTo}
                    />
                  </Col>
                </Row>
                {getCurrentAssignee() != null &&
                  assigneeNeedsUsers(getCurrentAssignee()) &&
                  !!state.assigneeUsers &&
                  state.assigneeUsers.length > 0 && (
                    <Fragment>
                      <Alert
                        color={"primary"}
                        className={"my-3 assignee-users-list"}
                      >
                        The following users will be assigned to this data query:
                        <ul>
                          {state.assigneeUsers.map((user, index) => {
                            return (
                              <li key={"assignee-user-" + index}>{user}</li>
                            );
                          })}
                        </ul>
                      </Alert>
                      {!hasAtLeastOnePublicComment() && (
                        <Alert color={"danger"}>
                          At least one public comment is required before
                          assigning to an external group
                        </Alert>
                      )}
                    </Fragment>
                  )}
                {getCurrentAssignee() != null &&
                  assigneeNeedsUsers(getCurrentAssignee()) &&
                  !!state.assigneeUsers &&
                  state.assigneeUsers.length === 0 && (
                    <Alert color={"danger"} className={"my-3"}>
                      At least one external user must be identified as having
                      access to the referenced object before it can be assigned
                      to this external group
                    </Alert>
                  )}
              </Fragment>
            )}
            <Row className={"my-3"}>
              <Col xs={3} className={"mt-1 text-end"}>
                Status*
              </Col>
              <Col xs={9}>
                <GenericSelector
                  clearable={false}
                  searchable={false}
                  showDescription={true}
                  readOnly={
                    !isHospitalAdministratorUser(user) &&
                    getCurrentAssignee() != null &&
                    isHospitalAdministratorRoleId(getCurrentAssignee().id)
                  }
                  options={dataQueries.statuses}
                  changeCallback={(value) =>
                    setState({
                      dataQuery: {
                        ...state.dataQuery,
                        statusId: value.id,
                      },
                    })
                  }
                  selected={getCurrentStatus()}
                />
              </Col>
            </Row>
          </Fragment>
        )}
        {!isEmptyOrNull(state.error) && (
          <Alert color={"danger"} className={"mt-3"}>
            {state.error}
          </Alert>
        )}
        {state.showMergeModal && (
          <PatientMergeModal
            patientId={
              !isEmptyOrNull(state.dataQuery.patients[0])
                ? state.dataQuery.patients[0].id
                : null
            }
            patientName={
              !isEmptyOrNull(state.dataQuery.patients[0])
                ? state.dataQuery.patients[0].firstName +
                  " " +
                  state.dataQuery.patients[0].lastName
                : null
            }
            mergedPatientId={state.dataQuery.patients[1].id}
            cancelCallback={() => setState({ showMergeModal: false })}
            showModal={state.mergeRequired}
            confirmCallback={saveAfterMerge}
            isBeingMergedFromDataQuery={true}
          />
        )}
        <ButtonBar buttons={getButtons()} />
      </ModalBody>
      {!!state.modalComponent && state.modalComponent}
    </Modal>
  );
};

ViewDataQuery.propTypes = {
  dataQueryId: PropTypes.number.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
};

export default ViewDataQuery;

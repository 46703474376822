// This is a custom input for react-datepicker to allow re-entry into the date picker on iOS.
// https://github.com/Hacker0x01/react-datepicker/issues/830#issuecomment-306473603
// i.e. iOS does not care about subsequent clicks on an input element that is focussed.
import React, { Component } from "react";
import "./DatePickerIosInput.css";

class DatePickerIosInput extends Component {
  handleTouchStart = () => this.props.onClick();
  render() {
    return (
      <input
        {...this.props}
        onTouchStart={this.handleTouchStart}
        type="text"
        className={this.props.className + " datepicker-ios-input"}
      />
    );
  }
}

export default DatePickerIosInput;

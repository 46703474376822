import {
  autoLogonPatient,
  autoLogonPatientSms,
} from "../../actions/PatientActions";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";

const AutoLogin = () => {
  const { id, token } = useParams();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const payload = {
      id: id,
      token: token,
    };
    if (location.pathname.split("/")[1] === "pl") {
      dispatch(autoLogonPatientSms(payload));
    } else {
      dispatch(autoLogonPatient(payload));
    }
  }, []);

  return "";
};

export default AutoLogin;
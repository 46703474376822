import { Button, Col, FormFeedback, Input, Row } from "reactstrap";
import PropTypes from "prop-types";
import { supportsTouchEvents } from "detect-it";
import { useEffect, useRef, useState } from "react";
import { handleEnterKeyPress } from "../../Utils";

const months = [
  ["January", "February", "March"],
  ["April", "May", "June"],
  ["July", "August", "September"],
  ["October", "November", "December"],
];

const SimpleMonthInput = ({
  updateAction = () => null,
  field = null,
  value = "",
  invalid = false,
  invalidNotice = "That does not look like a month.",
  enterAction = () => null,
}) => {
  const monthInput = useRef(null);

  const [state, setState] = useState({});

  useEffect(() => {
    monthInput.current.focus();
  }, [field]);

  const updateKeypress = (value) => {
    let target =
      value.length > 1
        ? value.substring(value.length - 1).toLowerCase()
        : value.toLowerCase();
    let options = [];
    months.forEach((row) =>
      row
        .filter((m) => m.substring(0, 1).toLowerCase() === target)
        .forEach((m) => options.push(m)),
    );
    if (options.length === 0) {
      return;
    }
    let count = state[target] !== undefined ? state[target] : -1;
    count++;
    count = count % options.length;
    let newState = { ...state };
    newState[target] = count;
    setState(newState);
    updateAction({ [field]: options[count] });
  };

  return (
    <div className={"simple-input-wrapper ro month"}>
      <Row>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
        <Col>
          <Input
            type={"text"}
            value={value || ""}
            readOnly={supportsTouchEvents}
            disabled={supportsTouchEvents}
            style={{ backgroundColor: "#fff" }}
            bsSize={"lg"}
            className={invalid ? "is-invalid" : null}
            innerRef={(input) => {
              monthInput.current = input;
            }}
            onChange={(event) => updateKeypress(event.target.value)}
            tabIndex={1}
            onKeyPress={(event) => handleEnterKeyPress(event, enterAction)}
          />
          <FormFeedback>{invalidNotice}</FormFeedback>
        </Col>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
      </Row>
      <Row className={"buttons"}>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
        <Col className={"keypad month"}>
          {months.map((monthRow, i) => {
            return (
              <Row key={i} style={{ marginLeft: 0, marginRight: 0 }}>
                {monthRow.map((month) => {
                  return (
                    <Col xs={4} key={month}>
                      <Button
                        outline={value !== month}
                        color={"primary"}
                        onClick={() => updateAction({ [field]: month })}
                        tabIndex={-1}
                      >
                        <span className={"button-text"}>{month}</span>
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </Col>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
      </Row>
    </div>
  );
};

SimpleMonthInput.propTypes = {
  updateAction: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  invalid: PropTypes.bool,
  invalidNotice: PropTypes.string,
  enterAction: PropTypes.func,
};

export default SimpleMonthInput;

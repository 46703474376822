import { useEffect, useReducer } from "react";
import { Table } from "reactstrap";
import { getSurgeonCrfForm } from "../../api/Crf";
import Moment from "moment";
import "./SurgeonCrfTable.css";
import { FaArrowDown } from "react-icons/fa";
import {
  getStartCase,
  isHospitalStudyCoordinatorUser,
  isSurgeonUser,
} from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import HelpModal from "../common/HelpModal";
import Crf from "../crf/Crf";
import { toast } from "react-toastify";

const SurgeonCrf = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { procedureId, crfTypeId } = useParams();

  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      patient: null,
      procedure: null,
      registryProcedure: null,
      crfType: null,
      study: null,
      showHelp: false,
    },
  );

  useEffect(() => {
    dispatch(startLoading());
    if (
      procedureId != null &&
      !Number.isNaN(parseInt(procedureId)) &&
      crfTypeId != null &&
      !Number.isNaN(parseInt(crfTypeId))
    ) {
      const _procedureId = parseInt(procedureId);
      const _crfTypeId = parseInt(crfTypeId);
      getSurgeonCrfForm(_procedureId, _crfTypeId)
        .then((response) => {
          setState({
            patient: response.data.patient,
            procedure: response.data.procedure,
            registryProcedure: response.data.registryProcedure,
            crfType: response.data.crfType,
            study: response.data.study,
          });
        })
        .finally(() => dispatch(finishLoading()));
    }
  }, []);

  const getProcedureInfo = () => {
    if (state.procedure == null) {
      return null;
    }
    let crfHscText = " procedure by ";
    if (isHospitalStudyCoordinatorUser(user)) {
      crfHscText = crfHscText + getSurgeonName(state.procedure) + " at ";
    }
    let result = "";
    if (isHospitalStudyCoordinatorUser(user)) {
      result =
        getStartCase(state.procedure.side) +
        " " +
        getStartCase(state.procedure.procedureType) +
        crfHscText +
        state.procedure.hospital.name;
    } else if (isSurgeonUser(user)) {
      result =
        getStartCase(state.procedure.side) +
        " " +
        getStartCase(state.procedure.procedureType) +
        " procedure by you at " +
        state.procedure.hospital.name;
    }
    if (state.registryProcedure !== null) {
      if (state.registryProcedure.revision === true) {
        result = "Revision " + result;
      } else {
        result = "Primary " + result;
      }
      result =
        result +
        " on " +
        formatDate(state.registryProcedure.procedureDate) +
        " (" +
        Moment(state.registryProcedure.procedureDate, "YYYY-MM-DD").fromNow() +
        ")";
    }
    return result;
  };

  const formatDate = (input) => {
    return Moment(input, "YYYY-MM-DD").format("DD/MM/YYYY");
  };

  const handleSuccess = (message) => {
    toast.success(message);
    navigate(-1);
  };

  const getSurgeonName = (procedure) => {
    let name;
    if (
      procedure.surgeon?.firstName !== null &&
      procedure.surgeon.lastName !== null
    )
      name =
        "Dr. " +
        procedure.surgeon.lastName +
        ", " +
        procedure.surgeon.firstName;
    else if (
      procedure.surgeon?.firstName !== null &&
      procedure.surgeon.lastName === null
    )
      name = "Dr. " + procedure.surgeon.firstName;
    else if (
      procedure.surgeon !== null &&
      procedure.surgeon.firstName === null &&
      procedure.surgeon.lastName !== null
    )
      name = "Dr. " + procedure.surgeon.lastName;
    else name = "Unknown";
    return name;
  };

  return (
    <div className={"surgeon-crf"}>
      <h2 className={"title"}>Your Patient</h2>
      <Table responsive className={"surgeon-crf-table"}>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Middle Name(s)</th>
            <th>Last Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{state.patient == null ? null : state.patient.firstName}</td>
            <td>{state.patient == null ? null : state.patient.middleNames}</td>
            <td>{state.patient == null ? null : state.patient.lastName}</td>
          </tr>
        </tbody>
      </Table>
      <Table responsive className={"surgeon-crf-table"}>
        <thead>
          <tr>
            <th>DOB</th>
            <th>Postcode</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {state.patient == null
                ? null
                : formatDate(state.patient.dateOfBirth)}
            </td>
            <td>{state.patient == null ? null : state.patient.postcode}</td>
          </tr>
        </tbody>
      </Table>
      <div className={"icon-div"}>
        <FaArrowDown className="down-arrow" />
      </div>
      <div className={"procedure-text"}>{getProcedureInfo()}</div>
      <div className={"icon-div"}>
        <FaArrowDown className="down-arrow" />
      </div>
      <div className={"explanation"}>
        <p>Please complete the following information:</p>
        <p>
          <i>Mandatory fields are marked with *</i>
        </p>
      </div>
      {state.crfType != null && (
        <Crf
          readOnly={false}
          preSelectedType={state.crfType}
          procedure={state.procedure}
          study={state.study}
          renderAsModal={false}
          onExit={() => navigate(-1)}
          onConfirm={handleSuccess}
        />
      )}
      <div className={"explanation"}>
        <a href="#" onClick={() => setState({ showHelp: true })}>
          Contact the AOANJRR
        </a>{" "}
        if you have any issues.
      </div>
      {state.showHelp && (
        <HelpModal closeCallback={() => setState({ showHelp: false })} />
      )}
    </div>
  );
};

export default SurgeonCrf;

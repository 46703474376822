import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import "./Activate.css";
import {
  getContactAoanjrrComponent,
  isEmptyOrNull,
  validateLengthLessThanOrEqualTo,
} from "../../Utils";
import zxcvbn from "zxcvbn";
import AoaLogo from "../../images/aoa_logo_blue.png";
import SimpleFooter from "../common/SimpleFooter";
import { activateUser, checkToken } from "../../api/User";
import { useEffect, useReducer } from "react";
import { Link, useParams } from "react-router-dom";

const passwordMaxLength = 160;

const Activate = () => {
  const { id, token } = useParams();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      tokenError: "",
      success: false,
      submitting: false,
      serverError: "",
      credentials: {
        password1: "",
        password2: "",
      },
      errors: {
        password1: "",
        password2: "",
        zxcvbn: {},
      },
    },
  );

  useEffect(() => {
    const payload = {
      id: id,
      token: token,
    };

    checkToken(payload)
      .then((response) => {
        if (response.data !== true) {
          setState({
            tokenError: (
              <span>
                Your activation link is invalid or has expired.{" "}
                {getContactAoanjrrComponent()}
              </span>
            ),
          });
        }
      })
      .catch(() => {
        setState({
          tokenError:
            "There was an issue communicating with the server. Please try again later.",
        });
      });
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;

    let errors = {
      password1: "",
      password2: "",
      zxcvbn: {},
    };

    if (id === "password2" && isEmptyOrNull(state.credentials.password1)) {
      errors.password1 = "This field is required";
    } else if (id === "password1") {
      if (!validateLengthLessThanOrEqualTo(value, 160)) {
        // We restrict the password to passwordMaxLength characters on both the client side and server side (prevent DOS through hashing)
        errors.password1 = "Passwords must be 160 characters or fewer";
      } else {
        errors.zxcvbn = {};
        const strength = zxcvbn(value);
        if (strength.score < 2) {
          // The formatting of zxcvbn's error messages aren't great, so we do some manual string manipulation to make them look ok.
          let suggestions = "";
          strength.feedback.suggestions.forEach(function (value) {
            suggestions = suggestions + value;
            if (!suggestions.endsWith(".")) {
              suggestions = suggestions + ".";
            }
            if (!suggestions.endsWith(" ")) {
              suggestions = suggestions + " ";
            }
          });
          let warning = strength.feedback.warning;
          if (!isEmptyOrNull(warning) && !warning.endsWith(".")) {
            warning = warning + ".";
          }
          errors.zxcvbn.warning = warning;
          errors.zxcvbn.suggestions = suggestions;
          errors.password1 = "This password cannot be submitted.";
        }
      }
    }
    if (
      !isEmptyOrNull(state.credentials.password1) &&
      !isEmptyOrNull(state.credentials.password2)
    ) {
      if (
        (id === "password1" && value !== state.credentials.password2) ||
        (id === "password2" && value !== state.credentials.password1)
      ) {
        errors.password2 = "Passwords must match";
      }
    }

    setState({
      serverError: "",
      errors: errors,
      credentials: {
        ...state.credentials,
        [id]: value,
      },
    });
  };

  const handleSubmit = () => {
    // Don't do anything if the form is invalid
    if (formValid()) {
      setState({
        submitting: true,
        serverError: "",
      });
      const payload = {
        id: id,
        token: token,
        password1: state.credentials.password1,
        password2: state.credentials.password2,
      };

      activateUser(payload)
        .then(() => {
          setState({ ...state, success: true });
        })
        .catch((error) => {
          if (!error.response.data || isEmptyOrNull(error.response.data)) {
            setState({
              serverError: "There was an issue setting your password.",
            });
          } else {
            setState({
              serverError: error.response.data,
            });
          }
        })
        .finally(() => setState({ submitting: false }));
    }
  };

  const formValid = () => {
    const checkCredentials =
      state.credentials.password1 === state.credentials.password2 &&
      !isEmptyOrNull(state.credentials.password1) &&
      validateLengthLessThanOrEqualTo(
        state.credentials.password1,
        passwordMaxLength,
      );
    const checkErrors =
      isEmptyOrNull(state.errors.password1) &&
      isEmptyOrNull(state.errors.password2) &&
      !state.errors.zxcvbn.warning &&
      !state.errors.zxcvbn.suggestions;
    return checkCredentials && checkErrors;
  };

  return (
    <div className="activate-user">
      <div className="landing-header">
        <a href="/" title="Home">
          <img src={AoaLogo} alt="aoa-header-logo" />
        </a>
      </div>
      <div className="map-background">
        <div className="spacer" />
        <Container className={"activate-user landing-body"}>
          <Card>
            <CardBody>
              <CardTitle>Set New Password</CardTitle>
              <CardText>
                Please set a new password for your AOANJRR trials account.
                <br />
                There are no specific password rules, however, you will not be
                able to submit a password if it is considered easy to guess.
                <br />
                Please try not to reuse any of your previous passwords.
                <br />
              </CardText>
              <div className="set-password">
                <Form>
                  <FormGroup>
                    <Row className={"password-row"}>
                      <Col xs={4} md={4} lg={3}>
                        <Label for="password1">Enter New Password</Label>
                      </Col>
                      <Col xs={8} md={6} lg={4}>
                        <Input
                          valid={
                            isEmptyOrNull(state.errors.password1) ? null : false
                          }
                          disabled={state.submitting}
                          type="password"
                          id="password1"
                          value={state.credentials.password1}
                          onChange={handleChange}
                          onKeyPress={handleKeyPress}
                        />
                        <FormFeedback>{state.errors.password1}</FormFeedback>
                      </Col>
                      <Col xs={0} md={2} lg={5} />
                    </Row>
                    <Row className={"password-row"}>
                      <Col xs={12} md={10} lg={7}>
                        <Alert
                          color="warning"
                          isOpen={
                            !isEmptyOrNull(state.errors.zxcvbn.warning) ||
                            !isEmptyOrNull(state.errors.zxcvbn.suggestions)
                          }
                        >
                          <ZxcvbnAlert
                            warning={state.errors.zxcvbn.warning}
                            suggestions={state.errors.zxcvbn.suggestions}
                          />
                        </Alert>
                      </Col>
                    </Row>
                    <Row className={"password-row"}>
                      <Col xs={4} md={4} lg={3}>
                        <Label for="password2">Enter New Password Again</Label>
                      </Col>
                      <Col xs={8} md={6} lg={4}>
                        <Input
                          valid={
                            isEmptyOrNull(state.errors.password2) ? null : false
                          }
                          disabled={state.submitting}
                          type="password"
                          id="password2"
                          value={state.credentials.password2}
                          onChange={handleChange}
                          onKeyPress={handleKeyPress}
                        />
                        <FormFeedback>{state.errors.password2}</FormFeedback>
                      </Col>
                      <Col xs={0} md={2} lg={5} />
                    </Row>
                  </FormGroup>
                </Form>
                <Alert
                  color="danger"
                  isOpen={!isEmptyOrNull(state.serverError)}
                >
                  {state.serverError}
                </Alert>
                <Alert color="danger" isOpen={!isEmptyOrNull(state.tokenError)}>
                  {state.tokenError}
                </Alert>
                <Alert color="success" isOpen={state.success === true}>
                  Your new password was set successfully. You can now{" "}
                  <Link to="/user/logon">logon</Link>.
                </Alert>
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  outline={!formValid() || state.success}
                  disabled={
                    !formValid() ||
                    state.success ||
                    state.submitting ||
                    !isEmptyOrNull(state.serverError)
                  }
                >
                  Submit
                </Button>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      <SimpleFooter />
    </div>
  );
};

const ZxcvbnAlert = ({ warning, suggestions }) => {
  return (
    <div>
      {warning}
      {!isEmptyOrNull(warning) ? <br /> : ""}
      {suggestions}
    </div>
  );
};

export default Activate;

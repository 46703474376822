import PropTypes from "prop-types";
import { FaFlag } from "react-icons/fa";
import { Button } from "reactstrap";
import "./DataQueryFlag.css";

export const FLAG_TYPE_CREATE = {
  id: 0,
  className: "query-flag-create",
  title: "Create New Data Query",
};
export const FLAG_TYPE_OPEN = {
  id: 1,
  className: "query-flag-open",
  title: "Open Data Query",
};
export const FLAG_TYPE_ANSWERED = {
  id: 2,
  className: "query-flag-answered",
  title: "Answered Data Query",
};
export const FLAG_TYPE_CLOSED = {
  id: 3,
  className: "query-flag-closed",
  title: "Closed Data Query",
};
export const FLAG_TYPE_IN_PROGRESS = {
  id: 3,
  className: "query-flag-in-progress",
  title: "In Progress Data Query",
};

const DataQueryFlag = ({ flagType, onClick }) => {
  if (flagType) {
    return (
      <Button
        className={flagType.className}
        outline={true}
        onClick={onClick}
        title={flagType.title}
      >
        <FaFlag />
      </Button>
    );
  } else {
    return null;
  }
};

DataQueryFlag.propTypes = {
  flagType: PropTypes.shape({
    id: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
};

export default DataQueryFlag;

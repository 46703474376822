import { Col, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { userLogout } from "../../actions/UserActions";
import AoaLogo from "../../images/aoa_logo_blue.png";
import { toast } from "react-toastify";
import { IoIosRedo } from "react-icons/io";
import SimpleFooter from "../common/SimpleFooter";
import { useEffect } from "react";

const DeclineThankYou = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    toast.info(
      <span>
        <IoIosRedo style={{ fontSize: "2em" }} />
        {}
        <br />
        You will be redirected to the AOANJRR Trials website shortly.
      </span>,
      {
        autoClose: 10000,
        onClose: () => dispatch(userLogout()),
      },
    );
  }, []);

  return (
    <div className={"landing"}>
      <div className="landing-header">
        <img src={AoaLogo} alt="AOA Logo" />
      </div>
      <div className="map-background" style={{ display: "flex" }}>
        <div className="spacer" />
        <Container className="modal-dialog-centered">
          <Col>
            <h2 style={{ marginBottom: 20 }}>Thank you for your time...</h2>
            <h4>
              Your information has been removed from the <br /> Australian
              Orthopaedic Association National Joint Replacement Registry.
            </h4>
          </Col>
        </Container>
      </div>
      <SimpleFooter showLinks={false} />
    </div>
  );
};

export default DeclineThankYou;

import { Fragment, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { getStartCase, isEmptyOrNull } from "../../Utils";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmPatientDetails,
  setPatientField,
} from "../../actions/PatientActions";
import { validatePatientContactDetails } from "./Utils";
import "./ConfirmPatientDetailsModal.css";
import ButtonBar from "../common/ButtonBar";
import SimpleSideSelector from "../common/SimpleSideSelector";
import { getLinkedStudies } from "../../api/Study";
import {
  loadProcedureTypes,
  loadSides,
  loadStudies,
} from "../../actions/CommonActions";
import SimpleProcedureTypeSelector from "../common/SimpleProcedureTypeSelector";

const ConfirmPatientDetailsModal = ({ isOpen }) => {
  const dispatch = useDispatch();

  const patient = useSelector((state) => state.patient);
  const studies = useSelector((state) => state.studies);
  const sides = useSelector((state) => state.sides);
  const procedureTypes = useSelector((state) => state.procedureTypes);

  const [validation, setValidation] = useState(null);
  const [edited, setEdited] = useState({
    mobilePhone: false,
    homePhone: false,
    email: false,
  });
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      page: 1,
      saveError: null,
      selectedStudies: [[]],
      selectedSides: [],
      selectedProcedureTypes: [],
      selectedProcedures: [],
      selectedHospitals: [],
      selectedSurgeons: [],
      availableStudies: [],
      surgeon: null,
      hospital: null,
      duplicateProc: false,
      allAvailableStudies: [],
      newlySelectedStudies: [],
      updatedProcedureTypeNames: [],
      invalidProcedureTypeNames: [],
      procedureTypeIds: [],
      updatedProcedureTypeIndex: null,
      updatedProcedureDetails: [],
      studyId: null,
    },
  );

  useEffect(() => {
    sides.length === 0 && dispatch(loadSides());
    procedureTypes.length === 0 && dispatch(loadProcedureTypes());
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useEffect(() => {
    setValidation(validatePatientContactDetails(patient));
  }, [patient]);

  useEffect(() => {
    setSideAndProcedureType();
  }, [sides.length, procedureTypes.length]);

  useEffect(() => {
    updateAvailableStudies(state.studyId);
  }, [state.updatedProcedureTypeNames]);

  const updatePatientDetail = (field, value) => {
    if (field === "mobilePhone" || field === "homePhone") {
      if (value != null && value.replace(/ /g, "").length > 10) {
        return;
      }
    }
    dispatch(setPatientField({ [field]: value }));
    setEdited((edited) => ({ ...edited, [field]: true }));
    setState({ saveError: null });
  };

  const checkIfDuplicateProcs = (procList) => {
    let result = [];
    let itemKey = null;
    const map = new Map();
    for (const item of procList) {
      itemKey = `'${item.side.id} ${item.procedureType.id}'`;
      if (!map.has(itemKey)) {
        map.set(itemKey, true); // set any value to Map
        result.push({
          id: itemKey,
          side: item.side,
          procedureType: item.procedureType,
        });
      }
    }
    return result.length !== procList.length;
  };

  const updateAvailableStudies = (value) => {
    let procedureTypeName = null;
    let _invalidProcedureTypeNames = [];
    _invalidProcedureTypeNames = state.invalidProcedureTypeNames;
    if (state.hospital == null || state.selectedProcedureTypes.length === 0) {
      setState({ allAvailableStudies: [] });
    } else {
      let procedureTypeIds = [];
      procedureTypeIds.push(value);
      getLinkedStudies(
        state.surgeon === null ? null : state.surgeon.id,
        state.hospital.id,
        procedureTypeIds,
        true,
      ).then((response) => {
        let newSelected = state.selectedStudies;
        // If what we get back from the API matches the current selection exactly, don't clear the studies selection
        if (isEmptyOrNull(response.data)) {
          procedureTypeName =
            state.selectedProcedureTypes[state.updatedProcedureTypeIndex]
              .displayName;
          _invalidProcedureTypeNames.push({
            procId:
              state.selectedProcedures[state.updatedProcedureTypeIndex].procId,
            ptName: procedureTypeName,
          });
        }
        if (!isEmptyOrNull(newSelected) && !isEmptyOrNull(response.data)) {
          let selectedStudyIds = [];
          state.selectedStudies[state.updatedProcedureTypeIndex].forEach((s) =>
            selectedStudyIds.push(s.studyId),
          );
          let responseStudyIds = [];
          response.data.forEach((study) => responseStudyIds.push(study.id));
          let checkValues = (arr, target) =>
            target.every((v) => arr.includes(v));
          if (checkValues(responseStudyIds, selectedStudyIds)) {
            _invalidProcedureTypeNames = _invalidProcedureTypeNames.filter(
              (obj) =>
                obj.procId !==
                state.selectedProcedures[state.updatedProcedureTypeIndex]
                  .procId,
            );
          } else {
            _invalidProcedureTypeNames.push({
              procId:
                state.selectedProcedures[state.updatedProcedureTypeIndex]
                  .procId,
              ptName:
                state.selectedProcedureTypes[state.updatedProcedureTypeIndex]
                  .displayName,
            });
          }
        }
        setState({
          allAvailableStudies: response.data,
          newlySelectedStudies: newSelected,
          invalidProcedureTypeNames: _invalidProcedureTypeNames,
          studyId: null,
        });
      });
    }
  };

  const nextPage = () => {
    let dupProc = false;
    if (checkIfDuplicateProcs(state.selectedProcedures)) {
      dupProc = true;
    }
    if (state.page === 1 && state.selectedProcedures.length === 0) {
      setState({
        page: 3,
        duplicateProc: dupProc,
      });
    } else {
      setState({
        page: state.page + 1,
        duplicateProc: dupProc,
      });
    }
  };

  const previousPage = () => {
    if (state.page === 3 && state.selectedProcedures.length === 0) {
      setState({
        page: 1,
        updatedProcedureDetails: [],
      });
    } else {
      if (state.page === 2 && !isEmptyOrNull(state.invalidProcedureTypeNames)) {
        dispatch(setPatientField({ updateProcedures: [] }));
        setState({
          page: state.page - 1,
        });
      } else {
        setState({ page: state.page - 1 });
      }
    }
  };

  const saveDetails = () => {
    // clicked on button, but it's "disabled" let's display all valid error fields on the page.
    if (!validation || (validation && !validation.okToProceed)) {
      let _edited = edited;
      Object.keys(_edited).forEach((v) => (_edited[v] = true));
      setEdited(_edited);
    } else {
      dispatch(
        confirmPatientDetails({ data: patient, callback: handleSaveError }),
      );
    }
  };

  const handleSaveError = (message) => {
    setState({ saveError: message });
  };

  const getSurgeonDisplayName = (surgeon) => {
    return surgeon == null
      ? "Unknown"
      : `Dr ${surgeon.lastName}, ${surgeon.firstName}`;
  };

  const setProcedureType = (value, j) => {
    let dupProc = false;
    let updatedPtNames = [];
    let updatedProcDetails = [];
    let updatedProc;
    let index = j;
    let selSurgeon = state.selectedSurgeons[j];
    let selHospital = state.selectedHospitals[j];
    state.selectedProcedureTypes[j] = value;
    let updatedProcedures = state.selectedProcedures;
    updatedProcedures[j] = {
      id: "'" + updatedProcedures[j].side.id + value.id + "'",
      side: updatedProcedures[j].side,
      procedureType: value,
      procId: updatedProcedures[j].procId,
    };
    updatedProcDetails = state.updatedProcedureDetails;

    if (checkIfDuplicateProcs(updatedProcedures)) {
      dupProc = true;
    }
    updatedProc = {
      procId: state.selectedProcedures[j].procId,
      newSideId: state.selectedSides[j].id,
      newProcedureTypeId: value.id,
    };
    updatedProcDetails[j] = updatedProc;
    dispatch(setPatientField({ updateProcedures: updatedProcDetails }));
    updatedPtNames.push(value.displayName);
    if (
      j !== null &&
      updatedProcedures.length === state.selectedProcedures.length
    ) {
      setState({
        selectedProcedureTypes: state.selectedProcedureTypes,
        duplicateProc: dupProc,
        surgeon: selSurgeon,
        hospital: selHospital,
        updatedProcedureTypeNames: updatedPtNames,
        updatedProcedureTypeIndex: index,
        updatedProcedureDetails: updatedProcDetails,
        studyId: value.id,
      });
    }
  };

  const setSide = (value, j) => {
    let dupProc = false;
    let updatedProcDetails = [];
    let updatedProc;
    state.selectedSides[j] = value;
    updatedProcDetails = state.updatedProcedureDetails;
    let updatedProcedures = state.selectedProcedures;
    updatedProcedures[j] = {
      id: `'${value.id} ${updatedProcedures[j].procedureType.id}'`,
      side: value,
      procedureType: updatedProcedures[j].procedureType,
      procId: updatedProcedures[j].procId,
    };
    if (checkIfDuplicateProcs(updatedProcedures)) {
      dupProc = true;
    }
    updatedProc = {
      procId: state.selectedProcedures[j].procId,
      newSideId: value.id,
      newProcedureTypeId: state.selectedProcedureTypes[j].id,
    };
    updatedProcDetails[j] = updatedProc;
    dispatch(setPatientField({ updateProcedures: updatedProcDetails }));
    if (
      j !== null &&
      updatedProcedures.length === state.selectedProcedures.length
    ) {
      setState({
        selectedSides: state.selectedSides,
        duplicateProc: dupProc,
        updatedProcedureDetails: updatedProcDetails,
      });
    }
  };

  const renderMultipleProcedures = () => {
    let invalidNames = "";
    if (
      !isEmptyOrNull(state.invalidProcedureTypeNames) &&
      state.invalidProcedureTypeNames.length > 0
    ) {
      state.invalidProcedureTypeNames.map((invalidProcedureType) => {
        invalidNames = invalidNames.concat(
          getStartCase(invalidProcedureType.ptName),
        );
        invalidNames = invalidNames.concat(",");
      });
      invalidNames = invalidNames.replace(/(^,)|(,$)/g, "");
    }
    return (
      <div>
        {state.selectedSides.map((i, j) => {
          return (
            <span key={i.name + j}>
              <div>
                <Row className={"my-3"}>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <SimpleSideSelector
                      value={i}
                      onChange={(value) => setSide(value, j)}
                      clearable={false}
                      searchable={true}
                    />
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <SimpleProcedureTypeSelector
                      value={state.selectedProcedureTypes[j]}
                      onChange={(value) => setProcedureType(value, j)}
                      clearable={false}
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <span>
                      {" "}
                      at <b>{state.selectedHospitals[j].name}</b>
                      <br /> by{" "}
                      <b> {getSurgeonDisplayName(state.selectedSurgeons[j])}</b>
                    </span>
                  </Col>
                </Row>
              </div>
            </span>
          );
        })}
        <p>
          If any information has been recorded incorrectly please contact the
          Registry Study Coordinator to get it updated (
          <a href="tel:1800068419">1800 068 419</a>,{" "}
          <a href="mailto:admin@aoanjrr.org.au">admin@aoanjrr.org.au</a>).
        </p>
        {state.duplicateProc && (
          <Alert style={{ marginTop: "1em" }} color={"danger"}>
            {
              "You cannot register two procedures with the same procedure type and side. Please contact the AOANJRR on  "
            }
            <span>
              {" "}
              <a href="tel:1800068419">1800 068 419</a>{" "}
            </span>
            ,
            <span>
              {" "}
              <a href="mailto:admin@aoanjrr.org.au">admin@aoanjrr.org.au</a>
            </span>
          </Alert>
        )}
        {!isEmptyOrNull(state.invalidProcedureTypeNames) &&
          state.invalidProcedureTypeNames.length > 0 && (
            <Alert style={{ marginTop: "1em" }} color={"danger"}>
              {"You cannot change your procedure type to"}
              <span>
                {" "}
                <b> {invalidNames}</b>{" "}
              </span>{" "}
              {
                " as it will alter the studies your hospital and surgeon have enrolled you in. Please contact the AOANJRR on "
              }
              <span>
                {" "}
                <a href="tel:1800068419">1800 068 419</a>{" "}
              </span>
              ,
              <span>
                {" "}
                <a href="mailto:admin@aoanjrr.org.au">admin@aoanjrr.org.au</a>
              </span>
            </Alert>
          )}
      </div>
    );
  };

  const setSideAndProcedureType = () => {
    let _selectedSides = [];
    let _selectedProcedureTypes = [];
    let _selectedProcedures = [];
    let _selectedHospitals = [];
    let _selectedSurgeons = [];
    let _selectedStudies = [];
    let procs = patient.procedures.filter(
      (procedure) => !procedure.hasCollection && !procedure.isMatched,
    );
    let selectedSurgeon = null;
    let selectedHospital = null;
    if (
      !isEmptyOrNull(sides) &&
      !isEmptyOrNull(procedureTypes) &&
      _selectedProcedures.length === 0
    ) {
      procs.forEach((proc, j) => {
        _selectedHospitals.push(proc.hospital);
        _selectedSurgeons.push(proc.surgeon);
        _selectedStudies[j] = [];
        proc.studies.forEach((s) => _selectedStudies[j].push(s));
        _selectedSides.push(sides.filter((s) => s.name === proc.side)[0]);
        _selectedProcedureTypes.push(
          procedureTypes.filter((s) => s.name === proc.procedureType)[0],
        );
        _selectedProcedures.push({
          procId: proc.id,
          id:
            "'" +
            sides.filter((s) => s.name === proc.side)[0].id +
            procedureTypes.filter((j) => j.name === proc.procedureType)[0].id +
            "'",
          side: sides.filter((s) => s.name === proc.side)[0],
          procedureType: procedureTypes.filter(
            (s) => s.name === proc.procedureType,
          )[0],
        });
      });
      setState({
        surgeon: selectedSurgeon,
        hospital: selectedHospital,
        allAvailableStudies: _selectedStudies,
        selectedStudies: _selectedStudies,
        selectedSides: _selectedSides,
        selectedHospitals: _selectedHospitals,
        selectedProcedureTypes: _selectedProcedureTypes,
        selectedSurgeons: _selectedSurgeons,
        selectedProcedures: _selectedProcedures,
      });
    }
  };

  const renderPatientProcedures = () => {
    let unmatchedProcs = [];
    patient.procedures.forEach((procedure) => {
      // We ignore procedures that have a procedure collection, as we assume that these are 'old'
      if (!procedure.hasCollection && !procedure.isMatched) {
        // See if our data structure already has an entry with the same surgeon & hospital
        let index = unmatchedProcs.findIndex(
          (value) =>
            value.hospital === procedure.hospital.name &&
            value.surgeon === getSurgeonDisplayName(procedure.surgeon),
        );
        if (index !== -1) {
          // If so, add the procedureType/side combo into the existing procedure (so long as it isn't the exact same one)
          if (
            !unmatchedProcs[index].procedureTypes.includes(
              `${getStartCase(procedure.side)} ${getStartCase(
                procedure.procedureType,
              )}`,
            )
          ) {
            unmatchedProcs[index].procedureTypes.push(
              `${getStartCase(procedure.side)} ${getStartCase(
                procedure.procedureType,
              )}`,
            );
          }
        } else {
          // This is a new hospital/surgeon combination
          let addThis = {};
          addThis.hospital = procedure.hospital.name;
          addThis.surgeon = getSurgeonDisplayName(procedure.surgeon);
          addThis.procedureTypes = [];
          addThis.procedureTypes[0] = `${getStartCase(
            procedure.side,
          )} ${getStartCase(procedure.procedureType)}`;
          unmatchedProcs.push(addThis);
        }
      }
    });
    if (unmatchedProcs.length === 0) {
      return null;
    }
    return (
      <div>
        <h5 className={"welcome"}>Procedure Details</h5>
        {unmatchedProcs.length > 0 && (
          <Fragment>
            <span>
              Please check and update your<b> upcoming procedures</b>, then
              click the <i> Next </i> button.
            </span>
            {renderMultipleProcedures()}
          </Fragment>
        )}
      </div>
    );
  };

  const getButtons = () => {
    const buttons = [];
    if (state.page === 1) {
      buttons.push(
        <Button color={"primary"} onClick={nextPage}>
          Next
        </Button>,
      );
    } else if (state.page === 2) {
      buttons.push(
        <Button
          color={"primary"}
          onClick={nextPage}
          disabled={
            (state.duplicateProc && state.page === 2) ||
            (!isEmptyOrNull(state.invalidProcedureTypeNames) &&
              state.invalidProcedureTypeNames.length > 0 &&
              state.page === 2)
          }
        >
          Next
        </Button>,
      );
      buttons.push(<Button onClick={previousPage}>Previous</Button>);
    } else if (state.page === 3) {
      buttons.push(
        <Button
          color={"primary"}
          outline={!validation || (validation && !validation.okToProceed)}
          onClick={saveDetails}
        >
          Complete
        </Button>,
        <Button onClick={previousPage}>Previous</Button>,
      );
    }
    return buttons;
  };

  return (
    <Modal
      className={"confirm-patient-details-modal"}
      isOpen={isOpen}
      size={"lg"}
    >
      <ModalBody>
        {state.page === 1 && (
          <div>
            <div className={"blurb"}>
              <h5 className={"welcome"}>Welcome</h5>
              <p>
                Please review your personal and procedure details below to
                ensure they are correct.
              </p>
              <p>
                If any information has been recorded incorrectly please contact
                the Registry Study Coordinator to get it updated (
                <a href="tel:1800068419">1800 068 419</a>,{" "}
                <a href="mailto:admin@aoanjrr.org.au">admin@aoanjrr.org.au</a>).
              </p>
              <p>
                If all details are correct please select ‘Next’ to continue.
              </p>
            </div>
            <div>
              <b>Personal Details</b>
              <ul>
                <li>
                  Your name is{" "}
                  <b>
                    {patient.firstName}
                    {isEmptyOrNull(patient.middleNames)
                      ? ""
                      : ` ${patient.middleNames}`}
                    {` ${patient.lastName}`}
                  </b>
                </li>
                <li>
                  You were born on the{" "}
                  <b>
                    {Moment(patient.dateOfBirth).format("Do of MMMM, YYYY")}
                  </b>
                </li>
                <li>
                  Your current postcode is <b>{patient.postcode}</b>
                </li>
              </ul>
            </div>
          </div>
        )}
        {state.page === 2 && renderPatientProcedures()}
        {state.page === 3 && (
          <div>
            <div className={"blurb"}>
              <h5 className={"welcome"}>Welcome</h5>
              <p>
                We require at least one method of contact so we can send you
                reminders to complete your questionnaires.
              </p>
              <p>
                Please check the contact details recorded below, please update
                them if anything is incorrect or missing.
              </p>
              <p>
                Please note you may record the contact details of a family
                member or trusted friend if you would like them to receive
                reminders on your behalf to assist you.
              </p>
              <p>
                If all details are correct please select ‘Complete’ to save
                details. Please select ‘Start Questions Now’ on the next page to
                complete the questionnaires.
              </p>
            </div>
            <FormGroup row>
              <Label xs={5} sm={4} for="mobilephone" className={"form-label"}>
                Mobile Phone
              </Label>
              <Col xs={7} sm={6}>
                <Input
                  type={"text"}
                  id="mobilephone"
                  placeholder="04## ### ###"
                  onChange={(event) =>
                    updatePatientDetail("mobilePhone", event.target.value)
                  }
                  value={patient.mobilePhone || ""}
                  className={
                    (edited.homePhone || edited.mobilePhone || edited.email) &&
                    validation &&
                    !validation.mobilePhone.ok
                      ? "is-invalid"
                      : null
                  }
                  pattern={"[0-9]*"}
                  inputMode={"numeric"}
                />
                <FormFeedback>
                  {validation && validation.mobilePhone.message}
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label xs={5} sm={4} for="homephone" className={"form-label"}>
                Home Phone
              </Label>
              <Col xs={7} sm={6}>
                <Input
                  type={"text"}
                  id="homephone"
                  placeholder="0# #### ####"
                  onChange={(event) =>
                    updatePatientDetail("homePhone", event.target.value)
                  }
                  value={patient.homePhone || ""}
                  className={
                    (edited.homePhone || edited.mobilePhone || edited.email) &&
                    validation &&
                    !validation.homePhone.ok
                      ? "is-invalid"
                      : null
                  }
                  pattern={"[0-9]*"}
                  inputMode={"numeric"}
                />
                <FormFeedback>
                  {validation && validation.homePhone.message}
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label xs={12} sm={4} for="email" className={"form-label"}>
                Email
              </Label>
              <Col xs={12} sm={8}>
                <Input
                  type={"text"}
                  id="email"
                  placeholder="_______@_____.___"
                  onChange={(event) =>
                    updatePatientDetail("email", event.target.value)
                  }
                  value={patient.email || ""}
                  className={
                    (edited.homePhone || edited.mobilePhone || edited.email) &&
                    validation &&
                    !validation.email.ok
                      ? "is-invalid"
                      : null
                  }
                />
                <FormFeedback>
                  {validation && validation.email.message}
                </FormFeedback>
              </Col>
            </FormGroup>
          </div>
        )}
        <Alert color={"danger"} isOpen={!isEmptyOrNull(state.saveError)}>
          {state.saveError}
        </Alert>
        <ButtonBar buttons={getButtons()} reversed={true} />
      </ModalBody>
    </Modal>
  );
};

ConfirmPatientDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default ConfirmPatientDetailsModal;

import { Button, Col, Label, Row, Table } from "reactstrap";
import { FaLink, FaUnlink } from "react-icons/fa";
import Search from "./Search";
import "./Linking.css";
import PropTypes from "prop-types";

const Linking = ({
  links = [],
  readOnly = true,
  publicPrivateStatus = null,
  includeState = null,
  onLinkChange,
}) => {
  const handleUnlink = (stakeholderGroup) => {
    let filtered = links.filter(
      (current) => current.id !== stakeholderGroup.id,
    );
    onLinkChange(filtered);
  };

  const handleLink = (stakeholderGroup) => {
    let _links = links.slice();
    _links.push(stakeholderGroup);
    onLinkChange(_links);
  };

  return (
    <div className={"stakeholder-group-linking"}>
      <Row>
        <Col sm={3} md={3}>
          <Label className={"section-text"}>Stakeholder Groups</Label>
        </Col>
        <Col sm={9} md={9}>
          <Table striped>
            <tbody>
              {links.length > 0 ? (
                links.map((stakeholderGroup) => (
                  <tr key={`tr-${stakeholderGroup.id}`}>
                    <td key={`td1-${stakeholderGroup.id}`}>
                      <span className={"stakeholder-group-text"}>
                        {stakeholderGroup.name}
                      </span>
                    </td>
                    <td key={`td2-${stakeholderGroup.id}`}>
                      {!readOnly && (
                        <span className={"unlink-button"}>
                          <Button
                            className={"action-button"}
                            outline
                            title={"Unlink user from stakeholder group"}
                            onClick={() => handleUnlink(stakeholderGroup)}
                          >
                            <FaUnlink />
                          </Button>
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <em>No linked stakeholder groups set</em>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {!readOnly && (
        <div>
          <hr />
          <Row>
            <Col sm={12}>
              <Label className={"section-text"}>
                Available Stakeholder Groups
              </Label>
            </Col>
          </Row>
          <Search
            itemsCountPerPage={5}
            excludeIds={links.map(function (stakeholderGroup) {
              return stakeholderGroup.id;
            })}
            actionButtons={[
              {
                icon: <FaLink onClick={() => handleLink} />,
                title: "Link user to stakeholder group",
                action: handleLink,
              },
            ]}
            publicPrivateStatus={publicPrivateStatus}
            includeState={includeState}
          />
        </div>
      )}
    </div>
  );
};

Linking.propTypes = {
  onLinkChange: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  publicPrivateStatus: PropTypes.string,
  includeState: PropTypes.object,
};

export default Linking;

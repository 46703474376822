import {
  Alert,
  Button,
  Col,
  FormFeedback,
  FormText,
  Input,
  Row,
} from "reactstrap";
import "./PatientDetailsModal.css";
import PropTypes from "prop-types";
import {
  EDIT,
  isAoaUser,
  isEmptyOrNull,
  isFollowUpUser,
  isUserInAdminGroup,
  LAUNCH_DATE,
  validateAustralianPostcode,
  validateEmailAddress,
  validateMobile,
  validatePhone,
  validName,
  VIEW,
} from "../../../Utils";
import {
  editPatient,
  openPatient,
  sendProcedureEmailLogonLink,
  sendProcedureSmsLogonLink,
} from "../../../api/Patient";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import "moment/locale/en-au";
import { useDispatch, useSelector } from "react-redux";
import DatePickerIosInput from "../../common/DatePickerIosInput";
import PatientDeleteModal from "./PatientDeleteModal";
import { getPatientMaximumNameLength } from "../../patient/Utils";
import SendLogonLinkModal from "./SendLogonLinkModal";
import { getDataQueryFlags } from "../../data-query/Utils";
import CreateDataQuery, { TYPE } from "../../data-query/CreateDataQuery";
import ViewDataQuery from "../../data-query/ViewDataQuery";
import { finishLoading, startLoading } from "../../../actions/CommonActions";
import ButtonBar from "../../common/ButtonBar";
import ImpersonationTokenRequest from "./ImpersonationTokenRequest";
import { toast } from "react-toastify";
import PatientMergeModal from "./PatientMergeModal";
import { useEffect, useReducer } from "react";

const dateFormat = "YYYY-MM-DD";
const dateFormatTwo = "DD/MM/YYYY";

const PatientDetailsModal = ({
  hideActionButtons = false,
  patientId,
  operation,
  onConfirm,
  onExit,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      modalPatientId: "",
      firstName: "",
      middleNames: "",
      lastName: "",
      dateOfDeath: "",
      dateOfBirth: "",
      deceased: false,
      postcode: "",
      homePhone: "",
      mobilePhone: "",
      email: "",
      dataQueries: [],
      error: "",
      checked: "",
      currentlySubmitting: false,
      loadPatientError: "",
      validation: null,
      mergeRequired: false,
      mergeApproved: false,
      showMergeModal: false,
      data: {},
      patient: null,
      mergedPatientId: "",
      registeredByName: null,
      registeredByEmail: null,
      deleteActive: false,
      reason: "",
      showDeleteDialog: false,
      showSendEmailDialog: false,
      showSendSmsDialog: false,
      errorForLoginLinkModal: null,
      createDataQuery: null,
      viewDataQueryId: null,
      edited: false,
      impersonatePatient: false,
      procedureIdAndStudyIds: [],
    },
  );

  useEffect(() => {
    loadData();
  }, []);

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (event.target === "mobilePhone" || event.target === "homePhone") {
      if (value != null && value.length > 10) {
        return;
      }
    }
    setState({ [id]: value, edited: true });
  };

  const handlePostcodeChange = (event) => {
    const re = /^[0-9\b]+$/;
    const { id, value } = event.target;
    // if value is not blank, then test the regex
    if (event.target.value === "" || re.test(event.target.value)) {
      setState({ [id]: value, edited: true });
    }
  };

  const handlePhoneChange = (event) => {
    const { id, value } = event.target;
    // if value is not blank, and input is only digits
    if (event.target.value === "" || !isNaN(value)) {
      setState({ [id]: value, edited: true });
    }
  };

  const handleDeceased = () => {
    setState({
      deceased: !state.deceased,
      dateOfDeath: state.deceased ? null : state.dateOfDeath,
      edited: true,
    });
  };

  const handleSubmit = () => {
    setState({ currentlySubmitting: true });
    const payload = {
      firstName: state.firstName,
      middleNames: state.middleNames,
      lastName: state.lastName,
      dateOfBirth: state.dateOfBirth,
      postcode: state.postcode,
      deceased: state.deceased,
      dateOfDeath: state.dateOfDeath,
      homePhone: state.homePhone,
      mobilePhone: state.mobilePhone,
      email: state.email,
      mergeRequired: state.mergeRequired,
      mergedPatientId: state.mergedPatientId,
    };
    if (operation === VIEW) {
      openPatient(patientId)
        .then((response) => {
          setState({
            firstName: response.data.firstName,
            middleNames: response.data.middleNames,
            lastName: response.data.lastName,
            dateOfBirth: response.data.dateOfBirth,
            postcode: response.data.postcode,
            email: response.data.email,
            homePhone: response.data.homePhone,
            mobilePhone: response.data.mobilePhone,
            deceased:
              response.data.deceased == null ? false : response.data.deceased,
            dateOfDeath: response.data.dateOfDeath,
            mergeRequired: response.mergeRequired,
            modalPatientId: patientId,
          });
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({ error: "Error loading the patient" });
          } else {
            setState({ error: error.response.data });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    } else if (operation === EDIT) {
      editPatient(patientId, payload)
        .then((response) => {
          if (response.data.mergeRequired) {
            setState({
              currentlySubmitting: false,
              mergeRequired: response.data.mergeRequired,
              mergeApproved: response.data.mergeApproved,
              patientId: response.data.patientId,
              mergedPatientId: response.data.patient.mergedPatientId,
              modalPatientId: patientId,
              patient: response.data.patient,
              open: false,
            });
          } else {
            setState({
              currentlySubmitting: false,
              mergeRequired: false,
              mergeApproved: false,
              isOpen: false,
              open: false,
              modalPatientId: patientId,
              showModal: false,
              validTransport: false,
            });
            onConfirm("Patient update successful");
          }
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({ error: "Error updating the patient" });
          } else {
            setState({ error: error.response.data });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    }
  };

  const buildDate = (value) => {
    let dateVal = Moment.utc(value, dateFormat, true);
    if (dateVal.isValid()) {
      return dateVal.toDate();
    } else {
      return null;
    }
  };

  const handleDateChange = (dob) => {
    let dateVal = Moment(dob, dateFormatTwo, true).format(dateFormat);
    setState({ dateOfBirth: dob == null ? null : dateVal, edited: true });
  };

  const handleDateOfDeathChange = (dod) => {
    let dateVal = Moment(dod, dateFormatTwo, true).format(dateFormat);
    setState({ dateOfDeath: dod == null ? null : dateVal, edited: true });
  };

  const confirmMerge = (message) => {
    setState({ mergeRequired: false });
    onConfirm(message);
  };

  const isDateOfBirthFormatValid = () => {
    let dateVal = Moment(state.dateOfBirth, dateFormat).isValid();
    let dateValAnotherFormat = Moment(
      state.dateOfBirth,
      dateFormatTwo,
    ).isValid();
    return dateVal || dateValAnotherFormat;
  };

  const isDateofDeathFormatValid = () => {
    return (
      Moment(state.dateOfDeath, dateFormat).isValid() ||
      Moment(state.dateOfDeath, dateFormatTwo).isValid()
    );
  };

  const validateDob = () => {
    let now = Moment().add(1, "hour");
    let dateVal = Moment(state.dateOfBirth, dateFormat, true);
    if (state.dateOfBirth !== null && dateVal.isValid()) {
      let diff = now.diff(state.dateOfBirth, "years");
      return diff >= 16 && diff <= 118;
    } else {
      return false;
    }
  };

  const isDateOfDeathFutureDate = () => {
    let momentDodObj = Moment(state.dateOfDeath, dateFormat);
    let now = Moment().format(dateFormat);
    now = Moment(now, dateFormat, true);
    return (
      momentDodObj.isValid() && state.deceased && !momentDodObj.isAfter(now)
    );
  };

  const isDateOfDeathBeforeLaunchDate = () => {
    let dateOfDeathToBeValidated = state.dateOfDeath;
    let momentDodObj = Moment(dateOfDeathToBeValidated, dateFormat);
    let goLiveDate = Moment(LAUNCH_DATE, dateFormat);
    if (
      (momentDodObj.isValid() ||
        Moment(dateOfDeathToBeValidated, dateFormat).isValid()) &&
      state.deceased
    ) {
      return !momentDodObj.isBefore(goLiveDate);
    } else {
      return false;
    }
  };

  const validateDod = () => {
    let dateOfDeathToBeValidated = state.dateOfDeath;
    let momentDodObj = Moment(dateOfDeathToBeValidated, dateFormat);
    if (
      (momentDodObj.isValid() ||
        Moment(dateOfDeathToBeValidated, dateFormatTwo).isValid()) &&
      state.deceased
    ) {
      if (state.dateOfBirth !== null && dateOfDeathToBeValidated !== null) {
        let dob = Moment(state.dateOfBirth, dateFormat);
        return (
          momentDodObj.isAfter(dob) ||
          Moment(dateOfDeathToBeValidated, dateFormatTwo).isAfter(dob)
        );
      } else return dateOfDeathToBeValidated !== null;
    } else {
      return false;
    }
  };

  const loadData = () => {
    if (operation === EDIT || operation === VIEW) {
      if (patientId !== null) {
        dispatch(startLoading());
        setState({ loadProcedureError: "" });
        openPatient(patientId)
          .then((response) => {
            setState({
              firstName: response.data.firstName,
              middleNames: response.data.middleNames,
              lastName: response.data.lastName,
              dateOfBirth: response.data.dateOfBirth,
              postcode: response.data.postcode,
              email: response.data.email,
              homePhone: response.data.homePhone,
              mobilePhone: response.data.mobilePhone,
              deceased:
                response.data.deceased == null ? false : response.data.deceased,
              dateOfDeath: response.data.dateOfDeath,
              mergeRequired: response.mergeRequired,
              modalPatientId: patientId,
              registeredByName: response.data.registeredByName,
              registeredByEmail: response.data.registeredByEmail,
              dataQueries: response.data.dataQueries,
              procedureIdAndStudyIds: response.data.procedureIdAndStudyIds,
            });
          })
          .catch((error) => {
            let errorMessage = "Error loading patient.";
            if (!isEmptyOrNull(error?.response?.data)) {
              errorMessage = `${errorMessage} ${error.response.data}`;
            }
            setState({ loadProcedureError: errorMessage });
          })
          .finally(() => dispatch(finishLoading()));
      }
    }
  };

  const patientHasAtLeastOneContact = () => {
    return (
      !isEmptyOrNull(state.homePhone) ||
      !isEmptyOrNull(state.mobilePhone) ||
      !isEmptyOrNull(state.email)
    );
  };

  const formValid = () => {
    let isValidEmail = isEmptyOrNull(state.email)
      ? true
      : validateEmailAddress(state.email);
    let isValidFirstName =
      !isEmptyOrNull(state.firstName) && validName(state.firstName);
    let isValidLastName =
      !isEmptyOrNull(state.lastName) && validName(state.lastName);
    let isValidMiddleName = isEmptyOrNull(state.middleNames)
      ? true
      : validName(state.middleNames);
    let isValidDob = !isEmptyOrNull(state.dateOfBirth) && validateDob();
    let isValidPostCode =
      !isEmptyOrNull(state.postcode) &&
      validateAustralianPostcode(state.postcode);
    let isValidHomePhone = isEmptyOrNull(state.homePhone)
      ? true
      : validatePhone(state.homePhone);
    let isValidMobilePhone = isEmptyOrNull(state.mobilePhone)
      ? true
      : validateMobile(state.mobilePhone);
    let isValidDod =
      isEmptyOrNull(state.dateOfDeath) ||
      (state.deceased &&
        !isEmptyOrNull(state.dateOfDeath) &&
        validateDod() &&
        isDateOfDeathFutureDate() &&
        isDateOfDeathBeforeLaunchDate());
    return (
      isValidFirstName &&
      isValidMiddleName &&
      isValidLastName &&
      isValidDob &&
      isValidPostCode &&
      isValidHomePhone &&
      isValidMobilePhone &&
      isValidEmail &&
      isValidDod
    );
  };

  const handleLogonUrlCopied = () => {
    setState({ impersonatePatient: false });
    toast.success("Logon URL copied to clipboard");
  };

  const sendLink = (patientId, procedureId, emailOrSms) => {
    dispatch(startLoading());
    const sendProcedureLogonLink =
      emailOrSms === "email"
        ? sendProcedureEmailLogonLink
        : sendProcedureSmsLogonLink;
    sendProcedureLogonLink(
      patientId,
      procedureId,
      emailOrSms === "email" ? state.email : state.mobilePhone,
    )
      .then((response) => {
        if (response.data) {
          onConfirm("Logon link sent");
        } else {
          setState({ error: `Error sending logon link` });
        }
      })
      .catch((error) => {
        if (!isEmptyOrNull(error?.response?.data)) {
          setState({ errorForLoginLinkModal: error.response.data });
        } else {
          setState({ error: "An error occurred while sending the logon link" });
        }
      })
      .finally(() => {
        setState({ currentlySubmitting: false });
        dispatch(finishLoading());
      });
  };

  const getButtonRow = () => {
    const buttons = [];
    if (hideActionButtons) {
      return <ButtonBar buttons={buttons} />;
    }
    let emailValid =
      !isEmptyOrNull(state.email) && validateEmailAddress(state.email);
    let mobileValid =
      !isEmptyOrNull(state.mobilePhone) && validateMobile(state.mobilePhone);
    let sendLinkTransportMode = {
      emailValid: emailValid,
      mobileValid: mobileValid,
    };
    if (isAoaUser(user)) {
      if (isEmptyOrNull(state.loadPatientError)) {
        buttons.push(
          <Button
            color="primary"
            disabled={!formValid() && !state.currentlySubmitting}
            onClick={handleSubmit}
          >
            Save
          </Button>,
          <Button
            color={"primary"}
            disabled={!emailValid && !mobileValid}
            onClick={() =>
              setState({
                showSendEmailDialog: true,
                validTransport: sendLinkTransportMode,
              })
            }
            title={"Send the patient their direct logon link"}
          >
            Send Logon Link
          </Button>,
          <Button
            color={"primary"}
            onClick={() => setState({ showMergeModal: true })}
          >
            Merge
          </Button>,
          <Button
            color={"primary"}
            onClick={() => setState({ impersonatePatient: true })}
          >
            Impersonate Patient
          </Button>,
          <Button
            color={"danger"}
            onClick={() => setState({ showDeleteDialog: true })}
            title={"Hard delete patient"}
          >
            Hard Delete
          </Button>,
          <Button onClick={onExit}>Cancel</Button>,
        );
      } // End load patient error // End else
    } else {
      if (isFollowUpUser(user)) {
        if (isEmptyOrNull(state.loadPatientError)) {
          buttons.push(
            <Button
              color={"primary"}
              disabled={!formValid() && !state.currentlySubmitting}
              onClick={handleSubmit}
            >
              Save
            </Button>,
            <Button
              color={"primary"}
              disabled={!emailValid && !mobileValid}
              onClick={() =>
                setState({
                  showSendEmailDialog: true,
                  validTransport: sendLinkTransportMode,
                })
              }
              title={"Send the patient their direct logon link"}
            >
              Send Logon Link
            </Button>,
            <Button onClick={onExit}>Cancel</Button>,
          );
        }
      } else {
        if (operation !== VIEW && isEmptyOrNull(state.loadPatientError)) {
          buttons.push(
            <Button
              color={"primary"}
              disabled={!formValid() && !state.currentlySubmitting}
              onClick={handleSubmit}
            >
              Save
            </Button>,
          );
        }
        buttons.push(<Button onClick={onExit}>Cancel</Button>);
      }
    }
    return <ButtonBar buttons={buttons} />;
  };

  const getDataQueryFlagColumn = (fieldName, fieldValue) => {
    return getDataQueryFlags(
      state.dataQueries,
      fieldName,
      fieldValue,
      user,
      state.edited,
      (id) => setState({ viewDataQueryId: id }),
      (fieldName, fieldValue) =>
        setState({
          createDataQuery: { fieldName: fieldName, fieldValue: fieldValue },
        }),
    );
  };

  let dateOfBirthDb =
    state.dateOfBirth !== null ? buildDate(state.dateOfBirth) : null;
  let dateOfDeathDb =
    state.dateOfDeath !== null ? buildDate(state.dateOfDeath) : null;

  return (
    <div className={"patient-details-modal"}>
      <div className={"field-row"}>
        <em>Note: Fields marked with an * are required</em>
      </div>
      <Row className={"my-3"}>
        <Col xs={6} sm={6} md={4} lg={3} className={"my-auto text-end"}>
          Patient Id
        </Col>
        <Col xs={4} sm={4} md={6} lg={3}>
          {state.modalPatientId}
        </Col>
        {isUserInAdminGroup(user) && (
          <Col xs={12} sm={12} md={12} lg={6} className={"my-auto"}>
            Registered by{" "}
            {state.registeredByName != null &&
            state.registeredByEmail != null ? (
              <a href={"mailto:" + state.registeredByEmail}>
                {state.registeredByName}
              </a>
            ) : (
              <i>Self-Registered</i>
            )}
          </Col>
        )}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"my-auto text-end"}>
          First Name*
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Input
            required
            type="text"
            id="firstName"
            value={state.firstName}
            valid={
              operation === VIEW
                ? null
                : !isEmptyOrNull(state.firstName) && validName(state.firstName)
            }
            onChange={handleChange}
            readOnly={operation === VIEW}
            maxLength={getPatientMaximumNameLength()}
          />
          {!validName(state.firstName) && (
            <FormFeedback>
              First name is required and cannot contain numbers or non-standard
              symbols.
            </FormFeedback>
          )}
          {isEmptyOrNull(state.firstName) && (
            <FormFeedback>
              First name is required and a value must be provided.
            </FormFeedback>
          )}
        </Col>
        {getDataQueryFlagColumn("First Name", state.firstName)}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"my-auto text-end"}>
          Middle Name
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Input
            type="text"
            id="middleNames"
            value={state.middleNames == null ? "" : state.middleNames}
            valid={
              isEmptyOrNull(state.middleNames) || operation === VIEW
                ? null
                : !isEmptyOrNull(state.middleNames) &&
                  validName(state.middleNames)
            }
            onChange={handleChange}
            readOnly={operation === VIEW}
            maxLength={getPatientMaximumNameLength()}
          />
          <FormFeedback>
            Middle names cannot contains numbers or non-standard symbols.
          </FormFeedback>
        </Col>
        {getDataQueryFlagColumn("Middle Name(s)", state.middleNames)}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"my-auto text-end"}>
          Last Name*
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Input
            required
            type="text"
            id="lastName"
            value={state.lastName}
            valid={
              operation === VIEW
                ? null
                : !isEmptyOrNull(state.lastName) && validName(state.lastName)
            }
            onChange={handleChange}
            readOnly={operation === VIEW}
            maxLength={getPatientMaximumNameLength()}
          />
          {!validName(state.lastName) && (
            <FormFeedback>
              Last name cannot contain numbers or non-standard symbols.
            </FormFeedback>
          )}
          {isEmptyOrNull(state.lastName) && (
            <FormFeedback>
              Last name is required and a value must be provided.
            </FormFeedback>
          )}
        </Col>
        {getDataQueryFlagColumn("Last Name", state.lastName)}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"my-auto text-end"}>
          Date of Birth*
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <DatePicker
            name="dateOfBirth"
            selected={dateOfBirthDb}
            for="dateOfBirth"
            onChange={(value) => handleDateChange(value)}
            peekNextMonth
            minDate={Moment().year(1900).startOf("year").toDate()}
            maxDate={Moment().add(1, "hour").toDate()}
            showMonthDropdown
            dateFormat={"dd/MM/yyyy"}
            showYearDropdown
            dropdownMode="scroll"
            scrollableYearDropdown={true}
            yearDropdownItemNumber={120}
            placeholderText={"Date of Birth..."}
            popperPlacement={"top-start"}
            disabled={operation === VIEW}
            style={{ align: "left" }}
            className={"date-of-birth"}
            customInput={<DatePickerIosInput />}
          />
          {!validateDob() && isDateOfBirthFormatValid() && (
            <FormFeedback style={{ display: "block" }}>
              A patient must be between 16 and 118 years old.
            </FormFeedback>
          )}
          {!isDateOfBirthFormatValid() && (
            <FormFeedback style={{ display: "block" }}>
              A Date of Birth must be entered in the format DD/MM/YYYY
            </FormFeedback>
          )}
        </Col>
        {getDataQueryFlagColumn(
          "Date of Birth",
          state.dateOfBirth == null
            ? null
            : Moment(state.dateOfBirth).format(dateFormatTwo),
        )}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"my-auto text-end"}>
          Postcode*
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Input
            required
            type="text"
            id="postcode"
            value={state.postcode}
            maxLength="4"
            valid={
              operation === VIEW
                ? null
                : validateAustralianPostcode(state.postcode)
            }
            onChange={handlePostcodeChange}
            readOnly={operation === VIEW}
          />
          <FormFeedback>An Australian postcode is required.</FormFeedback>
        </Col>
        {getDataQueryFlagColumn("Postcode", state.postcode)}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"my-auto text-end"}>
          Home Phone
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Input
            type="text"
            id="homePhone"
            value={state.homePhone || ""}
            maxLength="12"
            valid={
              isEmptyOrNull(state.homePhone) || operation === VIEW
                ? null
                : validatePhone(state.homePhone)
            }
            onChange={handlePhoneChange}
            readOnly={operation === VIEW}
          />
          <FormFeedback>
            Home phone must be an Australian phone number with area code.
          </FormFeedback>
        </Col>
        {getDataQueryFlagColumn("Home Phone", state.homePhone)}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"my-auto text-end"}>
          Mobile Phone
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Input
            type="text"
            id="mobilePhone"
            value={state.mobilePhone || ""}
            maxLength="10"
            valid={
              isEmptyOrNull(state.mobilePhone) || operation === VIEW
                ? null
                : validateMobile(state.mobilePhone)
            }
            onChange={handlePhoneChange}
            readOnly={operation === VIEW}
          />
          <FormFeedback>Mobile must be in the format 04########.</FormFeedback>
        </Col>
        {getDataQueryFlagColumn("Mobile Phone", state.mobilePhone)}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"my-auto text-end"}>
          Email
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Input
            type="text"
            id="email"
            value={state.email || ""}
            valid={
              isEmptyOrNull(state.email) || operation === VIEW
                ? null
                : validateEmailAddress(state.email)
            }
            onChange={handleChange}
            readOnly={operation === VIEW}
          />
          <FormFeedback>
            Email must be in the format ____@_____.___
          </FormFeedback>
        </Col>
        {getDataQueryFlagColumn("Email", state.email)}
      </Row>
      {!patientHasAtLeastOneContact() && (
        <Alert color={"warning"}>
          At least one form of contact information should be provided
        </Alert>
      )}
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"text-end my-auto"}>
          Deceased
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} className={"text-start"}>
          <Input
            className={"ms-2 mt-2"}
            type="checkbox"
            id="deceased"
            disabled={operation === VIEW}
            onChange={() => handleDeceased()}
            checked={state.deceased}
            style={{ align: "left" }}
          />
        </Col>
        {/* Coerce boolean into a string */}
        {getDataQueryFlagColumn("Deceased", state.deceased)}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={4} lg={3} className={"text-end my-auto"}>
          Date of Death
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <DatePicker
            name="dateOfDeath"
            for="dateOfDeath"
            selected={dateOfDeathDb}
            onChange={(value) => handleDateOfDeathChange(value)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dateFormat={"dd/MM/yyyy"}
            minDate={Moment().year(1900).startOf("year").toDate()}
            maxDate={Moment().year(2050).startOf("year").toDate()}
            disabled={!state.deceased || operation === VIEW}
            dropdownMode="scroll"
            readOnly={operation === VIEW}
            scrollableYearDropdown={true}
            yearDropdownItemNumber={120}
            placeholderText={"Date of Death..."}
            popperPlacement={
              "top-start"
            } /* Leave top, to avoid overlap with small screen keyboards */
            style={{ align: "left" }}
            className={"date-of-birth"}
            customInput={<DatePickerIosInput />}
          />
          {isEmptyOrNull(state.dateOfDeath) && state.deceased && (
            <FormText
              style={{ display: "block", width: "100%", align: "right" }}
            >
              If known, a valid date of death should be provided for deceased
              patients
            </FormText>
          )}
          {!isEmptyOrNull(state.dateOfDeath) &&
            !validateDod() &&
            state.deceased &&
            isDateofDeathFormatValid() && (
              <FormFeedback
                style={{ display: "block", width: "100%", align: "right" }}
              >
                If known, a valid date of death (that is after the date of
                birth) must be provided for deceased patients
              </FormFeedback>
            )}
          {state.deceased &&
            !isEmptyOrNull(state.dateOfDeath) &&
            !isDateofDeathFormatValid() && (
              <FormFeedback style={{ display: "block" }}>
                A Date of Death must be entered in the format DD/MM/YYYY
              </FormFeedback>
            )}
          {state.deceased &&
            !isEmptyOrNull(state.dateOfDeath) &&
            !isDateOfDeathBeforeLaunchDate() &&
            validateDod() &&
            isDateOfBirthFormatValid() && (
              <FormFeedback style={{ display: "block" }}>
                Date of death cannot be prior to the commencement date of this
                system
              </FormFeedback>
            )}
          {state.deceased &&
            !isEmptyOrNull(state.dateOfDeath) &&
            !isDateOfDeathFutureDate() &&
            validateDod() &&
            isDateOfBirthFormatValid() && (
              <FormFeedback style={{ display: "block" }}>
                Date of death cannot be in the future
              </FormFeedback>
            )}
        </Col>
        {getDataQueryFlagColumn(
          "Date of Death",
          state.dateOfDeath == null
            ? null
            : Moment(state.dateOfDeath).format(dateFormatTwo),
        )}
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <Alert color="danger" isOpen={!isEmptyOrNull(state.error)}>
            {state.error}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12}>
          {isAoaUser(user) ? (
            <Alert color="danger" isOpen={!!state.mergeRequired}>
              Your are creating a duplicate patient by these edits. refer to
              patient with ID: {state.mergedPatientId}. Please use the{" "}
              <b>Merge</b> button to do this formally.
            </Alert>
          ) : (
            <Alert color="danger" isOpen={!!state.mergeRequired}>
              Your are creating a duplicate patient by these edits. Please
              contact the AOANJRR at admin@aoanjrr.org.au or on 08 8128 4280 to
              request they perform a merge for you.
            </Alert>
          )}
        </Col>
      </Row>
      {getButtonRow()}

      {state.showDeleteDialog && (
        <PatientDeleteModal
          exitCallback={() => setState({ showDeleteDialog: false })}
          confirmCallback={onConfirm}
          patientId={state.modalPatientId}
        />
      )}
      {state.showSendEmailDialog && (
        <SendLogonLinkModal
          exitCallback={() =>
            setState({
              showSendEmailDialog: false,
              errorForLoginLinkModal: null,
            })
          }
          confirmCallback={sendLink}
          checkErrorCallback={state.errorForLoginLinkModal}
          patientId={state.modalPatientId}
          validTransport={state.validTransport}
        />
      )}
      {state.createDataQuery != null && (
        <CreateDataQuery
          fieldName={state.createDataQuery.fieldName}
          fieldValue={state.createDataQuery.fieldValue}
          referencedId={state.modalPatientId}
          type={TYPE.PATIENT}
          cancelCallback={() => setState({ createDataQuery: null })}
          createCallback={() => {
            setState({ createDataQuery: null });
            loadData();
          }}
        />
      )}
      {state.viewDataQueryId != null && (
        <ViewDataQuery
          dataQueryId={state.viewDataQueryId}
          cancelCallback={() => setState({ viewDataQueryId: null })}
          saveCallback={() => {
            setState({ viewDataQueryId: null });
            loadData();
          }}
        />
      )}
      {state.impersonatePatient && (
        <ImpersonationTokenRequest
          patientId={state.modalPatientId}
          patientName={`${state.firstName} ${state.lastName}`}
          cancelCallback={() => setState({ impersonatePatient: false })}
          clipboardSaveCallback={handleLogonUrlCopied}
        />
      )}
      {state.showMergeModal && (
        <PatientMergeModal
          patientId={state.modalPatientId}
          mergedPatientId={state.mergedPatientId}
          cancelCallback={() => setState({ showMergeModal: false })}
          confirmCallback={confirmMerge}
        />
      )}
    </div>
  );
};

PatientDetailsModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onExit: PropTypes.func,
  patientId: PropTypes.number,
  operation: PropTypes.string.isRequired,
  hideActionButtons: PropTypes.bool,
};

export default PatientDetailsModal;

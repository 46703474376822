import { del, get, post } from "./Api";

const BASE_URL = "/api/matching";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function saveFuzzyMatch(registryId, promsId) {
  return post(
    url(`fuzzy-match?registryId=${registryId}&promsId=${promsId}`),
    null,
    "Unable to save fuzzy match at this time.",
  );
}

export function swapMatches(payload) {
  return post(
    url(`swap-match`),
    payload,
    "Unable to swap the current match for the potentially-better registry procedure match at this time",
  );
}

export function unmatch(registryId, promsId) {
  return del(
    url(
      promsId == null
        ? `unmatch?registryId=${registryId}`
        : `unmatch?registryId=${registryId}&promsId=${promsId}`,
    ),
    "Unable to unmatch procedure at this time.",
  );
}

export function addNeverMatch(payload) {
  return post(
    url(`never-match`),
    payload,
    "Unable to save as never match at this time",
  );
}

export function searchNeverMatches(payload) {
  return get(
    url(`never-match/search`),
    payload,
    "Unable to fetch never matches at this time.",
  );
}

export function deleteNeverMatch(sourceId, procedureId) {
  if (procedureId == null) {
    return del(
      url(`never-match?sourceId=${sourceId}`),
      "Unable to delete never match at this time.",
    );
  } else {
    return del(
      url(`never-match?sourceId=${sourceId}&procedureId=${procedureId}`),
      "Unable to delete never match at this time.",
    );
  }
}

export function NJRRProcedureSearch(request) {
  return get(
    url(`search`),
    request,
    "Unable to search for NJRR procedures at this time.",
  );
}

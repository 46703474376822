import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import { isEmptyOrNull } from "../../Utils";
import { getPhoneCallReasons, savePhoneCall } from "../../api/Reminder";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import Select from "react-select";
import { FaQuestionCircle } from "react-icons/fa";
import ButtonBar from "../common/ButtonBar";
import { useEffect, useReducer } from "react";

const NO_ELECTRONIC_CONTACT_ID = 2;

const LogCall = ({ cancelCallback, patient, saveCallback }) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      reasons: [],
      reason: null,
      reasonFreeText: "",
      contactCompleted: false,
      details: "",
      errorText: "",
      spokeWithPatient: false,
    },
  );

  useEffect(() => {
    dispatch(startLoading());
    getPhoneCallReasons()
      .then((response) => {
        let reason = null;
        if (hasNoElectronicContactInfo(patient)) {
          reason = response.data.filter(
            (item) => item.id === NO_ELECTRONIC_CONTACT_ID,
          )[0];
        }
        setState({ reasons: response.data, reason: reason });
      })
      .finally(() => dispatch(finishLoading()));
  }, []);

  const selectReason = (reason) => {
    setState({ reason: reason });
  };

  const hasNoElectronicContactInfo = (patient) => {
    return isEmptyOrNull(patient.mobilePhone) && isEmptyOrNull(patient.email);
  };

  const formIsValid = () => {
    if (isEmptyOrNull(state.details)) {
      return false;
    }

    if (state.reason == null) {
      return true;
    }
    return !(state.reason.freeText && isEmptyOrNull(state.reasonFreeText));
  };

  const handleSave = () => {
    const procedureStudyCollections = {};
    patient.available.procedures.forEach((procedure) => {
      const studyCollectionIds = [];
      procedure.studyCollections.forEach((studyCollection) => {
        studyCollectionIds.push(studyCollection.studyCollectionId);
      });
      procedureStudyCollections[procedure.id] = studyCollectionIds;
    });

    if (formIsValid()) {
      setState({ errorText: "" });
      dispatch(startLoading());
      let payload = {
        patientId: patient.id,
        phoneCallReasonId: state.reason == null ? null : state.reason.id,
        details: state.details,
        recordReminder: state.contactCompleted,
        otherReason:
          !!state.reason && state.reason.freeText ? state.reasonFreeText : null,
        procedureStudyCollections: procedureStudyCollections,
        spokeWithPatient: state.spokeWithPatient,
      };
      savePhoneCall(payload)
        .then(() => saveCallback())
        .finally(() => dispatch(finishLoading()));
    }
  };

  return (
    <Modal isOpen={true} size={"lg"} className={"log-call"}>
      <ModalHeader>Log Reminder Call - {patient.name}</ModalHeader>
      <ModalBody>
        <Row className={"field-row"}>
          <Col xs={4}>
            Details* <br />
            <i>(e.g. home phone rang - no answer, left message, etc.)</i>
          </Col>
          <Col>
            <Input
              type={"textarea"}
              rows={6}
              value={state.details}
              onChange={(event) => setState({ details: event.target.value })}
            />
          </Col>
        </Row>
        <Row className={"field-row"}>
          <Col xs={4} />
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name={"contactCompleted"}
                  onChange={() =>
                    setState({ contactCompleted: !state.contactCompleted })
                  }
                  checked={state.contactCompleted}
                />
                {" Contact Completed"}
              </Label>
              <FaQuestionCircle
                id={"contact-completed-icon"}
                className={"tooltip-icon"}
              />
              <UncontrolledTooltip
                placement="top"
                target="contact-completed-icon"
              >
                Patient completed responses or is lost to follow-up. They will
                be removed from your queue.
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col xs={1} />
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name={"spokeWithPatient"}
                  onChange={() =>
                    setState({ spokeWithPatient: !state.spokeWithPatient })
                  }
                  checked={state.spokeWithPatient}
                />
                {" Spoke With Patient"}
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className={"field-row"}>
          <Col xs={4}>
            <Label for="reasons" className={"form-label"}>
              Reason follow-up required
            </Label>
          </Col>
          <Col>
            <Select
              id="reasons"
              placeholder="Please select"
              value={state.reason}
              options={state.reasons}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={selectReason}
              stayOpen={false}
              styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
              isDisabled={hasNoElectronicContactInfo(patient)}
            />
          </Col>
        </Row>
        {state.reason != null && state.reason.freeText === true && (
          <Row className={"field-row"}>
            <Col xs={4}>
              <Label for="reasons" className={"form-label"}>
                Please specify*
              </Label>
            </Col>
            <Col>
              <Input
                type={"text"}
                value={state.reasonFreeText}
                onChange={(event) =>
                  setState({ reasonFreeText: event.target.value })
                }
              />
            </Col>
          </Row>
        )}
        <ButtonBar
          buttons={[
            <Button
              color={"primary"}
              disabled={!formIsValid()}
              onClick={handleSave}
            >
              Save
            </Button>,
            <Button color={"secondary"} onClick={cancelCallback}>
              Cancel
            </Button>,
          ]}
        />
      </ModalBody>
      {!isEmptyOrNull(state.errorText) && (
        <ModalFooter>
          <Alert color={"danger"}>{state.errorText}</Alert>
        </ModalFooter>
      )}
    </Modal>
  );
};

LogCall.propTypes = {
  patient: PropTypes.object.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
};

export default LogCall;

import { Alert, Button, Col, Input, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import Moment from "moment";
import { EDIT, isEmptyOrNull, isSurgeonRoleId, VIEW } from "../../../Utils";
import {
  disableAccount,
  enableAccount,
  resetPassword,
  unlockAccount,
} from "../../../api/User";
import { useReducer } from "react";

const Account = ({
  operation = VIEW,
  datePasswordSet = null,
  email = null,
  disabledBy = null,
  disabledReason = null,
  dateDisabled = null,
  lockReason = null,
  dateLocked = null,
  tokenExpiry = null,
  roleId = null,
  disableAccountCallback,
  unlockAccountCallback,
  enableAccountCallback,
  passwordResetCallback,
  userId,
}) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      actionError: "",
      disableReasonFieldValue: "",
      showDisableReasonInput: false,
    },
  );

  const formatTimestamp = (input) => {
    return Moment(input).format("DD/MM/YYYY @ HH:mm:ss");
  };

  const handleResetPassword = () => {
    resetPassword(userId).then((response) => {
      if (response.data === true) {
        passwordResetCallback();
      } else {
        setState({
          actionError:
            "An error occurred while resetting this user's password. Please report this issue so it can be investigated.",
        });
      }
    });
  };

  const handleDisableAccount = () => {
    if (
      state.showDisableReasonInput &&
      !isEmptyOrNull(state.disableReasonFieldValue)
    ) {
      disableAccount(userId, { reason: state.disableReasonFieldValue }).then(
        (response) => {
          if (response.data === true) {
            disableAccountCallback();
          } else {
            setState({
              actionError:
                "An error occurred while locking this user's account. Please report this issue so it can be investigated.",
            });
          }
        },
      );
    } else {
      setState({ showDisableReasonInput: true });
    }
  };

  const handleEnableAccount = () => {
    enableAccount(userId).then((response) => {
      if (response.data === true) {
        enableAccountCallback();
      } else {
        setState({
          actionError:
            "An error occurred while enabling this user's account. Please report this issue so it can be investigated.",
        });
      }
    });
  };

  const handleUnlockAccount = () => {
    unlockAccount(userId).then((response) => {
      if (response.data === true) {
        unlockAccountCallback();
      } else {
        setState({
          actionError:
            "An error occurred while unlocking this user's account. Please report this issue so it can be investigated.",
        });
      }
    });
  };

  let isSurgeon = isSurgeonRoleId(roleId);
  return (
    <div>
      <Alert color={"danger"} isOpen={!isEmptyOrNull(state.actionError)}>
        {state.actionError}
      </Alert>
      {!isSurgeon && tokenExpiry === null && (
        <Row className={"first-row"}>
          <Col
            lg={9}
            style={{ textAlign: "right" }}
            className={"align-self-center"}
          >
            <Label>
              <b>Password last set:</b>{" "}
              {datePasswordSet === null
                ? "Never"
                : formatTimestamp(datePasswordSet)}
            </Label>
          </Col>
          <Col lg={3} className={"align-self-center"}>
            <Button
              color={"primary"}
              disabled={operation !== EDIT}
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
          </Col>
        </Row>
      )}
      {!isSurgeon && tokenExpiry !== null && (
        <Row className={"first-row"}>
          <Col
            lg={9}
            style={{ textAlign: "right" }}
            className={"align-self-center"}
          >
            <Label>
              <b>User activation token expiry:</b>{" "}
              {formatTimestamp(tokenExpiry)}
            </Label>
          </Col>
          <Col lg={3} className={"align-self-center"}>
            <Button
              color={"primary"}
              disabled={operation !== EDIT}
              onClick={handleResetPassword}
            >
              Regenerate Token
            </Button>
          </Col>
        </Row>
      )}
      <Row className={isSurgeon ? "first-row" : "field-row"}>
        <Col
          lg={9}
          style={{ textAlign: "right" }}
          className={"align-self-center"}
        >
          <b>Current Email:</b> {email === null ? "Unknown" : email}
        </Col>
        <Col lg={3} className={"align-self-center"}>
          <Button disabled={operation !== EDIT}>Update Email</Button>
        </Col>
      </Row>
      {dateLocked === null && (
        <Row className={"field-row"}>
          <Col
            lg={9}
            style={{ textAlign: "right" }}
            className={"align-self-center"}
          >
            Account is currently unlocked
          </Col>
        </Row>
      )}
      {dateLocked !== null && (
        <Row className={"field-row"}>
          <Col
            lg={9}
            style={{ textAlign: "right", color: "rgb(223, 0, 0)" }}
            className={"align-self-center"}
          >
            Account was locked on {formatTimestamp(dateLocked)}
            <br />
            Reason: <i>{lockReason}</i>.
          </Col>
          <Col lg={3} className={"align-self-center"}>
            <Button
              color={"primary"}
              disabled={operation !== EDIT}
              onClick={handleUnlockAccount}
            >
              Unlock Account
            </Button>
          </Col>
        </Row>
      )}
      {dateDisabled === null && (
        <Row className={"field-row"}>
          <Col
            lg={9}
            style={{ textAlign: "right" }}
            className={"align-self-center"}
          >
            {state.showDisableReasonInput ? (
              <Input
                type={"text"}
                id="disableReason"
                value={state.disableReasonFieldValue}
                autoFocus
                placeholder="Please enter a reason for disabling this account"
                required
                onChange={(event) =>
                  setState({ disableReasonFieldValue: event.target.value })
                }
              />
            ) : (
              "Account is currently enabled"
            )}
          </Col>
          <Col lg={3} className={"align-self-center"}>
            <Button
              color={"primary"}
              disabled={
                operation !== EDIT ||
                (state.showDisableReasonInput &&
                  isEmptyOrNull(state.disableReasonFieldValue))
              }
              onClick={handleDisableAccount}
            >
              Disable Account
            </Button>
          </Col>
        </Row>
      )}
      {dateDisabled !== null && (
        <Row className={"field-row"}>
          <Col
            lg={9}
            style={{ textAlign: "right", color: "rgb(223, 0, 0)" }}
            className={"align-self-center"}
          >
            Account was disabled on {formatTimestamp(dateDisabled)} by{" "}
            {disabledBy === null
              ? "the system"
              : `${disabledBy.firstName} ${disabledBy.lastName}`}
            .<br />
            The reason given was: <i>{disabledReason}</i>.
          </Col>
          <Col lg={3} className={"align-self-center"}>
            <Button
              color={"primary"}
              disabled={operation !== EDIT}
              onClick={handleEnableAccount}
            >
              Enable Account
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

Account.propTypes = {
  operation: PropTypes.string,
  datePasswordSet: PropTypes.string,
  email: PropTypes.string,
  disabledBy: PropTypes.object,
  disabledReason: PropTypes.string,
  dateDisabled: PropTypes.string,
  lockReason: PropTypes.string,
  dateLocked: PropTypes.string,
  tokenExpiry: PropTypes.string,
  roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userId: PropTypes.number,
  passwordResetCallback: PropTypes.func,
  disableAccountCallback: PropTypes.func,
  enableAccountCallback: PropTypes.func,
  unlockAccountCallback: PropTypes.func,
};

export default Account;

import {
  Alert,
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../../actions/CommonActions";
import { openPatient, searchPatients } from "../../../api/Patient";
import ButtonBar from "../../common/ButtonBar";
import PatientMergeConfirmationModal from "./PatientMergeConfirmationModal";
import TabbedPatientModal from "./TabbedPatientModal";
import { FaFolderOpen } from "react-icons/fa";
import { isEmptyOrNull, VIEW } from "../../../Utils";
import isInt from "validator/lib/isInt";
import GenericSelector from "../../common/GenericSelector";
import { useEffect, useReducer } from "react";
import { handleEnterKeyPress } from "../../../Utils";
import { useOnUpdate } from "../../CustomHooks";

const mergedStarter = {
  id: null,
  firstName: null,
  middleNames: null,
  lastName: null,
  dateOfBirth: null,
  postcode: null,
  homePhone: null,
  mobilePhone: null,
  email: null,
  deceased: null,
  dateOfDeath: null,
};

const PatientMergeModal = ({
  isBeingMergedFromDataQuery,
  cancelCallback,
  confirmCallback,
  mergedPatientId,
  patientId,
}) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      thisPatient: {},
      otherPatient: {},
      mergeHidden: true,
      patientSearchId: "",
      showPatientModal: false,
      modalViewPatient: null,
      selectedDeletePatientOption: null,
      mergeAllowed: false,
      mergeRequired: false,
      mergeSelectorOptions: [],
      merged: mergedStarter,
      error: null,
    },
  );

  useEffect(() => {
    fetchThisPatient();
    if (
      !isEmptyOrNull(isBeingMergedFromDataQuery) &&
      isBeingMergedFromDataQuery
    ) {
      setState({ mergeHidden: false });
      fetchOtherPatient(mergedPatientId);
    }
  }, []);

  useOnUpdate(() => {
    if (
      !isEmptyOrNull(state.thisPatient) &&
      !isEmptyOrNull(state.otherPatient)
    ) {
      let differentFields = [];
      Object.keys(state.thisPatient).forEach((fieldName) => {
        if (
          fieldName !== "id" &&
          fieldName in state.merged &&
          state.thisPatient[fieldName] !== state.otherPatient[fieldName]
        ) {
          differentFields.push(fieldName);
        }
      });
      setState({ mergeSelectorOptions: differentFields });
    }
  }, [state.thisPatient, state.otherPatient]);

  const searchDisabled = () => {
    // Double-equals will (correctly) coerce the comparison values into a common type for patientSearchId == thisPatient.id
    // eslint-disable-next-line
    return (
      state.patientSearchId == null ||
      state.patientSearchId == state.thisPatient.id ||
      state.patientSearchId === "" ||
      parsePatientId(state.patientSearchId) == null
    );
  };

  const confirmMerge = (message, mergedPatientId) => {
    if (isBeingMergedFromDataQuery) {
      setState({ mergeRequired: false, showPatientModal: false });
      confirmCallback(mergedPatientId);
    } else {
      setState({ mergeRequired: false, showPatientModal: false });
      confirmCallback(message);
    }
  };

  /**
   * Fetch the patient with ID = patientId and store it to state..thisPatient
   * patientId is passed in as a prop
   */
  const fetchThisPatient = () => {
    dispatch(startLoading());
    openPatient(patientId)
      .then((response) => {
        setState({
          thisPatient: response.data,
          merged: { ...state.merged, id: response.data.id },
        });
      })
      .finally(() => dispatch(finishLoading()));
  };

  const fetchOtherPatient = (otherPatientId) => {
    dispatch(startLoading());
    openPatient(otherPatientId)
      .then((response) => {
        let newMerged = {};
        Object.keys(state.merged).forEach((fieldName) => {
          let fieldValuesDiffer =
            state.thisPatient[fieldName] !== response.data[fieldName];
          if (fieldValuesDiffer) {
            newMerged[fieldName] = null;
          }
        });
        setState({
          otherPatient: response.data,
          merged: newMerged,
        });
      })
      .finally(() => dispatch(finishLoading()));
  };

  /**
   * Search for the patient with ID = patientSearchId and store it to state..otherPatient
   */
  const submitSearch = () => {
    let payload = {
      patientId: state.patientSearchId,
    };

    dispatch(startLoading());
    searchPatients(payload)
      .then((response) => {
        if (response.data.totalElements > 0) {
          setState({
            otherPatient: response.data.content[0],
            mergeHidden: false,
            mergeAllowed: false,
            merged: mergedStarter,
            error: null,
            selectedDeletePatientOption: null,
          });
          fetchOtherPatient(response.data.content[0].id);
        } else {
          setState({
            merged: mergedStarter,
            mergeHidden: true,
            error: "A patent with that ID cannot be found.",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          setState({ error: error.response.data });
        }
      })
      .finally(() => dispatch(finishLoading()));
  };

  const prepareMerge = () => {
    setState({
      mergeRequired: true,
      thisPatient: {
        ...state.thisPatient,
        mergedPatientId: state.otherPatient.id,
      },
    });
  };

  /**
   * Parse the patient ID input string
   * @param input
   * @returns null | the input validated as an int trimmed
   */
  const parsePatientId = (input) => {
    if (isEmptyOrNull(input)) {
      return null;
    }
    if (isInt(input.trim())) {
      return input.trim();
    } else {
      return null;
    }
  };

  /**
   * Sets the merged object field with the ID of the patient whose field value is to be selected for the merge
   * @param fieldName the name of the field the patientId will be assigned to
   * @param patientId
   */
  const selectField = (fieldName, patientId) => {
    // Direct assignment is only in the scope of this method
    state.merged[fieldName] = patientId;
    let mergeIsGo = checkMergeCriteriaComplete();
    // No mergeIsGo if the patient to delete has not been selected
    if (mergeIsGo && state.selectedDeletePatientOption == null) {
      mergeIsGo = false;
    }
    setState({ mergeAllowed: mergeIsGo, merged: { ...state.merged } });
  };

  /**
   * Have all the choices been made such that the Merge can take place?
   * option has just been chosen
   * @returns {boolean}
   */
  const checkMergeCriteriaComplete = () => {
    let mergeIsGo = true;
    Object.keys(state.merged).forEach((fn) => {
      if (state.mergeSelectorOptions.includes(fn) && state.merged[fn] == null) {
        mergeIsGo = false;
      }
    });
    return mergeIsGo;
  };

  /**
   * Update the delete patient option selection and re-check if merge is now allowed
   * @param option
   */
  const patientToDeleteSelectionChanged = (option) => {
    setState({
      mergeAllowed: checkMergeCriteriaComplete(),
      selectedDeletePatientOption: option,
    });
  };

  const generateMergedPatient = () => {
    let mergePatient = { ...state.thisPatient }; // Copy arbitrarily thisPatient. We are only interested in the fields common to thisPatient and otherPatient
    Object.keys(state.merged).forEach((fieldName) => {
      mergePatient[fieldName] =
        state.merged[fieldName] === state.thisPatient.id
          ? (mergePatient[fieldName] = state.thisPatient[fieldName])
          : (mergePatient[fieldName] = state.otherPatient[fieldName]);
    });
    mergePatient.mergedPatientId = state.selectedDeletePatientOption.id; // patient to remove
    return mergePatient;
  };

  const renderTableDataField = (fieldName, patient) => {
    return (
      <td
        onClick={() => selectField(fieldName, patient.id)}
        style={{
          backgroundColor:
            patient.id === state.merged[fieldName] ? "lightblue" : null,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {patient[fieldName] ? patient[fieldName] : <div>&nbsp;</div>}
          </div>
          <div style={{ paddingRight: "0.7rem" }}>
            <Input
              type="radio"
              name={`${patient.id}-${fieldName}`}
              checked={patient.id === state.merged[fieldName]}
              readOnly // Input requires onChange if not read only - still works as expected
            />
          </div>
        </div>
      </td>
    );
  };

  /**
   * Render the TableRow
   * @param headerName the name of the patient field
   * @param fieldName the property name of the patient field
   * @returns {*}
   */
  const renderTableRow = (headerName, fieldName) => {
    const fieldValuesDiffer =
      state.thisPatient[fieldName] !== state.otherPatient[fieldName];
    return (
      <tr>
        <th>{headerName}</th>
        {/** first patient table cell field */}
        {fieldValuesDiffer ? (
          renderTableDataField(fieldName, state.thisPatient)
        ) : (
          <td>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {state.thisPatient[fieldName]}
            </div>
          </td>
        )}
        {/** second patient table cell field*/}
        {fieldValuesDiffer ? (
          renderTableDataField(fieldName, state.otherPatient)
        ) : (
          <td>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {state.otherPatient[fieldName]}
            </div>
          </td>
        )}
      </tr>
    );
  };

  const idHighlightStyle = (patientId) => {
    return {
      color:
        state.selectedDeletePatientOption != null &&
        patientId === state.selectedDeletePatientOption.id
          ? "red"
          : "",
    };
  };

  const renderDeleteTableHeader = (patient) => {
    let body;
    if (state.selectedDeletePatientOption !== null) {
      if (patient.id === state.thisPatient.id) {
        body = "This Patient";
      } else {
        body = "Other Patient";
      }
    } else {
      body = "Retain Patient";
    }

    return <th style={idHighlightStyle(patient.id)}>{body}</th>;
  };

  const renderPatientIdTableCell = (patient) => {
    return (
      <td style={idHighlightStyle(patient.id)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {patient.id}
          <Button
            className={"action-button"}
            outline
            color={"primary"}
            title={"View this patient"}
            onClick={() =>
              setState({
                showPatientModal: true,
                modalViewPatient: patient,
              })
            }
          >
            <FaFolderOpen style={{ marginBottom: "0.2rem" }} />
          </Button>
        </div>
      </td>
    );
  };

  const renderTable = () => {
    return (
      <Row className={"patient-table"}>
        <Table bordered className={"patient-merge-table"}>
          <thead>
            <tr>
              <th>Field Name</th>
              {renderDeleteTableHeader(state.thisPatient)}
              {renderDeleteTableHeader(state.otherPatient)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>ID</th>
              {renderPatientIdTableCell(state.thisPatient)}
              {renderPatientIdTableCell(state.otherPatient)}
            </tr>
            {renderTableRow("First Name", "firstName")}
            {renderTableRow("Middle Names", "middleNames")}
            {renderTableRow("Last Name", "lastName")}
            {renderTableRow("DOB", "dateOfBirth")}
            {renderTableRow("Postcode", "postcode")}
            {renderTableRow("Home Phone", "homePhone")}
            {renderTableRow("Mobile Phone", "mobilePhone")}
            {renderTableRow("Email", "email")}
            {renderTableRow("Deceased", "deceased")}
            {renderTableRow("Date of Death", "dateOfDeath")}
          </tbody>
        </Table>
      </Row>
    );
  };

  const provideSelectorOptions = () => {
    return [
      {
        id: state.thisPatient.id,
        msg: `Merge and delete this (ID: ${state.thisPatient.id}) record`,
      },
      {
        id: state.otherPatient.id,
        msg: `Merge and delete other (ID: ${state.otherPatient.id}) record`,
      },
    ];
  };

  const patientSearchIdChanged = (e) => {
    if (e.target.value && !isInt(e.target.value)) return;
    // Double-equals will (correctly) coerce the comparison values into a common type
    // eslint-disable-next-line
    if (e.target.value === state.thisPatient.id) {
      setState({
        patientSearchId: e.target.value,
        mergeHidden: true,
        error: "It doesn't make sense to merge a patient with itself.",
      });
    } else {
      setState({ patientSearchId: e.target.value, error: null });
    }
  };

  const buttons = [<Button onClick={cancelCallback}>Cancel</Button>];

  let mergeWithSearch =
    `To merge this patient (ID: ${state.thisPatient.id}` +
    ") with another patient, please enter the patient ID of the " +
    "          other patient, decide which patient information between the two entities to keep, and then choose " +
    "          which record to delete as a result of the merge.";
  let mergeWithoutSearch =
    "To merge these two patients, decide which patient information between the two entities to keep, and then choose " +
    "          which record to delete as a result of the merge. \n";

  if (!state.mergeHidden)
    buttons.push(
      <Container fluid>
        <Row className={"auto"}>
          <Col xs={12} sm={12} md={4} lg={4} xl={2} className={"auto text-end"}>
            <Label>Merge option</Label>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={8} className={"auto"}>
            <GenericSelector
              options={provideSelectorOptions()}
              selected={state.selectedDeletePatientOption}
              labelPropertyName={"msg"}
              idPropertyName={"id"}
              changeCallback={patientToDeleteSelectionChanged}
              renderer={(item) => item.msg}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} xl={2} className={"auto"}>
            <Button
              disabled={!state.mergeAllowed}
              color="primary"
              onClick={prepareMerge}
            >
              Merge
            </Button>
          </Col>
        </Row>
      </Container>,
    );

  return (
    <Modal isOpen={true} size={"lg"} toggle={cancelCallback}>
      <ModalHeader>Merge Patient?</ModalHeader>
      <ModalBody>
        {!isBeingMergedFromDataQuery ? mergeWithSearch : mergeWithoutSearch}
        <Container className={"patient-search"}>
          {!isBeingMergedFromDataQuery && (
            <Row className={"my-3"}>
              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={"my-auto text-end"}
              >
                <b>Other Record Patient ID:</b>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={5}>
                <Input
                  type={"text"}
                  value={state.patientSearchId}
                  valid={parsePatientId(state.patientSearchId) != null}
                  placeholder={"Enter the Patient ID - e.g. 1234"}
                  onChange={(e) => patientSearchIdChanged(e)}
                  onKeyPress={(e) => handleEnterKeyPress(e, submitSearch)}
                />
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                <Button
                  block
                  id={"search"}
                  color={"primary"}
                  onClick={submitSearch}
                  disabled={searchDisabled()}
                >
                  Search
                </Button>
              </Col>
            </Row>
          )}
          {!state.mergeHidden && renderTable()}
          {state.error && <Alert color={"danger"}>{state.error}</Alert>}
        </Container>
        <ButtonBar buttons={buttons} />
        {state.mergeRequired && (
          <PatientMergeConfirmationModal
            showModal={state.mergeRequired}
            exitCallback={() => setState({ mergeRequired: false })}
            confirmCallback={confirmMerge}
            patientId={
              state.selectedDeletePatientOption.id === state.thisPatient.id
                ? state.otherPatient.id
                : state.thisPatient.id
            } // patient to keep
            patient={generateMergedPatient()}
          />
        )}
        {state.showPatientModal && (
          <TabbedPatientModal
            open={state.showPatientModal}
            onConfirm={() => setState({ showPatientModal: false })}
            onExit={() => setState({ showPatientModal: false })}
            hideActionButtons={true}
            modalOperation={VIEW}
            patientId={state.modalViewPatient.id}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

PatientMergeModal.propTypes = {
  patientId: PropTypes.number,
  cancelCallback: PropTypes.func,
  mergedPatientId: PropTypes.number,
  confirmCallback: PropTypes.func.isRequired,
  isBeingMergedFromDataQuery: PropTypes.bool,
};

export default PatientMergeModal;

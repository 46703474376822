import { Button, Card, CardBody, CardTitle, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaCheck, FaEdit } from "react-icons/fa";
import { loadUserCurrent } from "../../../actions/UserActions";
import { CREATE, EDIT, isAoaUser, VIEW } from "../../../Utils";
import StakeholderGroupModal from "./StakeholderGroupModal";
import Search from "./Search";
import { MdPageview } from "react-icons/md";
import { useEffect, useReducer } from "react";

const StakeholderManagement = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      showEditModal: false,
      showCreateModal: false,
      resultsVersion: 0,
      modalOperation: "",
      modalStakeholderGroupId: null,
    },
  );

  useEffect(() => {
    !user.found && dispatch(loadUserCurrent());
  }, [dispatch]);

  const showCreateStakeholderGroupDialog = () => {
    setState({
      showCreateModal: true,
      modalOperation: CREATE,
    });
  };

  const showEditStakeholderGroupDialog = (stakeholderGroup) => {
    setState({
      showEditModal: true,
      modalOperation: EDIT,
      modalStakeholderGroupId: stakeholderGroup.id,
    });
  };

  const showViewStakeholderGroupDialog = (stakeholderGroup) => {
    setState({
      showEditModal: true,
      modalOperation: VIEW,
      modalStakeholderGroupId: stakeholderGroup.id,
    });
  };

  const getActionButtonsForUser = () => {
    let buttons = [];
    if (isAoaUser(user)) {
      buttons.push({
        icon: <FaEdit />,
        title: "Edit StakeholderGroup",
        action: showEditStakeholderGroupDialog,
      });
    } else {
      buttons.push({
        icon: <MdPageview />,
        title: "View StakeholderGroup",
        action: showViewStakeholderGroupDialog,
      });
    }
    return buttons;
  };

  const handleSuccess = (message) => {
    setState({
      showCreateModal: false,
      showEditModal: false,
      resultsVersion: state.resultsVersion + 1,
    });
    toast.success(
      <span>
        <FaCheck /> {message}
      </span>,
    );
  };

  const handleCloseStakeholderModal = () => {
    setState({
      showCreateModal: false,
      showEditModal: false,
      resultsVersion: state.resultsVersion + 1,
    });
  };

  const canCreateStakeholderGroup = () => {
    return isAoaUser(user);
  };

  return (
    <div>
      <Container>
        <h1>Stakeholder Management</h1>
        {canCreateStakeholderGroup() && (
          <Card className={"create-stakeholder"}>
            <CardBody>
              <CardTitle>Create Stakeholder Group </CardTitle>
              <Button
                disabled={!canCreateStakeholderGroup()}
                onClick={showCreateStakeholderGroupDialog}
              >
                Create...
              </Button>
              {state.showCreateModal && (
                <StakeholderGroupModal
                  onConfirm={handleSuccess}
                  onExit={handleCloseStakeholderModal}
                  operation={state.modalOperation}
                  isOpen={state.showCreateModal}
                />
              )}
            </CardBody>
          </Card>
        )}
        <Card className={"search-stakeholder-group"}>
          <CardBody>
            <CardTitle>Current Stakeholder Groups </CardTitle>
            {state.showEditModal && (
              <StakeholderGroupModal
                onConfirm={handleSuccess}
                onExit={handleCloseStakeholderModal}
                operation={state.modalOperation}
                isOpen={state.showEditModal}
                modalStakeholderGroupId={state.modalStakeholderGroupId}
              />
            )}
            <Search
              actionButtons={getActionButtonsForUser()}
              resultsVersion={state.resultsVersion}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default StakeholderManagement;

import React from "react";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import NavButton from "./NavButton";
import { FaQuestion, FaSignOutAlt } from "react-icons/fa";
import { Routes, Route } from "react-router-dom";
import CallList from "../call-list/CallList";
import { PageTracking } from "../app/PageTracking";

export const Followup = (
  menuOpen,
  toggleMenuCb,
  userLogoutCb,
  helpToggleCallback,
) => {
  return {
    switchRoute: switchRoute,
    menu: null,
    menuClosing: <div />,
    menuCombined: menuCombined(
      menuOpen,
      toggleMenuCb,
      userLogoutCb,
      helpToggleCallback,
    ),
  };
};
export const menuCombined = (
  menuOpen,
  toggleMenu,
  userLogout,
  helpToggleCallback,
) => (
  <Navbar className={"vertical-menu header"} light expand={false}>
    <NavbarToggler onClick={toggleMenu} children={<span>MENU</span>} />
    <Collapse isOpen={menuOpen} navbar>
      <Nav className="me-auto  vert-menu-holder" vertical>
        <Collapse isOpen={menuOpen} navbar>
          <Nav className="me-auto  vert-menu-holder" vertical>
            <NavButton
              to={null}
              icon={<FaQuestion className={"icon help"} />}
              text={"Help"}
              onClick={helpToggleCallback}
            />
            <NavButton
              to={"/administration"}
              icon={<FaSignOutAlt className={"icon"} />}
              text={"Logout"}
              onClick={userLogout}
            />
          </Nav>
        </Collapse>
      </Nav>
    </Collapse>
  </Navbar>
);

const switchRoute = (
  <Routes>
    <Route
      path="/dashboard"
      element={
        <PageTracking>
          <CallList />
        </PageTracking>
      }
    />
    <Route
      path="/"
      element={
        <PageTracking>
          <CallList />
        </PageTracking>
      }
    />
  </Routes>
);

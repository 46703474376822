import {
  getContactText,
  isEmptyOrNull,
  validateEmailAddress,
  validateMobile,
} from "../../Utils";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import "./Logon.css";
import { useDispatch, useSelector } from "react-redux";
import {
  sendSurgeonSms,
  sendSurgeonSmsError,
  sendSurgeonSmsSuccess,
} from "../../actions/SurgeonActions";
import {
  loadUserCurrent,
  logonError,
  userLogon,
} from "../../actions/UserActions";
import AoaLogo from "../../images/aoa_logo_blue.png";
import SimpleFooter from "../common/SimpleFooter";
import { useEffect, useReducer } from "react";

const Logon = () => {
  const user = useSelector((state) => state.user);
  const error = useSelector((state) => state.error);
  const smsSuccess = useSelector((state) => state.sendSurgeonSms.smsSuccess);
  const smsError = useSelector((state) => state.sendSurgeonSms.smsError);
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: null,
      mobile: null,
      code: null,
    },
  );

  useEffect(() => {
    !user.found && dispatch(loadUserCurrent());
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!isEmptyOrNull(smsSuccess)) {
        handleLogon();
      } else {
        handleGetCode();
      }
    }
  };

  const formValid = () => {
    let validCode = true;
    if (!isEmptyOrNull(smsSuccess)) {
      validCode = !isEmptyOrNull(state.code) && state.code.length === 6;
    }
    return (
      !isEmptyOrNull(state.email) &&
      validateEmailAddress(state.email) &&
      !isEmptyOrNull(state.mobile) &&
      validateMobile(state.mobile) &&
      validCode
    );
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    // Only permit valid surgeon code characters in the corresponding field
    if (id === "code") {
      if (!/^[A-Za-z0-9]{0,6}$/.test(value)) {
        return;
      }
    }
    setState({ [id]: value });
  };

  const handleGetCode = () => {
    // Don't do anything if the form is invalid
    if (formValid()) {
      let payload = {
        email: state.email,
        mobile: state.mobile,
      };

      dispatch(sendSurgeonSms(payload));
    }
  };

  const handleLogon = () => {
    dispatch(logonError(null));
    dispatch(sendSurgeonSmsError(null));
    dispatch(sendSurgeonSmsSuccess(null));

    // Don't do anything if the form is invalid
    if (formValid()) {
      let payload = new FormData();
      payload.append("username", state.email);
      payload.append("password", state.mobile + "/" + state.code);

      dispatch(userLogon(payload));
    }
  };

  return (
    <div className="surgeon-logon">
      <div className="landing-header">
        <a href="/" title="Home">
          <img src={AoaLogo} alt="AOA Logo" />
        </a>
      </div>
      <div className="map-background">
        <div className="spacer" />
        <div>
          <Container>
            <Card>
              <CardBody>
                <CardTitle>Surgeon Logon</CardTitle>
                <CardText>
                  As a surgeon, you can logon via a one-time code sent to your
                  phone by SMS
                </CardText>
                <div className="logon-form">
                  <Form>
                    <FormGroup>
                      <Row className={"email-row"}>
                        <Col xs={3} md={2} lg={1}>
                          <Label for="email" className={"label"}>
                            Email
                          </Label>
                        </Col>
                        <Col xs={9} md={6} lg={4}>
                          <Input
                            required
                            autoFocus
                            type="email"
                            id="email"
                            value={state.email || ""}
                            disabled={!isEmptyOrNull(smsSuccess)}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                          />
                        </Col>
                        <Col xs={0} md={2} lg={7} />
                      </Row>
                      <Row className={"mobile-row"}>
                        <Col xs={3} md={2} lg={1}>
                          <Label for="mobile" className={"label"}>
                            Mobile
                          </Label>
                        </Col>
                        <Col xs={9} md={6} lg={4}>
                          <Input
                            required={!isEmptyOrNull(state.email)}
                            type="text"
                            id="mobile"
                            value={state.mobile || ""}
                            disabled={!isEmptyOrNull(smsSuccess)}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                          />
                        </Col>
                        <Col xs={0} md={2} lg={7} />
                      </Row>
                      {!isEmptyOrNull(smsSuccess) ? (
                        <Row className={"code-row"}>
                          <Col xs={3} md={2} lg={1}>
                            <Label for="code" className={"label"}>
                              Code
                            </Label>
                          </Col>
                          <Col xs={9} md={6} lg={4}>
                            <Input
                              className={"code-input"}
                              required
                              type={"text"}
                              id={"code"}
                              autoComplete={"one-time-code"}
                              value={state.code || ""}
                              onChange={handleChange}
                              onKeyPress={handleKeyPress}
                            />
                          </Col>
                          <Col xs={0} md={2} lg={7} />
                        </Row>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    {!isEmptyOrNull(smsSuccess) ? (
                      <div>
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <Alert color="info" isOpen={true}>
                              {smsSuccess === true
                                ? "Please wait a few moments. You should receive an SMS with a logon code shortly."
                                : smsSuccess}
                            </Alert>
                          </Col>
                        </Row>
                        <Button
                          color={"primary"}
                          onClick={handleLogon}
                          outline={!formValid()}
                          disabled={!formValid()}
                        >
                          Logon
                        </Button>
                      </div>
                    ) : (
                      <Button
                        color={"primary"}
                        onClick={handleGetCode}
                        outline={!formValid()}
                        disabled={!formValid()}
                      >
                        Get Code
                      </Button>
                    )}
                  </Form>
                </div>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <Alert color="danger" isOpen={!isEmptyOrNull(smsError)}>
                      {smsError}
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <Alert color="danger" isOpen={!isEmptyOrNull(error)}>
                      There was an issue logging you in: Please check you have
                      entered the correct logon code.
                      <br />
                      {getContactText()}
                    </Alert>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
      <SimpleFooter />
    </div>
  );
};

export default Logon;

import { del, get, head, post, put } from "./Api";

const BASE_URL = "/api/stakeholder-group";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function createStakeHolderGroup(request) {
  return post(
    url("create"),
    request,
    "Unable to create Stakeholder Group at this time.",
  );
}

export function getStakeholderGroup(id) {
  return get(
    url(`${id}`),
    null,
    "Unable to view Stakeholder Group details at this time.",
  );
}

export function editStakeHolderGroup(id, request) {
  return put(
    url(`${id}`),
    request,
    "Unable to edit Stakeholder Group details at this time.",
  );
}

export function getAllStakeholderGroups() {
  return get(
    url("all"),
    null,
    "Unable to fetch Stakeholder Groups at this time.",
  );
}

export function saveStakeholderGroupLogo(id, payload) {
  return put(
    url(`${id}/logo`),
    payload,
    "Unable to save Stakeholder Group logo at this time.",
  );
}

export function deleteStakeholderGroupLogo(id) {
  return del(
    url(`${id}/logo`),
    "Unable to delete Stakeholder Group logo at this time.",
  );
}

export function hasStakeholderLogo(id) {
  return head(url(`${id}/logo`));
}

export function stakeholderGroupSearch(request) {
  return get(
    url(`search`),
    request,
    "Unable to search stakeholder groups at this time.",
  );
}

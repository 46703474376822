import { getStudyCollectionStatistics } from "../../api/Reporting";
import { aoaBrown, aoaGreen, aoaGrey, aoaTan } from "./Utils";
import BarReport from "./BarReport";

const StudyCollections = () => {
  const mapColours = (series) => {
    if (series === "Pending") {
      return aoaBrown;
    } else if (series === "Completed") {
      return aoaGreen;
    } else if (series === "Opt-Out") {
      return aoaGrey;
    } else if (series === "Lost to Follow-Up") {
      return aoaTan;
    }
  };

  return (
    <BarReport
      fetchData={getStudyCollectionStatistics}
      mapColours={mapColours}
      id={"study-collections"}
      useStudySelector={true}
      useStudyCollectionSelector={true}
      useProcedureTypeSelector={false}
      legendHovers={{
        Pending: "Collections for which no answers have yet been received",
        "Opt-Out":
          "Collections for which a patient has declined to provide a response",
        "Lost to Follow-Up": "Collections which can no longer be collected",
      }}
    />
  );
};

export default StudyCollections;

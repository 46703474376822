import "./Consent.css";
import { isEmptyOrNull } from "../../Utils";
import { Fragment } from "react";
import ConsentCard from "./ConsentCard";

const SinglePageConsent = ({
  consents,
  expandedIndexes,
  scrollPoint,
  renderMiddleButtonRow,
  renderConsentButtons,
}) => {
  return (
    <Fragment>
      <div ref={scrollPoint} />
      {consents.map((consent, i) => (
        <ConsentCard
          key={i}
          consent={consent}
          expandedDescription={expandedIndexes.includes(i)}
          renderConsentButtons={renderConsentButtons}
          renderMiddleButtonRow={renderMiddleButtonRow(consent, i)}
          showStudyDescriptions={
            consent.index === 0 && isEmptyOrNull(consent.hospitalName)
          }
        />
      ))}
    </Fragment>
  );
};

export default SinglePageConsent;

import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {
  FaArrowDown,
  FaArrowUp,
  FaCopy,
  FaPlusSquare,
  FaTrash,
} from "react-icons/fa";
import PropTypes from "prop-types";
import Inlinese from "react-inlinese";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import ConfigureSeries from "./ConfigureSeries";
import { connect } from "react-redux";
import {
  deleteScreen,
  duplicateScreen,
  loadAllText,
  newSeries,
  screenIndexChange,
  updateScreen,
} from "../../actions/InstrumentReportActions";
import { addText } from "../../api/InstrumentReport";
import DOMPurify from "dompurify";

const EMPTY_FIELD = "...";

class ConfigureScreen extends Component {
  static defaultProps = {
    screen: null,
    reportId: null,
  };

  static propTypes = {
    screen: PropTypes.object,
    reportId: PropTypes.number,
  };

  gapOptions = [
    { value: 0, label: "None" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  saveField = (field, value) => {
    let { screen, reportId, updateScreen } = this.props;
    screen[field] = value;
    updateScreen({ reportId, screen });
  };

  changeText = (option, field) => {
    let { reportId, updateScreen } = this.props;
    if (option == null || option.id != null) {
      let screen = { ...this.props.screen, [field]: option };
      updateScreen({ reportId, screen });
    }
  };

  createText = (option, field, type) => {
    let { reportId, updateScreen } = this.props;
    addText(option, type).then((response) => {
      let screen = {
        ...this.props.screen,
        [field]: response.data,
      };
      updateScreen({ reportId, screen });
      loadAllText();
    });
  };

  renderValueOrOption(option) {
    if (!!option.__isNew__) {
      return option.label;
    } else {
      return option.text;
    }
  }

  createNewSeries = () => {
    const { screen, reportId, newSeries } = this.props;
    if (!screen.difference || screen.series.length < 2) {
      newSeries({ screen, reportId });
    }
  };

  render() {
    const {
      screen,
      bins,
      reportId,
      screenIndexChange,
      deleteScreen,
      duplicateScreen,
      texts,
    } = this.props;

    if (!screen) {
      return null;
    }

    return (
      <Card style={{ padding: 10, width: "100%" }} className="screen">
        <CardTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "1em",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ paddingRight: 10 }}>
              <FaArrowUp
                size={14}
                onClick={() =>
                  screenIndexChange({ reportId, screen, delta: -1 })
                }
              />
              <FaArrowDown
                size={14}
                onClick={() =>
                  screenIndexChange({ reportId, screen, delta: 1 })
                }
              />
            </div>
            <div>
              <span style={{ paddingRight: 10 }}>
                {screen.id == null ? "UNSAVED " : ""}Screen: {screen.index}
              </span>
              <Inlinese
                placeholder="Screen Name"
                value={screen.name}
                onSubmit={(v) => this.saveField("name", v)}
                showButtons={false}
                roundness=".25rem"
              >
                {screen.name || EMPTY_FIELD} [{screen.id || EMPTY_FIELD}]
              </Inlinese>
            </div>
          </div>
          <div>
            <FaCopy onClick={() => duplicateScreen({ reportId, screen })} />
            <FaTrash onClick={() => deleteScreen({ screen })} />
          </div>
        </CardTitle>
        <CardBody style={{ padding: 0 }}>
          <Row className={"g-0"}>
            <Inlinese
              placeholder="Screen description"
              value={screen.description}
              onSubmit={(v) => this.saveField("description", v)}
              showButtons={false}
              roundness=".25rem"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(screen.description || EMPTY_FIELD),
                }}
              />
            </Inlinese>
          </Row>
          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col xs={1}>Bins</Col>
            <Col xs={4}>
              <Select
                options={bins}
                value={screen.bin}
                onChange={(v) => this.saveField("bin", v)}
                isClearable={false}
                isSearchable={false}
                placeholder="Select Partitioning Bins"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
              />
            </Col>
            <Col xs={2}>Bar Gap</Col>
            <Col xs={2}>
              <Select
                options={this.gapOptions}
                value={this.gapOptions[screen.barGap || 0]}
                onChange={(v) => this.saveField("barGap", v.value)}
                clearable={false}
                searchable={false}
                placeholder="Bar Gap"
                styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
              />
            </Col>
            <Col xs={3}>
              <Inlinese
                placeholder="Comma separated: 5,6.5,9"
                value={!!screen.verticalLines ? screen.verticalLines : ""}
                onSubmit={(v) => this.saveField("verticalLines", v)}
                showButtons={false}
                roundness=".25rem"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      !!screen.verticalLines && screen.verticalLines.length > 0
                        ? DOMPurify.sanitize(screen.verticalLines)
                        : DOMPurify.sanitize("Vertical Line Ticks"),
                  }}
                />
              </Inlinese>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="clusterGap"
                    checked={!!screen.clusterGap}
                    onChange={(v) =>
                      this.saveField("clusterGap", v.target.checked)
                    }
                  />{" "}
                  <UncontrolledTooltip placement="top" target="clusterGap">
                    Insert space between clusters of bars on the chart
                  </UncontrolledTooltip>
                  Cluster Gap
                </Label>
              </FormGroup>
            </Col>
            <Col xs={2}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="tooltip"
                    checked={!!screen.tooltip}
                    onChange={(v) =>
                      this.saveField("tooltip", v.target.checked)
                    }
                  />{" "}
                  <UncontrolledTooltip placement="top" target="tooltip">
                    Display tooltip when mouse over graph
                  </UncontrolledTooltip>
                  Tooltip
                </Label>
              </FormGroup>
            </Col>
            <Col xs={2}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="stacked"
                    checked={!!screen.stacked}
                    onChange={(v) =>
                      this.saveField("stacked", v.target.checked)
                    }
                  />{" "}
                  <UncontrolledTooltip placement="top" target="stacked">
                    Graph is a stacked bar graph for Yes/No questions
                  </UncontrolledTooltip>
                  Stacked
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="showYAxis"
                    onChange={(v) =>
                      this.saveField("showYAxis", v.target.checked)
                    }
                    checked={!!screen.showYAxis}
                  />{" "}
                  <UncontrolledTooltip placement="top" target="showYAxis">
                    Show Y-Axis on graph
                  </UncontrolledTooltip>
                  Y Axis
                </Label>
              </FormGroup>
            </Col>
            <Col xs={4}>
              <CreatableSelect
                options={texts.filter((t) => t.type === "Y-AXIS")}
                getOptionLabel={(option) => this.renderValueOrOption(option)}
                getOptionValue={(option) => this.renderValueOrOption(option)}
                onChange={(option) => this.changeText(option, "titleYAxis")}
                onCreateOption={(option) =>
                  this.createText(option, "titleYAxis", "Y-AXIS")
                }
                value={screen.titleYAxis}
              />
            </Col>
            <Col xs={2}>X Axis Label</Col>
            <Col xs={4}>
              <CreatableSelect
                options={texts.filter((t) => t.type === "X-AXIS")}
                getOptionLabel={(option) => this.renderValueOrOption(option)}
                getOptionValue={(option) => this.renderValueOrOption(option)}
                onChange={(option) => this.changeText(option, "titleXAxis")}
                onCreateOption={(option) =>
                  this.createText(option, "titleXAxis", "X-AXIS")
                }
                value={screen.titleXAxis}
              />
            </Col>
          </Row>
          <Row className={"g-0"}>
            {screen.series
              .sort((s1, s2) =>
                s1.index > s2.index ? 1 : s1.index < s2.index ? -1 : 0,
              )
              .map((s, i) => {
                return (
                  <ConfigureSeries
                    key={i}
                    series={s}
                    reportId={reportId}
                    screenId={screen.id}
                    bin={screen.bin}
                  />
                );
              })}
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
            className={"g-0"}
          >
            <span className="add-button">Add Series</span>
            <FaPlusSquare onClick={this.createNewSeries} />
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  (state) => ({
    studies: state.studies,
    ...state.instrumentReports,
  }),
  {
    screenIndexChange: screenIndexChange,
    updateScreen: updateScreen,
    newSeries: newSeries,
    deleteScreen: deleteScreen,
    duplicateScreen: duplicateScreen,
    loadAllText: loadAllText,
  },
)(ConfigureScreen);

import "./LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className={"loading-container"}>
      <div className={"loading-spinner"}></div>
    </div>
  );
};

export default LoadingSpinner;

import { createAction } from "redux-actions";
import * as Types from "./Types";

export const loadStates = createAction(Types.LOAD_STATES);
export const statesLoaded = createAction(Types.STATES_LOADED);
export const loadHospitals = createAction(Types.LOAD_HOSPITALS);
export const hospitalsLoadedForState = createAction(Types.HOSPITALS_LOADED);
export const surgeonsLoaded = createAction(Types.SURGEONS_LOADED);
export const startLoading = createAction(Types.START_LOADING);
export const finishLoading = createAction(Types.FINISH_LOADING);
export const loadStudies = createAction(Types.LOAD_STUDIES);
export const studiesLoaded = createAction(Types.STUDIES_LOADED);
export const loadStatesWithHospitals = createAction(
  Types.LOAD_STATES_WITH_HOSPITALS,
);
export const statesWithHospitalsLoaded = createAction(
  Types.STATES_WITH_HOSPITALS_LOADED,
);
export const startScreenTransition = createAction(
  Types.START_SCREEN_TRANSITION,
);
export const stopScreenTransition = createAction(Types.STOP_SCREEN_TRANSITION);
export const loadJoints = createAction(Types.LOAD_JOINTS);
export const jointsLoaded = createAction(Types.JOINTS_LOADED);
export const loadProcedureTypes = createAction(Types.LOAD_PROCEDURE_TYPES);
export const procedureTypesLoaded = createAction(Types.PROCEDURE_TYPES_LOADED);
export const loadSides = createAction(Types.LOAD_SIDES);
export const sidesLoaded = createAction(Types.SIDES_LOADED);
export const showLogoutToast = createAction(Types.SHOW_LOGOUT_TOAST);
export const loadAnnouncements = createAction(Types.LOAD_ANNOUNCEMENTS);
export const announcementsLoaded = createAction(Types.ANNOUNCEMENTS_LOADED);
export const hideBannerCurrentUser = createAction(
  Types.HIDE_ANNOUNCEMENT_CURRENT_USER,
);
export const loadStakeholderGroups = createAction(
  Types.LOAD_STAKEHOLDER_GROUPS,
);
export const stakeholderGroupsLoaded = createAction(
  Types.STAKEHOLDER_GROUPS_LOADED,
);
export const loadMissingValueMethods = createAction(
  Types.LOAD_MISSING_VALUE_METHODS,
);
export const missingValueMethodsLoaded = createAction(
  Types.MISSING_VALUE_METHODS_LOADED,
);
export const loadInstrumentScoringMethods = createAction(
  Types.LOAD_INSTRUMENT_SCORING_METHODS,
);
export const instrumentScoringMethodsLoaded = createAction(
  Types.INSTRUMENT_SCORING_METHODS_LOADED,
);
export const loadQuestionTypes = createAction(Types.LOAD_QUESTION_TYPES);
export const questionTypesLoaded = createAction(Types.QUESTION_TYPES_LOADED);
export const loadTimeUnits = createAction(Types.LOAD_TIME_UNITS);
export const timeUnitsLoaded = createAction(Types.TIME_UNITS_LOADED);
export const loadReminderTypes = createAction(Types.LOAD_REMINDER_TYPES);
export const reminderTypesLoaded = createAction(Types.REMINDER_TYPES_LOADED);
export const loadRoles = createAction(Types.LOAD_ROLES);
export const rolesLoaded = createAction(Types.ROLES_LOADED);
export const loadDataQueryStatuses = createAction(
  Types.LOAD_DATA_QUERY_STATUSES,
);
export const dataQueryStatusesLoaded = createAction(
  Types.DATA_QUERY_STATUSES_LOADED,
);
export const loadDataQueryTypes = createAction(Types.LOAD_DATA_QUERY_TYPES);
export const dataQueryTypesLoaded = createAction(Types.DATA_QUERY_TYPES_LOADED);
export const loadPatientHardDeleteReasons = createAction(
  Types.LOAD_PATIENT_DELETE_REASONS,
);
export const patientHardDeleteReasonsLoaded = createAction(
  Types.PATIENT_DELETE_REASONS_LOADED,
);

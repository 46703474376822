import { Card, CardBody, CardTitle } from "reactstrap";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { getUploads } from "../../api/Upload";
import UploadTable from "./UploadTable";
import { useEffect, useReducer } from "react";

const MyUploads = ({ resultsVersion = 0 }) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      uploads: [],
      itemsCountPerPage: 10,
      pageRangeDisplayed: 5,
      totalItemsCount: 0,
      activePage: 1,
    },
  );

  useEffect(() => {
    refreshTable();
  }, [resultsVersion, state.activePage]);

  const refreshTable = () => {
    dispatch(startLoading());
    let payload = {
      page: state.activePage - 1,
      size: state.itemsCountPerPage,
      term: state.searchFilter,
      sort: "dateUploaded,desc",
    };
    getUploads(payload)
      .then((response) => {
        setState({
          uploads: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
        });
      })
      .finally(() => dispatch(finishLoading()));
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  return (
    <Card className={"upload-table"}>
      <CardBody>
        <CardTitle>Your Uploads</CardTitle>
        <UploadTable
          activePage={state.activePage}
          uploads={state.uploads}
          totalItemsCount={state.totalItemsCount}
          itemsCountPerPage={state.itemsCountPerPage}
          pageRangeDisplayed={state.pageRangeDisplayed}
          changePageCallback={changePage}
        />
      </CardBody>
    </Card>
  );
};

export default MyUploads;

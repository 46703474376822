import { handleActions } from "redux-actions";
import {
  finishLoading,
  hideBannerCurrentUser,
  showLogoutToast,
  startLoading,
  startScreenTransition,
  stopScreenTransition,
} from "../actions/CommonActions";
import { logonError } from "../actions/UserActions";
import { supportsTouchEvents } from "detect-it";
import { toast } from "react-toastify";

const INITIAL_STATE = {
  isLoading: false,
  logonError: null,
  hasTouch: supportsTouchEvents,
  isTransitioning: false,
  noHover: hoverHasIssues(),
  logoutToastId: null,
  announcements: [],
  announcementId: null,
};

export default handleActions(
  {
    [startLoading]: (state) => ({ ...state, ...{ isLoading: true } }),
    [finishLoading]: (state) => ({ ...state, ...{ isLoading: false } }),
    [logonError]: (state, { payload }) => ({
      ...state,
      ...{ logonError: payload },
    }),
    [startScreenTransition]: (state) => ({ ...state, isTransitioning: true }),
    [stopScreenTransition]: (state) => ({ ...state, isTransitioning: false }),
    [showLogoutToast]: (state) => {
      let toastId = state.logoutToastId;
      if (!toast.isActive(toastId)) {
        toastId = toast.success("You have been logged out");
      }
      return { ...state, logoutToastId: toastId };
    },
    [hideBannerCurrentUser]: (state, { payload }) => {
      const modified = state.announcements.slice();
      modified[payload].showAnnouncement = false;
      return { ...state, ...{ announcements: modified } };
    },
    ANNOUNCEMENTS_LOADED: (state, { payload }) => ({
      ...state,
      ...{ announcements: payload },
    }),
  },
  INITIAL_STATE,
);

function hoverHasIssues() {
  // Mozilla/5.0 (iPad; CPU OS 11_2_6 like Mac OS X)
  return /(iP(ad|hone|od)|Opera Mini|Android|IEMobile|Windows (Phone|CE)|(XBL|Zune)WP7)/.test(
    window.navigator.userAgent,
  );
}

import { handleActions } from "redux-actions";

const INITIAL_STATE = { timeUnits: [], reminderTypes: [] };

export default handleActions(
  {
    TIME_UNITS_LOADED: (state, { payload }) => {
      return { ...state, ...{ timeUnits: payload } };
    },
    REMINDER_TYPES_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ reminderTypes: payload } };
    },
  },
  INITIAL_STATE,
);

import PropTypes from "prop-types";
import { Button, Fade } from "reactstrap";

const MultiSelectMultipleChoice = ({
  options = [],
  responseAction = (option) => null,
  values = [],
  transitioning = false,
}) => {
  return (
    <div className={"multiplechoice"}>
      {options.map((option, i) => {
        let chosen =
          values != null && values.map((value) => value.id).includes(option.id);
        let shouldShow = transitioning ? chosen : true;
        return (
          <div className={"button-grouping"} key={"div-" + i}>
            <Fade in={shouldShow} key={i}>
              <Button
                color="primary"
                disabled={transitioning}
                outline={!chosen}
                onClick={() => responseAction(option)}
                key={"button-" + i}
                size="lg"
              >
                {option.choiceText}
              </Button>
            </Fade>
          </div>
        );
      })}
    </div>
  );
};

MultiSelectMultipleChoice.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  responseAction: PropTypes.func,
  values: PropTypes.array,
  transitioning: PropTypes.bool,
};

export default MultiSelectMultipleChoice;

import LargeNavButton from "../dashboard/LargeNavButton";
import { FaRetweet } from "react-icons/fa";
import BetterMatchesModal from "./BetterMatchesModal";
import { toast } from "react-toastify";
import { addNeverMatch, swapMatches } from "../../api/Matching";
import { getBetterMatches } from "../../api/RegistryProcedure";
import { apiErrorResponse } from "./Utils";
import PropTypes from "prop-types";
import { useEffect, useReducer, useState } from "react";

const BetterMatches = ({ itemsCountPerPage = 10 }) => {
  const [showBetterMatchModal, setShowBetterMatchModal] = useState(false);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      totalItemsCount: 0,
      activePage: 1,
      data: [],
    },
  );

  useEffect(() => {
    loadBetterMatches();
  }, [showBetterMatchModal, state.activePage]);

  const saveNeverMatch = (sourceId, procedureId) => {
    addNeverMatch({ sourceId: sourceId, procedureId: procedureId })
      .then((response) => {
        if (response.data) {
          toast.success("Never match saved");
          loadBetterMatches();
        }
      })
      .catch((error) => {
        let msg =
          "An error occurred while saving the never match. Please try again.";
        apiErrorResponse(error, msg);
      });
  };

  const swapMatch = (procedureId, currentMatchId, potentialMatchId) => {
    const payload = {
      procedureId: procedureId,
      currentMatchId: currentMatchId,
      potentialMatchId: potentialMatchId,
    };
    swapMatches(payload)
      .then((response) => {
        if (response.data) {
          toast.success(
            "Current and Potential Matches have been swapped successfully",
          );
          loadBetterMatches();
        }
      })
      .catch((error) => {
        let msg =
          "An error occurred while swapping the matches. Please try again.";
        apiErrorResponse(error, msg);
      });
  };

  const loadBetterMatches = () => {
    let payload = {
      page: state.activePage - 1,
      size: itemsCountPerPage,
    };
    getBetterMatches(payload)
      .then((response) => {
        setState({
          data: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
        });
      })
      .catch((error) => {
        let msg = "An error occurred while loading the better matches.";
        apiErrorResponse(error, msg);
      });
  };

  return (
    <div className={"never-matches"}>
      <LargeNavButton
        title={"Manage Better Matches"}
        icon={<FaRetweet />}
        columnPadding={false}
        to={null}
        onClick={() => setShowBetterMatchModal(!showBetterMatchModal)}
      />
      {showBetterMatchModal && (
        <BetterMatchesModal
          data={state.data}
          onExit={() => setShowBetterMatchModal(!showBetterMatchModal)}
          open={showBetterMatchModal}
          neverMatchCallback={saveNeverMatch}
          changePageCallback={(page) => setState({ activePage: page })}
          activePage={state.activePage}
          totalItemsCount={state.totalItemsCount}
          swapCallback={swapMatch}
        />
      )}
    </div>
  );
};

BetterMatches.propTypes = {
  itemsCountPerPage: PropTypes.number,
};

export default BetterMatches;

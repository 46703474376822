import { Fragment, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Select from "react-select";
import "./Compare.css";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { sendViewDashboardEvent } from "../../api/Event";
import PatientComparisonModal from "./PatientComparisonModal";
import { getAvailableComparisons } from "../../api/Patient";
import { isUserInAdminGroup } from "../../Utils";
import { useOnUpdate } from "../CustomHooks";
import { customSort } from "../../Utils";

const PatientView = ({
  patientView = true,
  adminData = [],
  patientId = null,
  procedureId = null,
}) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      procedureOptions: [],
      selectedProcedure: null,
      selectedInstrument: null,
      instrumentReports: [],
      selectedReport: null,
    },
  );

  useEffect(() => {
    let data = adminData;
    if (patientView) {
      data = patient.previousResponses;
      sendViewDashboardEvent();
    }
    if (data.length > 0) {
      // Filter out not shared and post-op.
      let filtered = data.reduce((values, curr) => {
        (values[curr["procedure"]] = values[curr["procedure"]] || []).push(
          curr,
        );
        return values;
      }, {});
      let options = [];
      Object.keys(filtered).forEach((j) => {
        let found = filtered[j]
          .filter(
            (p) =>
              patientView ||
              isUserInAdminGroup(user) ||
              (!patientView && p.sharedWithSurgeon),
          )
          .sort((a, b) => {
            return customSort(a.procedureCollectionId, b.procedureCollectionId);
          })[0];
        if (found) {
          options.push(found);
        }
      });
      // If we've been provided a specific procedureId, preselect that.
      let procedureIndex = 0;
      if (procedureId) {
        procedureIndex = options.findIndex(
          (p) => p.procedureId === procedureId,
        );
        procedureIndex = procedureIndex > -1 ? procedureIndex : 0;
      }
      setState({ procedureOptions: options });
      chooseProcedure(options[procedureIndex]);
    }
  }, []);

  useOnUpdate(() => {
    getAvailableComparisons(
      patientView ? null : patientId,
      state.selectedProcedure.procedureId,
    ).then((response) => {
      setState({ instrumentReports: response.data });
    });
  }, [state.selectedProcedure]);

  const chooseProcedure = (option) => {
    if (!option) {
      return;
    }
    setState({ selectedProcedure: option, selectedInstrument: null });
  };

  const closeGraph = () => {
    setState({
      showGraph: false,
      selectedInstrument: null,
      comparisonResponses: null,
    });
  };

  const chooseReport = (report) => {
    setState({ selectedReport: report, showGraph: true });
  };

  const comparisonModal = () => {
    return (
      <PatientComparisonModal
        isOpen={state.showGraph}
        onClose={closeGraph}
        procedureId={state.selectedProcedure.procedureId}
        reportId={state.selectedReport.id}
        patientId={patientId}
        patientView={patientView}
      />
    );
  };

  const instrumentTable = () => {
    let selectedReportId =
      state.selectedReport !== null ? state.selectedReport.id : null;
    return (
      <Table striped bordered size={"sm"} responsive className={"instrument"}>
        <thead>
          <tr>
            <th>{patientView ? "Study" : "Instrument"}</th>
            <th>Summary</th>
          </tr>
        </thead>

        <tbody>
          {state.instrumentReports
            .sort((s1, s2) => customSort(s1.index, s2.index))
            .map((report, i) => {
              return (
                <tr
                  key={i}
                  className={report.id === selectedReportId ? "selected" : ""}
                >
                  <td>{patientView ? report.description : report.name}</td>
                  <td className={"centered"}>
                    <a
                      onClick={() => chooseReport(report)}
                      title={"Compare results"}
                    >
                      <FaEye className={"hand"} />
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  const renderPatientView = () => {
    return (
      <Fragment>
        <Container className="compare">
          <h1>Compare Your Results</h1>
          <Card>
            <CardBody>
              <CardTitle>
                Here you can compare the answers you have given with the
                responses given by other people having the same procedure.
              </CardTitle>
              <Row className="procedure-selection">
                <Col xs={12} lg={4} className="right">
                  You registered:
                </Col>
                <Col xs={12} lg={8}>
                  <Select
                    isClearable={false}
                    options={state.procedureOptions}
                    value={state.selectedProcedure}
                    onChange={chooseProcedure}
                    getOptionLabel={(option) => (
                      <span>
                        <b>{option.procedure}</b>: {option.date},{" "}
                        {option.hospital}
                      </span>
                    )}
                    getOptionValue={(option) => option.procedureId}
                  />
                </Col>
              </Row>
              <Row className="instrument-selection">
                {state.selectedProcedure && instrumentTable()}
              </Row>
            </CardBody>
          </Card>
        </Container>
        {state.showGraph && comparisonModal()}
      </Fragment>
    );
  };

  const renderAdminView = () => {
    return (
      <Fragment>
        <Container className="compare">
          <Row className="procedure-selection" style={{ marginTop: "1em" }}>
            <Col xs={12} lg={4} className="right">
              Procedure:
            </Col>
            <Col xs={12} lg={8}>
              <Select
                isClearable={false}
                options={state.procedureOptions}
                value={state.selectedProcedure}
                onChange={chooseProcedure}
                getOptionLabel={(option) => (
                  <span>
                    <b>{option.procedure}</b>: {option.date}, {option.hospital}
                  </span>
                )}
                getOptionValue={(option) => option.procedureId}
              />
            </Col>
          </Row>
          <Row className="instrument-selection">
            {state.selectedProcedure && instrumentTable()}
          </Row>
        </Container>
        {state.showGraph && comparisonModal()}
      </Fragment>
    );
  };

  if (patientView) {
    return renderPatientView();
  } else {
    return renderAdminView();
  }
};

PatientView.propTypes = {
  adminData: PropTypes.array,
  patientView: PropTypes.bool,
  patientId: PropTypes.number,
  procedureId: PropTypes.number,
};

export default PatientView;

import { createAction } from "redux-actions";
import * as Types from "./Types";

export const sendSurgeonSms = createAction(
  Types.SEND_SURGEON_SMS,
  (payload) => payload,
);
export const sendSurgeonSmsSuccess = createAction(
  Types.SEND_SURGEON_SMS_SUCCESS,
  (payload) => payload,
);
export const sendSurgeonSmsError = createAction(
  Types.SEND_SURGEON_SMS_ERROR,
  (payload) => payload,
);
export const loadSurgeons = createAction(Types.LOAD_SURGEONS);

import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Button, Container } from "reactstrap";
import "./NewPatient.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selfLogonSearch, setPatientField } from "../../actions/PatientActions";
import SimpleTextInput from "../common/SimpleTextInput";
import SimpleNumericInput from "../common/SimpleNumericInput";
import SimpleMonthInput from "../common/SimpleMonthInput";
import SimpleCalendarInput from "../common/SimpleCalendarInput";
import Review from "./Review";
import Moment from "moment";
import { isEmptyOrNull, validateLength } from "../../Utils";
import {
  generateLinkButton,
  getPatientMaximumNameLength,
  hasAllDetails,
  nameValid,
  NEXT,
  optionalNameValid,
  postcodeValid,
  PREVIOUS,
  renderRoute,
  validateDobMandatory,
  validateDobMonth,
  validateDobYear,
} from "./Utils";
import SimpleLogoHeader from "../common/SimpleLogoHeader";
import scrollToComponent from "react-scroll-to-component";

const NewPatient = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const scrollPoint = useRef();

  const patient = useSelector((state) => state.patient);

  const [currentlySubmitting, setCurrentlySubmitting] = useState(false);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: false,
      middleNames: false,
      lastName: false,
      year: false,
      month: false,
      day: false,
      postcode: false,
    },
  );

  useEffect(() => {
    let locations = location.pathname.match(/^\/patient\/logon\/(.+)$/);
    if (!!locations && locations.length > 0 && locations[1] !== "firstname") {
      // The only valid entry point for this screen is... the first one. Anything later, redirect back to the start.
      navigate("/", { replace: true });
    }
    setState(validatePatientData(patient));
  }, []);

  // No need to re-render the DOM when validating patient details
  // (If useEffect is used, error messages will flash red when correct values are entered for some fields)
  useLayoutEffect(() => {
    setState(validatePatientData(patient));
    scrollToComponent(scrollPoint.current, {
      align: "top",
      duration: 500,
    });
  }, [patient]);

  useEffect(() => {
    if (patient.year && patient.month && patient.day) {
      let dob = Moment(
        `${patient.year}-${patient.month}-${patient.day}`,
        "YYYY-MMMM-Do",
      ).format("YYYY-MM-DD");
      dispatch(setPatientField({ dateOfBirth: dob }));
    }
  }, [patient.day]);

  const validatePatientData = (patient) => {
    return {
      firstName: nameValid(patient.firstName),
      middleNames: optionalNameValid(patient.middleNames),
      lastName: nameValid(patient.lastName),
      year: validateDobYear(patient.year),
      month: validateDobMonth(patient.year, patient.month),
      day: validateDobMandatory(patient.dateOfBirth),
      postcode: postcodeValid(patient.postcode),
    };
  };

  const submitSearch = () => {
    setCurrentlySubmitting(true);
    dispatch(selfLogonSearch({ ...patient }));
  };

  const renderProgressButtons = () => {
    const getProgressButtonColor = (current) => {
      if (isEmptyOrNull(patient[current])) {
        return "secondary";
      }
      if (state[current]) {
        return "primary";
      }
      return "danger";
    };

    let middleColor = "secondary";
    if (
      !isEmptyOrNull(patient.firstName) &&
      !isEmptyOrNull(patient.lastName) &&
      state.middleNames
    ) {
      middleColor = "primary";
    } else if (
      !isEmptyOrNull(patient.firstName) &&
      isEmptyOrNull(patient.middleNames) &&
      isEmptyOrNull(patient.lastName)
    ) {
      middleColor = "secondary";
    } else if (!isEmptyOrNull(patient.middleNames)) {
      middleColor = state.middleNames ? "primary" : "danger";
    }

    let yearColor = getProgressButtonColor("year");
    if (
      isEmptyOrNull(patient.year) &&
      (!isEmptyOrNull(patient.month) || !isEmptyOrNull(patient.day))
    ) {
      yearColor = "danger";
    }

    return (
      <Fragment>
        <Button tabIndex={-1} color={getProgressButtonColor("firstName")} />
        <Button color={middleColor} tabIndex={-1} />
        <Button tabIndex={-1} color={getProgressButtonColor("lastName")} />
        <Button tabIndex={-1} color={yearColor} />
        <Button tabIndex={-1} color={getProgressButtonColor("month")} />
        <Button tabIndex={-1} color={getProgressButtonColor("day")} />
        <Button tabIndex={-1} color={getProgressButtonColor("postcode")} />
      </Fragment>
    );
  };

  /**
   * Wrapper function for the useNavigate hook that appends the route with ../ to have relative path routing
   * This way the route is matched on .../patient/logon/ and not .../patient/logon/*previous route*
   *
   * This function must be in this component as the useNavigate hook can only be called from a React component
   *
   * @param route where to navigate to
   * @private
   */
  const _navigate = (route) => {
    navigate(`../${route}`, { relative: "path" });
  };

  /**
   * Validates the current state which is also the current url path and navigates to the next route
   *
   * This function must be in this component as the useNavigate hook can only be called from a React component
   *
   * @param current
   * @param next
   */
  const advanceOnEnter = (current, next) => {
    state[current] && _navigate(next);
  };

  return (
    <div>
      <SimpleLogoHeader link={true} />
      <Container className="patient-logon">
        <div className={"header"}>
          <h2 className={"welcome"}>Welcome!</h2>
          <div className="header-paragraph">
            Please tell us a little bit about yourself before we get started.
          </div>
          <div ref={scrollPoint} />
          <div className={"progress-buttons"}>{renderProgressButtons()}</div>
        </div>
        <Routes>
          {renderRoute(
            "firstname",
            <SimpleTextInput
              field={"firstName"}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.firstName}
              placeholder={"First name"}
              maxLength={getPatientMaximumNameLength()}
              enterAction={() => advanceOnEnter("firstName", "middlenames")}
            />,
            <div>
              <span className={"standout"}>What is your first name?</span>
              <br />
              <i>
                Please enter your full first name and not a shortened/nickname
              </i>
            </div>,
            // This navigate() call doesn't use the wrapper function because it is an absolute path to the landing page
            generateLinkButton(3, PREVIOUS, null, () => navigate("/")),
            generateLinkButton(2, NEXT, !state.firstName, () =>
              _navigate("middlenames"),
            ),
            "new-patient",
          )}

          {renderRoute(
            "middlenames",
            <SimpleTextInput
              field={"middleNames"}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.middleNames}
              text={
                "*Leave blank if not applicable, use spaces to separate multiple middle names."
              }
              placeholder={"Middle name(s)"}
              maxLength={getPatientMaximumNameLength()}
              enterAction={() => advanceOnEnter("middleNames", "lastname")}
            />,
            <div className={"standout"}>What are your middle names?</div>,
            generateLinkButton(3, PREVIOUS, null, () => _navigate("firstname")),
            generateLinkButton(2, NEXT, !state.middleNames, () =>
              _navigate("lastname"),
            ),
            "new-patient",
          )}

          {renderRoute(
            "lastname",
            <SimpleTextInput
              field={"lastName"}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.lastName}
              placeholder={"Last name"}
              maxLength={getPatientMaximumNameLength()}
              enterAction={() => advanceOnEnter("lastName", "year")}
            />,
            <div className={"standout"}>What is your last name?</div>,
            generateLinkButton(3, PREVIOUS, null, () =>
              _navigate("middlenames"),
            ),
            generateLinkButton(2, NEXT, !state.lastName, () =>
              _navigate("year"),
            ),
            "new-patient",
          )}

          {renderRoute(
            "year",
            <SimpleNumericInput
              field={"year"}
              length={4}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.year}
              invalid={validateLength(patient.year, 4) ? !state.year : false}
              invalidNotice={
                "Sorry, you must be between 16 and 118 years old to use this service."
              }
              hintText={"Please enter a 4-digit year (e.g. 1950)"}
              enterAction={() => advanceOnEnter("year", "month")}
            />,
            <div className={"standout"}>In which YEAR were you born?</div>,
            generateLinkButton(3, PREVIOUS, null, () => _navigate("lastname")),
            generateLinkButton(2, NEXT, !state.year, () => _navigate("month")),
            "new-patient",
          )}

          {renderRoute(
            "month",
            <SimpleMonthInput
              field={"month"}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.month}
              invalid={!isEmptyOrNull(patient.month) && !state.month}
              invalidNotice={
                "Sorry, you must be between 16 and 118 years old to use this service."
              }
              enterAction={() => advanceOnEnter("month", "day")}
            />,
            <div className={"standout"}>In which MONTH were you born?</div>,
            generateLinkButton(3, PREVIOUS, null, () => _navigate("year")),
            generateLinkButton(2, NEXT, !state.month, () => _navigate("day")),
            "new-patient",
          )}

          {renderRoute(
            "day",
            <SimpleCalendarInput
              field={"day"}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.day}
              month={patient.month}
              year={patient.year}
              invalid={!isEmptyOrNull(patient.day) && !state.day}
              invalidNotice={
                "Sorry, you must be between 16 and 118 years old to use this service."
              }
              enterAction={() => advanceOnEnter("day", "postcode")}
            />,
            <div className={"standout"}>On which DATE were you born?</div>,
            generateLinkButton(3, PREVIOUS, null, () => _navigate("month")),
            generateLinkButton(2, NEXT, !state.day, () =>
              _navigate("postcode"),
            ),
            "new-patient",
          )}

          {renderRoute(
            "postcode",
            <SimpleNumericInput
              field={"postcode"}
              length={4}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.postcode}
              invalid={!isEmptyOrNull(patient.postcode) && !state.postcode}
              invalidNotice={"Sorry, it appears that is not a valid postcode"}
              hintText={"Please enter an Australian postcode"}
              enterAction={() => advanceOnEnter("postcode", "review")}
            />,
            <div className={"standout"}>What is your postcode?</div>,
            generateLinkButton(3, PREVIOUS, null, () => _navigate("day")),
            generateLinkButton(2, NEXT, !state.postcode, () =>
              _navigate("review"),
            ),
            "new-patient",
          )}

          {renderRoute(
            "review",
            <Review patient={patient} />,
            <div className={"standout"}>Review your details</div>,
            generateLinkButton(2, PREVIOUS, null, () => _navigate("postcode")),
            <Button
              tabIndex={1}
              onClick={submitSearch}
              color={hasAllDetails(patient) ? "primary" : "secondary"}
              disabled={!hasAllDetails(patient) || currentlySubmitting}
              autoFocus
              outline={!hasAllDetails(patient)}
            >
              Submit
            </Button>,
            "new-patient",
          )}

          <Route path="*" element={<Navigate to="firstname" />} />
        </Routes>
      </Container>
    </div>
  );
};

export default NewPatient;

import * as Sentry from "@sentry/browser";
import { Card, CardText, CardTitle, Container } from "reactstrap";
import HelpModal from "../common/HelpModal";
import "./SentryErrorBoundary.css";
import { useState } from "react";

const SentryErrorBoundary = ({ error }) => {
  const [showHelp, setShowHelp] = useState(false);

  const redirectAndReload = () => {
    window.location = "/";
  };

  return (
    <Container className={"sentry-error-boundary my-3"}>
      <Card body>
        <CardTitle>Error!</CardTitle>
        <CardText>
          An error has occurred – please click{" "}
          <a onClick={redirectAndReload} title={"Return to the home page"}>
            here
          </a>{" "}
          to return to the site and try again.
          <br />
          <br />
          If you are still having issues, please{" "}
          <a
            onClick={() => setShowHelp(true)}
            title={"View AOANJRR contact information"}
          >
            contact the AOANJRR
          </a>{" "}
          for assistance.
        </CardText>
      </Card>
      {showHelp && <HelpModal closeCallback={() => setShowHelp(false)} />}
    </Container>
  );
};

export default SentryErrorBoundary;

import { Fragment, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import {
  ANSWER_FIELD_TYPES,
  getQuestionFromAnswer,
  isDateQuestion,
  isDateTimeQuestion,
  isDecimalQuestion,
  isEnumeratedChildFormSpinner,
  isFreeTextMultipleLinesQuestion,
  isFreeTextQuestion,
  isIntegerQuestion,
  isMultipleChoiceMultipleSelectionQuestion,
  isMultipleChoiceSingleSelectionQuestion,
  shouldShowBasedOnTrigger,
  updateAnswers,
  updateCheckboxAnswer,
  YEAR_FIRST_DATE_FORMAT,
  YEAR_FIRST_MERIDIAN_DATE_TIME_FORMAT,
} from "./Utils";
import { Col, FormFeedback, Input, Row, Table } from "reactstrap";
import { isEmptyOrNull } from "../../Utils";
import "./CrfType.css";
import Moment from "moment";
import ChildCrfType from "./ChildCrfType";
import CrfDateQuestion from "./CrfDateQuestion";
import CrfDateTimeQuestion from "./CrfDateTimeQuestion";
import CrfCheckboxQuestion from "./CrfCheckboxQuestion";
import CrfRadioQuestion from "./CrfRadioQuestion";
import CrfFreeTextQuestion from "./CrfFreeTextQuestion";
import CrfNumberQuestion from "./CrfNumberQuestion";
import { getDataQueryFlags } from "../data-query/Utils";
import ViewDataQuery from "../data-query/ViewDataQuery";
import { useSelector } from "react-redux";
import CreateDataQuery, { TYPE } from "../data-query/CreateDataQuery";
import { getDataQueriesForCrf } from "../../api/Crf";
import Responsive from "react-responsive";
import { getSortedQuestions } from "../management/crf-type/Utils";

const CrfType = ({
  type = null,
  childTypes = [],
  answers = [],
  updateAnswersCallback = (answers) => null,
  updateChildCrfsCallback = (updated) => null,
  childCrfs = [],
  readOnly = true,
  index = 1,
  showValidationErrors = false,
  crfId,
  crfProcId,
  crfType,
  dataQueries,
  onExit,
  onConfirm,
}) => {
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      childCrfs: [],
      createDataQuery: null,
      viewDataQueryId: null,
      dataQueries: [],
    },
  );

  useEffect(() => {
    // If we are suddenly no longer showing a conditional question, we need to remove the answer to that question too
    let updated = answers.slice().filter((answer, index, array) => {
      let triggerId = getQuestionFromAnswer(
        type,
        answer,
      ).triggerCrfQuestionChoiceId;
      return (
        triggerId == null || array.map((a) => a.choiceId).includes(triggerId)
      );
    });
    if (updated.length !== answers.length) {
      updateAnswersCallback(updated);
    }
  }, [answers]);

  const updateDataQueries = (question) => {
    if (crfId != null) {
      getDataQueriesForCrf(crfId).then((response) => {
        if (!isEmptyOrNull(response.data)) {
          setState({ dataQueries: response.data });
          getDataQueryFlagColumn(question.id, question.id);
        }
      });
    } else {
      setState({ dataQueries: dataQueries });
    }
  };

  const showCreateDataQueryModal = (question, createDataQuery) => {
    let thisAnswer = answers.filter(
      (answer) => answer.questionId === question.id,
    );
    let selVal = null;
    if (!isEmptyOrNull(thisAnswer)) {
      selVal = thisAnswer[0].integerValue;
    }
    return (
      <div>
        {!isEmptyOrNull(thisAnswer) &&
          !isEmptyOrNull(selVal) &&
          !isEmptyOrNull(createDataQuery) && (
            <CreateDataQuery
              fieldName={question.id}
              fieldValue={selVal}
              crfFieldName={question.name}
              crfFieldId={question.id}
              crfType={crfType}
              crfProcId={crfProcId}
              referencedId={crfId}
              type={TYPE.CRF}
              cancelCallback={() => setState({ createDataQuery: null })}
              createCallback={() => {
                setState({ createDataQuery: null });
                updateDataQueries(question);
              }}
            />
          )}
      </div>
    );
  };

  const handleRadioChange = (questionId, choiceId) => {
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.RADIO,
      choiceId,
    );
    updateAnswersCallback(updated);
  };

  const handleCheckboxChange = (questionId, choiceId) => {
    let updated = updateCheckboxAnswer(answers, questionId, choiceId);
    updateAnswersCallback(updated);
  };

  const handleFreeTextChange = (questionId, event) => {
    const value = event?.target?.value ? event.target.value : null;
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.FREE_TEXT,
      value,
    );
    updateAnswersCallback(updated);
  };

  const handleIntegerChange = (questionId, value) => {
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.INTEGER,
      value,
    );
    updateAnswersCallback(updated);
  };

  const handleDecimalChange = (questionId, value) => {
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.DECIMAL,
      value,
    );
    updateAnswersCallback(updated);
  };

  const handleDateChange = (questionId, value) => {
    let newValue =
      value !== null ? Moment(value).format(YEAR_FIRST_DATE_FORMAT) : value;
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.DATE,
      newValue,
    );
    updateAnswersCallback(updated);
  };

  const handleDateTimeChange = (questionId, value) => {
    let newValue =
      value !== null
        ? Moment(value).format(YEAR_FIRST_MERIDIAN_DATE_TIME_FORMAT)
        : value;
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.DATE_TIME,
      newValue,
    );
    updateAnswersCallback(updated);
  };

  const handleEnumeratedSpinnerChange = (questionId, event) => {
    const value = event?.target?.value ? event.target.value : null;
    if (value == null || (value > 0 && value < 10)) {
      if (
        answers.filter((answer) => answer.questionId === questionId).length ===
        1
      ) {
        // Need to replace existing answer
        answers.forEach((answer) => {
          if (answer.questionId === questionId) {
            let newValue = value == null ? null : parseInt(value, 10);
            let difference;
            if (newValue == null) {
              difference = answer.integerValue;
            } else {
              difference = answer.integerValue - newValue;
            }
            let updateNeeded = false;
            let updated = childCrfs.slice().sort((a, b) => a.index - b.index);
            while (difference > 0) {
              updateNeeded = true;
              updated.pop();
              difference--;
            }
            if (updateNeeded) {
              updateChildCrfsCallback(updated);
            }
            answer.integerValue = newValue;
          }
        });
      } else {
        answers.push({
          questionId: questionId,
          integerValue: parseInt(value, 10),
        });
      }
      updateAnswersCallback(answers);
    }
  };

  const getEnumeratedSpinnerQuestion = (question) => {
    const answersForThisQuestions = answers.filter(
      (answer) => answer.questionId === question.id,
    );
    let getOpenDataQueries = isEmptyOrNull(state.dataQueries)
      ? dataQueries
      : state.dataQueries;
    let openDataQueries = isEmptyOrNull(getOpenDataQueries)
      ? []
      : getOpenDataQueries.filter(
          (query) => query.attribute === "" + question.id,
        );
    let showFlagForOpenEnumQuestion =
      !isEmptyOrNull(crfId) && !isEmptyOrNull(openDataQueries);
    let showFlagForNewEnumQuestion =
      !isEmptyOrNull(crfId) &&
      !isEmptyOrNull(answersForThisQuestions) &&
      !isEmptyOrNull(answersForThisQuestions[0]) &&
      !isEmptyOrNull(answersForThisQuestions[0].integerValue) &&
      !isEmptyOrNull(childCrfs) &&
      !isEmptyOrNull(childCrfs[answersForThisQuestions[0].integerValue - 1]) &&
      !isEmptyOrNull(childCrfs[answersForThisQuestions[0].integerValue - 1]) &&
      !isEmptyOrNull(
        childCrfs[answersForThisQuestions[0].integerValue - 1].answers,
      );
    let showFlag = showFlagForNewEnumQuestion || showFlagForOpenEnumQuestion;
    return (
      <Fragment>
        <Row key={`question-${question.id}`} className={"crf-question g-0"}>
          <Col>
            <b>
              {question.name}
              {question.required && "*"}
            </b>
          </Col>
          <Col>
            <Input
              type={"number"}
              min={1}
              max={9}
              disabled={readOnly}
              valid={
                showValidationErrors
                  ? answersForThisQuestions.length === 1
                  : null
              }
              value={
                answersForThisQuestions.length === 1 && question.required
                  ? answersForThisQuestions[0].integerValue
                  : ""
              }
              onChange={(event) =>
                handleEnumeratedSpinnerChange(question.id, event)
              }
            />
            {showValidationErrors &&
              answersForThisQuestions.length === 0 &&
              question.required && (
                <FormFeedback style={{ display: "block" }}>
                  This field is required
                </FormFeedback>
              )}
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} className={"text-end my-auto"}>
            {showFlag && getDataQueryFlagColumn(question.id, question.id)}
          </Col>
          {showCreateDataQueryModal(question, state.createDataQuery)}
        </Row>
      </Fragment>
    );
  };

  const getDataQueryFlagColumn = (fieldName, fieldValue) => {
    let _dataQueries = isEmptyOrNull(state.dataQueries)
      ? dataQueries
      : state.dataQueries;
    return getDataQueryFlags(
      _dataQueries,
      fieldName,
      fieldValue,
      user,
      false,
      (id) => setState({ viewDataQueryId: id }),
      (fieldName, fieldValue) =>
        setState({
          createDataQuery: { fieldName: fieldName, fieldValue: fieldValue },
        }),
    );
  };

  const getQuestion = (_question, _answers, _index) => {
    if (shouldShowBasedOnTrigger(_question, _answers)) {
      if (isMultipleChoiceSingleSelectionQuestion(_question)) {
        return (
          <CrfRadioQuestion
            key={`radio-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            showValidationErrors={showValidationErrors}
            onChange={handleRadioChange}
            disabled={readOnly}
            crfProcId={crfProcId}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
          />
        );
      } else if (isMultipleChoiceMultipleSelectionQuestion(_question)) {
        return (
          <CrfCheckboxQuestion
            key={`check-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            onChange={handleCheckboxChange}
            index={_index}
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
            crfProcId={crfProcId}
          />
        );
      } else if (isFreeTextQuestion(_question)) {
        return (
          <CrfFreeTextQuestion
            key={`free-text-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            onChange={handleFreeTextChange}
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            multiLine={isFreeTextMultipleLinesQuestion(_question)}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
            crfProcId={crfProcId}
          />
        );
      } else if (isDateQuestion(_question)) {
        return (
          <CrfDateQuestion
            key={`date-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            onChange={handleDateChange}
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
            crfProcId={crfProcId}
          />
        );
      } else if (isDateTimeQuestion(_question)) {
        return (
          <CrfDateTimeQuestion
            key={`date-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            onChange={handleDateTimeChange}
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
            crfProcId={crfProcId}
          />
        );
      } else if (isIntegerQuestion(_question) || isDecimalQuestion(_question)) {
        return (
          <CrfNumberQuestion
            key={`number-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            onChange={
              isIntegerQuestion(_question)
                ? handleIntegerChange
                : handleDecimalChange
            }
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
            crfProcId={crfProcId}
          />
        );
      } else if (isEnumeratedChildFormSpinner(_question)) {
        return getEnumeratedSpinnerQuestion(_question);
      }
    } else {
      return null;
    }
  };

  const getChildType = (childType) => {
    let numberOfChildCrfs = 0;
    const temp = answers.filter(
      (answer) => answer.questionId === childType.enumeratedCrfQuestionId,
    );
    if (temp.length === 1) {
      numberOfChildCrfs = temp[0].integerValue;
    }

    let result = [];
    let index;
    for (index = 0; index < numberOfChildCrfs; index++) {
      let childAnswers = [];
      // See if we already have answers
      if (childCrfs.filter((item) => item.index === index).length === 1) {
        childAnswers = childCrfs
          .filter((item) => item.index === index)[0]
          .answers.slice();
      } else {
        // Create new child CRF
        let updated = childCrfs.slice();
        updated.push({
          index: index,
          answers: [],
          typeId: childType.id,
          dataQueries: [],
        });
        updateChildCrfsCallback(updated);
      }
      result.push(
        <ChildCrfType
          key={`child-crf-type-${childType.id}-index-${index}`}
          index={index}
          childType={childType}
          answers={childAnswers}
          readOnly={readOnly}
          showValidationErrors={showValidationErrors}
          updateAnswersCallback={updateChildCrfAnswers}
          unmountCallback={removeChildCrf}
          crfId={!isEmptyOrNull(childCrfs[index]) ? childCrfs[index].id : null}
          onExit={onExit}
          onConfirm={onConfirm}
          crfType={childType}
          crfProcId={crfProcId}
          dataQueries={
            !isEmptyOrNull(childCrfs[index]) &&
            !isEmptyOrNull(childCrfs[index].dataQueries)
              ? childCrfs[index].dataQueries
              : []
          }
        />,
      );
    }
    return result;
  };

  const removeChildCrf = (index) => {
    let updated = childCrfs
      .slice()
      .filter((childCrf) => childCrf.index !== index);
    if (updated.length !== childCrfs.length) {
      setState({ childCrfs: updated });
    }
  };

  const updateChildCrfAnswers = (index, childType, answers) => {
    const matching = childCrfs.filter((item) => item.index === index);
    let updated = childCrfs.slice();
    if (matching.length === 1) {
      // Update existing child CRF answer
      updated.forEach((childCrf) => {
        if (childCrf.index === index) {
          childCrf.answers = answers;
        }
      });
    } else {
      // Create a new child CRF
      updated.push({ index: index, answers: answers, typeId: childType.id });
    }
    updateChildCrfsCallback(updated);
  };

  return (
    <Fragment>
      <Table bordered striped responsive>
        <thead>
          <tr>
            {type && !isEmptyOrNull(type.heading) && (
              <Responsive minWidth={375}>
                <th>
                  <b>{type.heading}</b>
                </th>
              </Responsive>
            )}
          </tr>
        </thead>
        <tbody>
          {getSortedQuestions(type).map((question, idx) => {
            let x = getQuestion(question, answers, index);
            if (!isEmptyOrNull(x)) {
              return (
                <tr key={`question-row-${idx}-index-${index}`}>
                  <Responsive minWidth={375}>
                    <td className={"crf-type"}>{x}</td>
                  </Responsive>
                </tr>
              );
            }
          })}
          {childTypes?.map((childType, idx) => {
            return (
              <tr key={`child-crf-row-${childType.id}-index-${idx}`}>
                <Responsive minWidth={375}>
                  <td className={"crf-type"}>{getChildType(childType)}</td>
                </Responsive>
              </tr>
            );
          })}

          {state.viewDataQueryId != null && (
            <ViewDataQuery
              dataQueryId={state.viewDataQueryId}
              cancelCallback={() => setState({ viewDataQueryId: null })}
              saveCallback={() => setState({ viewDataQueryId: null })}
            />
          )}
        </tbody>
      </Table>
    </Fragment>
  );
};

CrfType.propTypes = {
  type: PropTypes.object.isRequired,
  childTypes: PropTypes.arrayOf(PropTypes.object),
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateAnswersCallback: PropTypes.func.isRequired,
  updateChildCrfsCallback: PropTypes.func.isRequired,
  childCrfs: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool,
  index: PropTypes.number,
  showValidationErrors: PropTypes.bool.isRequired,
  dataQueries: PropTypes.arrayOf(PropTypes.object),
  crfId: PropTypes.number,
  crfType: PropTypes.object,
  onExit: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  crfProcId: PropTypes.number,
};

export default CrfType;

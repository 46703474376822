import { handleActions } from "redux-actions";

const INITIAL_STATE = { statuses: [], types: [] };

export default handleActions(
  {
    DATA_QUERY_STATUSES_LOADED: (state, { payload }) => {
      return {
        ...state,
        ...{ statuses: [...payload.sort((a, b) => a.index - b.index)] },
      };
    },
    DATA_QUERY_TYPES_LOADED: (state, { payload }) => {
      return {
        ...state,
        ...{ types: [...payload.sort((a, b) => a.name.localeCompare(b.name))] },
      };
    },
  },
  INITIAL_STATE,
);

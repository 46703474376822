import { Fragment, useEffect, useReducer } from "react";
import { searchDataQueries } from "../../api/DataQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  finishLoading,
  loadDataQueryStatuses,
  loadDataQueryTypes,
  loadRoles,
  loadStudies,
  startLoading,
} from "../../actions/CommonActions";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import Moment from "moment";
import GenericSelector from "../common/GenericSelector";
import Pagination from "react-js-pagination";
import { canBeAssigned } from "./Utils";
import {
  getPaginationCounter,
  handleEnterKeyPress,
  isEmptyOrNull,
} from "../../Utils";
import CreateMultiDataQuery from "./CreateMultiDataQuery";
import ActionButton from "../task/ActionButton";
import isInt from "validator/lib/isInt";
import { FaQuestionCircle } from "react-icons/fa";

const itemsCountPerPage = 15;
const pageRangeDisplayed = 5;
const ALL_TYPES = { id: 0, name: "All Types", isMulti: false };
const ALL_ASSIGNEES = { id: 0, name: "All Assignees" };
const ALL_STUDIES = { id: 0, name: "All Studies" };

const DataQueries = () => {
  const dispatch = useDispatch();

  const studies = useSelector((state) => state.studies);
  const dataQueries = useSelector((state) => state.dataQueries);
  const common = useSelector((state) => state.common);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      statuses: [],
      type: ALL_TYPES,
      assignee: ALL_ASSIGNEES,
      study: ALL_STUDIES,
      singleStudy: false,
      list: [],
      totalItemsCount: null,
      activePage: 1,
      actionComponent: null,
      searchText: "",
    },
  );

  useEffect(() => {
    dataQueries.statuses.length === 0 && dispatch(loadDataQueryStatuses());
    dataQueries.types.length === 0 && dispatch(loadDataQueryTypes());
    common.roles.length === 0 && dispatch(loadRoles());
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [
    state.activePage,
    state.singleStudy,
    state.study,
    state.type,
    state.assignee,
    state.statuses,
  ]);

  const fetchData = () => {
    const payload = {
      page: state.activePage - 1,
      size: itemsCountPerPage,
      statusIds:
        state.statuses.length === 0
          ? null
          : state.statuses.map((status) => status.id).join(","),
      typeId:
        state.type.id == null || state.type.id === ALL_TYPES.id
          ? null
          : state.type.id,
      assigneeId:
        state.assignee.id == null || state.assignee.id === ALL_ASSIGNEES.id
          ? null
          : state.assignee.id,
      studyId:
        state.study.id == null || state.study.id === ALL_STUDIES.id
          ? null
          : state.study.id,
      singleStudyOnly: state.singleStudy,
      patientId: parsePatientId(state.searchText),
    };
    dispatch(startLoading());
    searchDataQueries(payload)
      .then((response) => {
        setState({
          list: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
        });
      })
      .finally(() => dispatch(finishLoading()));
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  const getStatus = (item) => {
    const index = dataQueries.statuses.findIndex(
      (status) => status.id === item.statusId,
    );
    if (index < 0) {
      return null;
    } else {
      return dataQueries.statuses[index].name;
    }
  };

  const getAssignee = (item) => {
    const index = common.roles.findIndex(
      (status) => status.id === item.assignedToId,
    );
    if (index < 0) {
      return null;
    } else {
      return common.roles[index].name;
    }
  };

  const resetActionComponent = () => {
    setState({ actionComponent: null });
  };

  const getActionButtons = (actions) => {
    return (
      <Fragment>
        {actions.map((action, index) => {
          return (
            <ActionButton
              key={"action-button-" + index}
              action={action}
              actionClick={(component) =>
                setState({ actionComponent: component })
              }
              actionCancelCallback={resetActionComponent}
              actionSuccessCallback={() => {
                resetActionComponent();
                fetchData();
              }}
            />
          );
        })}
      </Fragment>
    );
  };

  const getTypes = () => {
    const result = dataQueries.types.slice();
    result.unshift(ALL_TYPES);
    return result;
  };

  const parsePatientId = (input) => {
    if (isEmptyOrNull(input)) {
      return null;
    }
    if (input.trim().toLocaleLowerCase().startsWith("id:")) {
      let split = input.trim().toLocaleLowerCase().split("id:");
      if (split.length === 2 && isInt(split[1])) {
        return split[1];
      } else {
        return null;
      }
    }
  };

  const getAssignees = () => {
    const result = common.roles.slice().filter((role) => canBeAssigned(role));
    result.unshift(ALL_ASSIGNEES);
    return result.sort((a, b) => a.name.localeCompare(b.name));
  };

  const getStudies = () => {
    const result = studies.slice();
    result.unshift(ALL_STUDIES);
    return result;
  };

  const renderOption = (item) => {
    if (item.id === 0) {
      return <b>{item.name}</b>;
    } else {
      return item.name;
    }
  };

  const selectType = (type) => {
    setState({
      type: type,
      activePage: 1,
    });
  };

  return (
    <Container>
      <h1>Data Queries</h1>
      <Row className={"my-3"}>
        <Col>
          <GenericSelector
            clearable={false}
            searchable={false}
            options={dataQueries.statuses}
            selected={state.statuses}
            renderer={renderOption}
            isMulti={true}
            placeholder={
              <span style={{ color: "#212529" }}>
                <b>All Statuses</b>
              </span>
            }
            changeCallback={(value) =>
              setState({
                statuses: value == null ? [] : value,
                activePage: 1,
              })
            }
          />
        </Col>
        <Col>
          <GenericSelector
            clearable={false}
            searchable={false}
            options={getTypes()}
            selected={state.type}
            renderer={renderOption}
            changeCallback={selectType}
          />
        </Col>
        <Col>
          <GenericSelector
            clearable={false}
            searchable={false}
            options={getAssignees()}
            selected={state.assignee}
            renderer={renderOption}
            changeCallback={(value) =>
              setState({
                assignee: value,
                activePage: 1,
              })
            }
          />
        </Col>
      </Row>
      <Row className={"my-3"}>
        <Col xs={4}>
          <GenericSelector
            clearable={false}
            searchable={false}
            options={getStudies()}
            selected={state.study}
            renderer={renderOption}
            changeCallback={(value) =>
              setState({
                study: value,
                activePage: 1,
              })
            }
          />
          <FormGroup className={"mt-1"} check>
            <Label check>
              <Input
                type="checkbox"
                name={"singleStudy"}
                onChange={() => setState({ singleStudy: !state.singleStudy })}
                checked={state.singleStudy}
              />
              {" Single study"}
            </Label>
            <span className={"ms-2"}>
              <FaQuestionCircle
                id={"single-study-icon"}
                className={"tooltip-icon"}
              />
              <UncontrolledTooltip placement="top" target="single-study-icon">
                When selected, the results will be filtered to ONLY include
                queries related to the single chosen study
              </UncontrolledTooltip>
            </span>
          </FormGroup>
        </Col>
        <Col xs={4}>
          <Input
            type={"text"}
            value={state.searchText}
            invalid={
              !isEmptyOrNull(state.searchText) &&
              parsePatientId(state.searchText) == null
            }
            valid={parsePatientId(state.searchText) != null}
            placeholder={"Filter by Patient ID - e.g. ID:1234"}
            onChange={(e) => setState({ searchText: e.target.value })}
            onKeyPress={(event) =>
              handleEnterKeyPress(
                event,
                fetchData,
                isEmptyOrNull(state.searchText) ||
                  parsePatientId(state.searchText) != null,
              )
            }
          />
        </Col>
        <Col xs={4} className={"text-start"}>
          <Button
            color={"primary"}
            onClick={fetchData}
            disabled={
              !isEmptyOrNull(state.searchText) &&
              parsePatientId(state.searchText) == null
            }
          >
            Search
          </Button>
        </Col>
      </Row>
      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>Status</th>
            <th>Assignee</th>
            <th>Type</th>
            <th>Updated</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {state.list.length > 0 &&
            state.list.map((item, index) => {
              const dateToolTip =
                "Created: " +
                Moment(item.dateCreated).format("DD/MM/YYYY @ h:mm a") +
                ", Updated: " +
                Moment(item.dateUpdated).format("DD/MM/YYYY @ h:mm a");
              return (
                <tr key={"data-queries-row-" + index}>
                  <td>{getStatus(item)}</td>
                  <td>{getAssignee(item)}</td>
                  <td>{item.displayType}</td>
                  <td>
                    <span title={dateToolTip}>
                      {Moment(item.dateUpdated).calendar({
                        sameElse: "DD/MM/YYYY",
                      })}
                    </span>
                  </td>
                  <td>{getActionButtons(item.actions)}</td>
                </tr>
              );
            })}
          {state.list.length === 0 && (
            <tr>
              <td colSpan={6} className={"text-center"}>
                <i>No results found</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {state.totalItemsCount > itemsCountPerPage && (
        <Row>
          <Col className={"text-start"}>
            <Pagination
              activePage={state.activePage}
              totalItemsCount={state.totalItemsCount}
              itemsCountPerPage={itemsCountPerPage}
              pageRangeDisplayed={pageRangeDisplayed}
              onChange={changePage}
              hideDisabled
              innerClass={"pagination pagination-sm"}
              itemClass={"page-item"}
              linkClass={"page-link"}
            />
          </Col>
          <Col className={"text-end"}>
            {getPaginationCounter(
              state.activePage,
              itemsCountPerPage,
              state.totalItemsCount,
            )}
          </Col>
        </Row>
      )}
      <CreateMultiDataQuery createCallback={() => fetchData()} />
      {state.actionComponent != null && state.actionComponent}
    </Container>
  );
};

export default DataQueries;

import { get } from "./Api";

const BASE_URL = "/api/common";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function getTimeUnits() {
  return get(url("time-unit"), null, "Unable to get Time Units at this time.");
}

import { del, get, post, put } from "./Api";

const BASE_URL = "/api/crf/type";

function url(path) {
  if (path) {
    return `${BASE_URL}/${path}`;
  }
  return `${BASE_URL}`;
}

export function getCrfTypes(studyId) {
  return get(
    url(`all?studyId=${studyId}`),
    null,
    "Unable to get CRF Types at this time.",
  );
}

export function getCrfQuestionTypes() {
  return get(
    url("question-types"),
    null,
    "Unable to get CRF Question Types at this time.",
  );
}

export function getCrfDateValidationTypes() {
  return get(
    url("date-validation-types"),
    null,
    "Unable to get CRF Date Validation Types at this time.",
  );
}

export function getCrfDateValidationOperators() {
  return get(
    url("date-validation-operators"),
    null,
    "Unable to get CRF Date Validation Operators at this time.",
  );
}

export function getCrfDateTimeValidationTypes() {
  return get(
    url("datetime-validation-types"),
    null,
    "Unable to get CRF Date+Time Validation Types at this time.",
  );
}

export function getCrfDateTimeValidationOperators() {
  return get(
    url("datetime-validation-operators"),
    null,
    "Unable to get CRF Date+Time Validation Operators at this time.",
  );
}

export function getCrfStringValidationOperators() {
  return get(
    url("string-validation-operators"),
    null,
    "Unable to get CRF String Validation Operators at this time.",
  );
}

export function getCrfIntegerValidationTypes() {
  return get(
    url("integer-validation-types"),
    null,
    "Unable to get CRF Integer Validation Types at this time.",
  );
}

export function getCrfDecimalValidationTypes() {
  return get(
    url("decimal-validation-types"),
    null,
    "Unable to get CRF Decimal Validation Types at this time.",
  );
}

export function createCrfType(payload) {
  return post(url(""), payload, "Unable to create CRF Type at this time.");
}

export function updateCrfType(payload) {
  return put(url(""), payload, "Unable to update CRF Type at this time.");
}

export function deleteCrfType(id) {
  return del(`${BASE_URL}?id=${id}`, 'Unable to delete CRF Type at this time.');
}

export function importCrfType(payload) {
  return post(
    url("import"),
    payload,
    "Unable to import CRF Type at this time.",
  );
}

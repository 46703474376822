import React from "react";
import * as Types from "../actions/Types";
import { currentUser, logon, logout, sendUserSms } from "../api/User";
import {
  logonError,
  sendMfaCodeError,
  sendMfaCodeSuccess,
  userCurrentLoaded,
} from "../actions/UserActions";
import {
  finishLoading,
  showLogoutToast,
  startLoading,
} from "../actions/CommonActions";
import {
  getContactAoanjrrComponent,
  getContactText,
  isEmptyOrNull,
} from "../Utils";
import { router } from "../components/app/Router";

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === Types.LOAD_USER_CURRENT) {
      // call the api here
      currentUser().then((response) => {
        const { data } = response;
        dispatch(userCurrentLoaded(data));
      });
    }
    if (action.type === Types.USER_LOGOUT) {
      logout().then(() => {
        router.navigate("/").then(dispatch(showLogoutToast()));
      });
    }
    if (action.type === Types.USER_LOGON) {
      dispatch(startLoading());
      dispatch(logonError(null));
      logon(action.payload)
        .then(() => {
          currentUser((response) => {
            dispatch(userCurrentLoaded(response.data));
          }).then(() => {
            router
              .navigate("/dashboard", { replace: true })
              .then(dispatch(finishLoading()));
          });
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.data &&
            !isEmptyOrNull(error.response.data)
          ) {
            if (error.response.data === "LockedException") {
              dispatch(
                logonError(
                  <span>
                    Your account has been locked. Please use the forgotten
                    password link above.
                    <br />
                    {getContactText()}
                  </span>,
                ),
              );
            } else if (error.response.data === "AccountExpiredException") {
              dispatch(
                logonError(
                  <span>
                    Your account has expired due to inactivity.{" "}
                    {getContactAoanjrrComponent()}
                  </span>,
                ),
              );
            } else if (error.response.data === "DisabledException") {
              dispatch(
                logonError(
                  <span>
                    Your account has been disabled.{" "}
                    {getContactAoanjrrComponent()}
                  </span>,
                ),
              );
            } else {
              dispatch(
                logonError(
                  <span>
                    {error.response.data}
                    <br />
                    {getContactText()}
                  </span>,
                ),
              );
            }
          } else {
            dispatch(
              logonError(
                "There was an issue logging you in. Please try again later.",
              ),
            );
          }
          dispatch(finishLoading());
        });
    }
    if (action.type === Types.SEND_MFA_CODE) {
      dispatch(startLoading());
      dispatch(sendMfaCodeError(null));
      sendUserSms(action.payload)
        .then((response) => {
          dispatch(sendMfaCodeSuccess(response.data));
          dispatch(finishLoading());
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.data &&
            !isEmptyOrNull(error.response.data)
          ) {
            if (error.response.data === "LockedException") {
              dispatch(
                sendMfaCodeError(
                  <span>
                    Your account has been locked. Please use the forgotten
                    password link above. <br />
                    {getContactText()}
                  </span>,
                ),
              );
            } else if (error.response.data === "AccountExpiredException") {
              dispatch(
                sendMfaCodeError(
                  <span>
                    Your account has expired due to inactivity.{" "}
                    {getContactAoanjrrComponent()}
                  </span>,
                ),
              );
            } else if (error.response.data === "DisabledException") {
              dispatch(
                sendMfaCodeError(
                  <span>
                    Your account has been disabled.{" "}
                    {getContactAoanjrrComponent()}
                  </span>,
                ),
              );
            } else {
              dispatch(
                sendMfaCodeError(
                  <span>
                    {error.response.data}
                    <br />
                    {getContactText()}
                  </span>,
                ),
              );
            }
          } else {
            dispatch(
              sendMfaCodeError(
                <span>
                  Your logon code could not be sent at this time. Please try
                  again later.
                  <br />
                  {getContactText()}
                </span>,
              ),
            );
          }
          dispatch(finishLoading());
        });
    }
    return next(action);
  };

import { impersonatePatient } from "../../actions/PatientActions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const ImpersonatePatient = () => {
  const { patientId, userId, token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      impersonatePatient({
        patientId: patientId,
        userId: userId,
        token: token,
      }),
    );
  }, [dispatch]);
};

export default ImpersonatePatient;

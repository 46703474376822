import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { deletePatient } from "../../../api/Patient";
import { isEmptyOrNull } from "../../../Utils";
import ButtonBar from "../../common/ButtonBar";
import Select from "react-select";
import { loadPatientHardDeleteReasons } from "../../../actions/CommonActions";
import { useEffect, useReducer } from "react";

const PatientDeleteModal = ({ patientId, confirmCallback, exitCallback }) => {
  const dispatch = useDispatch();
  const patientHardDeleteReasons = useSelector(
    (state) => state.patientHardDeleteReasons,
  );

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      currentlySubmitting: false,
      deleteApproved: false,
      showOtherReasonText: false,
      selectedReason: null,
      otherReasonText: "",
    },
  );

  useEffect(() => {
    isEmptyOrNull(patientHardDeleteReasons) &&
      dispatch(loadPatientHardDeleteReasons());
  }, [dispatch]);

  const isValidDeleteReason = () => {
    return !(
      isEmptyOrNull(state.selectedReason) ||
      (state.selectedReason.id === 4 && isEmptyOrNull(state.otherReasonText))
    );
  };

  const handleDelete = () => {
    const payload = {
      patientId: patientId,
      selectedReasonId: state.selectedReason.id,
      otherReasonText: state.otherReasonText,
    };
    if (patientId != null && isValidDeleteReason()) {
      setState({
        currentlySubmitting: true,
        deleteApproved: true,
      });
      deletePatient(patientId, payload)
        .then((response) => {
          if (response && response.data && response.data.success) {
            confirmCallback(
              "Patient with id: " + patientId + " deleted successfully",
            );
          } else {
            let message = "Error deleting the patient";
            if (response?.data?.message?.length > 1) {
              message = response.data.message;
            }
            setState({ loadPatientError: message });
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.message &&
            isEmptyOrNull(error.response.data.message)
          ) {
            setState({ loadPatientError: "Error deleting the patient" });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    } else {
      setState({ deleteApproved: false });
    }
  };

  const setReason = (value) => {
    setState({ selectedReason: value, showOtherReasonText: value.id === 4 });
  };

  const renderOption = (option) => {
    return option.reason ? option.reason : null;
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader>Confirm Patient Hard Deletion</ModalHeader>
      <ModalBody className={"delete-confirm"}>
        <div>
          <Form>
            <Row className={"first-row"}>
              <Col xs={12}>
                If you are sure that you want to{" "}
                <ins>
                  <b>PERMANENTLY HARD DELETE</b>
                </ins>{" "}
                the patient record, please select an appropriate reason from the
                drop down and click the <i>DELETE</i> button to continue.
                Otherwise, select the <i>CANCEL</i> button.
              </Col>
            </Row>
            <Row className={"first-row"}>
              <Col xs={12}>
                <Select
                  options={patientHardDeleteReasons}
                  onChange={setReason}
                  getOptionLabel={(option) => renderOption(option)}
                  getOptionValue={(option) => option.id}
                  placeholder={"Select..."}
                  value={state.selectedReason}
                  styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                />
              </Col>
            </Row>
            {state.showOtherReasonText && (
              <Row className={"first-row"}>
                <Col xs={12}>
                  <Input
                    type={"text"}
                    value={state.otherReasonText}
                    onChange={(event) =>
                      setState({ otherReasonText: event.target.value })
                    }
                  />
                </Col>
              </Row>
            )}
            <ButtonBar
              buttons={[
                <Button
                  color="danger"
                  onClick={handleDelete}
                  disabled={!isValidDeleteReason()}
                >
                  Delete
                </Button>,
                <Button onClick={exitCallback}>Cancel</Button>,
              ]}
            />
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

PatientDeleteModal.propTypes = {
  confirmCallback: PropTypes.func.isRequired,
  exitCallback: PropTypes.func.isRequired,
  patientId: PropTypes.number.isRequired,
};

export default PatientDeleteModal;

import { handleActions } from "redux-actions";

const INITIAL_STATE = [];

export default handleActions(
  {
    PATIENT_DELETE_REASONS_LOADED: (state, action) => [...action.payload],
  },
  INITIAL_STATE,
);

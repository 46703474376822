import { Fragment } from "react";
import UnmatchedProcedures from "./UnmatchedProcedures";
import NeverMatches from "./NeverMatches";
import { useSelector } from "react-redux";
import { isSahmriUser } from "../../Utils";
import BetterMatches from "./BetterMatches";

const RegistryProceduresManagement = () => {
  const user = useSelector((state) => state.user);

  return (
    <div>
      {isSahmriUser(user) && (
        <Fragment>
          <UnmatchedProcedures />
          <NeverMatches />
          <BetterMatches />
        </Fragment>
      )}
    </div>
  );
};

export default RegistryProceduresManagement;

import { Badge, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "./CallList.css";
import classnames from "classnames";
import { FaSpinner } from "react-icons/fa";
import PhoneListTable, {
  TYPE_ME,
  TYPE_OTHERS,
  TYPE_UNASSIGNED,
} from "./PhoneListTable";
import { useReducer } from "react";

const CallList = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      activeTab: 1,
      resultsVersion: 1,
      unassignedCount: null,
      assignedToMeCount: null,
      assignedToOthersCount: null,
    },
  );

  const setActiveTab = (tab) => {
    if (state.activeTab !== tab) {
      setState({ activeTab: tab });
    }
  };

  const refreshAllTables = () => {
    setState({ resultsVersion: state.resultsVersion + 1 });
  };

  const getBadge = (count) => {
    if (count == null) {
      return <FaSpinner className={"icon-spin"} />;
    } else {
      return <Badge color={"primary"}>{count}</Badge>;
    }
  };

  return (
    <div className={"call-list"}>
      <h1>Call List</h1>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: state.activeTab === 1 })}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            Assigned To Me {getBadge(state.assignedToMeCount)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: state.activeTab === 2 })}
            onClick={() => {
              setActiveTab(2);
            }}
          >
            Assigned To Others {getBadge(state.assignedToOthersCount)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: state.activeTab === 3 })}
            onClick={() => {
              setActiveTab(3);
            }}
          >
            Unassigned {getBadge(state.unassignedCount)}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={state.activeTab}>
        <TabPane tabId={1}>
          <PhoneListTable
            type={TYPE_ME}
            dataChangedCallback={refreshAllTables}
            resultsVersion={state.resultsVersion}
            countChangedCallback={(count) =>
              setState({ assignedToMeCount: count })
            }
          />
        </TabPane>
        <TabPane tabId={2}>
          <PhoneListTable
            type={TYPE_OTHERS}
            dataChangedCallback={refreshAllTables}
            resultsVersion={state.resultsVersion}
            countChangedCallback={(count) =>
              setState({ assignedToOthersCount: count })
            }
          />
        </TabPane>
        <TabPane tabId={3}>
          <PhoneListTable
            type={TYPE_UNASSIGNED}
            dataChangedCallback={refreshAllTables}
            resultsVersion={state.resultsVersion}
            countChangedCallback={(count) =>
              setState({ unassignedCount: count })
            }
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CallList;

import { loadHospitals } from "../../actions/CommonActions";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const SimpleHospitalSelector = ({
  clearable = true,
  disabled = false,
  stateId = null,
  onlyThoseWithAutoEnrolStudies = true,
  onlyThoseWithMinAge = false,
  usePadding = true,
  autoFocus = true,
  tabIndex = 0,
  showCodes = false,
  filterByHospitalIds = null,
  minAge,
  onChange,
  searchable,
  value,
}) => {
  const dispatch = useDispatch();
  const hospitals = useSelector((state) => state.hospitals);

  useEffect(() => {
    hospitals.length === 0 && dispatch(loadHospitals());
  }, [dispatch]);

  const _buildHospitalList = () => {
    if (stateId) {
      if (
        onlyThoseWithAutoEnrolStudies === true &&
        onlyThoseWithMinAge !== true
      ) {
        return hospitals.filter(
          (h) => h.state.id === stateId && h.hasActiveAutoEnrolmentStudies,
        );
      } else {
        return hospitals.filter(
          (h) =>
            h.state.id === stateId &&
            h.studiesWithMinimumAge.some((s) => s.minimumAge <= minAge),
        );
      }
    }
    return hospitals.filter(
      (j) => !filterByHospitalIds || filterByHospitalIds.includes(j.id),
    );
  };

  const renderOption = (hospital) => {
    if (showCodes) {
      return `${hospital.name} (${hospital.state.shortName}${hospital.abbreviation})}`;
    } else {
      return hospital.name;
    }
  };

  const filterOption = (option, filter) => {
    if (showCodes) {
      let combined = `${option.data.name} ${option.data.state.shortName}${option.data.abbreviation} `;
      return combined.toLowerCase().includes(filter.toLowerCase());
    } else {
      return option.data.name.toLowerCase().includes(filter.toLowerCase());
    }
  };

  const getSelect = () => {
    return (
      <Select
        options={_buildHospitalList()}
        onChange={onChange}
        isClearable={clearable}
        isSearchable={searchable}
        getOptionLabel={(option) => renderOption(option)}
        getOptionValue={(option) => option.id}
        value={value}
        isDisabled={disabled}
        tabIndex={tabIndex}
        filterOption={filterOption}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
        autoFocus={autoFocus}
      />
    );
  };

  if (usePadding) {
    return (
      <div className={"simple-state-selector"}>
        <Row>
          <Col xs={1} sm={1} md={1} lg={2} xl={3} />
          <Col>{getSelect()}</Col>
          <Col xs={1} sm={1} md={1} lg={2} xl={3} />
        </Row>
      </div>
    );
  } else {
    return <div>{getSelect()}</div>;
  }
};

SimpleHospitalSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  stateId: PropTypes.number,
  onlyThoseWithAutoEnrolStudies: PropTypes.bool,
  usePadding: PropTypes.bool,
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.number,
  showCodes: PropTypes.bool,
  onlyThoseWithMinAge: PropTypes.bool,
  minAge: PropTypes.number,
  filterByHospitalIds: PropTypes.arrayOf(PropTypes.number),
};

export default SimpleHospitalSelector;

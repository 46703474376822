import AnnouncementManagement from "../management/announcement/AnnouncementManagement";
import StakeholderGroupLogos from "./StakeholderGroupLogos";
import Configuration from "../compare/Configuration";
import Reminders from "./Reminders";

const Administration = () => {
  return (
    <div className={"administration"}>
      <AnnouncementManagement />
      <StakeholderGroupLogos />
      <Reminders />
      <Configuration />
    </div>
  );
};

export default Administration;
import { createAction } from "redux-actions";
import * as Types from "./Types";
import { CLEAR_PATIENT } from "./Types";

export const setPatientField = createAction(
  Types.SET_PATIENT_FIELD,
  (payload) => payload,
);
export const selfLogonSearch = createAction(
  Types.SEARCH_PATIENT_SELF_LOGON,
  (payload) => payload,
);
export const loadPatientDetails = createAction(
  Types.LOAD_PATIENT_DETAILS,
  (payload) => payload,
);
export const setPatientProcedureField = createAction(
  Types.SET_PATIENT_PROCEDURE_FIELD,
  (payload) => payload,
);
export const setProcedureState = createAction(
  Types.SET_PROCEDURE_STATE,
  (payload) => payload,
);
export const setProcedureHospital = createAction(
  Types.SET_PROCEDURE_HOSPITAL,
  (payload) => payload,
);
export const setProcedureSurgeon = createAction(
  Types.SET_PROCEDURE_SURGEON,
  (payload) => payload,
);
export const setScheduledProcedureDate = createAction(
  Types.SET_SCHEDULED_PROCEDURE_DATE,
  (payload) => payload,
);
export const autoLogonPatient = createAction(
  Types.AUTO_LOGON_PATIENT,
  (payload) => payload,
);
export const setAutoLogonDetails = createAction(
  Types.AUTO_LOGON_DETAILS,
  (payload) => payload,
);
export const autoLogonPatientSms = createAction(
  Types.AUTO_LOGON_PATIENT_SMS,
  (payload) => payload,
);
export const registerSubmit = createAction(Types.REGISTER_SUBMIT);
export const clearPatientData = createAction(CLEAR_PATIENT);
export const confirmPatientDetails = createAction(
  Types.CONFIRM_PATIENT_DETAILS,
  (payload) => payload,
);
export const hasAvailableCollections = createAction(
  Types.HAS_AVAILABLE_COLLECTIONS,
  (payload) => payload,
);
export const checkCollectionsAvailable = createAction(
  Types.CHECK_COLLECTIONS_AVAILABLE,
  (payload) => payload,
);
export const declineThankYou = createAction(Types.DECLINE_THANKYOU);
export const clearNewProcedure = createAction(Types.CLEAR_NEW_PROCEDURE);
export const loadPreviousResponses = createAction(
  Types.LOAD_PREVIOUS_RESPONSES,
  (payload) => payload,
);
export const previousResponsesLoaded = createAction(
  Types.PREVIOUS_RESPONSES_LOADED,
  (payload) => payload,
);
export const reloadPreviousResponses = createAction(
  Types.RELOAD_PREVIOUS_RESPONSES,
  (payload) => payload,
);
export const impersonatePatient = createAction(Types.IMPERSONATE_PATIENT);

import Moment from "moment";
import {
  isAdministratorUser,
  isAoaUser,
  isEmptyOrNull,
  isHospitalStudyCoordinatorUser,
  isSahmriUser,
  isStudyCoordinatorUser,
  isSurgeonUser,
} from "../../Utils";

export const YEAR_FIRST_DATE_FORMAT = "YYYY-MM-DD";
export const YEAR_FIRST_MERIDIAN_DATE_TIME_FORMAT = "YYYY-MM-DD h:mm a";

export const ANSWER_FIELD_TYPES = {
  DATE: "dateValue",
  DATE_TIME: "dateTimeValue",
  RADIO: "choiceId",
  CHECKBOX: "choiceId",
  FREE_TEXT: "textValue",
  INTEGER: "integerValue",
  DECIMAL: "decimalValue",
};

export function isMultipleChoiceSingleSelectionQuestion(crfQuestion) {
  return crfQuestion.questionTypeId === 1;
}

export function isMultipleChoiceMultipleSelectionQuestion(crfQuestion) {
  return crfQuestion.questionTypeId === 2;
}

export function isFreeTextSingleLineQuestion(crfQuestion) {
  return crfQuestion.questionTypeId === 3;
}

export function isDateQuestion(crfQuestion) {
  return crfQuestion.questionTypeId === 4;
}

export function isDateTimeQuestion(crfQuestion) {
  return crfQuestion.questionTypeId === 9;
}

export function isEnumeratedChildFormSpinner(crfQuestion) {
  return crfQuestion.questionTypeId === 5;
}

export function isFreeTextMultipleLinesQuestion(crfQuestion) {
  return crfQuestion.questionTypeId === 6;
}

export function isFreeTextQuestion(crfQuestion) {
  return (
    isFreeTextSingleLineQuestion(crfQuestion) ||
    isFreeTextMultipleLinesQuestion(crfQuestion)
  );
}

export function isMultipleChoiceQuestion(crfQuestion) {
  return (
    isMultipleChoiceSingleSelectionQuestion(crfQuestion) ||
    isMultipleChoiceMultipleSelectionQuestion(crfQuestion)
  );
}

export function isIntegerQuestion(crfQuestion) {
  return crfQuestion.questionTypeId === 7;
}

export function isDecimalQuestion(crfQuestion) {
  return crfQuestion.questionTypeId === 8;
}

export function crfIsValid(crfType, answers, childCrfTypes, childCrfs) {
  let requiredQuestionIds = crfType.questions
    .filter(
      (question) =>
        shouldShowBasedOnTrigger(question, answers) && question.required,
    )
    .map((question) => question.id);
  let requiredFreeTextQuestionIds = crfType.questions
    .filter(
      (question) =>
        shouldShowBasedOnTrigger(question, answers) &&
        question.required &&
        isFreeTextQuestion(question),
    )
    .map((question) => question.id);
  let haveAllRequiredAnswers = requiredQuestionIds.every((questionId) => {
    return answers.map((answer) => answer.questionId).includes(questionId);
  });
  let noBlankTextRequiredAnswers = requiredFreeTextQuestionIds.every(
    (questionId) => {
      let index = answers.findIndex(
        (answer) => answer.questionId === questionId,
      );
      if (index < 0) {
        return false;
      }
      let text = answers[index].textValue;
      return text != null && text.trim().length > 0;
    },
  );
  let dateQuestionIds = crfType.questions
    .filter((question) => isDateQuestion(question))
    .map((question) => question.id);
  let dateTimeQuestionIds = crfType.questions
    .filter((question) => isDateTimeQuestion(question))
    .map((question) => question.id);
  let dateAnswerFormatsCorrect = answers
    .filter((answer) => dateQuestionIds.includes(answer.questionId))
    .every((answer) => {
      return (
        getDateValue(answer.dateValue) != null ||
        isEmptyOrNull(answer.dateValue)
      );
    });
  let dateTimeAnswerFormatsCorrect = answers
    .filter((answer) => dateTimeQuestionIds.includes(answer.questionId))
    .every((answer) => {
      return (
        getDateTimeValue(answer.dateValue) != null ||
        isEmptyOrNull(answer.dateValue)
      );
    });

  if (childCrfTypes != null && childCrfs != null) {
    let i;
    for (i = 0; i < childCrfTypes.length; i++) {
      const childCrfType = childCrfTypes[i];
      const childCrfIsValid = childCrfs.every((childCrf) => {
        requiredQuestionIds = childCrfType.questions
          .filter(
            (question) =>
              shouldShowBasedOnTrigger(question, childCrf.answers) &&
              question.required,
          )
          .map((question) => question.id);
        requiredFreeTextQuestionIds = childCrfType.questions
          .filter(
            (question) =>
              shouldShowBasedOnTrigger(question, childCrf.answers) &&
              question.required &&
              isFreeTextQuestion(question),
          )
          .map((question) => question.id);

        haveAllRequiredAnswers = requiredQuestionIds.every((questionId) => {
          return childCrf.answers
            .map((answer) => answer.questionId)
            .includes(questionId);
        });
        noBlankTextRequiredAnswers = requiredFreeTextQuestionIds.every(
          (questionId) => {
            let index = childCrf.answers.findIndex(
              (answer) => answer.questionId === questionId,
            );
            if (index < 0) {
              return false;
            }
            let text = childCrf.answers[index].textValue;
            return text != null && text.trim().length > 0;
          },
        );
        dateQuestionIds = childCrfType.questions
          .filter((question) => isDateQuestion(question))
          .map((question) => question.id);
        dateAnswerFormatsCorrect = childCrf.answers
          .filter((answer) => dateQuestionIds.includes(answer.questionId))
          .every((answer) => {
            return (
              getDateValue(answer.dateValue) != null ||
              isEmptyOrNull(answer.dateValue)
            );
          });
        return (
          haveAllRequiredAnswers &&
          noBlankTextRequiredAnswers &&
          dateAnswerFormatsCorrect &&
          dateTimeAnswerFormatsCorrect
        );
      });

      if (!childCrfIsValid) {
        return false;
      }
    }
  }

  return (
    haveAllRequiredAnswers &&
    noBlankTextRequiredAnswers &&
    dateAnswerFormatsCorrect &&
    dateTimeAnswerFormatsCorrect
  );
}

export function getDateValue(value) {
  let dateVal = Moment(value, YEAR_FIRST_DATE_FORMAT, true);
  if (dateVal.isValid()) {
    return dateVal.toDate();
  } else {
    return null;
  }
}

export function getDateTimeValue(value) {
  let dateTimeVal = Moment(value);
  if (dateTimeVal.isValid()) {
    return dateTimeVal.toDate();
  } else {
    dateTimeVal = Moment(value, YEAR_FIRST_MERIDIAN_DATE_TIME_FORMAT, true);
    if (dateTimeVal.isValid()) {
      return dateTimeVal.toDate();
    }
    return null;
  }
}

export function shouldShowBasedOnTrigger(question, answers) {
  if (question == null || answers == null) {
    return false;
  }
  if (question.triggerCrfQuestionChoiceId != null) {
    return (
      answers.filter(
        (answer) => answer.choiceId === question.triggerCrfQuestionChoiceId,
      ).length > 0
    );
  } else {
    return true;
  }
}

export function getQuestionFromAnswer(crfType, answer) {
  return crfType.questions.filter(
    (question) => answer.questionId === question.id,
  )[0];
}

export function hasCrfWriteAccess(user, completableRoles) {
  if (isAoaUser(user)) {
    return true;
  } else if (isEmptyOrNull(completableRoles)) {
    return false;
  } else {
    return completableRoles.some((role) => role.roleId === user.roleId);
  }
}

export function hasCrfReadAccess(user) {
  return (
    isStudyCoordinatorUser(user) ||
    isAoaUser(user) ||
    isSahmriUser(user) ||
    isAdministratorUser(user) ||
    isSurgeonUser(user) ||
    isHospitalStudyCoordinatorUser(user)
  );
}

export const updateAnswers = (answers, questionId, answerField, newValue) => {
  let filteredAnswers = answers.filter(
    (answer) => answer.questionId === questionId,
  );
  if (filteredAnswers.length === 1) {
    // Need to replace existing answer
    answers.forEach((answer) => {
      if (answer.questionId === questionId) {
        answer[answerField] = newValue;
      }
    });
  } else {
    answers.push({
      questionId: questionId,
      [answerField]: newValue,
    });
  }
  return answers;
};

export const updateCheckboxAnswer = (answers, questionId, choiceId) => {
  let updated = answers.splice(0);
  const answersForThisQuestionId = updated.filter(
    (answer) => answer.questionId === questionId,
  );
  // Let us see if there's an existing answer we need to toggle off
  if (
    answersForThisQuestionId.filter((answer) => answer.choiceId === choiceId)
      .length === 1
  ) {
    updated = updated.filter((answer) => answer.choiceId !== choiceId);
  } else {
    updated.push({
      questionId: questionId,
      choiceId: choiceId,
    });
  }
  return updated;
};

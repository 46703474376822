import * as Types from "../actions/Types";
import {
  CHECK_COLLECTIONS_AVAILABLE,
  DECLINE_THANKYOU,
  LOAD_PREVIOUS_RESPONSES,
  RELOAD_PREVIOUS_RESPONSES,
} from "../actions/Types";
import {
  confirmDetails,
  enrolPatient,
  getPreviousAnswers,
  hasCollectionsAvailable,
  impersonate,
  logon,
  logonSms,
  searchSelfLogon,
} from "../api/Patient";
import { getSurgeonsAtHospital } from "../api/Hospital";
import {
  clearPatientData,
  hasAvailableCollections,
  loadPatientDetails,
  previousResponsesLoaded,
  setAutoLogonDetails,
} from "../actions/PatientActions";
import Moment from "moment";
import {
  finishLoading,
  startLoading,
  surgeonsLoaded,
} from "../actions/CommonActions";
import { toastError, UNKNOWN_SURGEON } from "../Utils";
import { router } from "../components/app/Router";

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type === Types.SEARCH_PATIENT_SELF_LOGON) {
      searchSelfLogon(patientSearchRequest(action.payload)) // do the actual API call now
        .then((response) => {
          // handle a successful response (could still be no result found)...
          const { data } = response;
          if (data.success && data.patient && data.patient.id) {
            data.patient.dateOfBirth = Moment.utc(data.patient.dateOfBirth);
            dispatch(loadPatientDetails(data));
            router.navigate("/dashboard", { replace: true });
          } else {
            if (!data.success) {
              let message = data.message
                ? data.message
                : response.message
                ? response.message
                : "Network error.";
              toastError("Unable to logon at this time.", message);
            }
            router.navigate("/patient/notfound", { replace: true }); // back to the start
          }
        })
        .catch(() => {
          // mechanical failure
          router.navigate("/", { replace: true }); // force redirect back to the start
        });
    }
    if (action.type === Types.SET_PROCEDURE_HOSPITAL) {
      getSurgeonsAtHospital(action.payload.id).then((response) => {
        let surgeons = [...response.data];
        surgeons.push(UNKNOWN_SURGEON);
        dispatch(surgeonsLoaded(surgeons));
      });
    }
    if (action.type === Types.REGISTER_SUBMIT) {
      dispatch(startLoading());
      let callback = action.payload.callback;
      let request = {
        ...patientSearchRequest(action.payload.data),
        ...procedureRegistrationRequest(action.payload.data),
        ...contactDetails(action.payload.data),
        ..._studies(action.payload.data),
      };

      enrolPatient(request)
        .then((response) => {
          if (response.data.success) {
            callback && callback(true, response.data.message);
          } else {
            callback && callback(false, response.data.message);
          }
        })
        .catch((error) => {
          callback && callback(false, error.message);
        });
      dispatch(finishLoading());
    }
    if (action.type === Types.AUTO_LOGON_PATIENT_SMS) {
      dispatch(startLoading());
      logonSms(action.payload).then((response) => {
        const { data } = response;
        if (data.success) {
          // successful response for sms token
          dispatch(
            setAutoLogonDetails({
              id: action.payload.id,
              token: action.payload.token,
            }),
          );
          router.navigate("/patient/logon-sms");
        } else {
          let message = data.message
            ? data.message
            : response.message
            ? response.message
            : "Network error.";
          toastError("Unable to auto-logon at this time.", message);
          router.navigate("/patient/logon");
        }
      });
      dispatch(finishLoading());
    }
    if (action.type === Types.AUTO_LOGON_PATIENT) {
      dispatch(startLoading());
      logon(action.payload).then((response) => {
        const { data } = response;
        if (data.success && data.patient && data.patient.id) {
          // successful response
          if (data.patient.dateOfBirth == null) {
            dispatch(loadPatientDetails(data));
            router.navigate("/patient/logon-sms");
          } else {
            data.patient.dateOfBirth = Moment(data.patient.dateOfBirth);
            dispatch(loadPatientDetails(data));
            router.navigate("/", { replace: true });
          }
        } else {
          let message = data.message
            ? data.message
            : response.message
            ? response.message
            : "Network error.";
          toastError("Unable to auto-logon at this time.", message);
          router.navigate("/patient/logon");
        }
      });
      dispatch(finishLoading());
    }
    if (action.type === Types.IMPERSONATE_PATIENT) {
      dispatch(startLoading());
      impersonate(action.payload).then((response) => {
        const { data } = response;
        if (data.success && data.patient && data.patient.id) {
          // successful response
          data.patient.dateOfBirth = Moment(data.patient.dateOfBirth);
          dispatch(loadPatientDetails(data));
          router.navigate("/", { replace: true });
        } else {
          let message = data.message
            ? data.message
            : response.message
            ? response.message
            : "Network error.";
          toastError(message);
          router.navigate("/", { replace: true });
        }
      });
      dispatch(finishLoading());
    }
    if (action.type === Types.CONFIRM_PATIENT_DETAILS) {
      let callback = action.payload.callback;
      dispatch(startLoading());
      confirmDetails(action.payload.data)
        .then((response) => {
          const { data } = response;
          if (data.success && data.patient && data.patient.id) {
            // successful response
            data.patient.dateOfBirth = Moment.utc(data.patient.dateOfBirth);
            dispatch(loadPatientDetails(data));
          } else {
            callback && callback(response.data.message);
          }
        })
        .catch((error) => {
          callback && callback(error.message);
        });
      dispatch(finishLoading());
    }
    if (action.type === CHECK_COLLECTIONS_AVAILABLE) {
      hasCollectionsAvailable().then((response) => {
        dispatch(hasAvailableCollections(response.data));
      });
    }
    if (action.type === DECLINE_THANKYOU) {
      dispatch(clearPatientData());
    }
    if (action.type === LOAD_PREVIOUS_RESPONSES) {
      if (getState().patient.previousResponses.length === 0) {
        dispatch(startLoading());
        getPreviousAnswers().then((response) => {
          dispatch(previousResponsesLoaded(response.data));
        });
        dispatch(finishLoading());
      }
    }
    if (action.type === RELOAD_PREVIOUS_RESPONSES) {
      dispatch(startLoading());
      getPreviousAnswers().then((response) => {
        dispatch(previousResponsesLoaded(response.data));
      });
      dispatch(finishLoading());
    }
    return next(action);
  };

export function patientSearchRequest(data) {
  let { firstName, middleNames, lastName, dateOfBirth, postcode } = data;
  return { firstName, middleNames, lastName, dateOfBirth, postcode };
}

export function procedureRegistrationRequest(data) {
  let {
    leftShoulderReplacement,
    rightShoulderReplacement,
    leftHipReplacement,
    rightHipReplacement,
    leftKneeReplacement,
    rightKneeReplacement,
    leftKneeOsteotomy,
    rightKneeOsteotomy,
    surgeon,
    hospital,
    scheduledProcedureDate,
  } = data.newProcedure;
  let baseProc = { surgeon, hospital, scheduledProcedureDate };

  let procedures = [];
  if (leftShoulderReplacement) {
    procedures.push({
      ...baseProc,
      side: "Left",
      procedureType: "shoulder_replacement",
    });
  }
  if (rightShoulderReplacement) {
    procedures.push({
      ...baseProc,
      side: "Right",
      procedureType: "shoulder_replacement",
    });
  }
  if (leftHipReplacement) {
    procedures.push({
      ...baseProc,
      side: "Left",
      procedureType: "hip_replacement",
    });
  }
  if (rightHipReplacement) {
    procedures.push({
      ...baseProc,
      side: "Right",
      procedureType: "hip_replacement",
    });
  }
  if (leftKneeReplacement) {
    procedures.push({
      ...baseProc,
      side: "Left",
      procedureType: "knee_replacement",
    });
  }
  if (rightKneeReplacement) {
    procedures.push({
      ...baseProc,
      side: "Right",
      procedureType: "knee_replacement",
    });
  }
  if (leftKneeOsteotomy) {
    procedures.push({
      ...baseProc,
      side: "Left",
      procedureType: "knee_osteotomy",
    });
  }
  if (rightKneeOsteotomy) {
    procedures.push({
      ...baseProc,
      side: "Right",
      procedureType: "knee_osteotomy",
    });
  }

  return { procedures: procedures };
}

export function contactDetails(data) {
  let { homePhone, mobilePhone, email } = data;
  return { homePhone: homePhone, mobilePhone: mobilePhone, email };
}

function _studies(data) {
  return { studies: data.studies };
}

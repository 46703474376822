import { useEffect } from "react";
import { Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./AnnouncementDetails.css";
import {
  hideBannerCurrentUser,
  loadAnnouncements,
} from "../../actions/CommonActions";
import DOMPurify from "dompurify";

const AnnouncementDetails = () => {
  const dispatch = useDispatch();
  const announcements = useSelector((state) => state.app.announcements);

  useEffect(() => {
    setTimeout(() => {
      if (!announcements || announcements.length === 0) {
        dispatch(loadAnnouncements());
      }
    }, 3000);
  }, []);

  const onDismiss = (id) => {
    dispatch(hideBannerCurrentUser(id));
  };

  return (
    <div className={"announcements"}>
      {Array.isArray(announcements) &&
        announcements.length > 0 &&
        announcements.map((announcement, id) => (
          <Alert
            color="danger"
            isOpen={announcement.showAnnouncement}
            toggle={onDismiss.bind(this, id)}
            key={"multi-alert-" + id}
          >
            <span
              id={"announcement"}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(announcement.message),
              }}
            />
          </Alert>
        ))}
    </div>
  );
};

export default AnnouncementDetails;
import Moment from "moment";
import { toast } from "react-toastify";

export const IS_EXACTLY = { label: "exactly", value: "EQUALS" };
export const IS_BEFORE = { label: "before", value: "BEFORE" };
export const IS_AFTER = { label: "after", value: "AFTER" };

export function isPartialPostcodeValid(postcode) {
  // Given that we support partial postcode matching, we can't use the validateAustralianPostcode method from Utils.js
  return /^[0-9]{1,4}$/.test(postcode);
}

export function isSearchDateValid(date) {
  return Moment(date, "DD/MM/YYYY", true).isValid();
}

export function apiErrorResponse(error, msg) {
  if (
    (error?.response?.status === 400 || error?.response?.status === 404) &&
    error?.response?.data?.message &&
    typeof error.response.data.message === "string"
  ) {
    toast.error(error.response.data.message);
  } else {
    toast.error(msg);
  }
}

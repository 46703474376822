import { get } from "./Api";

const BASE_URL = "/api/reporting";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function getProcedureRecruitment(data) {
  return get(
    url("procedure/recruitment"),
    data,
    "Unable to fetch procedure recruitment data.",
  );
}

export function getProcedureTypes(data) {
  return get(
    url("procedure/procedure-types"),
    data,
    "Unable to fetch procedure types data.",
  );
}

export function getPatientAgeDemographics(data) {
  return get(
    url("patient/demographics/age"),
    data,
    "Unable to fetch patient age demographic data.",
  );
}

export function getStudyCollectionStatistics(data) {
  return get(
    url("study-collection"),
    data,
    "Unable to fetch procedure statistics data.",
  );
}

export function getMatchedRegistryProcedures(data) {
  return get(
    url("registry-procedure/matched"),
    data,
    "Unable to fetch matched registry procedure data.",
  );
}

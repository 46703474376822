import {
  CREATE,
  EDIT,
  isAoaUser,
  isEmptyOrNull,
  PRIVATE,
  PUBLIC,
  SAVE,
  validEmailSuffix,
  VIEW,
} from "../../../Utils";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Linking from "../../study/Linking";
import StakeholderGroupHospitals from "./StakeholderGroupHospitals";
import StakeholderGroupUsers from "./StakeholderGroupUsers";
import PropTypes from "prop-types";
import SimpleStateSelector from "../../common/SimpleStateSelector";
import { loadStudies } from "../../../actions/CommonActions";
import {
  createStakeHolderGroup,
  editStakeHolderGroup,
  getStakeholderGroup,
} from "../../../api/StakeholderGroup";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getStudies } from "../../../api/Study";
import "./StakeholderGroupModal.css";
import ButtonBar from "../../common/ButtonBar";
import { useEffect, useReducer } from "react";

const StakeholderGroupModal = ({
  modalStakeholderGroupId,
  operation,
  isOpen,
  onConfirm,
  onExit,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const studies = useSelector((state) => state.studies);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      emailSuffix: "",
      state: null,
      publicPrivateStatus: "",
      studyLinks: [],
      error: "",
      currentlySubmitting: false,
      saveStakeHolderError: "",
      emailSuffixes: [""],
      hospitalLinks: [],
      loadStakeholderGroupError: "",
      activeTab: "3",
    },
  );

  useEffect(() => {
    if (operation === CREATE) {
      return;
    }
    if (modalStakeholderGroupId !== null) {
      setState({ loadStakeholderGroupError: "" });
      getStakeholderGroup(modalStakeholderGroupId)
        .then((response) => {
          setState({
            name: response.data.name,
            publicPrivateStatus: response.data.isPrivate ? PRIVATE : PUBLIC,
            studyIds: response.data.studyIds,
            state: response.data.state,
            emailSuffixes:
              response.data.emailSuffixes.length > 0
                ? response.data.emailSuffixes
                : state.emailSuffixes,
          });
          // Make sure we know about all studies that are linked
          let haveAllStudies = true;
          for (const incomingStudy of response.data.studyIds) {
            if (
              studies.filter((study) => study.id === incomingStudy).length === 0
            ) {
              haveAllStudies = false;
              break;
            }
          }
          if (haveAllStudies) {
            // I know about all the studies that are linked to this user - just set them in the component state
            let studyLinks = [];
            for (const incomingStudy of response.data.studyIds) {
              studyLinks.push(
                studies.filter((study) => study.id === incomingStudy)[0],
              );
            }
            setState({
              studyLinks: studyLinks.sort((a, b) =>
                a.name.localeCompare(b.name),
              ),
            });
          } else {
            // We should indicate that the list of studies is stale - so let's make sure our redux state is current
            dispatch(loadStudies());
            // But we also have to keep going so we'll do the call directly and set the links in a callback
            getStudies().then((innerResponse) => {
              let fresh = innerResponse.data;
              let studyLinks = [];
              for (const incomingStudy of response.data.studyIds) {
                studyLinks.push(
                  fresh.filter((study) => study.id === incomingStudy)[0],
                );
              }
              setState({
                studyLinks: studyLinks.sort((a, b) =>
                  a.name.localeCompare(b.name),
                ),
              });
            });
          }
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({
              loadStakeholderGroupError: "Error loading StakeholderGroup",
            });
          } else {
            setState({
              loadStakeholderGroupError: `Error loading StakeholderGroup. ${error.response}`,
            });
          }
        });
    }
  }, []);

  const setActiveTab = (tab) => {
    if (state.activeTab !== tab) {
      setState({ activeTab: tab });
    }
  };

  const getStudyIds = () => {
    let allStudyIds = [];
    state.studyLinks.forEach((study) => allStudyIds.push(study.id));
    return allStudyIds;
  };

  const handleStudyLinkChange = (links) => {
    setState({
      studyLinks: links.sort((a, b) => a.name.localeCompare(b.name)),
    });
  };

  const handleEmailChange = (i) => (e) => {
    let emailSuffixes = [...state.emailSuffixes];
    emailSuffixes[i] = e.target.value;
    setState({ emailSuffixes });
  };

  const handleDelete = (i) => (e) => {
    e.preventDefault();
    if (state.emailSuffixes.length > 1) {
      let emailSuffixes = [
        ...state.emailSuffixes.slice(0, i),
        ...state.emailSuffixes.slice(i + 1),
      ];
      setState({ emailSuffixes });
    }
  };

  const addEmailSuffix = (e) => {
    e.preventDefault();
    let emailSuffixes = state.emailSuffixes.concat([""]);
    setState({ emailSuffixes });
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState({ [id]: value });
  };

  const handleSubmit = () => {
    setState({ currentlySubmitting: true });

    let studies = getStudyIds();
    const payload = {
      name: state.name,
      state: state.state,
      isPrivate: state.publicPrivateStatus === PRIVATE,
      emailSuffixes: state.emailSuffixes,
      studyIds: studies,
    };
    if (operation === CREATE) {
      createStakeHolderGroup(payload)
        .then(() => {
          onConfirm(`Stakeholder Group ${state.name} was successfully created`);
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({ error: "Error creating the Stakeholder" });
          } else {
            setState({ error: error.response.data });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    }
    if (operation === EDIT) {
      editStakeHolderGroup(modalStakeholderGroupId, payload)
        .then(() => {
          onConfirm(`Stakeholder Group ${state.name} was successfully edited`);
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({ error: "Error editing the Stakeholder" });
          } else {
            setState({ error: error.response });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    }
  };

  const formValid = () => {
    let isValidName = !isEmptyOrNull(state.name);
    let privateStateValid =
      state.state === null && state.publicPrivateStatus === PRIVATE;
    return (
      isValidName &&
      (state.publicPrivateStatus === PUBLIC || privateStateValid) &&
      !isEmptyOrNull(state.publicPrivateStatus)
    );
  };

  const getButtons = () => {
    const buttons = [];
    if (isAoaUser(user)) {
      buttons.push(
        <Button
          color="primary"
          disabled={!formValid() && !state.currentlySubmitting}
          onClick={handleSubmit}
        >
          {operation === CREATE ? CREATE : SAVE}
        </Button>,
      );
    }
    buttons.push(<Button onClick={onExit}>Cancel</Button>);
    return buttons;
  };

  return (
    <Modal
      className={"edit-stakeholder-group-modal"}
      isOpen={isOpen}
      toggle={onExit}
      size={"lg"}
      onClosed={onExit}
    >
      <ModalHeader toggle={onExit}>
        {" "}
        {operation}
        {" Stakeholder Group "}
      </ModalHeader>
      <ModalBody>
        <div className={"edit-stakeholder-group-modal"}>
          <div className={"field-row"}>
            <em>Note: Fields marked with an * are required</em>
          </div>
          <Form>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={3} md={3} lg={3}>
                  <Label className={"form-label"} for="name">
                    Name*
                  </Label>
                </Col>
                <Col xs={9} md={9} lg={9}>
                  <Input
                    autoFocus
                    required
                    type="text"
                    id="name"
                    value={state.name}
                    valid={
                      (operation === VIEW && isEmptyOrNull(state.name)) ||
                      (operation === CREATE && isEmptyOrNull(state.name))
                        ? null
                        : !isEmptyOrNull(state.name)
                    }
                    onChange={handleChange}
                    readOnly={operation === VIEW}
                  />
                  {isEmptyOrNull(state.name) && (
                    <FormFeedback>
                      Stakeholder Group name is required and a value must be
                      provided.
                    </FormFeedback>
                  )}
                </Col>
              </Row>
              <Row className={"field-row"}>
                <Col xs={3} md={3} lg={3}>
                  <Label className={"form-label"}>Status*</Label>
                </Col>
                <Col xs={9} md={9} lg={9}>
                  <ButtonGroup>
                    {/* Special case - we don't allow changing a Stakeholder from public to private,
                      so disable it if not creating a new Stakeholder */}
                    <Button
                      className={"private-button"}
                      color={
                        state.publicPrivateStatus === PRIVATE
                          ? "primary"
                          : "secondary"
                      }
                      onClick={() => setState({ publicPrivateStatus: PRIVATE })}
                      disabled={operation !== CREATE}
                      active={state.publicPrivateStatus === PRIVATE}
                    >
                      Private
                    </Button>
                    <Button
                      color={
                        state.publicPrivateStatus === PUBLIC
                          ? "primary"
                          : "secondary"
                      }
                      onClick={() => setState({ publicPrivateStatus: PUBLIC })}
                      disabled={operation !== CREATE}
                      active={state.publicPrivateStatus === PUBLIC}
                    >
                      Public
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>{" "}
              {state.publicPrivateStatus === PUBLIC && (
                <Row className={"field-row"}>
                  <Col xs={3} md={3} lg={3}>
                    <Label className={"form-label"} for="state">
                      State
                    </Label>
                  </Col>
                  <Col xs={9} md={9} lg={9}>
                    <SimpleStateSelector
                      value={state.state}
                      onChange={(value) => setState({ state: value })}
                      clearable={false}
                      usePadding={false}
                      useShortNames={true}
                      autoFocus={false}
                      tabIndexFirst={false}
                      disabled={operation === VIEW}
                    />
                  </Col>
                </Row>
              )}
              <Row className={"field-row"}>
                <Col xs={12} sm={3} md={5} lg={3}>
                  <Label className={"form-label"} for="emailSuffixes">
                    Email Suffixes
                  </Label>
                </Col>
                <Col xs={12} sm={9} md={7} lg={9}>
                  {state.emailSuffixes.map((emailSuffix, index) => (
                    <FormGroup key={`form-group-${index}`}>
                      <InputGroup key={`input-group-${index}`}>
                        <InputGroupText>@</InputGroupText>
                        <Input
                          type="text"
                          onChange={handleEmailChange(index)}
                          disabled={operation === VIEW}
                          valid={
                            isEmptyOrNull(emailSuffix)
                              ? null
                              : !isEmptyOrNull(emailSuffix) &&
                                validEmailSuffix(emailSuffix)
                          }
                          value={emailSuffix}
                        />
                        <Button
                          disabled={
                            state.emailSuffixes.length === 1 ||
                            operation === VIEW
                          }
                          onClick={handleDelete(index)}
                        >
                          <FaTrash />
                        </Button>
                        <Button
                          disabled={operation === VIEW}
                          onClick={addEmailSuffix}
                        >
                          <FaPlusCircle />
                        </Button>
                        {!validEmailSuffix(emailSuffix) && (
                          <FormFeedback>
                            Email suffix needs to be a valid domain name.
                          </FormFeedback>
                        )}
                      </InputGroup>
                    </FormGroup>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <Alert color="danger" isOpen={!isEmptyOrNull(state.error)}>
                    {state.error}
                  </Alert>
                </Col>
              </Row>
              <div>
                <Nav tabs>
                  <NavItem>
                    {" "}
                    {operation !== CREATE && (
                      <NavLink
                        className={classnames({
                          active: state.activeTab === "1",
                        })}
                        onClick={() => {
                          setActiveTab("1");
                        }}
                      >
                        Hospitals
                      </NavLink>
                    )}
                  </NavItem>
                  <NavItem>
                    {operation !== CREATE && (
                      <NavLink
                        className={classnames({
                          active: state.activeTab === "2",
                        })}
                        onClick={() => {
                          setActiveTab("2");
                        }}
                      >
                        Users
                      </NavLink>
                    )}
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "3",
                      })}
                      onClick={() => {
                        setActiveTab("3");
                      }}
                    >
                      Studies
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={state.activeTab}>
                  <TabPane tabId="1">
                    <StakeholderGroupHospitals
                      operation={operation}
                      stakeholderGroupId={modalStakeholderGroupId}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <StakeholderGroupUsers
                      operation={operation}
                      stakeholderGroupId={modalStakeholderGroupId}
                      readOnly={true}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <Linking
                      readOnly={operation === VIEW}
                      links={state.studyLinks}
                      sourceObjectName={"user"}
                      onLinkChange={handleStudyLinkChange}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </FormGroup>
            <ButtonBar buttons={getButtons()} />
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

StakeholderGroupModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  operation: PropTypes.string.isRequired,
  modalStakeholderGroupId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
};

export default StakeholderGroupModal;

import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import PropTypes from "prop-types";
import "./FuzzyMatchingModal.css";
import { deleteNeverMatch, searchNeverMatches } from "../../api/Matching";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { useDispatch } from "react-redux";
import { FaTrash } from "react-icons/fa";
import ButtonBar from "../common/ButtonBar";
import { isEmptyOrNull } from "../../Utils";
import CreateNeverMatchModal from "./CreateNeverMatchModal";
import { apiErrorResponse } from "./Utils";
import { useEffect, useReducer } from "react";
import { handleEnterKeyPress } from "../../Utils";

const NeverMatchesModal = ({
  onExit = () => null,
  open = false,
  itemsCountPerPage = 10,
  pageRangeDisplayed = 5,
  firstPage = 1,
}) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      totalItemsCount: 0,
      activePage: 1,
      data: [],
      sourceIdSearch: "",
      promsIdSearch: "",
      showCreateModal: false,
    },
  );

  useEffect(() => {
    fetchData();
  }, [state.activePage]);

  const deleteNeverMatchAction = (sourceId, procedureId) => {
    dispatch(startLoading());
    deleteNeverMatch(sourceId, procedureId)
      .then((response) => {
        if (response.data) {
          toast.success("Never match deleted");
          fetchData();
        }
      })
      .catch((error) => {
        let msg =
          "An error occurred while deleting the never match. Please try again.";
        apiErrorResponse(error, msg);
      })
      .finally(() => {
        dispatch(finishLoading());
      });
  };

  const getDeleteButton = (row) => {
    return (
      <Button
        className={"action-button"}
        outline={true}
        color={"danger"}
        onClick={() => deleteNeverMatchAction(row.sourceId, row.procedureId)}
        title={"Delete Never Match"}
      >
        <FaTrash />
      </Button>
    );
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  const fetchData = () => {
    let payload = {
      page: state.activePage - 1,
      size: itemsCountPerPage,
      sourceId: isEmptyOrNull(state.sourceIdSearch)
        ? null
        : state.sourceIdSearch,
      procedureId: isEmptyOrNull(state.procedureIdSearch)
        ? null
        : state.procedureIdSearch,
    };
    dispatch(startLoading());
    searchNeverMatches(payload)
      .then((response) => {
        setState({
          data: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
        });
      })
      .catch((error) => {
        let msg =
          "An error occurred while fetching the never matches. Please try again.";
        apiErrorResponse(error, msg);
      })
      .finally(() => dispatch(finishLoading()));
  };

  const search = () => {
    fetchData();
    setState({ activePage: firstPage });
  };

  const getButtons = () => {
    const buttons = [];
    buttons.push(
      <Button onClick={onExit}>Close</Button>,
      <Button
        color="primary"
        onClick={() => setState({ showCreateModal: !state.showCreateModal })}
      >
        Create
      </Button>,
    );
    return buttons;
  };

  return (
    <Modal
      isOpen={open}
      toggle={onExit}
      className={"never-matches-modal"}
      keyboard={false}
    >
      <ModalHeader toggle={onExit}>Never Matches</ModalHeader>
      <ModalBody>
        <Row className={"my-3"}>
          <Col>
            <Input
              type={"text"}
              value={state.sourceIdSearch}
              placeholder={"Source ID"}
              onChange={(event) =>
                setState({ sourceIdSearch: event.target.value })
              }
              onKeyPress={(event) => handleEnterKeyPress(event, search)}
            />
          </Col>
          <Col>
            <Input
              type={"text"}
              value={state.procedureIdSearch}
              placeholder={"PROMs ID"}
              onChange={(event) =>
                setState({ procedureIdSearch: event.target.value })
              }
              onKeyPress={(event) => handleEnterKeyPress(event, search)}
            />
          </Col>
          <Col className={"text-start"}>
            <Button color={"primary"} onClick={search}>
              Search
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            size={"sm"}
            bordered
            striped
            responsive
            className={"never-match-table"}
          >
            <thead>
              <tr>
                <th>Source ID</th>
                <th>PROMs Procedure ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {state.data.map((row, i) => {
                return (
                  <tr key={i}>
                    <td>{row.sourceId}</td>
                    <td>{row.procedureId}</td>
                    <td>{getDeleteButton(row)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Pagination
            activePage={state.activePage}
            totalItemsCount={state.totalItemsCount}
            itemsCountPerPage={itemsCountPerPage}
            pageRangeDisplayed={pageRangeDisplayed}
            onChange={changePage}
            hideDisabled
            innerClass={"pagination pagination-sm"}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        </div>
        <ButtonBar buttons={getButtons()} />
      </ModalBody>
      {state.showCreateModal && (
        <CreateNeverMatchModal
          onExit={() => setState({ showCreateModal: !state.showCreateModal })}
          onSave={fetchData}
          open={state.showCreateModal}
        />
      )}
    </Modal>
  );
};

NeverMatchesModal.propTypes = {
  onExit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  firstPage: PropTypes.number,
};

export default NeverMatchesModal;

import { Button, Col, Label, Row, Table } from "reactstrap";
import { FaLink, FaUnlink } from "react-icons/fa";
import "./Linking.css";
import PropTypes from "prop-types";
import SurgeonSearch from "./Search";

const Linking = ({
  links = [],
  readOnly = true,
  sourceObjectName,
  onLinkChange,
}) => {
  const handleUnlink = (surgeon) => {
    let removeId = surgeon.id;
    let filtered = links.filter((surgeon) => surgeon.id !== removeId);
    onLinkChange(filtered);
  };

  const handleLink = (surgeon) => {
    let newLinks = links.slice();
    newLinks.push(surgeon);
    onLinkChange(newLinks);
  };

  return (
    <div>
      <Row>
        <Col sm={3}>
          <Label className={"section-text"}>Linked Surgeons</Label>
        </Col>
        <Col sm={9}>
          <Table striped>
            <tbody>
              {links.length > 0 ? (
                links.map((surgeon) => (
                  <tr key={`surgeon-key-${surgeon.id}`}>
                    <td className={"surgeon-entry"}>
                      <span className={"surgeon-text"}>
                        {`${surgeon.firstName} ${surgeon.lastName} (${surgeon.surgeonCode})`}
                      </span>
                    </td>
                    <td>
                      {!readOnly && (
                        <span className={"unlink-button"}>
                          <Button
                            className={"action-button"}
                            outline
                            title={`Unlink ${sourceObjectName.toLowerCase()} from surgeon`}
                            onClick={() => handleUnlink(surgeon)}
                          >
                            <FaUnlink />
                          </Button>
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <em>No linked surgeons set</em>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {!readOnly && (
        <div>
          <hr />
          <Row>
            <Col sm={12}>
              <Label className={"section-text"}>Available Surgeons</Label>
            </Col>
          </Row>
          <SurgeonSearch
            excludeIds={links.map(function (surgeon) {
              return surgeon.id;
            })}
            actionButtons={[
              {
                icon: <FaLink onClick={() => handleLink} />,
                title: `Link ${sourceObjectName.toLowerCase()} to surgeon`,
                action: handleLink,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

Linking.propTypes = {
  onLinkChange: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  sourceObjectName: PropTypes.string.isRequired,
};

export default Linking;

import { Fragment, useEffect, useLayoutEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Label, Row, Table, Tooltip } from "reactstrap";
import { isEmptyOrNull, isSurgeonUser } from "../../../Utils";
import {
  hospitalsByStudy,
  hospitalsByStudyAndSurgeon,
  hospitalsBySurgeon,
  studiesByHospital,
  studiesByHospitalAndSurgeon,
  studiesBySurgeon,
  surgeonsByHospital,
  surgeonsByHospitalAndStudy,
  surgeonsByStudy,
} from "./Utils";
import Pagination from "react-js-pagination";
import { loadSurgeons } from "../../../actions/SurgeonActions";
import Select from "react-select";
import "./Search.css";
import {
  finishLoading,
  loadHospitals,
  loadStudies,
  startLoading,
} from "../../../actions/CommonActions";
import { searchPatients } from "../../../api/Patient";
import { FaExclamationCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import Moment from "moment";
import Responsive from "react-responsive";
import PatientSearchTextField from "./PatientSearchTextField";
import GenericSelector from "../../common/GenericSelector";
import { useOnUpdate, usePrevious } from "../../CustomHooks";

const Search = ({
  actionButtons = [],
  itemsCountPerPage = 10,
  pageRangeDisplayed = 5,
  resultsVersion,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const studies = useSelector((state) => state.studies);
  const hospitals = useSelector((state) => state.hospitals);
  const surgeons = useSelector((state) => state.surgeons);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedHospital: null, // auto selected hospital,
      selectedStudy: null, // study, and
      selectedSurgeon: null, // surgeon
      sH: null, // manually selected hospital
      sS: null, // study, and
      sG: null, // surgeon
      surgeonSelectorOptions: [],
      hospitalSelectorOptions: [],
      studySelectorOptions: [],
      textFilter: {
        value: null,
        isPostcode: false,
        isMaybePostcode: false,
        isDateOfBirth: false,
        isMaybeDateOfBirth: false,
        isName: false,
        isMaybeName: false,
        isPatientId: false,
        isMaybePatientId: false,
      },
      patients: [],
      totalItemsCount: 0,
      activePage: 1,
      resultsHidden: true,
      version: 0,
      toolTipId: null,
    },
  );

  useEffect(() => {
    surgeons.length === 0 && dispatch(loadSurgeons());
    hospitals.length === 0 && dispatch(loadHospitals());
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useLayoutEffect(() => {
    let initiallySelectedSurgeon,
      initiallySelectedStudy,
      initiallySelectedHospital;
    if (isSurgeonUser(user)) {
      // We only do this for surgeons, as other user types may want to include "Unknown" surgeons in their results
      initiallySelectedSurgeon = surgeons[0];
    }
    if (hospitals.length === 1) {
      initiallySelectedHospital = hospitals[0];
    }
    if (studies.length === 1) {
      initiallySelectedStudy = studies[0];
    }
    setState({
      hospitalSelectorOptions: hospitals,
      studySelectorOptions: studies,
      surgeonSelectorOptions: surgeons,
      selectedHospital: !!initiallySelectedHospital
        ? initiallySelectedHospital
        : state.selectedHospital,
      selectedStudy: !!initiallySelectedStudy
        ? initiallySelectedStudy
        : state.selectedStudy,
      selectedSurgeon: !!initiallySelectedSurgeon
        ? initiallySelectedSurgeon
        : state.selectedSurgeon,
    });
  }, [surgeons]); // Need to wait for surgeons to be loaded

  useOnUpdate(() => {
    if (surgeons.length === 1 && isSurgeonUser(user)) {
      // We only do this for surgeons, as other user types may want to include "Unknown" surgeons in their results
      updateSelectors("G", surgeons[0], false);
    }
    setState({ surgeonSelectorOptions: surgeons });
  }, [surgeons]);

  useOnUpdate(() => {
    studies.length === 1 && updateSelectors("S", studies[0], false);
    setState({ studySelectorOptions: studies });
  }, [studies]);

  useOnUpdate(() => {
    hospitals.length === 1 && updateSelectors("H", hospitals[0], false);
    setState({ hospitalSelectorOptions: hospitals });
  }, [hospitals]);

  const prevActivePage = usePrevious(state.activePage);

  useOnUpdate(() => {
    if (prevActivePage !== state.activePage) {
      submitSearch(state.activePage);
    }
  }, [resultsVersion, state.activePage]);

  const setSelectedHospital = (value) => {
    updateSelectors("H", value, false);
  };

  const setSelectedStudy = (value) => {
    updateSelectors("S", value, false);
  };

  const setSelectedSurgeon = (value) => {
    updateSelectors("G", value, false);
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  /**
   * @param {number} activePage required to ensure we are using the correct activePage value
   */
  const submitSearch = (activePage) => {
    let payload = {
      studyIds: state.selectedStudy != null ? state.selectedStudy.id : null,
      surgeonIds:
        state.selectedSurgeon != null ? state.selectedSurgeon.id : null,
      hospitalIds:
        state.selectedHospital != null ? state.selectedHospital.id : null,
      dateOfBirth: state.textFilter.isDateOfBirth
        ? state.textFilter.value
        : null,
      postcode: state.textFilter.isPostcode ? state.textFilter.value : null,
      name: state.textFilter.isName ? state.textFilter.value : null,
      patientId: state.textFilter.isPatientId
        ? state.textFilter.value.trim().toLocaleLowerCase().split("id:")[1]
        : null,
      email: state.textFilter.isEmail ? state.textFilter.value : null,
      phoneNumber: state.textFilter.isPhoneNumber
        ? state.textFilter.value
        : null,
      page: activePage - 1,
      size: itemsCountPerPage,
    };
    dispatch(startLoading());
    searchPatients(payload)
      .then((response) => {
        setState({
          patients: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
          resultsHidden: false,
        });
      })
      .finally(() => dispatch(finishLoading()));
  };

  const handleSearchButtonPress = () => {
    submitSearch(1);
  };

  const renderSurgeonOption = (surgeon) => {
    return (
      <div>
        Dr {surgeon.lastName}, {surgeon.firstName}
      </div>
    );
  };

  const renderHospitalOption = (hospital) => {
    return `${hospital.name} (${hospital.state.shortName})`;
  };

  const getHospitalSelector = () => {
    return (
      <Select
        options={state.hospitalSelectorOptions}
        onChange={(e) => setSelectedHospital(e)}
        isClearable={true}
        isSearchable={true}
        getOptionLabel={(option) => renderHospitalOption(option)}
        getOptionValue={(option) => option.id}
        value={state.selectedHospital}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
        isDisabled={
          !!state.hospitalSelectorOptions &&
          state.hospitalSelectorOptions.length === 1 &&
          state.selectedHospital != null
        }
      />
    );
  };

  /**
   *
   * @param selectorChar a character code indicating which selector was modified
   * @param value the hospital, study, or surgeon selected
   * @param recur when present indicates selectorChar is auto-selected so don't commit it to the state. Calls with this
   * set to true will update the selector options but not register the selector as being manually chosen.
   */
  const updateSelectors = (selectorChar, value, recur) => {
    let hospitalOptions = state.hospitalSelectorOptions;
    let studyOptions = state.studySelectorOptions;
    let surgeonOptions = state.surgeonSelectorOptions;

    if (
      isEmptyOrNull(hospitals) ||
      isEmptyOrNull(studies) ||
      isEmptyOrNull(surgeons)
    )
      return;

    let sH = state.sH;
    let sS = state.sS;
    let sG = isSurgeonUser(user) ? surgeons[0] : state.sG;

    switch (selectorChar) {
      case "H":
        sH = value;
        break;
      case "S":
        sS = value;
        break;
      case "G":
        sG = value;
        break;
    }

    // Returns an arbitrary code representing the relative states of the selectors
    // Comments indicate what is selected for each code
    const valueState = (H, S, G) => {
      if (!!sH && !!sS && !sG) return 0; // H' S'
      if (!!sH && !sS && !!sG) return 1; // H' G'
      if (!sH && !!sS && !!sG) return 2; // S' G'
      if (!sH && !!sS && !sG) return 3; // S'
      if (!sH && !sS && !!sG) return 4; // G'
      if (!!sH && !sS && !sG) return 5; // H'
      if (!!sS && !!sG && !!sH) return 6; // H' S' G'
      return 7;
    };

    switch (valueState(sH, sS, sG)) {
      case 0: // H' S'
        surgeonOptions = surgeonsByHospitalAndStudy(user, sH, sS, surgeons);
        break;
      case 1: // H' G'
        studyOptions = studiesByHospitalAndSurgeon(user, sH, studies, sG);
        break;
      case 2: // S' G'
        hospitalOptions = hospitalsByStudyAndSurgeon(user, hospitals, sS, sG);
        break;
      case 3: // S'
        hospitalOptions = hospitalsByStudy(user, hospitals, sS, surgeons);
        studyOptions = studies;
        surgeonOptions = surgeonsByStudy(user, hospitals, sS, surgeons);
        break;
      case 4: // G'
        hospitalOptions = hospitalsBySurgeon(user, hospitals, sG);
        studyOptions = studiesBySurgeon(user, studies, sG);
        surgeonOptions = surgeons;
        break;
      case 5: // H'
        hospitalOptions = hospitals;
        studyOptions = studiesByHospital(user, sH, studies, surgeons);
        surgeonOptions = surgeonsByHospital(user, sH, studies, surgeons);
        break;
      case 6: // H' S' G'
        hospitalOptions = hospitalsByStudyAndSurgeon(user, hospitals, sS, sG);
        studyOptions = studiesByHospitalAndSurgeon(user, sH, studies, sG);
        surgeonOptions = surgeonsByHospitalAndStudy(user, sH, sS, surgeons);
        break;
      default:
        hospitalOptions = hospitals;
        studyOptions = studies;
        surgeonOptions = surgeons;
        break;
    }

    setState({
      sH: !!recur ? state.sH : sH,
      sS: !!recur ? state.sS : sS,
      sG: !!recur ? state.sG : sG,
      selectedHospital: sH
        ? sH
        : hospitalOptions && hospitalOptions.length === 1
          ? hospitalOptions[0]
          : null,
      selectedStudy: sS
        ? sS
        : studyOptions && studyOptions.length === 1
          ? studyOptions[0]
          : null,
      selectedSurgeon: sG,
      hospitalSelectorOptions: hospitalOptions,
      studySelectorOptions: studyOptions,
      surgeonSelectorOptions: surgeonOptions,
    });
  };

  const searchDisabled = () => {
    const {
      isMaybeDateOfBirth,
      isMaybeName,
      isMaybePostcode,
      isMaybePatientId,
      isMaybePhoneNumber,
    } = state.textFilter;
    return (
      isMaybeDateOfBirth ||
      isMaybeName ||
      isMaybePostcode ||
      isMaybePatientId ||
      isMaybePhoneNumber
    );
  };

  const filterSurgeonOption = (option, filter) => {
    let firstLast = `${option.data.firstName} ${option.data.lastName}`;
    return firstLast.toLowerCase().includes(filter.toLowerCase());
  };

  const getResultsTable = () => {
    return (
      <Fragment>
        <Responsive maxWidth={767}>
          <Table
            className={"patient-search-table"}
            size={"sm"}
            bordered
            striped
            responsive
          >
            <tbody>
              {state.patients.map((patient, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          First Name:
                        </Col>
                        <Col xs={7}>{patient.firstName}</Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          Middle Names:
                        </Col>
                        <Col xs={7}>{patient.middleNames}</Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          Last Name:
                        </Col>
                        <Col xs={7}>{patient.lastName}</Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          DOB:
                        </Col>
                        <Col xs={7}>
                          {Moment(patient.dateOfBirth).format("DD/MM/YYYY")}
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          Postcode:
                        </Col>
                        <Col xs={7}>{patient.postcode}</Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          Details:
                        </Col>
                        <Col xs={7}>
                          {patient.deceased && (
                            <span>
                              <span
                                className={"deceased-span"}
                                key={"deceased-span-" + i}
                              >
                                <a id={"deceased-link-" + i} href={"#"}>
                                  <FaExclamationCircle
                                    key={"icon-" + i}
                                    color="red"
                                  />
                                </a>
                              </span>
                              <Tooltip
                                key={"tooltip-" + i}
                                placement="top"
                                isOpen={state.toolTipId === i}
                                target={"deceased-link-" + i}
                                toggle={() =>
                                  setState({
                                    toolTipId: state.toolTipId === i ? null : i,
                                  })
                                }
                              >
                                Patient is deceased
                              </Tooltip>
                            </span>
                          )}
                          {!patient.deceased && (
                            <span className={"deceased-span"} />
                          )}
                          <span className={"action-buttons"}>
                            {actionButtons.map((button) => (
                              <Button
                                key={"patient-" + patient.id}
                                id={"patient-" + patient.id}
                                className={"action-button"}
                                outline
                                title={button.title}
                                onClick={() => button.action(patient)}
                              >
                                {button.icon}
                              </Button>
                            ))}
                          </span>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={768}>
          <Table className={"patient-search-table"}>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle Names</th>
                <th>Last Name</th>
                <th>DOB</th>
                <th>Postcode</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {state.patients.map((patient, i) => {
                return (
                  <tr key={i}>
                    <td>{patient.firstName}</td>
                    <td>{patient.middleNames}</td>
                    <td>{patient.lastName}</td>
                    <td>{Moment(patient.dateOfBirth).format("DD/MM/YYYY")}</td>
                    <td>{patient.postcode}</td>
                    <td className="buttons">
                      <span className={"action-buttons"}>
                        {actionButtons.map((button) => (
                          <Button
                            key={"patient-" + patient.id}
                            id={"patient-" + patient.id}
                            className={"action-button"}
                            outline
                            title={button.title}
                            onClick={() => button.action(patient)}
                          >
                            {button.icon}
                          </Button>
                        ))}
                      </span>
                      {patient.deceased && (
                        <span>
                          <span
                            className={"deceased-span"}
                            key={"deceased-span-" + i}
                          >
                            <a id={"deceased-link-" + i} href={"#"}>
                              <FaExclamationCircle
                                key={"icon-" + i}
                                color={"red"}
                              />
                            </a>
                          </span>
                          <Tooltip
                            key={"tooltip-" + i}
                            placement="top"
                            isOpen={state.toolTipId === i}
                            target={"deceased-link-" + i}
                            toggle={() =>
                              setState({
                                toolTipId: state.toolTipId === i ? null : i,
                              })
                            }
                          >
                            Patient is deceased
                          </Tooltip>
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
      </Fragment>
    );
  };

  return (
    <div className={"patient-search"}>
      <Row className={"field-row"}>
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={2}
          className={"my-auto text-end"}
        >
          <Label for="hospital">Hospital</Label>
        </Col>
        <Col xs={12} sm={12} md={9} lg={9} xl={10} className={"search-filter"}>
          {getHospitalSelector()}
        </Col>
      </Row>
      <Row className={"field-row"}>
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={2}
          className={"my-auto text-end"}
        >
          <Label for="study">Study</Label>
        </Col>
        <Col xs={12} sm={12} md={9} lg={9} xl={4} className={"search-filter"}>
          <GenericSelector
            selected={state.selectedStudy}
            changeCallback={setSelectedStudy}
            options={state.studySelectorOptions}
            clearable={true}
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={2}
          className={"my-auto text-end"}
        >
          <Label for="study">Surgeon</Label>
        </Col>
        <Col xs={12} sm={12} md={9} lg={9} xl={4} className={"search-filter"}>
          <Select
            options={state.surgeonSelectorOptions}
            onChange={setSelectedSurgeon}
            isClearable={true}
            isSearchable={true}
            getOptionLabel={(option) => renderSurgeonOption(option)}
            getOptionValue={(option) => option.id}
            value={state.selectedSurgeon}
            isDisabled={isSurgeonUser(user)}
            styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            filterOption={filterSurgeonOption}
          />
        </Col>
      </Row>
      <Row className={"align-items-end"}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={10}
          className={"search-filter"}
        >
          <PatientSearchTextField
            updateCallback={(value) => setState({ textFilter: value })}
            doSearchCallback={handleSearchButtonPress}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={2} xl={2}>
          <Button
            block
            id={"search"}
            color={"primary"}
            onClick={handleSearchButtonPress}
            disabled={searchDisabled()}
          >
            Search
          </Button>
        </Col>
      </Row>
      {state.resultsHidden !== true && (
        <div>
          <Row className={"patient-table"}>{getResultsTable()}</Row>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Pagination
              activePage={state.activePage}
              totalItemsCount={state.totalItemsCount}
              itemsCountPerPage={itemsCountPerPage}
              pageRangeDisplayed={pageRangeDisplayed}
              onChange={changePage}
              hideDisabled
              innerClass={"pagination pagination-sm"}
              itemClass={"page-item"}
              linkClass={"page-link"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ),
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  resultsVersion: PropTypes.number,
};

export default Search;

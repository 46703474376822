import { get } from "./Api";

const BASE_URL = "/api/state";

export function getStates() {
  return get(`${BASE_URL}/all`, null, "Unable to load states at this time.");
}

export function getStatesWithHospitals() {
  return get(
    `${BASE_URL}/with-hospitals`,
    null,
    "Unable to load states at this time.",
  );
}

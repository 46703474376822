import { Fragment } from "react";
import { Button, Row, Table } from "reactstrap";
import PropTypes from "prop-types";
import "./UnmatchedPromsTable.css";
import Pagination from "react-js-pagination";
import { getStartCase, isEmptyOrNull } from "../../Utils";
import Moment from "moment";
import { FaSearch } from "react-icons/fa";

const UnmatchedPromsTable = ({
  data = [],
  itemsCountPerPage = 10,
  searchCallback = (row) => null,
  showSearchButton = true,
  totalItemsCount = 0,
  activePage = 1,
  pageRangeDisplayed = 5,
  changePageCallback,
}) => {
  const getCellClass = (data, key, valuesAreDates) => {
    if (valuesAreDates) {
      return Moment(data[key + "Proms"]).format("DD/MM/YYYY")
        ? null
        : "highlight-cell";
    } else if (isEmptyOrNull(data[key + "Proms"])) {
      return "highlight-cell";
    } else {
      return getStartCase(data[key + "Proms"]) ? null : "highlight-cell";
    }
  };

  const getActionButtons = (row) => {
    return (
      <Fragment>
        {showSearchButton && (
          <Button
            className={"action-button"}
            outline
            color={"secondary"}
            title={"Search for another match"}
            onClick={() => searchCallback(row)}
          >
            <FaSearch />
          </Button>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Row className={"unmatched-proms-table"}>
        <Table size={"sm"} bordered responsive>
          <thead>
            <tr>
              <th>Procedure ID</th>
              <th>First Name</th>
              <th>Middle Name(s)</th>
              <th>Last Name</th>
              <th>DOB</th>
              <th>Postcode</th>
              <th>Hospital</th>
              <th>Procedure Type</th>
              <th>Side</th>
              <th>Surgeon</th>
              <th>Registered Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className={"table-body"}>
            {data.map((row, i) => {
              return (
                <Fragment key={`row-fragment-${i}`}>
                  <tr
                    key={`proms-row-${i}`}
                    className={i % 2 === 0 ? "stripe-row" : null}
                  >
                    <td className={getCellClass(row, "firstName")}>
                      {row.idProms}
                    </td>
                    <td className={getCellClass(row, "firstName")}>
                      {getStartCase(row.firstNameProms)}
                    </td>
                    <td className={getCellClass(row, "middleNames")}>
                      {getStartCase(row.middleNamesProms)}
                    </td>
                    <td className={getCellClass(row, "lastName")}>
                      {getStartCase(row.lastNameProms)}
                    </td>
                    <td className={getCellClass(row, "dateOfBirth")}>
                      {Moment(row.dateOfBirthProms).format("DD/MM/YYYY")}
                    </td>
                    <td className={getCellClass(row, "postcode")}>
                      {row.postcodeProms}
                    </td>
                    <td className={getCellClass(row, "hospital")}>
                      {row.hospitalProms}
                    </td>
                    <td className={getCellClass(row, "procedureType")}>
                      {getStartCase(row.procedureTypeProms)}
                    </td>
                    <td className={getCellClass(row, "side")}>
                      {getStartCase(row.sideProms)}
                    </td>
                    <td className={getCellClass(row, "surgeon")}>
                      {row.surgeonProms}
                    </td>
                    <td
                      className={
                        row.procedureRegistered === null
                          ? "highlight-cell"
                          : null
                      }
                    >
                      {Moment(row.procedureRegistered).format("DD/MM/YYYY")}
                    </td>
                    <td>{getActionButtons(row)}</td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </Row>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Pagination
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={changePageCallback}
          hideDisabled
          innerClass={"pagination pagination-sm"}
          itemClass={"page-item"}
          linkClass={"page-link"}
        />
      </div>
    </Fragment>
  );
};

UnmatchedPromsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  itemsCountPerPage: PropTypes.number,
  totalItemsCount: PropTypes.number,
  activePage: PropTypes.number,
  searchCallback: PropTypes.func,
  changePageCallback: PropTypes.func,
  showSearchButton: PropTypes.bool,
  pageRangeDisplayed: PropTypes.number,
};

export default UnmatchedPromsTable;

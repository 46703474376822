import PropTypes from "prop-types";
import {
  Button,
  Fade,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { isEmptyOrNull } from "../../Utils";

const MultipleChoice = ({
  options = [],
  responseAction = (option, value) => null,
  value = {},
  transitioning = false,
}) => {
  const freeTextOptionIds = options
    .filter((option) => option.allowFreeText)
    .map((option) => option.id);
  return (
    <div className={"multiplechoice"}>
      {options.map((option, i) => {
        let chosen = value && option.id === value.id;
        let shouldShow = transitioning ? chosen : true;
        let freeTextOptionIsSelected =
          options.filter(
            (o) => value != null && o.id === value.id && o.allowFreeText,
          ).length > 0;
        return (
          <div className={"button-grouping"} key={`div-${i}`}>
            <Fade in={shouldShow || freeTextOptionIsSelected} key={i}>
              <Button
                color="primary"
                disabled={
                  transitioning &&
                  value != null &&
                  !freeTextOptionIds.includes(value.id)
                }
                outline={option.id !== value?.id}
                onClick={() => responseAction(option, "")}
                key={`button-${i}`}
                size="lg"
              >
                {option.choiceText}
              </Button>
              {option.allowFreeText && value && option.id === value.id && (
                <FormGroup key={`form-group-${i}`} className={"free-text"}>
                  <Label key={`label-${i}`} for={`freetext-${option.id}`}>
                    Please specify
                  </Label>
                  <Input
                    key={`input-${i}`}
                    type="text"
                    id={`freetext-${option.id}`}
                    value={value.freeText ? value.freeText : ""}
                    required
                    className={
                      value.freeText == null || isEmptyOrNull(value.freeText)
                        ? "is-invalid"
                        : null
                    }
                    onChange={(event) =>
                      responseAction(option, event.target.value)
                    }
                  />
                  <FormFeedback>
                    The response you have chosen requires some additional text
                    before continuing
                  </FormFeedback>
                </FormGroup>
              )}
            </Fade>
          </div>
        );
      })}
    </div>
  );
};

MultipleChoice.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  responseAction: PropTypes.func,
  value: PropTypes.object,
  transitioning: PropTypes.bool,
};

export default MultipleChoice;

import { Fragment, useEffect, useReducer } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import "./AnnouncementManagement.css";
import AnnouncementDetailsModal from "./AnnouncementDetailsModal";
import { toast } from "react-toastify";
import { FaCheck, FaTrash } from "react-icons/fa";
import { isEmptyOrNull } from "../../../Utils";
import {
  deleteAnnouncement,
  getAllAnnouncements,
} from "../../../api/Announcement";
import Responsive from "react-responsive";
import Moment from "moment";
import ConfirmDeleteAnnouncementModal from "./ConfirmDeleteAnnouncementModal";
import DOMPurify from "dompurify";

export const DISPLAY_DATE_FORMAT = "DD/MM/YYYY @ HH:mm";

const AnnouncementManagement = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      modalAnnouncementId: null,
      showModal: false,
      showDeleteDialog: false,
      deleteAnnouncementError: null,
      loadAnnouncementsError: null,
      allAnnouncements: null,
    },
  );

  useEffect(() => {
    if (
      state.allAnnouncements === null ||
      state.allAnnouncements === "undefined"
    ) {
      fetchAnnouncementTable();
    }
  }, []);

  const fetchAnnouncementTable = () => {
    getAllAnnouncements()
      .then((response) => {
        setState({
          allAnnouncements: response.data,
        });
      })
      .catch((error) => {
        if (error.response && isEmptyOrNull(error.response.data)) {
          setState({
            loadAnnouncementsError: "Error loading the announcements",
          });
        } else {
          setState({
            loadAnnouncementsError: error.response,
          });
        }
      });
  };

  const handleSuccess = (message) => {
    setState({
      showModal: false,
      searchVersion: state.searchVersion + 1,
    });
    toast.success(
      <span>
        <FaCheck /> {message}
      </span>,
    );
    fetchAnnouncementTable();
  };

  const handleDeleteAnnouncement = (announcementId) => {
    setState({
      modalAnnouncementId: announcementId,
      showDeleteDialog: true,
    });
  };

  const confirmDelete = (announcementId) => {
    deleteAnnouncement(announcementId)
      .then(() => {
        setState({
          showDeleteDialog: false,
        });
        toast.success(
          <span>
            <FaCheck />
            {"Announcement {id: " + announcementId + "} successfully deleted"}
          </span>,
        );
        fetchAnnouncementTable();
      })
      .catch((error) => {
        if (error.response && isEmptyOrNull(error.response.data)) {
          setState({
            deleteAnnouncementError:
              "There was an error deleting the announcement",
            showDeleteDialog: false,
          });
        } else {
          setState({
            deleteAnnouncementError: error.response,
            showDeleteDialog: false,
          });
        }
      });
  };

  const rejectDelete = () => {
    setState({
      showDeleteDialog: false,
    });
  };

  const renderTable = () => {
    return (
      <Fragment>
        <Responsive maxWidth={559}>
          <Table size={"sm"} bordered striped responsive>
            <tbody>
              {state.allAnnouncements.map((announcement, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          Id:
                        </Col>
                        <Col xs={7}>{announcement.id}</Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          Start Date:
                        </Col>
                        <Col xs={7}>
                          {Moment(announcement.startDate).format(
                            DISPLAY_DATE_FORMAT,
                          )}
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          Finish Date:
                        </Col>
                        <Col xs={7}>
                          {Moment(announcement.finishDate).format(
                            DISPLAY_DATE_FORMAT,
                          )}
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={5} className={"td-label"}>
                          Message:
                        </Col>
                        <Col xs={7}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(announcement.message),
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <div>
                          <Button
                            color={"primary"}
                            outline
                            onClick={() =>
                              handleDeleteAnnouncement(announcement.id)
                            }
                            title={"Delete Announcement"}
                          >
                            <FaTrash />
                          </Button>
                        </div>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={560} maxWidth={767}>
          <Table size={"sm"} bordered striped responsive>
            <tbody>
              {state.allAnnouncements.map((announcement, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Row className={"g-0"}>
                        <Col sm={6}>
                          <span className={"td-label"}>Id: </span>
                          {announcement.id}
                        </Col>
                        <Col sm={6}>
                          <span className={"td-label"}>Start Date: </span>
                          {Moment(announcement.startDate).format(
                            DISPLAY_DATE_FORMAT,
                          )}
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col sm={6}>
                          <span className={"td-label"}>Finish Date: </span>
                          {Moment(announcement.finishDate).format(
                            DISPLAY_DATE_FORMAT,
                          )}
                        </Col>
                        <Col sm={6}>
                          <span className={"td-label"}>Message: </span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(announcement.message),
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col sm={12}>
                          <div>
                            <Button
                              color={"primary"}
                              outline
                              onClick={() =>
                                handleDeleteAnnouncement(announcement.id)
                              }
                              title={"Delete Announcement"}
                            >
                              <FaTrash />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={768}>
          <Table size={"sm"} bordered striped responsive>
            <thead>
              <tr>
                <th>Id</th>
                <th>Start Date</th>
                <th>Finish Date</th>
                <th>Message</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {state.allAnnouncements.map((announcement, i) => {
                return (
                  <tr key={i}>
                    <td>{announcement.id}</td>
                    <td>
                      {Moment(announcement.startDate).format(
                        DISPLAY_DATE_FORMAT,
                      )}
                    </td>
                    <td>
                      {Moment(announcement.finishDate).format(
                        DISPLAY_DATE_FORMAT,
                      )}
                    </td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(announcement.message),
                        }}
                      />
                    </td>
                    <td>
                      <div>
                        <Button
                          color={"primary"}
                          outline
                          onClick={() =>
                            handleDeleteAnnouncement(announcement.id)
                          }
                          title={"Delete Announcement"}
                        >
                          <FaTrash />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
      </Fragment>
    );
  };

  return (
    <div>
      <Container>
        <h2>Announcement Management</h2>
        <Card className={"create-announcement"}>
          <CardBody>
            <CardTitle>Create Announcement</CardTitle>
            <div>
              <Row>
                <Col xs={12} sm={7} md={6} lg={4}>
                  <Button onClick={() => setState({ showModal: true })}>
                    Create...
                  </Button>
                </Col>
                <Col xs={0} sm={2} md={2} lg={2} />
              </Row>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Current Announcements</CardTitle>
            <CardBody>
              {state.allAnnouncements !== null && renderTable()}
            </CardBody>
          </CardBody>
        </Card>
      </Container>
      {state.showModal && (
        <AnnouncementDetailsModal
          onConfirm={handleSuccess}
          onExit={() => setState({ showModal: false })}
          open={true}
        />
      )}
      {state.showDeleteDialog && (
        <ConfirmDeleteAnnouncementModal
          isOpen={state.showDeleteDialog}
          announcementId={state.modalAnnouncementId}
          noCallback={rejectDelete}
          yesCallback={confirmDelete}
        />
      )}
    </div>
  );
};

export default AnnouncementManagement;

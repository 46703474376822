import { Fragment, useEffect, useReducer, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactText,
  isEmptyOrNull,
  validateEmailAddress,
} from "../../Utils";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import "./Logon.css";
import {
  loadUserCurrent,
  logonError,
  sendMfaCode,
  sendMfaCodeError,
  sendMfaCodeSuccess,
  userLogon,
} from "../../actions/UserActions";
import AoaLogo from "../../images/aoa_logo_blue.png";
import SimpleFooter from "../common/SimpleFooter";
import { resetPasswordByUser, userLogonType } from "../../api/User";
import ReCAPTCHA from "react-google-recaptcha";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { FaQuestionCircle } from "react-icons/fa";

const INITIAL_STATE = {
  username: null,
  password: null,
  code: null,
  forgot: false,
  userIsHuman: false,
  passwordReset: false,
  azureManagedUser: true,
  mfaRequired: false,
  passwordVerified: false,
};

const Logon = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const error = useSelector((state) => state.app.logonError);
  const codeError = useSelector((state) => state.sendMfaCode.codeError);
  const codeSuccess = useSelector((state) => state.sendMfaCode.codeSuccess);

  const recaptchaRef = useRef();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    INITIAL_STATE,
  );

  useEffect(() => {
    !user.found && dispatch(loadUserCurrent());
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (state.forgot) {
        handleReset();
      } else if (state.azureManagedUser) {
        handleUserLoginPath();
      } else {
        handleSubmit();
      }
    }
  };

  const formValid = () => {
    if (state.azureManagedUser) {
      return (
        !isEmptyOrNull(state.username) && validateEmailAddress(state.username)
      );
    } else {
      if (state.forgot) {
        return (
          !isEmptyOrNull(state.username) &&
          validateEmailAddress(state.username) &&
          state.userIsHuman
        );
      }
      if (state.mfaRequired && state.codeSuccess) {
        return (
          !isEmptyOrNull(state.username) &&
          validateEmailAddress(state.username) &&
          !isEmptyOrNull(state.password) &&
          !isEmptyOrNull(state.code) &&
          state.code.length === 6
        );
      }
      return (
        !isEmptyOrNull(state.username) &&
        validateEmailAddress(state.username) &&
        !isEmptyOrNull(state.password)
      );
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === "password") {
      setState({
        username: state.username,
        password: value,
        code: state.code,
      });
    } else if (id === "username") {
      setState({
        username: value,
        password: state.password,
        code: state.code,
      });
    } else if (id === "code") {
      setState({
        username: state.username,
        password: state.password,
        code: value,
      });
    }
  };

  const handleLogin = () => {
    if (state.mfaRequired) {
      handleGetCode();
    } else {
      // Don't do anything if the form is invalid
      if (formValid()) {
        dispatch(logonError(null));
        dispatch(sendMfaCodeError(null));
        dispatch(sendMfaCodeSuccess(null));
        let payload = new FormData();
        payload.append("username", state.username);
        payload.append("password", state.password);
        dispatch(userLogon(payload));
      }
    }
  };

  const handleMfaLogin = () => {
    // Don't do anything if the form is invalid
    if (formValid()) {
      dispatch(logonError(null));
      dispatch(sendMfaCodeError(null));
      dispatch(sendMfaCodeSuccess(null));
      let payload = new FormData();
      payload.append("username", state.username);
      payload.append("password", state.password + "/" + state.code);
      dispatch(userLogon(payload));
    }
  };

  const handleUserLoginPath = () => {
    let sahmriSuffix = "@sahmri.com";
    let aoaSuffix = "@aoanjrr.org.au";

    if (
      state.username.toString().toLocaleLowerCase().includes(sahmriSuffix) ||
      state.username.toString().toLocaleLowerCase().includes(aoaSuffix)
    ) {
      // Use azure login
      window.location.replace("/oauth2/authorization/azure");
    } else {
      // Do DB authentication instead
      setState({ azureManagedUser: false });
      userLogonType(state.username).then((response) => {
        setState({ mfaRequired: response.data });
      });
    }
  };

  const handleGetCode = () => {
    if (formValid()) {
      dispatch(logonError(null));
      dispatch(sendMfaCodeError(null));
      dispatch(sendMfaCodeSuccess(null));
      let payload = {
        username: state.username,
        password: state.password,
      };
      dispatch(sendMfaCode(payload));
    }
  };

  const handleReset = () => {
    const recaptchaToken = recaptchaRef.current.getValue();
    // Don't do anything if the form is invalid
    if (formValid()) {
      dispatch(startLoading());
      let request = { username: state.username, token: recaptchaToken };
      resetPasswordByUser(request)
        .then(() => {
          setState({ passwordReset: true });
          recaptchaRef.current.reset();
        })
        .finally(() => dispatch(finishLoading()));
    }
  };

  const handleBack = () => {
    setState(INITIAL_STATE);
    dispatch(logonError(null));
    dispatch(sendMfaCodeError(null));
    dispatch(sendMfaCodeSuccess(null));
  };

  const handleSubmit = () => {
    if (state.forgot) {
      handleReset();
    } else {
      if (!isEmptyOrNull(codeSuccess)) {
        handleMfaLogin();
      } else handleLogin();
    }
  };

  return (
    <div className="user-logon">
      <div className="landing-header">
        <a href="/" title="Home">
          <img src={AoaLogo} alt="AOA Logo" />
        </a>
      </div>
      <div className="map-background">
        <div className="spacer" />
        <div>
          <Container>
            <Card>
              <CardBody>
                {((!isEmptyOrNull(error) &&
                  error.props?.children?.length > 0 &&
                  error.props.children[0].includes(
                    "Your password has expired",
                  )) ||
                  (!isEmptyOrNull(codeError) &&
                    codeError.props?.children?.length > 0 &&
                    codeError.props.children[0].includes(
                      "Your password has expired",
                    ))) && (
                  <div style={{ color: "red" }}>
                    <b>
                      Please check your email inbox for a link to set a new
                      password
                    </b>
                  </div>
                )}
                <CardTitle>
                  {state.forgot ? "Forgot Password" : "User Logon"}
                </CardTitle>
                {!state.forgot && (
                  <CardText>
                    If your account has multi-factor authentication, you will be
                    asked to verify the logon via a one-time code sent by SMS or
                    email &nbsp;
                    <FaQuestionCircle
                      id={"mfa-info-icon"}
                      className={"tooltip-icon"}
                    />
                    <UncontrolledTooltip placement="top" target="mfa-info-icon">
                      Hospital Administrators and Hospital Study Coordinators
                      will receive their codes via email. <br />
                      All other users will receive their codes via SMS.
                    </UncontrolledTooltip>
                  </CardText>
                )}
                <div className="logon-form">
                  <FormGroup>
                    <Row className={"username-row"}>
                      <Col xs={3} md={2} lg={1}>
                        <Label for="email" className={"label"}>
                          Email
                        </Label>
                      </Col>
                      <Col xs={9} md={6} lg={4}>
                        <Input
                          required
                          autoFocus
                          type="email"
                          id="username"
                          value={state.username || ""}
                          disabled={
                            state.passwordReset || !state.azureManagedUser
                          }
                          onChange={handleChange}
                          onKeyPress={handleKeyPress}
                        />
                        {state.forgot && (
                          <FormText>
                            Ensure the email entered is the one you use to
                            login. A password reset link will be sent to this
                            address.
                          </FormText>
                        )}
                      </Col>
                      <Col xs={0} md={2} lg={7} />
                    </Row>
                    {state.forgot && (
                      <Row>
                        <Col xs={3} md={2} lg={1} />
                        <Col>
                          <ReCAPTCHA
                            sitekey="6LcjncQUAAAAAHFIkcsEuqjfDEIG2Yo7LF7GV_dN"
                            ref={recaptchaRef}
                            onChange={() => setState({ userIsHuman: true })}
                            onExpired={() => setState({ userIsHuman: false })}
                          />
                        </Col>
                      </Row>
                    )}
                    {state.passwordReset && (
                      <Alert color={"primary"}>
                        If an account matching the supplied email exists, you
                        will receive a password reset email shortly.{" "}
                        {getContactText()}
                      </Alert>
                    )}
                    {!state.forgot && !state.azureManagedUser && (
                      <Fragment>
                        <Row className={"password-row"}>
                          <Col xs={3} md={2} lg={1}>
                            <Label for="password" className={"label"}>
                              Password
                            </Label>
                          </Col>
                          <Col xs={9} md={6} lg={4}>
                            <Input
                              required={!isEmptyOrNull(state.username)}
                              type="password"
                              id="password"
                              value={state.password || ""}
                              disabled={!isEmptyOrNull(codeSuccess)}
                              onChange={handleChange}
                              onKeyPress={handleKeyPress}
                            />
                          </Col>
                          <Col xs={0} md={2} lg={7} />
                        </Row>
                        <Row>
                          <Col xs={3} md={2} lg={1} />
                          <Col>
                            <FormText className={"forgot-password-link"}>
                              <a
                                href={"#"}
                                onClick={() => {
                                  setState({ forgot: true });
                                  dispatch(logonError(null));
                                }}
                              >
                                Forgotten Password?
                              </a>
                            </FormText>
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                    {!state.forgot && state.mfaRequired && codeSuccess && (
                      <Fragment>
                        <Row className={"code-row"}>
                          <Col xs={3} md={2} lg={1}>
                            <Label for="code" className={"label"}>
                              Code
                            </Label>
                          </Col>
                          <Col xs={9} md={6} lg={4}>
                            <Input
                              required
                              type={"text"}
                              id={"code"}
                              inputMode={"numeric"}
                              autoComplete={"one-time-code"}
                              value={state.code || ""}
                              pattern={"d{6}"}
                              onChange={handleChange}
                              onKeyPress={handleKeyPress}
                            />
                          </Col>
                          <Col xs={0} md={2} lg={7} />
                        </Row>
                      </Fragment>
                    )}
                  </FormGroup>
                  {(state.forgot || !state.azureManagedUser) && (
                    <Button
                      className={"me-3"}
                      color={"secondary"}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  )}
                  {state.azureManagedUser && (
                    <Button
                      color={"primary"}
                      onClick={handleUserLoginPath}
                      outline={!formValid()}
                      disabled={!formValid()}
                    >
                      Next
                    </Button>
                  )}
                  {(state.forgot || !state.azureManagedUser) && (
                    <Button
                      color={"primary"}
                      onClick={handleSubmit}
                      outline={!formValid()}
                      disabled={!formValid() || state.passwordReset}
                    >
                      Submit
                    </Button>
                  )}
                  {!isEmptyOrNull(codeSuccess) && !state.forgot && (
                    <div>
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <Alert color="info" isOpen={true}>
                            {codeSuccess === true
                              ? "Please wait a few moments. You should receive an SMS or email with a logon code shortly."
                              : codeSuccess}
                          </Alert>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {state.mfaRequired &&
                    !isEmptyOrNull(codeError) &&
                    !state.forgot && (
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <Alert
                            color="danger"
                            isOpen={!isEmptyOrNull(codeError)}
                          >
                            {codeError}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <Alert color="danger" isOpen={!isEmptyOrNull(error)}>
                        {error}
                      </Alert>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
      <SimpleFooter />
    </div>
  );
};

export default Logon;

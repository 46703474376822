import { Fragment } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { FormText } from "reactstrap";

const GenericSelector = ({
  options = [],
  selected = null,
  filter = null,
  renderer = null,
  readOnly = false,
  clearable = false,
  classNamePrefix = "generic-selector",
  idPropertyName = "id",
  labelPropertyName = "name",
  invalid = false,
  searchable = true,
  isMulti = false,
  placeholder = "Select...",
  showDescription = false,
  changeCallback,
}) => {
  const getCustomStyles = () => {
    let result = { menu: (provided) => ({ ...provided, zIndex: 2 }) };
    if (invalid) {
      result["control"] = (provided) => ({
        ...provided,
        // Copy invalid styles from bootstrap
        borderColor: "#dc3545",
        "&:hover": {
          borderColor: "#dc3545",
        },
      });
    }
    return result;
  };

  const getOptions = () => {
    if (filter) {
      return options.filter(filter);
    } else {
      return options;
    }
  };

  const renderOption = (option) => {
    if (renderer) {
      return renderer(option);
    }
    return option.name;
  };

  // Necessary as react-select searches on your value property also - https://github.com/JedWatson/react-select/issues/3556
  const filterOption = (option, input) => {
    return option.label.toLocaleLowerCase().includes(input.toLocaleLowerCase());
  };

  return (
    <Fragment>
      <Select
        classNamePrefix={classNamePrefix}
        options={getOptions()}
        value={selected}
        getOptionLabel={(option) => option[labelPropertyName]}
        getOptionValue={(option) => option[idPropertyName]}
        onChange={changeCallback}
        isSearchable={searchable}
        filterOption={searchable ? filterOption : null}
        formatOptionLabel={renderOption}
        isClearable={clearable}
        isDisabled={readOnly}
        isMulti={isMulti}
        styles={getCustomStyles()}
        placeholder={readOnly ? null : placeholder}
      />
      {showDescription && selected != null && selected.description != null && (
        <FormText>{selected.description}</FormText>
      )}
    </Fragment>
  );
};

GenericSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  changeCallback: PropTypes.func,
  filter: PropTypes.func,
  renderer: PropTypes.func,
  readOnly: PropTypes.bool,
  clearable: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  idPropertyName: PropTypes.string,
  labelPropertyName: PropTypes.string,
  invalid: PropTypes.bool,
  searchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showDescription: PropTypes.bool,
};

export default GenericSelector;

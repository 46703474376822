import { Button, ButtonGroup, Col, Label, Row, Table } from "reactstrap";
import SimpleStateSelector from "../../common/SimpleStateSelector";
import "./SearchHospitals.css";
import {
  loadHospitals,
  loadStatesWithHospitals,
} from "../../../actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useEffect, useReducer } from "react";
import { useOnUpdate } from "../../CustomHooks";

const SearchHospitals = ({
  actionButtons = [],
  excludeIds = [],
  resultsVersion = 0,
}) => {
  const dispatch = useDispatch();
  const hospitals = useSelector((state) => state.hospitals);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { stateFilter: null, cSelected: [], filteredHospitals: [], version: 0 },
  );

  useEffect(() => {
    // We always fetch a new set of hospitals in case a new one has been created
    // All of this logic will be reworked when we get to paged searching
    dispatch(loadHospitals());
  }, [dispatch]);

  useEffect(() => {
    if (resultsVersion > state.version) {
      dispatch(loadHospitals());
      dispatch(loadStatesWithHospitals());
      setState({ version: resultsVersion });
    }
  }, [resultsVersion, state.version]);

  useEffect(() => {
    setState({ filteredHospitals: getFilteredHospitals(hospitals) });
  }, [hospitals]);

  // Filter the hospitals when the type (public / private) or state has changed
  // But the state must have a value
  useOnUpdate(() => {
    state.stateFilter && filter();
  }, [state.stateFilter, state.cSelected]);

  const getFilteredHospitals = (hospitals) => {
    let id = state.stateFilter ? state.stateFilter.id : null;
    let filtered = hospitals.slice();
    filtered = filtered.filter((h) => h.state.id === id);
    if (onlyPrivate()) {
      filtered = filtered.filter((h) => h.isPrivate);
    } else if (onlyPublic()) {
      filtered = filtered.filter((h) => !h.isPrivate);
    }
    return filtered;
  };

  const filter = () => {
    setState({ filteredHospitals: getFilteredHospitals(hospitals) });
  };

  const onlyPrivate = () => {
    // if both selected or both not selected, return false
    if (
      (state.cSelected.includes(1) && state.cSelected.includes(2)) ||
      (!state.cSelected.includes(1) && !state.cSelected.includes(2))
    ) {
      return false;
    }
    return state.cSelected.includes(1) && !state.cSelected.includes(2);
  };

  const onlyPublic = () => {
    // if both selected or both not selected, return false
    if (
      (state.cSelected.includes(1) && state.cSelected.includes(2)) ||
      (!state.cSelected.includes(1) && !state.cSelected.includes(2))
    ) {
      return false;
    }
    return !state.cSelected.includes(1) && state.cSelected.includes(2);
  };

  const setHospitalStateFilter = (value) => {
    setState({ stateFilter: value });
  };

  const onCheckboxBtnClick = (selected) => {
    const cSelected = state.cSelected;
    const index = cSelected.indexOf(selected);
    if (index < 0) {
      cSelected.push(selected);
    } else {
      cSelected.splice(index, 1);
    }

    if (cSelected.includes(1) && cSelected.includes(2)) {
      if (selected === 1) {
        cSelected.splice(cSelected.indexOf(2), 1);
      } else {
        cSelected.splice(cSelected.indexOf(1), 1);
      }
    }
    setState({ cSelected: [...cSelected] });
  };

  return (
    <div>
      <Row className={"hospital-search-filter"}>
        <Col xs={4} sm={2} md={2} lg={2}>
          <Label className={"form-label"} for="type">
            Type
          </Label>
        </Col>
        <Col xs={8} sm={4} md={4} lg={3}>
          <ButtonGroup className={"public-private-buttons"}>
            <Button
              color="secondary"
              outline={!state.cSelected.includes(1)}
              onClick={() => onCheckboxBtnClick(1)}
              active={state.cSelected.includes(1)}
            >
              Private
            </Button>
            <Button
              color="secondary"
              outline={!state.cSelected.includes(2)}
              onClick={() => onCheckboxBtnClick(2)}
              active={state.cSelected.includes(2)}
            >
              Public
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs={4} sm={2} md={3} lg={2}>
          <Label className={"form-label text-nowrap"} for="state">
            State*
          </Label>
        </Col>
        <Col xs={8} sm={3} md={3} lg={3}>
          <SimpleStateSelector
            value={state.stateFilter}
            onChange={setHospitalStateFilter}
            clearable={false}
            usePadding={false}
            useShortNames={true}
            autoFocus={false}
            tabIndexFirst={false}
            onlyThoseWithHospitals={true}
          />
        </Col>
      </Row>
      <Table striped className={"search-table"}>
        <thead>
          <tr>
            <th>Name</th>
            <th>State</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {state.filteredHospitals.length > 0 ? (
            state.filteredHospitals
              .filter((hosp) => !excludeIds.includes(hosp.id))
              .map((hospital) => (
                <tr key={`hospital-${hospital.id}`}>
                  <td className={"hospital-entry"}>
                    <span className={"hospital-text"}>{hospital.name}</span>
                  </td>
                  <td>{hospital.state.shortName}</td>
                  <td>
                    <span className={"action-buttons"}>
                      {actionButtons.map((button) => (
                        <Button
                          key={`hospital-${hospital.id}`}
                          id={`hospital-${hospital.id}`}
                          className={"action-button"}
                          outline
                          title={button.title}
                          onClick={() => button.action(hospital)}
                        >
                          {button.icon}
                        </Button>
                      ))}
                    </span>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td>
                <em>
                  No hospitals to display.
                  {state.stateFilter === null
                    ? " Please select (at least) a State filter."
                    : ""}
                </em>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

SearchHospitals.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ),
  excludeIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  resultsVersion: PropTypes.number,
};

export default SearchHospitals;

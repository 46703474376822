import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./FileUploader.css";
import { FaUpload } from "react-icons/fa";
import PropTypes from "prop-types";
import classnames from "classnames";

const FileUploader = ({
  instructionText = "Drag and drop a new file to upload, or click anywhere within this box to browse for a file.",
  maxFileSizeBytes = 10000000,
  onFileAccepted = (newAccepted) => null,
  acceptedFileTypes,
}) => {
  const [rejected, setRejected] = useState(null);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    let newAccepted = null;
    let newRejected = null;
    if (acceptedFiles && acceptedFiles.length > 0) {
      newAccepted = acceptedFiles[0];
    } else if (!!fileRejections && fileRejections.length > 0) {
      // Last chance intervention in case the MIME type detection is incorrect
      let filename = fileRejections[0].file.name.toLocaleLowerCase();
      // Need to get array of extensions from the acceptedFileTypes object
      let acceptedFileExtensions = [];
      Object.values(acceptedFiles).forEach(
        (e) => (acceptedFileExtensions = acceptedFileExtensions.concat(e)),
      );
      acceptedFileExtensions.forEach((ext) => {
        if (filename.endsWith(ext)) {
          newAccepted = fileRejections[0].file;
        }
      });
      if (newAccepted == null) {
        newRejected = fileRejections[0];
      }
    }
    if (newAccepted != null) {
      setRejected(null);
      onFileAccepted(newAccepted);
    } else {
      setRejected(newRejected);
    }
  }, []);

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: acceptedFileTypes,
    onDrop,
    maxSize: maxFileSizeBytes,
  });

  return (
    <section>
      <div
        {...getRootProps()}
        className={classnames("drop-zone", { active: isDragAccept })}
      >
        <input {...getInputProps()} />
        <FaUpload className={"upload-icon"} />
        <p className={"instruction-text"}>
          <i>{instructionText}</i>
        </p>
        {!!rejected && rejected.file.name && (
          <p className={"error-text"}>
            <i>
              Your file {rejected.file.name} is not a valid file that can be
              uploaded.
            </i>
          </p>
        )}
      </div>
    </section>
  );
};

FileUploader.propTypes = {
  mimeTypeWhitelist: PropTypes.arrayOf(PropTypes.string),
  fileExtensionWhitelist: PropTypes.arrayOf(PropTypes.string),
  instructionText: PropTypes.string,
  maxFileSizeBytes: PropTypes.number,
  onFileAccepted: PropTypes.func,
};

export default FileUploader;

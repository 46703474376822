export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";
export const SET_PATIENT_FIELD = "SET_PATIENT_FIELD";
export const SEARCH_PATIENT_SELF_LOGON = "SEARCH_PATIENT_SELF_LOGON";
export const LOAD_PATIENT_DETAILS = "LOAD_PATIENT_DETAILS";
export const SET_PATIENT_PROCEDURE_FIELD = "SET_PATIENT_PROCEDURE_FIELD";
export const LOAD_STATES = "LOAD_STATES";
export const STATES_LOADED = "STATES_LOADED";
export const SET_PROCEDURE_STATE = "SET_PROCEDURE_STATE";
export const LOAD_HOSPITALS = "LOAD_HOSPITALS";
export const HOSPITALS_LOADED = "HOSPITALS_LOADED";
export const SET_PROCEDURE_HOSPITAL = "SET_PROCEDURE_HOSPITAL";
export const SURGEONS_LOADED = "SURGEONS_LOADED";
export const SET_PROCEDURE_SURGEON = "SET_PROCEDURE_SURGEON";
export const SET_SCHEDULED_PROCEDURE_DATE = "SET_SCHEDULED_PROCEDURE_DATE";
export const LOAD_USER_CURRENT = "LOAD_USER_CURRENT";
export const USER_CURRENT_LOADED = "USER_CURRENT_LOADED";
export const USER_LOGON = "USER_LOGON";
export const USER_LOGOUT = "USER_LOGOUT";
export const LOGON_ERROR = "LOGON_ERROR";
export const SEND_SURGEON_SMS = "SEND_SURGEON_SMS";
export const SEND_SURGEON_SMS_SUCCESS = "SEND_SURGEON_SMS_SUCCESS";
export const SEND_SURGEON_SMS_ERROR = "SEND_SURGEON_SMS_ERROR";
export const SEND_MFA_CODE = "SEND_MFA_CODE";
export const SEND_MFA_CODE_SUCCESS = "SEND_MFA_CODE_SUCCESS";
export const SEND_MFA_CODE_ERROR = "SEND_MFA_CODE_ERROR";
export const LOAD_STUDIES = "LOAD_STUDIES";
export const STUDIES_LOADED = "STUDIES_LOADED";
export const LOAD_STATES_WITH_HOSPITALS = "LOAD_STATES_WITH_HOSPITALS";
export const STATES_WITH_HOSPITALS_LOADED = "STATES_WITH_HOSPITALS_LOADED";
export const AUTO_LOGON_PATIENT = "AUTO_LOGON_PATIENT";
export const AUTO_LOGON_PATIENT_SMS = "AUTO_LOGON_PATIENT_SMS";
export const AUTO_LOGON_DETAILS = "AUTO_LOGON_DETAILS";
export const REGISTER_SUBMIT = "REGISTER_SUBMIT";
export const CLEAR_PATIENT = "CLEAR_PATIENT";
export const CLEAR_NEW_PROCEDURE = "CLEAR_NEW_PROCEDURE";
export const CONFIRM_PATIENT_DETAILS = "CONFIRM_PATIENT_DETAILS";
export const HAS_AVAILABLE_COLLECTIONS = "HAS_AVAILABLE_COLLECTIONS";
export const CHECK_COLLECTIONS_AVAILABLE = "CHECK_COLLECTIONS_AVAILABLE";
export const LOAD_SURGEONS = "LOAD_SURGEONS";
export const DECLINE_THANKYOU = "DECLINE_THANKYOU";
export const START_SCREEN_TRANSITION = "START_SCREEN_TRANSITION";
export const STOP_SCREEN_TRANSITION = "STOP_SCREEN_TRANSITION";
export const LOAD_JOINTS = "LOAD_JOINTS";
export const LOAD_PROCEDURE_TYPES = "LOAD_PROCEDURE_TYPES";
export const LOAD_SIDES = "LOAD_SIDES";
export const JOINTS_LOADED = "JOINTS_LOADED";
export const PROCEDURE_TYPES_LOADED = "PROCEDURE_TYPES_LOADED";
export const SIDES_LOADED = "SIDES_LOADED";
export const SHOW_LOGOUT_TOAST = "SHOW_LOGOUT_TOAST";
export const LOAD_ANNOUNCEMENTS = "LOAD_ANNOUNCEMENTS";
export const ANNOUNCEMENTS_LOADED = "ANNOUNCEMENTS_LOADED";
export const HIDE_ANNOUNCEMENT_CURRENT_USER = "HIDE_ANNOUNCEMENT_CURRENT_USER";
export const LOAD_STAKEHOLDER_GROUPS = "LOAD_STAKEHOLDER_GROUPS";
export const STAKEHOLDER_GROUPS_LOADED = "STAKEHOLDER_GROUPS_LOADED";
export const LOAD_PREVIOUS_RESPONSES = "LOAD_PREVIOUS_RESPONSES";
export const PREVIOUS_RESPONSES_LOADED = "PREVIOUS_RESPONSES_LOADED";
export const RELOAD_PREVIOUS_RESPONSES = "RELOAD_PREVIOUS_RESPONSES";
export const LOAD_ALL_INSTRUMENT_REPORTS = "LOAD_ALL_INSTRUMENT_REPORTS";
export const INSTRUMENT_REPORTS_LOADED = "INSTRUMENT_REPORTS_LOADED";
export const LOAD_ALL_INSTRUMENT_BINS = "LOAD_ALL_INSTRUMENT_BINS";
export const INSTRUMENT_BINS_LOADED = "INSTRUMENT_BINS_LOADED";
export const LOAD_ALL_GRAPH_TYPES = "LOAD_ALL_GRAPH_TYPES";
export const GRAPH_TYPES_LOADED = "GRAPH_TYPES_LOADED";
export const LOAD_SUBSCORE_TYPES = "LOAD_ALL_SUBSCORE_TYPES";
export const SUBSCORE_TYPES_LOADED = "SUBSCORE_TYPES_LOADED";
export const LOAD_ALL_ROLES = "LOAD_ALL_ROLES";
export const ALL_ROLES_LOADED = "ALL_ROLES_LOADED";
export const LOAD_ALL_TEXT = "LOAD_ALL_TEXT";
export const ALL_TEXT_LOADED = "ALL_TEXT_LOADED";
export const REPORT_INDEX_CHANGE = "REPORT_INDEX_CHANGE";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const UPDATE_SCREEN = "UPDATE_SCREEN";
export const SCREEN_INDEX_CHANGE = "SCREEN_INDEX_CHANGE";
export const SERIES_INDEX_CHANGE = "SERIES_INDEX_CHANGE";
export const UPDATE_SERIES = "UPDATE_SERIES";
export const NEW_SERIES = "NEW_SERIES";
export const NEW_SCREEN = "NEW_SCREEN";
export const NEW_REPORT = "NEW_REPORT";
export const DELETE_SERIES = "DELETE_SERIES";
export const DELETE_SCREEN = "DELETE_SCREEN";
export const DELETE_REPORT = "DELETE_REPORT";
export const LOAD_ALL_POSITIONS = "LOAD_ALL_POSITIONS";
export const ALL_POSITIONS_LOADED = "ALL_POSITIONS_LOADED";
export const DUPLICATE_REPORT = "DUPLICATE_REPORT";
export const DUPLICATE_SCREEN = "DUPLICATE_SCREEN";
export const SWITCH_REPORT_ROLE = "SWITCH_REPORT_ROLE";
export const ADD_REPORT_ROLE = "ADD_REPORT_ROLE";
export const REMOVE_REPORT_ROLE = "REMOVE_REPORT_ROLE";
export const LOAD_MISSING_VALUE_METHODS = "LOAD_MISSING_VALUE_METHODS";
export const MISSING_VALUE_METHODS_LOADED = "MISSING_VALUE_METHODS_LOADED";
export const LOAD_INSTRUMENT_SCORING_METHODS =
  "LOAD_INSTRUMENT_SCORING_METHODS";
export const INSTRUMENT_SCORING_METHODS_LOADED =
  "INSTRUMENT_SCORING_METHODS_LOADED";
export const LOAD_QUESTION_TYPES = "LOAD_QUESTION_TYPES";
export const QUESTION_TYPES_LOADED = "QUESTION_TYPES_LOADED";
export const LOAD_TIME_UNITS = "LOAD_TIME_UNITS";
export const TIME_UNITS_LOADED = "TIME_UNITS_LOADED";
export const LOAD_REMINDER_TYPES = "LOAD_REMINDER_TYPES";
export const REMINDER_TYPES_LOADED = "REMINDER_TYPES_LOADED";
export const LOAD_CRF_DATE_VALIDATION_TYPES = "LOAD_CRF_DATE_VALIDATION_TYPES";
export const CRF_DATE_VALIDATION_TYPES_LOADED =
  "CRF_DATE_VALIDATION_TYPES_LOADED";
export const LOAD_CRF_DATE_VALIDATION_OPERATORS =
  "LOAD_CRF_DATE_VALIDATION_OPERATORS";
export const CRF_DATE_VALIDATION_OPERATORS_LOADED =
  "CRF_DATE_VALIDATION_OPERATORS_LOADED";
export const LOAD_CRF_DATETIME_VALIDATION_TYPES =
  "LOAD_CRF_DATETIME_VALIDATION_TYPES";
export const CRF_DATETIME_VALIDATION_TYPES_LOADED =
  "CRF_DATETIME_VALIDATION_TYPES_LOADED";
export const LOAD_CRF_DATETIME_VALIDATION_OPERATORS =
  "LOAD_CRF_DATETIME_VALIDATION_OPERATORS";
export const CRF_DATETIME_VALIDATION_OPERATORS_LOADED =
  "CRF_DATETIME_VALIDATION_OPERATORS_LOADED";
export const LOAD_CRF_QUESTION_TYPES = "LOAD_CRF_QUESTION_TYPES";
export const CRF_QUESTION_TYPES_LOADED = "CRF_QUESTION_TYPES_LOADED";
export const LOAD_CRF_STRING_VALIDATION_OPERATORS =
  "LOAD_CRF_STRING_VALIDATION_OPERATORS";
export const CRF_STRING_VALIDATION_OPERATORS_LOADED =
  "CRF_STRING_VALIDATION_OPERATORS_LOADED";
export const LOAD_CRF_INTEGER_VALIDATION_TYPES =
  "LOAD_CRF_INTEGER_VALIDATION_TYPES";
export const CRF_INTEGER_VALIDATION_TYPES_LOADED =
  "CRF_INTEGER_VALIDATION_TYPES_LOADED";
export const LOAD_CRF_DECIMAL_VALIDATION_TYPES =
  "LOAD_CRF_DECIMAL_VALIDATION_TYPES";
export const CRF_DECIMAL_VALIDATION_TYPES_LOADED =
  "CRF_DECIMAL_VALIDATION_TYPES_LOADED";
export const LOAD_ROLES = "LOAD_ROLES";
export const ROLES_LOADED = "ROLES_LOADED";
export const LOAD_DATA_QUERY_STATUSES = "LOAD_DATA_QUERY_STATUSES";
export const DATA_QUERY_STATUSES_LOADED = "DATA_QUERY_STATUSES_LOADED";
export const LOAD_DATA_QUERY_TYPES = "LOAD_DATA_QUERY_TYPES";
export const DATA_QUERY_TYPES_LOADED = "DATA_QUERY_TYPES_LOADED";
export const IMPERSONATE_PATIENT = "IMPERSONATE_PATIENT";
export const LOAD_PATIENT_DELETE_REASONS = "LOAD_PATIENT_DELETE_REASONS";
export const PATIENT_DELETE_REASONS_LOADED = "PATIENT_DELETE_REASONS_LOADED";

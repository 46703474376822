import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { deleteCrf } from "../../api/Crf";
import { toast } from "react-toastify";
import ButtonBar from "../common/ButtonBar";

const DeleteCrf = ({ onExit = () => null, crf }) => {
  const _deleteCrf = () => {
    if (crf?.id !== null) {
      deleteCrf(crf.id).then((response) => {
        if (response.data === true) {
          toast.success(`CRF ID: ${crf.id} successfully deleted`);
          onExit();
        } else {
          toast.error("Unable to delete CRF");
        }
      });
    }
  };

  return (
    <Modal className={"delete-crf"} toggle={onExit} isOpen={crf?.id !== null}>
      <ModalHeader toggle={onExit}>Delete CRF?</ModalHeader>
      <ModalBody>
        {`Are you sure you want to delete the ${crf.typeName} CRF with ID: ${crf.id}?`}
        <ButtonBar
          buttons={[
            <Button key={"delete-button"} color={"danger"} onClick={_deleteCrf}>
              Delete
            </Button>,
            <Button key={"cancel-button"} color={"secondary"} onClick={onExit}>
              Cancel
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

DeleteCrf.propTypes = {
  crf: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired,
};

export default DeleteCrf;

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar, Scatter } from "react-chartjs-2";
import PropTypes from "prop-types";
import { getStartCase, isEmptyOrNull } from "../../Utils";
import { memo } from "react";
import { useMediaQuery } from "react-responsive";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
);

export const CHART_TYPES = {
  STACKED: { TYPE: "Stacked", MODE: "x" },
  SCATTER: { TYPE: "Scatter", MODE: "point" },
  BAR: { TYPE: "Bar", MODE: "index" },
  NOT_DISPLAYED: { TYPE: "Not Displayed" },
  ALERT: { TYPE: "ALERT" },
  PERSON: { TYPE: "Person" },
  // Only used to show the patients' answer - not actually a chart type
  // but is set as a series type in the response data
  PEOPLE: { TYPE: "People" },
};

const TRENDLINE = "trendline";

const ResultGraph = memo(
  ({
    series,
    showYAxis = false,
    maxY = 0,
    xAxisLabels = [],
    tooltip = false,
    titleYAxis = null,
    titleXAxis = null,
    yAxisIsPercent,
  }) => {
    if (!series) {
      return (
        <div className="loader">
          <div className="loading-spinner" />
        </div>
      );
    }
    const isSmallScreen = useMediaQuery({ maxWidth: 991 });
    /** Check if any of the points in any of the series say they should show the y-axis percent */
    let shouldShowPercentOnBar = false;
    for (let i = 0; i < series.length; i++) {
      for (let j = 0; j < series[i].points.length; j++) {
        // Don't show percentages if on mobile
        if (series[i].points[j].showPercent) {
          shouldShowPercentOnBar = true;
        }
      }
    }

    // Determine the chart type by getting the series type
    // (should be the same for each series given)
    // Not the same for Person charts TODO get unique types and check if array length = 1
    const chartType = series.map((s) => s.type)[0];

    /**
     * Determine if the "You answered" text needs to be offset
     * True when all the following is true:
     * - Chart is a person type (ie patient view)
     * - The 2 comparison series have one point only and the realX of
     *   those points is the same
     */
    let requireYouAnsweredTextOffset = false;
    if (chartType === CHART_TYPES.PERSON.TYPE) {
      let firstPointX = undefined;
      let secondPointX = undefined;
      for (let i = 0; i < series.length; i++) {
        if (series[i].isComparison && series[i].points.length === 1) {
          if (firstPointX === undefined) {
            firstPointX = series[i].points[0].realX;
          } else if (secondPointX === undefined) {
            secondPointX = series[i].points[0].realX;
          }
        }
      }
      requireYouAnsweredTextOffset = firstPointX === secondPointX;
    }

    /** Custom tooltip positioner to fix it to the top of the chart */
    Tooltip.positioners.fixedY = function (items) {
      const pos = Tooltip.positioners.average(items);

      // Happens when nothing is found
      if (pos === false) {
        return false;
      }

      const chart = this.chart;
      return {
        x: pos.x,
        y: chart.chartArea.top + 70,
        xAlign: "center",
        yAlign: "bottom",
      };
    };

    /** Required custom plugin to have a white background for the entire canvas */
    const customBackgroundColor = {
      id: "customBackgroundColor",
      afterDraw: (chart) => {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
    };

    /** Custom plugin to draw a trendline from the bottom left to top right of scatter charts */
    const scatterChartTrendline = {
      id: "scatterChartTrendline",
      beforeDraw: (chart) => {
        const {
          ctx,
          chartArea: { top, bottom, left, right },
        } = chart;
        ctx.save();

        ctx.moveTo(left, bottom);
        ctx.lineTo(right, top);
        ctx.strokeStyle = "#626262";
        ctx.stroke();

        ctx.restore();
      },
    };

    const customLegendColours = {
      id: "customLegendColours",
      beforeDraw: (chart) => {
        let legendItems = chart.legend.legendItems;
        let barSeries = series.filter((s) => s.type === CHART_TYPES.BAR.TYPE);
        let peopleSeries = series.filter(
          (s) => s.type === CHART_TYPES.PEOPLE.TYPE,
        );

        // Change legend colour to be the same as the point the patient's answer is.
        for (let i = 0; i < legendItems.length; i++) {
          legendItems[i].fillStyle = barSeries[i].points.find(
            (p) => p.realX === peopleSeries[0].points[i].realX,
          ).fill;
        }
      },
    };

    // Define the image here, so it isn't re-created every time the chart is rendered
    const img = new Image();
    img.src = "/person.svg";

    /** Custom plugin to draw a person image, text and an arrow for the patients' answer on person charts */
    const youAnsweredText = {
      id: "youAnsweredText",
      afterDraw: (chart) => {
        const {
          ctx,
          chartArea: { top, bottom },
        } = chart;
        const peopleSeries = series.filter(
          (s) => s.type === CHART_TYPES.PEOPLE.TYPE,
        );
        if (peopleSeries.length !== 1) {
          return;
        }

        // For each dataset in the chart, find the patient answer and put the text there
        // Need to loop through each dataset (_metasets) as each will have an answer
        // Then loop through the data points for that dataset to match the x-axis point
        chart._metasets.forEach((m, i) => {
          m.data.forEach((d, j) => {
            // Only show on what the patient answered
            if (j === peopleSeries[0].points[i].realX) {
              ctx.save();

              const imgWidth = 40;
              const imgHeight = 40;
              ctx.drawImage(
                img,
                d.x - imgWidth / 2,
                bottom - imgHeight,
                imgWidth,
                imgHeight,
              );

              // Add text to the top of the chart above the bar
              // If the 2 texts are on the same x value, set the
              // "After" text to be slightly lower
              const personText = peopleSeries[0].points[i].personText;
              ctx.textAlign = "center";
              let yOffset = 10;
              let xOffset = d.x;
              if (
                requireYouAnsweredTextOffset &&
                personText.toUpperCase() === "AFTER"
              ) {
                yOffset = 25;
                xOffset = d.x + 5;
                if (isSmallScreen) {
                  xOffset = xOffset + 5;
                }
              }
              ctx.fillText(personText, xOffset, top + yOffset);

              // If the chart is the patient view and there are percentages shown on the bars,
              // the arrow needs to end further down as there is no percentage text on the
              // top of the bars
              let arrowYEnd =
                chartType === CHART_TYPES.PERSON.TYPE && !shouldShowPercentOnBar
                  ? 10
                  : 30;

              // Add an arrow going from the text to the top of the bar
              // Start at the top of the chart and draw down
              ctx.beginPath();
              ctx.moveTo(d.x, top + yOffset + 10);
              ctx.lineTo(d.x, d.y - arrowYEnd);
              ctx.moveTo(d.x, d.y - arrowYEnd);
              ctx.lineTo(d.x + 6, d.y - (arrowYEnd + 6));
              ctx.moveTo(d.x, d.y - arrowYEnd);
              ctx.lineTo(d.x - 6, d.y - (arrowYEnd + 6));
              ctx.stroke();

              ctx.restore();
            }
          });
        });
      },
    };

    /** Sets the suggested max y-axis value to the max y value of the data
     *  plus a bit more rounded down to the nearest base 10 or 5 */
    const getSuggestedMaxY = () => {
      // How much extra space above the bars
      let yOffset = 10;
      if (maxY > 50) {
        yOffset = 20;
      } else if (maxY > 30) {
        yOffset = 15;
      }
      // Defines the y-axis scale. Either multiples of 5 or 10
      let multiplier = 10;
      if (maxY <= 30) {
        multiplier = 5;
      }
      let y = Math.floor((maxY + yOffset) / multiplier) * multiplier;
      if (y > 100) {
        return 100;
      }
      return y;
    };

    const options = {
      maintainAspectRatio: false,
      plugins: {
        customCanvasBackgroundColor: {},
        title: { display: false },
        legend: {
          labels: {
            usePointStyle: true,
          },
          // Don't show the chart legend for scatter charts
          display: ![
            CHART_TYPES.SCATTER.TYPE,
            CHART_TYPES.PERSON.TYPE,
            CHART_TYPES.PEOPLE.TYPE,
          ].includes(chartType),
          onClick: (event, legendItem, legend) => {
            // Clicking legend items toggle display of that series
            // Disable click action for charts shown to patients
            if (chartType === CHART_TYPES.PERSON.TYPE) {
              return;
            }
            const index = legendItem.datasetIndex;
            const ci = legend.chart;
            if (ci.isDatasetVisible(index)) {
              ci.hide(index);
              legendItem.hidden = true;
            } else {
              ci.show(index);
              legendItem.hidden = false;
            }
          },
        },
        tooltip: {
          // https://www.chartjs.org/docs/latest/configuration/interactions.html#modes
          mode: CHART_TYPES[chartType.toUpperCase()].MODE,
          // Mouse must intersect the actual data on scatter charts only
          intersect: chartType === CHART_TYPES.SCATTER.TYPE,
          // Fix tooltip to top of screen for bar charts with custom tooltip positioner
          position:
            chartType !== CHART_TYPES.SCATTER.TYPE ? "fixedY" : "nearest",
          callbacks: {
            label: (context) => {
              // For each dataset (patient point) in the scatter chart, show the patient name
              if (chartType === CHART_TYPES.SCATTER.TYPE) {
                return getStartCase(context.dataset.label);
              }
              return `${context.dataset.label}: ${context.parsed.y}%`;
            },
            title: (context) => {
              // As the scatter chart has each patient point as its own dataset,
              // only show the before and after values as the title.
              // The patient names will be rendered as the tooltip label
              if (chartType === CHART_TYPES.SCATTER.TYPE) {
                return [
                  `Before: ${context[0].dataset.data[0].x}`,
                  `After: ${context[0].dataset.data[0].y}`,
                ];
              }
              // For stacked bar charts, the title should be the label of the dataset
              // instead of the x-axis label
              if (chartType === CHART_TYPES.STACKED.TYPE) {
                return context.label;
              }
            },
          },
          // Filter out all 'trendline' datasets (used to show the trendline in scatter charts)
          filter: (context) => context.dataset.label !== TRENDLINE,
          enabled: tooltip,
        },
        // Data labels only for non-stacked bar charts and person charts
        datalabels: {
          display: [CHART_TYPES.BAR.TYPE, CHART_TYPES.PERSON.TYPE].includes(
            chartType,
          ),
          anchor: "end",
          align: "end",
          formatter: (value) => {
            if (isSmallScreen || !shouldShowPercentOnBar) {
              return "";
            }
            return value + "%";
          },
          font: {
            size: 11,
          },
          padding: {
            right: 1,
            left: 1,
            top: 2,
          },
          backgroundColor: "white",
        },
      },
      hover: { mode: chartType === CHART_TYPES.SCATTER.TYPE ? "point" : "x" },
      scales: {
        x: {
          stacked: chartType === CHART_TYPES.STACKED.TYPE,
          grid: { drawOnChartArea: false },
          title: { display: titleXAxis, text: titleXAxis },
        },
        y: {
          display: showYAxis || isSmallScreen,
          suggestedMax: getSuggestedMaxY(),
          stacked: chartType === CHART_TYPES.STACKED.TYPE,
          grid: { drawOnChartArea: false },
          ticks: {
            // Include a percent sign in the ticks
            callback: (value) => (yAxisIsPercent ? value + "%" : value),
          },
          title: { display: titleYAxis, text: titleYAxis },
        },
      },
      elements: {
        point: {
          radius: 6,
          hoverRadius: 8,
        },
      },
      layout: {
        padding: {
          // Add some padding on the right so the "you answered" text isn't cut off
          // Only happens for the "Expected" text
          right: showYAxis && 20,
        },
      },
    };

    const setupData = () => {
      // For scatter charts
      // Creates a dataset for each point in the chart so the
      // tooltips can show patient names nicely on hover
      if (chartType === CHART_TYPES.SCATTER.TYPE) {
        let scatterData = [];
        series[0].points.forEach((p) => {
          scatterData.push({
            data: [{ x: p.x, y: p.y }],
            label: p.patientName,
            backgroundColor: p.stroke,
          });
        });
        return scatterData;
      }

      // For stacked bar charts
      // Need to consolidate the results for each hospital which requires using the labels from the points
      // with the names of the series
      if (chartType === CHART_TYPES.STACKED.TYPE) {
        let stackedBarData = [];
        let _points = [];
        series.forEach((s) => {
          // Skip any series that don't have a name as these also don't have data
          if (s.name === null) {
            return;
          }
          // Add each point to a temporary array to get the data we actually need
          // as it's easier to flatten the series and points and then set up the data after
          s.points.forEach((p) => {
            _points.push({
              x: s.name,
              y: p.realY,
              label: p.longLabel,
              backgroundColor: p.fill,
            });
          });
        });
        // Get all the unique labels from the _points array, so we can add the right data to each dataset
        const uniqueLabels = [...new Set(_points.map((item) => item.label))];
        // For each of the labels, create a new dataset with the data we flattened previously
        uniqueLabels.forEach((label) => {
          // Filter the points in the _points array by label
          const point = _points.filter((p) => p.label === label);
          stackedBarData.push({
            label,
            data: point,
            // This is ok because all points with the same label will have the same backgroundColor (or fill)
            backgroundColor: point[0].backgroundColor,
          });
        });
        return stackedBarData;
      }

      // For regular bar charts
      // If the chart type is either 'Not Displayed' or 'Alert' (not enough data for comparison),
      // don't show it. Have to filter out these results after the fact because .map() will set
      // each entry to undefined if it matches the conditions.
      return series
        .map((s) => {
          if (
            s.type !== CHART_TYPES.NOT_DISPLAYED.TYPE &&
            s.type !== CHART_TYPES.ALERT.TYPE
          ) {
            if (s.name === null) {
              return;
            }
            return {
              label: s.name,
              data: s.points.map((p) => p.realY),
              backgroundColor: s.points.map((p) => p.fill),
            };
          }
        })
        .filter((e) => !isEmptyOrNull(e));
    };

    const data = {
      labels: xAxisLabels,
      datasets: setupData(),
    };

    return (
      // Set the min height to 400px so the charts show bigger on small screens
      <div style={{ minHeight: 400 }}>
        {chartType === CHART_TYPES.SCATTER.TYPE ? (
          <Scatter
            data={data}
            options={options}
            plugins={[customBackgroundColor, scatterChartTrendline]}
          />
        ) : (
          <Bar
            options={options}
            data={data}
            plugins={[
              ChartDataLabels,
              customBackgroundColor,
              chartType === CHART_TYPES.PERSON.TYPE && youAnsweredText,
              // chartType === CHART_TYPES.PERSON.TYPE && customLegendColours,
            ]}
            redraw
          />
        )}
      </div>
    );
  },
);

ResultGraph.propTypes = {
  series: PropTypes.array.isRequired,
  showYAxis: PropTypes.bool,
  maxY: PropTypes.number,
  xAxisLabels: PropTypes.arrayOf(PropTypes.string),
  tooltip: PropTypes.bool,
  titleYAxis: PropTypes.string,
  titleXAxis: PropTypes.string,
  yAxisIsPercent: PropTypes.bool,
};

export default ResultGraph;

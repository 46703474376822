import { Fragment, useReducer } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import FileUploader from "../common/FileUploader";
import { isEmptyOrNull } from "../../Utils";
import "./NewUpload.css";
import { saveUpload } from "../../api/Upload";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { renderErrorMessageForFileUpload } from "./Utils";

const NewUpload = ({ onConfirm = (message) => {} }) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { description: "", file: null, followUp: false, errorMessage: null },
  );

  const fileAccepted = (file) => {
    setState({ file: file, errorMessage: null });
  };

  const uploadFile = () => {
    dispatch(startLoading());

    const formData = new FormData();
    formData.append("name", state.file.name);
    formData.append("description", state.description);
    formData.append("file", state.file);
    formData.append("followUp", state.followUp);

    saveUpload(formData)
      .then(() => {
        setState({ description: "", file: null, errorMessage: null });
        onConfirm("Responses upload saved successfully.");
      })
      .catch((error) => {
        setState({
          description: "",
          file: null,
          errorMessage: renderErrorMessageForFileUpload(error),
        });
      })
      .finally(() => dispatch(finishLoading()));
  };

  return (
    <Card className={"new-upload"}>
      <CardBody>
        <CardTitle>New Upload</CardTitle>
        <FormGroup row>
          <FileUploader
            acceptedFileTypes={{
              "text/csv": [".csv"],
              "application/vnd.ms-excel": [".csv", ".xls", ".xlsx"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".csv", ".xls", ".xlsx"],
            }}
            maxFileSizeBytes={1000000}
            onFileAccepted={fileAccepted}
            instructionText={
              "Drag and drop a new CSV or Excel file (up to 10MB) here, or click anywhere within this box to browse for a file."
            }
          />
        </FormGroup>
        {state.file !== null && (
          <Fragment>
            <Alert color={"warning"}>
              Before submitting please ensure that your data conforms to the
              following requirements:
              <ul>
                <li>No duplicate procedures for the same patient</li>
                <li>
                  The patient registration date is not before the hospital
                  commenced in the study
                </li>
                <li>The patient registration date is not in the future</li>
                <li>Patient ages are correct and valid</li>
                <li>Postcodes are present for each patient</li>
                <li>
                  At least one form of contact information (email, mobile or
                  home phone) is present (the more the better)
                </li>
                <li>Phone numbers are correct and valid</li>
                <li>Email addresses are correct and valid</li>
                <li>
                  None of the following are missing:
                  <ul>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>DOB</li>
                    <li>Procedure Type</li>
                    <li>Joint</li>
                    <li>Side</li>
                  </ul>
                </li>
                <li>Missing responses should be coded as -9</li>
              </ul>
            </Alert>
            <Form className={"submit-form"}>
              <FormGroup row>
                <Label
                  className={"description-label"}
                  for={"description"}
                  sm={6}
                >
                  {state.file.name}
                </Label>
                <Col sm={6}>
                  <Input
                    value={state.description}
                    valid={!isEmptyOrNull(state.description)}
                    placeholder={"Please enter a description for this file"}
                    onChange={(event) =>
                      setState({ description: event.target.value })
                    }
                  />
                </Col>
              </FormGroup>
              <Row className={"justify-content-end"}>
                <Col sm={6}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        id={"followUp"}
                        checked={state.followUp}
                        onChange={() => setState({ followUp: !state.followUp })}
                      />{" "}
                      Trials system to handle further patient follow-ups
                    </Label>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <Button
                    type={"button"}
                    color={"primary"}
                    disabled={isEmptyOrNull(state.description)}
                    onClick={uploadFile}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Fragment>
        )}
        {!isEmptyOrNull(state.errorMessage) && (
          <Alert color={"danger"}>{state.errorMessage}</Alert>
        )}
      </CardBody>
    </Card>
  );
};

export default NewUpload;

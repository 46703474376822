import * as Types from "../actions/Types";
import {
  finishLoading,
  startLoading,
  surgeonsLoaded,
} from "../actions/CommonActions";
import {
  sendSurgeonSmsError,
  sendSurgeonSmsSuccess,
} from "../actions/SurgeonActions";
import { getSurgeons, sendSms } from "../api/Surgeon";
import {
  getContactAoanjrrComponent,
  getContactText,
  isEmptyOrNull,
} from "../Utils";
import React from "react";

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === Types.SEND_SURGEON_SMS) {
      dispatch(startLoading());
      dispatch(sendSurgeonSmsError(null));
      sendSms(action.payload)
        .then((response) => {
          dispatch(sendSurgeonSmsSuccess(response.data));
          dispatch(finishLoading());
        })
        .catch((error) => {
          if (
            error.response.status === 400 &&
            error.response.data &&
            !isEmptyOrNull(error.response.data)
          ) {
            if (error.response.data === "LockedException") {
              dispatch(
                sendSurgeonSmsError(
                  <span>
                    Your account has been locked.
                    <br />
                    {getContactAoanjrrComponent()}
                  </span>,
                ),
              );
            } else if (error.response.data === "BadCredentialsException") {
              dispatch(
                sendSurgeonSmsError(
                  <span>
                    Your email or mobile phone number were incorrect.{" "}
                    {getContactAoanjrrComponent()}
                  </span>,
                ),
              );
            } else if (error.response.data === "AccountExpiredException") {
              dispatch(
                sendSurgeonSmsError(
                  <span>
                    Your account has expired due to inactivity.{" "}
                    {getContactAoanjrrComponent()}
                  </span>,
                ),
              );
            } else if (error.response.data === "CredentialsExpiredException") {
              dispatch(
                sendSurgeonSmsError(
                  <span>
                    An error has occurred. <br />
                    {getContactText()}
                  </span>,
                ),
              );
            } else if (error.response.data === "DisabledException") {
              dispatch(
                sendSurgeonSmsError(
                  <span>
                    Your account has been disabled.{" "}
                    {getContactAoanjrrComponent()}
                  </span>,
                ),
              );
            } else {
              dispatch(
                sendSurgeonSmsError(
                  <span>
                    There was an issue logging you in: {error.response.data}
                    <br />
                    {getContactText()}
                  </span>,
                ),
              );
            }
          } else {
            dispatch(
              sendSurgeonSmsError(
                <span>
                  There was an issue sending you a code. Please try again later.
                  <br />
                  {getContactText()}
                </span>,
              ),
            );
          }
          dispatch(finishLoading());
        });
    }
    if (action.type === Types.LOAD_SURGEONS) {
      getSurgeons().then((response) => {
        dispatch(surgeonsLoaded(response.data));
      });
    }
    return next(action);
  };

import "bootstrap/dist/css/bootstrap.css";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import PromiseMiddleware from "redux-promise";
import PatientMiddleware from "./middleware/PatientMiddleware";
import CommonMiddleware from "./middleware/CommonMiddleware";
import rootReducer from "./reducers";
import UserMiddleware from "./middleware/UserMiddleware";
import SurgeonMiddleware from "./middleware/SurgeonMiddleware";
import Api from "./api/Api";
import * as Sentry from "@sentry/react";
import packageJson from "../package.json";
import { getSentryUri } from "./Utils";
import InstrumentReportMiddleware from "./middleware/InstrumentReportMiddleware";
import CrfTypeManagementMiddleware from "./middleware/CrfTypeManagementMiddleware";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const store = createStore(
  rootReducer,
  /* initial state */
  compose(
    applyMiddleware(
      ReduxThunk,
      PromiseMiddleware,
      PatientMiddleware,
      CommonMiddleware,
      UserMiddleware,
      SurgeonMiddleware,
      InstrumentReportMiddleware,
      CrfTypeManagementMiddleware,
    ),
    /* This will load the store hooks for the Chrome redux devtools extension if it has it installed */
    // Adding the trace option allows us to view an action and see where it was dispatched from.
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
      : (f) => f,
  ),
);

Api.setupInterceptors(store);

Sentry.init({
  dsn: window?.location?.hostname
    ? getSentryUri(window.location.hostname)
    : null,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  release: "proms-" + packageJson.version,
});

const container = document.getElementById("root");
const root = createRoot(container);

function AppWithCallbackAfterRender() {
  useEffect(() => {
    document.body.classList.remove("no-hover");
    if (store.getState().app.noHover) {
      document.body.classList.add("no-hover");
    }
  });

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

root.render(<AppWithCallbackAfterRender />);

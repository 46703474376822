import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { getConsentedStudyList } from "../../api/Patient";
import ButtonBar from "../common/ButtonBar";
import { useEffect, useState } from "react";

const PatientInfoSheets = ({ open = false, toggle = () => null }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getConsentedStudyList().then((response) => {
      setData(response.data);
    });
  }, []);

  const setStudyNames = (names) => {
    const listItems = names.map((name) => <li key={name}>{name}</li>);
    return <ul>{listItems}</ul>;
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size="lg"
      style={{ fontSize: "1.2em" }}
      autoFocus
    >
      <ModalHeader>Patient Information Sheets</ModalHeader>
      <ModalBody>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            The Patient Information and Consent form(s) which you reviewed prior
            to completing the study requirements are listed in the table below.
          </div>
          <br />
          <div>
            Please select the PDF link to download the form(s) to your device.
          </div>
          <div style={{ marginTop: 20 }}>
            <Table size="sm" bordered striped responsive>
              <thead>
                <tr>
                  <th>Study</th>
                  <th>Hospital</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {data.map((consent, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ paddingTop: 5, paddingBottom: 5 }}>
                        {setStudyNames(consent.studyNames)}
                      </td>
                      <td>{consent.hospitalName}</td>
                      <td>
                        <a
                          href={`api/study/information-sheet?studyId=${consent.studyId}&hospitalId=${consent.hospitalId}`}
                          target="_blank"
                        >
                          PDF
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <ButtonBar
          buttons={[
            <Button
              key={"close-button"}
              color="primary"
              outline
              onClick={toggle}
            >
              Close
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

PatientInfoSheets.propTypes = {
  open: PropTypes.bool,
};

export default PatientInfoSheets;

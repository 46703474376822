import { getMatchedRegistryProcedures } from "../../api/Reporting";
import BarReport from "./BarReport";
import { aoaLightBlue, aoaTan } from "./Utils";
import PropTypes from "prop-types";

const MatchedProcedures = ({ useStudySelector = false }) => {
  const mapColours = (series, index) => {
    if (index === 0) {
      return aoaLightBlue;
    } else {
      return aoaTan;
    }
  };

  return (
    <BarReport
      fetchData={getMatchedRegistryProcedures}
      mapColours={mapColours}
      id={"matched-procedures"}
      useProcedureTypeSelector={true}
      useStudySelector={useStudySelector}
      subtitle={
        <div>
          <i>
            Excluding unmatched AOANJRR procedures with a diagnosis of Fractured
            Neck of Femur (Hip) or Fracture (any joint)
          </i>
        </div>
      }
      legendHovers={{
        Matched:
          "Registry procedures that have been matched to a PROMs procedure",
        Unmatched:
          "Registry procedures that have not been matched to a PROMs procedure",
      }}
    />
  );
};

MatchedProcedures.propTypes = {
  useStudySelector: PropTypes.bool,
};

export default MatchedProcedures;

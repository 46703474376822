import aoaLogo from "../../images/group_aoa_logo.png";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import Responsive from "react-responsive";

const SimpleLogoHeader = ({ link = false }) => {
  const getImage = () => {
    return <img src={aoaLogo} alt="AOANJRR Logo" />;
  };

  return (
    <div style={{ padding: "10px" }}>
      <Responsive minWidth={641}>
        <Row className={"g-0"}>
          <Col xs={0} sm={0} md={0} lg={1} xl={1} />
          <Col xs={0} sm={0} md={0} lg={11} xl={11}>
            {link ? (
              <a href="/" title="Go to homepage">
                {getImage()}
              </a>
            ) : (
              getImage()
            )}
          </Col>
        </Row>
      </Responsive>
    </div>
  );
};

SimpleLogoHeader.propTypes = {
  link: PropTypes.bool,
};

export default SimpleLogoHeader;

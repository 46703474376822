import React, { Component, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { FaArrowDown, FaArrowUp, FaTrash } from "react-icons/fa";
import { TiArrowMaximise, TiArrowMinimise } from "react-icons/ti";
import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import {
  deleteSeries,
  loadAllText,
  seriesIndexChange,
  updateSeries,
} from "../../actions/InstrumentReportActions";
import {
  addText,
  instrumentsForStudyCollection,
  questionsForInstrument,
} from "../../api/InstrumentReport";
import ColourPicker from "../common/ColourPicker";
import DOMPurify from "dompurify";

const clearTextOption = {
  id: -1,
  text: "Clear any selected option",
  type: null,
};

class ConfigureSeries extends Component {
  static defaultProps = {
    series: null,
    reportId: null,
    screenId: null,
    bin: null,
  };

  static propTypes = {
    series: PropTypes.object,
    reportId: PropTypes.number,
    screenId: PropTypes.number,
    bin: PropTypes.object,
  };

  constructor(props) {
    super(props);
    let state = {};

    let options = [];
    props.studies
      .sort((s1, s2) => (s1.id > s2.id ? 1 : s1.id < s2.id ? -1 : 0))
      .forEach((s) => {
        s.studyCollections
          .sort((sc1, sc2) => (sc1.id > sc2.id ? 1 : sc1.id < sc2.id ? -1 : 0))
          .forEach((sc) =>
            options.push({
              id: sc.id,
              name: `${s.name} ${sc.name}`,
            }),
          );
      });
    state.studyCollections = options;
    state.maximised = false;
    this.state = state;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.series.studyCollectionId !== this.props.series.studyCollectionId
    ) {
      this.loadInstruments(this.props.series.studyCollectionId);
    }
    if (
      prevProps.series.instrumentId !== this.props.series.instrumentId &&
      this.props.series.instrumentScoring === "QUESTION"
    ) {
      this.loadQuestions(this.props.series.instrumentId);
    }
  }

  componentDidMount() {
    this.loadInstruments();
    if (this.props.series.instrumentScoring === "QUESTION") {
      this.loadQuestions();
    }
  }

  loadInstruments(id) {
    instrumentsForStudyCollection(
      id != null ? id : this.props.series.studyCollectionId,
    ).then((response) => {
      this.setState({ instruments: response.data });
    });
  }

  loadQuestions(id) {
    questionsForInstrument(
      id != null ? id : this.props.series.instrumentId,
    ).then((response) => this.setState({ questions: response.data }));
  }

  toggle = () => {
    this.setState({ maximised: !this.state.maximised });
  };

  changeType = (option, type) => {
    let { reportId, screenId, updateSeries } = this.props;
    let { baselineLineMarks, comparisonLineMarks } = this.props.series;

    if (!(option.name === "Area" || option.name === "Line")) {
      if (type === "baselineSeriesType") {
        baselineLineMarks = false;
      } else if (type === "comparisonSeriesType") {
        comparisonLineMarks = false;
      }
    }

    let series = {
      ...this.props.series,
      [type]: option,
      combinedLegend:
        type === "baselineSeriesType" && option.name !== "Bar"
          ? false
          : this.props.series.combinedLegend,
      baselineLineMarks: baselineLineMarks,
      comparisonLineMarks: comparisonLineMarks,
    };
    updateSeries({ reportId, screenId, series });
  };

  changeStudyCollection = (studyCollection) => {
    let { reportId, screenId, updateSeries } = this.props;
    let series = {
      ...this.props.series,
      studyCollectionId: studyCollection.id,
      studyCollectionName: studyCollection.name,
      instrumentId: null,
      instrumentName: null,
      instrumentScoring: null,
      questionId: null,
      questionName: null,
      subscore: null,
    };
    updateSeries({ reportId, screenId, series });
  };

  changeInstrument = (instrument) => {
    let { reportId, screenId, updateSeries } = this.props;
    let series = {
      ...this.props.series,
      instrumentId: instrument.id,
      instrumentName: instrument.name,
      instrumentScoring: instrument.scoreType,
      questionId: null,
      questionName: null,
      subscore: null,
    };
    updateSeries({ reportId, screenId, series });
  };

  changeQuestion = (question) => {
    let { reportId, screenId, updateSeries } = this.props;
    let series = {
      ...this.props.series,
      questionId: question.id,
      questionName: question.name,
      subscore: null,
    };
    updateSeries({ reportId, screenId, series });
  };

  changeField = (field, value) => {
    let { reportId, screenId, updateSeries } = this.props;
    let series = {
      ...this.props.series,
      [field]: value,
    };
    updateSeries({ reportId, screenId, series });
  };

  changeBoolean = (field) => {
    let { reportId, screenId, updateSeries } = this.props;
    let series = {
      ...this.props.series,
      [field]: !this.props.series[field],
    };
    updateSeries({ reportId, screenId, series });
  };

  changeBaselineShowLegend = () => {
    let { reportId, screenId, updateSeries } = this.props;
    let checked = !this.props.series.baselineShowLegend;
    let series = {
      ...this.props.series,
      baselineShowLegend: checked,
      combinedLegend: checked ? this.props.series.combinedLegend : false,
    };
    updateSeries({ reportId, screenId, series });
  };

  changeUseLightColour = (type) => {
    let { reportId, screenId, updateSeries } = this.props;
    let series = { ...this.props.series };
    if (type === "BASELINE") {
      series.baselineUseProcedureTypeLightColour =
        !this.props.series.baselineUseProcedureTypeLightColour;
    } else {
      series.comparisonUseProcedureTypeLightColour =
        !this.props.series.comparisonUseProcedureTypeLightColour;
    }
    updateSeries({ reportId, screenId, series });
  };

  changeAutoColour = (type) => {
    let { reportId, screenId, updateSeries } = this.props;
    let series = { ...this.props.series };
    if (type === "BASELINE") {
      series.baselineUseProcedureTypeColour =
        !this.props.series.baselineUseProcedureTypeColour;
      if (!series.baselineUseProcedureTypeColour) {
        series.baselineUseProcedureTypeLightColour = false;
      }
      if (!series.baselineUseProcedureTypeColour) {
        series.baselineLineColour = "#2d3884";
        series.baselineFillColour = "#2d3884";
      } else {
        series.baselineLineColour = null;
        series.baselineFillColour = null;
      }
    } else {
      series.comparisonUseProcedureTypeColour =
        !this.props.series.comparisonUseProcedureTypeColour;
      if (!series.comparisonUseProcedureTypeColour) {
        series.comparisonUseProcedureTypeLightColour = false;
      }
      if (!series.comparisonUseProcedureTypeColour) {
        series.comparisonLineColour = "#2d3884";
        series.comparisonFillColour = "#2d3884";
      } else {
        series.comparisonLineColour = null;
        series.comparisonFillColour = null;
      }
    }
    updateSeries({ reportId, screenId, series });
  };

  changeText = (option, field) => {
    if (option.id < 0) {
      option = null;
    }
    let { reportId, screenId, updateSeries } = this.props;
    if (option == null || option.id != null) {
      let series = {
        ...this.props.series,
        [field]: option,
      };
      updateSeries({ reportId, screenId, series });
    }
  };

  createText = (option, field, type) => {
    let { reportId, screenId, updateSeries, loadAllText } = this.props;
    // New text save and reload text
    addText(option, type).then((response) => {
      let series = {
        ...this.props.series,
        [field]: response.data,
      };
      updateSeries({ reportId, screenId, series });
      loadAllText();
    });
  };

  changeColour = (colour, field) => {
    let { reportId, screenId, updateSeries } = this.props;
    let series = {
      ...this.props.series,
      [field]: `rgba(${colour.rgb.r},${colour.rgb.g},${colour.rgb.b},${colour.rgb.a})`,
    };
    updateSeries({ reportId, screenId, series });
  };

  getSubscoreInput() {
    const { series, subscores } = this.props;
    if (series.instrumentScoring !== "SUBSCORE") {
      return <Col xs={6} />;
    } else {
      return (
        <Fragment>
          <Col xs={2} className="text-end">
            Subscore:
          </Col>
          <Col xs={4}>
            <Select
              options={subscores}
              value={series.subscore}
              onChange={(value) => this.changeField("subscore", value)}
              isClearable={true}
              isSearchable={true}
              placeholder="Subscore"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            />
          </Col>
        </Fragment>
      );
    }
  }

  getQuestionInput() {
    const { series } = this.props;
    const { questions } = this.state;
    if (series.instrumentScoring !== "QUESTION") {
      return <Col xs={6} />;
    } else {
      let question = {
        id: series.questionId,
        name: series.questionName,
      };
      return (
        <Fragment>
          <Col xs={2} className="text-end">
            Question:
          </Col>
          <Col xs={4}>
            <Select
              options={questions}
              value={question}
              onChange={this.changeQuestion}
              isClearable={false}
              isSearchable={false}
              placeholder="Question"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            />
          </Col>
        </Fragment>
      );
    }
  }

  getTypeInput(type) {
    const { series, seriesTypes } = this.props;
    return (
      <Select
        options={seriesTypes}
        value={series[type]}
        onChange={(option) => this.changeType(option, type)}
        isClearable={false}
        isSearchable={false}
        placeholder="Display type"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
      />
    );
  }

  getPositionInput() {
    const { series, positions } = this.props;
    return (
      <Select
        id="positionInput"
        options={positions}
        value={series.position}
        onChange={(value) => this.changeField("position", value)}
        isClearable={false}
        isSearchable={false}
        placeholder="Point Position"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
      />
    );
  }

  getCreatableTextInput(field, type) {
    const { texts, series } = this.props;

    return (
      <CreatableSelect
        options={[
          clearTextOption,
          ...texts
            .filter((t) => t.type === type)
            .sort((ts1, ts2) => ts1.id - ts2.id),
        ]}
        getOptionLabel={(option) => this.optionRenderer(option)}
        getOptionValue={(option) => this.valueRenderer(option)}
        onChange={(option) => this.changeText(option, field)}
        onCreateOption={(option) => this.createText(option, field, type)}
        value={series[field]}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
      />
    );
  }

  optionRenderer(option) {
    if (!!option.__isNew__) {
      return option.label;
    }
    if (
      option.type === "ARROW" ||
      option.type === "LEGEND" ||
      option.type === "Y-AXIS"
    ) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: option != null ? DOMPurify.sanitize(option.text) : null,
          }}
        />
      );
    } else {
      return (
        <div className="option">
          <div
            className="raw"
            dangerouslySetInnerHTML={{
              __html:
                option != null && option.id >= 0
                  ? DOMPurify.sanitize(`${option.id}: ${option.text}`)
                  : null,
            }}
          />
        </div>
      );
    }
  }

  valueRenderer(option) {
    if (!!option.__isNew__) {
      return null;
    }
    let size =
      option.type === "ARROW" ||
      option.type === "LEGEND" ||
      option.type === "Y-AXIS"
        ? "1em"
        : "0.8em";
    let id =
      option.type === "ARROW" ||
      option.type === "LEGEND" ||
      option.type === "Y-AXIS"
        ? ""
        : `${option.id}: `;
    return (
      <span
        style={{ fontSize: size }}
        dangerouslySetInnerHTML={{
          __html:
            option != null ? DOMPurify.sanitize(`${id}${option.text}`) : null,
        }}
      />
    );
  }

  render() {
    const { studyCollections, instruments, maximised } = this.state;
    const { series, seriesIndexChange, screenId, reportId, deleteSeries } =
      this.props;

    if (!series) {
      return null;
    }

    let studyCollection = {
      id: series.studyCollectionId,
      name: series.studyCollectionName,
    };

    let instrument = {
      id: series.instrumentId,
      name: series.instrumentName,
      scoreType: series.instrumentScoring,
    };

    let id = `_${series.id}`;

    return (
      <Card style={{ padding: 10, width: "100%" }} className="series">
        <CardBody style={{ padding: 0 }}>
          <Row className={"g-0"}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ paddingRight: 10 }}>
                <FaArrowUp
                  size={14}
                  onClick={() =>
                    seriesIndexChange({ series, delta: -1, screenId, reportId })
                  }
                />
                <FaArrowDown
                  size={14}
                  onClick={() =>
                    seriesIndexChange({ series, delta: 1, screenId, reportId })
                  }
                />
                <b style={{ marginRight: 10 }}>
                  Series ID: {series.id || "UNSAVED"} (Order: {series.index})
                </b>
                {maximised ? (
                  <TiArrowMinimise onClick={this.toggle} size={24} />
                ) : (
                  <TiArrowMaximise onClick={this.toggle} size={24} />
                )}
              </div>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="rightToLeft"
                    checked={!!series.rightToLeft}
                    onChange={() => this.changeBoolean("rightToLeft")}
                  />{" "}
                  <UncontrolledTooltip placement="top" target="rightToLeft">
                    Control direction of x axis
                  </UncontrolledTooltip>
                  Right To Left
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="difference"
                    checked={!!series.difference}
                    onChange={(v) => this.changeBoolean("difference")}
                  />{" "}
                  <UncontrolledTooltip placement="top" target="difference">
                    Graph is a difference between preop and postop results.
                  </UncontrolledTooltip>
                  Difference
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="comparisonFallback"
                    checked={!!series.comparisonFallback}
                    onChange={(v) => this.changeBoolean("comparisonFallback")}
                  />{" "}
                  <UncontrolledTooltip
                    placement="top"
                    target="comparisonFallback"
                  >
                    Allow comparison series to fallback to national results if
                    no comparison criteria are selected. This is a special case
                    for showing pre/post hospital comparisons on the same graph
                    to handle when "All Hospitals" is selected (effectively a
                    national baseline) but it needs to be calculated as part of
                    the comparison series.
                  </UncontrolledTooltip>
                  Comparison Fallback
                </Label>
              </FormGroup>
              <div>
                <FaTrash onClick={() => deleteSeries({ series })} />
              </div>
            </div>
          </Row>
          <Row className={"g-0"}>
            <Col xs={2} className="text-end">
              Study Collection:
            </Col>
            <Col xs={4}>
              <Select
                options={studyCollections}
                value={studyCollection}
                onChange={this.changeStudyCollection}
                isClearable={false}
                isSearchable={false}
                placeholder="Study Collection"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
              />
            </Col>
            <Col xs={2} className="text-end">
              Instrument:
            </Col>
            <Col xs={4}>
              <Select
                options={instruments}
                value={instrument}
                onChange={this.changeInstrument}
                isClearable={false}
                isSearchable={false}
                placeholder="Instrument"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
              />
            </Col>
          </Row>
          <Row className={"g-0"}>
            {this.getQuestionInput()}
            {this.getSubscoreInput()}
          </Row>
          <hr />
          {!maximised && (
            <Row className={"g-0"}>
              Baseline {series.baselineSeriesType.name} - {series.position.name}{" "}
              <br />
              Comparison {series.comparisonSeriesType.name}
            </Row>
          )}
          <Row className={`series ${maximised ? "expanded" : "hidden"} g-0`}>
            <Row className={"g-0"}>
              <div style={{ marginRight: 20 }}>
                <strong>Baseline</strong>
              </div>
            </Row>
            <Row className={"g-0"}>
              <Col xs={3}>
                <div className="text-small">Type:</div>
                {this.getTypeInput("baselineSeriesType")}
              </Col>
              <Col xs={1} />
              <Col xs={3}>
                <div className="text-small">Position:</div>
                {this.getPositionInput()}
              </Col>
              <Col xs={1} />
              <Col xs={4} style={{ paddingLeft: 5 }}>
                <div className="text-small">Legend Text</div>
                {this.getCreatableTextInput("baselineLegendText", "LEGEND")}
              </Col>
            </Row>
            <Row className={"g-0"}>
              <Col xs={3} style={{ paddingLeft: 5 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="baselineShowPercent"
                      checked={!!series.baselineShowPercent}
                      onChange={() => this.changeBoolean("baselineShowPercent")}
                    />{" "}
                    Show Percent Value
                  </Label>
                </FormGroup>
              </Col>
              <Col xs={3} style={{ paddingLeft: 5 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="baselineShowLegend"
                      checked={!!series.baselineShowLegend}
                      onChange={() => this.changeBaselineShowLegend()}
                    />{" "}
                    Include in Legend
                  </Label>
                </FormGroup>
              </Col>
              {series.baselineSeriesType.name === "Bar" && (
                <Col xs={3} style={{ paddingLeft: 5 }}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="combinedLegend"
                        checked={!!series.combinedLegend}
                        onChange={() => this.changeBoolean("combinedLegend")}
                      />{" "}
                      Combined Bar Legend
                    </Label>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className={"g-0"}>
              <Col xs={3} style={{ paddingLeft: 5 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="baselineUseProcedureTypeColour"
                      checked={!!series.baselineUseProcedureTypeColour}
                      onChange={() => this.changeAutoColour("BASELINE")}
                    />{" "}
                    Procedure Type/Auto Colour
                  </Label>
                </FormGroup>
              </Col>
              {series.baselineUseProcedureTypeColour && (
                <Col xs={3} style={{ paddingLeft: 5 }}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="useBaselineProcedureTypeLightColour"
                        checked={!!series.baselineUseProcedureTypeLightColour}
                        onChange={() => this.changeUseLightColour("BASELINE")}
                      />{" "}
                      Procedure Type Light Colour
                    </Label>
                  </FormGroup>
                </Col>
              )}
              {!series.baselineUseProcedureTypeColour && (
                <Fragment>
                  <Col xs={3} style={{ paddingLeft: 5 }}>
                    <div style={{ display: "flex" }}>
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        Line Colour
                      </span>
                      <ColourPicker
                        color={series.baselineLineColour}
                        handleChangeComplete={(colour) =>
                          this.changeColour(colour, "baselineLineColour")
                        }
                        id={"bLine" + id}
                      />
                    </div>
                  </Col>
                  <Col xs={3} style={{ paddingLeft: 5 }}>
                    <div style={{ display: "flex" }}>
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        Fill Colour
                      </span>
                      <ColourPicker
                        color={series.baselineFillColour}
                        handleChangeComplete={(colour) =>
                          this.changeColour(colour, "baselineFillColour")
                        }
                        id={"bArea" + id}
                      />
                    </div>
                  </Col>
                </Fragment>
              )}
              {(series.baselineSeriesType.name === "Line" ||
                series.baselineSeriesType.name === "Area") && (
                <Col xs={3} style={{ paddingLeft: 5 }}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="baselineLineMarks"
                        checked={!!series.baselineLineMarks}
                        onChange={() => this.changeBoolean("baselineLineMarks")}
                      />{" "}
                      Line Marks
                    </Label>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className={"g-0"}>
              <strong>Patient/Surgeon/Stakeholder Comparison</strong>
            </Row>
            <Row className={"g-0"}>
              <Col xs={3}>
                <div className="text-small">Type:</div>
                {this.getTypeInput("comparisonSeriesType")}
              </Col>
              <Col xs={1} />
              <Col xs={3}>
                <div className="text-small">Arrow Text:</div>
                {this.getCreatableTextInput("comparisonPersonText", "ARROW")}
              </Col>
              <Col xs={1} />
              <Col xs={4}>
                <div className="text-small">Legend Text</div>
                {this.getCreatableTextInput("comparisonLegendText", "LEGEND")}
              </Col>
            </Row>
            <Row className={"g-0"}>
              <Col xs={3} style={{ paddingLeft: 5 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="comparisonShowPercent"
                      checked={!!series.comparisonShowPercent}
                      onChange={() =>
                        this.changeBoolean("comparisonShowPercent")
                      }
                    />{" "}
                    Show Percent Value
                  </Label>
                </FormGroup>
              </Col>
              {series.comparisonSeriesType.name !== "Person" &&
                series.comparisonSeriesType.name !== "Not Displayed" && (
                  <Col xs={3} style={{ paddingLeft: 5 }}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="comparisonShowLegend"
                          checked={!!series.comparisonShowLegend}
                          onChange={() =>
                            this.changeBoolean("comparisonShowLegend")
                          }
                        />{" "}
                        Include in Legend
                      </Label>
                    </FormGroup>
                  </Col>
                )}
            </Row>
            <Row className={"g-0"}>
              <Col xs={3} style={{ paddingLeft: 5 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="comparisonUseProcedureTypeColour"
                      checked={!!series.comparisonUseProcedureTypeColour}
                      onChange={() => this.changeAutoColour("COMPARISON")}
                    />{" "}
                    Procedure Type/Auto Colour
                  </Label>
                </FormGroup>
              </Col>
              {series.comparisonUseProcedureTypeColour && (
                <Col xs={3} style={{ paddingLeft: 5 }}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="useProcedureTypeLightColour"
                        checked={!!series.comparisonUseProcedureTypeLightColour}
                        onChange={() => this.changeUseLightColour("COMPARISON")}
                      />{" "}
                      Procedure Type Light Colour
                    </Label>
                  </FormGroup>
                </Col>
              )}
              {!series.comparisonUseProcedureTypeColour && (
                <Fragment>
                  <Col xs={3} style={{ paddingLeft: 5 }}>
                    <div style={{ display: "flex" }}>
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        Line Colour
                      </span>
                      <ColourPicker
                        color={series.comparisonLineColour}
                        handleChangeComplete={(colour) =>
                          this.changeColour(colour, "comparisonLineColour")
                        }
                        id={"cLine" + id}
                      />
                    </div>
                  </Col>
                  <Col xs={3} style={{ paddingLeft: 5 }}>
                    <div style={{ display: "flex" }}>
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        Fill Colour
                      </span>
                      <ColourPicker
                        color={series.comparisonFillColour}
                        handleChangeComplete={(colour) =>
                          this.changeColour(colour, "comparisonFillColour")
                        }
                        id={"cArea" + id}
                      />
                    </div>
                  </Col>
                </Fragment>
              )}
              {(series.comparisonSeriesType.name === "Line" ||
                series.comparisonSeriesType.name === "Area") && (
                <Col xs={3} style={{ paddingLeft: 5 }}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="comparisonLineMarks"
                        checked={!!series.comparisonLineMarks}
                        onChange={() =>
                          this.changeBoolean("comparisonLineMarks")
                        }
                      />{" "}
                      Line Marks
                    </Label>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className={"g-0"}>
              <hr style={{ width: "100%" }} />
            </Row>
            <Row className={"g-0"}>
              <div style={{ width: "100%" }}>
                1: Baseline with Comparison text:
              </div>
              <div style={{ width: "100%" }}>
                {this.getCreatableTextInput(
                  "baselineComparisonText",
                  "BASELINE_WITH_COMPARISON",
                )}
              </div>
            </Row>
            <Row className={"g-0"}>
              <div style={{ width: "100%" }}>
                2: Comparison Description (Patient/Surgeon etc):
              </div>
              <div style={{ width: "100%" }}>
                {this.getCreatableTextInput("comparisonText", "COMPARISON")}
              </div>
            </Row>
            <Row className={"g-0"}>
              <div style={{ width: "100%" }}>
                3: Baseline without Comparison text:
              </div>
              <div style={{ width: "100%" }}>
                {this.getCreatableTextInput(
                  "baselineNoComparisonText",
                  "BASELINE_NO_COMPARISON",
                )}
              </div>
            </Row>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  (state) => ({
    studies: state.studies,
    ...state.instrumentReports,
  }),
  {
    seriesIndexChange: seriesIndexChange,
    updateSeries: updateSeries,
    loadAllText: loadAllText,
    deleteSeries: deleteSeries,
  },
)(ConfigureSeries);

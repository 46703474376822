import {
  createBrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Landing from "./Landing";
import UserLogon from "../user/Logon";
import AutoLogin from "../patient/AutoLogin";
import ImpersonatePatient from "../patient/ImpersonatePatient";
import NewPatient from "../patient/NewPatient";
import PatientDobConfirm from "../patient/PatientDobConfirm";
import NotFound from "../patient/NotFound";
import SurgeonLogon from "../surgeon/Logon";
import DeclineThankYou from "../consent/DeclineThankYou";
import { useSelector } from "react-redux";
import React, { Fragment } from "react";
import LoadingSpinner from "../common/LoadingSpinner";
import { ToastContainer } from "react-toastify";
import NewProcedure from "../patient/NewProcedure";
import Dashboard from "../dashboard/Dashboard";
import SentryErrorBoundary from "./SentryErrorBoundary";
import { PageTracking } from "../app/PageTracking";
import Activate from "../user/Activate";
import * as Sentry from "@sentry/react";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  [
    {
      Component: Layout,
      children: [
        {
          path: "*",
          element: (
            <PageTracking>
              <RootComponent />
            </PageTracking>
          ),
        },
      ],
      errorElement: <SentryErrorBoundary />,
    },
  ],
  // react router migration completed with behaviour that was reverted post 6.20.0,
  // so this future flag is needed as the behaviour will be implemented in v7
  // see https://github.com/remix-run/react-router/issues/11052#issuecomment-1836589329
  { future: { v7_relativeSplatPath: true } },
);

function Layout() {
  const app = useSelector((state) => state.app);
  return (
    <Fragment>
      <Outlet />
      {app.isLoading && <LoadingSpinner />}
      <ToastContainer theme={"colored"} />
    </Fragment>
  );
}

function RootComponent() {
  const user = useSelector((state) => state.user);
  const patient = useSelector((state) => state.patient);
  if (user.found || patient.id) {
    return <Dashboard />;
  }
  // Default routes if no patient or user logged in
  return (
    <Routes>
      <Route path={"/"} element={<Landing />} />
      <Route path={"/user/activate/:id/:token"} element={<Activate />} />
      <Route path={"/pl/:id/:token"} element={<AutoLogin />} />
      <Route path={"/patient/logon/:id/:token"} element={<AutoLogin />} />
      <Route
        path={"/patient/:patientId/impersonate/:userId/:token"}
        element={<ImpersonatePatient />}
      />
      <Route
        path={"/user/logon"}
        element={
          <PageTracking>
            <UserLogon />
          </PageTracking>
        }
      />
      <Route
        path={"/patient/logon/*"}
        element={
          <PageTracking>
            <NewPatient />
          </PageTracking>
        }
      />
      <Route
        path={"/patient/logon-sms/*"}
        element={
          <PageTracking>
            <PatientDobConfirm />
          </PageTracking>
        }
      />
      <Route
        path={"/patient/notfound"}
        element={
          <PageTracking>
            <NotFound />
          </PageTracking>
        }
      />
      <Route
        path={"/patient/register/*"}
        element={
          <PageTracking>
            <NewProcedure />
          </PageTracking>
        }
      />
      <Route
        path={"/surgeon/logon"}
        element={
          <PageTracking>
            <SurgeonLogon />
          </PageTracking>
        }
      />
      <Route
        path={"/thankyou"}
        element={
          <PageTracking>
            <DeclineThankYou />
          </PageTracking>
        }
      />
      <Route element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
}

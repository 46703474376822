import { Fragment, useEffect, useState } from "react";
import {
  isAutomatedRemindersRunning,
  runAutomatedReminders,
} from "../../api/Reminder";
import { Button, Card, CardBody, Container } from "reactstrap";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { useDispatch } from "react-redux";
import { areTaskRemindersInProgress, runTaskReminders } from "../../api/Task";
import ButtonBar from "../common/ButtonBar";

const Reminders = () => {
  const [runningPatients, setRunningPatients] = useState(false);
  const [runningTasks, setRunningTasks] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    checkRemindersRunning(true);
    let interval = setInterval(() => checkRemindersRunning(false), 10000);
    return () => clearInterval(interval);
  }, []);

  const checkRemindersRunning = (useLoadingScreen) => {
    if (useLoadingScreen) {
      dispatch(startLoading());
    }
    isAutomatedRemindersRunning()
      .then((response) => {
        setRunningPatients(response.data);
      })
      .finally(() => {
        dispatch(finishLoading());
      });
    areTaskRemindersInProgress().then((response) => {
      setRunningTasks(response.data);
    });
  };

  const triggerReminders = (reminderTypeId) => {
    dispatch(startLoading());
    runAutomatedReminders(reminderTypeId).finally(() =>
      checkRemindersRunning(true),
    );
  };

  const triggerTaskReminders = () => {
    dispatch(startLoading());
    runTaskReminders().finally(() => checkRemindersRunning());
  };

  const getButtons = () => {
    const buttons = [];
    if (runningPatients || runningTasks) {
      buttons.push(
        <Fragment>
          Automated reminders are currently running {"  "}
          <Button onClick={() => checkRemindersRunning(true)}>
            Force Refresh
          </Button>
        </Fragment>,
      );
    } else {
      buttons.push(
        <span style={{ paddingRight: "10px" }}>
          Automated reminders not currently running
        </span>,
        <Button color={"primary"} onClick={() => triggerReminders(1)}>
          Send Emails
        </Button>,
        <Button color={"primary"} onClick={() => triggerReminders(2)}>
          Send SMS Messages
        </Button>,
        <Button color={"primary"} onClick={() => triggerTaskReminders()}>
          Send Task Reminders
        </Button>,
      );
    }
    return buttons;
  };

  return (
    <Container>
      <h2>Reminders</h2>
      <Card>
        <CardBody>
          <ButtonBar buttons={getButtons()} horizontalRule={false} />
        </CardBody>
      </Card>
    </Container>
  );
};

export default Reminders;
import { get, post, put } from "./Api";

const BASE_URL = "/api/hospital";

export function getHospitals() {
  return get(`${BASE_URL}/all`);
}

export function getHospital(hospitalId) {
  return get(`${BASE_URL}/${hospitalId}`);
}

export function getSurgeonsAtHospital(hospitalId) {
  return get(`${BASE_URL}/${hospitalId}/surgeons`);
}

export function getProcedureTypesForHospital(hospitalId) {
  return get(`${BASE_URL}/${hospitalId}/procedure-types`);
}

export function createHospital(request) {
  return post(`${BASE_URL}/create`, request);
}

export function updateHospital(hospitalId, request) {
  return put(`${BASE_URL}/${hospitalId}`, request);
}

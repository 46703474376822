import { post } from "./Api";

const BASE_URL = "/api/event";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function sendViewDashboardEvent() {
  return post(url(`view-dashboard`), null);
}

export function sendPreviousResponseEvent(procedureCollectionId) {
  return post(
    url(`previous-responses?procedureCollectionId=${procedureCollectionId}`),
    null,
  );
}

import { useDispatch, useSelector } from "react-redux";
import { loadStudies } from "../../actions/CommonActions";
import PropTypes from "prop-types";
import Select from "react-select";
import { useEffect } from "react";

const MultiStudySelector = ({
  clearable = true,
  disabled = false,
  searchable = false,
  excludeStudyIds = [],
  values,
  onChange,
}) => {
  const dispatch = useDispatch();
  const studies = useSelector((state) => state.studies);

  useEffect(() => {
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  let filteredStudies = !excludeStudyIds
    ? studies
    : studies.filter((s) => !excludeStudyIds.includes(s.id));

  return (
    <Select
      options={filteredStudies}
      onChange={(selected) => onChange(selected == null ? [] : selected)}
      isClearable={clearable}
      isSearchable={searchable}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      value={values}
      isDisabled={disabled}
      isMulti
      styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
    />
  );
};

MultiStudySelector.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  excludeStudyIds: PropTypes.arrayOf(PropTypes.number),
};

export default MultiStudySelector;

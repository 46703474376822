import { Fragment, useEffect, useReducer } from "react";
import { Button, Row, Table, Tooltip } from "reactstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ExternalResponseUploadManagement from "./ExternalResponseUploadManagement";
import {
  FaCheckCircle,
  FaDownload,
  FaEye,
  FaTimes,
  FaUpload,
} from "react-icons/fa";
import { BsStarHalf } from "react-icons/bs";
import Pagination from "react-js-pagination";
import { isSahmriUser, VIEW } from "../../Utils";
import { formatDate, formatDateTime, hasManagementAccess } from "./Utils";
import FinaliseUpload, {
  STATUS_ACCEPTED,
  STATUS_PARTIALLY_ACCEPTED,
  STATUS_REJECTED,
} from "./FinaliseUpload";
import { toast } from "react-toastify";
import "./UploadTable.css";
import { useOnUpdate } from "../CustomHooks";

const UploadTable = ({
  uploads = [],
  itemsCountPerPage = 10,
  pageRangeDisplayed = 5,
  totalItemsCount = 0,
  activePage = 1,
  changePageCallback,
  tableChangeCallback,
}) => {
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      uploadToFinalise: false,
      toolTipId: null,
      showUploadDialog: false,
      isExternalFollowUp: false,
      uploadId: null,
      selectedUpload: null,
      integrateUpload: null,
      errorText: null,
      operation: null,
    },
  );

  useOnUpdate(() => {
    tableChangeCallback();
  }, [state.showUploadDialog, state.uploadToFinalise]);

  const getUploadDialog = (row) => {
    let opn;
    if (row.status === "Accepted") {
      opn = VIEW;
    } else {
      opn = "Upload";
    }
    setState({
      showUploadDialog: true,
      selectedUpload: row,
      operation: opn,
      isExternalFollowUp: row.followUp,
    });
  };

  const getActionButtons = (row) => {
    return (
      <Fragment>
        <Button
          className={"action-button"}
          outline
          color={"secondary"}
          href={`api/upload/download?id=${row.id}`}
          target="_blank"
          title={"Download Response Upload"}
        >
          <FaDownload />
        </Button>
        {row.rejectedUploadFileId != null && (
          <Button
            className={"action-button"}
            outline
            color={"danger"}
            href={`api/upload/rejected?id=${row.rejectedUploadFileId}`}
            target="_blank"
            title={"Download Rejected Upload Records"}
          >
            <FaDownload />
          </Button>
        )}
        {isSahmriUser(user) && row.status === "New" && (
          <Button
            className={"action-button"}
            outline
            color={"primary"}
            onClick={() => getUploadDialog(row)}
            disabled={row.status === "Accepted"}
            title={"Upload cleansed response from template"}
          >
            <FaUpload />
          </Button>
        )}
        {isSahmriUser(user) &&
          row.externalResponseUploads?.some(
            (upload) => upload.status === "Completed",
          ) &&
          row.status === "New" && (
            <Fragment>
              <Button
                className={"action-button"}
                outline
                color={"primary"}
                title={"Mark Accepted"}
                disabled={
                  row.externalResponseUploads === null ||
                  row.externalResponseUploads.every(
                    (upload) => upload.status === "Failed",
                  )
                }
                onClick={() =>
                  setState({
                    uploadToFinalise: row,
                    operation: STATUS_ACCEPTED,
                  })
                }
              >
                <FaCheckCircle />
              </Button>
              <Button
                className={"action-button"}
                outline
                color={"primary"}
                title={"Mark Partially Accepted"}
                disabled={
                  row.externalResponseUploads === null ||
                  row.externalResponseUploads.every(
                    (upload) => upload.status === "Failed",
                  )
                }
                onClick={() =>
                  setState({
                    uploadToFinalise: row,
                    operation: STATUS_PARTIALLY_ACCEPTED,
                  })
                }
              >
                <BsStarHalf />
              </Button>
            </Fragment>
          )}
        {isSahmriUser(user) &&
          row.externalResponseUploads?.filter(
            (upload) => upload.status === "Completed",
          ).length === 0 &&
          row.status === "New" && (
            <Button
              className={"action-button"}
              outline
              color={"danger"}
              title={"Reject Upload File"}
              disabled={row.externalResponseUploads?.some(
                (upload) => upload.status === "Completed",
              )}
              onClick={() =>
                setState({ uploadToFinalise: row, operation: STATUS_REJECTED })
              }
            >
              <FaTimes />
            </Button>
          )}
        {isSahmriUser(user) && row.status === "Accepted" && (
          <Button
            className={"action-button"}
            outline
            color={"secondary"}
            onClick={() => getUploadDialog(row)}
            disabled={row.status !== "Accepted"}
            title={"View Uploads"}
          >
            <FaEye />
          </Button>
        )}
      </Fragment>
    );
  };

  const getStatusValue = (row, index) => {
    if (
      hasManagementAccess(user) &&
      (row.status === "Rejected" || row.status === "Partially Accepted")
    ) {
      return (
        <span>
          <span className={"has-tooltip"}>
            <a id={"status-tooltip-link-" + index}>{row.status}</a>
          </span>
          <Tooltip
            key={"tooltip-" + index}
            placement="left"
            isOpen={state.toolTipId === index}
            target={"status-tooltip-link-" + index}
            toggle={() =>
              setState({ toolTipId: state.toolTipId === index ? null : index })
            }
          >
            Internal reason: {row.reasonInternal}
            <br />
            External reason: {row.reasonExternal}
          </Tooltip>
        </span>
      );
    } else {
      return row.status;
    }
  };

  return (
    <Fragment>
      <Row>
        <Table
          className={"upload-table"}
          size={"sm"}
          bordered
          striped
          responsive
        >
          <thead>
            <tr>
              <th>Description</th>
              {hasManagementAccess(user) && <th>Uploader</th>}
              <th>
                {hasManagementAccess(user) ? "Uploaded" : "Date Uploaded"}
              </th>
              <th>
                {hasManagementAccess(user) ? "Reviewed" : "Date Reviewed"}
              </th>
              {hasManagementAccess(user) && <th>Reviewed By</th>}
              <th>Status</th>
              <th>{user.uploader ? "Download" : "Action"}</th>
            </tr>
          </thead>
          <tbody>
            {uploads.map((row, index) => {
              return (
                <tr
                  key={`upload-table-row-${row.description}-${row.dateUploaded}`}
                >
                  <td>{row.description}</td>
                  {hasManagementAccess(user) && <td>{row.uploadedBy}</td>}
                  <td>
                    {
                      <span title={formatDateTime(row.dateUploaded)}>
                        {formatDate(row.dateUploaded)}
                      </span>
                    }
                  </td>
                  <td>
                    {
                      <span title={formatDateTime(row.dateReviewed)}>
                        {formatDate(row.dateReviewed)}
                      </span>
                    }
                  </td>
                  {hasManagementAccess(user) && <td>{row.reviewedBy}</td>}
                  <td>{getStatusValue(row, index)}</td>
                  <td>{getActionButtons(row)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Pagination
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={changePageCallback}
          hideDisabled
          innerClass={"pagination pagination-sm"}
          itemClass={"page-item"}
          linkClass={"page-link"}
        />
      </div>
      {/* Modals go here */}
      {state.uploadToFinalise && (
        <FinaliseUpload
          selectedUpload={state.uploadToFinalise}
          status={state.operation}
          onCancel={() => setState({ uploadToFinalise: null })}
          onConfirm={() => {
            toast.success("Upload finalised");
            setState({ uploadToFinalise: null });
          }}
        />
      )}
      {state.showUploadDialog && (
        <ExternalResponseUploadManagement
          open={state.showUploadDialog}
          exitCallback={() => setState({ showUploadDialog: false })}
          operation={state.operation}
          upload={state.selectedUpload}
        />
      )}
    </Fragment>
  );
};

UploadTable.propTypes = {
  uploads: PropTypes.arrayOf(PropTypes.object),
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  totalItemsCount: PropTypes.number,
  activePage: PropTypes.number,
};

export default UploadTable;

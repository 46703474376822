import { Fragment, useEffect, useReducer } from "react";
import "./PatientReminders.css";
import PropTypes from "prop-types";
import TextareaAutosize from "react-autosize-textarea";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Row,
  Table,
} from "reactstrap";
import { addNewNote, getPatientNotes } from "../../../api/Patient";
import { isEmptyOrNull } from "../../../Utils";
import Moment from "moment";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../../actions/CommonActions";
import DOMPurify from "dompurify";

const PatientNotes = ({ patientId }) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      modalPatientId: "",
      loadNotesError: "",
      patient: null,
      notes: [],
      note: null,
      noteText: "",
      dateAdded: null,
      errorText: null,
      currentlySubmitting: false,
    },
  );

  useEffect(() => {
    getPatientNotes(patientId)
      .then((response) => {
        setState({ notes: response.data });
      })
      .catch((error) => {
        if (error.response && isEmptyOrNull(error.response)) {
          setState({ errorText: "Error loading patient notes" });
        } else {
          setState({
            errorText: `Error loading patient notes. ${error.response.data}`,
          });
        }
      });
  }, []);

  const handleAdd = () => {
    const initalNoteText = state.noteText;
    setState({ currentlySubmitting: true, noteText: "" });
    dispatch(startLoading());
    let payload = {
      patientId: patientId,
      noteText: initalNoteText,
    };
    addNewNote(patientId, payload)
      .then(() => {
        toast.success(
          <span>
            <FaCheck /> Note saved successfully
          </span>,
        );
        getPatientNotes(patientId).then((response) => {
          setState({ notes: response.data, currentlySubmitting: false });
        });
      })
      .catch((error) => {
        if (error.response && isEmptyOrNull(error.response)) {
          setState({
            errorText: "Error adding new patient note",
            noteText: initalNoteText,
          });
        } else {
          setState({
            errorText: `Error adding new patient note ${error.response.data}`,
            noteText: initalNoteText,
          });
        }
      })
      .finally(() => {
        dispatch(finishLoading());
        setState({ currentlySubmitting: false });
      });
  };

  const isInValidNote = () => {
    return isEmptyOrNull(state.noteText) || state.noteText.trim().length <= 0;
  };

  const getNotesTable = () => {
    return (
      <Fragment>
        <Table
          bordered
          striped
          responsive
          className={"patient-notes-table"}
          size={"sm"}
        >
          <thead>
            <tr>
              <th>User</th>
              <th>Date</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {state.notes.map((note) => {
              return (
                <tr key={note.noteId}>
                  <td>{note.createdBy}</td>
                  <td title={note.creationDate}>
                    {Moment(note.creationDate).format("DD/MM/YYYY")}
                  </td>
                  <td>
                    {/* Change line breaks to <br/> elements */}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          note.noteText.replace(/\n/g, "<br/>\n"),
                        ),
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  };

  return (
    <div className="patient-notes">
      <div>{getNotesTable()}</div>
      <Fragment>
        <Card>
          <CardBody>
            <CardTitle>Add New Note</CardTitle>
            <Form>
              <FormGroup>
                <Row className={"first-row"}>
                  <Col xs={12}>
                    <TextareaAutosize
                      type={"textarea"}
                      className={"free-text-multi"}
                      value={state.noteText}
                      onChange={(event) =>
                        setState({ noteText: event.target.value })
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      color={"primary"}
                      onClick={handleAdd}
                      disabled={isInValidNote() || state.currentlySubmitting}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
            {!isEmptyOrNull(state.errorText) && (
              <Alert color={"warning"}>{state.errorText}</Alert>
            )}
          </CardBody>
        </Card>
      </Fragment>
    </div>
  );
};

PatientNotes.propTypes = {
  patientId: PropTypes.number,
};

export default PatientNotes;

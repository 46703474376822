import { Fragment, useEffect, useReducer } from "react";
import {
  CREATE,
  EDIT,
  isEmptyOrNull,
  PRIVATE,
  PUBLIC,
  SAVE,
  VIEW,
} from "../../../Utils";
import classnames from "classnames";
import StakeholderGroupLinking from "../stakeholder/Linking";
import { searchUsers } from "../../../api/User";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import "./HospitalModal.css";
import PropTypes from "prop-types";
import SimpleStateSelector from "../../common/SimpleStateSelector";
import {
  createHospital,
  getHospital,
  updateHospital,
} from "../../../api/Hospital";
import { useDispatch, useSelector } from "react-redux";
import { loadStakeholderGroups } from "../../../actions/CommonActions";
import { getAllStakeholderGroups } from "../../../api/StakeholderGroup";
import HospitalStudyLinking from "./HospitalStudyLinking";
import ButtonBar from "../../common/ButtonBar";
import Responsive from "react-responsive";

const HospitalModal = ({ hospitalId, onConfirm, operation, onExit }) => {
  const dispatch = useDispatch();
  const stakeholderGroups = useSelector((state) => state.stakeholderGroups);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      abbreviation: "",
      state: null,
      publicPrivateStatus: "",
      hospitalStudies: [],
      error: "",
      currentlySubmitting: false,
      loadHospitalError: "",
      activeTab: "1",
      stakeholderGroupLinks: [],
      excludeIds: [],
      users: [],
    },
  );

  useEffect(() => {
    if (operation === EDIT || operation === VIEW) {
      if (hospitalId !== null) {
        setState({ loadHospitalError: "" });
        getHospital(hospitalId)
          .then((response) => {
            setState({
              name: response.data.name,
              abbreviation: response.data.abbreviation,
              state: response.data.state,
              publicPrivateStatus: response.data.isPrivate ? PRIVATE : PUBLIC,
              hospitalStudies: response.data.studies,
            });
            // Make sure we know about all the stakeholder groups that are linked
            let haveAllStakeholderGroups = true;
            for (const incomingGroup of response.data.stakeholderGroups) {
              if (
                stakeholderGroups.filter((group) => group.id === incomingGroup)
                  .length === 0
              ) {
                haveAllStakeholderGroups = false;
                break;
              }
            }

            loadHospitalsUsers(hospitalId);

            if (haveAllStakeholderGroups) {
              // I know about all the stakeholder groups that are linked to this user - just set them in the component state
              let stakeholderGroupLinks = [];
              for (const incomingGroup of response.data.stakeholderGroups) {
                stakeholderGroupLinks.push(
                  stakeholderGroups.filter(
                    (group) => group.id === incomingGroup,
                  )[0],
                );
              }
              setState({
                stakeholderGroupLinks: stakeholderGroupLinks.sort((a, b) =>
                  a.name.localeCompare(b.name),
                ),
              });
            } else {
              // We should indicate that the list of stakeholder groups is stale - so let's make sure our redux state is current
              dispatch(loadStakeholderGroups());
              // But we also have to keep ongoing, so we'll do the call directly and set the links in a callback
              getAllStakeholderGroups().then((innerResponse) => {
                let fresh = innerResponse.data;
                let stakeholderGroupLinks = [];
                for (const incomingGroup of response.data.stakeholderGroups) {
                  stakeholderGroupLinks.push(
                    fresh.filter((group) => group.id === incomingGroup)[0],
                  );
                }
                setState({
                  stakeholderGroupLinks: stakeholderGroupLinks.sort((a, b) =>
                    a.name.localeCompare(b.name),
                  ),
                });
              });
            }
          })
          .catch((error) => {
            if (error.response && isEmptyOrNull(error.response.data)) {
              setState({ loadHospitalError: "Error loading hospital" });
            } else {
              setState({
                loadHospitalError: `Error loading hospital. ${error.response}`,
              });
            }
          });
      }
    }
  }, []);

  const loadHospitalsUsers = (hospitalId) => {
    let payload = {
      page: 0,
      size: 1000,
      hospitalId: hospitalId,
    };
    searchUsers(payload).then((response) => {
      setState({
        users: response.data.content,
      });
    });
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState({ [id]: value });
  };

  const handleAbbreviationChange = (event) => {
    const { id, value } = event.target;
    let filtered = value.toUpperCase().replace(/[^A-Z]/gi, "");
    setState({ [id]: filtered });
  };

  const handleSubmit = () => {
    setState({ currentlySubmitting: true });

    const payload = {
      name: state.name,
      abbreviation: state.abbreviation,
      state: state.state,
      isPrivate: state.publicPrivateStatus === PRIVATE,
      studies: state.hospitalStudies,
      stakeholderGroups: state.stakeholderGroupLinks.map(
        (stakeholder) => stakeholder.id,
      ),
    };

    if (operation === CREATE) {
      createHospital(payload)
        .then(() => {
          onConfirm(`Hospital ${state.name} was successfully created`);
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({ error: "Error creating the hospital" });
          } else {
            setState({ error: error.response.data });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    } else if (operation === EDIT) {
      updateHospital(hospitalId, payload)
        .then(() => {
          onConfirm(`Successfully updated hospital ${state.name}`);
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({ error: "Error updating the hospital" });
          } else {
            setState({ error: error.response.data });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    }
  };

  const setHospitalState = (value) => {
    setState({ state: value });
  };

  const setActiveTab = (tab) => {
    if (state.activeTab !== tab) {
      setState({ activeTab: tab });
    }
  };

  const setHospitalPrivate = () => {
    setState({ publicPrivateStatus: PRIVATE });
  };

  const setHospitalPublic = () => {
    setState({ publicPrivateStatus: PUBLIC });
  };

  const handleStudyLinkChange = (links) => {
    setState({
      hospitalStudies: links.sort((a, b) =>
        a.studyName.localeCompare(b.studyName),
      ),
    });
  };

  const handleStakeholderGroupLinkChange = (links) => {
    setState({
      stakeholderGroupLinks: links.sort((a, b) => a.name.localeCompare(b.name)),
    });
  };

  const formValid = () => {
    return (
      !isEmptyOrNull(state.name) &&
      !isEmptyOrNull(state.abbreviation) &&
      state.state !== null &&
      !isEmptyOrNull(state.publicPrivateStatus)
    );
  };

  const getButtons = () => {
    const buttons = [];
    if (operation !== VIEW && isEmptyOrNull(state.loadHospitalError)) {
      buttons.push(
        <Button
          color="primary"
          disabled={!formValid() && !state.currentlySubmitting}
          onClick={handleSubmit}
        >
          {operation === CREATE ? CREATE : SAVE}
        </Button>,
      );
    }
    buttons.push(<Button onClick={onExit}>Cancel</Button>);
    return buttons;
  };

  const renderUsersTable = () => {
    return (
      <Fragment>
        <Responsive maxWidth={991}>
          <Table size={"sm"} bordered striped responsive>
            <tbody>
              {state.users.map((u, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Row className={"g-0"}>
                        <Col xs={3} className={"td-label"}>
                          Name:
                        </Col>
                        <Col xs={9}>
                          {" "}
                          {u.firstName} {u.lastName}
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={3} className={"td-label"}>
                          Email:
                        </Col>
                        <Col xs={9}> {u.email}</Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={3} className={"td-label"}>
                          Role:
                        </Col>
                        <Col xs={9}> {u.role}</Col>
                      </Row>
                      {!!u.surgeonCode && (
                        <Row className={"g-0"}>
                          <Col xs={3} className={"td-label"}>
                            SurgeonCode:
                          </Col>{" "}
                          <Col xs={9}>{u.surgeonCode}</Col>
                        </Row>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={992}>
          <Table size={"sm"} bordered striped responsive>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Surgeon Code</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {state.users.map((u, i) => {
                return (
                  <tr key={i}>
                    <td>{u.firstName}</td>
                    <td>{u.lastName}</td>
                    <td>{u.email}</td>
                    <td>{u.surgeonCode}</td>
                    <td>{u.role}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
      </Fragment>
    );
  };

  return (
    <div>
      <Modal isOpen={true} toggle={onExit} onClosed={onExit} size={"lg"}>
        <ModalHeader toggle={onExit}>
          {operation === CREATE
            ? "Create Hospital "
            : `${operation} ${state.name}`}
        </ModalHeader>
        <ModalBody>
          <div className={"field-row"}>
            <em>Note: Fields marked with an * are required</em>
          </div>
          <Form>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={2} md={2} lg={2}>
                  <Label className={"form-label"} for="name">
                    Name*
                  </Label>
                </Col>
                <Col xs={10} md={10} lg={10}>
                  <Input
                    autoFocus
                    required
                    type="text"
                    id="name"
                    value={state.name}
                    onChange={handleChange}
                    readOnly={operation === VIEW}
                  />
                </Col>
              </Row>
              <Row className={"field-row"}>
                <Col xs={2} md={2} lg={2}>
                  <Label className={"form-label"} for="state">
                    State*
                  </Label>
                </Col>
                <Col xs={3} md={3} lg={3}>
                  <SimpleStateSelector
                    value={state.state}
                    onChange={setHospitalState}
                    clearable={false}
                    usePadding={false}
                    useShortNames={true}
                    autoFocus={false}
                    tabIndexFirst={false}
                    disabled={operation === VIEW}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <Label className={"second-row-label"} for="abbreviation">
                    AOANJRR Abbreviation*
                  </Label>
                </Col>
                <Col xs={3} md={3} lg={3}>
                  <Input
                    required
                    type="text"
                    id="abbreviation"
                    value={state.abbreviation}
                    onChange={handleAbbreviationChange}
                    readOnly={operation === VIEW}
                    maxLength={3}
                  />
                </Col>
              </Row>
              <Row className={"field-row"}>
                <Col xs={2} md={2} lg={2}>
                  <Label className={"form-label"}>Status*</Label>
                </Col>
                <Col xs={10} md={10} lg={10}>
                  <ButtonGroup>
                    {/* Special case - we don't allow changing a hospital from public to private,
                      so disable it if not creating a new hospital */}
                    <Button
                      className={"private-button"}
                      disabled={operation !== CREATE}
                      color={
                        state.publicPrivateStatus === PRIVATE
                          ? "primary"
                          : "secondary"
                      }
                      onClick={setHospitalPrivate}
                      active={state.publicPrivateStatus === PRIVATE}
                    >
                      Private
                    </Button>
                    <Button
                      disabled={operation !== CREATE}
                      color={
                        state.publicPrivateStatus === PUBLIC
                          ? "primary"
                          : "secondary"
                      }
                      onClick={setHospitalPublic}
                      active={state.publicPrivateStatus === PUBLIC}
                    >
                      Public
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <Alert color="danger" isOpen={!isEmptyOrNull(state.error)}>
                    {state.error}
                  </Alert>
                </Col>
              </Row>
            </FormGroup>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: state.activeTab === "1",
                    })}
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    Stakeholder Groups
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: state.activeTab === "2",
                    })}
                    onClick={() => {
                      setActiveTab("2");
                    }}
                  >
                    Studies
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: state.activeTab === "3",
                    })}
                    onClick={() => {
                      setActiveTab("3");
                    }}
                  >
                    Hospital Users
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={state.activeTab}>
                <TabPane tabId="1">
                  <StakeholderGroupLinking
                    readOnly={operation === VIEW}
                    links={state.stakeholderGroupLinks}
                    publicPrivateStatus={state.publicPrivateStatus}
                    includeState={state.state}
                    onLinkChange={handleStakeholderGroupLinkChange}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <HospitalStudyLinking
                    readOnly={operation === VIEW}
                    links={state.hospitalStudies}
                    hospitalName={state.name}
                    onLinkChange={handleStudyLinkChange}
                  />
                </TabPane>
                <TabPane tabId="3">{renderUsersTable()}</TabPane>
              </TabContent>
            </div>
          </Form>
          <ButtonBar buttons={getButtons()} />
        </ModalBody>
      </Modal>
    </div>
  );
};

HospitalModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  operation: PropTypes.string.isRequired,
  hospitalId: PropTypes.number,
};

export default HospitalModal;

import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import SimpleHospitalSelector from "../common/SimpleHospitalSelector";
import SimpleProcedureTypeSelector from "../common/SimpleProcedureTypeSelector";
import SurgeonSelector from "../common/SurgeonSelector";
import SimpleSideSelector from "../common/SimpleSideSelector";
import { isEmptyOrNull, jsonEquals } from "../../Utils";
import PropTypes from "prop-types";
import Select from "react-select";
import {
  IS_AFTER,
  IS_BEFORE,
  IS_EXACTLY,
  isPartialPostcodeValid,
  isSearchDateValid,
} from "./Utils";
import { useReducer } from "react";
import { handleEnterKeyPress } from "../../Utils";

const INITIAL_STATE = {
  firstName: "",
  middleNames: "",
  lastName: "",
  postcode: "",
  dateOfBirth: "",
  hospital: null,
  procedureType: null,
  surgeon: null,
  side: null,
  dateOfBirthComparator: null,
  procedureDateComparator: null,
  procedureDate: "",
  sourceId: "",
  revision: null,
};

const RegistryProcedureSearchForm = ({ searchCallback }) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    INITIAL_STATE,
  );

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState({ [id]: value });

    if (id === "dateOfBirthComparator" && value == null) {
      setState({ dateOfBirth: "" });
    }

    if (id === "procedureDateComparator" && value == null) {
      setState({ procedureDate: "" });
    }
  };

  const canSubmit = () => {
    if (jsonEquals(state, INITIAL_STATE)) {
      return false;
    }
    if (
      state.procedureDateComparator != null &&
      (isEmptyOrNull(state.procedureDate) ||
        !isSearchDateValid(state.procedureDate))
    ) {
      return false;
    }
    if (
      state.dateOfBirthComparator != null &&
      (isEmptyOrNull(state.dateOfBirth) ||
        !isSearchDateValid(state.dateOfBirth))
    ) {
      return false;
    }
    return !(
      !isEmptyOrNull(state.postcode) && !isPartialPostcodeValid(state.postcode)
    );
  };

  const getPayload = () => {
    return {
      firstName: isEmptyOrNull(state.firstName) ? null : state.firstName,
      middleNames: isEmptyOrNull(state.middleNames) ? null : state.middleNames,
      lastName: isEmptyOrNull(state.lastName) ? null : state.lastName,
      dateOfBirth: isEmptyOrNull(state.dateOfBirth) ? null : state.dateOfBirth,
      dateOfBirthComparator:
        state.dateOfBirthComparator == null
          ? null
          : state.dateOfBirthComparator.value,
      postcode: isEmptyOrNull(state.postcode) ? null : state.postcode,
      hospitalId: state.hospital == null ? null : state.hospital.id,
      procedureTypeId:
        state.procedureType == null ? null : state.procedureType.id,
      surgeonCode: state.surgeon == null ? null : state.surgeon.surgeonCode,
      sideId: state.side == null ? null : state.side.id,
      procedureDateComparator:
        state.procedureDateComparator == null
          ? null
          : state.procedureDateComparator.value,
      procedureDate: isEmptyOrNull(state.procedureDate)
        ? null
        : state.procedureDate,
      sourceId: isEmptyOrNull(state.sourceId) ? null : state.sourceId,
      revision: isEmptyOrNull(state.revision) ? null : state.revision === "Yes",
    };
  };

  return (
    <Form>
      <Row className={"align-items-center search-row"}>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"firstName"}>
            First Name
          </Label>
        </Col>
        <Col xs={3}>
          <Input
            type="text"
            id={"firstName"}
            name={"firstName"}
            onChange={handleChange}
            value={state.firstName}
            autoFocus
            onKeyPress={(event) =>
              handleEnterKeyPress(
                event,
                () => searchCallback(getPayload()),
                canSubmit(),
              )
            }
            tabIndex={1}
          />
        </Col>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"hospital"}>
            Hospital
          </Label>
        </Col>
        <Col xs={3}>
          <SimpleHospitalSelector
            usePadding={false}
            searchable={true}
            clearable={true}
            value={state.hospital}
            autoFocus={false}
            showCodes={true}
            tabIndex={6}
            onChange={(hospital) =>
              handleChange({
                target: {
                  id: "hospital",
                  value: hospital,
                },
              })
            }
          />
        </Col>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"procedureType"}>
            Procedure Type
          </Label>
        </Col>
        <Col xs={2}>
          <SimpleProcedureTypeSelector
            searchable={true}
            clearable={true}
            value={state.procedureType}
            autoFocus={false}
            tabIndex={12}
            onChange={(procedureType) =>
              handleChange({
                target: {
                  id: "procedureType",
                  value: procedureType,
                },
              })
            }
          />
        </Col>
      </Row>
      <Row className={"align-items-center search-row"}>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"middleName"}>
            Middle Name(s)
          </Label>
        </Col>
        <Col xs={3}>
          <Input
            type={"text"}
            id={"middleNames"}
            name={"middleNames"}
            onChange={handleChange}
            value={state.middleNames}
            onKeyPress={(event) =>
              handleEnterKeyPress(
                event,
                () => searchCallback(getPayload()),
                canSubmit(),
              )
            }
            tabIndex={2}
          />
        </Col>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"surgeon"}>
            Surgeon
          </Label>
        </Col>
        <Col xs={3}>
          <SurgeonSelector
            searchable={true}
            clearable={true}
            value={state.surgeon}
            tabIndex={7}
            showUnknownValue={false}
            useAllSurgeons={false}
            showSurgeonCode={true}
            onChange={(surgeon) =>
              handleChange({
                target: {
                  id: "surgeon",
                  value: surgeon,
                },
              })
            }
          />
        </Col>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"side"}>
            Side
          </Label>
        </Col>
        <Col xs={2}>
          <SimpleSideSelector
            searchable={true}
            clearable={true}
            value={state.side}
            autoFocus={false}
            tabIndex={13}
            onChange={(side) =>
              handleChange({ target: { id: "side", value: side } })
            }
          />
        </Col>
      </Row>
      <Row className={"align-items-center search-row"}>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"lastName"}>
            Last Name
          </Label>
        </Col>
        <Col xs={3}>
          <Input
            type={"text"}
            id={"lastName"}
            name={"lastName"}
            onChange={handleChange}
            value={state.lastName}
            onKeyPress={(event) =>
              handleEnterKeyPress(
                event,
                () => searchCallback(getPayload()),
                canSubmit(),
              )
            }
            tabIndex={3}
          />
        </Col>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"}>Procedure Date</Label>
        </Col>
        <Col xs={3}>
          {/* Nesting a row here to give us more precise control over the bootstrap columns */}
          <Row>
            <Col xs={5}>
              <Select
                tabIndex={8}
                value={state.procedureDateComparator}
                options={[IS_EXACTLY, IS_BEFORE, IS_AFTER]}
                isClearable={true}
                isSearchable={true}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(option) =>
                  handleChange({
                    target: {
                      id: "procedureDateComparator",
                      value: option,
                    },
                  })
                }
                styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
              />
            </Col>
            <Col xs={7}>
              <Input
                type={"text"}
                id={"procedureDate"}
                name={"procedureDate"}
                onChange={handleChange}
                value={state.procedureDate}
                placeholder={"dd/mm/yyyy"}
                onKeyPress={(event) =>
                  handleEnterKeyPress(
                    event,
                    () => searchCallback(getPayload()),
                    canSubmit(),
                  )
                }
                disabled={state.procedureDateComparator == null}
                tabIndex={9}
                valid={
                  state.procedureDateComparator == null
                    ? null
                    : isSearchDateValid(state.procedureDate)
                }
              />
            </Col>
          </Row>
        </Col>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"sourceId"}>
            Source ID
          </Label>
        </Col>
        <Col xs={2}>
          <Input
            type={"text"}
            id={"sourceId"}
            name={"sourceId"}
            onChange={handleChange}
            value={state.sourceId}
            onKeyPress={(event) =>
              handleEnterKeyPress(
                event,
                () => searchCallback(getPayload()),
                canSubmit(),
              )
            }
            tabIndex={14}
          />
        </Col>
      </Row>
      <Row className={"align-items-center search-row"}>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"dob"}>
            DOB
          </Label>
        </Col>
        <Col xs={3}>
          {/* Nesting a row here to give us more precise control over the bootstrap columns */}
          <Row>
            <Col xs={5}>
              <Select
                tabIndex={4}
                value={state.dateOfBirthComparator}
                options={[IS_EXACTLY, IS_BEFORE, IS_AFTER]}
                isClearable={true}
                isSearchable={true}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(option) =>
                  handleChange({
                    target: {
                      id: "dateOfBirthComparator",
                      value: option,
                    },
                  })
                }
                styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
              />
            </Col>
            <Col xs={7}>
              <Input
                type={"text"}
                id={"dateOfBirth"}
                name={"dateOfBirth"}
                onChange={handleChange}
                value={state.dateOfBirth}
                placeholder={"dd/mm/yyyy"}
                onKeyPress={(event) =>
                  handleEnterKeyPress(
                    event,
                    () => searchCallback(getPayload()),
                    canSubmit(),
                  )
                }
                disabled={state.dateOfBirthComparator == null}
                valid={
                  state.dateOfBirthComparator == null
                    ? null
                    : isSearchDateValid(state.dateOfBirth)
                }
                tabIndex={5}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"postcode"}>
            Postcode
          </Label>
        </Col>
        <Col xs={1}>
          <Input
            type={"text"}
            id={"postcode"}
            name={"postcode"}
            onChange={handleChange}
            value={state.postcode}
            onKeyPress={(event) =>
              handleEnterKeyPress(
                event,
                () => searchCallback(getPayload()),
                canSubmit(),
              )
            }
            tabIndex={10}
            valid={
              isEmptyOrNull(state.postcode)
                ? null
                : isPartialPostcodeValid(state.postcode)
            }
          />
        </Col>
        <Col xs={1} className={"text-end"}>
          <Label className={"search-field-label"} for={"revision"}>
            Revision
          </Label>
        </Col>
        <Col xs={1}>
          <Select
            tabIndex={11}
            value={isEmptyOrNull(state.revision) ? null : state.revision.value}
            options={[{ value: "Yes" }, { value: "No" }]}
            getOptionLabel={(option) => option.value}
            getOptionValue={(option) => option.value}
            isClearable={true}
            isSearchable={false}
            onChange={(option) =>
              handleChange({
                target: {
                  id: "revision",
                  value: isEmptyOrNull(option) ? null : option.value,
                },
              })
            }
            styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
          />
        </Col>
        <Col xs={1} />
        <Col xs={1}>
          <Button
            color={"primary"}
            type={"button"}
            disabled={!canSubmit()}
            onClick={() => searchCallback(getPayload())}
          >
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

RegistryProcedureSearchForm.propTypes = {
  searchCallback: PropTypes.func.isRequired,
};

export default RegistryProcedureSearchForm;

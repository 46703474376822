import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import NavButton from "./NavButton";
import { FaHome, FaQuestion, FaSignOutAlt } from "react-icons/fa";
import { Navigate, Route, Routes } from "react-router-dom";
import PatientHome from "./PatientHome";
import Instrument from "../instrument/Instrument";
import Consent from "../consent/Consent";
import NewProcedure from "../patient/NewProcedure";
import PatientView from "../compare/PatientView";
import { PageTracking } from "../app/PageTracking";

export const Patient = (
  menuOpen,
  toggleMenuCb,
  userLogoutCb,
  helpToggleCallback,
  showLogoutButton,
  showHomeButton,
) => {
  return {
    switchRoute: (
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PageTracking>
              <PatientHome />
            </PageTracking>
          }
          exact
        />
        <Route
          path="/compare"
          element={
            <PageTracking>
              <PatientView />
            </PageTracking>
          }
          exact
        />
        <Route
          path="/consent/*"
          element={
            <PageTracking>
              <Consent />
            </PageTracking>
          }
          exact
        />
        <Route
          path="/instrument/*"
          element={
            <PageTracking>
              <Instrument />
            </PageTracking>
          }
        />
        <Route
          path="/patient/register/*"
          element={
            <PageTracking>
              <NewProcedure />
            </PageTracking>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" replace={true} />} />
      </Routes>
    ),
    menu: null,
    menuClosing: <div />,
    menuCombined: menuCombined(
      menuOpen,
      toggleMenuCb,
      userLogoutCb,
      helpToggleCallback,
      showLogoutButton,
      showHomeButton,
    ),
  };
};

const menuCombined = (
  menuOpen,
  toggleMenu,
  userLogout,
  helpToggleCallback,
  showLogoutButton,
  showHomeButton,
) => (
  <Navbar className={"vertical-menu header"} light expand={false}>
    <NavbarToggler onClick={toggleMenu} children={<span>MENU</span>} />
    <Collapse isOpen={menuOpen} navbar>
      <Nav className="me-auto vert-menu-holder" vertical>
        {showHomeButton && (
          <NavButton
            to={"/dashboard"}
            icon={<FaHome className={"icon"} />}
            text={"Home"}
            onClick={toggleMenu}
          />
        )}
        <NavButton
          to={null}
          icon={<FaQuestion className={"icon help"} />}
          text={"Help"}
          onClick={helpToggleCallback}
        />
        {showLogoutButton && (
          <NavButton
            to={"/administration"}
            icon={<FaSignOutAlt className={"icon"} />}
            text={"Logout"}
            onClick={userLogout}
          />
        )}
      </Nav>
    </Collapse>
  </Navbar>
);

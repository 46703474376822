import { useEffect, useReducer } from "react";
import { getGraphColours, getTotal } from "./Utils";
import PropTypes from "prop-types";
import ReportingCheckbox from "./ReportingCheckbox";
import "./Reporting.css";
import MultiRingChart from "./MultiRingChart";
import { Row } from "reactstrap";

const PieReport = ({
  mapColours = (series, index) => getGraphColours()[index],
  fetchData,
  id,
}) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: { datasets: [] },
      matched: false,
      isLoading: false,
    },
  );

  useEffect(() => {
    setState({ isLoading: true });
    fetchData({ matched: state.matched }).then((response) =>
      processData(response),
    );
  }, [state.matched]);

  const processData = (response) => {
    let result = state.data;
    result.title = response.data.title;
    result.subtitle = response.data.subtitle ? response.data.subtitle : null;

    // Total needs to be calculated
    const total = getTotal(response.data.data, state.matched);

    let newDataset = [];
    if (state.matched) {
      newDataset.push({
        backgroundColor: response.data.primarySeriesOrder.map((s, i) =>
          mapColours(s, i),
        ),
        data: response.data.primarySeriesOrder.map((s) => ({
          name: s,
          subValues: response.data.data[s],
          value: Object.values(response.data.data[s]).reduce(
            (acc, curr) => acc + curr,
          ),
          total: total,
        })),
      });
    } else {
      newDataset.push({
        backgroundColor: response.data.seriesOrder.map((s, i) =>
          mapColours(s, i),
        ),
        data: response.data.seriesOrder.map((s) => ({
          name: s,
          value: response.data.data[s],
          total: total,
        })),
      });
    }
    result.datasets = newDataset;
    setState({ data: result, isLoading: false });
  };

  return (
    <div className={"pie-report"}>
      <h3 className={"report-title"}>{state.data.title}</h3>
      <ReportingCheckbox
        checked={state.matched}
        id={id}
        label={"Matched Procedures"}
        tooltip={
          "Show only procedures that have been positively matched to registry data."
        }
        callback={() => setState({ matched: !state.matched })}
      />
      {state.data.subtitle && (
        <div className={"report-subtitle"}>
          <i>{state.data.subtitle}</i>
        </div>
      )}
      {state.isLoading ? (
        <Row
          className={"justify-content-center align-items-center"}
          style={{ height: "400px" }}
        >
          <i>Loading data...</i>
        </Row>
      ) : (
        <MultiRingChart data={state.data} cutout={"0%"} />
      )}
    </div>
  );
};

PieReport.propTypes = {
  fetchData: PropTypes.func.isRequired,
  mapColours: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export default PieReport;

import { handleActions } from "redux-actions";

const INITIAL_STATE = [];

export default handleActions(
  {
    STUDIES_LOADED: (state, action) => [
      ...action.payload.sort((a, b) => a.name.localeCompare(b.name)),
    ],
  },
  INITIAL_STATE,
);

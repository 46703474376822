import { get, post, put } from "./Api";

const BASE_URL = "/api/patient";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function searchSelfLogon(request) {
  return post(url("logon/search"), request, "Unable to logon at this time.");
}

export function selfRegister(request) {
  return post(
    url("self-register"),
    request,
    "Unable to register at this time.",
  );
}

export function logon(request) {
  return post(url("logon/auto"), request, "Unable to logon at this time.");
}

export function logonSms(request) {
  return post(url("logon/auto/sms"), request, "Unable to logon at this time.");
}

export function enrolPatient(request) {
  return post(url("enrol"), request, "Unable to enrol patient at this time.");
}

export function confirmDetails(request) {
  return post(
    url("confirm-details"),
    request,
    "Unable to confirm details at this time.",
  );
}

export function hasCollectionsAvailable() {
  return get(
    url("collections/available"),
    null,
    "Unable to check for questionnaires waiting to be answered at this time.",
  );
}

export function getCollectionQuestions() {
  return get(
    url("collections/get"),
    null,
    "Unable to load questionnaire at this time.",
  );
}

export function saveCollectionResults(request) {
  return post(
    url("collections/save"),
    request,
    "Unable to save answer at this time.",
  );
}

export function getPendingStudyConsents() {
  return get(
    url("studies/pending"),
    null,
    "Unable to load pending studies at this time.",
  );
}

export function consentToParticipateInStudy(studyId) {
  return post(
    url(`study/${studyId}/consent`),
    null,
    "Unable to save study consent answer at this time.",
  );
}

export function declineParticipationInStudy(studyId) {
  return post(
    url(`study/${studyId}/decline`),
    null,
    "Unable to save study consent answer at this time.",
  );
}

export function declinePatientOnTheirBehalf(patientId, studyId) {
  return post(
    url(`${patientId}/decline/${studyId}`),
    null,
    "Unable to save study consent answer at this time.",
  );
}

export function optBackInPatient() {
  return put(
    url("opt-back-in"),
    null,
    "Unable to opt you back into the system.",
  );
}

export function searchPatients(request) {
  return get(url("search"), request, "Unable to search patients at this time.");
}

export function getConsentedStudyList() {
  return get(
    url("studies/consented"),
    null,
    "Unable to load studies you have consented to at this time.",
  );
}

export function getPreviousAnswers() {
  return get(
    url("previous-responses"),
    null,
    "Unable to load previous questionnaire responses at this time.",
  );
}

export function getPreviousAnswersForPatient(id) {
  return get(
    url(`${id}/previous-responses`),
    null,
    "Unable to load previous questionnaire responses at this time.",
  );
}

export function openPatient(id) {
  return get(
    url(`${id}`),
    null,
    "Unable to view patient details at this time.",
  );
}

export function editPatient(id, request) {
  return put(
    url(`${id}`),
    request,
    "Unable to edit patient details at this time.",
  );
}

export function mergePatient(id, request) {
  return put(
    url(`merge/${id}`),
    request,
    "Unable to merge patient details at this time.",
  );
}

export function collectionsCompleted(request) {
  return post(
    url(`collections/completed`),
    request,
    "Unable to mark your responses as completed at this time.",
  );
}

export function getProcedures(patientId) {
  return get(
    url(`${patientId}/procedures`),
    null,
    "Unable to get Procedure details for the Patient at this time.",
  );
}

export function updateProcedureDetails(patientId, payload) {
  return put(
    url(`${patientId}/procedure`),
    payload,
    "Unable to update the Patient's Procedure Details at this time",
  );
}

export function getRegistryProcedures(patientId) {
  return get(
    url(`${patientId}/registry-procedures`),
    null,
    "Unable to get Registry Procedure details for the Patient at this time.",
  );
}

export function sendProcedureEmailLogonLink(patientId, procedureId, email) {
  return post(
    url(
      `${patientId}/send-logon-link?procedureId=${procedureId}&email=${email}`,
    ),
    null,
    "Unable to send logon link at this time.",
  );
}

export function sendProcedureSmsLogonLink(patientId, procedureId, mobile) {
  return post(
    url(
      `${patientId}/send-logon-link?procedureId=${procedureId}&mobile=${mobile}`,
    ),
    null,
    "Unable to send logon link at this time.",
  );
}

export function deletePatient(id, payload) {
  return post(
    url(`delete-patient`),
    payload,
    "Unable to delete patient with id: " + id,
  );
}

export function addAnotherProcedure(patientId, payload) {
  return put(
    url(`${patientId}/add-another-procedure`),
    payload,
    "Unable to add new procedure for the patient at this time.",
  );
}

export function getAvailableComparisons(patientId, procedureId) {
  let generatedUrl =
    patientId != null
      ? `${patientId}/available-comparisons/${procedureId}`
      : `available-comparisons/${procedureId}`;
  return get(
    url(generatedUrl),
    null,
    "Unable to retrieve available reports at this time.",
  );
}

export function getInstrumentReport(patientId, payload) {
  let generatedUrl = patientId != null ? `${patientId}/compare` : "compare";
  return post(
    url(generatedUrl),
    payload,
    "Unable to retrieve report at this time.",
  );
}

export function getImpersonationToken(patientId) {
  return get(
    url(`${patientId}/impersonate`),
    null,
    "Unable to get impersonate URL.",
  );
}

export function impersonate(payload) {
  return post(
    url(`${payload.patientId}/impersonate/${payload.userId}/${payload.token}`),
    null,
    "Unable to impersonate patient.",
  );
}

export function procedures(payload) {
  return get(
    url(`procedures`),
    payload,
    "Unable to verify if patient already exists.",
  );
}

export function getCurrentAndPendingConsents(patientId) {
  return get(
    url(`${patientId}/consent`),
    null,
    "Unable to fetch current and pending consents.",
  );
}

export function getPatientNotes(id) {
  return get(
    url(`${id}/notes`),
    null,
    "Unable to fetch notes for Patient at this time",
  );
}

export function addNewNote(id, request) {
  return post(
    url(`${id}/notes`),
    request,
    "Unable to add new note for Patient at this time",
  );
}

export function getPatientDeleteReasons(request) {
  return get(
    url("delete-reasons"),
    request,
    "Unable to get hard delete reasons at this time.",
  );
}

export function unlockPatientResponses(procedureCollectionId) {
  return get(
    url(`unlock-previous-responses/${procedureCollectionId}`),
    null,
    "Unable to unlock previous responses at this time.",
  );
}

export function validatePatientEmailDomain(domain) {
  return get(
    url(`validate-email-domain/${domain}`),
    null,
    "Unable to validate patient email domain at this time.",
  );
}

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FileUploader from "../common/FileUploader";
import { isEmptyOrNull } from "../../Utils";
import "./ExternalResponseUploads.css";
import { saveExternalResponseUpload } from "../../api/Upload";
import PropTypes from "prop-types";
import Select from "react-select";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonBar from "../common/ButtonBar";
import SimpleProcedureTypeSelector from "../common/SimpleProcedureTypeSelector";
import { useEffect, useReducer } from "react";
import { useOnUpdate } from "../CustomHooks";
import { renderErrorMessageForFileUpload } from "./Utils";

const ExternalResponseUploads = ({ open, upload, exitCallback }) => {
  const dispatch = useDispatch();
  const studies = useSelector((state) => state.studies);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      description: "",
      file: null,
      errorMessage: null,
      selectedStudy: null,
      allStudies: [],
      allCollectionTypes: [],
      selectedStudyCollection: null,
      showUploadDialog: false,
      noFollowUp: null,
      enrolOnly: false,
      procedureType: null,
      studyErrorText: null,
    },
  );

  useEffect(() => {
    setState({
      showUploadDialog: open,
      noFollowUp:
        upload.followUp === null ? false : JSON.parse(upload.followUp),
    });
  }, [upload]);

  useOnUpdate(() => {
    let shortlistedStudies = studies.filter((study) =>
      study.procedureTypeIds.includes(state.procedureType.id),
    );
    setState({
      allStudies: shortlistedStudies,
    });
  }, [state.procedureType]);

  const handleFollowUpChange = () => {
    setState({
      noFollowUp: !state.noFollowUp,
    });
  };

  const handleEnrolOnlyChange = () => {
    setState({
      enrolOnly: !state.enrolOnly,
    });
  };

  const fileAccepted = (file) => {
    setState({ file: file, errorMessage: null });
  };

  const setProcedureType = (value) => {
    if (value !== null) {
      setState({
        procedureType: value,
        selectedStudy: null,
      });
    }
  };

  const closeCallback = () => {
    exitCallback();
  };

  const selectStudy = (value) => {
    if (value !== null) {
      setState({
        selectedStudy: value,
        allCollectionTypes: value.studyCollections,
      });
    }
  };

  const selectStudyCollection = (value) => {
    if (value !== null) {
      setState({
        selectedStudyCollection: value,
      });
    }
  };

  const uploadFile = () => {
    dispatch(startLoading());

    const formData = new FormData();
    formData.append("name", state.file.name);
    formData.append("description", state.description);
    formData.append("file", state.file);
    formData.append("study", state.selectedStudy.id);
    formData.append("studyCollectionId", state.selectedStudyCollection.id);
    formData.append("procedureTypeId", state.procedureType.id);
    formData.append("isExternalFollowUp", state.noFollowUp);
    formData.append("enrolOnly", state.enrolOnly);
    formData.append("uploadId", upload.id);
    formData.append("uploadStatus", "Processing");

    saveExternalResponseUpload(formData)
      .then((response) => {
        if (isEmptyOrNull(response.data.errorMessage)) {
          toast.success(
            "Upload being processed. Please check your email for results.",
          );
          setState({
            description: "",
            file: null,
            errorMessage: null,
            showUploadDialog: false,
          });
        } else {
          toast.error(
            "An error occurred while uploading the external responses file",
          );
          setState({
            description: "",
            file: null,
            errorMessage: response.data.errorMessage,
          });
        }
      })
      .catch((error) => {
        setState({
          description: "",
          file: null,
          errorMessage: renderErrorMessageForFileUpload(error),
        });
      })
      .finally(() => dispatch(finishLoading()));
  };

  return (
    <Modal isOpen={state.showUploadDialog} size={"lg"}>
      <ModalHeader>Upload Cleansed External Patient Responses</ModalHeader>
      <ModalBody className={"enrolment-confirm"}>
        <Card className={"new-external-responses-upload"}>
          <CardBody>
            <CardTitle>New External Responses Upload</CardTitle>
            <FormGroup row>
              <FileUploader
                acceptedFileTypes={{ "text/csv": [".csv"] }}
                maxFileSizeBytes={1000000}
                onFileAccepted={fileAccepted}
                instructionText={
                  "Drag and drop a new CSV file (up to 10MB) here, or click anywhere within this box to browse for a file."
                }
              />
            </FormGroup>

            {state.file != null && (
              <Form className={"submit-form"}>
                <FormGroup>
                  <Row className={"field-row"}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <i>{state.file.name}</i>
                    </Col>
                  </Row>
                  <Row className={"field-row"}>
                    <Col xs={12} sm={12} md={4} lg={4}>
                      <Label className={"form-label"} for={"description"}>
                        Description*
                      </Label>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8}>
                      <Input
                        value={state.description}
                        valid={!isEmptyOrNull(state.description)}
                        placeholder={"Please enter a description for this file"}
                        onChange={(event) =>
                          setState({ description: event.target.value })
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row className={"field-row"}>
                    <Col xs={12} sm={12} md={4} lg={4}>
                      <Label className={"form-label"} for="procedureTypes">
                        Procedure Type*
                      </Label>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8}>
                      <SimpleProcedureTypeSelector
                        value={state.procedureType}
                        onChange={setProcedureType}
                        clearable={false}
                        searchable={true}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row className={"field-row"}>
                    <Col xs={12} sm={12} md={4} lg={4}>
                      <Label for="studies" className={"form-label"}>
                        Study*
                      </Label>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8}>
                      <Select
                        id="studies"
                        placeholder="Enrol in studies..."
                        value={state.selectedStudy}
                        options={state.allStudies}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable={false}
                        autoFocus={true}
                        isSearchable={true}
                        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                        onChange={(value) => selectStudy(value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row className={"field-row"}>
                    <Col xs={12} sm={12} md={4} lg={4}>
                      <Label for="studyCollectionIds" className={"form-label"}>
                        Study Collection*
                      </Label>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8}>
                      <Select
                        id="studyCollectionIds"
                        placeholder="Type of collection..."
                        options={
                          state.selectedStudy == null
                            ? []
                            : state.selectedStudy.studyCollections
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={state.selectedStudyCollection}
                        isClearable={false}
                        autoFocus={true}
                        isSearchable={true}
                        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                        onChange={(value) => selectStudyCollection(value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <Row>
                  <Col xs={4} />
                  <Col xs={8}>
                    <FormGroup className={"check-row"}>
                      <Label check className={"check-label"} for="followUp">
                        <Input
                          type="checkbox"
                          id="followUp"
                          onChange={() => handleFollowUpChange()}
                          checked={state.noFollowUp}
                          style={{ align: "right" }}
                        />{" "}
                        Trials system to handle further patient follow-ups
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"field-row"}>
                  <Col xs={4} />
                  <Col xs={8}>
                    <FormGroup className={"check-row"}>
                      <Label check className={"check-label"} for="enrolOnly">
                        <Input
                          type="checkbox"
                          id="enrolOnly"
                          onChange={() => handleEnrolOnlyChange()}
                          checked={state.enrolOnly}
                          style={{ align: "right" }}
                        />{" "}
                        Enrol patients only - do not add answers
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"justify-content-end"}>
                  <Col sm={2}>
                    <Button
                      type={"button"}
                      color={"primary"}
                      disabled={
                        isEmptyOrNull(state.description) ||
                        isEmptyOrNull(state.selectedStudyCollection) ||
                        isEmptyOrNull(state.selectedStudy)
                      }
                      onClick={uploadFile}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
            {!isEmptyOrNull(state.errorMessage) && (
              <Alert color={"danger"}>{state.errorMessage}</Alert>
            )}
          </CardBody>
        </Card>
        <ButtonBar
          buttons={[
            <Button
              key={"cancelButton"}
              type={"button"}
              onClick={closeCallback}
            >
              Cancel
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

ExternalResponseUploads.propTypes = {
  open: PropTypes.bool.isRequired,
  exitCallback: PropTypes.func,
  upload: PropTypes.object,
};

export default ExternalResponseUploads;

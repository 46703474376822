import LargeNavButton from "../dashboard/LargeNavButton";
import { FaBinoculars } from "react-icons/fa";
import { getUnmatchedCount } from "../../api/Procedures";
import FuzzyMatchingModal from "./FuzzyMatchingModal";
import { useEffect, useState } from "react";

const UnmatchedProcedures = () => {
  const [unmatchedCount, setUnmatchedCount] = useState(null);
  const [showUnmatchedModal, setShowUnmatchedModal] = useState(false);

  useEffect(() => {
    getUnmatchedCount().then((response) => setUnmatchedCount(response.data));
  }, []);

  return (
    <div className={"unmatched-procedures"}>
      <LargeNavButton
        title={"Manage Unmatched"}
        subtitle={
          unmatchedCount == null
            ? null
            : `${unmatchedCount} unmatched PROMs procedures`
        }
        icon={<FaBinoculars />}
        columnPadding={false}
        to={null}
        onClick={() => setShowUnmatchedModal(!showUnmatchedModal)}
      />
      {showUnmatchedModal && (
        <FuzzyMatchingModal
          onExit={() => setShowUnmatchedModal(!showUnmatchedModal)}
          open={showUnmatchedModal}
        />
      )}
    </div>
  );
};

export default UnmatchedProcedures;

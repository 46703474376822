import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import "./Responses.css";
import Responsive from "react-responsive";
import { sendPreviousResponseEvent } from "../../api/Event";
import DOMPurify from "dompurify";
import ButtonBar from "../common/ButtonBar";

const ResponsesView = ({
  responses = [],
  isOpen = false,
  toggle = () => null,
  patientView = true,
  title = "Previous Responses",
  procedureCollectionId,
}) => {
  useEffect(() => {
    procedureCollectionId && sendPreviousResponseEvent(procedureCollectionId);
  }, [procedureCollectionId]);

  const getScore = (response) => {
    // Special case: Non-scored answers or Yes/No answers - don't show the score
    if (
      response.score == null ||
      (response.answer === "Yes" && response.score === 1) ||
      (response.answer === "No" && response.score === 0)
    ) {
      return "N/A";
    }
    return response.score;
  };

  const getPatientViewTable = () => {
    return (
      <Fragment>
        <Responsive maxWidth={691}>
          <Table striped bordered size={"sm"} responsive>
            <tbody>
              {responses.map((response, i) => {
                return (
                  <tr key={i} className={"plain"}>
                    <td>
                      <Row className={"g-0"}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(response.question),
                          }}
                        />
                      </Row>
                      <Row style={{ fontWeight: "bold" }} className={"g-0"}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(response.answer),
                          }}
                        />
                      </Row>
                      {!patientView && (
                        <Row style={{ fontWeight: "bold" }} className={"g-0"}>
                          Score: {getScore(response)}
                        </Row>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={692}>
          <Table striped bordered size={"sm"} responsive>
            <thead>
              <tr>
                <th>Question</th>
                <th>Response</th>
                {!patientView && <th>Score</th>}
              </tr>
            </thead>
            <tbody>
              {responses.map((response, i) => {
                return (
                  <tr key={i} className={"plain"}>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(response.question),
                        }}
                      />
                    </td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(response.answer),
                        }}
                      />
                    </td>
                    {!patientView && <td>{getScore(response)}</td>}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
      </Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={"lg"}
      className={"previous-responses"}
      keyboard={false}
      autoFocus
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {getPatientViewTable()}
        <ButtonBar
          buttons={[
            <Button
              key={"close-button"}
              color="primary"
              outline
              onClick={toggle}
            >
              Close
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

ResponsesView.propTypes = {
  responses: PropTypes.array.isRequired,
  procedureCollectionId: PropTypes.number,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  patientView: PropTypes.bool,
  title: PropTypes.string,
};

export default ResponsesView;

import Select from "react-select";
import PropTypes from "prop-types";
import "../reporting/Reporting.css";
import { useEffect } from "react";

export const ALL_STUDIES = { id: 0, name: "All Studies" };

const MyStudiesSelector = ({
  disabled = false,
  options = [],
  searchable = false,
  useAllStudies = true,
  autoSelectOnMount = true,
  onChange,
  value,
}) => {
  useEffect(() => {
    if (useAllStudies && autoSelectOnMount) {
      onChange(ALL_STUDIES);
    }
  }, []);

  const _buildStudyList = () => {
    let result = options.slice();
    if (useAllStudies) {
      result.unshift(ALL_STUDIES);
    }
    return result;
  };

  const getRenderedValue = (value) => {
    if (value.id <= 0) {
      return <b>{value.name}</b>;
    } else {
      return value.name;
    }
  };

  return (
    <div className={"my-studies-selector"}>
      <Select
        options={_buildStudyList()}
        onChange={onChange}
        isClearable={false}
        isSearchable={searchable}
        getOptionLabel={(option) => getRenderedValue(option)}
        getOptionValue={(option) => option.id}
        value={value}
        isDisabled={disabled}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
      />
    </div>
  );
};

MyStudiesSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  useAllStudies: PropTypes.bool,
  autoSelectOnMount: PropTypes.bool,
};

export default MyStudiesSelector;

import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { TiAnchor } from "react-icons/ti";

const NavButton = (props) => {
  const {
    to = "/",
    tabIndex = -1,
    icon = <TiAnchor />,
    text = "Generic Button",
    onClick = () => null,
  } = props;

  const location = useLocation();

  if (to == null) {
    return (
      <Button
        style={{ backgroundColor: "white" }}
        color={"primary"}
        outline
        tabIndex={tabIndex}
        onClick={onClick}
      >
        <Row className={"g-0"}>
          <Col xs={3} className={"left"}>
            <span className={"icon"}>{icon}</span>
          </Col>
          <Col className={"right"} xs={9}>
            <div className={"text"}>{text}</div>
          </Col>
        </Row>
      </Button>
    );
  } else {
    return (
      <Button
        style={{ backgroundColor: "white" }}
        color={"primary"}
        outline={location.pathname !== to}
        tag={Link}
        to={to}
        tabIndex={tabIndex}
        onClick={onClick}
      >
        <Row className={"g-0"}>
          <Col xs={3} className={"left"}>
            <span className={"icon"}>{icon}</span>
          </Col>
          <Col className={"right"} xs={9}>
            <div className={"text"}>{text}</div>
          </Col>
        </Row>
      </Button>
    );
  }
};

NavButton.propTypes = {
  tabIndex: PropTypes.number,
  icon: PropTypes.element,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavButton;
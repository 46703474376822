import {
  arrayValuesAreNull,
  getStartCase,
  isAdministratorRoleId,
  isAdministratorUser,
  isAoaRoleId,
  isAoaUser,
  isEmptyOrNull,
  isHospitalAdministratorRoleId,
  isHospitalStudyCoordinatorRoleId,
  isSahmriRoleId,
  isSahmriUser,
} from "../../Utils";
import "./Utils.css";
import DataQueryFlag, {
  FLAG_TYPE_ANSWERED,
  FLAG_TYPE_CLOSED,
  FLAG_TYPE_CREATE,
  FLAG_TYPE_IN_PROGRESS,
  FLAG_TYPE_OPEN,
} from "./DataQueryFlag";
import { Badge, Col, Table, UncontrolledTooltip } from "reactstrap";
import React, { Fragment } from "react";
import classNames from "classnames";
import Moment from "moment";
import ActionButton from "../task/ActionButton";
import { FaExclamation } from "react-icons/fa";

export const PATIENT_FIELD_TYPE_ID = 1;
export const PROCEDURE_FIELD_TYPE_ID = 2;
export const DUPLICATE_PATIENT_TYPE_ID = 3;
export const DUPLICATE_PROCEDURE_TYPE_ID = 4;
export const DUPLICATE_CRF_TYPE_ID = 5;
export const CRF_FIELD_TYPE_ID = 6;
export const DUPLICATE_PROCEDURE_COLLECTION_QUERY_TYPE_ID = 7;

export function canCreateQueries(user) {
  return isAoaUser(user) || isSahmriUser(user) || isAdministratorUser(user);
}

export function canChooseAssignee(user) {
  return isAoaUser(user) || isSahmriUser(user) || isAdministratorUser(user);
}

export function canBeAssigned(role) {
  return (
    isAoaRoleId(role.id) ||
    isSahmriRoleId(role.id) ||
    isAdministratorRoleId(role.id) ||
    isHospitalAdministratorRoleId(role.id) ||
    isHospitalStudyCoordinatorRoleId(role.id)
  );
}

export function isValidDuplicatePatientQueryWithTwoPatients(patients) {
  if (isEmptyOrNull(patients)) {
    return false;
  }
  if (patients.length !== 2) {
    return false;
  }
  return !!(
    !isEmptyOrNull(patients[0].firstName) &&
    !isEmptyOrNull(patients[1].firstName) &&
    !isEmptyOrNull(patients[0].lastName) &&
    !isEmptyOrNull(patients[1].lastName) &&
    !isEmptyOrNull(patients[0].postcode) &&
    !isEmptyOrNull(patients[1].postcode) &&
    !isEmptyOrNull(patients[0].dateOfBirth) &&
    !isEmptyOrNull(patients[1].dateOfBirth)
  );
}

// typeId here refers to the type of the dataQuery already present in the db in reference to ViewDataQuery, and it  refers to CRF in reference to CreateDataQuery while creating a new CRF Field Data Query
export function userCanAssignTaskTo(user, role, typeId) {
  if (canChooseAssignee(user) && !!role) {
    // we are excluding HSC role assignment toc CRFs here as their access is more complicated and is handled by more
    // specific functions prior to this being called
    if (
      (isHospitalAdministratorRoleId(role.id) ||
        isHospitalStudyCoordinatorRoleId(role.id)) &&
      !isEmptyOrNull(typeId) &&
      ![DUPLICATE_CRF_TYPE_ID, CRF_FIELD_TYPE_ID, "CRF"].includes(typeId)
    ) {
      return isAoaUser(user);
    } else if (
      isEmptyOrNull(typeId) &&
      (isHospitalAdministratorRoleId(role.id) ||
        isHospitalStudyCoordinatorRoleId(role.id))
    ) {
      return isAoaUser(user);
    } else {
      return (
        isAoaRoleId(role.id) ||
        isSahmriRoleId(role.id) ||
        isAdministratorRoleId(role.id)
      );
    }
  }
  return false;
}

export const OPEN_DATA_QUERY_STATUS_ID = 1;
export const IN_PROGRESS_DATA_QUERY_STATUS_ID = 4;
export const ANSWERED_DATA_QUERY_STATUS_ID = 2;
export const CLOSED_DATA_QUERY_STATUS_ID = 3;

export function isOpenDataQuery(dataQuery) {
  return dataQuery.statusId === OPEN_DATA_QUERY_STATUS_ID;
}

export function isAnsweredDataQuery(dataQuery) {
  return dataQuery.statusId === ANSWERED_DATA_QUERY_STATUS_ID;
}

export function isClosedDataQuery(dataQuery) {
  return dataQuery.statusId === CLOSED_DATA_QUERY_STATUS_ID;
}

export function isInProgressDataQuery(dataQuery) {
  return dataQuery.statusId === IN_PROGRESS_DATA_QUERY_STATUS_ID;
}

// If a procedure/patient/etc. has no identified hospital administrators (e.g. because none are linked to the PROMs
// hospital) then we prevent it from being created or saved, because otherwise the data query will just go into a
// black hole (as it won't appear on anyone's Task dashboard). We don't need to do these kinds of checks for
// assignments to other kinds of user roles (e.g. SAHMRI, AOA, etc.) because we know that there are active users of all
// kinds.
export function assigneeNeedsUsers(role) {
  return (
    isHospitalAdministratorRoleId(role.id) ||
    isHospitalStudyCoordinatorRoleId(role.id)
  );
}

export function getDataQueryFlags(
  dataQueries,
  fieldName,
  fieldValue,
  user,
  edited,
  viewCallback,
  createCallback,
  additionalClassName,
) {
  // The comparisons are deliberately done in this format dataQuery.attribute === '' + fieldName to avoid type coercion errors
  const existingClosed = dataQueries.filter(
    (dataQuery) =>
      isClosedDataQuery(dataQuery) && dataQuery.attribute === "" + fieldName,
  );
  const existingOpen = dataQueries.filter(
    (dataQuery) =>
      isOpenDataQuery(dataQuery) && dataQuery.attribute === "" + fieldName,
  );
  const existingAnswered = dataQueries.filter(
    (dataQuery) =>
      isAnsweredDataQuery(dataQuery) && dataQuery.attribute === "" + fieldName,
  );
  const existingInProgress = dataQueries.filter(
    (dataQuery) =>
      isInProgressDataQuery(dataQuery) &&
      dataQuery.attribute === "" + fieldName,
  );
  const flags = [];

  if (existingOpen.length > 0) {
    existingOpen.forEach((dataQuery) => {
      flags.push(
        <DataQueryFlag
          flagType={FLAG_TYPE_OPEN}
          onClick={() => viewCallback(dataQuery.id)}
        />,
      );
    });
  } else if (existingAnswered.length > 0) {
    existingAnswered.forEach((dataQuery) => {
      flags.push(
        <DataQueryFlag
          flagType={FLAG_TYPE_ANSWERED}
          onClick={() => viewCallback(dataQuery.id)}
        />,
      );
    });
  } else if (existingInProgress.length > 0) {
    existingInProgress.forEach((dataQuery) => {
      flags.push(
        <DataQueryFlag
          flagType={FLAG_TYPE_IN_PROGRESS}
          onClick={() => viewCallback(dataQuery.id)}
        />,
      );
    });
  } else if (canCreateQueries(user) && !edited) {
    // Can only create a new one if there are no Open or Answered queries for this field, and the form hasn't been edited
    flags.push(
      <DataQueryFlag
        flagType={FLAG_TYPE_CREATE}
        onClick={() => createCallback(fieldName, fieldValue)}
      />,
    );
  }

  // Always add these as they're historic.
  existingClosed.forEach((dataQuery) => {
    flags.push(
      <DataQueryFlag
        flagType={FLAG_TYPE_CLOSED}
        onClick={() => viewCallback(dataQuery.id)}
      />,
    );
  });

  if (flags.length === 0) {
    return null;
  } else {
    return (
      <Col
        className={classNames("my-auto", {
          [additionalClassName]: !isEmptyOrNull(additionalClassName),
        })}
      >
        {flags.map((flag, index) => {
          return <Fragment key={"data-query-flag-" + index}>{flag}</Fragment>;
        })}
      </Col>
    );
  }
}

export function getDataQueryPatientsTable(
  patients,
  modalClosedCallback,
  openPatientCallback,
) {
  const showActionButton = !!modalClosedCallback && !!openPatientCallback;
  return (
    <Table striped responsive>
      <caption className={"caption-class"}>Patient Details</caption>
      <thead>
        <tr>
          <th>ID</th>
          <th>First Name</th>
          <th>Middle Name(s)</th>
          <th>Last Name</th>
          <th>DOB</th>
          <th>Postcode</th>
          {showActionButton && <th />}
        </tr>
      </thead>
      <tbody>
        {patients.map((patient, index) => {
          const action = {
            componentType: "PATIENT_MANAGEMENT",
            componentSubType: "DETAILS",
            // As this field (date of birth) is compulsory, its absence is a proxy for the patient not being found
            enabled: patient.dateOfBirth != null,
            label:
              patient.dateOfBirth == null
                ? "Patient Not Found"
                : "Open Patient",
            ids: { id: patient.id },
          };
          return (
            <tr key={"data-query-patient-" + index}>
              <td>{patient.id}</td>
              <td>
                {patient.firstName == null ? (
                  <i>Not Found</i>
                ) : (
                  patient.firstName
                )}
              </td>
              <td>{patient.middleNames}</td>
              <td>
                {patient.lastName == null ? <i>Not Found</i> : patient.lastName}
              </td>
              <td>
                {patient.dateOfBirth == null ? (
                  <i>Not Found</i>
                ) : (
                  Moment(patient.dateOfBirth).format("DD/MM/YYYY")
                )}
              </td>
              <td>
                {patient.postcode == null ? <i>Not Found</i> : patient.postcode}
              </td>
              {showActionButton && (
                <td>
                  <ActionButton
                    action={action}
                    actionSuccessCallback={modalClosedCallback}
                    actionCancelCallback={modalClosedCallback}
                    actionClick={openPatientCallback}
                  />
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export function getDataQueryProceduresTable(
  patient,
  procedures,
  modalClosedCallback,
  openPatientCallback,
  referencedAction,
) {
  const showActionButton =
    !!modalClosedCallback &&
    !!openPatientCallback &&
    patient != null &&
    patient.id != null;
  let compSubType = !isEmptyOrNull(referencedAction)
    ? referencedAction
    : "PROCEDURES";
  let showNotFounds = arrayValuesAreNull(procedures);
  let procId = !arrayValuesAreNull(procedures) ? procedures[0].id : null;
  const action = {
    componentType: "PATIENT_MANAGEMENT",
    componentSubType: compSubType,
    enabled: true,
    label: "Open Patient",
    ids: {
      patientId: patient == null ? null : patient.id,
      procedureId: procId,
    },
  };
  return (
    <Fragment>
      <Table striped responsive>
        <caption className={"caption-class"}>Patient Details</caption>
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Middle Name(s)</th>
            <th>Last Name</th>
            <th>DOB</th>
            <th>Postcode</th>
            {showActionButton && <th />}
          </tr>
        </thead>
        <tbody>
          {patient == null && (
            <tr>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td />
            </tr>
          )}
          {patient != null && (
            <tr>
              <td>{patient.id == null ? <i>Not Found</i> : patient.id}</td>
              <td>
                {patient.firstName == null ? (
                  <i>Not Found</i>
                ) : (
                  patient.firstName
                )}
              </td>
              <td>{patient.middleNames}</td>
              <td>
                {patient.lastName == null ? <i>Not Found</i> : patient.lastName}
              </td>
              <td>
                {patient.dateOfBirth == null ? (
                  <i>Not Found</i>
                ) : (
                  Moment(patient.dateOfBirth).format("DD/MM/YYYY")
                )}
              </td>
              <td>
                {patient.postcode == null ? <i>Not Found</i> : patient.postcode}
              </td>
              {showActionButton && (
                <td>
                  <ActionButton
                    action={action}
                    actionSuccessCallback={modalClosedCallback}
                    actionCancelCallback={modalClosedCallback}
                    actionClick={openPatientCallback}
                  />
                </td>
              )}
            </tr>
          )}
        </tbody>
      </Table>
      <Table striped responsive>
        <caption className={"caption-class"}>Procedure Details</caption>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Created</th>
            <th>Procedure Type</th>
            <th>Side</th>
            <th>Hospital</th>
            <th>Surgeon</th>
            <th>Studies</th>
          </tr>
        </thead>
        <tbody>
          {showNotFounds && (
            <tr>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td>
                <i>Not Found</i>
              </td>
              <td />
            </tr>
          )}
          {!arrayValuesAreNull(procedures) &&
            procedures.map((procedure, index) => {
              return (
                <tr key={"data-query-procedures-" + index}>
                  <td>
                    {procedure.answeredQuestions === 0 && (
                      <>
                        <FaExclamation id={"exclamation-icon-" + index} />
                        <UncontrolledTooltip
                          placement="right"
                          target={"exclamation-icon-" + index}
                        >
                          This procedure does not have any answered questions
                          associated with it
                        </UncontrolledTooltip>
                      </>
                    )}
                  </td>
                  <td>
                    {procedure.id == null ? <i>Not Found</i> : procedure.id}
                  </td>
                  <td>
                    <span
                      title={
                        procedure.dateCreated == null
                          ? null
                          : procedure.dateCreated
                      }
                    >
                      {procedure.dateCreated == null ? (
                        <i>Not Found</i>
                      ) : (
                        Moment(procedure.dateCreated).format("DD/MM/YYYY")
                      )}
                    </span>
                  </td>
                  <td>
                    {procedure.procedureType == null ? (
                      <i>Not Found</i>
                    ) : (
                      getStartCase(procedure.procedureType)
                    )}
                  </td>
                  <td>
                    {procedure.side == null ? (
                      <i>Not Found</i>
                    ) : (
                      getStartCase(procedure.side)
                    )}
                  </td>
                  <td>
                    {procedure.hospital == null ? (
                      <i>Not Found</i>
                    ) : (
                      procedure.hospital
                    )}
                  </td>
                  <td>
                    {procedure.surgeon == null ? (
                      <i>Unknown</i>
                    ) : (
                      procedure.surgeon
                    )}
                  </td>
                  <td>
                    {isEmptyOrNull(procedure.procedureStudies) ? (
                      <i>Unknown</i>
                    ) : (
                      procedure.procedureStudies.map((study, studyIndex) => {
                        return (
                          <Badge
                            key={"study-badge-" + studyIndex}
                            color={"primary"}
                            className={"mr-2"}
                          >
                            {study.studyName}
                          </Badge>
                        );
                      })
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Fragment>
  );
}

export function getDataQueryCrfsTable(
  patient,
  procedure,
  crfs,
  modalClosedCallback,
  openPatientCallback,
) {
  return (
    <Fragment>
      {getDataQueryProceduresTable(
        patient,
        [procedure],
        modalClosedCallback,
        openPatientCallback,
        "CASE REPORT FORMS",
      )}
      <Table striped responsive>
        <caption className={"caption-class"}>CRF Details</caption>
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Created By</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          {crfs.map((crf, index) => {
            return (
              <tr key={"data-query-crfs-" + index}>
                <td>{crf.id == null ? <i>Not Found</i> : crf.id}</td>
                <td>
                  {crf.typeName == null ? <i>Not Found</i> : crf.typeName}
                </td>
                <td>
                  {crf.createdBy == null ? <i>Not Found</i> : crf.createdBy}
                </td>
                <td>
                  <span
                    title={
                      crf.dateCreated == null ? (
                        <i>Not Found</i>
                      ) : (
                        crf.dateCreated
                      )
                    }
                  >
                    {crf.dateCreated == null ? (
                      <i>Not Found</i>
                    ) : (
                      Moment(crf.dateCreated).format("DD/MM/YYYY")
                    )}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
}

export function getDataQueryProcedureCollectionsTable(
  patient,
  procedure,
  procedureCollections,
  modalClosedCallback,
  openPatientCallback,
) {
  return (
    <Fragment>
      {getDataQueryProceduresTable(
        patient,
        [procedure],
        modalClosedCallback,
        openPatientCallback,
        "RESPONSES",
      )}
      <Table striped responsive>
        <caption className={"caption-class"}>
          Procedure Collection Details
        </caption>
        <thead>
          <tr>
            <th>ID</th>
            <th>Study</th>
            <th>Collection</th>
            <th>Started</th>
            <th>Finished</th>
            <th>Answers</th>
          </tr>
        </thead>
        <tbody>
          {procedureCollections.map((pc, index) => {
            return (
              <tr key={"data-query-pcs-" + index}>
                <td>{pc.id == null ? <i>Not Found</i> : pc.id}</td>
                <td>
                  {pc.studyName == null ? <i>Not Found</i> : pc.studyName}
                </td>
                <td>
                  {pc.studyCollectionName == null ? (
                    <i>Not Found</i>
                  ) : (
                    pc.studyCollectionName
                  )}
                </td>
                <td>
                  <span
                    title={
                      pc.collectionStarted == null ? null : pc.collectionStarted
                    }
                  >
                    {pc.collectionStarted == null ? (
                      <i>Not Found</i>
                    ) : (
                      Moment(pc.collectionStarted).format("DD/MM/YYYY")
                    )}
                  </span>
                </td>
                <td>
                  <span
                    title={
                      pc.collectionFinished == null
                        ? null
                        : pc.collectionFinished
                    }
                  >
                    {pc.collectionFinished == null ? (
                      <i>Not Found</i>
                    ) : (
                      Moment(pc.collectionFinished).format("DD/MM/YYYY")
                    )}
                  </span>
                </td>
                <td>
                  {pc.numOfAnswers == null ? <i>Not Found</i> : pc.numOfAnswers}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
}

import { toast } from "react-toastify";
import { MdErrorOutline } from "react-icons/md";
import "./Consent.css";
import {
  consentToParticipateInStudy,
  declineParticipationInStudy,
  getPendingStudyConsents,
} from "../../api/Patient";
import MultiPageConsent from "./MultiPageConsent";
import { Fragment, useEffect, useReducer, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getIndexFromPath } from "../../Utils";
import { declineThankYou } from "../../actions/PatientActions";
import { useDispatch } from "react-redux";
import { FaRegMinusSquare, FaRegPlusSquare, FaSpinner } from "react-icons/fa";
import { Button, Col, Container, Row } from "reactstrap";
import DeclineModal from "./DeclineModal";
import SinglePageConsent from "./SinglePageConsent";

const Consent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const scrollPoint = useRef();

  const [consents, setConsents] = useState([]); // Straight list of all consents required
  const [singlePageConsent, setSinglePageConsent] = useState(null);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      gotoQuestions: false, // Should we redirect to instruments at end of consent process?
      showLongDescription: false,
      saving: false,
      patientPurged: false,
      expandedIndexes: [], // Indexes of consent sections that a patient has expanded to see the long description field
      declinedConsent: null,
    },
  );

  useEffect(() => {
    getPendingStudyConsents()
      .then((response) => {
        if (response?.data?.consents?.length === 0) {
          navigate("/instrument/1");
        } else {
          setConsents(response.data.consents);
          setSinglePageConsent(response.data.singlePageConsent);
        }
      })
      .catch(() => {
        toast.error(
          <span>
            <MdErrorOutline />
            <br />
            {}
            <br />
            Unable to load study consents at this time.
          </span>,
          { onClose: () => navigate("/dashboard", { replace: true }) },
        );
      });
  }, []);

  useEffect(() => {
    if (state.patientPurged) {
      dispatch(declineThankYou());
      navigate("/thankyou", { replace: true });
    }
  }, [state.patientPurged]);

  useEffect(() => {
    if (state.patientPurged && state.declinedConsent) {
      handleRemoveStudy(state.declinedConsent.studyId);
    }
  }, [state.declinedConsent]);

  useEffect(() => {
    if (state.gotoQuestions) {
      if (singlePageConsent) {
        handleRemoveStudy(state.declineStudyId);
      } else {
        navigate("/instrument/1");
      }
    }
    state.gotoQuestions && navigate("/instrument/1");
  }, [state.gotoQuestions]);

  const participate = (studyId) => {
    setState({ saving: true });
    consentToParticipateInStudy(studyId)
      .then(() => setState({ gotoQuestions: true }))
      .catch(() => {
        toast.error(
          <span>
            <MdErrorOutline />
            <br />
            {}
            <br />
            Unable to save consent at this time.
          </span>,
          { onClose: () => navigate("/dashboard", { replace: true }) },
        );
      })
      .finally(() => setState({ saving: false }));
  };

  const decline = (studyId) => {
    setState({ saving: true });
    declineParticipationInStudy(studyId)
      .then((response) =>
        setState({ patientPurged: response.data.patientPurged }),
      )
      .catch(() => {
        toast.error(
          <span>
            <MdErrorOutline />
            <br />
            {}
            <br />
            Unable to save choice at this time.
          </span>,
          { onClose: () => navigate("/dashboard", { replace: true }) },
        );
      })
      .finally(() => setState({ saving: false }));
  };

  const handleRemoveStudy = (studyId) => {
    setConsents(consents.filter((consent) => consent.studyId !== studyId));
  };

  const toggleLongDescription = (index) => {
    if (state.expandedIndexes.includes(index)) {
      setState({
        expandedIndexes: state.expandedIndexes.filter((item) => item !== index),
      });
    } else {
      const updated = state.expandedIndexes.splice();
      updated.push(index);
      setState({ expandedIndexes: updated });
    }
  };

  const renderMiddleButtonRow = (consent, index) => {
    let rightButtonText = !consent.forInformationOnly ? "Next" : "I Understand";
    let arrow =
      state.showLongDescription || state.expandedIndexes.includes(index) ? (
        <FaRegMinusSquare className="arrow" />
      ) : (
        <FaRegPlusSquare className="arrow" />
      );

    let leftButton = <div className="empty" />;
    let rightButton = null;

    if (!consent.lastQuestion) {
      rightButton = (
        <Button
          color="primary"
          outline
          onClick={() => {
            setState({ expandedIndexes: [] });
            navigate(`/consent/${index + 1}`);
          }}
          className={"right"}
        >
          {rightButtonText}
        </Button>
      );
    }

    if (!!consent.collapsible && !!consent.longDescription) {
      leftButton = (
        <Button
          color="primary"
          outline
          onClick={() => toggleLongDescription(index)}
          className={
            state.expandedIndexes.includes(index) ? "left force-hover" : "left"
          }
        >
          <span>Learn More</span>
          {arrow}
        </Button>
      );
    }

    return (
      <Fragment>
        {leftButton}
        {!singlePageConsent && rightButton}
      </Fragment>
    );
  };

  const consentButtons = (consent) => {
    if (!consent.lastQuestion) {
      return null;
    }
    let participateButtonContent = "I Consent to Participate";
    let declineButtonContent = "I No Longer Wish to Participate";
    let disabled = false;
    if (state.saving) {
      participateButtonContent = <FaSpinner className="wait-spin" />;
      declineButtonContent = <FaSpinner className="wait-spin" />;
      disabled = true;
    }
    return (
      <Fragment>
        <Row>
          <Col xs={12} sm={12} md={10} lg={8} xl={8} className={"mx-auto"}>
            <Button
              color="primary"
              outline
              onClick={() => participate(consent.studyId)}
              disabled={disabled}
              className={"participate"}
            >
              {participateButtonContent}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={10} lg={8} xl={8} className={"mx-auto"}>
            <Button
              color="primary"
              outline
              onClick={() => setState({ declinedConsent: consent })}
              disabled={disabled}
              className={"decline"}
            >
              {declineButtonContent}
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };

  if (singlePageConsent == null) {
    return null;
  }

  return (
    <Fragment>
      <DeclineModal
        open={state.declinedConsent !== null}
        studyName={
          state.declinedConsent?.studyDescription.length > 1
            ? null
            : state.declinedConsent?.studyName
        }
        confirmCallback={() => decline(state.declinedConsent.studyId)}
        cancelCallback={() => setState({ declinedConsent: null })}
      />
      <Container className={"consent"}>
        {singlePageConsent ? (
          <SinglePageConsent
            consents={consents}
            scrollPoint={scrollPoint}
            expandedIndexes={state.expandedIndexes}
            renderMiddleButtonRow={renderMiddleButtonRow}
            renderConsentButtons={consentButtons}
          />
        ) : (
          <MultiPageConsent
            index={getIndexFromPath(location, "consent")}
            consents={consents}
            scrollPoint={scrollPoint}
            expandedIndexes={state.expandedIndexes}
            renderMiddleButtonRow={renderMiddleButtonRow}
            renderConsentButtons={consentButtons}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default Consent;

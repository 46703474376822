import { Fragment, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { PATIENT_COMPARISON_TYPE } from "./Utils";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { FaCheck } from "react-icons/fa";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { getStartCase } from "../../Utils";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { useDispatch } from "react-redux";
import { getInstrumentReport } from "../../api/Patient";
import DOMPurify from "dompurify";
import { customSort } from "../../Utils";
import ResultGraph, { CHART_TYPES } from "./ResultGraph";

const PERMANENT_SELECT_OPTIONS = [
  { value: PATIENT_COMPARISON_TYPE.NATIONAL, label: "National responses" },
  { value: PATIENT_COMPARISON_TYPE.AGE, label: "Your age" },
];

const OPTIONAL_SELECT_OPTIONS = [
  { value: PATIENT_COMPARISON_TYPE.SEX, label: "Your gender" },
  { value: PATIENT_COMPARISON_TYPE.AGEANDSEX, label: "Your age and gender" },
];

const PatientComparisonModal = ({
  isOpen = false,
  onClose = () => null,
  procedureId = null,
  reportId = null,
  patientView = true,
  patientId,
}) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      questionIndex: 0,
      selectedFilter: PATIENT_COMPARISON_TYPE.NATIONAL,
      availableFilters: [],
      graphClass: "",
      data: null,
    },
  );

  // Run effect on initial component mount and when selectedFilter is changed
  useEffect(() => {
    fetchData();
  }, [state.selectedFilter]);

  const fetchData = () => {
    let _questionIndex = state.questionIndex;
    dispatch(startLoading());
    getInstrumentReport(!patientView ? patientId : null, {
      procedureId: procedureId,
      reportId: reportId,
      ...state.selectedFilter.payloadNew,
    })
      .then((response) => {
        if (response.data && response.data.length === 0) {
          dispatch(finishLoading());
          onClose();
        } else {
          let filters = [...PERMANENT_SELECT_OPTIONS];
          if (response.data[0].gender) {
            filters = [...filters, ...OPTIONAL_SELECT_OPTIONS];
          }
          // Make sure the new data has the screen we're currently on...
          if (!response.data[state.questionIndex]) {
            _questionIndex = 0;
          }
          setState({
            data: response.data,
            availableFilters: filters,
            questionIndex: _questionIndex,
          });
        }
      })
      .catch(() => {
        onClose();
      })
      .finally(() => dispatch(finishLoading()));
  };

  const navigateQuestion = (increment) => {
    let nextQuestionIndex = state.questionIndex + increment;
    if (nextQuestionIndex > state.data.length - 1 || nextQuestionIndex < 0) {
      return;
    }
    setState({ questionIndex: nextQuestionIndex });
  };

  const isBeforeAfterComparison = (series) => {
    let peopleSeries = series.filter((s) => s.type === CHART_TYPES.PEOPLE.TYPE);
    if (peopleSeries.length === 1 && peopleSeries[0].points.length === 2) {
      return true;
    }
    return false;
  };

  const getFilterOptions = () => {
    return (
      <div className="filters">
        {state.availableFilters.map((f, i) => {
          return (
            <div className="filter-option" key={i}>
              <div
                className="clicker"
                onClick={() => setState({ selectedFilter: f.value })}
              >
                <div className="square">
                  {state.selectedFilter === f.value && (
                    <FaCheck
                      size={24}
                      style={{ bottom: 3, right: 1, position: "relative" }}
                    />
                  )}
                </div>
                <div className="text">{f.label}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getLegend = () => {
    const { legendType, legendText } = state.data[state.questionIndex];
    if (legendType === "BLENDED") {
      return (
        <Fragment>
          <div className="colour-legend">
            Worse
            <div className={`colour-bar national`} />
            Better
          </div>
          <div
            className="explanation-text"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(legendText) }}
          />
        </Fragment>
      );
    } else if (legendType === "OXFORD_DISCRETE") {
      return (
        <div className="colour-legend">
          <div>
            <div className={`square a ${state.selectedFilter.className}`} />
            Severe
          </div>
          <div>
            <div className={`square c ${state.selectedFilter.className}`} />
            Moderate to Severe
          </div>
          <div>
            <div className={`square d ${state.selectedFilter.className}`} />
            Moderate
          </div>
          <div>
            <div className={`square e ${state.selectedFilter.className}`} />
            Satisfactory
          </div>
        </div>
      );
    } else if (legendType === "ARROWS") {
      return (
        <div className="arrow-legend">
          <div className="inner">
            <div className="item">
              <IoIosArrowRoundBack size={24} color="#666" /> Worse
            </div>
            <div className="item">
              Better <IoIosArrowRoundForward size={24} color="#666" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="explanation-text"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(legendText) }}
        />
      );
    }
  };

  if (!state.data || state.data.length === 0) {
    return null;
  }

  let d = state.data[state.questionIndex];
  let comments = [];
  d.series
    .sort((s1, s2) => customSort(s1.index, s2.index))
    .forEach((s) => {
      if (s.description) {
        comments.push(s.description);
      }
    });

  return (
    <Modal
      isOpen={isOpen}
      size={"lg"}
      className={"comparison-graph modal-dialog-centered"}
      keyboard={false}
      autoFocus={true}
      toggle={onClose}
    >
      <ModalHeader toggle={onClose} tag={"div"}>
        <h3>{getStartCase(d.sideProcedureType)}</h3>
        {state.data.length > 1 && (
          <h4>
            <i>
              Screen {state.questionIndex + 1}/{state.data.length}
            </i>
          </h4>
        )}
      </ModalHeader>
      <ModalBody>
        <div className={"graph-container"}>
          <div className="title">
            <h4
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(d.title) }}
            />
          </div>
          <div className="description">
            <h5
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(d.description),
              }}
            />
          </div>
          <div
            className="patient-answer"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(comments.join("<br/>")),
            }}
          />
          <Row style={{ marginTop: 10 }} className={"g-0"}>
            <div style={{ width: "100%" }}>
              <ResultGraph
                series={d.series}
                showYAxis={d.showYAxis}
                maxY={d.maxYValue}
                xAxisLabels={d.labelsXAxis}
                yAxisIsPercent={d.yaxisIsPercent}
                titleYAxis={d.titleYAxis}
                titleXAxis={d.titleXAxis}
                isBeforeAfterComparison={isBeforeAfterComparison(d.series)}
              />
            </div>
          </Row>
        </div>
        {getLegend()}
        {getFilterOptions()}
        <Row style={{ width: "100%" }} className={"g-0"}>
          <div className="center-full-width">
            <Alert
              isOpen={d.series.filter((s) => s.type === "ALERT").length > 0}
              color={"primary"}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    d.series
                      .filter((s) => s.type === "ALERT")
                      .map((s) => s.alert)
                      .join("<br/>"),
                  ),
                }}
              />
            </Alert>
          </div>
        </Row>
        {isBeforeAfterComparison(d.series) && (
          <div style={{ paddingTop: "15px" }}>
            <ul>
              <li>
                The ‘light’ columns represent answers given by others before
                their operation
              </li>
              <li>
                The ‘dark’ columns represent answers given by others after their
                operation
              </li>
              <li>
                The arrows represent{" "}
                <b>
                  <u>YOUR</u>
                </b>{" "}
                answers both before and after your operation
              </li>
            </ul>
          </div>
        )}
        <div className="discuss-results">
          If you would like to discuss your results further please contact your
          surgeon directly.
        </div>
      </ModalBody>
      <ModalFooter>
        {state.data.length > 1 && (
          <Fragment>
            <Button
              color="primary"
              disabled={state.questionIndex <= 0}
              outline={state.questionIndex <= 0}
              onClick={() => navigateQuestion(-1)}
            >
              Previous
            </Button>
            <Button
              color="primary"
              disabled={state.questionIndex >= state.data.length - 1}
              outline={state.questionIndex >= state.data.length - 1}
              onClick={() => navigateQuestion(1)}
            >
              Next
            </Button>
          </Fragment>
        )}

        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PatientComparisonModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  procedureId: PropTypes.number,
  reportId: PropTypes.number,
  patientView: PropTypes.bool,
};

export default PatientComparisonModal;

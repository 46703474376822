import Select from "react-select";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { loadStakeholderGroups } from "../../actions/CommonActions";
import { useEffect, useState } from "react";

const StakeholderGroupSelector = ({
  onChange = (value) => null,
  stakeholderGroups,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);

  useEffect(() => {
    dispatch(loadStakeholderGroups());
  }, [dispatch]);

  const renderStakeholderGroup = (stakeholderGroup) => {
    return stakeholderGroup.name + " (ID: " + stakeholderGroup.id + ")";
  };

  const valueChanged = (value) => {
    setValue(value);
    onChange(value);
  };

  return (
    <div className={"stakeholder-group-selector"}>
      <Select
        value={value}
        options={stakeholderGroups}
        autoFocus={true}
        onChange={(value) => valueChanged(value)}
        isSearchable={true}
        getOptionLabel={(option) => renderStakeholderGroup(option)}
        getOptionValue={(option) => option.id}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
      />
    </div>
  );
};

StakeholderGroupSelector.propTypes = {
  onChange: PropTypes.func,
};

export default StakeholderGroupSelector;

import { Card, CardBody, CardText, Fade } from "reactstrap";
import DOMPurify from "dompurify";
import { convertMarkdown } from "./Utils";

const ConsentCard = ({
  scrollPoint = null,
  showStudyDescriptions,
  consent,
  renderMiddleButtonRow,
  renderConsentButtons,
  expandedDescription,
  backButton = null,
}) => {
  return (
    <Card key={`consent-card-${consent.index}-${consent.heading}`}>
      <CardBody>
        {scrollPoint}
        {showStudyDescriptions && (
          <h4>
            <ul>
              {consent.studyDescription.map((item) => (
                <li
                  key={`consent-li-${item}`}
                  style={{
                    listStyleType: "none",
                    paddingBottom: 20,
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </h4>
        )}
        <h5>{consent.heading}</h5>
        <div className={"dynamic"}>
          <CardText
            className={
              consent.longDescription
                ? "short-description short-description-bold"
                : "short-description"
            }
          >
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  convertMarkdown(consent.shortDescription),
                ),
              }}
            />
          </CardText>
          {renderMiddleButtonRow}
          {expandedDescription && !!consent.longDescription && (
            <Fade>
              <CardText className="long-description">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      convertMarkdown(consent.longDescription),
                    ),
                  }}
                />
              </CardText>
            </Fade>
          )}
        </div>
        <div className="consent-buttons">{renderConsentButtons(consent)}</div>
        {backButton && backButton}
      </CardBody>
    </Card>
  );
};

export default ConsentCard;

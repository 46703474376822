import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const MultiRingChart = ({ data = null, cutout = "50%" }) => {
  // Data will always be present as it is initialised as a prop in parent component,
  // so need to check if data.datasets is not empty
  if (data === null || data.datasets.length === 0) {
    return;
  }
  const options = {
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          // Custom tooltip label
          // If the data has subValues (ie matched data) then we need to append those to the label
          label: (context) => {
            let label = [
              `${context.raw.name}: ${context.raw.value} (${Math.round(
                (context.raw.value / context.raw.total) * 100,
              )}%)`,
            ];
            if (context.raw.subValues) {
              Object.keys(context.raw.subValues).forEach((key) =>
                label.push(
                  `${key}: ${context.raw.subValues[key]} (${Math.round(
                    (context.raw.subValues[key] / context.raw.value) * 100,
                  )}%)`,
                ),
              );
            }
            return label;
          },
          title: (context) => context[0].dataset.label,
        },
      },
    },
    cutout: cutout, // 0% cutout for pie charts
  };

  return <Doughnut data={data} options={options} redraw={false} />;
};

export default MultiRingChart;

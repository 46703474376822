import PropTypes from "prop-types";
import Select from "react-select";
import { getStartCase } from "../../Utils";
import Moment from "moment";

const PatientProcedureSelector = ({
  placeholder = "Select...",
  clearable = false,
  searchable = false,
  disabled = false,
  optionRenderer = null,
  onChange,
  value,
  options,
}) => {
  const renderProcedureOptions = (procedure) => {
    return (
      getStartCase(procedure.procedureName) +
      " (" +
      Moment(procedure.dateCreated).format("DD/MM/YYYY") +
      ")"
    );
  };

  return (
    <div className={"patient-procedure-selector"}>
      <Select
        value={value}
        options={options}
        autoFocus={true}
        onChange={onChange}
        isSearchable={searchable}
        isClearable={clearable}
        placeholder={placeholder}
        getOptionLabel={(option) =>
          optionRenderer !== null
            ? optionRenderer(option)
            : renderProcedureOptions(option)
        }
        getOptionValue={(option) => option.procedureId}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
        isDisabled={disabled}
      />
    </div>
  );
};

PatientProcedureSelector.propTypes = {
  value: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  optionRenderer: PropTypes.func,
};

export default PatientProcedureSelector;

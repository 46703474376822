import axios from "axios";
import { toastError } from "../Utils";
import { USER_LOGOUT } from "../actions/Types";

export default {
  setupInterceptors: (store) => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          error != null &&
          error.response != null &&
          error.response.status === 401
        ) {
          store.dispatch({ type: USER_LOGOUT });
          // Hard-forward to the homepage
          window.location = "/";
        }
        return Promise.reject(error);
      },
    );
  },
};

export function post(url, data, errorText, config) {
  return axios
    .post(url, data, config)
    .catch((error) => handleError(error, errorText));
}

export function put(url, data, errorText, config) {
  return axios
    .put(url, data, config)
    .catch((error) => handleError(error, errorText));
}

export function get(url, data, errorText) {
  return axios
    .get(url, { params: data })
    .catch((error) => handleError(error, errorText));
}

export function del(url, errorText, config) {
  return axios
    .delete(url, config)
    .catch((error) => handleError(error, errorText));
}

export function head(url, errorText) {
  return axios.head(url).catch((error) => handleError(error, errorText));
}

function handleError(error, text) {
  // Ignore 401 errors as these will trigger logout pathways anyway
  if (!!error && !!error.response && error.response.status !== 401) {
    if (text != null && text.length >= 1) {
      if (error.response.status === 429) {
        text =
          "Too many attempts within a short period of time. Please wait one minute and try again.";
      }
      toastError(text);
    }
  }
  return Promise.reject(error);
}

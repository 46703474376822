import { useEffect, useReducer } from "react";
import { getGraphColours, getReportableStudies, getTotal } from "./Utils";
import { useSelector } from "react-redux";
import MyHospitalsSelector, {
  ALL_HOSPITALS,
  MY_HOSPITALS,
} from "./MyHospitalsSelector";
import { Alert, Col, FormGroup, Label, Row } from "reactstrap";
import { isEmptyOrNull, isStakeholderUser, isSurgeonUser } from "../../Utils";
import PropTypes from "prop-types";
import "./Reporting.css";
import ReportingCheckbox from "./ReportingCheckbox";
import MyStudiesSelector from "../common/MyStudiesSelector";
import { usePrevious } from "../CustomHooks";
import MultiRingChart from "./MultiRingChart";

const MultiRingReport = ({
  mapColours = (series, index) => getGraphColours()[index],
  fetchData,
  id,
}) => {
  const user = useSelector((state) => state.user);
  const studies = useSelector((state) => state.studies);
  const hospitals = useSelector((state) => state.hospitals);

  // Must be defined before state is defined so the innerHospital can be
  // set to the correct value.
  const getFirstInnerHospital = () => {
    if (hospitals.length > 1 && isStakeholderUser(user)) {
      return MY_HOSPITALS;
    } else if (hospitals.length === 1) {
      return hospitals[0];
    } else {
      return ALL_HOSPITALS;
    }
  };

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: { datasets: [] },
      outerHospital: ALL_HOSPITALS,
      innerHospital: getFirstInnerHospital(),
      outerData: [],
      innerData: [],
      matched: false,
      study: null,
      hospitalIdFilter: [],
      isLoading: false,
    },
  );

  const prevOuter = usePrevious(state.outerHospital);
  const prevInner = usePrevious(state.innerHospital);
  const prevMatched = usePrevious(state.matched);
  const prevStudy = usePrevious(state.study);

  // Async and await required to allow both hospital datasets to load correctly.
  useEffect(() => {
    const asyncUpdate = async () => {
      if (
        prevInner !== state.innerHospital ||
        prevMatched !== state.matched ||
        prevStudy !== state.study
      ) {
        await updateData(state.innerHospital.id, false);
      }
      if (
        prevOuter !== state.outerHospital ||
        prevMatched !== state.matched ||
        prevStudy !== state.study
      ) {
        await updateData(state.outerHospital.id, true);
      }
    };
    asyncUpdate();
  }, [state.innerHospital, state.outerHospital, state.study, state.matched]);

  const hospitalFilterFromStudy = (studyId) => {
    return hospitals
      .filter(
        (h) =>
          !!h.studies &&
          h.studies.length > 0 &&
          h.studies
            .map((hospitalStudy) => hospitalStudy.studyId)
            .includes(studyId),
      )
      .map((h1) => h1.id);
  };

  const updateData = async (hospitalId, isOuter) => {
    setState({ isLoading: true });
    let payload = {
      matched: state.matched,
      studyId: getStudySelection(),
    };
    if (hospitalId === MY_HOSPITALS.id) {
      payload.hospitalIds = hospitals.map((hospital) => hospital.id).join();
    } else if (hospitalId !== ALL_HOSPITALS.id) {
      payload.hospitalIds = hospitalId;
    }
    await fetchData(payload).then((response) => {
      processData(response, isOuter);
    });
  };

  const getStudySelection = () => {
    if (state.study == null || state.study.id === 0) {
      return null;
    }
    return state.study.id;
  };

  const processData = (response, isOuter) => {
    let result = state.data;
    let ringName;
    result.datasets = [];
    if (isOuter) {
      ringName = state.outerHospital.name;
      result.outerSubtitle = response.data.subtitle;
    } else {
      ringName = state.innerHospital.name;
      result.innerSubtitle = response.data.subtitle;
    }

    result.title = response.data.title;
    result.labels = state.matched
      ? response.data.primarySeriesOrder
      : response.data.seriesOrder;

    // Total needs to be calculated
    const total = getTotal(response.data.data, state.matched);

    let newDataset = [];
    if (state.matched) {
      newDataset.push({
        label: ringName,
        isOuter: isOuter,
        backgroundColor: response.data.primarySeriesOrder.map((s, i) =>
          mapColours(s, i),
        ),
        data: response.data.primarySeriesOrder.map((s) => ({
          name: s,
          subValues: response.data.data[s],
          value: Object.values(response.data.data[s]).reduce(
            (acc, curr) => acc + curr,
          ),
          total: total,
        })),
      });
    } else {
      newDataset.push({
        label: ringName,
        isOuter: isOuter,
        backgroundColor: response.data.seriesOrder.map((s, i) =>
          mapColours(s, i),
        ),
        data: response.data.seriesOrder.map((s) => ({
          name: s,
          value: response.data.data[s],
          total: total,
        })),
      });
    }

    setState({ data: result, isLoading: false });
    if (isOuter) {
      setState({ outerData: newDataset });
    } else {
      setState({ innerData: newDataset });
    }
  };

  const innerHospitalChanged = (hospital) => {
    hospital != null && setState({ innerHospital: hospital });
  };

  const outerHospitalChanged = (hospital) => {
    hospital != null && setState({ outerHospital: hospital });
  };

  const studyChanged = (study) => {
    const hospitalStudies = hospitalFilterFromStudy(study.id);
    setState({
      study: study,
      innerHospital: hospitalStudies.includes(state.innerHospital.id)
        ? state.innerHospital
        : ALL_HOSPITALS,
      outerHospital: hospitalStudies.includes(state.outerHospital.id)
        ? state.outerHospital
        : ALL_HOSPITALS,
      hospitalIdFilter: hospitalStudies,
    });
  };

  /** Need to add the inner and outer data to the main state data
   *  This is due to difficulties managing state when changing study or matched toggle */
  const compileData = () => {
    let data = state.data;
    data.datasets = [...state.outerData, ...state.innerData];
    return data;
  };

  const isSurgeon = isSurgeonUser(user);
  let allowableStudies = studies;
  if (!isEmptyOrNull(user.hospitalStudies)) {
    allowableStudies = studies.filter((s) =>
      user.hospitalStudies.map((hs) => hs.studyId).includes(s.id),
    );
  }
  return (
    <div className={"multi-ring-report"}>
      <h3 className={"report-title"}>{state.data.title}</h3>
      <ReportingCheckbox
        checked={state.matched}
        id={id}
        label={"Matched Procedures"}
        tooltip={
          "Show only procedures that have been positively matched to registry data."
        }
        callback={() => setState({ matched: !state.matched })}
      />
      {getReportableStudies(allowableStudies, user).length > 1 && (
        <Row>
          <Col xs={3} xl={3}>
            <Label>
              <b>Study</b>
            </Label>
          </Col>
          <Col xs={9} xl={9}>
            <MyStudiesSelector
              options={getReportableStudies(allowableStudies, user)}
              value={state.study}
              onChange={(value) => studyChanged(value)}
            />
          </Col>
        </Row>
      )}
      {hospitals.length > 0 && (
        <Row>
          <Col xs={12} sm={12} md={isSurgeon ? 12 : 6}>
            <FormGroup inline>
              <Label>
                <b>Outer Ring</b>
              </Label>
              <MyHospitalsSelector
                value={state.outerHospital}
                onChange={(value) => outerHospitalChanged(value)}
                hospitalIdFilter={state.hospitalIdFilter}
              />
            </FormGroup>
            {state.data.outerSubtitle && (
              <div className={"report-subtitle"}>
                <i>{state.data.outerSubtitle}</i>
              </div>
            )}
          </Col>
          <Col xs={12} sm={12} md={isSurgeon ? 12 : 6}>
            <FormGroup inline>
              <Label>
                <b>Inner Ring</b>
              </Label>
              <MyHospitalsSelector
                value={state.innerHospital}
                onChange={(value) => innerHospitalChanged(value)}
                hospitalIdFilter={state.hospitalIdFilter}
              />
            </FormGroup>
            {state.data.innerSubtitle && (
              <div className={"report-subtitle"}>
                <i>{state.data.innerSubtitle}</i>
              </div>
            )}
          </Col>
        </Row>
      )}
      {state.innerData[0]?.data[0].total === 0 && (
        <Alert color={"primary"}>
          No data was found for {state.innerHospital.name}.
        </Alert>
      )}
      {state.outerData[0]?.data[0].total === 0 && (
        <Alert color={"primary"}>
          No data was found for {state.outerHospital.name}.
        </Alert>
      )}
      {state.isLoading ? (
        <Row
          className={"justify-content-center align-items-center"}
          style={{ height: "400px" }}
        >
          <i>Loading data...</i>
        </Row>
      ) : (
        <MultiRingChart data={compileData()} />
      )}
    </div>
  );
};

MultiRingReport.propTypes = {
  fetchData: PropTypes.func.isRequired,
  mapColours: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export default MultiRingReport;

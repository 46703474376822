import { Button, Col, FormFeedback, Input, Row } from "reactstrap";
import PropTypes from "prop-types";
import { supportsTouchEvents } from "detect-it";
import { stripAllButDigits } from "../../Utils";
import "./SimpleNumericInput.css";
import { MdBackspace } from "react-icons/md";
import { useEffect, useRef, useState } from "react";

export const BACKSPACE = "BACKSPACE";
export const PHONE_NUMBER_INPUT_TYPES = {
  mobile: "mobile",
  landline: "landline",
};

const SimplePhoneNumberInput = ({
  invalid = false,
  phoneNumberInputType,
  updateAction,
  field,
  length,
  value,
}) => {
  const numericInput = useRef(null);

  const [caret, setCaret] = useState(0);
  const [oldLength, setOldLength] = useState(0);

  let placeholder, invalidNotice;
  // Location of the spaces for pretty formatting of mobiles and landlines
  let spaces = { first: 0, second: 0 };

  if (phoneNumberInputType === PHONE_NUMBER_INPUT_TYPES.mobile) {
    placeholder = "04##  ###  ###";
    invalidNotice = "That does not appear to be a valid phone number.";

    spaces.first = 4;
    spaces.second = 8;
  } else if (phoneNumberInputType === PHONE_NUMBER_INPUT_TYPES.landline) {
    placeholder = "##  ####  ####";
    invalidNotice =
      "That does not appear to be a valid landline phone number. Please be sure to include the area code.";

    spaces.first = 2;
    spaces.second = 7;
  }

  useEffect(() => {
    numericInput.current.focus();

    if (value) {
      setCaret(value.length);
    }
  }, []);

  useEffect(() => {
    numericInput.current.focus();

    setTimeout(() => {
      numericInput.current.setSelectionRange(caret, caret);
    }, 0);
  }, [field]);

  const createButton = (key) => {
    return (
      <Col xs={4} key={key}>
        <Button
          color={"primary"}
          className={"no-background-while-focused"}
          outline
          onClick={() => updateValue(key)}
          tabIndex={-1}
        >
          {key}
        </Button>
      </Col>
    );
  };

  const updateValue = (newValue) => {
    let oldValue = value;
    if (oldValue === null || oldValue === undefined) {
      oldValue = "";
    }

    oldValue = stripAllButDigits(oldValue);

    if (newValue === BACKSPACE) {
      newValue = oldValue.slice(0, -1);
    } else if (oldValue.length < length) {
      newValue = oldValue + newValue + "";
    } else {
      return;
    }
    newValue = newValue != null && newValue.length === 0 ? null : newValue;
    updateAction({ [field]: format(newValue) });
  };

  const format = (input) => {
    let output = "";
    if (input != null && input.length) {
      output = `${input.slice(0, spaces.first)}`;
      if (input.length > spaces.first) {
        output += ` ${input.slice(spaces.first, spaces.second - 1)}`;
      }
      if (input.length > spaces.second - 1) {
        output += ` ${input.slice(spaces.second - 1, 10)}`;
      }
    }
    return output;
  };

  const handleInput = (event) => {
    let oldValue = value || "";
    let _caret = event.target.selectionStart;
    let newValue = event.target.value || "";
    let input = stripAllButDigits(newValue) || "";
    if (input.length > 10) {
      setCaret(_caret - 1);
      return;
    }
    let output = "";

    // Delete the first space
    if (oldValue.length > newValue.length && _caret === spaces.first) {
      newValue =
        newValue.slice(0, spaces.first - 1) +
        newValue.slice(spaces.first, newValue.length);
      input = stripAllButDigits(newValue);
      _caret--;
    }

    // Delete the second space
    if (oldValue.length > newValue.length && _caret === spaces.second) {
      newValue =
        newValue.slice(0, spaces.second - 1) +
        newValue.slice(spaces.second, newValue.length);
      input = stripAllButDigits(newValue);
      _caret--;
    }

    if (input != null && input.length) {
      output = `${input.slice(0, spaces.first)}`;
      if (input.length > spaces.first) {
        output += ` ${input.slice(spaces.first, spaces.second - 1)}`;
        if (_caret === spaces.first + 1 && input.length > oldLength) {
          _caret++;
        }
      }
      if (input.length > spaces.second - 1) {
        output += ` ${input.slice(spaces.second - 1, 10)}`;
        if (_caret === spaces.second + 1 && input.length > oldLength) {
          _caret++;
        }
      }
    }
    // Do it this way to quieten sonarqube.
    let inputLength = !!input ? input.length : 0;
    setCaret(_caret);
    setOldLength(inputLength);
    updateAction({ [field]: output });
  };

  return (
    <div className={"simple-input-wrapper"}>
      <Row>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
        <Col>
          <Input
            type={"text"}
            value={value || ""}
            readOnly={supportsTouchEvents}
            disabled={supportsTouchEvents}
            onInput={(e) => handleInput(e)}
            onChange={() => null}
            style={{ backgroundColor: "#fff" }}
            bsSize={"lg"}
            innerRef={(input) => {
              numericInput.current = input;
            }}
            tabIndex={1}
            className={invalid ? "is-invalid" : null}
            placeholder={placeholder}
          />
          <FormFeedback>{invalidNotice}</FormFeedback>
        </Col>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
      </Row>
      {/*Number Pad time*/}
      <Row>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
        <Col className={"keypad numeric"}>
          <Row>
            {[7, 8, 9].map((i) => {
              return createButton(i);
            })}
          </Row>
          <Row>
            {[4, 5, 6].map((i) => {
              return createButton(i);
            })}
          </Row>
          <Row>
            {[1, 2, 3].map((i) => {
              return createButton(i);
            })}
          </Row>
          <Row>
            <Col xs={8}>
              <Button
                color={"primary"}
                className={"no-background-while-focused"}
                outline
                style={{ flexGrow: 2 }}
                tabIndex={-1}
                onClick={() => updateValue(0)}
              >
                0
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                color={"primary"}
                className={"no-background-while-focused"}
                outline
                tabIndex={-1}
                onClick={() => updateValue(BACKSPACE)}
              >
                <div>
                  <span className={"backspace-icon"}>
                    <MdBackspace />
                  </span>
                  <span className={"backspace-short-word"}>DEL</span>
                  <span className={"backspace-long-word"}> DELETE</span>
                </div>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
      </Row>
    </div>
  );
};

SimplePhoneNumberInput.propTypes = {
  updateAction: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  length: PropTypes.number.isRequired,
  invalid: PropTypes.bool,
  invalidNotice: PropTypes.string,
  placeholder: PropTypes.string,
  phoneNumberInputType: PropTypes.string.isRequired,
};

export default SimplePhoneNumberInput;

import { Button, Col, Row } from "reactstrap";
import Moment from "moment";
import PropTypes from "prop-types";
import { isEmptyOrNull } from "../../Utils";
import { useEffect, useState } from "react";

const SimpleCalendarInput = ({
  updateAction = () => null,
  field = null,
  value = "",
  invalidNotice = "That does not look like a date.",
  enterAction = () => null,
  year,
  month,
  invalid,
}) => {
  const constructCalendar = () => {
    // Populate an array of [{Sun} ... {Sat}] arrays with calendar details for display.
    // set bounds of calendar.
    let targetMoment = Moment(month + "-" + year, "MMM-YYYY");
    let currMoment = Moment(targetMoment);
    currMoment.startOf("week");
    let endMoment = Moment(targetMoment);
    endMoment.endOf("month");
    endMoment.endOf("week");

    let _calendar = [];
    let week = [];
    let i = 0;

    while (currMoment.isSameOrBefore(endMoment)) {
      week.push({
        day: currMoment.format("ddd"),
        date: currMoment.date(),
        thisMonth: currMoment.isSame(targetMoment, "month"),
        formatted: currMoment.format("Do"),
      });
      i++;
      if (i >= 7) {
        _calendar.push(week);
        week = [];
        i = 0;
      }
      currMoment.add(1, "d");
    }
    return _calendar;
  };

  let calendar = constructCalendar();

  const daysInMonth = Moment(month + "-" + year, "MMM-YYYY").daysInMonth();
  const [keypress, setKeypress] = useState("");

  useEffect(() => {
    // Clear the current key press every 1000ms
    const timer = setTimeout(() => setKeypress(""), 1000);
    return () => clearTimeout(timer);
  }, [keypress]);

  const keydownHandler = (event) => {
    // Handle enter action
    if (event.key === "Enter") {
      enterAction();
    }
    // If the key pressed is a number, update the value if it's a valid date
    if (!isNaN(event.key)) {
      let possibleDate = keypress + event.key;
      if (possibleDate <= daysInMonth && possibleDate > 0) {
        updateValue(possibleDate);
        setKeypress(event.key);
      } else if (event.key !== "0") {
        // Update the value to the current key if the date is invalid and key is not '0'
        updateValue(event.key);
        setKeypress(event.key);
      }
    }
  };

  useEffect(() => {
    // Used to allow browser to listen to key presses when component is active
    document.addEventListener("keydown", keydownHandler, false);
    // Reverts to regular behaviour when component unmounts
    return () => document.removeEventListener("keydown", keydownHandler, false);
  }, [keydownHandler]);

  const updateValue = (value) => {
    let displayDate = Moment(
      year + "-" + month + "-" + value,
      "YYYY-MMMM-D",
    ).format("Do");
    updateAction({ [field]: displayDate });
  };

  const generateCalendarTile = (detail, j, value) => {
    const { day, date, thisMonth, formatted } = detail;
    return (
      <Button
        className={"btn-date"}
        color={"primary"}
        onClick={() => updateValue(date)}
        key={j}
        disabled={!thisMonth}
        outline={!(!!value && value === formatted && thisMonth)}
        tabIndex={-1}
      >
        <div className={"content"}>
          <div className={"inner"}>
            <div className={"day"}>{day}</div>
            <div className={"date"}>{date}</div>
          </div>
        </div>
      </Button>
    );
  };

  return (
    <div className={"simple-input-wrapper ro"}>
      <Row>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
        <Col className={"text-center"}>
          <div className={invalid ? "is-invalid" : null}>
            <h3>
              {isEmptyOrNull(value) ? "Please select a value below" : value}
            </h3>
          </div>
          {invalid && !isEmptyOrNull(invalidNotice) && (
            <div className={"invalid-feedback"} style={{ display: "block" }}>
              {invalidNotice}
            </div>
          )}
        </Col>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
      </Row>
      <Row className={"simple-calendar"}>
        <Col xs={1} sm={1} md={1} lg={2} xl={3} />
        <Col className={"inner"}>
          <Row className={"header"}>
            <div className={"header-day"}>Sun</div>
            <div className={"header-day"}>Mon</div>
            <div className={"header-day"}>Tue</div>
            <div className={"header-day"}>Wed</div>
            <div className={"header-day"}>Thu</div>
            <div className={"header-day"}>Fri</div>
            <div className={"header-day"}>Sat</div>
          </Row>
          {calendar.map((week, i) => {
            return (
              <Row key={i}>
                {week.map((day, j) => {
                  return generateCalendarTile(day, j, value);
                })}
              </Row>
            );
          })}
        </Col>
        <Col xs={1} sm={1} md={1} lg={2} xl={3} />
      </Row>
    </div>
  );
};

SimpleCalendarInput.propTypes = {
  updateAction: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  invalid: PropTypes.bool,
  invalidNotice: PropTypes.string,
  enterAction: PropTypes.func,
};

export default SimpleCalendarInput;

import { createAction } from "redux-actions";
import * as Types from "./Types";

export const loadInstrumentReports = createAction(
  Types.LOAD_ALL_INSTRUMENT_REPORTS,
);
export const instrumentReportsLoaded = createAction(
  Types.INSTRUMENT_REPORTS_LOADED,
);
export const loadInstrumentBins = createAction(Types.LOAD_ALL_INSTRUMENT_BINS);
export const instrumentBinsLoaded = createAction(Types.INSTRUMENT_BINS_LOADED);
export const loadGraphTypes = createAction(Types.LOAD_ALL_GRAPH_TYPES);
export const graphTypesLoaded = createAction(Types.GRAPH_TYPES_LOADED);
export const loadSubscoreTypes = createAction(Types.LOAD_SUBSCORE_TYPES);
export const subscoreTypesLoaded = createAction(Types.SUBSCORE_TYPES_LOADED);
export const loadAllRoles = createAction(Types.LOAD_ALL_ROLES);
export const allRolesLoaded = createAction(Types.ALL_ROLES_LOADED);
export const loadAllText = createAction(Types.LOAD_ALL_TEXT);
export const allTextLoaded = createAction(Types.ALL_TEXT_LOADED);
export const reportIndexChange = createAction(Types.REPORT_INDEX_CHANGE);
export const updateReport = createAction(Types.UPDATE_REPORT);
export const screenIndexChange = createAction(Types.SCREEN_INDEX_CHANGE);
export const seriesIndexChange = createAction(Types.SERIES_INDEX_CHANGE);
export const updateSeries = createAction(Types.UPDATE_SERIES);
export const updateScreen = createAction(Types.UPDATE_SCREEN);
export const newSeries = createAction(Types.NEW_SERIES);
export const newScreen = createAction(Types.NEW_SCREEN);
export const newReport = createAction(Types.NEW_REPORT);
export const deleteSeries = createAction(Types.DELETE_SERIES);
export const deleteScreen = createAction(Types.DELETE_SCREEN);
export const deleteReport = createAction(Types.DELETE_REPORT);
export const loadAllPositions = createAction(Types.LOAD_ALL_POSITIONS);
export const allPositionsLoaded = createAction(Types.ALL_POSITIONS_LOADED);
export const duplicateReport = createAction(Types.DUPLICATE_REPORT);
export const duplicateScreen = createAction(Types.DUPLICATE_SCREEN);
export const switchReportRole = createAction(Types.SWITCH_REPORT_ROLE);
export const addReportRole = createAction(Types.ADD_REPORT_ROLE);
export const removeReportRole = createAction(Types.REMOVE_REPORT_ROLE);

import * as Types from "../actions/Types";
import {
  crfDateTimeValidationOperatorsLoaded,
  crfDateTimeValidationTypesLoaded,
  crfDateValidationOperatorsLoaded,
  crfDateValidationTypesLoaded,
  crfDecimalValidationTypesLoaded,
  crfIntegerValidationTypesLoaded,
  crfQuestionTypesLoaded,
  crfStringValidationOperatorsLoaded,
} from "../actions/CrfTypeManagementActions";
import {
  getCrfDateTimeValidationOperators,
  getCrfDateTimeValidationTypes,
  getCrfDateValidationOperators,
  getCrfDateValidationTypes,
  getCrfDecimalValidationTypes,
  getCrfIntegerValidationTypes,
  getCrfQuestionTypes,
  getCrfStringValidationOperators,
} from "../api/CrfType";

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === Types.LOAD_CRF_DATE_VALIDATION_TYPES) {
      getCrfDateValidationTypes().then((response) => {
        const { data } = response;
        dispatch(crfDateValidationTypesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_CRF_DATE_VALIDATION_OPERATORS) {
      getCrfDateValidationOperators().then((response) => {
        const { data } = response;
        dispatch(crfDateValidationOperatorsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_CRF_DATETIME_VALIDATION_TYPES) {
      getCrfDateTimeValidationTypes().then((response) => {
        const { data } = response;
        dispatch(crfDateTimeValidationTypesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_CRF_DATETIME_VALIDATION_OPERATORS) {
      getCrfDateTimeValidationOperators().then((response) => {
        const { data } = response;
        dispatch(crfDateTimeValidationOperatorsLoaded(data));
      });
    }
    if (action.type === Types.LOAD_CRF_QUESTION_TYPES) {
      getCrfQuestionTypes().then((response) => {
        const { data } = response;
        dispatch(crfQuestionTypesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_CRF_STRING_VALIDATION_OPERATORS) {
      getCrfStringValidationOperators().then((response) => {
        const { data } = response;
        dispatch(crfStringValidationOperatorsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_CRF_INTEGER_VALIDATION_TYPES) {
      getCrfIntegerValidationTypes().then((response) => {
        const { data } = response;
        dispatch(crfIntegerValidationTypesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_CRF_DECIMAL_VALIDATION_TYPES) {
      getCrfDecimalValidationTypes().then((response) => {
        const { data } = response;
        dispatch(crfDecimalValidationTypesLoaded(data));
      });
    }

    return next(action);
  };

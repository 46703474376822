import { del, get, post, put } from "./Api";

const BASE_URL = "/api/instrument";

function url(path) {
  if (path) {
    return `${BASE_URL}/${path}`;
  }
  return `${BASE_URL}`;
}

export function getInstrumentsForStudyId(studyId) {
  return get(
    url(`all?studyId=${studyId}`),
    null,
    "Unable to get Instruments at this time.",
  );
}

export function getAllInstruments() {
  return get(url("all"), null, "Unable to get all Instruments at this time.");
}

export function getMissingValueMethods() {
  return get(
    url("missing-value-methods"),
    null,
    "Unable to get missing value methods at this time.",
  );
}

export function getScoringMethods() {
  return get(
    url("scoring-methods"),
    null,
    "Unable to get instrument scoring methods at this time.",
  );
}

export function getQuestionTypes() {
  return get(
    url("question-types"),
    null,
    "Unable to load instrument question types at this time.",
  );
}

export function createInstrument(payload) {
  return post(url(""), payload, "Unable to create instrument at this time.");
}

export function updateInstrument(payload) {
  return put(url(""), payload, "Unable to save instrument at this time.");
}

export function deleteInstrument(id) {
  return del(
    `${BASE_URL}?id=${id}`,
    "Unable to delete Instrument at this time.",
  );
}

export function importInstrument(payload) {
  return post(
    url("import"),
    payload,
    "Unable to import Instrument at this time.",
  );
}

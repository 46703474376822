import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import "./Landing.css";
import { useLocation } from "react-router-dom";
import AoaLogo from "../../images/aoa_logo_blue.png";
import LogonButton from "../user/LogonButton";
import { getContactText } from "../../Utils";
import SimpleFooter from "../common/SimpleFooter";
import AnnouncementDetails from "./AnnouncementDetails";

const Landing = () => {
  const location = useLocation();
  const { hash } = location;

  return (
    <div className={"landing"}>
      <AnnouncementDetails />
      <div className="landing-header">
        <img src={AoaLogo} alt="AOA Logo" />
      </div>
      <div className="map-background">
        <div className="spacer" />
        <div>
          <Container>
            <Card>
              <CardBody>
                <p>
                  The AOANJRR (the Registry) is an initiative of the Australian
                  Orthopaedic Association (AOA). It was established in 1999 to
                  improve outcomes for patients having joint replacement
                  surgery.
                </p>
                <p>
                  This website was setup by the Registry to collect information
                  directly from patients to enable a better understanding of how
                  they are feeling before and after their joint replacement
                  surgery.
                </p>
                <p>
                  If you are a patient please select the <b>Patients</b> button
                  below to register for the first time to participate or log in
                  again to view your personal dashboard.
                </p>
                <p>
                  If you are a surgeon or administrator please select the
                  appropriate button to login to the Registry system.
                </p>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Logon</CardTitle>
                <CardText>
                  Select one of the buttons below to login to the Registry
                  system
                </CardText>
                <Row>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <LogonButton userType={LogonButton.userTypes.patient} />
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <LogonButton userType={LogonButton.userTypes.surgeon} />
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <LogonButton
                      userType={LogonButton.userTypes.administrator}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card
              id={"contact"}
              inverse={hash === "#contact"}
              color={hash === "#contact" ? "primary" : null}
            >
              <CardBody>
                <CardTitle>Contact</CardTitle>
                <CardText>{getContactText()}</CardText>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
      <SimpleFooter showLinks={true} />
    </div>
  );
};

export default Landing;
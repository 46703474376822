import {
  isAoaUser,
  isEmptyOrNull,
  isHospitalAdministratorUser,
  isHospitalStudyCoordinatorUser,
  isSurgeonUser,
  validateAustralianPostcode,
  validateEmailAddress,
  validateLength,
  validateOptionalMobile,
  validateOptionalPhone,
  validName,
} from "../../Utils";
import { Link, Route } from "react-router-dom";
import { Button } from "reactstrap";
import Moment from "moment";
import QuestionWrapper from "../common/SimpleQuestionWrapper";

export function newProcedureTypeSelected(newProcedure) {
  return (
    newProcedure.leftKneeReplacement ||
    newProcedure.rightKneeReplacement ||
    newProcedure.rightKneeOsteotomy ||
    newProcedure.leftKneeOsteotomy ||
    newProcedure.leftHipReplacement ||
    newProcedure.rightHipReplacement ||
    newProcedure.leftShoulderReplacement ||
    newProcedure.rightShoulderReplacement
  );
}

export function hasProcedureDetails(newProcedure) {
  return (
    newProcedureTypeSelected(newProcedure) &&
    !isEmptyOrNull(newProcedure.hospital) &&
    !isEmptyOrNull(newProcedure.state)
  );
}

export function patientHasContact(patient) {
  const { homePhone, mobilePhone, email } = patient;
  return (
    atLeastOneContact(patient) &&
    (isEmptyOrNull(homePhone) || validateOptionalPhone(homePhone)) &&
    (isEmptyOrNull(mobilePhone) || validateOptionalMobile(mobilePhone)) &&
    (isEmptyOrNull(email) || validateEmailAddress(email))
  );
}

export function atLeastOneContact(patient) {
  return (
    !isEmptyOrNull(patient.homePhone) ||
    !isEmptyOrNull(patient.mobilePhone) ||
    !isEmptyOrNull(patient.email)
  );
}

export function hasDetailsForSelfRegistration(patient) {
  const { newProcedure } = patient;
  return (
    !isEmptyOrNull(newProcedure.state) &&
    !isEmptyOrNull(newProcedure.hospital) &&
    newProcedureTypeSelected(newProcedure) &&
    patientHasContact(patient)
  );
}

export function hasDetailsForRegistrationByUser(patient) {
  const { newProcedure } = patient;
  return (
    isEmptyOrNull(patient.id) &&
    !isEmptyOrNull(newProcedure.hospital) &&
    newProcedureTypeSelected(newProcedure) &&
    patientHasContact(patient) &&
    patient.studies &&
    patient.studies.length > 0 &&
    hasAllDetails(patient)
  );
}

export function hasAllDetails(patient) {
  return (
    nameValid(patient.firstName) &&
    optionalNameValid(patient.middleNames) &&
    nameValid(patient.lastName) &&
    postcodeValid(patient.postcode) &&
    validateDobMandatory(patient.dateOfBirth, patient.studies)
  );
}

export function validateDobYear(year, month, day) {
  if (!year) {
    return false;
  }
  // add an hour to bypass and timing issues
  let plusOneHour = Moment().add(1, "hour");
  let dob = Moment().set("year", year);
  if (!!month) {
    dob.month(month);
  }
  if (!!day) {
    dob.day(day);
  }
  return (
    validateLength(year, 4) &&
    plusOneHour.diff(dob, "years") >= 16 &&
    plusOneHour.diff(dob, "years") <= 118
  );
}

export function validateDobMonth(year, month) {
  if (!year || !month) {
    return false;
  }
  let plusOneHour = Moment().add(1, "hour");
  let dob = Moment().set("year", year);
  dob.month(month);
  return (
    validateLength(year, 4) &&
    plusOneHour.diff(dob, "years") >= 16 &&
    plusOneHour.diff(dob, "years") <= 118
  );
}

// Check a DOB in a mandatory manner. All values must be supplied and date given must be valid.
export function validateDobMandatory(dateOfBirth) {
  if (!dateOfBirth) {
    return false;
  }
  let now = Moment().add(1, "hour");
  let dob = Moment(dateOfBirth, "YYYY-MMMM-Do");
  let diff = now.diff(dob, "years");
  return dob.isValid() && diff >= 16 && diff <= 118;
}

export function validateStudyAgeLimit(dateOfBirth, study) {
  if (!dateOfBirth) {
    return false;
  }
  let now = Moment().add(1, "hour");
  let dob = Moment(dateOfBirth, "YYYY-MMMM-Do");
  let diff = now.diff(dob, "years");
  let studyAge = study.minimumAge;
  return studyAge <= diff;
}

export function generateLinkButton(tabIndex, text, validation, navigate) {
  return (
    <Button
      color={"primary"}
      outline={!!validation}
      tabIndex={tabIndex}
      disabled={!!validation}
      onClick={!!validation ? null : navigate}
    >
      {text}
    </Button>
  );
}

/**
 * Validate a patient's contact details only. okToProceed value dependent only on contact details being acceptable.
 */
export function validatePatientContactDetails(patient) {
  let response = validatePatientEnrolment(patient);
  response.okToProceed =
    response.mobilePhone.ok === true &&
    response.homePhone.ok === true &&
    response.email.ok === true;
  return response;
}

export function nameValid(name) {
  return !isEmptyOrNull(name) && validName(name);
}

export function postcodeValid(postcode) {
  return !isEmptyOrNull(postcode) && validateAustralianPostcode(postcode);
}

export function optionalNameValid(name) {
  return isEmptyOrNull(name) || validName(name);
}

export function validatePatientEnrolment(patient) {
  let hasAContact = atLeastOneContact(patient);
  let noContactMessage = "At least one contact detail required.";
  let mobileMessage = hasAContact
    ? "Mobile must be in the format 04########."
    : noContactMessage;
  let homeMessage = hasAContact
    ? "Home phone must be an Australian phone number with area code."
    : noContactMessage;
  let emailMessage = hasAContact
    ? "Email must be in the format ____@_____.___"
    : noContactMessage;
  return {
    hospital: {
      ok: !isEmptyOrNull(patient.newProcedure.hospital),
      message: "Missing hospital.",
    },
    procedureType: {
      ok: newProcedureTypeSelected(patient.newProcedure),
      message: "Missing procedure selection.",
    },
    studies: {
      ok: patient.studies && patient.studies.length > 0,
      message: "Must select a study.",
    },
    firstName: {
      ok: nameValid(patient.firstName),
      message:
        "First name is required and cannot contain numbers or non-standard symbols.",
    },
    middleNames: {
      ok: optionalNameValid(patient.middleNames),
      message: "Name(s) cannot contain numbers or non-standard symbols.",
    },
    lastName: {
      ok: nameValid(patient.lastName),
      message:
        "Last name is required and cannot contain numbers or non-standard symbols.",
    },
    dateOfBirth: {
      ok: validateDobMandatory(patient.dateOfBirth),
      message: getInvalidDobText(patient.studies),
    },
    postcode: {
      ok: postcodeValid(patient.postcode),
      message: "An Australian postcode is required.",
    },
    mobilePhone: {
      ok:
        atLeastOneContact(patient) &&
        (isEmptyOrNull(patient.mobilePhone) ||
          validateOptionalMobile(patient.mobilePhone)),
      message: mobileMessage,
    },
    homePhone: {
      ok:
        atLeastOneContact(patient) &&
        (isEmptyOrNull(patient.homePhone) ||
          validateOptionalPhone(patient.homePhone)),
      message: homeMessage,
    },
    email: {
      ok:
        atLeastOneContact(patient) &&
        (isEmptyOrNull(patient.email) || validateEmailAddress(patient.email)),
      message: emailMessage,
    },
    okToProceed: hasDetailsForRegistrationByUser(patient),
  };
}

function getInvalidDobText(studies) {
  if (studies == null || studies.length === 0) {
    return "Please use the DD/MM/YYYY format. Patients must be between 16 and 118 years old.";
  } else {
    // The minimum allowable age for the patient is the largest of the study minimum ages
    let minimumAge = studies
      .map((s) => s.minimumAge)
      .reduce((a, b) => Math.max(a, b));
    return (
      "Please use the DD/MM/YYYY format. Patients must be between " +
      minimumAge +
      " and 118 years old."
    );
  }
}

export function getProcedureTypeIdsFromProps(newProcedure) {
  let result = [];
  if (
    newProcedure.leftKneeOsteotomy === true ||
    newProcedure.rightKneeOsteotomy === true
  ) {
    result.push(4);
  }
  if (
    newProcedure.leftKneeReplacement === true ||
    newProcedure.rightKneeReplacement === true
  ) {
    result.push(3);
  }
  if (
    newProcedure.leftHipReplacement === true ||
    newProcedure.rightHipReplacement === true
  ) {
    result.push(2);
  }
  if (
    newProcedure.leftShoulderReplacement === true ||
    newProcedure.rightShoulderReplacement === true
  ) {
    result.push(1);
  }
  return result;
}

export function getPatientMaximumNameLength() {
  return 50;
}

export function canEditPatient(user) {
  return (
    isAoaUser(user) ||
    isSurgeonUser(user) ||
    isHospitalAdministratorUser(user) ||
    isHospitalStudyCoordinatorUser(user)
  );
}

/**
 * Renders a react-router Route with a specified component for navigation in patient screens
 *
 * @param route where the component should be rendered
 * @param inputComponent component to be rendered in the route
 * @param question the question text as a string or HTML element
 * @param leftButton
 * @param rightButton
 * @param className
 * @param warningText optional warning text for use in NewProcedure.jsx
 * @returns {JSX.Element}
 */
export const renderRoute = (
  route,
  inputComponent,
  question,
  leftButton,
  rightButton,
  className = "",
  warningText = null,
) => {
  return (
    <Route
      path={route}
      element={
        <QuestionWrapper
          inputComponent={inputComponent}
          question={question}
          leftButton={leftButton}
          rightButton={rightButton}
          className={className}
          warningText={warningText}
        />
      }
    />
  );
};

export const newProcedureFieldsToExclude = [
  "hospital",
  "surgeon",
  "state",
  "scheduledProcedureDate",
];

export const NEXT = "Next";
export const PREVIOUS = "Previous";

import { Fragment, useState } from "react";
import { Container } from "reactstrap";
import NewUpload from "./NewUpload";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import UploadManagement from "./UploadManagement";
import { canUpload, hasManagementAccess } from "./Utils";
import MyUploads from "./MyUploads";

const Uploads = () => {
  const user = useSelector((state) => state.user);
  const [resultsVersion, setResultsVersion] = useState(0);

  const handleNewUpload = (message) => {
    toast.success(message);
    setResultsVersion(resultsVersion + 1);
  };

  return (
    <Container className={"uploads"}>
      <h2>Response Uploads</h2>
      {canUpload(user) && (
        <Fragment>
          <p>
            You can use this facility to upload other forms of PROMs collection
            that you are capturing outside of this system.
          </p>
          <p>
            The AOANJRR will review your uploads and integrate them into the
            system if they are in the correct format.
          </p>

          <NewUpload onConfirm={handleNewUpload} />
        </Fragment>
      )}
      {user.uploader && <MyUploads resultsVersion={resultsVersion} />}
      {hasManagementAccess(user) && (
        <UploadManagement resultsVersion={resultsVersion} />
      )}
    </Container>
  );
};

export default Uploads;

import * as Types from "../actions/Types";
import { getHospitals } from "../api/Hospital";
import { getStates, getStatesWithHospitals } from "../api/State";
import { showAnnouncements } from "../api/Announcement";
import {
  announcementsLoaded,
  dataQueryStatusesLoaded,
  dataQueryTypesLoaded,
  hospitalsLoadedForState,
  instrumentScoringMethodsLoaded,
  jointsLoaded,
  missingValueMethodsLoaded,
  patientHardDeleteReasonsLoaded,
  procedureTypesLoaded,
  questionTypesLoaded,
  reminderTypesLoaded,
  rolesLoaded,
  sidesLoaded,
  stakeholderGroupsLoaded,
  statesLoaded,
  statesWithHospitalsLoaded,
  studiesLoaded,
  timeUnitsLoaded,
} from "../actions/CommonActions";
import { getStudies } from "../api/Study";
import {
  getAllJoints,
  getAllProcedureTypes,
  getAllSides,
} from "../api/Procedures";
import { getAllStakeholderGroups } from "../api/StakeholderGroup";
import {
  getMissingValueMethods,
  getQuestionTypes,
  getScoringMethods,
} from "../api/Instrument";
import { getTimeUnits } from "../api/Common";
import { getReminderTypes } from "../api/Reminder";
import { getRoles } from "../api/Role";
import { getDataQueryStatuses, getDataQueryTypes } from "../api/DataQuery";
import { getPatientDeleteReasons } from "../api/Patient";

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === Types.LOAD_STATES) {
      getStates().then((response) => {
        const { data } = response;
        dispatch(statesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_STATES_WITH_HOSPITALS) {
      getStatesWithHospitals().then((response) => {
        const { data } = response;
        dispatch(statesWithHospitalsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_JOINTS) {
      getAllJoints().then((response) => {
        const { data } = response;
        dispatch(jointsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_PROCEDURE_TYPES) {
      getAllProcedureTypes().then((response) => {
        const { data } = response;
        dispatch(procedureTypesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_PATIENT_DELETE_REASONS) {
      getPatientDeleteReasons().then((response) => {
        const { data } = response;
        dispatch(patientHardDeleteReasonsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_SIDES) {
      getAllSides().then((response) => {
        const { data } = response;
        dispatch(sidesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_HOSPITALS) {
      getHospitals(action.payload).then((response) => {
        const { data } = response;
        dispatch(hospitalsLoadedForState(data));
      });
    }

    if (action.type === Types.LOAD_STUDIES) {
      getStudies().then((response) => {
        const { data } = response;
        dispatch(studiesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_ANNOUNCEMENTS) {
      showAnnouncements().then((response) => {
        const { data } = response;
        dispatch(announcementsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_STAKEHOLDER_GROUPS) {
      getAllStakeholderGroups(action.payload).then((response) => {
        const { data } = response;
        dispatch(stakeholderGroupsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_MISSING_VALUE_METHODS) {
      getMissingValueMethods().then((response) => {
        const { data } = response;
        dispatch(missingValueMethodsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_INSTRUMENT_SCORING_METHODS) {
      getScoringMethods().then((response) => {
        const { data } = response;
        dispatch(instrumentScoringMethodsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_QUESTION_TYPES) {
      getQuestionTypes().then((response) => {
        const { data } = response;
        dispatch(questionTypesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_TIME_UNITS) {
      getTimeUnits().then((response) => {
        const { data } = response;
        dispatch(timeUnitsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_REMINDER_TYPES) {
      getReminderTypes().then((response) => {
        const { data } = response;
        dispatch(reminderTypesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_ROLES) {
      getRoles().then((response) => {
        const { data } = response;
        dispatch(rolesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_DATA_QUERY_STATUSES) {
      getDataQueryStatuses().then((response) => {
        const { data } = response;
        dispatch(dataQueryStatusesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_DATA_QUERY_TYPES) {
      getDataQueryTypes().then((response) => {
        const { data } = response;
        dispatch(dataQueryTypesLoaded(data));
      });
    }

    return next(action);
  };

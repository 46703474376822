import {isHospitalAdministratorUser, isHospitalStudyCoordinatorUser, isSurgeonUser} from "../../../Utils";

/**
 * Filter a list of hospitals to those that are user-role-linked
 * @param user
 * @param hospitals the user's hospitals
 * @param study the study constraint
 * @param surgeons the user's surgeons
 * @returns {any[]|*}
 */
export function hospitalsByStudy(user, hospitals, study, surgeons) {
  if (hasHospitalStudies(user)) {
    return hospitals.filter(h =>
      user.hospitalStudies.some(uhs =>
        uhs.hospitalId === h.id && uhs.studyId === study.id));
  } else if (haLinkedToSurgeons(user)) {
    // my surgeons hospitals by study
    let hospitalsSet = new Set();
    hospitals.forEach(h => {
      surgeons.forEach(g => {
          if ((user.surgeonIds.includes(g.id)) &&
            (g.hospitalStudies.some(hs =>
              hs.studyId === study.id && hs.hospitalId === h.id))) {
            hospitalsSet.add(h);
          }
        }
      )
    });
    return [...hospitalsSet];
  } else {
    return hospitals.filter(h => getHospitalStudyIds(user, h).includes(study.id));
  }
}

export function hospitalsBySurgeon(user, hospitals, surgeon) {
  if (hasHospitalStudies(user)) {
    return hospitalsFromHSIntersectAndStudyMatches(surgeon.hospitalStudies, user.hospitalStudies, hospitals, null);
  } else if (haLinkedToSurgeons(user)) {
    /* HA linked to surgeons. Could probably use hospitalStudiesIntersectWithConstraint but would have to
       create a union set of all ha.surgeons.hospital-studies.  Below is sufficient.
       Also - in this case 'hospitals' has is already filtered server-side so we only need a match between the selected
       hospital and the surgeon's hospital */
    if (user.surgeonIds.includes(surgeon.id)) {
      return hospitals.filter(h =>
        surgeon.hospitalStudies.map(hs => hs.hospitalId).includes(h.id));
    } else {
      return [];
    }

  } else {
    /* AOA, SAHMRI, etc.. Since they have all hospitals we need to restrict by study as well.  This is less efficient
    * than*/
    return hospitals.filter(hospital =>
      surgeon.hospitalStudies.some(hs => hs.hospitalId === hospital.id
        // && getHospitalStudyIds(user, hospital).includes(hs.studyId)
      ));
  }
}

export function hospitalsByStudyAndSurgeon(user, hospitals, study, surgeon) {
  if (hasHospitalStudies(user)) {
    return hospitalsFromHSIntersectAndStudyMatches(user.hospitalStudies, surgeon.hospitalStudies, hospitals, study);
  } else if (haLinkedToSurgeons(user)) {
    if (user.surgeonIds.includes(surgeon.id)) {
      return hospitals.filter(h => surgeon.hospitalStudies.some(hs =>
        hs.hospitalId === h.id && hs.studyId === study.id
      ));
    } else {
      return [];
    }
  } else {
    return hospitals.filter(h =>
      surgeon.hospitalStudies.some(hs =>
        hs.hospitalId === h.id && hs.studyId === study.id &&
        getHospitalStudyIds(user, h).includes(hs.studyId))
    );
  }
}

export function studiesByHospital(user, hospital, studies, surgeons) {
  if (hasHospitalStudies(user)) {
    return studies.filter(s =>
      user.hospitalStudies.some(hs =>
        hs.studyId === s.id && hs.hospitalId === hospital.id));
  } else if (haLinkedToSurgeons(user)) {
    let studiesSet = new Set();
    studies.forEach(s => {
      // create a list of surgeons from ids and then do forEach
      surgeons.forEach(g => {
          if ((user.surgeonIds.includes(g.id)) && (g.hospitalStudies.some(hs =>
            hs.studyId === s.id && hs.hospitalId === hospital.id))) {
            studiesSet.add(s);
          }
        }
      )
    })
    return [...studiesSet];
  } else {
    return studies.filter(s => getHospitalStudyIds(user, hospital).includes(s.id));
  }
}

export function studiesByHospitalAndSurgeon(user, hospital, studies, surgeon) {
  if (hasHospitalStudies(user)) {
    return studies.filter(s =>
      user.hospitalStudies.some(hs =>
        hs.studyId === s.id && surgeon.hospitalStudies.some(ghs =>
        ghs.hospitalId === hs.hospitalId && hs.hospitalId === hospital.id && ghs.studyId === hs.studyId)
      )
    )
  } else {
    /* Remove after PR
    else if (haLinkedToSurgeons(user)) {
      // surgeon constraint must be one of the HA's surgeons
      if (!user.surgeonIds.includes(surgeon.id)) {
        return [];
      }
    }*/
    return studies.filter(study =>
      surgeon.hospitalStudies.some(ghs =>
        ghs.studyId === study.id && ghs.hospitalId === hospital.id));
  }
}

export function studiesBySurgeon(user, studies, surgeon) {
  if (hasHospitalStudies(user)) {
    return studies.filter(s =>
      user.hospitalStudies.some(hs => hs.studyId === s.id &&
        surgeon.hospitalStudies.some(ghs =>
          ghs.hospitalId === hs.hospitalId && ghs.studyId === hs.studyId)));
  } else {
    return studies.filter(s =>
      surgeon.hospitalStudies.some(hs => hs.studyId === s.id));
  }
}

export function surgeonsByHospital(user, hospital, studies, surgeons) {
  if (hasHospitalStudies(user)) {
    return surgeons.filter(g =>
      g.hospitalStudies.some(hs =>
        hs.hospitalId === hospital.id && studies.map(s => s.id).includes(hs.studyId)));
  } else if (haLinkedToSurgeons(user)) {
    return surgeons.filter(g => user.surgeonIds.includes(g.id)).filter(g1 =>
      g1.hospitalStudies.some(hs => hs.hospitalId === hospital.id));
  } else {
    return surgeons.filter(s =>
      s.hospitalStudies.some(hs =>
        hs.hospitalId === hospital.id && getHospitalStudyIds(user, hospital).includes(hs.studyId)));
  }
}

export function surgeonsByStudy(user, hospitals, study, surgeons) {
  if (hasHospitalStudies(user)) {
    return surgeons.filter(g =>
      g.hospitalStudies.some(ghs =>
        user.hospitalStudies.some(uhs =>
          uhs.hospitalId === ghs.hospitalId && uhs.studyId === ghs.studyId && uhs.studyId === study.id)));
  } else if (haLinkedToSurgeons(user)) {
    // HA linked to surgeons
    let surgeonsSet = new Set();
    surgeons.forEach(g => {
      if ((user.surgeonIds.includes(g.id)) && (g.hospitalStudies.some(hs => hs.studyId === study.id))) {
        surgeonsSet.add(g);
      }
    })
    return [...surgeonsSet];
  } else {
    return surgeons.filter(g =>
      g.hospitalStudies.filter(hs1 =>
        hospitals.map(h => h.id).includes(hs1.hospitalId))
        .some(hs => hs.studyId === study.id));
  }
}

export function surgeonsByHospitalAndStudy(user, hospital, study, surgeons) {
  if (hasHospitalStudies(user)) {
    return surgeons.filter(g =>
      g.hospitalStudies.some(ghs =>
        user.hospitalStudies.some(uhs =>
          uhs.hospitalId === ghs.hospitalId && uhs.studyId === ghs.studyId &&
          uhs.hospitalId === hospital.id && uhs.studyId === study.id
        )));
  } else if (haLinkedToSurgeons(user)) {
    // HA linked to surgeons
    let surgeonsSet = new Set();
    surgeons.forEach(g => {
      if ((user.surgeonIds.includes(g.id)) && (g.hospitalStudies.some(hs =>
        hs.studyId === study.id && hs.hospitalId === hospital.id))) {
        surgeonsSet.add(g);
      }
    })
    return [...surgeonsSet];
  } else {
    return surgeons.filter(g =>
      g.hospitalStudies.some(hs => hs.hospitalId === hospital.id && hs.studyId === study.id));
  }
}

/**
 * fetch the all the study IDs for this hospital that is relevant for the logged in user.
 * !!presumes props.studies set correctly
 * HA linked to Hospitals => hospital studies
 * HA linked to Surgeons => (hospital.studies) intersect (surgeon.hospital_studies.studies)
 * Surgeon =>               (hospital.studies) intersect (props.studies)
 * HSC =>                   (hospital.studies) intersect (props.studies)
 * @param user
 * @param hospital
 * @returns array of study ids
 */
export function getHospitalStudyIds(user, hospital) {
  if ( hasHospitalStudies(user)) {
    return user.hospitalStudies.filter(hs => hs.hospitalId === hospital.id).map(hs => hs.studyId);
  } else if (isHospitalAdministratorUser(user) && !!hospital.studiesWithMinimumAge) {
    return hospital.studiesWithMinimumAge.map(s => s.studyId);
  } else {
    return (!hospital || !hospital.studies) ? [] : hospital.studies.map(s => s.studyId);
  }
}

/**
 *
 * @param hss1 first list of hospital-studies
 * @param hss2 second list of hospital-studies
 * @param hospitals to be filtered
 * @param study when present, constrains the intersection to only those with the study
 * @returns {*}
 */
export function hospitalStudiesIntersectWithConstraint(hss1, hss2, hospitals, study) {
  if (!!study)
    return hospitals.filter(h =>
      hss1.filter(hs =>
        hs.hospitalId === h.id && hs.studyId === study.id)
        .some(ghs => hss2.some(uhs =>
          uhs.hospitalId === ghs.hospitalId && uhs.studyId === ghs.studyId)));
  else { // just find hospitals in the intersection of both hospital-study lists with no study constraint
    return hospitals.filter(h =>
      hss1.filter(hs => hs.hospitalId === h.id)
        .some(ghs => hss2.some(uhs =>
          uhs.hospitalId === ghs.hospitalId && uhs.studyId === ghs.studyId)));
  }
}

export function hospitalsFromHSIntersectAndStudyMatches(userHospitalStudies, surgeonsHospitalStudies, hospitals, study) {
  if (!!study)
    return hospitals.filter(h =>
      userHospitalStudies.filter(hs =>
        hs.hospitalId === h.id && hs.studyId === study.id)
        .some(uhs => surgeonsHospitalStudies.some(ghs =>
          uhs.hospitalId === ghs.hospitalId && uhs.studyId === ghs.studyId)));
  else { // just find hospitals in the intersection of both hospital-study lists with no study constraint
    return hospitals.filter(h =>
      userHospitalStudies.filter(hs => hs.hospitalId === h.id)
        .some(uhs => surgeonsHospitalStudies.some(ghs =>
          uhs.hospitalId === ghs.hospitalId && uhs.studyId === ghs.studyId)));
  }
}

export function hasHospitalStudies(user) {
  return isHospitalStudyCoordinatorUser(user) || isSurgeonUser(user);
}

export function haLinkedToSurgeons(user) {
  return isHospitalAdministratorUser(user) && (!!user.surgeonIds && user.surgeonIds.length > 0);
}

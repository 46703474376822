import { Fragment, useEffect, useState } from "react";
import Responsive from "react-responsive";
import { Col, Label, Row, Table } from "reactstrap";
import { loadUserCurrent } from "../../../actions/UserActions";
import PropTypes from "prop-types";
import { getStakeholderGroup } from "../../../api/StakeholderGroup";
import { useDispatch, useSelector } from "react-redux";
import "./StakeholderGroupUsers.css";
import { CREATE } from "../../../Utils";

const StakeholderGroupUsers = ({ stakeholderGroupId, operation }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    !user.found && dispatch(loadUserCurrent());
  }, [dispatch]);

  useEffect(() => {
    if (operation !== CREATE && !users.length > 0) {
      getStakeholderGroup(stakeholderGroupId).then((response) => {
        setUsers(response.data.userIds);
      });
    }
  }, []);

  const renderUserOption = (user) => {
    return `${user.firstName} ${user.lastName}`;
  };

  const getUsersTable = () => {
    return (
      <Fragment>
        <Responsive maxWidth={767}>
          <Table striped>
            <tbody>
              {" "}
              {users.map((user, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Row className={"g-0"}>
                        <Col xs={7}>{renderUserOption(user)}</Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={768}>
          <Table striped>
            <tbody>
              {users.map((user, i) => {
                return (
                  <tr key={i}>
                    <td>{renderUserOption(user)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
      </Fragment>
    );
  };

  return (
    <div className={"stakeholder-group-users"}>
      <Row>
        <Col xs={3} md={3} lg={3} sm={12}>
          <Label className={"section-text"}>Linked Users</Label>
        </Col>
        <Col xs={9} md={9} lg={9} sm={12}>
          {users.length > 0 ? (
            getUsersTable()
          ) : (
            <Table striped>
              <tbody>
                <tr>
                  <td>
                    <em>No linked users set</em>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </div>
  );
};

StakeholderGroupUsers.propTypes = {
  stakeholderGroupId: PropTypes.number,
  operation: PropTypes.string.isRequired,
};

export default StakeholderGroupUsers;

import { Badge, FormText, Input, InputGroup } from "reactstrap";
import { isEmptyOrNull, validateAustralianPostcode } from "../../../Utils";
import { FaTimes } from "react-icons/fa";
import PropTypes from "prop-types";
import isInt from "validator/lib/isInt";
import Moment from "moment";
import "./PatientSearchTextField.css";
import { useReducer } from "react";
import { handleEnterKeyPress } from "../../../Utils";

const PatientSearchTextField = ({ doSearchCallback, updateCallback }) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      textFilter: {
        value: "",
        isPostcode: false,
        isMaybePostcode: false,
        isDateOfBirth: false,
        isMaybeDateOfBirth: false,
        isName: false,
        isMaybeName: false,
        isPatientId: false,
        isMaybePatientId: false,
      },
    },
  );

  const getSearchBoxHint = () => {
    return "Enter a patient's ID, first or last name, contact details, postcode or DOB to filter your results";
  };

  const getSearchBoxError = () => {
    return "Please enter a valid patient ID, first or last name, contact details, postcode, or DOB in DD/MM/YYYY format";
  };

  const getSearchBoxPlaceholder = () => {
    return "e.g. ID:123 or Smith or 0400000000 or me@patient.com or 5000 or 25/12/2000";
  };

  const searchDisabled = () => {
    const {
      isMaybeDateOfBirth,
      isMaybeName,
      isMaybePostcode,
      isMaybePatientId,
      isMaybePhoneNumber,
    } = state.textFilter;
    return (
      isMaybeDateOfBirth ||
      isMaybeName ||
      isMaybePostcode ||
      isMaybePatientId ||
      isMaybePhoneNumber
    );
  };

  const getTextFilterBadge = () => {
    const {
      isPostcode,
      isMaybePostcode,
      isDateOfBirth,
      isMaybeDateOfBirth,
      isName,
      isMaybeName,
      isPatientId,
      isMaybePatientId,
      isMaybePhoneNumber,
      isPhoneNumber,
      isEmail,
    } = state.textFilter;
    function buildTextFilterBadge(field, maybe) {
      return (
        <Badge
          className={"text-filter-badge"}
          color={maybe ? "warning" : "primary"}
        >
          {maybe ? `${field}?` : field}
        </Badge>
      );
    }
    if (isPostcode) {
      return buildTextFilterBadge("Postcode", false);
    } else if (isMaybePostcode) {
      return buildTextFilterBadge("Postcode", true);
    } else if (isDateOfBirth) {
      return buildTextFilterBadge("DOB", false);
    } else if (isMaybeDateOfBirth) {
      return buildTextFilterBadge("DOB", true);
    } else if (isName) {
      return buildTextFilterBadge("First Name/Last Name", false);
    } else if (isMaybeName) {
      return buildTextFilterBadge("First Name/Last Name", true);
    } else if (isPatientId) {
      return buildTextFilterBadge("Patient ID", false);
    } else if (isMaybePatientId) {
      return buildTextFilterBadge("Patient ID", true);
    } else if (isPhoneNumber) {
      return buildTextFilterBadge("Phone Number", false);
    } else if (isMaybePhoneNumber) {
      return buildTextFilterBadge("Phone Number", true);
    } else if (isEmail) {
      return buildTextFilterBadge("Email", false);
    }
    return null;
  };

  const setTextFilter = (value) => {
    let isPostcode = false;
    let isMaybePostcode = false;
    let isDateOfBirth = false;
    let isMaybeDateOfBirth = false;
    let isName = false;
    let isMaybeName = false;
    let isPatientId = false;
    let isMaybePatientId = false;
    let isEmail = false;
    let isPhoneNumber = false;
    let isMaybePhoneNumber = false;
    let newValue = value;

    if (!isEmptyOrNull(newValue)) {
      if (isInt(newValue.trim())) {
        newValue = newValue.trim();
        if (validateAustralianPostcode(newValue)) {
          isPostcode = true;
        } else if (newValue.length < 4) {
          isMaybePostcode = true;
          isMaybePhoneNumber = true;
        } else {
          isPhoneNumber = true;
        }
      } else if (newValue.trim().toLocaleLowerCase().includes("@")) {
        newValue = newValue.trim();
        isEmail = true;
      } else if (newValue.trim().toLocaleLowerCase().startsWith("id:")) {
        newValue = newValue.trim();
        isMaybePatientId = true;
        let split = newValue.toLocaleLowerCase().split("id:");
        if (split.length === 2 && isInt(split[1])) {
          isPatientId = true;
          isMaybePatientId = false;
        }
      } else if (newValue.trim().includes("/")) {
        newValue = newValue.trim();
        let maybeDate = Moment(newValue, "DD/MM/YYYY", true);
        if (maybeDate.isValid()) {
          isDateOfBirth = true;
        } else {
          isMaybeDateOfBirth = true;
        }
      } else if (newValue.trimStart().length > 1) {
        newValue = newValue.trimStart();
        isName = true;
      } else {
        isMaybeName = true;
      }
    }

    let updated = {
      value: newValue,
      isPostcode: isPostcode,
      isMaybePostcode: isMaybePostcode,
      isDateOfBirth: isDateOfBirth,
      isMaybeDateOfBirth: isMaybeDateOfBirth,
      isName: isName,
      isMaybeName: isMaybeName,
      isPatientId: isPatientId,
      isMaybePatientId: isMaybePatientId,
      isMaybePhoneNumber: isMaybePhoneNumber,
      isPhoneNumber: isPhoneNumber,
      isEmail: isEmail,
    };
    setState({ textFilter: updated });
    updateCallback(updated);
  };

  const isError = searchDisabled() && getSearchBoxError();
  return (
    <span className={"patient-search-text-field"}>
      <FormText color={isError ? "danger" : "muted"}>
        {isError ? getSearchBoxError() : getSearchBoxHint()}
      </FormText>
      <InputGroup>
        <Input
          type="text"
          bsSize="small"
          placeholder={getSearchBoxPlaceholder()}
          value={state.textFilter.value}
          onChange={(e) => setTextFilter(e.target.value)}
          className={"search-term-input"}
          onKeyPress={(event) =>
            handleEnterKeyPress(event, doSearchCallback, !searchDisabled())
          }
        />
        {!isEmptyOrNull(state.textFilter.value) && (
          <span style={{ zIndex: 1000 }}>
            {getTextFilterBadge()}
            <FaTimes
              onClick={() => setTextFilter("")}
              className={"clear-search-icon"}
            />
          </span>
        )}
      </InputGroup>
    </span>
  );
};

PatientSearchTextField.propTypes = {
  doSearchCallback: PropTypes.func.isRequired,
  updateCallback: PropTypes.func.isRequired,
};

export default PatientSearchTextField;

import { useDispatch, useSelector } from "react-redux";
import { loadHospitals, loadStudies } from "../../actions/CommonActions";
import Select from "react-select";
import PropTypes from "prop-types";
import "./Reporting.css";
import {
  isEmptyOrNull,
  isHospitalAdministratorUser,
  isHospitalStudyCoordinatorUser,
  isStakeholderUser,
  isSurgeonUser,
} from "../../Utils";
import { ALL_STUDIES } from "../common/MyStudiesSelector";
import { useEffect } from "react";

export const ALL_HOSPITALS = { id: 0, name: "All Hospitals" };
export const MY_HOSPITALS = { id: -1, name: "My Hospitals" };

export const filterHospitalsByStudyId = (hospitals, studyId, user, studies) => {
  let filteredHospitals = [];
  if (studyId === null || studyId === ALL_STUDIES) {
    if (
      isHospitalAdministratorUser(user) ||
      isHospitalStudyCoordinatorUser(user)
    ) {
      filteredHospitals = hospitals.filter((h) => {
        // exclude hospitals that have only non-stakeholder reportable studies
        let hStudies = !isEmptyOrNull(h.studies)
          ? h.studies
          : !isEmptyOrNull(h.studiesWithMinimumAge)
          ? h.studiesWithMinimumAge
          : [];
        let skipThisHospital = skipHospitalWithNoStakeholderReportableStudies(
          hStudies,
          studies,
          user.roleId,
        );
        return !skipThisHospital;
      });
    } else {
      filteredHospitals = [...hospitals];
    }
  } else {
    // Be careful! Depends on who the user is, we may have studies populated or studiesWithMinimumAge.
    hospitals.forEach((hospital) => {
      let studyIds = [];
      if (!!hospital.studies) {
        studyIds = studyIds.concat(
          hospital.studies.map((hospitalStudy) => hospitalStudy.studyId),
        );
      }
      if (!!hospital.studiesWithMinimumAge) {
        studyIds = studyIds.concat(
          hospital.studiesWithMinimumAge.map((s) => s.studyId),
        );
      }
      // exclude hospitals that have only non-stakeholder reportable studies
      let skipThisHospital = false;
      if (
        user != null &&
        studies != null &&
        (isHospitalAdministratorUser(user) ||
          isHospitalStudyCoordinatorUser(user))
      ) {
        skipThisHospital = skipHospitalWithNoStakeholderReportableStudies(
          studyIds,
          studies,
          user.roleId,
        );
      }
      if (studyIds.includes(studyId) && !skipThisHospital) {
        filteredHospitals.push(hospital);
      }
    });
  }
  return filteredHospitals;
};

const skipHospitalWithNoStakeholderReportableStudies = (
  someHospitalStudyIds,
  studies,
  userRole,
) => {
  return someHospitalStudyIds.every((sId) => {
    let foundStudy = studies.find((s) => s.id === sId);
    if (foundStudy != null) {
      return !foundStudy.studyReportableRoles.map(
        (reportableRole) => reportableRole.roleId === userRole,
      );
    } else {
      return false;
    }
  });
};

const MyHospitalsSelector = ({
  disabled = false,
  filterByStudyId = null,
  hospitalIdFilter = null,
  onChange,
  searchable,
  value,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const hospitals = useSelector((state) => state.hospitals);
  const studies = useSelector((state) => state.studies);

  useEffect(() => {
    hospitals.length === 0 && dispatch(loadHospitals());
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useEffect(() => {
    /* Surgeons get a different version of hospital.studies in hospital.studiesWithMinimumAge.
       To avoid a lot of checking to use alternative versions we can just create the hospital.studies
       from hospital.studiesWithMinimumAge if it exists
      */
    hospitals.forEach((h) => {
      if (!h.studies && !!h.studiesWithMinimumAge) {
        h.studies = h.studiesWithMinimumAge.map((swma) => swma.studyId);
      }
    });
  }, [hospitals]);

  const _buildHospitalList = () => {
    let filteredHospitals = filterHospitalsByStudyId(
      hospitals,
      filterByStudyId,
      user,
      studies,
    );

    if (!!hospitalIdFilter && hospitalIdFilter.length > 0) {
      filteredHospitals = _applyHospitalIdFilter(
        filteredHospitals,
        hospitalIdFilter,
      );
    }

    if (filteredHospitals.length > 1) {
      if (
        isStakeholderUser(user) ||
        isSurgeonUser(user) ||
        isHospitalAdministratorUser(user) ||
        isHospitalStudyCoordinatorUser(user)
      ) {
        filteredHospitals.unshift(MY_HOSPITALS);
      }
    }
    filteredHospitals.unshift(ALL_HOSPITALS);

    return filteredHospitals;
  };

  const _applyHospitalIdFilter = (firstFilteredHospitals, hospitalIdFilter) => {
    return firstFilteredHospitals.filter((h) =>
      hospitalIdFilter.includes(h.id),
    );
  };

  const getRenderedValue = (value) => {
    if (isSurgeonUser(user)) {
      if (value.id === ALL_HOSPITALS.id) {
        return <b>All Hospitals/Surgeons</b>;
      } else if (value.id === MY_HOSPITALS.id) {
        return <b>All My Procedures</b>;
      } else {
        return "My Procedures at " + value.name;
      }
    } else {
      if (value.id <= 0) {
        return <b>{value.name}</b>;
      } else {
        return value.name;
      }
    }
  };

  return (
    <div className={"my-hospitals-selector"}>
      <Select
        options={_buildHospitalList()}
        onChange={onChange}
        isClearable={false}
        isSearchable={searchable}
        getOptionLabel={(option) => getRenderedValue(option)}
        getOptionValue={(option) => option.id}
        value={value}
        isDisabled={disabled}
        tabIndex={1}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
      />
    </div>
  );
};

MyHospitalsSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  filterByStudyId: PropTypes.number,
  hospitalIdFilter: PropTypes.array,
};

export default MyHospitalsSelector;

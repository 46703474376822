import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";
import { toast } from "react-toastify";
import "./Enrolment.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import "moment/locale/en-au";
import {
  clearPatientData,
  registerSubmit,
  setPatientField,
  setPatientProcedureField,
  setScheduledProcedureDate,
} from "../../actions/PatientActions";
import ProcedureTypePicker from "../common/ProcedureTypePicker";
import {
  getProcedureTypesForHospital,
  getSurgeonsAtHospital,
} from "../../api/Hospital";
import {
  getPatientMaximumNameLength,
  getProcedureTypeIdsFromProps,
  validatePatientEnrolment,
  validateStudyAgeLimit,
} from "./Utils";
import { FaCheck } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import EnrolmentConfirmModal from "./EnrolmentConfirmModal";
import {
  getCamelCase,
  getStartCase,
  isEmptyOrNull,
  UNKNOWN_SURGEON,
} from "../../Utils";
import DatePickerIosInput from "../common/DatePickerIosInput";
import scrollToComponent from "react-scroll-to-component";
import { loadHospitals } from "../../actions/CommonActions";
import { procedures, validatePatientEmailDomain } from "../../api/Patient";
import ButtonBar from "../common/ButtonBar";
import ConfirmPastSchdProcDateModal from "../management/patient/ConfirmPastSchdProcDateModal";
import { useDispatch, useSelector } from "react-redux";
import {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { getLinkedStudies } from "../../api/Study";
import { useOnUpdate } from "../CustomHooks";
import Prompt from "../common/Prompt";

const dateFormatTwo = "YYYY-MM-DD";
const dateFormatThree = "dd/MM/yyyy";
const dateFormatFour = "DD/MM/YYYY";

const defaultEdited = {
  hospital: false,
  procedureType: false,
  studies: false,
  firstName: false,
  middleNames: false,
  lastName: false,
  dateOfBirth: false,
  postcode: false,
  mobilePhone: false,
  homePhone: false,
  email: false,
  scheduledProcedureDate: false,
};

const defaultState = {
  surgeons: [],
  selectedSurgeon: null,
  selectedHospital: null,
  hospitalProcedureTypes: [],
  isSurgeon: false,
  studies: [],
  selectedStudies: [],
  acknowledgedAsNewOrRevision: false,
  hasPotentialDuplicates: false,
  resetVersion: 0,
  confirmOpen: false,
  existingProcedures: [],
  relevantExistingProcedures: [],
  scheduledProcedureDateValid: true,
  haveSetSpdToThePast: false,
  originalSpd: null,
  showEmailWarning: false,
};

const Enrolment = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const patient = useSelector((state) => state.patient);
  const hospitals = useSelector((state) => state.hospitals);

  const scrollPoint = useRef();

  const [validation, setValidation] = useState(null);

  const [edited, setEdited] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    defaultEdited,
  );
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    defaultState,
  );

  // Clear patient data when component unmounts
  useEffect(() => {
    return () => {
      clearPage();
    };
  }, []);

  // No need to re-render the DOM when validating patient details
  // (If useEffect is used, the 'Studies' error message will flash red when selecting a procedure type and side)
  useLayoutEffect(() => {
    setValidation(validatePatientEnrolment(buildPatientModel()));
  }, [
    patient.newProcedure,
    patient.studies,
    patient.firstName,
    patient.middleNames,
    patient.lastName,
    patient.dateOfBirth,
    patient.postcode,
    patient.mobilePhone,
    patient.homePhone,
    patient.email,
    state.selectedStudies,
    state.selectedHospital,
    state.selectedSurgeon,
  ]);

  useEffect(() => {
    if (hospitals.length === 0) {
      dispatch(loadHospitals());
    } else {
      if (hospitals.length === 1) {
        selectHospital(hospitals[0]);
      }
      if ("Surgeon" === user.roleName) {
        setState({
          isSurgeon: true,
          selectedSurgeon: user,
          surgeons: [user],
        });
      }
    }
  }, [hospitals, state.selectedHospital]);

  useOnUpdate(() => {
    if (!state.selectedSurgeon) {
      return;
    }
    if (patient.newProcedure.length === 0) {
      setState({
        studies: [],
        selectedStudies: filterStudiesSelected([], state.selectedStudies),
      });
    } else {
      const procedureTypeIds = getProcedureTypeIdsFromProps(
        patient.newProcedure,
      );
      let surgeonId =
        state.selectedSurgeon.id === UNKNOWN_SURGEON.id
          ? null
          : state.selectedSurgeon.id;
      let hospitalId = !state.selectedHospital
        ? null
        : state.selectedHospital.id;
      getLinkedStudies(surgeonId, hospitalId, procedureTypeIds, false).then(
        (response) => {
          // If the current user is a hospital study coordinator then filter the response studies to those the HSC is linked to.
          let allowedStudies = !isEmptyOrNull(user.hospitalStudies)
            ? response.data.filter((s) =>
                user.hospitalStudies
                  .map((hs) => hs.hospitalId === hospitalId && hs.studyId)
                  .includes(s.id),
              )
            : response.data;
          setState({
            studies: allowedStudies,
            selectedStudies: filterStudiesSelected(
              [...allowedStudies],
              state.selectedStudies,
            ),
          });
        },
      );
    }
  }, [patient.newProcedure, state.selectedSurgeon]);

  useEffect(() => {
    if (
      state.selectedStudies.some(
        (study) =>
          !getFilteredStudies()
            .map((s) => s.id)
            .includes(study.id),
      )
    ) {
      setState({
        selectedStudies: state.selectedStudies.filter((study) =>
          getFilteredStudies()
            .map((s) => s.id)
            .includes(study.id),
        ),
      });
    }
  }, [patient.dateOfBirth, state.selectedStudies]);

  useEffect(() => {
    checkForExistingProcedures();
  }, [validation]);

  const checkForExistingProcedures = () => {
    // See if the form is complete enough to check if the patient already exists
    let noValidationErrors =
      validation &&
      validation.dateOfBirth.ok &&
      validation.firstName.ok &&
      validation.lastName.ok &&
      validation.middleNames.ok &&
      validation.postcode.ok;

    if (noValidationErrors) {
      let { firstName, middleNames, lastName, dateOfBirth, postcode } =
        buildPatientModel();
      let payload = {
        firstName,
        middleNames,
        lastName,
        dateOfBirth: Moment(dateOfBirth).format(dateFormatTwo),
        postcode,
      };
      procedures(payload).then((response) => {
        let _relevantExistingProcedures = getRelevantExistingProcedures(
          response.data,
        );
        let _hasPotentialDuplicates =
          !!_relevantExistingProcedures &&
          _relevantExistingProcedures.length > 0;
        if (state.acknowledgedAsNewOrRevision && !_hasPotentialDuplicates)
          setState({
            existingProcedures: response.data,
            acknowledgedAsNewOrRevision: false,
            relevantExistingProcedures: _relevantExistingProcedures,
          });
        else
          setState({
            existingProcedures: response.data,
            relevantExistingProcedures: _relevantExistingProcedures,
            hasPotentialDuplicates: _hasPotentialDuplicates,
          });
      });
    } else if (state.existingProcedures.length > 0) {
      setState({
        existingProcedures: [],
        acknowledgedAsNewOrRevision: false,
        relevantExistingProcedures: [],
        hasPotentialDuplicates: false,
      });
    }
  };

  const getRelevantExistingProcedures = (existingProcedures) => {
    if (existingProcedures == null) {
      return [];
    }
    let _patient = buildPatientModel();
    return existingProcedures.filter(
      (procedure) =>
        _patient.newProcedure[getCamelCase(procedure.procedureName)],
    );
  };

  const isScheduledProcedureDateInValidRange = (date) => {
    // Assumes: date is valid and not null //
    let oneYearHence = Moment().add(1, "year").endOf("day");
    let oneYearPrior = Moment().subtract(1, "year").startOf("day");
    let rangeOK = !Moment(date, dateFormatFour, true).isAfter(oneYearHence);
    rangeOK =
      rangeOK && !Moment(date, dateFormatFour, true).isBefore(oneYearPrior);
    return rangeOK;
  };

  const handleChangeRawScheduledProcedureDate = (date, wasBlur) => {
    setEdited({ scheduledProcedureDate: true });
    if (isEmptyOrNull(date)) {
      if (wasBlur) {
        setState({
          scheduledProcedureDateValid: true,
          haveSetSpdToThePast: false,
        });
      }
      return;
    }
    if (Moment.utc(date, dateFormatFour, true).isValid()) {
      let spdValid = isScheduledProcedureDateInValidRange(date);
      updateScheduledProcedureDate(date);
      setState({
        scheduledProcedureDateValid: spdValid,
        haveSetSpdToThePast: !wasBlur,
      });
    } else {
      setState({
        scheduledProcedureDateValid: false,
        haveSetSpdToThePast: false,
      });
    }
  };

  const updatePatientDetail = (field, value) => {
    let input = value;
    // Prevent text field entry that consists only of spaces
    if (
      (typeof input === "string" || input instanceof String) &&
      input.trim().length === 0
    ) {
      input = "";
    }
    // Don't update field if already more than 10 non-space characters entered for mobile
    if (field === "mobilePhone") {
      if (value != null && value.replace(/ /g, "").length > 10) {
        return;
      }
    }
    // Update state so the form fields reflect what is being typed
    setState({
      patient: { ...patient, [field]: input },
    });
    dispatch(setPatientField({ [field]: input }));
    setEdited({ [field]: true });
  };

  const updateScheduledProcedureDate = (date) => {
    let haveSetSpdToThePast;
    if (date != null) {
      let todayStart = Moment().startOf("day");
      haveSetSpdToThePast = Moment(date).isBefore(todayStart);
    }
    setEdited({ scheduledProcedureDate: true });
    setState({
      scheduledProcedureDateValid: true,
      haveSetSpdToThePast: date == null ? false : haveSetSpdToThePast,
      originalSpd: patient.newProcedure.scheduledProcedureDate,
    });
    dispatch(
      setScheduledProcedureDate(
        Moment(date, dateFormatFour, true).format(dateFormatTwo),
      ),
    );
  };

  const submit = () => {
    let _patient = buildPatientModel();
    dispatch(
      registerSubmit({ data: _patient, callback: handleSubmitResponse }),
    );
  };

  const badSurgeonSelection = () => {
    return (
      "Hospital Administrator" === user.roleName &&
      user.hospitalIds &&
      user.hospitalIds.length === 0 &&
      state.selectedSurgeon &&
      state.selectedSurgeon.id === UNKNOWN_SURGEON.id
    );
  };

  const validateEmail = async (patientEmail) => {
    if (patientEmail === null || patientEmail === "") {
      setState({ showEmailWarning: false });
    } else {
      let patientEmailDomain = patientEmail.substring(
        patient.email.indexOf("@") + 1,
      );
      let response = await validatePatientEmailDomain(patientEmailDomain);

      if (response.status === 200) {
        if (!response.data) {
          setState({ showEmailWarning: true });
        } else {
          setState({ showEmailWarning: false });
        }
      }
    }
  };

  const handleRegisterButtonClick = () => {
    validateEmail(patient.email).then(() => {
      buildPatientModel();
      // Clicked on button, but it's "disabled" let's display all valid error fields on the page.
      validation.okToProceed &= !badSurgeonSelection();
      if (!validation || (validation && !validation.okToProceed)) {
        let _edited = edited;
        Object.keys(_edited).forEach((v) => (_edited[v] = true));
        setEdited(_edited);
      } else {
        setState({ confirmOpen: true });
      }
    });
  };

  const closeConfirmModal = () => {
    setState({ confirmOpen: false });
  };

  const handleSubmitResponse = (success, message) => {
    if (success) {
      toast.success(
        <span>
          <FaCheck /> {message}
        </span>,
      );
      clearPage();
      scrollToComponent(scrollPoint.current, {
        align: "top",
        duration: 500,
      });
    } else {
      closeConfirmModal();
      toast.error(
        <span>
          <MdErrorOutline /> {message}
        </span>,
      );
    }
  };

  const clearPage = () => {
    setState({
      ...defaultState,
      resetVersion: state.resetVersion + 1,
    });
    dispatch(clearPatientData());
    setValidation(null);
    setEdited(defaultEdited);
  };

  const buildDateOfBirth = (dateOfBirth) => {
    if (dateOfBirth) {
      return Moment.utc(dateOfBirth, "DD/MM/YYYY");
    } else {
      return null;
    }
  };

  const getInvalidStudyAgeText = () => {
    if (state.studies.length > getFilteredStudies().length) {
      if (!isEmptyOrNull(user.hospitalStudies)) {
        if (user.hospitalStudies.length < getFilteredStudies().length) {
          return null;
        }
      }
      if (!hasInappropriateStudy()) {
        return null;
      }

      let minimumAgeErrorText = "";
      let excluded = state.studies.filter(
        (study) =>
          !getFilteredStudies()
            .map((s) => s.id)
            .includes(study.id),
      );
      excluded.forEach((study) => {
        minimumAgeErrorText = minimumAgeErrorText
          .concat(study.name)
          .concat(" (Patients must be ")
          .concat(study.minimumAge)
          .concat(" or over)")
          .concat(",");
      });
      if (minimumAgeErrorText.lastIndexOf(",") > -1) {
        minimumAgeErrorText = minimumAgeErrorText.substr(
          0,
          minimumAgeErrorText.lastIndexOf(","),
        );
      }
      return (
        "The following studies do not have their minimum age requirements met and have been removed from the selection: " +
        minimumAgeErrorText
      );
    }
    return null;
  };

  const buildPatientModel = (_patient) => {
    let patientModel = _patient == null ? { ...patient } : { ..._patient };
    patientModel.newProcedure = { ...patientModel.newProcedure };
    patientModel.dateOfBirth = buildDateOfBirth(patientModel.dateOfBirth);
    patientModel.newProcedure.hospital = state.selectedHospital;
    if (
      state.selectedSurgeon &&
      state.selectedSurgeon.id !== UNKNOWN_SURGEON.id
    ) {
      patientModel.newProcedure.surgeon = { ...state.selectedSurgeon };
    }
    patientModel.studies = [...state.selectedStudies];
    return patientModel;
  };

  const selectHospital = (hospital) => {
    setState({
      selectedHospital: hospital,
      selectedStudies: [],
    });

    let procedureTypes = [];
    getProcedureTypesForHospital(hospital.id).then((response) => {
      procedureTypes = [...response.data];
      setHospitalProcedureTypes(procedureTypes);
    });

    let surgeons = [];
    let selectedSurgeon = null;
    if ("Surgeon" !== user.roleName && hospital && hospital.id) {
      getSurgeonsAtHospital(hospital.id).then((response) => {
        surgeons = [...response.data];
        if (user.hospitalIds.includes(hospital.id)) {
          // Add unknown surgeon only if the hospital admin is directly linked to the hospital
          surgeons.push(UNKNOWN_SURGEON);
        }
        setState({ surgeons: surgeons });
        // Auto-select the surgeon if there's only one
        if (surgeons.length === 1) {
          selectedSurgeon = surgeons[0];
        } else {
          // 2 or greater length: if the previous surgeon is in the list, keep them selected.
          let matched = surgeons.filter((s) => {
            return selectedSurgeon && s.id === selectedSurgeon.id;
          });
          if (matched.length !== 1) {
            selectedSurgeon = UNKNOWN_SURGEON;
          }
        }
        selectSurgeon(selectedSurgeon);
      });
    }
    setEdited({ hospital: true });
  };

  const setHospitalProcedureTypes = (procedureTypes) => {
    setState({
      hospitalProcedureTypes: procedureTypes === null ? [] : procedureTypes,
    });
  };

  const selectSurgeon = (surgeon) => {
    if (surgeon === null) {
      surgeon = { ...UNKNOWN_SURGEON };
    }
    setState({ selectedSurgeon: surgeon });
  };

  const filterStudiesSelected = (studies, selectedStudies) => {
    // If the list of studies only contains one item, auto-set the study selection to that
    if (studies.length === 1) {
      return studies;
    }
    if (selectedStudies.length === 0) {
      return studies.filter((study) => study.autoEnrolment === true);
    }
    return studies.filter((s) => {
      return (
        selectedStudies.filter(
          (ss) => ss.id === s.id || s.autoEnrolment === true,
        ).length > 0
      );
    });
  };

  const filterSurgeons = (option, filter) => {
    return `${option.data.firstName} ${option.data.lastName} ${option.data.code}`
      .toLowerCase()
      .includes(filter.toLowerCase());
  };

  const selectStudy = (studies) => {
    setEdited({ studies: true });
    setState({ selectedStudies: studies });
  };

  const hasInappropriateStudy = () => {
    if (patient.dateOfBirth && validation.dateOfBirth.ok) {
      // If HSC then only consider for validity from that list of studies
      // Otherwise; consider validity of all the state.studies
      let _studies = isEmptyOrNull(user.hospitalStudies)
        ? state.studies
        : state.studies.filter((study) =>
            user.hospitalStudies.map((hs) => hs.studyId).includes(study.id),
          );
      // If some study is invalid then return true
      return _studies.some((study) =>
        validateStudyAgeLimit(buildDateOfBirth(patient.dateOfBirth), study),
      );
    }
    return false;
  };

  const getFilteredStudies = () => {
    let filtered;
    if (patient.dateOfBirth && validation.dateOfBirth.ok) {
      filtered = state.studies.filter((study) =>
        validateStudyAgeLimit(buildDateOfBirth(patient.dateOfBirth), study),
      );
    } else {
      filtered = state.studies;
    }
    if (!isEmptyOrNull(user.hospitalStudies)) {
      // The user is restricted to a list of studies (e.g. user is hospital study coordinator)
      filtered = filtered.filter((study) =>
        user.hospitalStudies.map((hs) => hs.studyId).includes(study.id),
      );
    }
    return filtered;
  };

  const selectProcedureType = (procedureType) => {
    dispatch(setPatientProcedureField(procedureType));
    setEdited({ procedureType: true });
  };

  const handleEnableSubmitButtonClick = () => {
    setState({ acknowledgedAsNewOrRevision: true });
  };

  const renderSurgeonOption = (surgeon) => {
    return surgeon.id === 0 ? (
      <div>
        <i>{surgeon.firstName}</i>
      </div>
    ) : (
      <div>
        Dr {surgeon.lastName}, {surgeon.firstName}
      </div>
    );
  };

  const confirmSpdInThePast = (confirm) => {
    if (confirm) {
      setState({ haveSetSpdToThePast: false });
    } else {
      setState({ haveSetSpdToThePast: false });
      dispatch(setScheduledProcedureDate(state.originalSpd));
    }
  };

  let editedFields = Object.keys(edited).filter(
    (field) => edited[field] === true,
  );
  return (
    <div>
      <Prompt
        when={
          (hospitals.length > 1 && editedFields.length > 0) ||
          (hospitals.length === 1 && editedFields.length > 1)
        }
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <EnrolmentConfirmModal
        open={state.confirmOpen}
        confirmCallback={submit}
        exitCallback={closeConfirmModal}
        patient={
          !validation || (validation && !validation.okToProceed)
            ? null
            : buildPatientModel()
        }
        showEmailWarning={state.showEmailWarning}
      />

      {state.haveSetSpdToThePast && (
        <ConfirmPastSchdProcDateModal
          confirmCallback={confirmSpdInThePast}
          dateToConfirm={patient.newProcedure.scheduledProcedureDate}
          showModal={state.haveSetSpdToThePast}
        />
      )}
      <Container className={"patient-registration my-3"}>
        <div ref={scrollPoint} />
        <h1>New Patient Registration</h1>
        <Card>
          <CardBody>
            <CardTitle>Procedure Details</CardTitle>
            <Form className={"procedure"}>
              <FormGroup row>
                <Label
                  sm={12}
                  md={4}
                  for="hospital"
                  className={"form-label text-start"}
                >
                  Hospital
                </Label>
                <Col sm={12} md={8}>
                  <Select
                    id="hospital"
                    placeholder="Choose Hospital..."
                    value={state.selectedHospital}
                    options={hospitals}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.state.shortName})`
                    }
                    getOptionValue={(option) => option.id}
                    onChange={selectHospital}
                    isDisabled={hospitals.length === 1}
                    isClearable={false}
                    className={
                      edited.hospital && validation && !validation.hospital.ok
                        ? "border-danger"
                        : null
                    }
                    styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                  {edited.hospital && validation && !validation.hospital.ok && (
                    <FormFeedback style={{ display: "block" }}>
                      {validation && validation.hospital.message}
                    </FormFeedback>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label
                  sm={12}
                  md={4}
                  for="surgeon"
                  className={"form-label text-start"}
                >
                  Surgeon
                </Label>
                <Col sm={12} md={8}>
                  <Select
                    id="surgeon"
                    placeholder="Choose Surgeon..."
                    isDisabled={
                      state.isSurgeon ||
                      (state.surgeons && state.surgeons.length === 1)
                    }
                    value={state.selectedSurgeon}
                    options={state.surgeons}
                    getOptionLabel={(option) => renderSurgeonOption(option)}
                    getOptionValue={(option) => option.id}
                    onChange={selectSurgeon}
                    isSearchable
                    styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                    filterOption={filterSurgeons}
                  />
                  {badSurgeonSelection() && (
                    <FormFeedback style={{ display: "block" }}>
                      Choose a surgeon
                    </FormFeedback>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label
                  sm={12}
                  md={4}
                  for="procedure-type-side"
                  className={"form-label text-start procedure-type-and-side"}
                >
                  <div>Procedure Type and Side</div>
                  <div className={"subdued text-start"}>
                    *Tick all that apply
                  </div>
                </Label>
                <Col sm={12} md={8}>
                  <ProcedureTypePicker
                    updateAction={selectProcedureType}
                    resetVersion={state.resetVersion}
                    newProcedure={patient.newProcedure}
                    showProcedureTypes={state.hospitalProcedureTypes}
                    compact
                    id="procedure-type-and-side"
                  />
                  {edited.procedureType &&
                    validation &&
                    !validation.procedureType.ok && (
                      <FormFeedback style={{ display: "block" }}>
                        {validation && validation.procedureType.message}
                      </FormFeedback>
                    )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label
                  sm={12}
                  md={4}
                  for="studies"
                  className={"form-label text-start"}
                >
                  Studies
                </Label>
                <Col sm={12} md={8}>
                  <Select
                    id="studies"
                    placeholder="Enrol in studies..."
                    value={state.selectedStudies}
                    options={getFilteredStudies()}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(selected) =>
                      selectStudy(selected == null ? [] : selected)
                    }
                    isMulti
                    hideSelectedOptions
                    stayOpen={true}
                    styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                    className={
                      edited.studies && validation && !validation.studies.ok
                        ? "border-danger"
                        : null
                    }
                  />
                  {edited.studies && validation && !validation.studies.ok && (
                    <FormFeedback style={{ display: "block" }}>
                      {validation && validation.studies.message}
                    </FormFeedback>
                  )}
                  {!isEmptyOrNull(getInvalidStudyAgeText()) && (
                    <FormText className={"study-dob-warning"}>
                      {getInvalidStudyAgeText()}
                    </FormText>
                  )}
                </Col>
              </FormGroup>
              {state.selectedStudies != null &&
                state.selectedStudies.some(
                  (s) => s.showScheduledProcedureDate,
                ) && (
                  <FormGroup row>
                    <Label
                      sm={12}
                      md={4}
                      for="scheduled-procedure-date"
                      className={
                        "form-label text-start scheduled-procedure-date"
                      }
                    >
                      <div>Scheduled Procedure Date</div>
                    </Label>
                    <Col sm={12} md={8}>
                      <DatePicker
                        selected={
                          isEmptyOrNull(patient) ||
                          isEmptyOrNull(patient.newProcedure) ||
                          isEmptyOrNull(
                            patient.newProcedure.scheduledProcedureDate,
                          )
                            ? null
                            : Moment(
                                patient.newProcedure.scheduledProcedureDate,
                              )
                                .utc()
                                .toDate()
                        }
                        onChange={(value) =>
                          updateScheduledProcedureDate(value)
                        }
                        onChangeRaw={(event) =>
                          handleChangeRawScheduledProcedureDate(
                            event.target.value,
                          )
                        }
                        onBlur={(event) =>
                          handleChangeRawScheduledProcedureDate(
                            event.target.value,
                            true,
                          )
                        }
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormat={dateFormatThree}
                        strictParsing
                        minDate={Moment().subtract(1, "year").toDate()}
                        maxDate={Moment().add(1, "year").toDate()}
                        dropdownMode="scroll"
                        scrollableYearDropdown={true}
                        yearDropdownItemNumber={120}
                        placeholderText={"Scheduled Procedure Date..."}
                        popperPlacement={"top-start"}
                        className={
                          !state.scheduledProcedureDateValid
                            ? "border-danger"
                            : "scheduled-procedure-date"
                        }
                        customInput={<DatePickerIosInput />}
                      />
                      {!state.scheduledProcedureDateValid && (
                        <FormText className={"sch-proc-date-warning"}>
                          Please use the DD/MM/YYYY format. Scheduled procedure
                          date must be within one year either side of today's
                          date.
                        </FormText>
                      )}
                    </Col>
                  </FormGroup>
                )}
            </Form>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Patient Details</CardTitle>
            <FormGroup row>
              <Label
                sm={12}
                md={4}
                for="firstname"
                className={"form-label text-start"}
              >
                First Name
              </Label>
              <Col sm={12} md={8}>
                <Input
                  type={"text"}
                  id="firstname"
                  placeholder="First Name..."
                  onChange={(event) =>
                    updatePatientDetail("firstName", event.target.value)
                  }
                  value={!!patient ? patient.firstName : ""}
                  className={
                    edited.firstName && validation && !validation.firstName.ok
                      ? "is-invalid"
                      : null
                  }
                  maxLength={getPatientMaximumNameLength()}
                />
                <FormFeedback>
                  {validation && validation.firstName.message}
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                sm={12}
                md={4}
                for="middlenames"
                className={"form-label text-start"}
              >
                Middle Names
              </Label>
              <Col sm={12} md={8}>
                <Input
                  type={"text"}
                  id="middlenames"
                  placeholder="Middle Names..."
                  onChange={(event) =>
                    updatePatientDetail("middleNames", event.target.value)
                  }
                  value={!!patient ? patient.middleNames : ""}
                  className={
                    edited.middleNames &&
                    validation &&
                    !validation.middleNames.ok
                      ? "is-invalid"
                      : null
                  }
                  maxLength={getPatientMaximumNameLength()}
                />
                <FormFeedback>
                  {validation && validation.middleNames.message}
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                sm={12}
                md={4}
                for="lastname"
                className={"form-label text-start"}
              >
                Last Name
              </Label>
              <Col sm={12} md={8}>
                <Input
                  type={"text"}
                  id="lastname"
                  placeholder="Last Name..."
                  onChange={(event) =>
                    updatePatientDetail("lastName", event.target.value)
                  }
                  value={!!patient ? patient.lastName : ""}
                  className={
                    edited.lastName && validation && !validation.lastName.ok
                      ? "is-invalid"
                      : null
                  }
                  maxLength={getPatientMaximumNameLength()}
                />
                <FormFeedback>
                  {validation && validation.lastName.message}
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                sm={12}
                md={4}
                for="dateofbirth"
                className={"form-label text-start"}
              >
                Date of Birth
              </Label>
              <Col sm={12} md={8}>
                <Input
                  type={"text"}
                  id="dateofbirth"
                  placeholder="DD/MM/YYYY"
                  onChange={(event) =>
                    updatePatientDetail("dateOfBirth", event.target.value)
                  }
                  value={!!patient ? patient.dateOfBirth : ""}
                  className={
                    edited.dateOfBirth &&
                    validation &&
                    !validation.dateOfBirth.ok
                      ? "is-invalid"
                      : "date-of-birth"
                  }
                  pattern={"[0-9]{0,10}"}
                  inputMode={"numeric"}
                  maxLength={"10"}
                />
                {edited.dateOfBirth &&
                  validation &&
                  !validation.dateOfBirth.ok && (
                    <FormFeedback style={{ display: "block" }}>
                      {validation && validation.dateOfBirth.message}
                    </FormFeedback>
                  )}
                {!isEmptyOrNull(getInvalidStudyAgeText()) && (
                  <FormText className={"study-dob-warning"}>
                    {getInvalidStudyAgeText()}
                  </FormText>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                sm={12}
                md={4}
                for="postcode"
                className={"form-label text-start"}
              >
                Postcode
              </Label>
              <Col sm={12} md={8}>
                <Input
                  type={"text"}
                  id="postcode"
                  placeholder="Postcode..."
                  onChange={(event) =>
                    updatePatientDetail("postcode", event.target.value)
                  }
                  value={!!patient ? patient.postcode : ""}
                  className={
                    edited.postcode && validation && !validation.postcode.ok
                      ? "is-invalid"
                      : null
                  }
                  pattern={"[0-9]{0,4}"}
                  inputMode={"numeric"}
                  maxLength={4}
                />
                <FormFeedback>
                  {validation && validation.postcode.message}
                </FormFeedback>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
        {!!state.relevantExistingProcedures &&
          state.relevantExistingProcedures.length > 0 && (
            <Alert color={"danger"}>
              <b>
                WARNING, YOU MAY BE ABOUT TO REGISTER A DUPLICATE PROCEDURE FOR
                THIS PATIENT.
              </b>
              <br />
              <br />
              If this procedure is listed below, DO NOT proceed with this
              registration unless you are registering a revision of the listed
              procedure.
              <br />
              <br />
              Existing Procedures:
              <ul>
                {!!state.relevantExistingProcedures &&
                  state.relevantExistingProcedures.map((procedure, i) => {
                    let haveHospital = hospitals.some(
                      (hospital) => hospital.id === procedure.hospitalId,
                    );
                    let haveSurgeon = state.surgeons.some(
                      (surgeon) => surgeon.id === procedure.surgeonId,
                    );
                    let message = getStartCase(procedure.procedureName);
                    if (haveHospital) {
                      message =
                        message +
                        " at " +
                        hospitals.filter(
                          (hospital) => hospital.id === procedure.hospitalId,
                        )[0].name;
                    }
                    if (haveSurgeon) {
                      let surgeon = state.surgeons.filter(
                        (surgeon) => surgeon.id === procedure.surgeonId,
                      )[0];
                      message =
                        message +
                        " by " +
                        surgeon.firstName +
                        " " +
                        surgeon.lastName;
                    }
                    message =
                      message +
                      " (Registered: " +
                      Moment(procedure.dateCreated).format("DD/MM/YYYY") +
                      ")";

                    return (
                      <li key={"existing-procedure-li-" + i}>{message}</li>
                    );
                  })}
              </ul>
              <b>
                You will need to acknowledge that is NOT a duplicate in order to
                proceed with this registration.
              </b>
              <br />
              <br />
              <Button
                color={"secondary"}
                className={"submit"}
                outline={state.acknowledgedAsNewOrRevision}
                disabled={state.acknowledgedAsNewOrRevision}
                onClick={handleEnableSubmitButtonClick}
              >
                {state.acknowledgedAsNewOrRevision
                  ? "Acknowledged"
                  : "Acknowledge"}
              </Button>
            </Alert>
          )}
        <Card>
          <CardBody>
            <CardTitle>Patient Contact Details</CardTitle>
            <FormGroup row>
              <Label
                sm={12}
                md={4}
                for="email"
                className={"form-label text-start"}
              >
                Email
              </Label>
              <Col sm={12} md={8}>
                <Input
                  type={"text"}
                  id="email"
                  placeholder="_______@_____.___"
                  onChange={(event) =>
                    updatePatientDetail("email", event.target.value)
                  }
                  value={!!patient ? patient.email : ""}
                  className={
                    (edited.homePhone || edited.mobilePhone || edited.email) &&
                    validation &&
                    !validation.email.ok
                      ? "is-invalid"
                      : null
                  }
                />
                <FormFeedback>
                  {validation && validation.email.message}
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                sm={12}
                md={4}
                for="mobilephone"
                className={"form-label text-start"}
              >
                Mobile Phone
              </Label>
              <Col sm={12} md={8}>
                <Input
                  type={"text"}
                  id="mobilephone"
                  placeholder="04########"
                  onChange={(event) =>
                    updatePatientDetail("mobilePhone", event.target.value)
                  }
                  value={!!patient ? patient.mobilePhone : ""}
                  className={
                    (edited.homePhone || edited.mobilePhone || edited.email) &&
                    validation &&
                    !validation.mobilePhone.ok
                      ? "is-invalid"
                      : null
                  }
                  pattern={"[0-9]*"}
                  inputMode={"numeric"}
                />
                <FormFeedback>
                  {validation && validation.mobilePhone.message}
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                sm={12}
                md={4}
                for="homephone"
                className={"form-label text-start"}
              >
                Home Phone
              </Label>
              <Col sm={12} md={8}>
                <Input
                  type={"text"}
                  id="homephone"
                  placeholder="0#########"
                  onChange={(event) =>
                    updatePatientDetail("homePhone", event.target.value)
                  }
                  value={!!patient ? patient.homePhone : ""}
                  className={
                    (edited.homePhone || edited.mobilePhone || edited.email) &&
                    validation &&
                    !validation.homePhone.ok
                      ? "is-invalid"
                      : null
                  }
                  pattern={"[0-9]*"}
                  inputMode={"numeric"}
                />
                <FormFeedback>
                  {validation && validation.homePhone.message}
                </FormFeedback>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
        <ButtonBar
          horizontalRule={false}
          buttons={[
            <Button
              color={"primary"}
              className={"submit"}
              disabled={
                state.hasPotentialDuplicates &&
                !state.acknowledgedAsNewOrRevision
              }
              outline={!validation || (validation && !validation.okToProceed)}
              onClick={handleRegisterButtonClick}
            >
              {" "}
              Register{" "}
            </Button>,
            <Button color={"secondary"} className={"reset"} onClick={clearPage}>
              Reset
            </Button>,
          ]}
        />
      </Container>
    </div>
  );
};

export default Enrolment;

import { Fragment, useEffect, useReducer } from "react";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import {
  isEmptyOrNull,
  isHospitalAdministratorRoleId,
  isHospitalStudyCoordinatorRoleId,
} from "../../Utils";
import TextareaAutosize from "react-autosize-textarea";
import {
  assigneeNeedsUsers,
  canChooseAssignee,
  userCanAssignTaskTo,
} from "./Utils";
import { useDispatch, useSelector } from "react-redux";
import SimpleRoleSelector from "../common/SimpleRoleSelector";
import {
  createDataQuery,
  getHospitalAdministrators,
  getHospitalStudyCoordinators,
} from "../../api/DataQuery";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import ButtonBar from "../common/ButtonBar";
import { FaQuestionCircle } from "react-icons/fa";
import "./CreateDataQuery.css";

export const TYPE = {
  PATIENT: "PATIENT",
  PROCEDURE: "PROCEDURE",
  CRF: "CRF",
};

const CreateDataQuery = ({
  cancelCallback,
  createCallback,
  type,
  crfType,
  crfFieldId,
  crfFieldName,
  crfProcId,
  fieldName,
  fieldValue,
  referencedId,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      comment: "",
      assignTo: null,
      isPublic: false,
      error: null,
      assigneeUsers: null,
    },
  );

  useEffect(() => {
    if (!isEmptyOrNull(crfType)) {
      let crfCompletableRolesByType = crfType.completableRoles;
      crfCompletableRolesByType.push({ roleId: 1 }); // Administrator
      crfCompletableRolesByType.push({ roleId: 4 }); // SAHMRI
      setState({
        crfRolesProvided: crfCompletableRolesByType,
      });
    }
  }, []);

  const canSubmit = () => {
    return (
      !isEmptyOrNull(state.comment) &&
      (!canChooseAssignee(user) || state.assignTo != null) &&
      (!assigneeNeedsUsers(state.assignTo) ||
        (state.isPublic &&
          !!state.assigneeUsers &&
          state.assigneeUsers.length > 0))
    );
  };

  const submit = () => {
    dispatch(startLoading());
    const payload = [
      {
        attribute: fieldName,
        value: fieldValue,
        assignedToId: state.assignTo == null ? null : state.assignTo.id,
        comment: state.comment,
        public: state.isPublic,
        referencedIds: [referencedId],
        crfFieldId: crfFieldId,
        crfTypeId: crfType != null ? crfType.id : null,
      },
    ];
    createDataQuery(payload)
      .then(createCallback)
      .catch((error) => {
        if (error?.response?.data && typeof error.response.data === "string") {
          setState({ error: error.response.data });
        } else {
          setState({ error: "An error occurred" });
        }
      })
      .finally(() => dispatch(finishLoading()));
  };

  const setCurrentAssignee = (value) => {
    if (
      isHospitalAdministratorRoleId(value.id) ||
      isHospitalStudyCoordinatorRoleId(value.id)
    ) {
      dispatch(startLoading());
      let payload = {};
      if (type === TYPE.PATIENT) {
        payload.patientIds = referencedId;
      } else if (type === TYPE.PROCEDURE) {
        payload.procedureIds = referencedId;
      } else if (type === TYPE.CRF) {
        payload.crfIds = crfId;
        payload.procedureIds = crfProcId;
      }
      if (isHospitalAdministratorRoleId(value.id)) {
        getHospitalAdministrators(payload)
          .then((response) =>
            setState({ assigneeUsers: response.data, error: null }),
          )
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            } else {
              setState({ error: "An error occurred" });
            }
          })
          .finally(() => dispatch(finishLoading()));
      } else if (isHospitalStudyCoordinatorRoleId(value.id)) {
        getHospitalStudyCoordinators(payload)
          .then((response) =>
            setState({ assigneeUsers: response.data, error: null }),
          )
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            } else {
              setState({ error: "An error occurred" });
            }
          })
          .finally(() => dispatch(finishLoading()));
      }
    } else {
      setState({ assigneeUsers: null });
    }
    setState({ assignTo: value });
  };

  const getFieldName = () => {
    if (type === TYPE.CRF) {
      return crfFieldName;
    } else {
      return fieldName;
    }
  };

  const callUserCanAssignTaskTo = (role) => {
    if (type === TYPE.CRF && !isEmptyOrNull(state.crfRolesProvided)) {
      return state.crfRolesProvided.map((cr) => cr.roleId).includes(role.id);
    } else {
      return userCanAssignTaskTo(user, role, type);
    }
  };

  return (
    <Modal isOpen={true} className={"create-data-query"}>
      <ModalHeader>Create Data Query</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <b>Field</b>
          </Col>
          <Col>{getFieldName()}</Col>
        </Row>
        <Row>
          <Col>
            <b>Value When Queried</b>
          </Col>
          <Col>{isEmptyOrNull(fieldValue) ? <i>Empty</i> : fieldValue}</Col>
        </Row>
        <Row className={"mt-3"}>
          <Col xs={12}>Comment*</Col>
          <Col xs={12}>
            <TextareaAutosize
              type={"textarea"}
              className={"free-text-multi"}
              value={state.comment}
              onChange={(event) => setState({ comment: event.target.value })}
            />
          </Col>
        </Row>
        <Row className={"mb-3"}>
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name={"isPublic"}
                  onChange={() => setState({ isPublic: !state.isPublic })}
                  checked={state.isPublic}
                />
                {" Public"}
              </Label>
              <FaQuestionCircle
                id={"is-public-icon"}
                className={"tooltip-icon"}
              />
              <UncontrolledTooltip placement="top" target="is-public-icon">
                Show comment to external users (e.g. Hospital Administrators)
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
        </Row>
        {canChooseAssignee(user) && (
          <Fragment>
            <Row className={"my-auto"}>
              <Col xs={3} className={"text-end"}>
                Assign To*
              </Col>
              <Col xs={9}>
                <SimpleRoleSelector
                  value={state.assignTo}
                  clearable={false}
                  searchable={false}
                  onChange={setCurrentAssignee}
                  filter={callUserCanAssignTaskTo}
                />
              </Col>
            </Row>
            {state.assignTo != null &&
              assigneeNeedsUsers(state.assignTo) &&
              !!state.assigneeUsers &&
              state.assigneeUsers.length > 0 && (
                <Fragment>
                  <Alert
                    color={"primary"}
                    className={"my-3 assignee-users-list"}
                  >
                    The following users will be assigned to this data query:
                    <ul>
                      {state.assigneeUsers.map((user, index) => {
                        return <li key={`assignee-user-${index}`}>{user}</li>;
                      })}
                    </ul>
                  </Alert>
                  {!state.isPublic && (
                    <Alert color={"danger"}>
                      The creation comment must be public when creating a data
                      query for an external group
                    </Alert>
                  )}
                </Fragment>
              )}
            {state.assignTo != null &&
              assigneeNeedsUsers(state.assignTo) &&
              !!state.assigneeUsers &&
              state.assigneeUsers.length === 0 && (
                <Alert color={"danger"} className={"my-3"}>
                  At least one external user must be identified as having access
                  to the referenced object before it can be assigned to this
                  external group
                </Alert>
              )}
          </Fragment>
        )}
        {!isEmptyOrNull(state.error) && (
          <Alert color={"danger"} className={"mt-3"}>
            {state.error}
          </Alert>
        )}
        <ButtonBar
          buttons={[
            <Button
              key={"create-button"}
              color={"primary"}
              disabled={!canSubmit()}
              onClick={submit}
            >
              Create
            </Button>,
            <Button key={"cancel-button"} onClick={cancelCallback}>
              Cancel
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

CreateDataQuery.propTypes = {
  type: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.any.isRequired,
  referencedId: PropTypes.number.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  createCallback: PropTypes.func.isRequired,
  crfFieldName: PropTypes.string,
  crfFieldId: PropTypes.number,
  crfType: PropTypes.object,
  crfProcId: PropTypes.number,
};

export default CreateDataQuery;

import { Button, Col, Row } from "reactstrap";
import "./Consent.css";
import { Route, Routes, useNavigate } from "react-router-dom";

import ConsentCard from "./ConsentCard";

const MultiPageConsent = ({
  consents = null,
  index = 0,
  scrollPoint,
  renderMiddleButtonRow,
  renderConsentButtons,
  expandedIndexes,
}) => {
  const navigate = useNavigate();
  return (
    <Routes>
      {consents.map((consent, i) => {
        return (
          <Route
            path={`${i + 1}`}
            key={i}
            element={
              <ConsentCard
                consent={consent}
                showStudyDescriptions={i === 0}
                renderMiddleButtonRow={renderMiddleButtonRow(
                  consent,
                  index + 1,
                )}
                renderConsentButtons={renderConsentButtons}
                scrollPoint={<div ref={scrollPoint} />}
                expandedDescription={!!expandedIndexes.includes(index + 1)}
                backButton={
                  <Row className="back-button-row">
                    <Col xs={6}>
                      <Button
                        outline
                        disabled={index === 0}
                        onClick={() => navigate(`/consent/${index}`)}
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                }
              />
            }
          />
        );
      })}
    </Routes>
  );
};

export default MultiPageConsent;

import { get, post } from "./Api";

const BASE_URL = "/api/data-query";

function url(path) {
  if (path) {
    return `${BASE_URL}/${path}`;
  }
  return `${BASE_URL}`;
}

export function createDataQuery(payload) {
  return post(url(""), payload, "Unable to create Data Query at this time.");
}

export function getDataQuery(id) {
  return get(url(id), null, "Unable to fetch Data Query at this time.");
}

export function updateDataQuery(id, payload) {
  return post(
    url(id),
    payload,
    "Unable to save changes to Data Query at this time.",
  );
}

export function getDataQueryStatuses() {
  return get(
    url("status"),
    null,
    "Unable to get Data Query Statuses at this time.",
  );
}

export function getDataQueryTypes() {
  return get(url("type"), null, "Unable to get Data Query Types at this time.");
}

export function searchDataQueries(payload) {
  return get(
    url("search"),
    payload,
    "Unable to search Data Queries at this time.",
  );
}

export function validatePatients(payload) {
  return post(
    url("validate/patients"),
    payload,
    "Unable to validate patient IDs at this time.",
  );
}

export function validateProcedures(payload) {
  return post(
    url("validate/procedures"),
    payload,
    "Unable to validate procedure IDs at this time.",
  );
}

export function getHospitalAdministrators(payload) {
  return get(
    url("hospital-administrators"),
    payload,
    "Unable to fetch hospital administrators at this time.",
  );
}

export function getHospitalStudyCoordinators(payload) {
  return get(
    url("hospital-study-coordinators"),
    payload,
    "Unable to fetch hospital study coordinators at this time.",
  );
}

export function validateCrfs(payload) {
  return post(
    url("validate/crfs"),
    payload,
    "Unable to validate CRF IDs at this time.",
  );
}

export function validateProcedureCollections(payload) {
  return post(
    url("validate/procedure-collections"),
    payload,
    "Unable to validate Procedure Collection IDs at this time.",
  );
}

import { Button, Col, Label, Row, Table } from "reactstrap";
import { FaLink, FaUnlink } from "react-icons/fa";
import SearchHospitals from "../hospital/SearchHospitals";
import "./UserHospitalLinking.css";
import PropTypes from "prop-types";

const UserHospitalLinking = ({ links = [], readOnly = true, onLinkChange }) => {
  const handleUnlink = (hospital) => {
    let filtered = links.filter((current) => current.id !== hospital.id);
    onLinkChange(filtered);
  };

  const handleLink = (hospital) => {
    links.push(hospital);
    onLinkChange(links);
  };

  return (
    <div>
      <Row>
        <Col sm={3}>
          <Label className={"section-text"}>Linked Hospitals</Label>
        </Col>
        <Col sm={9}>
          <Table striped>
            <tbody>
              {links.length > 0 ? (
                links.map((hospital) => (
                  <tr key={hospital.id}>
                    <td className={"hospital-entry"}>
                      <span className={"hospital-text"}>{hospital.name}</span>
                    </td>
                    <td className={"hospital-entry"}>
                      <span className={"hospital-text"}>
                        {hospital.state.shortName}
                      </span>
                    </td>
                    <td>
                      {!readOnly && (
                        <span className={"unlink-button"}>
                          <Button
                            className={"action-button"}
                            outline
                            title={"Unlink user from hospital"}
                            onClick={() => handleUnlink(hospital)}
                          >
                            <FaUnlink />
                          </Button>
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <em>No linked hospitals set</em>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {!readOnly && (
        <div>
          <hr />
          <Row>
            <Col sm={12}>
              <Label className={"section-text"}>Available Hospitals</Label>
            </Col>
          </Row>
          <SearchHospitals
            excludeIds={links.map(function (hospital) {
              return hospital.id;
            })}
            actionButtons={[
              {
                icon: <FaLink onClick={() => handleLink} />,
                title: "Link user to hospital",
                action: handleLink,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

UserHospitalLinking.propTypes = {
  onLinkChange: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
};

export default UserHospitalLinking;

import { useSelector } from "react-redux";
import { Fragment, useReducer } from "react";
import { getDataQueryFlags } from "../data-query/Utils";
import { isEmptyOrNull } from "../../Utils";
import CreateDataQuery, { TYPE } from "../data-query/CreateDataQuery";
import PropTypes from "prop-types";
import "./CrfType.css";
import { Col, Row } from "reactstrap";
import ViewDataQuery from "../data-query/ViewDataQuery";

const CrfBaseQuestion = ({
  question = null,
  answers = [],
  index = -1,
  crfId,
  crfProcId,
  crfType,
  dataQueries,
  onConfirm,
  crfComponent,
  parseFieldValue,
}) => {
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      rows: 1,
      types: [],
      selectedType: null,
      answers: [],
      errorText: null,
      childCrfs: [],
      showValidationErrors: false,
      createDataQuery: null,
      viewDataQueryId: null,
      fieldName: null,
      fieldValue: null,
    },
  );

  const getDataQueryFlagColumn = (fieldName, fieldValue) => {
    return getDataQueryFlags(
      dataQueries,
      fieldName,
      fieldValue,
      user,
      false,
      (id) => setState({ viewDataQueryId: id }),
      (fieldName, fieldValue) =>
        setState({
          createDataQuery: { fieldName: fieldName, fieldValue: fieldValue },
        }),
    );
  };

  const showCreateDataQueryModal = (question, createDataQuery) => {
    if (createDataQuery === null) {
      return null;
    }
    let thisAnswer = answers.filter(
      (answer) => answer.questionId === question.id,
    );
    let parsedFieldValue;
    if (!isEmptyOrNull(thisAnswer) && !isEmptyOrNull(thisAnswer[0])) {
      parsedFieldValue = parseFieldValue(thisAnswer, question);
    }
    return (
      <div>
        <CreateDataQuery
          crfFieldName={question.name}
          fieldName={createDataQuery.fieldName}
          fieldValue={parsedFieldValue}
          crfFieldId={question.id}
          crfProcId={crfProcId}
          referencedId={crfId}
          type={TYPE.CRF}
          crfType={crfType}
          cancelCallback={() => setState({ createDataQuery: null })}
          createCallback={() => {
            setState({ createDataQuery: null });
            onConfirm("Data query created successfully");
          }}
        />
      </div>
    );
  };

  return (
    <Fragment>
      <Row
        key={`question-flag-row-${question.id}-index-${index}`}
        className={"crf-question g-0"}
      >
        <Col>
          <b>
            {question.name}
            {question.required && "*"}
          </b>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} className={"text-end my-auto"}>
          {!isEmptyOrNull(crfId) &&
            getDataQueryFlagColumn(question.id, question.id)}
        </Col>
      </Row>
      {crfComponent}
      {showCreateDataQueryModal(question, state.createDataQuery)}
      {state.viewDataQueryId != null && (
        <ViewDataQuery
          dataQueryId={state.viewDataQueryId}
          cancelCallback={() => setState({ viewDataQueryId: null })}
          saveCallback={() => {
            setState({ viewDataQueryId: null });
            onConfirm("Data query updated successfully");
          }}
        />
      )}
    </Fragment>
  );
};

CrfBaseQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  dataQueries: PropTypes.arrayOf(PropTypes.object),
  crfId: PropTypes.number,
  onConfirm: PropTypes.func.isRequired,
  crfType: PropTypes.object,
  crfProcId: PropTypes.number,
  crfComponent: PropTypes.element.isRequired,
  parseFieldValue: PropTypes.func.isRequired,
};

export default CrfBaseQuestion;

import { get, post } from "./Api";

const BASE_URL = "/api/reminder";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function getUnassigned(payload) {
  return get(
    url("phone-list/unassigned"),
    payload,
    "Unable to fetch unassigned phone call list.",
  );
}

export function getAssigned(payload) {
  return get(
    url("phone-list/assigned"),
    payload,
    "Unable to fetch assigned phone call list.",
  );
}

export function getAssignedToOthers(payload) {
  return get(
    url("phone-list/assigned-others"),
    payload,
    "Unable to fetch assigned to others phone call list.",
  );
}

export function assignPatients(patientIds) {
  return post(
    url(`phone-list/assign?patientIds=${patientIds}`),
    null,
    "Unable to assign patient(s) at this time.",
  );
}

export function unassignPatients(patientIds) {
  return post(
    url(`phone-list/unassign?patientIds=${patientIds}`),
    null,
    "Unable to unassign patient(s) at this time.",
  );
}

export function getPhoneCallReasons() {
  return get(
    url("phone-list/reasons"),
    null,
    "Unable to fetch phone call reasons at this time.",
  );
}

export function savePhoneCall(payload) {
  return post(
    url("phone-list/phone-call"),
    payload,
    "Unable to save phone call at this time.",
  );
}

export function getPhoneCalls(patientId) {
  return get(
    url(`phone-list/phone-calls?patientId=${patientId}`),
    null,
    "Unable to fetch phone calls at this time.",
  );
}

export function isAutomatedRemindersRunning() {
  return get(
    url("automated"),
    null,
    "Unable to determine if automated reminders are running.",
  );
}

export function runAutomatedReminders(reminderTypeId) {
  return post(
    url(`automated?reminderTypeId=${reminderTypeId}`),
    null,
    "Unable to run automated reminders.",
  );
}

export function getReminderTypes() {
  return get(url("types"), null, "Unable to load reminder types at this time.");
}

import { Button, Table } from "reactstrap";
import "./Search.css";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { loadStudies } from "../../actions/CommonActions";
import { useEffect } from "react";

const Search = ({
  actionButtons = [],
  excludeIds = [],
  showDescription = false,
  showActive = false,
}) => {
  const dispatch = useDispatch();
  const studies = useSelector((state) => state.studies);

  useEffect(() => {
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  return (
    <div>
      <Table striped className={"search-table"}>
        <thead>
          <tr>
            <th>Name</th>
            {showDescription && <th>Description</th>}
            {showActive && <th>Active</th>}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {studies.filter((study) => !excludeIds.includes(study.id)).length >
          0 ? (
            studies
              .filter((study) => !excludeIds.includes(study.id))
              .map((study) => (
                <tr key={"study-key-" + study.id}>
                  <td className={"study-entry"}>
                    <span className={"study-text"}>{study.name}</span>
                  </td>
                  {showDescription && (
                    <td className={"study-entry"}>
                      <span className={"study-text"}>{study.description}</span>
                    </td>
                  )}
                  {showActive && (
                    <td className={"study-entry"}>
                      <span className={"study-text"}>
                        {study.active ? "Yes" : "No"}
                      </span>
                    </td>
                  )}
                  <td>
                    <span className={"action-buttons"}>
                      {actionButtons.map((button) => (
                        <Button
                          key={"study-" + study.id}
                          id={"study-" + study.id}
                          className={"action-button"}
                          outline
                          title={button.title}
                          onClick={() => button.action(study)}
                        >
                          {button.icon}
                        </Button>
                      ))}
                    </span>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td>
                <em>No studies to display.</em>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

Search.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ),
  excludeIds: PropTypes.arrayOf(PropTypes.number),
  showDescription: PropTypes.bool,
  showActive: PropTypes.bool,
};

export default Search;

import { handleActions } from "redux-actions";
import {
  statesLoaded,
  statesWithHospitalsLoaded,
} from "../actions/CommonActions";

const INITIAL_STATE = { all: [], withHospitals: [] };

export default handleActions(
  {
    [statesLoaded]: (state, action) => ({
      ...state,
      ...{
        all: [...action.payload.sort((a, b) => a.name.localeCompare(b.name))],
      },
    }),
    [statesWithHospitalsLoaded]: (state, action) => ({
      ...state,
      ...{
        withHospitals: [
          ...action.payload.sort((a, b) => a.name.localeCompare(b.name)),
        ],
      },
    }),
  },
  INITIAL_STATE,
);

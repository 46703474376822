import { Button, Col, Label, Row, Table } from "reactstrap";
import { FaLink, FaUnlink } from "react-icons/fa";
import SearchHospitalStudies from "./SearchHospitalStudies";
import "./UserHospitalLinking.css";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { loadHospitals } from "../../../actions/CommonActions";
import { isEmptyOrNull } from "../../../Utils";
import { useEffect } from "react";

const UserHospitalStudyLinking = ({
  hospitalStudyLinks = [],
  readOnly = true,
  onLinkChange,
  userId,
}) => {
  const dispatch = useDispatch();
  const hospitals = useSelector((state) => state.hospitals);
  const studies = useSelector((state) => state.studies);

  useEffect(() => {
    isEmptyOrNull(hospitals.length) && dispatch(loadHospitals());
  }, [dispatch]);

  const handleLink = (hospital, study) => {
    // if hospitalStudyLinks does not contain hospital/study then add it
    if (
      hospitalStudyLinks.filter(
        (l) => l.hospitalId === hospital.id && l.studyId === study.studyId,
      ).length > 0
    ) {
      return;
    }
    let newLinks = hospitalStudyLinks.slice();
    newLinks.push({
      hospitalId: hospital.id,
      studyId: study.studyId,
      userId: userId,
    });
    onLinkChange(newLinks);
  };

  const handleUnlink = (hospitalStudy) => {
    let filtered = hospitalStudyLinks.filter(
      (hsl) =>
        !(
          hsl.hospitalId === hospitalStudy.hospitalId &&
          hsl.studyId === hospitalStudy.studyId
        ),
    );
    onLinkChange(filtered);
  };

  return (
    <div>
      <Row>
        <Col sm={3}>
          <Label className={"section-text"}>Linked Hospital Studies</Label>
        </Col>
        <Col sm={9}>
          <Table striped>
            <tbody>
              {hospitalStudyLinks.length > 0 ? (
                hospitalStudyLinks.map((hospitalStudy) => (
                  <tr
                    key={`${hospitalStudy.hospitalId}-${hospitalStudy.studyId}`}
                  >
                    <td className={"hospital-entry"}>
                      <span className={"hospital-text"}>
                        {
                          hospitals.find(
                            (h) => h.id === hospitalStudy.hospitalId,
                          ).name
                        }
                      </span>
                    </td>
                    <td className={"hospital-entry"}>
                      <span className={"hospital-text"}>
                        {
                          hospitals.find(
                            (h) => h.id === hospitalStudy.hospitalId,
                          ).state.shortName
                        }
                      </span>
                    </td>
                    <td className={"hospital-entry"}>
                      <span className={"hospital-text"}>
                        {
                          studies.find((s) => s.id === hospitalStudy.studyId)
                            .name
                        }
                      </span>
                    </td>
                    <td>
                      {!readOnly && (
                        <span className={"unlink-button"}>
                          <Button
                            className={"action-button"}
                            outline
                            title={"Unlink user from a hospital study"}
                            onClick={() => handleUnlink(hospitalStudy)}
                          >
                            <FaUnlink />
                          </Button>
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <em>No linked hospital studies set</em>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {!readOnly && (
        <div>
          <hr />
          <Row>
            <Col sm={12}>
              <Label className={"section-text"}>
                Available Hospital Studies
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <SearchHospitalStudies
                excludeLinks={hospitalStudyLinks}
                actionButtons={[
                  {
                    icon: <FaLink onClick={() => handleLink} />,
                    title: "Link user to hospital Study",
                    action: handleLink,
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

UserHospitalStudyLinking.propTypes = {
  onLinkChange: PropTypes.func.isRequired,
  hospitalStudyLinks: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
};

export default UserHospitalStudyLinking;

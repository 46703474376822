import { del, get } from "./Api";

const BASE_URL = "/api/procedure";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function getAllJoints() {
  return get(
    url(`joint/all`),
    null,
    "Unable to get Joint details for the Patient at this time.",
  );
}

export function getAllProcedureTypes() {
  return get(
    url("procedure-type/all"),
    null,
    "Unable to get Procedure Type details for the Patient at this time.",
  );
}

export function getAllSides() {
  return get(
    url(`side/all`),
    null,
    "Unable to get Side details for the Patient at this time.",
  );
}

export function deleteProcedure(id) {
  return del(url(`${id}`), "Unable to delete procedure with id: " + id);
}

export function getProcedureReminders(id) {
  return get(
    url(`${id}/reminders`),
    null,
    "Unable to fetch reminders for Procedure at this time",
  );
}

export function getUnmatchedCount() {
  return get(
    url(`unmatched/count`),
    null,
    "Unable to get unmatched procedure count at this time.",
  );
}

export function getUnmatchedPromsProcedures(payload) {
  return get(
    url(`unmatched-procedures`),
    payload,
    "Unable to get unmatched proms procedure at this time.",
  );
}

export function deleteProcedureCollections(id, procedureCollectionIds) {
  return del(
    url(`${id}/procedure-collection?ids=${procedureCollectionIds.join()}`),
    "Unable to delete procedureCollection with  procedureId: " + id,
  );
}

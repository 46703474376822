import * as Types from "../actions/Types";
import {
  allPositionsLoaded,
  allRolesLoaded,
  allTextLoaded,
  graphTypesLoaded,
  instrumentBinsLoaded,
  instrumentReportsLoaded,
  subscoreTypesLoaded,
} from "../actions/InstrumentReportActions";
import {
  addRole,
  allGraphTypes,
  allInstrumentBins,
  allInstrumentReports,
  allPositions,
  allRoles,
  allText,
  changeReportIndex,
  createReport,
  editReport,
  loadSubscores,
  removeRole,
  reportDelete,
  reportDuplicate,
  screenDelete,
  screenDuplicate,
  seriesDelete,
} from "../api/InstrumentReport";
import Configuration from "../components/compare/Configuration";

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type === Types.LOAD_ALL_INSTRUMENT_REPORTS) {
      allInstrumentReports(
        getState().instrumentReports.viewingRole.roleId,
      ).then((response) => {
        dispatch(instrumentReportsLoaded(response.data));
      });
    }

    if (action.type === Types.SWITCH_REPORT_ROLE) {
      allInstrumentReports(action.payload.roleId).then((response) => {
        dispatch(instrumentReportsLoaded(response.data));
      });
    }

    if (action.type === Types.LOAD_ALL_GRAPH_TYPES) {
      allGraphTypes().then((response) => {
        const { data } = response;
        dispatch(graphTypesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_ALL_INSTRUMENT_BINS) {
      allInstrumentBins().then((response) => {
        const { data } = response;
        dispatch(instrumentBinsLoaded(data));
      });
    }

    if (action.type === Types.LOAD_SUBSCORE_TYPES) {
      loadSubscores().then((response) => {
        const { data } = response;
        dispatch(subscoreTypesLoaded(data));
      });
    }

    if (action.type === Types.LOAD_ALL_ROLES) {
      allRoles().then((response) => {
        response.data.unshift({ ...Configuration.DUMMY_PATIENT_ROLE });
        dispatch(allRolesLoaded(response.data));
      });
    }

    if (action.type === Types.LOAD_ALL_TEXT) {
      allText().then((response) => {
        dispatch(allTextLoaded(response.data));
      });
    }

    if (action.type === Types.LOAD_ALL_POSITIONS) {
      allPositions().then((response) => {
        dispatch(allPositionsLoaded(response.data));
      });
    }

    // REPORT WORK

    if (action.type === Types.NEW_REPORT) {
      const report = {
        ...NEW_REPORT,
        index: action.payload.index,
        roles: [
          {
            ...getState().instrumentReports.viewingRole,
            index: null,
            id: null,
          },
        ],
      };
      createReport(report).then((response) =>
        dispatch(instrumentReportsLoaded(response.data)),
      );
    }

    if (action.type === Types.DELETE_REPORT) {
      const { report } = action.payload;
      reportDelete(
        report,
        getState().instrumentReports.viewingRole.roleId,
        dispatch,
      ).then((response) =>
        dispatch(instrumentReportsLoaded([...response.data])),
      );
    }

    if (action.type === Types.DUPLICATE_REPORT) {
      const { id } = action.payload;
      reportDuplicate(id, getState().instrumentReports.viewingRole.roleId).then(
        (response) => dispatch(instrumentReportsLoaded([...response.data])),
      );
    }

    if (action.type === Types.UPDATE_REPORT) {
      const { report } = action.payload;
      saveReports(
        [report],
        getState().instrumentReports.viewingRole.roleId,
        dispatch,
      );
    }

    if (action.type === Types.REPORT_INDEX_CHANGE) {
      const { report, newIndex } = action.payload;
      const roleId = getState().instrumentReports.viewingRole.roleId;
      changeReportIndex(report.id, newIndex, roleId).then((response) =>
        dispatch(instrumentReportsLoaded([...response.data])),
      );
    }

    if (action.type === Types.ADD_REPORT_ROLE) {
      const { reportId, role } = action.payload;
      addRole(reportId, role, getState().instrumentReports.viewingRole.id).then(
        (response) => dispatch(instrumentReportsLoaded(response.data)),
      );
    }

    if (action.type === Types.REMOVE_REPORT_ROLE) {
      const { reportId, role } = action.payload;
      removeRole(
        reportId,
        role,
        getState().instrumentReports.viewingRole.id,
      ).then((response) => dispatch(instrumentReportsLoaded(response.data)));
    }

    // SCREEN WORK

    if (action.type === Types.NEW_SCREEN) {
      const { report, index } = action.payload;
      let bin = getState().instrumentReports.bins[0];
      report.screens.push({
        ...NEW_SCREEN,
        index: index,
        bin: bin,
      });
      saveReports(
        [report],
        getState().instrumentReports.viewingRole.roleId,
        dispatch,
      );
    }

    if (action.type === Types.DELETE_SCREEN) {
      const { screen } = action.payload;
      screenDelete(
        screen,
        getState().instrumentReports.viewingRole.roleId,
      ).then((response) =>
        dispatch(instrumentReportsLoaded([...response.data])),
      );
    }

    if (action.type === Types.SCREEN_INDEX_CHANGE) {
      const { reportId, screen, delta } = action.payload;
      let oldIndex = screen.index;
      let newIndex = oldIndex + delta;
      const report = findReport(
        [...getState().instrumentReports.reports],
        reportId,
      );
      if (newIndex !== -1 && newIndex !== report.screens.length) {
        let switched = switchElements(oldIndex, newIndex, report.screens);
        report.screens.splice(Math.min(oldIndex, newIndex), 2, ...switched);
        saveReports(
          [report],
          getState().instrumentReports.viewingRole.roleId,
          dispatch,
        );
      }
    }

    if (action.type === Types.UPDATE_SCREEN) {
      const { reportId, screen } = action.payload;
      const reports = [...getState().instrumentReports.reports];
      const report = findReport(reports, reportId);
      report.screens.splice(screen.index, 1, screen);
      saveReports(
        [report],
        getState().instrumentReports.viewingRole.roleId,
        dispatch,
      );
    }

    if (action.type === Types.DUPLICATE_SCREEN) {
      const { reportId, screen } = action.payload;
      const report = findReport(
        [...getState().instrumentReports.reports],
        reportId,
      );
      screenDuplicate(
        report.id,
        screen.id,
        getState().instrumentReports.viewingRole.roleId,
      ).then((response) =>
        dispatch(instrumentReportsLoaded([...response.data])),
      );
    }

    // SERIES WORK

    if (action.type === Types.NEW_SERIES) {
      const { screen, reportId } = action.payload;
      const report = findReport(
        [...getState().instrumentReports.reports],
        reportId,
      );
      screen.series.push({
        ...NEW_SERIES,
        index: screen.series.length,
      });
      saveReports(
        [report],
        getState().instrumentReports.viewingRole.roleId,
        dispatch,
      );
    }

    if (action.type === Types.SERIES_INDEX_CHANGE) {
      const { reportId, screenId, series, delta } = action.payload;
      let oldIndex = series.index;
      let newIndex = oldIndex + delta;
      const report = findReport(
        [...getState().instrumentReports.reports],
        reportId,
      );
      const screen = report.screens.filter((s) => s.id === screenId)[0];
      if (newIndex !== -1 && newIndex !== screen.series.length) {
        let switched = switchElements(oldIndex, newIndex, screen.series);
        screen.series.splice(Math.min(oldIndex, newIndex), 2, ...switched);
        saveReports(
          [report],
          getState().instrumentReports.viewingRole.roleId,
          dispatch,
        );
      }
    }

    if (action.type === Types.UPDATE_SERIES) {
      const { series, reportId, screenId } = action.payload;
      const report = findReport(
        [...getState().instrumentReports.reports],
        reportId,
      );
      const screen = report.screens.filter((s) => s.id === screenId)[0];
      screen.series.splice(series.index, 1, series);
      saveReports(
        [report],
        getState().instrumentReports.viewingRole.roleId,
        dispatch,
      );
    }

    if (action.type === Types.DELETE_SERIES) {
      const { series } = action.payload;
      seriesDelete(
        series,
        getState().instrumentReports.viewingRole.roleId,
      ).then((response) =>
        dispatch(instrumentReportsLoaded([...response.data])),
      );
    }

    return next(action);
  };

function saveReports(reports, roleId, dispatch) {
  editReport(reports, roleId).then((response) =>
    dispatch(instrumentReportsLoaded(response.data)),
  );
}

function switchElements(oldIndex, newIndex, array) {
  let start = Math.min(oldIndex, newIndex);
  let end = Math.max(oldIndex, newIndex);
  let sliced = [...array.slice(start, end + 1)];
  sliced[0].index = end;
  sliced[1].index = start;
  return sliced;
}

function findReport(reports, id) {
  return reports.filter((r) => r.id === id)[0];
}

const NEW_REPORT = {
  id: null,
  index: null,
  name: "",
  description: "",
  screens: [],
  roles: [],
};

const NEW_SCREEN = {
  id: null,
  index: null,
  name: "",
  description: "",
  series: [],
  showYAxis: false,
  clusterGap: false,
  barGap: 1,
  tooltip: true,
  stacked: false,
  verticalLines: null,
  titleYAxis: null,
  titleXAxis: null,
};

const NEW_SERIES = {
  id: null,
  index: null,
  instrumentId: 7,
  instrumentName: "EQ-5D-5L",
  instrumentScoring: "QUESTION",
  questionId: 13,
  questionName: "MOBILITY",
  subscore: null,
  studyCollectionId: 1,
  studyCollectionName: "PROMs Pre-Operative",
  baselineComparisonText: null,
  baselineNoComparisonText: null,
  baselineSeriesType: {
    id: 6,
    name: "Not Displayed",
  },
  comparisonText: null,
  comparisonSeriesType: {
    id: 6,
    name: "Not Displayed",
  },
  baselineLegendText: null,
  comparisonLegendText: null,
  comparisonPersonText: null,
  position: {
    id: 1,
    name: "Middle",
  },
  baselineShowPercent: true,
  comparisonShowPercent: true,
  baselineShowLegend: true,
  comparisonShowLegend: false,
  baselineLineColour: null,
  baselineFillColour: null,
  comparisonLineColour: null,
  comparisonFillColour: null,
  baselineUseProcedureTypeColour: true,
  comparisonUseProcedureTypeColour: true,
  combineLegend: false,
  rightToLeft: false,
  baselineLineMarks: false,
  comparisonLineMarks: false,
  difference: false,
  comparisonFallback: false,
};

import { Alert, Button, Col, Container, Row } from "reactstrap";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { hasResponses } from "../../api/Surgeon";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SurgeonDownload = () => {
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(null);

  useEffect(() => {
    dispatch(startLoading());
    hasResponses()
      .then((response) => {
        setEnabled(response.data);
      })
      .catch(() => setEnabled(false))
      .finally(() => dispatch(finishLoading()));
  }, []);

  const disabled = enabled == null || !enabled;
  return (
    <Container>
      <Row>
        <Col>
          <h2>Download patient response data</h2>
        </Col>
      </Row>
      <Row className={"field-row"}>
        <Col>
          Click on the button below to download your patient response data in
          spreadsheet form.
        </Col>
      </Row>
      {disabled && (
        <Alert color={"primary"}>
          Download unavailable, you currently have no patient responses
        </Alert>
      )}
      <Row>
        <Col>
          <Button
            color={"primary"}
            href={`/api/surgeon/download`}
            target="_blank"
            disabled={disabled}
          >
            Download Data
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SurgeonDownload;

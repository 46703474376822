import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Responsive from "react-responsive";
import { Col, Label, Row, Table } from "reactstrap";
import { loadUserCurrent } from "../../../actions/UserActions";
import PropTypes from "prop-types";
import { getStakeholderGroup } from "../../../api/StakeholderGroup";
import { getHospitals } from "../../../api/Hospital";
import { loadHospitals } from "../../../actions/CommonActions";
import "./StakeholderGroupHospitals.css";
import { CREATE } from "../../../Utils";

const StakeholderGroupHospitals = ({ stakeholderGroupId, operation }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [hospitalLinks, setHospitalLinks] = useState([]);

  useEffect(() => {
    !user.found && dispatch(loadUserCurrent());
  }, [dispatch]);

  useEffect(() => {
    if (operation !== CREATE && !hospitalLinks.length > 0) {
      getStakeholderGroup(stakeholderGroupId).then((response) => {
        let hospitalIdsToShow = [];
        // We should indicate that the list of hospitals is stale - so let's make sure our redux state is current
        dispatch(loadHospitals());
        // But we also have to keep going, so we'll do the call directly and set the links in a callback
        getHospitals().then((innerResponse) => {
          let fresh = innerResponse.data;
          for (const incomingHospital of response.data.hospitalIds) {
            hospitalIdsToShow.push(
              fresh.filter((hospital) => hospital.id === incomingHospital)[0],
            );
          }
          setHospitalLinks(
            hospitalIdsToShow.sort((a, b) => a.name.localeCompare(b.name)),
          );
        });
      });
    }
  }, []);

  const renderHospitalOption = (hospital) => {
    return `${hospital.name} (${hospital.state.shortName})`;
  };

  const getHospitalsTable = () => {
    return (
      <Fragment>
        <Responsive maxWidth={767}>
          <Table striped>
            <tbody>
              {hospitalLinks.map((hospital, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Row className={"g-0"}>
                        <Col xs={7}>{renderHospitalOption(hospital)}</Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={768}>
          <Table striped>
            <tbody>
              {hospitalLinks.map((hospital, i) => {
                return (
                  <tr key={i}>
                    <td>{renderHospitalOption(hospital)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
      </Fragment>
    );
  };

  return (
    <div className={"stakeholder-group-hospitals"}>
      <Row>
        <Col xs={3} md={3} lg={3} sm={12}>
          <Label className={"section-text"}>Linked Hospitals</Label>
        </Col>
        <Col xs={9} md={9} lg={9} sm={12}>
          {hospitalLinks.length > 0 ? (
            getHospitalsTable()
          ) : (
            <Table striped>
              <tbody>
                <tr>
                  <td>
                    <em>No linked hospitals set</em>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </div>
  );
};

StakeholderGroupHospitals.propTypes = {
  stakeholderGroupId: PropTypes.number,
  operation: PropTypes.string.isRequired,
};

export default StakeholderGroupHospitals;

import { useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

/** Run an effect hook NOT on the first render */
export const useOnUpdate = (callback, deps) => {
  const isFirst = useRef(true);
  useEffect(() => {
    if (!isFirst.current) {
      callback();
    }
  }, deps);

  useEffect(() => {
    isFirst.current = false;
  }, []);
};

/**
 * Store and access previous value of a variable between state updates
 *
 * @param value the variable to track the previous value of
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/** Sends a Google Analytics pageview event based on the current location */
export const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.set({ hitType: "pageview", page: location.pathname });
  }, [location]);
};

import PropTypes from "prop-types";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import Moment from "moment";

const dateFormat = "YYYY-MM-DD";
const dateFormatTwo = "DD/MM/YYYY";

const ConfirmPastSchdProcDateModal = ({
  confirmCallback,
  dateToConfirm,
  showModal,
}) => {
  return (
    <Modal isOpen={showModal}>
      <ModalBody>
        <div>
          <div>
            <h5>Procedure date is in the past</h5>
            <p>
              {" "}
              You are about to enter a procedure date which is in the past.{" "}
            </p>
            <div align="center">
              <b>
                {Moment(dateToConfirm, dateFormat, true).format(dateFormatTwo)}
              </b>
            </div>
            <p />
            <p>Please confirm that this is correct.</p>
          </div>
        </div>
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <Button
              className={"float-left"}
              onClick={() => confirmCallback(false)}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} />
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <Button
              color="primary"
              className={"float-right"}
              onClick={() => confirmCallback(true)}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

ConfirmPastSchdProcDateModal.propTypes = {
  confirmCallback: PropTypes.func.isRequired,
  dateToConfirm: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default ConfirmPastSchdProcDateModal;

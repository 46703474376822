import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { getStartCase, isEmptyOrNull } from "../../Utils";
import { getPhoneCallReasons, savePhoneCall } from "../../api/Reminder";
import { useDispatch, useSelector } from "react-redux";
import {
  finishLoading,
  loadStudies,
  startLoading,
} from "../../actions/CommonActions";
import ButtonBar from "../common/ButtonBar";
import { getProcedures } from "../../api/Patient";
import GenericSelector from "../common/GenericSelector";
import { useEffect, useReducer } from "react";

const LogAdHocCall = ({ cancelCallback, patientId, saveCallback }) => {
  const dispatch = useDispatch();
  const studies = useSelector((state) => state.studies);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedProcedures: [],
      selectedCollection: null,
      procedures: [],
      reasons: [],
      reason: null,
      reasonFreeText: "",
      details: "",
      errorText: "",
      spokeWithPatient: false,
    },
  );

  useEffect(() => {
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useEffect(() => {
    dispatch(startLoading());
    getPhoneCallReasons().then((response) => {
      setState({ reasons: response.data });
    });

    getProcedures(patientId)
      .then((response) =>
        setState({
          procedures: response.data,
          selectedProcedures: response.data.length === 1 ? response.data : [],
        }),
      )
      .finally(() => dispatch(finishLoading()));
  }, []);

  const selectReason = (reason) => {
    setState({ reason: reason });
  };

  const renderProcedure = (procedure) => {
    return `${getStartCase(procedure.procedureName)} (ID: ${
      procedure.procedureId
    })`;
  };

  const renderCollection = (collection) => {
    if (collection == null) {
      return null;
    }
    let study = null;
    let studyIndex = studies.findIndex((s) => s.id === collection.studyId);
    if (studyIndex >= 0) {
      study = studies[studyIndex];
    } else {
      return collection.name;
    }

    return `${study.name} ${collection.name}`;
  };

  const formIsValid = () => {
    if (
      isEmptyOrNull(state.details) ||
      state.selectedProcedures.length === 0 ||
      state.selectedCollection == null
    ) {
      return false;
    }
    if (state.reason == null) {
      return true;
    }
    return !(state.reason.freeText && isEmptyOrNull(state.reasonFreeText));
  };

  const updateSelectedProcedures = (procedures) => {
    setState({
      selectedProcedures: procedures == null ? [] : procedures,
      selectedCollection: null,
    });
  };

  const getCollections = () => {
    const studyIds = new Set();
    state.selectedProcedures.forEach((procedure) =>
      procedure.studyIds.forEach((studyId) => studyIds.add(studyId)),
    );
    let collections = [];
    studies
      .filter((s) => [...studyIds].includes(s.id))
      .forEach((study) => collections.push(...study.studyCollections));
    collections = collections.reduce(
      (accumulator, item) =>
        accumulator.map((coll) => coll.id).includes(item.id)
          ? accumulator
          : [...accumulator, item],
      [],
    );
    return collections;
  };

  const handleSave = () => {
    const procedureStudyCollections = {};
    state.selectedProcedures.forEach((procedure) => {
      procedureStudyCollections[procedure.procedureId] = [
        state.selectedCollection.id,
      ];
    });

    if (formIsValid()) {
      setState({ errorText: "" });
      dispatch(startLoading());
      let payload = {
        patientId: patientId,
        phoneCallReasonId: state.reason == null ? null : state.reason.id,
        details: state.details,
        recordReminder: false, // Hardcoded as this is outside the call list system
        otherReason:
          !!state.reason && state.reason.freeText ? state.reasonFreeText : null,
        procedureStudyCollections: procedureStudyCollections,
        spokeWithPatient: state.spokeWithPatient,
      };
      savePhoneCall(payload)
        .then(() => saveCallback())
        .finally(() => dispatch(finishLoading()));
    }
  };

  return (
    <Modal isOpen={true} size={"lg"} className={"log-adhoc-call"}>
      <ModalHeader>Log Phone Call - Patient ID: {patientId}</ModalHeader>
      <ModalBody>
        <Row className={"field-row"}>
          <Col xs={4} className={"my-auto"}>
            Procedure(s)*
          </Col>
          <Col>
            <GenericSelector
              isMulti={true}
              selected={state.selectedProcedures}
              options={state.procedures}
              renderer={renderProcedure}
              searchable={false}
              idPropertyName={"procedureId"}
              labelPropertyName={"procedureName"}
              changeCallback={updateSelectedProcedures}
            />
          </Col>
        </Row>
        {state.selectedProcedures.length > 0 && (
          <Row className={"field-row"}>
            <Col xs={4} className={"my-auto"}>
              Collection*
            </Col>
            <Col>
              <GenericSelector
                isMulti={false}
                selected={state.selectedCollection}
                options={getCollections()}
                renderer={renderCollection}
                searchable={false}
                changeCallback={(value) =>
                  setState({ selectedCollection: value })
                }
              />
            </Col>
          </Row>
        )}
        <Row className={"field-row"}>
          <Col xs={4}>
            Details* <br />
            <i>(e.g. home phone rang - no answer, left message, etc.)</i>
          </Col>
          <Col>
            <Input
              type={"textarea"}
              rows={6}
              value={state.details}
              onChange={(event) => setState({ details: event.target.value })}
            />
          </Col>
        </Row>
        <Row className={"field-row"}>
          <Col xs={4} className={"my-auto"}>
            <Label for="reasons" className={"form-label"}>
              Reason follow-up required
            </Label>
          </Col>
          <Col>
            <GenericSelector
              options={state.reasons}
              changeCallback={selectReason}
              selected={state.reason}
            />
          </Col>
        </Row>
        <Row className={"field-row"}>
          <Col xs={4} className={"my-auto"} />
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name={"spokeWithPatient"}
                  onChange={() =>
                    setState({ spokeWithPatient: !state.spokeWithPatient })
                  }
                  checked={state.spokeWithPatient}
                />
                {" Spoke With Patient"}
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {state.reason != null && state.reason.freeText === true && (
          <Row className={"field-row"}>
            <Col xs={4} className={"my-auto"}>
              <Label for="reasons" className={"form-label"}>
                Please specify*
              </Label>
            </Col>
            <Col>
              <Input
                type={"text"}
                value={state.reasonFreeText}
                onChange={(event) =>
                  setState({ reasonFreeText: event.target.value })
                }
              />
            </Col>
          </Row>
        )}
        <ButtonBar
          buttons={[
            <Button
              color={"primary"}
              disabled={!formIsValid()}
              onClick={handleSave}
            >
              Save
            </Button>,
            <Button color={"secondary"} onClick={cancelCallback}>
              Cancel
            </Button>,
          ]}
        />
      </ModalBody>
      {!isEmptyOrNull(state.errorText) && (
        <ModalFooter>
          <Alert color={"danger"}>{state.errorText}</Alert>
        </ModalFooter>
      )}
    </Modal>
  );
};

LogAdHocCall.propTypes = {
  patientId: PropTypes.number.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
};

export default LogAdHocCall;

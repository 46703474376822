import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import PropTypes from "prop-types";
import "./FuzzyMatchingModal.css";
import ButtonBar from "../common/ButtonBar";
import { addNeverMatch } from "../../api/Matching";
import { toast } from "react-toastify";
import { isEmptyOrNull } from "../../Utils";
import { apiErrorResponse } from "./Utils";
import { useState } from "react";

const CreateNeverMatchModal = ({
  onExit = () => null,
  onSave = () => null,
  open = false,
}) => {
  const [sourceId, setSourceId] = useState("");
  const [procedureId, setProcedureId] = useState("");

  const saveNeverMatch = () => {
    let payload = {
      sourceId: isEmptyOrNull(sourceId) ? null : sourceId,
      procedureId: isEmptyOrNull(procedureId) ? null : procedureId,
    };
    addNeverMatch(payload)
      .then((response) => {
        if (response.data) {
          toast.success("Never match saved");
          onSave();
          onExit();
        }
      })
      .catch((error) => {
        let msg =
          "An error occurred while saving the never match. Please try again.";
        apiErrorResponse(error, msg);
        toast.error(msg);
      });
  };

  const getButtons = () => {
    const buttons = [];
    buttons.push(
      <Button onClick={onExit}>Cancel</Button>,
      <Button
        color="primary"
        onClick={saveNeverMatch}
        disabled={isEmptyOrNull(sourceId)}
      >
        Save
      </Button>,
    );
    return buttons;
  };

  return (
    <Modal
      isOpen={open}
      toggle={onExit}
      className={"create-never-matches-modal"}
      keyboard={false}
    >
      <ModalHeader toggle={onExit}>Create new Never Match</ModalHeader>
      <ModalBody>
        <FormGroup row className={"my-3"}>
          <Label for="sourceId" sm={3} className={"text-end"}>
            Source ID*
          </Label>
          <Col sm={8}>
            <Input
              type="text"
              name="sourceId"
              id="sourceId"
              value={sourceId}
              onChange={(event) =>
                setSourceId(event.target.value.replace(/\D/g, ""))
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row className={"my-3"}>
          <Label for="procedureId" sm={3} className={"text-end"}>
            PROMs ID
          </Label>
          <Col sm={8}>
            <Input
              type="text"
              name="procedureId"
              id="procedureId"
              value={procedureId}
              onChange={(event) =>
                setProcedureId(event.target.value.replace(/\D/g, ""))
              }
            />
          </Col>
        </FormGroup>
        <ButtonBar buttons={getButtons()} />
      </ModalBody>
    </Modal>
  );
};

CreateNeverMatchModal.propTypes = {
  onExit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreateNeverMatchModal;

import { get, put, del } from "./Api";

const BASE_URL = "/api/announcement";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function showAnnouncements() {
  return get(url("show"), null);
}

export function getAllAnnouncements() {
  return get(
    url("admin/all"),
    null,
    "Unable to check for Announcement details at this time.",
  );
}

export function deleteAnnouncement(id) {
  return del(
    url(`delete/${id}`),
    "Unable to delete Announcement details at this time.",
  );
}

export function createAnnouncement(request) {
  return put(
    url("create"),
    request,
    "Unable to create Announcement at this time.",
  );
}

import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";

const HandleTooltip = (props) => {
  const { value, children, visible, tipFormatter = (val) => val } = props;
  return (
    <Tooltip
      placement="left"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: "auto" }}
      visible={visible}
    >
      {children}
    </Tooltip>
  );
};
export const handleRender = (node, props) => {
  return (
    <HandleTooltip value={props.value} visible={props.dragging}>
      {node}
    </HandleTooltip>
  );
};

import { Fragment, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { FaBan, FaCheck, FaEye, FaTrash, FaUnlock } from "react-icons/fa";
import "./Responses.css";
import ResponsesView from "./ResponsesView";
import Responsive from "react-responsive";
import { useSelector } from "react-redux";
import {
  isAdministratorUser,
  isAoaUser,
  isEmptyOrNull,
  isFollowUpUser,
  isHospitalAdministratorUser,
  isHospitalStudyCoordinatorUser,
  isSahmriUser,
  isSurgeonUser,
} from "../../Utils";
import ScoredInstruments from "./ScoredInstruments";
import ConfirmDeleteProcedureCollectionModal from "./ConfirmDeleteProcedureCollectionModal";
import ButtonBar from "../common/ButtonBar";
import { unlockPatientResponses } from "../../api/Patient";

const CompletedCollections = ({
  previousResponses = [],
  modal = true,
  isOpen = false,
  toggle = () => null,
  patientView = true,
  onConfirm,
}) => {
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      responses: [],
      viewResponses: false,
      viewInstruments: false,
      procedure: null,
      showDeleteDialog: false,
      reason: "",
      deleteProcedureCollections: null,
      deleteProcedureId: null,
      index: null,
      unlockProcedureCollectionId: null,
      hideUnlockIcon: false,
      instruments: [],
    },
  );

  useEffect(() => {
    modal && document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [state.viewResponses]);

  const handleSuccess = (message) => {
    setState({
      deleteProcedureId: null,
      deleteProcedureCollections: null,
    });
    onConfirm(message);
  };

  const handleUnlockProcedureCollection = (unlockProcedureCollectionId) => {
    unlockPatientResponses(unlockProcedureCollectionId)
      .then(() => {
        setState({
          hideUnlockIcon: true,
        });
        onConfirm("Patient Responses unlocked successfully");
      })
      .catch((error) => {
        let message = "Error Unlocking the responses for the Questionnaire";
        setState({
          error: message + error.message,
        });
      });
  };

  const showProcedureCollectionIds = () => {
    return isSahmriUser(user) || isAoaUser(user);
  };

  const handleKeyPress = (e) => {
    // Escape
    if (e.keyCode === 27) {
      toggle();
    }
  };

  const showResults = (idx) => {
    if (patientView) {
      let questions = [];
      previousResponses[idx].instrumentScores.forEach((instrument) => {
        instrument.responses.forEach((question) => {
          questions.push({ ...question });
        });
      });
      setState({
        responses: questions,
        viewResponses: true,
        procedure: previousResponses[idx].procedure,
        procedureCollectionId: previousResponses[idx].procedureCollectionId,
      });
    } else {
      setState({
        responses: [],
        instruments: previousResponses[idx].instrumentScores,
        viewInstruments: true,
        procedure: previousResponses[idx].procedure,
        procedureCollectionId: previousResponses[idx].procedureCollectionId,
      });
    }
  };

  const handleCancelDeleteProcedureCollection = () => {
    setState({ deleteProcedureCollections: null, deleteProcedureId: null });
  };

  const toggleResultView = () => {
    setState({
      responses: [],
      viewResponses: false,
      procedure: null,
    });
  };

  const toggleInstrumentView = () => {
    setState({
      instruments: [],
      viewInstruments: false,
      procedure: null,
    });
  };

  const canSeeNumberOfAnswers = (user) => {
    return isSahmriUser(user) || isAdministratorUser(user) || isAoaUser(user);
  };

  const getTable = () => {
    let label = isSahmriUser(user) ? "Action" : "Summary";
    const currentUserIsSurgeon = user && isSurgeonUser(user);
    const cannotSeeIndividualResponses =
      (user &&
        (isHospitalAdministratorUser(user) ||
          isHospitalStudyCoordinatorUser(user)) &&
        !user.patientResponseAccess) ||
      isFollowUpUser(user);
    return (
      <Fragment>
        <Responsive maxWidth={691}>
          <Table
            striped
            bordered
            size={"sm"}
            responsive
            className={"patient-view"}
          >
            <tbody>
              {previousResponses.map((response, i) => {
                let procedureIds = !isEmptyOrNull(
                  response.allProcedureCollections,
                )
                  ? response.allProcedureCollections
                      .map((pair) => pair.first)
                      .join(", ")
                  : response.procedureCollectionId;
                return (
                  <tr key={i}>
                    <td>
                      <Row className={"first-line g-0"}>
                        {isSahmriUser(user) && !isEmptyOrNull(procedureIds) && (
                          <div>{procedureIds}</div>
                        )}
                        <div>{response.date}</div>
                        <div>{getProcedureValue(response)}</div>
                        <div>{response.studyType}</div>
                      </Row>
                      {!currentUserIsSurgeon &&
                        !cannotSeeIndividualResponses && (
                          <Row className={"second-line g-0"}>
                            <div>{response.surgeon}</div>
                            <div>
                              Shared with surgeon?{" "}
                              {response.sharedWithSurgeon ? "Yes" : "No"}
                            </div>
                          </Row>
                        )}
                      {canSeeNumberOfAnswers(user) && (
                        <Row className={"g-0"}>
                          <span>Answers: {getNumberAnswered(response)}</span>
                        </Row>
                      )}
                      {!cannotSeeIndividualResponses && (
                        <Row className={"third-line g-0"}>
                          <div>{response.hospital}</div>
                          {response.instrumentScores.length > 0 && (
                            <div>
                              <Button
                                size="sm"
                                title={"View Previous Responses"}
                                onClick={() => showResults(i)}
                              >
                                View
                              </Button>
                              {isSahmriUser(user) && (
                                <Button
                                  color={"danger"}
                                  className={"float-right"}
                                  title={"Delete Procedure Collection(s)"}
                                  onClick={() =>
                                    setState({
                                      deleteProcedureCollections:
                                        response.allProcedureCollections,
                                      deleteProcedureId: response.procedureId,
                                    })
                                  }
                                >
                                  <FaTrash />
                                </Button>
                              )}
                            </div>
                          )}
                        </Row>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={692}>
          <Table striped bordered size={"sm"} responsive>
            <thead>
              <tr>
                {showProcedureCollectionIds() && (
                  <th>Procedure Collection Id</th>
                )}
                <th>Date</th>
                <th>Procedure</th>
                <th>Survey Type</th>
                <th>Hospital</th>
                {!currentUserIsSurgeon && <th>Surgeon</th>}
                {!currentUserIsSurgeon && !cannotSeeIndividualResponses && (
                  <th>Shared with surgeon?</th>
                )}
                {canSeeNumberOfAnswers(user) && <th>Answers</th>}
                {!cannotSeeIndividualResponses && <th>{label}</th>}
              </tr>
            </thead>
            <tbody>
              {previousResponses.map((response, i) => {
                let procedureCollectionIds = !isEmptyOrNull(
                  response.allProcedureCollections,
                )
                  ? response.allProcedureCollections
                      .map((pair) => pair.first)
                      .join(", ")
                  : response.procedureCollectionId;
                return (
                  <tr key={i}>
                    {showProcedureCollectionIds() &&
                      !isEmptyOrNull(procedureCollectionIds) && (
                        <td>{procedureCollectionIds}</td>
                      )}
                    <td>{response.date}</td>
                    <td>{getProcedureValue(response)}</td>
                    <td>{response.studyType}</td>
                    <td>{response.hospital}</td>
                    {!currentUserIsSurgeon && <td>{response.surgeon}</td>}
                    {!currentUserIsSurgeon && !cannotSeeIndividualResponses && (
                      <td className={"centered"}>
                        {response.sharedWithSurgeon ? (
                          <FaCheck className={"green"} />
                        ) : (
                          <FaBan className={"red"} />
                        )}
                      </td>
                    )}
                    {canSeeNumberOfAnswers(user) && (
                      <td>{getNumberAnswered(response)}</td>
                    )}
                    {!cannotSeeIndividualResponses && (
                      <td className={"centered"}>
                        {response.instrumentScores.length > 0 && (
                          <a
                            onClick={() => showResults(i)}
                            title={"View Previous Responses"}
                          >
                            <FaEye className={"hand"} />
                          </a>
                        )}
                        {isSahmriUser(user) && (
                          <Button
                            className={"action-button"}
                            outline
                            color={"danger"}
                            id={`showDeleteDialog-${i}`}
                            onClick={() =>
                              setState({
                                deleteProcedureCollections:
                                  response.allProcedureCollections,
                                deleteProcedureId: response.procedureId,
                              })
                            }
                            title={"Delete Procedure Collection(s)"}
                          >
                            <FaTrash />
                          </Button>
                        )}
                        {isAoaUser(user) &&
                          response.isStudyCollectionStillInScope && (
                            <Button
                              className={"action-button"}
                              title={"Unlock Answer(s)"}
                              outline
                              color={"danger"}
                              id={"unlockButton"}
                              disabled={state.hideUnlockIcon}
                              onClick={() =>
                                handleUnlockProcedureCollection(
                                  response.procedureCollectionId,
                                )
                              }
                            >
                              <FaUnlock />
                            </Button>
                          )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
      </Fragment>
    );
  };

  const getNumberAnswered = (response) => {
    if (canSeeNumberOfAnswers(user)) {
      let count = 0;
      if (response.instrumentScores) {
        response.instrumentScores.forEach((instrumentScore) => {
          if (instrumentScore.responses) {
            count = count + instrumentScore.responses.length;
          }
        });
      }
      return <span title={"# Questions Answered"}>{count}</span>;
    } else {
      return null;
    }
  };

  const getProcedureValue = (response) => {
    if (isSahmriUser(user) || isAdministratorUser(user)) {
      return (
        <span>
          {response.procedure}
          <br />
          (ID: {response.procedureId})
        </span>
      );
    } else {
      return response.procedure;
    }
  };

  const renderModal = () => {
    return (
      <Fragment>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          size={"lg"}
          className={"previous-responses"}
          keyboard={false}
          autoFocus={true}
        >
          <ModalHeader toggle={toggle}>Previous Responses</ModalHeader>
          <ModalBody>
            {patientView && (
              <Responsive minWidth={692}>
                <div style={{ marginBottom: "1em" }}>
                  <i>
                    Click on the 'Eye' Summary Icon to view your responses to a
                    previous set of questions.
                  </i>
                </div>
              </Responsive>
            )}
            {previousResponses.length > 0 && getTable()}
            {previousResponses.length === 0 && (
              <i>No previous responses were found for this patient</i>
            )}
            <ButtonBar
              buttons={[
                <Button
                  key={"close-button"}
                  color="primary"
                  outline
                  onClick={toggle}
                >
                  Close
                </Button>,
              ]}
            />
          </ModalBody>
        </Modal>
        <ResponsesView
          isOpen={state.viewResponses}
          responses={state.responses}
          procedureCollectionId={state.procedureCollectionId}
          toggle={toggleResultView}
          patientView={true}
          title={`Previous Responses - ${state.procedure}`}
        />
      </Fragment>
    );
  };

  const renderComponent = () => {
    return (
      <Container className={"previous-responses component"}>
        {getTable()}
        {state.deleteProcedureCollections && isSahmriUser(user) && (
          <ConfirmDeleteProcedureCollectionModal
            exitCallback={handleCancelDeleteProcedureCollection}
            confirmCallback={(message) => handleSuccess(message)}
            procedureCollections={state.deleteProcedureCollections}
            procedureId={state.deleteProcedureId}
          />
        )}
        <ScoredInstruments
          isOpen={state.viewInstruments}
          responses={state.responses}
          procedureCollectionId={state.procedureCollectionId}
          instruments={state.instruments}
          toggle={toggleInstrumentView}
        />
      </Container>
    );
  };

  return modal ? renderModal() : renderComponent();
};

CompletedCollections.propTypes = {
  previousResponses: PropTypes.array.isRequired,
  modal: PropTypes.bool,
  instrumentTableModal: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  patientView: PropTypes.bool,
  onConfirm: PropTypes.func,
};

export default CompletedCollections;

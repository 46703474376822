import { Card, CardBody, CardTitle, Container } from "reactstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaCheck, FaEdit } from "react-icons/fa";
import TabbedPatientModal from "./TabbedPatientModal";
import Search from "./Search";
import { canEditPatient } from "../../patient/Utils";
import { useReducer } from "react";
import { EDIT, VIEW } from "../../../Utils";

const PatientManagement = () => {
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      showModal: false,
      modalOperation: "",
      searchVersion: 0,
      modalPatientId: null,
      patient: null,
    },
  );

  const handleSuccess = (message) => {
    setState({
      showModal: false,
      showPatientMergeModal: false,
      searchVersion: state.searchVersion + 1,
    });
    toast.success(
      <span>
        <FaCheck /> {message}
      </span>,
    );
  };

  const handleClose = () => {
    setState({
      showModal: false,
      showPatientMergeModal: false,
      open: !state.open,
      isOpen: !state.isOpen,
    });
  };

  const showPatientDialog = (patient) => {
    setState({
      showModal: true,
      open: true,
      modalOperation: canEditPatient(user) ? EDIT : VIEW,
      modalPatientId: patient.id,
      showPatientMergeModal: false,
      patient: patient,
    });
  };

  const getActionButtonsForUser = (user) => {
    let buttons = [];
    buttons.push({
      icon: <FaEdit />,
      title: canEditPatient(user) ? "Edit Patient" : "View Patient",
      action: showPatientDialog,
    });
    return buttons;
  };

  return (
    <div>
      <Container>
        <h1>Patient Management</h1>
        <Card>
          <CardBody>
            <CardTitle>Current Patients</CardTitle>
            <CardBody>
              <Search
                actionButtons={getActionButtonsForUser(user)}
                resultsVersion={state.searchVersion}
              />
            </CardBody>
          </CardBody>
        </Card>
      </Container>
      {state.showModal && (
        <TabbedPatientModal
          onConfirm={handleSuccess}
          onExit={handleClose}
          modalOperation={state.modalOperation}
          patientId={state.modalPatientId}
        />
      )}
    </div>
  );
};

export default PatientManagement;

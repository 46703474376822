import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { getExternalResponseUploads } from "../../api/Upload";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyOrNull, isSahmriUser, VIEW } from "../../Utils";
import ExternalResponseUploads from "./ExternalResponseUploads";
import { FaUpload } from "react-icons/fa";
import ExternalResponseUploadTable from "./ExternalResponseUploadTable";
import ButtonBar from "../common/ButtonBar";
import { useEffect, useReducer } from "react";
import { useOnUpdate } from "../CustomHooks";

const ExternalResponseUploadManagement = ({
  resultsVersion = 0,
  open,
  exitCallback,
  upload,
  operation,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      responseUploads: [],
      itemsCountPerPage: 10,
      pageRangeDisplayed: 5,
      totalItemsCount: 0,
      activePage: 1,
      description: "",
      showNewUpload: false,
      isOpen: false,
      operation: null,
    },
  );

  useEffect(() => {
    setState({
      responseUploads: upload.externalResponseUploads,
      isOpen: open,
      operation: operation,
    });
  }, [state.activePage]);

  useOnUpdate(() => {
    !state.showNewUpload && exitCallback();
  }, [state.showNewUpload]);

  useEffect(() => {
    refreshTable();
  }, [resultsVersion]);

  const refreshTable = (showSpinner) => {
    if (showSpinner) {
      dispatch(startLoading());
    }
    let payload = {
      page: state.activePage - 1,
      size: state.itemsCountPerPage,
      description: isEmptyOrNull(state.description) ? null : state.description,
      uploadId: upload.id,
      statusId: state.status == null ? null : state.status.id,
    };
    getExternalResponseUploads(payload)
      .then((response) => {
        setState({
          responseUploads: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
        });
      })
      .finally(() => dispatch(finishLoading()));
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  return (
    <Modal isOpen={open} size={"lg"}>
      <ModalHeader>Cleansed External Patient Responses</ModalHeader>
      <ModalBody className={"external-response-uploads"}>
        {isSahmriUser(user) && state.operation !== VIEW && (
          <Card className={"upload-another-external-response-upload"}>
            <CardBody>
              <CardTitle>New External Response Upload</CardTitle>
              <Row className={"justify-content-end"}>
                <Col xl={8} className={"float-right"}>
                  <Button
                    className={"float-right"}
                    outline
                    color={"primary"}
                    onClick={() => setState({ showNewUpload: true })}
                    title={"Upload cleaned response from template"}
                  >
                    <FaUpload />
                    Upload Another Cleansed Response
                  </Button>
                </Col>
                {state.showNewUpload && (
                  <ExternalResponseUploads
                    open={state.showNewUpload}
                    upload={upload}
                    exitCallback={() => setState({ showNewUpload: false })}
                  />
                )}
              </Row>
            </CardBody>
          </Card>
        )}
        <Card className={"external-response-upload-management"}>
          <CardBody>
            <CardTitle>Existing External Response Uploads</CardTitle>
            <ExternalResponseUploadTable
              pageRangeDisplayed={state.pageRangeDisplayed}
              itemsCountPerPage={state.itemsCountPerPage}
              totalItemsCount={state.totalItemsCount}
              responseUploads={state.responseUploads}
              activePage={state.activePage}
              changePageCallback={changePage}
              tableChangeCallback={() => refreshTable(true)}
            />
            {isSahmriUser(user) && state.operation === VIEW && (
              <ButtonBar
                buttons={[
                  <Button
                    key={"cancelButton"}
                    type={"button"}
                    onClick={exitCallback}
                  >
                    Cancel
                  </Button>,
                ]}
              />
            )}
          </CardBody>
        </Card>
        {isSahmriUser(user) && state.operation !== VIEW && (
          <ButtonBar
            buttons={[
              <Button
                key={"closeButton"}
                type={"button"}
                onClick={exitCallback}
              >
                Close
              </Button>,
            ]}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

ExternalResponseUploadManagement.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmCallback: PropTypes.func,
  exitCallback: PropTypes.func,
  upload: PropTypes.object,
  operation: PropTypes.string,
  resultsVersion: PropTypes.number,
};

export default ExternalResponseUploadManagement;

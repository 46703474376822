import { Fragment } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";

const SimpleQuestionWrapper = ({
  className,
  question,
  inputComponent,
  leftButton,
  rightButton,
  mutedText,
  warningText,
}) => {
  return (
    <Fragment>
      <div className={`question-wrapper ${className}`}>
        <div className={"question"}>{question}</div>
        <div>{inputComponent}</div>
        <div className={"subdued"}>{mutedText}</div>
      </div>
      <Alert color={"danger"} isOpen={!!warningText}>
        {warningText}
      </Alert>
      <div className={"button-row"}>
        {leftButton}
        {rightButton}
      </div>
    </Fragment>
  );
};

SimpleQuestionWrapper.propTypes = {
  question: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  inputComponent: PropTypes.node,
  leftButton: PropTypes.node,
  rightButton: PropTypes.node,
  className: PropTypes.string,
  mutedText: PropTypes.string,
  warningText: PropTypes.string,
};

export default SimpleQuestionWrapper;

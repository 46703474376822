import { Fragment, useEffect, useReducer } from "react";
import { useOnUpdate } from "../CustomHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  FormText,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import "./NonPatientView.css";
import {
  finishLoading,
  loadHospitals,
  loadProcedureTypes,
  loadStudies,
  startLoading,
} from "../../actions/CommonActions";
import SimpleProcedureTypeSelector from "../common/SimpleProcedureTypeSelector";
import MyStudiesSelector, { ALL_STUDIES } from "../common/MyStudiesSelector";
import {
  getComparisonReport,
  getReportsForStudyAndProcedureType,
} from "../../api/Study";
import {
  getStartCase,
  isAoaUser,
  isHospitalAdministratorUser,
  isSahmriUser,
  isStakeholderUser,
  isSurgeonUser,
} from "../../Utils";
import {
  canCompareAllStudies,
  STAKEHOLDER_COMPARISON_BASE,
  STAKEHOLDER_COMPARISON_TYPE,
} from "./Utils";
import MyHospitalsSelector, {
  ALL_HOSPITALS,
  filterHospitalsByStudyId,
  MY_HOSPITALS,
} from "../reporting/MyHospitalsSelector";
import DOMPurify from "dompurify";
import { customSort } from "../../Utils";
import ResultGraph from "./ResultGraph";

const NonPatientView = () => {
  const dispatch = useDispatch();

  // Global state from redux
  const user = useSelector((state) => state.user);
  const studies = useSelector((state) => state.studies);
  const procedureTypes = useSelector((state) => state.procedureTypes);
  const hospitals = useSelector((state) => state.hospitals);

  // Component state
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      reportOptions: [],
      study: null,
      procedureType: null,
      report: null,
      hospital: null,
      data: null,
      questionIndex: 0,
      filteredProcedureTypeIds: [],
      filterOptions: [...STAKEHOLDER_COMPARISON_TYPE],
      filters: [STAKEHOLDER_COMPARISON_BASE],
      transition: false,
      studyHasNoReports: false,
    },
  );

  // Begin effect hooks

  // Load initial
  useEffect(() => {
    procedureTypes.length === 0 && dispatch(loadProcedureTypes());
    hospitals.length === 0 && dispatch(loadHospitals());
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  // Select default options - not on initial render
  // Watch for changes to procedureTypes from redux
  useEffect(() => {
    initialAutoSelection();
  }, [procedureTypes]);

  // Run getReports when user changes study or procedureType filter
  useOnUpdate(() => {
    getReports();
  }, [state.study, state.procedureType]);

  // Run selectReport when report options are changed
  useEffect(() => {
    selectReport();
  }, [state.reportOptions]);

  // Run fetchData when any of the filters have been changed
  // If there are no reports available, set component to show message on screen
  useOnUpdate(() => {
    if (!state.report) {
      setState({ studyHasNoReports: true });
    } else if (!!state.procedureType && !!state.hospital && !!state.filters) {
      fetchData();
      setState({ studyHasNoReports: false });
    }
  }, [state.report, state.hospital, state.filters]);

  // End effect hooks

  const initialAutoSelection = () => {
    // Force PROMs study to be selected.
    let _study = canCompareAllStudies(user) ? ALL_STUDIES : studies[0];
    // procedure types and hospitals are filtered by study.
    let usersStudyProcedureTypes = _studyProcedureTypeIds(_study);
    let filteredProcedureTypes = procedureTypes.filter((j) =>
      usersStudyProcedureTypes.includes(j.id),
    );
    let pickedProcedureType = _pickProcedureType(filteredProcedureTypes, user);
    let newShowResults = studies.filter((s) =>
      s.studyComparableRoles.some(
        (comparableRole) => comparableRole.roleId === user.roleId,
      ),
    );
    let filterHospitals = filterHospitalsByStudyId(
      hospitals,
      _study ? _study.id : null,
    );
    let pickedHospital = _pickHospital(filterHospitals, user);
    let pickedStudy =
      isAoaUser(user) || isSahmriUser(user) ? _study : newShowResults[0];
    setState({
      study: pickedStudy,
      procedureType: pickedProcedureType,
      hospital: pickedHospital,
      filteredProcedureTypeIds: usersStudyProcedureTypes,
    });
  };

  const navigateQuestion = (increment) => {
    let nextQuestionIndex = state.questionIndex + increment;
    if (nextQuestionIndex > state.data.length - 1 || nextQuestionIndex < 0) {
      return;
    }
    setState({ questionIndex: nextQuestionIndex, transition: false });
  };

  const _pickHospital = (hospitals, user) => {
    return hospitals.length > 1 && isStakeholderUser(user)
      ? MY_HOSPITALS
      : ALL_HOSPITALS;
  };

  const _pickProcedureType = (procedureTypes, user) => {
    if (procedureTypes.length > 0 && user.majorProcedureTypeId != null) {
      let index = procedureTypes.findIndex(
        (j) => j.id === user.majorProcedureTypeId,
      );
      if (index < 0) {
        return procedureTypes[0];
      } else {
        return procedureTypes[index];
      }
    } else if (procedureTypes.length > 0) {
      return procedureTypes[0];
    } else {
      return null;
    }
  };

  /**
   * Fetches  an array of unique Procedure Type Ids that relate to the study parameter.
   * Where the study is 'All_Studies' all the procedureTypes related to all the user's studies is returned.
   * @param study
   * @returns {any[]}
   */
  const _studyProcedureTypeIds = (study) => {
    let intersection = new Set();
    let newShowResults = studies.filter((s) =>
      s.studyComparableRoles.some(
        (comparableRole) => comparableRole.roleId === user.roleId,
      ),
    );
    if (study.id > 0) {
      return study.procedureTypeIds;
    } else {
      if (
        isSurgeonUser(user) ||
        isHospitalAdministratorUser(user) ||
        isStakeholderUser(user)
      ) {
        let newProcedureTypeIds = [];
        newShowResults.forEach((std) =>
          newProcedureTypeIds.push(std.procedureTypeIds),
        );
        return newProcedureTypeIds.flatMap((s) => s);
      } else {
        studies
          .filter(() => canCompareAllStudies(user))
          .flatMap((s) => s.procedureTypeIds)
          .forEach((jId) => intersection.add(jId));
        return [...intersection];
      }
    }
  };

  const selectStudy = (study) => {
    let _procedureType = state.procedureType;
    let _hospital = state.hospital;
    // Check procedure type is still valid
    let usersStudyProcedureTypes = _studyProcedureTypeIds(study);
    let validProcedureTypes = procedureTypes.filter((j) =>
      usersStudyProcedureTypes.includes(j.id),
    );
    if (!validProcedureTypes.includes(_procedureType)) {
      _procedureType = _pickProcedureType(validProcedureTypes, user);
    }
    // Check hospital is still valid
    let validHospitals = filterHospitalsByStudyId(
      hospitals,
      study ? study.id : null,
    );
    if (!validHospitals.includes(_hospital)) {
      _hospital = _pickHospital(validHospitals, user);
    }
    setState({
      study: study,
      procedureType: _procedureType,
      hospital: _hospital,
      filteredProcedureTypeIds: usersStudyProcedureTypes,
    });
  };

  const selectReport = (report) => {
    // If there's no report selected OR a report is already selected but it doesn't exist in the options list
    // default to the first available report in the option list.
    if (
      !report ||
      state.reportOptions.filter((r) => r.id === report.id).length !== 1
    ) {
      report = state.reportOptions.length !== 0 ? state.reportOptions[0] : null;
    }
    setState({
      report: report,
      transition: true,
      questionIndex: 0,
    });
  };

  const selectHospital = (hospital) => {
    // Only non surgeons can filter by hospitals.
    if (!isSurgeonUser(user) && hospital != null) {
      setState({ hospital: hospital });
    }
  };

  const getReports = () => {
    if (!!state.study && !!state.procedureType && !!state.hospital) {
      getReportsForStudyAndProcedureType(
        state.study.id,
        state.procedureType.id,
        getHospitalIds(state.hospital),
      ).then((response) => {
        setState({ reportOptions: response.data });
      });
    }
  };

  const getHospitalIds = (hospital) => {
    let hospitalIds = [];
    if (hospital.id === MY_HOSPITALS.id) {
      hospitalIds = hospitals.map((hospital) => hospital.id);
    } else if (hospital.id !== ALL_HOSPITALS.id) {
      hospitalIds = [hospital.id];
    }
    return hospitalIds;
  };

  const fetchData = () => {
    dispatch(startLoading());
    let payload = {
      studyId: state.study.id,
      reportId: state.report !== null ? state.report.id : 0,
      procedureTypeId: state.procedureType.id,
      hospitalIds: getHospitalIds(state.hospital),
    };
    state.filters.forEach((f) => (payload = { ...payload, ...f.filter }));
    getComparisonReport(payload)
      .then((response) => {
        setState({ data: response.data, transition: false });
      })
      .catch(() => {
        setState({ data: null, transition: false });
      })
      .finally(() => dispatch(finishLoading()));
  };

  // Filters are grouped and are mutually-exclusive within the groups. E.g. If you pick 'Male', it will be taken out of
  // the option list, but 'Female' will remain. If you then select 'Female' from the option list, 'Male' is automatically
  // de-selected and is available from the options again.
  const selectFilter = (filters) => {
    let newFilters = [];
    if (filters.length === 0) {
      newFilters = [STAKEHOLDER_COMPARISON_BASE];
    } else {
      // Add filter
      if (filters.length > state.filters.length) {
        // This is the filter that's been added.
        let added = filters.filter((f) => !state.filters.includes(f))[0];
        ["a", "b", "c", "d"].forEach((group) => {
          if (group === added.group) {
            newFilters.push(...filters.filter((f) => f === added));
          } else {
            newFilters.push(...filters.filter((f) => f.group === group));
          }
        });
      } else {
        // Remove filter
        newFilters = filters;
      }
    }
    // Remove national from the selected filter list as exclusive to itself
    if (newFilters.length > 1) {
      newFilters = newFilters.filter((f) => f !== STAKEHOLDER_COMPARISON_BASE);
    }
    setState({ filters: newFilters });
  };

  const allResponsesFilterFieldText = () => {
    let f = state.filters.includes(
      STAKEHOLDER_COMPARISON_TYPE.find((f) => f.field === "matchedAgnostic"),
    );
    let stdTxt = "Responses matched to a Registry procedure are shown below";
    let altTxt =
      "Responses may have been provided by patients who are yet to undergo their procedure";
    let txt = f ? altTxt : stdTxt;
    return <FormText>{txt}</FormText>;
  };

  const renderGraph = () => {
    let d = undefined;
    if (state.data) {
      d = state.data[state.questionIndex];
    }
    if (!d) {
      return <div style={{ height: 400 }} />;
    }

    let comments = [];
    d.series
      .sort((s1, s2) => customSort(s1.index, s2.index))
      .forEach((s) => {
        if (s.description) {
          comments.push(s.description);
        }
      });

    if (d) {
      return (
        <Fragment>
          {state.data.length > 1 && (
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <h4 style={{ fontSize: "1em", textAlign: "right" }}>
                <i>
                  Screen {state.questionIndex + 1}/{state.data.length}
                </i>
              </h4>
            </Row>
          )}
          <Row>
            <div
              className={`graph-header ${
                state.transition ? " transition" : ""
              }`}
            >
              <h4 className="comparison-title">
                {getStartCase(d.procedureType)}
                {` - ${d.title}`}
              </h4>
              <div className="strip-formatting">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(d.description),
                  }}
                />
              </div>
            </div>
          </Row>
          <ResultGraph
            series={d.series}
            maxY={d.maxYValue}
            showYAxis={d.showYAxis}
            xAxisLabels={d.labelsXAxis}
            tooltip={d.tooltip}
            yAxisIsPercent={d.yaxisIsPercent}
            titleYAxis={d.titleYAxis}
            titleXAxis={d.titleXAxis}
          />
          <Row>
            {!!d.legendText && (
              <div className="center-full-width">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(d.legendText),
                  }}
                />
              </div>
            )}
          </Row>
          <Row>
            <div className={`graph-header ${state.transition && "transition"}`}>
              <div
                className="comparison-explanation"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(comments.join("<br/>")),
                }}
              />
            </div>
            <Row style={{ width: "100%" }} className={"g-0"}>
              <div className="center-full-width">
                <Alert
                  isOpen={d.series.filter((s) => s.type === "ALERT").length > 0}
                  color={"primary"}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        d.series
                          .filter((s) => s.type === "ALERT")
                          .map((s) => s.alert)
                          .join("<br/>"),
                      ),
                    }}
                  />
                </Alert>
              </div>
            </Row>
          </Row>
        </Fragment>
      );
    }
  };

  const getSelectionRow = () => {
    let availComparableStudies;
    if (canCompareAllStudies(user)) {
      availComparableStudies = studies;
    } else {
      availComparableStudies = studies.filter((s) =>
        s.studyComparableRoles.some(
          (compareRole) => compareRole.roleId === user.roleId,
        ),
      );
    }
    let colSize = isSurgeonUser(user) ? 4 : 3;
    return (
      <Row>
        <Col xs={12} sm={6} md={colSize}>
          <FormGroup inline>
            <Label className={"label"}>
              <b>Study</b>
            </Label>
            <MyStudiesSelector
              options={availComparableStudies}
              value={state.study}
              onChange={selectStudy}
              useAllStudies={canCompareAllStudies(user)}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6} md={colSize}>
          <FormGroup inline>
            <Label className={"label"}>
              <b>Procedure Type</b>
            </Label>
            <SimpleProcedureTypeSelector
              value={state.procedureType}
              onChange={(value) => setState({ procedureType: value })}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              filterByProcedureTypeIds={state.filteredProcedureTypeIds}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6} md={colSize}>
          <FormGroup inline>
            <Label className={"label"}>
              <b>Report</b>
            </Label>
            <Select
              options={state.reportOptions}
              value={state.report}
              onChange={selectReport}
              isClearable={false}
              isSearchable={false}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            />
          </FormGroup>
        </Col>
        {!isSurgeonUser(user) && (
          <Col xs={12} sm={6} md={colSize}>
            <FormGroup inline>
              <Label className={"label"}>
                <b>Hospital</b>
              </Label>
              <MyHospitalsSelector
                value={state.hospital}
                onChange={(value) => selectHospital(value)}
                filterByStudyId={state.study ? state.study.id : null}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <Container className="instrument-comparison">
      <Row>
        <h3 className="container-title">Compare Patient Reported Results</h3>
      </Row>
      {getSelectionRow()}
      <Row className="filter-selector">
        <Col xs={12}>
          <FormGroup inline>
            <Select
              isMulti
              autosize
              blurInputOnSelect
              hideSelectedOptions
              isSearchable={false}
              onChange={(selected) =>
                selectFilter(selected == null ? [] : selected)
              }
              menuShouldScrollIntoView
              options={state.filterOptions}
              placeholder="Filter results"
              value={state.filters}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.field}
              styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            />
            {allResponsesFilterFieldText()}
          </FormGroup>
        </Col>
      </Row>
      {state.studyHasNoReports && (
        <div>
          There are no reports available for Study:{" "}
          <strong>{!!state.study && state.study.name}</strong> and Procedure
          Type:{" "}
          <strong>
            {!!state.procedureType && state.procedureType.displayName}
          </strong>
        </div>
      )}
      {!state.studyHasNoReports && renderGraph()}
      {!state.studyHasNoReports && !!state.data && state.data.length > 1 && (
        <div className={"buttons"}>
          <Button
            color="primary"
            disabled={state.questionIndex <= 0}
            outline={state.questionIndex <= 0}
            onClick={() => navigateQuestion(-1)}
          >
            Previous
          </Button>
          <Button
            color="primary"
            disabled={state.questionIndex >= state.data.length - 1}
            outline={state.questionIndex >= state.data.length - 1}
            onClick={() => navigateQuestion(1)}
          >
            Next
          </Button>
        </div>
      )}
    </Container>
  );
};

export default NonPatientView;

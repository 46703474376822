import { useBlocker } from "react-router-dom";

const Prompt = ({ when, message }) => {
  useBlocker(() => {
    if (when) {
      return !window.confirm(message);
    }
    return false;
  });

  return <div key={when} />;
};

export default Prompt;

import { Fragment, useReducer } from "react";
import PropTypes from "prop-types";
import ButtonBar from "../../common/ButtonBar";
import Select from "react-select";
import "./Procedures.css";

import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { isEmptyOrNull } from "../../../Utils";

const ProcedureStudyWithdrawalConfirmationModal = ({
  confirmCallback,
  exitCallback,
  showModal,
  studyRemovalCandidate,
  studyWithdrawalReasonOptions,
}) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      showOtherReasonText: false,
      selectedReason: null,
      otherReasonText: "",
    },
  );

  const setReason = (value) => {
    setState({ selectedReason: value, showOtherReasonText: value.id === 6 });
  };

  return (
    <Modal isOpen={showModal}>
      <ModalHeader>
        Remove {studyRemovalCandidate} Study from Procedure
      </ModalHeader>
      <ModalBody>
        <Container>
          <Form>
            <FormGroup>
              <Row className={"confirm-study-withdrawal first-row"}>
                <Col xs={12}>
                  Please select a reason as to why you are removing the{" "}
                  <b>{studyRemovalCandidate}</b> study from the procedure, then
                  select the <i>Save</i> button to continue; otherwise select
                  the <i>CANCEL</i> button.
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Select
                    options={studyWithdrawalReasonOptions}
                    onChange={setReason}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder={"Select..."}
                    value={state.selectedReason}
                    styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                </Col>
              </Row>
              {state.showOtherReasonText && (
                <Row>
                  <Col xs={12}>
                    <Fragment>
                      <Form>
                        <FormGroup>
                          <Row className={"first-row"}>
                            <Col xs={12}>
                              <Input
                                type={"text"}
                                value={state.otherReasonText}
                                onChange={(event) =>
                                  setState({
                                    otherReasonText: event.target.value,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                    </Fragment>
                  </Col>
                </Row>
              )}
            </FormGroup>
          </Form>
        </Container>
        <ButtonBar
          buttons={[
            <Button
              disabled={
                isEmptyOrNull(state.selectedReason) ||
                (state.selectedReason.id === 6 &&
                  isEmptyOrNull(state.otherReasonText))
              }
              color="primary"
              onClick={() => {
                setState({
                  showOtherReasonText: false,
                  selectedReason: null,
                  otherReasonText: "",
                });
                confirmCallback(state.selectedReason, state.otherReasonText);
              }}
            >
              Done
            </Button>,
            <Button onClick={exitCallback}>Cancel</Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

ProcedureStudyWithdrawalConfirmationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  exitCallback: PropTypes.func.isRequired,
  studyRemovalCandidate: PropTypes.string.isRequired,
  studyWithdrawalReasonOptions: PropTypes.array.isRequired,
};

export default ProcedureStudyWithdrawalConfirmationModal;

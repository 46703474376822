import { Button, Col, Label, Row, Table } from "reactstrap";
import { FaEdit, FaLink, FaLongArrowAltRight, FaUnlink } from "react-icons/fa";
import "./HospitalStudyLinking.css";
import PropTypes from "prop-types";
import StudySearch from "../../study/Search";
import { useSelector } from "react-redux";
import Moment from "moment";
import GovernanceDateModal from "./GovernanceDateModal";
import { useReducer } from "react";

const HospitalStudyLinking = ({
  links = [],
  readOnly = true,
  hospitalName,
  onLinkChange,
}) => {
  const studies = useSelector((state) => state.studies);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      modalStudy: null,
      modalGovernanceStartDate: null,
      modalRecruitmentEndDate: null,
    },
  );

  const handleUnlink = (hospitalStudy) => {
    let filtered = links.filter((hs) => hs.studyId !== hospitalStudy.studyId);
    onLinkChange(filtered);
  };

  const handleLink = (study, governanceStartDate, recruitmentEndDate) => {
    const endDateValid = Moment(recruitmentEndDate).isValid();
    setState({
      modalStudy: null,
      modalGovernanceStartDate: null,
      modalRecruitmentEndDate: null,
    });
    let newLinks = links.slice();
    let currentIndex = newLinks.findIndex((link) => link.studyId === study.id);
    if (currentIndex < 0) {
      newLinks.push({
        studyId: study.id,
        studyName: study.name,
        governanceStartDate: Moment(governanceStartDate)
          .local()
          .format("YYYY-MM-DD"),
        recruitmentEndDate: endDateValid
          ? Moment(recruitmentEndDate).local().format("YYYY-MM-DD")
          : null,
      });
    } else {
      newLinks[currentIndex].governanceStartDate = Moment(governanceStartDate)
        .local()
        .format("YYYY-MM-DD");
      newLinks[currentIndex].recruitmentEndDate = endDateValid
        ? Moment(recruitmentEndDate).local().format("YYYY-MM-DD")
        : null;
    }
    onLinkChange(newLinks);
  };

  const getStudyName = (studyId) => {
    let index = studies.findIndex((study) => study.id === studyId);
    if (index < 0) {
      return null;
    }
    return studies[index].name;
  };

  const getStudy = (studyId) => {
    let index = studies.findIndex((study) => study.id === studyId);
    if (index < 0) {
      return null;
    }
    return studies[index];
  };

  const formatDate = (governanceDate) => {
    return Moment(governanceDate, "YYYY-MM-DD").format("DD/MM/YYYY");
  };

  return (
    <div className={"hospital-study-linking"}>
      <Row>
        <Col sm={3}>
          <Label className={"section-text"}>Linked Studies</Label>
        </Col>
        <Col sm={9}>
          <Table striped responsive>
            <tbody>
              {links.length > 0 ? (
                links
                  .sort((o1, o2) =>
                    o1.governanceStartDate.localeCompare(
                      o2.governanceStartDate,
                    ),
                  )
                  .map((hospitalStudy) => (
                    <tr key={`study-key-${hospitalStudy.studyId}`}>
                      <td className={"study-entry"}>
                        <span className={"study-text"}>
                          {getStudyName(hospitalStudy.studyId)}
                        </span>
                      </td>
                      <td className={"study-entry"}>
                        <span className={"study-text me-2"}>
                          {formatDate(hospitalStudy.governanceStartDate)}
                        </span>
                        <FaLongArrowAltRight />
                        <span className={"study-text ms-2"}>
                          {hospitalStudy.recruitmentEndDate == null ? (
                            <i>Ongoing</i>
                          ) : (
                            formatDate(hospitalStudy.recruitmentEndDate)
                          )}
                        </span>
                        {!readOnly && (
                          <span className={"edit-button"}>
                            <Button
                              className={"action-button"}
                              outline
                              title={"Edit governance date"}
                              onClick={() =>
                                setState({
                                  modalStudy: getStudy(hospitalStudy.studyId),
                                  modalGovernanceStartDate:
                                    hospitalStudy.governanceStartDate,
                                  modalRecruitmentEndDate:
                                    hospitalStudy.recruitmentEndDate,
                                })
                              }
                            >
                              <FaEdit />
                            </Button>
                          </span>
                        )}
                      </td>
                      <td>
                        {!readOnly && (
                          <span className={"unlink-button"}>
                            <Button
                              className={"action-button"}
                              outline
                              title={"Unlink hospital from study"}
                              onClick={() => handleUnlink(hospitalStudy)}
                            >
                              <FaUnlink />
                            </Button>
                          </span>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td>
                    <em>No linked studies set</em>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {!!state.modalStudy && (
        <GovernanceDateModal
          study={state.modalStudy}
          governanceStartDateProp={state.modalGovernanceStartDate}
          recruitmentEndDateProp={state.modalRecruitmentEndDate}
          hospitalName={hospitalName}
          onConfirm={handleLink}
          onCancel={() =>
            setState({
              modalStudy: null,
              modalGovernanceStartDate: null,
              modalRecruitmentEndDate: null,
            })
          }
        />
      )}
      {!readOnly && (
        <div>
          <hr />
          <Row>
            <Col sm={12}>
              <Label className={"section-text"}>Available Studies</Label>
            </Col>
          </Row>
          <StudySearch
            excludeIds={links.map((hospitalStudy) => hospitalStudy.studyId)}
            actionButtons={[
              {
                icon: (
                  <FaLink
                    onClick={(study) => setState({ modalStudy: study })}
                  />
                ),
                title: "Link hospital to study",
                action: (study) => setState({ modalStudy: study }),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

HospitalStudyLinking.propTypes = {
  onLinkChange: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  hospitalName: PropTypes.string.isRequired,
};

export default HospitalStudyLinking;

import { get, post } from "./Api";

const BASE_URL = "/api/surgeon";

export function sendSms(request) {
  return post(
    `${BASE_URL}/send-sms`,
    request,
    "Unable to send sms at this time.",
  );
}

export function getSurgeons() {
  return get(`${BASE_URL}/all`, null, "Unable to load surgeons at this time.");
}

export function hasResponses() {
  return get(
    `${BASE_URL}/has-responses`,
    null,
    "Unable to determine whether responses are available.",
  );
}

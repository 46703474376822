import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import NavButton from "./NavButton";
import {
  FaChartArea,
  FaChartBar,
  FaClipboardCheck,
  FaMedkit,
  FaQuestion,
  FaSignOutAlt,
  FaUpload,
  FaWheelchair,
} from "react-icons/fa";
import Enrolment from "../patient/Enrolment";
import { Navigate, Route, Routes } from "react-router-dom";
import HospitalAdministratorHome from "./HospitalAdministratorHome";
import PatientManagement from "../management/patient/PatientManagement";
import Uploads from "../uploads/Uploads";
import Tasks from "../task/Tasks";
import Reporting from "../reporting/Reporting";
import NonPatientView from "../compare/NonPatientView";
import SurgeonCrf from "../surgeon/SurgeonCrf";
import { PageTracking } from "../app/PageTracking";

export const HospitalAdministrator = (
  menuOpen,
  toggleMenuCb,
  userLogoutCb,
  helpToggleCallback,
  showUploads,
  showReporting,
  showResults,
) => {
  const menu = (sidebarMenu, showHelpAndLogout) => (
    <Navbar
      className={`vertical-menu ${sidebarMenu ? "header" : ""}`}
      light
      expand={!sidebarMenu}
    >
      <NavbarToggler onClick={toggleMenuCb} children={<span>MENU</span>} />
      <Collapse isOpen={sidebarMenu ? menuOpen : false} navbar>
        <Nav className="me-auto  vert-menu-holder" vertical>
          <NavButton
            to={"/patient/enrolment"}
            icon={<FaWheelchair />}
            text={"Patient Enrolment"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/patient/management"}
            icon={<FaMedkit />}
            text={"Existing Patients"}
            onClick={toggleMenuCb}
          />
          {showReporting && (
            <NavButton
              to={"/reporting"}
              icon={<FaChartBar />}
              text={"Reporting"}
              onClick={toggleMenuCb}
            />
          )}
          {showResults && (
            <NavButton
              to={"/compare"}
              icon={<FaChartArea />}
              text={"Compare Results"}
              onClick={toggleMenuCb}
            />
          )}
          <NavButton
            to={"/tasks"}
            icon={<FaClipboardCheck />}
            text={"Tasks"}
            onClick={toggleMenuCb}
          />
          {showUploads && (
            <NavButton
              to={"/uploads"}
              icon={<FaUpload />}
              text={"Response Uploads"}
              onClick={toggleMenuCb}
            />
          )}
          {showHelpAndLogout && (
            <>
              {" "}
              <hr />
              <NavButton
                to={null}
                icon={<FaQuestion className={"icon help"} />}
                text={"Help"}
                onClick={helpToggleCallback}
              />
              <NavButton
                to={"/administration"}
                icon={<FaSignOutAlt className={"icon"} />}
                text={"Logout"}
                onClick={userLogoutCb}
              />
            </>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );

  const switchRoute = (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <PageTracking>
            <HospitalAdministratorHome
              showResults={showResults}
              showReporting={showReporting}
            />
          </PageTracking>
        }
      />
      <Route
        path="/patient/enrolment"
        element={
          <PageTracking>
            <Enrolment />
          </PageTracking>
        }
      />
      <Route
        path="/patient/management"
        element={
          <PageTracking>
            <PatientManagement />
          </PageTracking>
        }
      />
      <Route
        path="/reporting"
        element={
          <PageTracking>
            <Reporting />
          </PageTracking>
        }
      />
      <Route
        path="/compare"
        element={
          <PageTracking>
            <NonPatientView />
          </PageTracking>
        }
      />
      <Route
        path="/tasks"
        element={
          <PageTracking>
            <Tasks />
          </PageTracking>
        }
      />
      <Route
        path="/uploads"
        element={
          <PageTracking>
            <Uploads />
          </PageTracking>
        }
      />
      <Route
        path="/hospitalStudyCoordinator/crf/:procedureId/:crfTypeId"
        element={
          <PageTracking>
            <SurgeonCrf />
          </PageTracking>
        }
      />
      <Route element={<Navigate to="/dashboard" />} />
    </Routes>
  );

  return {
    switchRoute: switchRoute,
    menu: menu(),
    menuClosing: menu(true, false),
    menuCombined: menu(true, true),
  };
};

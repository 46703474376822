import { Fragment, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { getPhoneCalls } from "../../../api/Reminder";
import { Button, Table, Tooltip } from "reactstrap";
import { FaBan, FaCheck, FaQuestionCircle } from "react-icons/fa";
import Moment from "moment";
import "./PhoneCalls.css";
import { isAoaUser, isEmptyOrNull } from "../../../Utils";
import ButtonBar from "../../common/ButtonBar";
import { useSelector } from "react-redux";
import LogAdHocCall from "../../call-list/LogAdHocCall";

const PhoneCalls = ({ patientId }) => {
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { list: [], toolTipId: null, showCreateDialog: false },
  );

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    if (patientId != null) {
      getPhoneCalls(patientId).then((response) => {
        setState({ list: response.data });
      });
    }
  };

  const getReason = (item) => {
    if (isEmptyOrNull(item.otherReason)) {
      return item.reason;
    } else {
      return (
        <Fragment>
          {item.reason}
          <FaQuestionCircle
            id={"reason-tooltip-" + item.id}
            className={"tooltip-icon"}
          />
          <Tooltip
            placement={"right"}
            target={"reason-tooltip-" + item.id}
            isOpen={state.toolTipId === "reason-tooltip-" + item.id}
            toggle={() =>
              setState({
                toolTipId:
                  state.toolTipId === "reason-tooltip-" + item.id
                    ? null
                    : "reason-tooltip-" + item.id,
              })
            }
          >
            {item.otherReason}
          </Tooltip>
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      {isAoaUser(user) && (
        <ButtonBar
          horizontalRule={false}
          className={"my-3"}
          buttons={[
            <Button
              color={"primary"}
              onClick={() => setState({ showCreateDialog: true })}
            >
              Create New...
            </Button>,
          ]}
        />
      )}
      <Table
        striped
        bordered
        size={"sm"}
        responsive
        className={"phone-calls-table"}
      >
        <thead>
          <tr>
            <th>Created</th>
            <th>Created By</th>
            <th>Contact Complete</th>
            <th>Spoke With Patient</th>
            <th>Collection(s)</th>
            <th>Details</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          {state.list.map((item, index) => {
            return (
              <tr key={"phone-calls-tr-" + index}>
                <td
                  title={Moment(item.dateCreated).format("DD/MM/YYYY @ h:mm A")}
                >
                  {Moment(item.dateCreated).format("DD/MM/YYYY")}
                </td>
                <td>{item.createdBy}</td>
                <td>
                  {item.contactCompleted ? (
                    <FaCheck className={"green"} />
                  ) : (
                    <FaBan className={"red"} />
                  )}
                </td>
                <td>
                  {item.spokeWithPatient ==
                  null ? null : item.spokeWithPatient ? (
                    <FaCheck className={"green"} />
                  ) : (
                    <FaBan className={"red"} />
                  )}
                </td>
                <td>
                  {item.collections.map((collection, i) => {
                    return (
                      <div key={"collection-td-label-" + i}>{collection}</div>
                    );
                  })}
                </td>
                <td>{item.details}</td>
                <td>{getReason(item)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {state.showCreateDialog && (
        <LogAdHocCall
          patientId={patientId}
          saveCallback={() => {
            setState({ showCreateDialog: false });
            loadData();
          }}
          cancelCallback={() => setState({ showCreateDialog: false })}
        />
      )}
    </Fragment>
  );
};

PhoneCalls.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default PhoneCalls;

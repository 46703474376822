import { Fragment, useReducer } from "react";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { isEmptyOrNull } from "../../Utils";
import { finaliseUpload } from "../../api/Upload";
import ButtonBar from "../common/ButtonBar";
import FileUploader from "../common/FileUploader";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../actions/CommonActions";

export const STATUS_ACCEPTED = { name: "Accepted" };
export const STATUS_REJECTED = { name: "Rejected" };
export const STATUS_PARTIALLY_ACCEPTED = { name: "Partially Accepted" };

const FinaliseUpload = ({
  onCancel,
  status,
  selectedUpload,
  onConfirm = () => {},
}) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      internalReason: "",
      externalReason: "",
      errorText: "",
      rejectedRecords: null,
    },
  );

  const canFinalise = () => {
    if (
      isEmptyOrNull(state.internalReason) ||
      isEmptyOrNull(state.externalReason)
    ) {
      return false;
    }
    if (status.name === STATUS_PARTIALLY_ACCEPTED.name) {
      return state.rejectedRecords != null;
    } else {
      return true;
    }
  };

  const finalise = () => {
    dispatch(startLoading());

    const formData = new FormData();
    formData.append("id", selectedUpload.id);
    formData.append("internalReason", state.internalReason);
    formData.append("externalReason", state.externalReason);
    formData.append("status", status.name);
    if (status.name === STATUS_PARTIALLY_ACCEPTED.name) {
      formData.append("file", state.rejectedRecords);
    }

    setState({ errorText: "" });
    finaliseUpload(formData)
      .then(() => onConfirm())
      .catch((error) => {
        if (error.response && !isEmptyOrNull(error.response.data)) {
          setState({ errorText: error.response.data });
        } else {
          setState({ errorText: "An error occurred during the process" });
        }
      })
      .finally(() => dispatch(finishLoading()));
  };

  return (
    <Modal
      className={"reject-upload"}
      isOpen={true}
      onExit={onCancel}
      toggle={onCancel}
    >
      <ModalHeader>Upload - {status.name}</ModalHeader>
      <ModalBody>
        <p>
          You are about to mark the upload by{" "}
          <i>
            <b>{selectedUpload.uploadedBy}</b>
          </i>{" "}
          with the description:{" "}
          <i>
            <b>{selectedUpload.description}</b>
          </i>{" "}
          as{" "}
          <i>
            <b>{status.name}</b>
          </i>
          .
        </p>
        <FormGroup>
          <Label for="internalReason">
            Internal {state.operationName} reason*
          </Label>
          <Input
            required
            type="textarea"
            name="text"
            id="internalReason"
            value={state.internalReason}
            onChange={(event) =>
              setState({
                internalReason: event.target.value.replace(
                  /(?:\r\n|\r|\n)/g,
                  "",
                ),
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="externalReason">
            External {state.operationName} reason*
            <br />
            <b>
              <em>
                (Note: This text will be sent back to the originator of the
                upload)
              </em>
            </b>
          </Label>
          <Input
            required
            type="textarea"
            name="text"
            id="externalReason"
            value={state.externalReason}
            /* We don't allow line-breaks here in order to simplify the Thymeleaf email template */
            onChange={(event) =>
              setState({
                externalReason: event.target.value.replace(
                  /(?:\r\n|\r|\n)/g,
                  "",
                ),
              })
            }
          />
        </FormGroup>
        {status.name === STATUS_PARTIALLY_ACCEPTED.name && (
          <Fragment>
            <Row>
              <Col>Rejected Records*</Col>
            </Row>
            <FileUploader
              acceptedFileTypes={{
                "text/plain": [],
                "application/vnd.ms-excel": [".csv", ".xls", ".xlsx"],
              }}
              onFileAccepted={(file) => setState({ rejectedRecords: file })}
            />
            {!!state.rejectedRecords && (
              <Row className={"my-2"}>
                <Col>
                  Rejected records file to attach: {state.rejectedRecords.name}
                </Col>
              </Row>
            )}
          </Fragment>
        )}
        {!isEmptyOrNull(state.errorText) && (
          <Alert color={"danger"}>{state.errorText}</Alert>
        )}
        <ButtonBar
          buttons={[
            <Button
              key={"statusButton"}
              color={"primary"}
              onClick={() => finalise()}
              disabled={!canFinalise()}
            >
              {" "}
              {status.name}
            </Button>,
            <Button
              key={"cancelButton"}
              color={"secondary"}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

FinaliseUpload.propTypes = {
  selectedUpload: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default FinaliseUpload;

import { Col, Row } from "reactstrap";
import Moment from "moment";

const ReviewDob = ({ patient }) => {
  const renderPatientDetails = () => {
    let dob = Moment(
      patient.year + patient.month + patient.day,
      "YYYYMMMMDo",
    ).format("Do [of] MMMM[,] YYYY");
    return (
      <div className={"detail-line"}>
        <div>
          You were born on the <span className={"standout"}>{dob}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={"review"}>
      <Row>
        <Col xs={0} sm={1} md={1} lg={2} xl={3} />
        <Col className={"content"}>{renderPatientDetails()}</Col>
        <Col xs={0} sm={1} md={1} lg={2} xl={3} />
      </Row>
      <div className={"instruction-line"}>
        Please carefully review your <i>date of birth</i> details listed above.
        If they are correct, please select the <i>SUBMIT</i> button to continue.
        If anything is incorrect you may select the <i>PREVIOUS</i> button to
        correct the date.
      </div>
    </div>
  );
};

export default ReviewDob;

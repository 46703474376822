import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  missingValueMethods: [],
  scoringMethods: [],
  questionTypes: [],
};

export default handleActions(
  {
    MISSING_VALUE_METHODS_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ missingValueMethods: payload } };
    },
    INSTRUMENT_SCORING_METHODS_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ scoringMethods: payload } };
    },
    QUESTION_TYPES_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ questionTypes: payload } };
    },
  },
  INITIAL_STATE,
);

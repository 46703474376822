import {
  Alert,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  getPreviousAnswersForPatient,
  openPatient,
} from "../../../api/Patient";
import PatientDetailsModal from "./PatientDetailsModal";
import Procedures from "./Procedures";
import PatientReminders from "./PatientReminders";
import PatientNotes from "./PatientNotes";
import "moment/locale/en-au";
import PropTypes from "prop-types";
import {
  EDIT,
  isAdministratorUser,
  isAoaUser,
  isEmptyOrNull,
  isFollowUpUser,
  isHospitalAdministratorUser,
  isHospitalStudyCoordinatorUser,
  isSahmriUser,
  isSurgeonUser,
  VIEW,
} from "../../../Utils";
import classnames from "classnames";
import { useSelector } from "react-redux";
import CompletedCollections from "../../responses/CompletedCollections";
import PatientView from "../../compare/PatientView";
import CrfPatientTab from "../../crf/CrfPatientTab";
import { hasCrfReadAccess } from "../../crf/Utils";
import PhoneCalls from "./PhoneCalls";
import "./TabbedPatientModal.css";
import ConsentTab from "./ConsentTab";
import { useEffect, useReducer } from "react";

export const TABS = [
  "Details",
  "Procedures",
  "Consent",
  "Reminders",
  "Phone Calls",
  "Responses",
  "Reports",
  "Case Report Forms",
  "Notes",
];

const TabbedPatientModal = ({
  initialTab = null,
  modalOperation,
  hideActionButtons,
  onConfirm,
  patientId,
  onExit,
}) => {
  const user = useSelector((state) => state.user);
  const studies = useSelector((state) => state.studies);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      open: true,
      loadPatientError: "",
      currentlySubmitting: false,
      activeTab: TABS[0],
      modalPatientId: "",
      firstName: "",
      middleNames: "",
      lastName: "",
      dateOfDeath: "",
      dateOfBirth: "",
      deceased: false,
      postcode: "",
      homePhone: "",
      mobilePhone: "",
      email: "",
      error: "",
      checked: "",
      mergeRequired: false,
      mergeApproved: false,
      previousResponses: [],
    },
  );

  const handleSuccess = (message) => {
    onConfirm(message);
  };

  useEffect(() => {
    if (initialTab != null) {
      setState({ activeTab: initialTab });
    }

    if (modalOperation === EDIT || modalOperation === VIEW) {
      if (patientId !== null) {
        setState({ loadPatientError: "" });
        openPatient(patientId)
          .then((response) => {
            setState({
              firstName: response.data.firstName,
              middleNames: response.data.middleNames,
              lastName: response.data.lastName,
              dateOfBirth: response.data.dateOfBirth,
              postcode: response.data.postcode,
              email: response.data.email,
              homePhone: response.data.homePhone,
              mobilePhone: response.data.mobilePhone,
              deceased:
                response.data.deceased == null ? false : response.data.deceased,
              dateOfDeath: response.data.dateOfDeath,
              mergeRequired: response.mergeRequired,
              modalPatientId: patientId,
            });
          })
          .catch((error) => {
            if (error.response && isEmptyOrNull(error.response.data)) {
              setState({ loadPatientError: "Error loading patient" });
            } else {
              setState({
                loadPatientError: `Error loading patient. ${error.response}`,
              });
            }
          });
      }
    }

    getPreviousAnswersForPatient(patientId).then((response) => {
      setState({ previousResponses: response.data });
    });
  }, []);

  const toggle = () => {
    setState({ open: !state.open });
  };

  const setActiveTab = (tab) => {
    if (state.activeTab !== tab) {
      setState({ activeTab: tab });
    }
  };

  const showRemindersTab = (user) => {
    return (
      isAoaUser(user) ||
      isSahmriUser(user) ||
      isAdministratorUser(user) ||
      isFollowUpUser(user)
    );
  };

  const isPowerUser = (user) => {
    return isAoaUser(user) || isSahmriUser(user) || isAdministratorUser(user);
  };

  const showPhoneCallsTab = (user) => {
    return (
      isAoaUser(user) ||
      isSahmriUser(user) ||
      isAdministratorUser(user) ||
      isFollowUpUser(user)
    );
  };

  const showReportsTab = (user) => {
    if (
      isHospitalAdministratorUser(user) ||
      isHospitalStudyCoordinatorUser(user) ||
      isFollowUpUser(user)
    ) {
      return false;
    }

    return (
      !isSurgeonUser(user) ||
      (!!studies &&
        studies.some((study) =>
          study.studyComparableRoles.map(
            (compareRole) => compareRole.roleId === user.roleId,
          ),
        ))
    );
  };

  return (
    <div>
      <Modal
        isOpen={state.open}
        toggle={toggle}
        onClosed={onExit}
        keyboard={false}
        className={"tabbed-patient-modal"}
      >
        <ModalHeader toggle={toggle}>
          <span>{modalOperation} - Patient</span>
        </ModalHeader>
        <ModalBody>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: state.activeTab === TABS[0],
                  })}
                  onClick={() => {
                    setActiveTab(TABS[0]);
                  }}
                >
                  {TABS[0]}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: state.activeTab === TABS[1],
                  })}
                  onClick={() => {
                    setActiveTab(TABS[1]);
                  }}
                >
                  {TABS[1]}
                </NavLink>
              </NavItem>
              {isPowerUser(user) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: state.activeTab === TABS[2],
                    })}
                    onClick={() => {
                      setActiveTab(TABS[2]);
                    }}
                  >
                    {TABS[2]}
                  </NavLink>
                </NavItem>
              )}
              {showRemindersTab(user) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: state.activeTab === TABS[3],
                    })}
                    onClick={() => {
                      setActiveTab(TABS[3]);
                    }}
                  >
                    {TABS[3]}
                  </NavLink>
                </NavItem>
              )}
              {showPhoneCallsTab(user) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: state.activeTab === TABS[4],
                    })}
                    onClick={() => {
                      setActiveTab(TABS[4]);
                    }}
                  >
                    {TABS[4]}
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={classnames({
                    active: state.activeTab === TABS[5],
                  })}
                  onClick={() => {
                    setActiveTab(TABS[5]);
                  }}
                >
                  {TABS[5]}
                </NavLink>
              </NavItem>
              {state.previousResponses.length > 0 && showReportsTab(user) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: state.activeTab === TABS[6],
                    })}
                    onClick={() => {
                      setActiveTab(TABS[6]);
                    }}
                  >
                    {TABS[6]}
                  </NavLink>
                </NavItem>
              )}
              {hasCrfReadAccess(user) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: state.activeTab === TABS[7],
                    })}
                    onClick={() => {
                      setActiveTab(TABS[7]);
                    }}
                  >
                    {TABS[7]}
                  </NavLink>
                </NavItem>
              )}
              {isPowerUser(user) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: state.activeTab === TABS[8],
                    })}
                    onClick={() => {
                      setActiveTab(TABS[8]);
                    }}
                  >
                    {TABS[8]}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={state.activeTab}>
              <TabPane tabId={TABS[0]}>
                <PatientDetailsModal
                  onConfirm={handleSuccess}
                  onExit={onExit}
                  hideActionButtons={hideActionButtons}
                  operation={modalOperation}
                  patientId={patientId}
                />
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <Alert
                      color="danger"
                      isOpen={!isEmptyOrNull(state.loadPatientError)}
                    >
                      {state.loadPatientError}
                    </Alert>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={TABS[1]}>
                <Procedures
                  onConfirm={handleSuccess}
                  onExit={onExit}
                  patientId={patientId}
                  open={state.open}
                  operation={isFollowUpUser(user) ? VIEW : modalOperation}
                />
              </TabPane>
              {isPowerUser(user) && (
                <TabPane tabId={TABS[2]}>
                  <ConsentTab
                    patientId={patientId}
                    patientPurgedCallback={handleSuccess}
                  />
                </TabPane>
              )}
              {showRemindersTab(user) && (
                <TabPane tabId={TABS[3]}>
                  <PatientReminders
                    onExit={onExit}
                    onConfirm={handleSuccess}
                    operation={modalOperation}
                    patientId={patientId}
                    open={state.open}
                  />
                </TabPane>
              )}
              {showPhoneCallsTab(user) && (
                <TabPane tabId={TABS[4]}>
                  <PhoneCalls patientId={patientId} />
                </TabPane>
              )}
              <TabPane tabId={TABS[5]}>
                <CompletedCollections
                  previousResponses={state.previousResponses}
                  toggle={onExit}
                  isOpen={true}
                  patientView={false}
                  modal={false}
                  onConfirm={handleSuccess}
                />
              </TabPane>
              {!(
                isHospitalAdministratorUser(user) ||
                isHospitalStudyCoordinatorUser(user)
              ) && (
                <TabPane tabId={TABS[6]}>
                  {state.previousResponses.length > 0 && (
                    <PatientView
                      adminData={state.previousResponses}
                      patientView={false}
                      patientId={patientId}
                    />
                  )}
                </TabPane>
              )}
              {hasCrfReadAccess(user) && (
                <TabPane tabId={TABS[7]}>
                  <CrfPatientTab
                    onExit={onExit}
                    onConfirm={handleSuccess}
                    patientId={patientId}
                  />
                </TabPane>
              )}
              {isPowerUser(user) && (
                <TabPane tabId={TABS[8]}>
                  <PatientNotes patientId={patientId} />
                </TabPane>
              )}
            </TabContent>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

TabbedPatientModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  modalOperation: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired,
  initialTab: PropTypes.string,
  initialProcedureId: PropTypes.number,
  hideActionButtons: PropTypes.bool,
};

export default TabbedPatientModal;

import { Col, Container, Row } from "reactstrap";
import LargeNavButton from "./LargeNavButton";
import {
  FaChartArea,
  FaChartBar,
  FaDownload,
  FaMedkit,
  FaUpload,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Tasks from "../task/Tasks";
import { useEffect } from "react";
import { loadUserCurrent } from "../../actions/UserActions";

const SurgeonHome = (props) => {
  const { showResults, showReporting } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadUserCurrent);
  });

  return (
    <Container>
      <Row>
        <Col xs={"1"} />
        <Col xs={10}>
          <span className={"welcome-text aoa-title"}>
            {" "}
            Welcome {user.found ? user.firstName + " " + user.lastName : ""}
          </span>
        </Col>
        <Col xs={"1"} />
      </Row>
      <Tasks className={"mt-4"} />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <LargeNavButton
            to={"/patient/management"}
            icon={<FaMedkit />}
            title={"Existing Patients"}
            subtitle={
              "Click here to manage existing patients already in the system"
            }
          />
        </Col>
      </Row>
      {showReporting && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <LargeNavButton
              to={"/reporting"}
              icon={<FaChartBar />}
              title={"Reporting"}
              subtitle={"Click here to view recruitment data"}
            />
          </Col>
        </Row>
      )}
      {showResults && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <LargeNavButton
              to={"/compare"}
              icon={<FaChartArea />}
              title={"How Do My Patients Compare"}
              subtitle={
                "Click here to review your patients PROMs compared to National Results"
              }
            />
          </Col>
        </Row>
      )}
      {user.uploader && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <LargeNavButton
              to={"/uploads"}
              icon={<FaUpload />}
              title={"Upload Patient Responses"}
              subtitle={
                "Click here to upload patient responses you have collected externally"
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <LargeNavButton
            to={"/surgeon/download"}
            icon={<FaDownload />}
            title={"Download Patient Responses"}
            subtitle={
              "Click here to download an excel spreadsheet of your patient responses"
            }
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SurgeonHome;
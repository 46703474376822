import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import PropTypes from "prop-types";
import { getDisplayNameForProcedureType, isEmptyOrNull } from "../../Utils";
import "./EnrolmentConfirmModal.css";
import ButtonBar from "../common/ButtonBar";
import Moment from "moment";
import { useEffect, useState } from "react";
import { usePrevious } from "../CustomHooks";
import { newProcedureFieldsToExclude } from "./Utils";

const dateFormat = "DD/MM/YYYY";

const EnrolmentConfirmModal = ({
  open,
  confirmCallback,
  exitCallback,
  patient,
  showEmailWarning,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const prevOpenRef = usePrevious(open);

  useEffect(() => {
    if (!prevOpenRef && open) {
      setSubmitting(false);
    }
  });

  const handleConfirm = () => {
    setSubmitting(true);
    confirmCallback();
  };

  return (
    <Modal isOpen={open}>
      <ModalHeader>Confirm Patient Enrolment</ModalHeader>
      {patient !== null && (
        <ModalBody className={"enrolment-confirm"}>
          <p>
            Please review the patient registration details below. If any details
            are incorrect please select <i>CANCEL</i> to correct them. If
            everything is correct then please select <i>SUBMIT</i>.
          </p>
          <Row className={"field-row"}>
            <Col sm={4} className={"left-column"}>
              Hospital
            </Col>
            <Col>{patient.newProcedure.hospital.name}</Col>
          </Row>
          <Row className={"field-row"}>
            <Col sm={4} className={"left-column"}>
              Surgeon
            </Col>
            <Col>
              {patient.newProcedure.surgeon === null ? (
                <i>Unknown</i>
              ) : (
                `Dr ${patient.newProcedure.surgeon.firstName} ${patient.newProcedure.surgeon.lastName}`
              )}
            </Col>
          </Row>
          {patient.newProcedure.scheduledProcedureDate != null && (
            <Row className={"field-row"}>
              <Col sm={4} className={"left-column"}>
                Scheduled Procedure Date
              </Col>
              <Col>
                {Moment(patient.newProcedure.scheduledProcedureDate).format(
                  dateFormat,
                )}
              </Col>
            </Row>
          )}
          <Row className={"field-row"}>
            <Col sm={4} className={"left-column"}>
              Procedure Types/Sides
            </Col>
            <Col>
              <ul>
                {Object.keys(patient.newProcedure).map((key) => {
                  if (
                    !newProcedureFieldsToExclude.includes(key) &&
                    patient.newProcedure[key]
                  ) {
                    return (
                      <li key={key}>{getDisplayNameForProcedureType(key)}</li>
                    );
                  }
                })}
              </ul>
            </Col>
          </Row>
          <Row className={"field-row"}>
            <Col sm={4} className={"left-column"}>
              Studies
            </Col>
            <Col>
              <ul>
                {patient.studies.map((study, i) => (
                  <li key={"study-li" + i}>{study.name}</li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row className={"field-row"}>
            <Col sm={4} className={"left-column"}>
              First Name
            </Col>
            <Col>{patient.firstName}</Col>
          </Row>
          {!isEmptyOrNull(patient.middleNames) && (
            <Row className={"field-row"}>
              <Col sm={4} className={"left-column"}>
                Middle Name
              </Col>
              <Col>{patient.middleNames}</Col>
            </Row>
          )}
          <Row className={"field-row"}>
            <Col sm={4} className={"left-column"}>
              Last Name
            </Col>
            <Col>{patient.lastName}</Col>
          </Row>
          <Row className={"field-row"}>
            <Col sm={4} className={"left-column"}>
              Date of Birth
            </Col>
            <Col>
              {!!patient.dateOfBirth
                ? patient.dateOfBirth.format("DD/MM/YYYY")
                : null}
            </Col>
          </Row>
          <Row className={"field-row"}>
            <Col sm={4} className={"left-column"}>
              Postcode
            </Col>
            <Col>{patient.postcode}</Col>
          </Row>
          {!isEmptyOrNull(patient.mobilePhone) && (
            <Row className={"field-row"}>
              <Col sm={4} className={"left-column"}>
                Mobile
              </Col>
              <Col>{patient.mobilePhone}</Col>
            </Row>
          )}
          {!isEmptyOrNull(patient.homePhone) && (
            <Row className={"field-row"}>
              <Col sm={4} className={"left-column"}>
                Home Phone
              </Col>
              <Col>{patient.homePhone}</Col>
            </Row>
          )}
          {!isEmptyOrNull(patient.email) && (
            <Row className={"field-row"}>
              <Col sm={4} className={"left-column"}>
                Email
              </Col>
              <Col>{patient.email}</Col>
            </Row>
          )}
          {showEmailWarning && (
            <Row className={"field-row"}>
              <Col sm={4} className={"left-column"} />
              <Col className={"email-warning"}>
                Please double-check the patient's email address before
                proceeding as it is unusual.
              </Col>
            </Row>
          )}
          <ButtonBar
            buttons={[
              <Button
                color="primary"
                onClick={handleConfirm}
                disabled={submitting}
              >
                Submit
              </Button>,
              <Button onClick={exitCallback}>Cancel</Button>,
            ]}
          />
        </ModalBody>
      )}
    </Modal>
  );
};

EnrolmentConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  exitCallback: PropTypes.func.isRequired,
  patient: PropTypes.object,
  showEmailWarning: PropTypes.bool.isRequired,
};

export default EnrolmentConfirmModal;

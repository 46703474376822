import { get } from "./Api";

const BASE_URL = "/api/registry-procedure";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function getFuzzyMatches(request) {
  return get(
    url(`fuzzy-matches`),
    request,
    "Unable to fetch fuzzy match procedures at this time.",
  );
}

export function getBetterMatches(payload) {
  return get(
    url(`better-matches`),
    payload,
    "Unable to fetch better match procedures at this time.",
  );
}

import {
  Alert,
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { deleteProcedureCollections } from "../../api/Procedures";
import GenericSelector from "../common/GenericSelector";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import ButtonBar from "../common/ButtonBar";
import { useState } from "react";

const ConfirmDeleteProcedureCollectionModal = ({
  confirmCallback,
  exitCallback,
  procedureCollections,
  procedureId,
}) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);
  const [validation, setValidation] = useState("");
  const [error, setError] = useState(null);

  const handleDelete = () => {
    if (!!selected && !!procedureId) {
      dispatch(startLoading());
      setError(null);
      deleteProcedureCollections(procedureId, [selected.first])
        .then((response) => {
          if (response?.data?.success) {
            confirmCallback(
              "Procedure Collection with ID: " +
                selected.first +
                " deleted successfully",
            );
          } else {
            let message = "Error deleting the procedure collection";
            if (response?.data?.message && response.data.message.length > 1) {
              message = response.data.message;
            }
            setError(message);
          }
        })
        .finally(() => dispatch(finishLoading()));
    }
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader>Confirm Procedure Collection Deletion</ModalHeader>
      <ModalBody className={"delete-confirm"}>
        <p>Select a Procedure Collection to delete:</p>
        <Row className={"my-2"}>
          <Col>
            <GenericSelector
              options={procedureCollections}
              selected={selected}
              idPropertyName={"first"}
              labelPropertyName={"second"}
              changeCallback={(value) => setSelected(value)}
              renderer={(item) => `ID: ${item.first} - ${item.second}`}
            />
          </Col>
        </Row>

        {selected && (
          <FormGroup>
            <Input
              type={"text"}
              placeholder={"Enter ID"}
              value={validation}
              onChange={(event) => setValidation(event.target.value)}
            />
            <FormText>
              To confirm this deletion please type the ID of the procedure
              collection to be deleted into this box
            </FormText>
          </FormGroup>
        )}
        {error && <Alert color={"danger"}>{error}</Alert>}
        <ButtonBar
          buttons={[
            <Button
              key={"delete-button"}
              color="danger"
              onClick={handleDelete}
              disabled={
                selected == null || validation !== selected.first.toString()
              }
            >
              Delete
            </Button>,
            <Button key={"cancel-button"} onClick={exitCallback}>
              Cancel
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

ConfirmDeleteProcedureCollectionModal.propTypes = {
  confirmCallback: PropTypes.func.isRequired,
  exitCallback: PropTypes.func.isRequired,
  procedureCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
  procedureId: PropTypes.number.isRequired,
};

export default ConfirmDeleteProcedureCollectionModal;

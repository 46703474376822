import classnames from "classnames";
import PropTypes from "prop-types";
import "./SimpleFooter.css";

const SimpleFooter = ({ showLinks = false }) => {
  return (
    <footer className="simple-footer">
      <div className={classnames(showLinks ? "left" : null)}>
        {showLinks ? (
          <a href="https://aoanjrr.sahmri.com" title="Registry Homepage">
            AOANJRR
          </a>
        ) : (
          <span> </span>
        )}
      </div>
      <div className={classnames(showLinks ? "right" : null)}>
        {showLinks ? (
          <a href="https://aoa.org.au" title="AOA Homepage">
            AOA
          </a>
        ) : (
          <span> </span>
        )}
      </div>
    </footer>
  );
};

SimpleFooter.propTypes = {
  showLinks: PropTypes.bool,
};

export default SimpleFooter;

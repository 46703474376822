import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { mergePatient } from "../../../api/Patient";
import { isEmptyOrNull } from "../../../Utils";
import ButtonBar from "../../common/ButtonBar";
import { useReducer } from "react";

const PatientMergeConfirmationModal = ({
  confirmCallback,
  patientId,
  exitCallback,
  showModal,
  patient,
}) => {
  // TODO should state be used?
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      currentlySubmitting: false,
      mergeRequired: false,
      mergeApproved: false,
    },
  );

  const confirmMerge = () => {
    const payload = {
      firstName: patient.firstName,
      middleNames: patient.middleNames,
      lastName: patient.lastName,
      dateOfBirth: patient.dateOfBirth,
      postcode: patient.postcode,
      deceased: patient.deceased,
      dateOfDeath: patient.dateOfDeath,
      homePhone: patient.homePhone,
      mobilePhone: patient.mobilePhone,
      email: patient.email,
      reminderOptOut: patient.reminderOptOut,
      mergedPatientId: patient.mergedPatientId,
    };
    setState({ currentlySubmitting: true });
    mergePatient(patientId, payload)
      .then(() => {
        setState({
          mergeRequired: true,
          mergeApproved: true,
          showModal: false,
        });
        confirmCallback(
          "The two patient records were merged successfully",
          patient.mergedPatientId,
        );
      })
      .catch((error) => {
        if (isEmptyOrNull(error?.response?.data)) {
          setState({ error: "Error merging the patient" });
        } else {
          setState({ error: error.response });
        }
      })
      .finally(() => setState({ currentlySubmitting: false }));
  };

  return (
    <Modal isOpen={showModal}>
      <ModalHeader>Confirm Patient Merge</ModalHeader>
      <ModalBody className={"merge-confirm"}>
        <div>
          Please carefully review the details of the patient your are about to{" "}
          <b>delete</b>. Any information you have chosen to merge from this
          patient will be assigned to the remaining patient along with their
          procedures and consented studies (where appropriate). If you are sure
          that those details are correct click the <i>MERGE</i> button to
          continue, otherwise select the <i>CANCEL</i> button.
        </div>
        <ButtonBar
          buttons={[
            <Button color="primary" onClick={confirmMerge}>
              Merge
            </Button>,
            <Button onClick={exitCallback}>Cancel</Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

PatientMergeConfirmationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  exitCallback: PropTypes.func.isRequired,
  patientId: PropTypes.number,
  patient: PropTypes.object,
};

export default PatientMergeConfirmationModal;

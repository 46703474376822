import { isAdministratorUser, isAoaUser, isSahmriUser } from "../../Utils";
import Moment from "moment/moment";

export const canUpload = (user) => {
  return user.uploader || isAoaUser(user);
};

export const hasManagementAccess = (user) => {
  return isAoaUser(user) || isSahmriUser(user) || isAdministratorUser(user);
};

export const renderErrorMessageForFileUpload = (error) => {
  let message =
    "An error occurred while processing the external responses file.";
  if (error.response?.data?.message) {
    return (
      <span>
        {message}
        <br />
        <b>Error Message:</b>
        <br />
        <i>{error.response.data.message}</i>
      </span>
    );
  } else if (error.response?.data) {
    return (
      <span>
        {message}
        <br />
        <b>Error Message:</b>
        <br />
        <i>{error.response.data}</i>
      </span>
    );
  }
  return message;
};

export const formatDate = (date) => {
  if (date != null) {
    return Moment(date).format("DD/MM/YYYY");
  }
  return null;
};

export const formatDateTime = (date) => {
  if (date != null) {
    return Moment(date).format("DD/MM/YYYY @ h:mm A");
  }
  return null;
};

import { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { loadUserCurrent, userLogout } from "../../actions/UserActions";
import Header from "./Header";
import Admin from "./Administrator";
import Responsive from "react-responsive";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AoaMenu } from "./Aoa";
import { Followup } from "./Followup";
import { HospitalAdministrator } from "./HospitalAdministrator";
import { canCompareAllStudies } from "../compare/Utils";
import { StudyCoordinator } from "./StudyCoordinator";
import { Patient } from "./Patient";
import "./Dashboard.css";
import { SahmriMenu } from "./Sahmri";
import { Surgeon } from "./Surgeon";
import {
  isEmptyOrNull,
  isFollowUpUser,
  isStakeholderUser,
  isStudyCoordinatorUser,
} from "../../Utils";
import SimpleFooter from "../common/SimpleFooter";
import AnnouncementDetails from "../app/AnnouncementDetails";
import { hasStakeholderLogo } from "../../api/StakeholderGroup";
import { Stakeholder } from "./Stakeholder";
import { loadStudies } from "../../actions/CommonActions";
import HelpModal from "../common/HelpModal";
import AddMobileNumberModal from "../management/stakeholder/AddMobileNumberModal";
import { usePageTracking } from "../CustomHooks";

const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const user = useSelector((state) => state.user);
  const patient = useSelector((state) => state.patient);
  const studies = useSelector((state) => state.studies);

  const [menuOpen, setMenuOpen] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [logoIds, setLogoIds] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showReporting, setShowReporting] = useState(false);

  usePageTracking();

  useEffect(() => {
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useEffect(() => {
    if (!!studies) {
      let newShowResults = studies.map((study) =>
        study.studyComparableRoles.some(
          (comparableRole) => comparableRole.roleId === user.roleId,
        ),
      );
      newShowResults =
        newShowResults.some((r) => r === true) || canCompareAllStudies(user);
      let newShowReporting = studies.map((study) =>
        study.studyReportableRoles.some(
          (reportableRole) => reportableRole.roleId === user.roleId,
        ),
      );
      newShowReporting = newShowReporting.some((r) => r === true);
      if (
        newShowResults !== showResults ||
        newShowReporting !== showReporting
      ) {
        setShowResults(newShowResults);
        setShowReporting(newShowReporting);
      }
    }
  }, [studies]);

  useEffect(() => {
    if (user != null && user.stakeholderGroupIds != null) {
      user.stakeholderGroupIds.forEach((id) => {
        hasStakeholderLogo(id).then(() => {
          setLogoIds([...logoIds, id]);
        });
      });
    }
  }, []);

  const reloadUser = () => {
    dispatch(loadUserCurrent());
  };

  const toggleSideMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleHelp = () => {
    setMenuOpen(false);
    setShowHelp(!showHelp);
  };

  const showVertMenu = () => {
    if (patient.id) return false;
    return !!(user.found && !isFollowUpUser(user));
  };

  const userLogoutCallback = () => {
    dispatch(userLogout());
  };

  const _getDefinition = () => {
    let definition = {};
    if (user.found) {
      switch (user.roleName) {
        case "Administrator":
          definition = Admin(
            menuOpen,
            toggleSideMenu,
            userLogoutCallback,
            toggleHelp,
            showResults,
          );
          break;
        case "AOA":
          definition = AoaMenu(
            menuOpen,
            toggleSideMenu,
            userLogoutCallback,
            toggleHelp,
            showResults,
          );
          break;
        case "Follow-Up":
          definition = Followup(
            menuOpen,
            toggleSideMenu,
            userLogoutCallback,
            toggleHelp,
          );
          break;
        case "Hospital Administrator":
          definition = HospitalAdministrator(
            menuOpen,
            toggleSideMenu,
            userLogoutCallback,
            toggleHelp,
            user.uploader,
            showReporting,
            showResults,
          );
          break;
        case "Hospital Study Coordinator":
          definition = HospitalAdministrator(
            menuOpen,
            toggleSideMenu,
            userLogoutCallback,
            toggleHelp,
            user.uploader,
            showReporting,
          );
          break;
        case "SAHMRI":
          definition = SahmriMenu(
            menuOpen,
            toggleSideMenu,
            userLogoutCallback,
            toggleHelp,
            showResults,
          );
          break;
        case "Surgeon":
          definition = Surgeon(
            menuOpen,
            toggleSideMenu,
            userLogoutCallback,
            toggleHelp,
            showResults,
            showReporting,
            user.uploader,
          );
          break;
        case "Stakeholder":
          definition = Stakeholder(
            menuOpen,
            toggleSideMenu,
            userLogoutCallback,
            toggleHelp,
            showResults,
          );
          break;
        case "Study Coordinator":
          definition = StudyCoordinator(
            menuOpen,
            toggleSideMenu,
            userLogoutCallback,
            toggleHelp,
          );
          break;
        default:
          break;
      }
    } else if (patient.id) {
      definition = Patient(
        menuOpen,
        toggleSideMenu,
        userLogoutCallback,
        toggleHelp,
        location.pathname.includes("dashboard"),
        location.pathname.includes("compare"),
      );
    }
    return definition;
  };

  const isSmsMfaUser =
    isStakeholderUser(user) ||
    isFollowUpUser(user) ||
    isStudyCoordinatorUser(user);
  let config = _getDefinition();
  return (
    <div className={"dashboard map-background"}>
      <AnnouncementDetails />
      {showHelp && <HelpModal closeCallback={toggleHelp} />}
      {isSmsMfaUser && (
        <AddMobileNumberModal
          isOpen={isEmptyOrNull(user.mobile)}
          saveCallback={reloadUser}
        />
      )}
      <div className={"dashboard-top"}>
        <Responsive maxWidth={576}>
          <Row className={"top-row g-0"}>
            <Col xs={4}>{config.menuCombined}</Col>
            <Col xs={8}>
              <Header logoIds={logoIds} helpToggleCallback={toggleHelp} />
            </Col>
          </Row>
        </Responsive>
        <Responsive maxWidth={799} minWidth={577}>
          <Row className={"top-row g-0"}>
            <Col sm={2}>{config.menuCombined}</Col>
            <Col sm={10}>
              <Header logoIds={logoIds} helpToggleCallback={toggleHelp} />
            </Col>
          </Row>
        </Responsive>
        <Responsive maxWidth={991} minWidth={800}>
          <Row className={"top-row g-0"}>
            <Col sm={1}>{config.menuClosing}</Col>
            <Col sm={11}>
              <Header logoIds={logoIds} helpToggleCallback={toggleHelp} />
            </Col>
          </Row>
        </Responsive>
        <Responsive minWidth={992}>
          <Row className={"top-row g-0"}>
            <Header logoIds={logoIds} helpToggleCallback={toggleHelp} />
          </Row>
        </Responsive>
      </div>
      <Container className={"dashboard-container"}>
        <div className={"dashboard-bottom"}>
          <Responsive minWidth={992}>
            {showVertMenu() && (
              <div className={"vert-menu-holder"}>{config.menu}</div>
            )}
          </Responsive>
          <div className={showVertMenu() ? "content" : "content-large"}>
            {config.switchRoute}
          </div>
        </div>
      </Container>
      <SimpleFooter />
    </div>
  );
};

export default Dashboard;

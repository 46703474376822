import { handleActions } from "redux-actions";
import Configuration from "../components/compare/Configuration";

const INITIAL_STATE = {
  reports: [],
  bins: [],
  seriesTypes: [],
  subscores: [],
  roles: [],
  texts: [],
  positions: [],
  viewingRole: Configuration.DUMMY_PATIENT_ROLE,
};

export default handleActions(
  {
    INSTRUMENT_REPORTS_LOADED: (state, { payload }) => {
      // Sort by role index
      let reports = [...payload];
      reports.sort((r1, r2) => {
        let rr1 = r1.roles.filter(
          (rr) => rr.roleId === state.viewingRole.roleId,
        )[0];
        let rr2 = r2.roles.filter(
          (rr) => rr.roleId === state.viewingRole.roleId,
        )[0];
        return rr1.index > rr2.index ? 1 : rr1.index < rr2.index ? -1 : 0;
      });
      return { ...state, reports };
    },
    INSTRUMENT_BINS_LOADED: (state, { payload }) => ({
      ...state,
      bins: payload,
    }),
    GRAPH_TYPES_LOADED: (state, { payload }) => ({
      ...state,
      seriesTypes: payload,
    }),
    SUBSCORE_TYPES_LOADED: (state, { payload }) => ({
      ...state,
      subscores: payload,
    }),
    ALL_ROLES_LOADED: (state, { payload }) => ({ ...state, roles: payload }),
    ALL_TEXT_LOADED: (state, { payload }) => ({ ...state, texts: payload }),
    ALL_POSITIONS_LOADED: (state, { payload }) => ({
      ...state,
      positions: payload,
    }),
    SWITCH_REPORT_ROLE: (state, { payload }) => ({
      ...state,
      viewingRole: payload,
    }),
  },
  INITIAL_STATE,
);

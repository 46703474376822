import PropTypes from "prop-types";
import Select from "react-select";
import { getStartCase } from "../../Utils";
import { loadProcedureTypes } from "../../actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export const ALL_PROCEDURE_TYPES = { id: 0, name: "All Procedure Types" };

const SimpleProcedureTypeSelector = ({
  placeholder = "Select...",
  clearable = false,
  disabled = false,
  searchable = false,
  autoFocus = true,
  tabIndex = null,
  filterByProcedureTypeIds = null,
  includeAllProcedureTypes = false,
  onChange,
  value,
}) => {
  const dispatch = useDispatch();
  const procedureTypes = useSelector((state) => state.procedureTypes);

  useEffect(() => {
    procedureTypes.length === 0 && dispatch(loadProcedureTypes());
  }, [dispatch]);

  const renderOption = (option) => {
    if (option.id === ALL_PROCEDURE_TYPES.id) {
      return <b>{option.name}</b>;
    }
    return getStartCase(option.displayName ? option.displayName : null);
  };

  const getOptions = () => {
    const result = procedureTypes.slice();
    if (includeAllProcedureTypes) {
      result.unshift(ALL_PROCEDURE_TYPES);
    }
    return result.filter(
      (pt) =>
        pt === ALL_PROCEDURE_TYPES ||
        !filterByProcedureTypeIds ||
        (filterByProcedureTypeIds.length > 0 &&
          filterByProcedureTypeIds.includes(pt.id)),
    );
  };

  return (
    <div>
      <Select
        options={getOptions()}
        value={value}
        getOptionLabel={(option) => renderOption(option)}
        getOptionValue={(option) => option.id}
        autoFocus={autoFocus}
        onChange={onChange}
        isSearchable={searchable}
        isClearable={clearable}
        placeholder={placeholder}
        isDisabled={disabled}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
        tabIndex={tabIndex}
      />
    </div>
  );
};

SimpleProcedureTypeSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.number,
  filterByProcedureTypeIds: PropTypes.arrayOf(PropTypes.number),
  includeAllProcedureTypes: PropTypes.bool,
};

export default SimpleProcedureTypeSelector;

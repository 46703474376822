import { Fragment, useState } from "react";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import "./BetterMatchingModal.css";
import { FaExclamationCircle, FaRetweet, FaTimes } from "react-icons/fa";
import Pagination from "react-js-pagination";
import Moment from "moment/moment";
import { getStartCase, isEmptyOrNull } from "../../Utils";
import ButtonBar from "../common/ButtonBar";

const BetterMatchesModal = ({
  onExit = () => null,
  changePageCallback = () => null,
  neverMatchCallback = (sourceIdCurrentMatch, procedureId) => null,
  swapCallback = () => null,
  open = false,
  data = [],
  itemsCountPerPage = 10,
  totalItemsCount = 0,
  activePage = 1,
  pageRangeDisplayed = 5,
}) => {
  const [toolTipId, setToolTipId] = useState(null);

  const showNeverMatchCallBack = (
    isCurrentMatch,
    procedureId,
    sourceIdCurrentMatch,
    sourceIdPotentialMatch,
  ) => {
    if (isCurrentMatch) {
      neverMatchCallback(sourceIdCurrentMatch, procedureId);
    } else {
      neverMatchCallback(sourceIdPotentialMatch, procedureId);
    }
  };

  const getActionButtons = (
    row,
    index,
    isRegistryProcedure,
    isCurrentMatch,
  ) => {
    return (
      <Fragment>
        {!isRegistryProcedure && !row.isAlreadyMatched && (
          <Button
            className={"action-button"}
            outline
            color={"primary"}
            title={"Swap the matches"}
            onClick={() =>
              swapCallback(
                row.procedureId,
                row.currentMatchId,
                row.potentialMatchId,
              )
            }
          >
            <FaRetweet />
          </Button>
        )}
        {isRegistryProcedure && (
          <Button
            className={"action-button"}
            outline
            color={"danger"}
            title={
              "Add the PROMs procedure and Registry Procedure as a never match"
            }
            onClick={() => {
              showNeverMatchCallBack(
                isCurrentMatch,
                row.procedureId,
                row.currentMatchId,
                row.potentialMatchId,
              );
            }}
          >
            <FaTimes />
          </Button>
        )}
        {isRegistryProcedure && !isCurrentMatch && row.isAlreadyMatched && (
          <span>
            <span key={`other-matches-span-${index}`}>
              <a id={`other-matches-link-${index}`} href={"#"}>
                <FaExclamationCircle key={`icon-${index}`} color="red" />
              </a>
            </span>
            <Tooltip
              key={`tooltip-${index}`}
              placement="top"
              isOpen={toolTipId === index}
              target={`other-matches-link-${index}`}
              toggle={() => setToolTipId(toolTipId === index ? null : index)}
            >
              This registry procedure is already matched to another PROMs
              procedure. You must unmatch it before the match can be swapped.
            </Tooltip>
          </span>
        )}
      </Fragment>
    );
  };

  const getCellClass = (data, key, isCurrentMatch, valuesAreDates) => {
    if (isCurrentMatch) {
      if (valuesAreDates) {
        return Moment(data[key + "CurrentMatch"]).format("DD/MM/YYYY") ===
          Moment(data[key + "Proms"]).format("DD/MM/YYYY")
          ? null
          : "highlight-cell";
      } else if (
        isEmptyOrNull(data[key + "CurrentMatch"]) ||
        isEmptyOrNull(data[key + "Proms"])
      ) {
        return "highlight-cell";
      } else {
        return data[key + "CurrentMatch"].toLocaleUpperCase() ===
          data[key + "Proms"].toLocaleUpperCase()
          ? null
          : "highlight-cell";
      }
    } else {
      if (valuesAreDates) {
        return Moment(data[key + "PotentialMatch"]).format("DD/MM/YYYY") ===
          Moment(data[key + "Proms"]).format("DD/MM/YYYY")
          ? null
          : "highlight-cell";
      } else if (
        isEmptyOrNull(data[key + "PotentialMatch"]) ||
        isEmptyOrNull(data[key + "Proms"])
      ) {
        return "highlight-cell";
      } else {
        return data[key + "PotentialMatch"].toLocaleUpperCase() ===
          data[key + "Proms"].toLocaleUpperCase()
          ? null
          : "highlight-cell";
      }
    }
  };

  return (
    <Modal
      isOpen={open}
      toggle={onExit}
      className={"better-matching-modal"}
      keyboard={false}
    >
      <ModalHeader toggle={onExit}>Better Matches</ModalHeader>
      <ModalBody>
        <i>
          Top row is the PROMs procedure, middle row is the current matched
          registry procedure and bottom row is the potentially better match
          registry procedure
        </i>
        <Fragment>
          <Row className={"better-matching-table"}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Middle Name(s)</th>
                  <th>Last Name</th>
                  <th>DOB</th>
                  <th>Postcode</th>
                  <th>Hospital</th>
                  <th>Procedure Type</th>
                  <th>Side</th>
                  <th>Surgeon</th>
                  <th>Revision</th>
                  <th>Procedure/Source Id</th>
                  <th>Procedure/Registered Date</th>
                  <th>Score</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className={"table-body"}>
                {data.map((row, i) => {
                  return (
                    <Fragment key={`row-fragment-${i}`}>
                      <tr
                        key={`registry-row-${i}`}
                        className={i % 2 === 0 ? "stripe-row" : null}
                      >
                        <td>{getStartCase(row.firstNameProms)}</td>
                        <td>{getStartCase(row.middleNamesProms)}</td>
                        <td>{getStartCase(row.lastNameProms)}</td>
                        <td>
                          {Moment(row.dateOfBirthProms).format("DD/MM/YYYY")}
                        </td>
                        <td>{row.postcodeProms}</td>
                        <td>{row.hospitalProms}</td>
                        <td>{getStartCase(row.procedureTypeProms)}</td>
                        <td>{getStartCase(row.sideProms)}</td>
                        <td>{row.surgeonProms}</td>
                        <td></td>
                        <td>{row.procedureId}</td>
                        <td>
                          {Moment(row.procedureDate).format("DD/MM/YYYY")}
                        </td>
                        <td></td>
                        <td rowSpan={1}>
                          {getActionButtons(row, i, false, false)}
                        </td>
                      </tr>
                      <tr
                        key={`current-match-row-${i}`}
                        className={i % 2 === 0 ? "stripe-row" : null}
                      >
                        <td className={getCellClass(row, "firstName", true)}>
                          {getStartCase(row.firstNameCurrentMatch)}
                        </td>
                        <td className={getCellClass(row, "middleNames", true)}>
                          {getStartCase(row.middleNamesCurrentMatch)}
                        </td>
                        <td className={getCellClass(row, "lastName", true)}>
                          {getStartCase(row.lastNameCurrentMatch)}
                        </td>
                        <td
                          className={getCellClass(
                            row,
                            "dateOfBirth",
                            true,
                            true,
                          )}
                        >
                          {Moment(row.dateOfBirthCurrentMatch).format(
                            "DD/MM/YYYY",
                          )}
                        </td>
                        <td className={getCellClass(row, "postcode", true)}>
                          {row.postcodeCurrentMatch}
                        </td>
                        <td className={getCellClass(row, "hospital", true)}>
                          {row.hospitalCurrentMatch}
                        </td>
                        <td
                          className={getCellClass(row, "procedureType", true)}
                        >
                          {getStartCase(row.procedureTypeCurrentMatch)}
                        </td>
                        <td className={getCellClass(row, "side", true)}>
                          {getStartCase(row.sideCurrentMatch)}
                        </td>
                        <td className={getCellClass(row, "surgeon", true)}>
                          {row.surgeonCurrentMatch}
                        </td>
                        <td className={"centered"}>
                          {" "}
                          {row.currentMatchRevision && (
                            <Badge color={"danger"}>Revision</Badge>
                          )}
                          {!row.currentMatchRevision && (
                            <Badge color={"primary"}>Primary</Badge>
                          )}
                        </td>
                        <td>{row.currentMatchId}</td>
                        <td
                          className={
                            Moment(row.dateRegisteredCurrentMatch).isBefore(
                              Moment(row.procedureDate),
                            )
                              ? "highlight-cell"
                              : null
                          }
                        >
                          {Moment(row.dateRegisteredCurrentMatch).format(
                            "DD/MM/YYYY",
                          )}
                        </td>
                        <td
                          className={
                            row.currentMatchScore < 80 ? "highlight-cell" : null
                          }
                        >
                          {row.currentMatchScore}
                        </td>
                        <td>{getActionButtons(row, i, true, true)}</td>
                      </tr>
                      <tr
                        key={`proms-row-${i}`}
                        className={i % 2 === 0 ? "stripe-row" : null}
                      >
                        <td className={getCellClass(row, "firstName", false)}>
                          {getStartCase(row.firstNamePotentialMatch)}
                        </td>
                        <td className={getCellClass(row, "middleNames", false)}>
                          {getStartCase(row.middleNamesPotentialMatch)}
                        </td>
                        <td className={getCellClass(row, "lastName", false)}>
                          {getStartCase(row.lastNamePotentialMatch)}
                        </td>
                        <td
                          className={getCellClass(
                            row,
                            "dateOfBirth",
                            false,
                            true,
                          )}
                        >
                          {Moment(row.dateOfBirthPotentialMatch).format(
                            "DD/MM/YYYY",
                          )}
                        </td>
                        <td className={getCellClass(row, "postcode", false)}>
                          {row.postcodePotentialMatch}
                        </td>
                        <td className={getCellClass(row, "hospital", false)}>
                          {row.hospitalPotentialMatch}
                        </td>
                        <td
                          className={getCellClass(row, "procedureType", false)}
                        >
                          {getStartCase(row.procedureTypePotentialMatch)}
                        </td>
                        <td className={getCellClass(row, "side", false)}>
                          {getStartCase(row.sidePotentialMatch)}
                        </td>
                        <td className={getCellClass(row, "surgeon", false)}>
                          {row.surgeonPotentialMatch}
                        </td>
                        <td className={"centered"}>
                          {row.potentialMatchRevision && (
                            <Badge color={"danger"}>Revision</Badge>
                          )}
                          {!row.potentialMatchRevision && (
                            <Badge color={"primary"}>Primary</Badge>
                          )}
                        </td>
                        <td>{row.potentialMatchId}</td>
                        <td
                          className={
                            Moment(row.dateRegisteredPotentialMatch).isBefore(
                              Moment(row.procedureDate),
                            )
                              ? "highlight-cell"
                              : null
                          }
                        >
                          {Moment(row.dateRegisteredPotentialMatch).format(
                            "DD/MM/YYYY",
                          )}
                        </td>
                        <td
                          className={
                            row.potentialMatchScore < 80
                              ? "highlight-cell"
                              : null
                          }
                        >
                          {row.potentialMatchScore}
                        </td>
                        <td>{getActionButtons(row, i, true, false)}</td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
          </Row>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Pagination
              activePage={activePage}
              totalItemsCount={totalItemsCount}
              itemsCountPerPage={itemsCountPerPage}
              pageRangeDisplayed={pageRangeDisplayed}
              onChange={changePageCallback}
              hideDisabled
              innerClass={"pagination pagination-sm"}
              itemClass={"page-item"}
              linkClass={"page-link"}
            />
          </div>
        </Fragment>
        <ButtonBar buttons={[<Button onClick={onExit}>Close</Button>]} />
      </ModalBody>
    </Modal>
  );
};

BetterMatchesModal.propTypes = {
  onExit: PropTypes.func.isRequired,
  changePageCallback: PropTypes.func,
  neverMatchCallback: PropTypes.func,
  swapCallback: PropTypes.func,
  open: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  itemsCountPerPage: PropTypes.number,
  totalItemsCount: PropTypes.number,
  activePage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
};

export default BetterMatchesModal;

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import "./UserManagement.css";
import SimpleRoleSelector from "../../common/SimpleRoleSelector";
import { useDispatch, useSelector } from "react-redux";
import UserModal from "./UserModal";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import { loadUserCurrent } from "../../../actions/UserActions";
import Search from "./Search";
import {
  CREATE,
  EDIT,
  isAdministratorRoleId,
  isAoaRoleId,
  isAoaUser,
  isSahmriRoleId,
  VIEW,
} from "../../../Utils";
import { loadSurgeons } from "../../../actions/SurgeonActions";
import { useEffect, useReducer } from "react";

const UserManagement = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      createUserRole: null,
      showModal: false,
      searchVersion: 0,
      modalOperation: "",
      modalUserId: null,
      modalRoleId: null,
      modalRoleEmailSuffix: null,
    },
  );

  useEffect(() => {
    !user.found && dispatch(loadUserCurrent());
  }, [dispatch]);

  const handleCreateUserRoleChange = (value) => {
    setState({ createUserRole: value });
  };

  const handleSuccess = (message) => {
    setState({
      showModal: false,
      searchVersion: state.searchVersion + 1,
    });
    dispatch(loadSurgeons());
    toast.success(
      <span>
        <FaCheck /> {message}
      </span>,
    );
  };

  const handleCloseCreateUserModal = () => {
    setState({
      showModal: false,
      modalOperation: "",
      modalUserId: null,
      modalRoleId: null,
      modalRoleEmailSuffix: null,
      createUserRole: null,
    });
  };

  const showCreateUserDialog = () => {
    setState({
      showModal: true,
      modalOperation: CREATE,
      modalRoleId: state.createUserRole.id,
      modalRoleEmailSuffix: state.createUserRole.emailSuffix,
    });
  };

  const showEditUserDialog = (user) => {
    setState({
      showModal: true,
      modalOperation: EDIT,
      modalUserId: user.id,
      modalRoleId: user.roleId,
      createUserRole: user.role,
      modalRoleEmailSuffix: user.emailSuffix,
    });
  };

  const showViewUserDialog = (user) => {
    setState({
      showModal: true,
      modalOperation: VIEW,
      modalUserId: user.id,
      modalRoleId: user.roleId,
      modalRoleEmailSuffix: user.emailSuffix,
    });
  };

  const canCreateUser = () => {
    return isAoaUser(user);
  };

  const isCreatableUser = (role) => {
    return (
      !(
        isAoaRoleId(role.id) ||
        isSahmriRoleId(role.id) ||
        isAdministratorRoleId(role.id)
      ) && role.canWrite
    );
  };

  return (
    <div>
      <Container>
        <h1>User Management</h1>
        {canCreateUser() && (
          <Card className={"create-user"}>
            <CardBody>
              <CardTitle>Create User</CardTitle>
              <div>
                <Row>
                  <Col xs={12} sm={2} md={3} lg={2}>
                    <Label
                      className={"role-label"}
                      for={"add-user-role-selector"}
                    >
                      Role:
                    </Label>
                  </Col>
                  <Col xs={12} sm={7} md={6} lg={4}>
                    <SimpleRoleSelector
                      value={state.createUserRole}
                      onChange={handleCreateUserRoleChange}
                      clearable={false}
                      filter={isCreatableUser}
                    />
                  </Col>

                  <Col>
                    <Button
                      disabled={state.createUserRole == null}
                      onClick={showCreateUserDialog}
                    >
                      Create...
                    </Button>
                  </Col>
                  <Col xs={0} sm={2} md={2} lg={2} />
                </Row>
              </div>
            </CardBody>
          </Card>
        )}
        <Card>
          <CardBody>
            <CardTitle>Current Users</CardTitle>
            <CardBody>
              <Search
                editCallback={showEditUserDialog}
                viewCallback={showViewUserDialog}
                resultsVersion={state.searchVersion}
              />
            </CardBody>
          </CardBody>
        </Card>
      </Container>
      {state.showModal && (
        <UserModal
          onConfirm={handleSuccess}
          onExit={handleCloseCreateUserModal}
          operation={state.modalOperation}
          userId={state.modalUserId}
          roleName={state.createUserRole ? state.createUserRole.name : null}
          roleId={state.modalRoleId}
          emailSuffix={state.modalRoleEmailSuffix}
          open={true}
        />
      )}
    </div>
  );
};

export default UserManagement;

import { Fragment } from "react";
import PropTypes from "prop-types";
import "./ButtonBar.css";
import classNames from "classnames";

const ButtonBar = ({
  buttons = [],
  horizontalRule = true,
  reversed = false,
  className,
}) => {
  return (
    <div className={classNames("button-bar", className)}>
      {horizontalRule && buttons.length > 0 && <hr />}
      <div className={classNames("buttons", { reversed: reversed })}>
        {buttons.map((button, index) => (
          <Fragment key={"button-bar-span-" + index}>{button}</Fragment>
        ))}
      </div>
    </div>
  );
};

ButtonBar.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.element),
  horizontalRule: PropTypes.bool,
  reversed: PropTypes.bool,
};

export default ButtonBar;

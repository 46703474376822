import { del, get, post, put } from "./Api";

const BASE_URL = "/api/instrument-report";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function allInstrumentReports(roleId) {
  return get(
    url("list"),
    { roleId: roleId || 0 },
    "Unable to get report list at this time.",
  );
}

export function allInstrumentBins() {
  return get(url("bins"), null, "Unable to get bin list at this time.");
}

export function instrumentsForStudyCollection(studyCollectionId) {
  return get(
    url("instruments"),
    { collection: studyCollectionId },
    "Unable to get instruments at this time.",
  );
}

export function questionsForInstrument(instrumentId) {
  return get(
    url("questions"),
    { instrument: instrumentId },
    "Unable to get questions at this time.",
  );
}

export function loadSubscores() {
  return get(url("subscores"), null, "Unable to load subscores at this time.");
}

export function allGraphTypes() {
  return get(
    url("graphtypes"),
    null,
    "Unable to load graph types at this time.",
  );
}

export function allRoles() {
  return get(url("roles"), null, "Unable to load roles at this time.");
}

export function allPositions() {
  return get(url("positions"), null, "Unable to load positions at this time.");
}

export function allText() {
  return get(url("texts"), null, "Unable to load text at this time.");
}

export function addText(text, type) {
  return post(
    url("texts"),
    { id: null, text, type },
    "Unable to save text at this time.",
  );
}

export function createReport(report) {
  return post(url("report"), report, "Unable to create report at this time.");
}

export function editReport(reports, roleId) {
  return put(url("report"), reports, "Unable to edit report at this time.", {
    params: { roleId: roleId },
  });
}

export function addRole(reportId, role, roleId) {
  return post(
    url(`report/${reportId}/addRole`),
    role,
    "Unable to add role at this time.",
    { params: { roleId } },
  );
}

export function removeRole(reportId, role, roleId) {
  return del(
    url(`report/${reportId}/removeRole/${role.id}`),
    { params: { roleId: roleId } },
    "Unable to remove role at this time.",
  );
}

export function seriesDelete(series, roleId) {
  return del(
    url(`series/${series.id}`),
    { params: { roleId: roleId } },
    "Unable to delete series.",
  );
}

export function screenDelete(screen, roleId) {
  return del(
    url(`screen/${screen.id}`),
    { params: { roleId: roleId } },
    "Unable to delete screen.",
  );
}

export function reportDelete(report, roleId) {
  return del(
    url(`report/${report.id}`),
    { params: { roleId: roleId } },
    "Unable to delete report.",
  );
}

export function reportDuplicate(id, roleId) {
  return post(url(`${id}/duplicate`), null, "Unable to duplicate report.", {
    params: { roleId: roleId },
  });
}

export function screenDuplicate(reportId, screenId, roleId) {
  return post(
    url(`${reportId}/${screenId}/duplicate`),
    null,
    "Unable to duplicate screen",
    { params: { roleId } },
  );
}

export function changeReportIndex(reportId, newIndex, roleId) {
  return post(
    url("report-index"),
    { reportId, newIndex, roleId },
    "Unable to change report index.",
  );
}

import { Fragment, useEffect, useReducer } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import "./FuzzyMatchingModal.css";
import { getStartCase, isEmptyOrNull } from "../../Utils";
import Moment from "moment";
import RegistryProcedureSearchForm from "./RegistryProcedureSearchForm";
import {
  FaCheck,
  FaExclamationCircle,
  FaTimes,
  FaUnlink,
} from "react-icons/fa";
import {
  addNeverMatch,
  NJRRProcedureSearch,
  unmatch,
} from "../../api/Matching";
import { toast } from "react-toastify";
import "./SearchFuzzyMatchModal.css";
import ButtonBar from "../common/ButtonBar";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { apiErrorResponse } from "./Utils";

const INITIAL_STATE = {
  totalItemsCount: 0,
  activePage: 1,
  itemsCountPerPage: 5,
  pageRangeDisplayed: 5,
  firstPage: 1,
  data: [],
  payload: null,
};

const SearchFuzzyMatchModal = ({
  onExit = () => null,
  saveCallback = () => null,
  open = false,
  searchRow,
}) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    INITIAL_STATE,
  );

  useEffect(() => {
    !open && setState(INITIAL_STATE);
  }, [open]);

  useEffect(() => {
    state.payload !== null && fetchData(state.payload);
  }, [state.activePage]);

  const getPromsProcedureCard = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>
            PROMs Procedure (ID: {!!searchRow && searchRow.idProms})
          </CardTitle>
          <Table
            size={"sm"}
            bordered
            responsive
            className={"fuzzy-matching-table"}
          >
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle Name(s)</th>
                <th>Last Name</th>
                <th>DOB</th>
                <th>Postcode</th>
                <th>Hospital</th>
                <th>Procedure Type</th>
                <th>Side</th>
                <th>Surgeon</th>
                <th>Studies</th>
                <th>Registered Date</th>
              </tr>
            </thead>
            {searchRow && (
              <tbody className={"table-body"}>
                <tr>
                  <td>{getStartCase(searchRow.firstNameProms)}</td>
                  <td>{getStartCase(searchRow.middleNamesProms)}</td>
                  <td>{getStartCase(searchRow.lastNameProms)}</td>
                  <td>
                    {Moment(searchRow.dateOfBirthProms).format("DD/MM/YYYY")}
                  </td>
                  <td>{searchRow.postcodeProms}</td>
                  <td>{searchRow.hospitalProms}</td>
                  <td>{getStartCase(searchRow.procedureTypeProms)}</td>
                  <td>{getStartCase(searchRow.sideProms)}</td>
                  <td>{searchRow.surgeonProms}</td>
                  <td>
                    {searchRow.studies.map((study, studyIndex) => {
                      return (
                        <Badge
                          key={`study-badge-${studyIndex}`}
                          color={"primary"}
                          className={"me-2"}
                        >
                          {study}
                        </Badge>
                      );
                    })}
                  </td>
                  <td>
                    {Moment(searchRow.procedureRegistered).format("DD/MM/YYYY")}
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </CardBody>
      </Card>
    );
  };

  const checkMatchStatus = (neverMatch, alreadyMatched) => {
    let message = "";
    let showTooltip = neverMatch || alreadyMatched;

    if (neverMatch) {
      message += "This procedure is listed as a Never Match. ";
    }
    if (alreadyMatched) {
      message += "This procedure is already matched. ";
    }

    return (
      showTooltip && (
        <span className={"ms-2"}>
          <FaExclamationCircle
            id={"result-warning-icon"}
            className={"tooltip-icon"}
          />
          <UncontrolledTooltip placement="top" target="result-warning-icon">
            {message}
          </UncontrolledTooltip>
        </span>
      )
    );
  };

  const getResultsTable = () => {
    if (searchRow == null) {
      return null;
    }

    return (
      <Fragment>
        <Row className={"fuzzy-matching-table"}>
          <Table size={"sm"} bordered responsive>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle Name(s)</th>
                <th>Last Name</th>
                <th>DOB</th>
                <th>Postcode</th>
                <th>Hospital</th>
                <th>Procedure Type</th>
                <th>Side</th>
                <th>Surgeon</th>
                <th>Source Id/Procedure Id</th>
                <th>Procedure/Registered Date</th>
                <th>Score</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className={"table-body"}>
              {state.data.map((row, i) => {
                const alreadyMatched = row.idProms != null;
                const tds = [];
                tds.push(
                  <tr
                    key={`registry-row-${i}`}
                    className={row.idProms == null ? null : "stripe-row"}
                  >
                    <td className={getCellClass(row, searchRow, "firstName")}>
                      {getStartCase(row.firstName)}
                    </td>
                    <td className={getCellClass(row, searchRow, "middleNames")}>
                      {getStartCase(row.middleNames)}
                    </td>
                    <td className={getCellClass(row, searchRow, "lastName")}>
                      {getStartCase(row.lastName)}
                    </td>
                    <td
                      className={getCellClass(
                        row,
                        searchRow,
                        "dateOfBirth",
                        true,
                      )}
                    >
                      {Moment(row.dateOfBirth).format("DD/MM/YYYY")}
                    </td>
                    <td className={getCellClass(row, searchRow, "postcode")}>
                      {row.postcode}
                    </td>
                    <td className={getCellClass(row, searchRow, "hospital")}>
                      {row.hospital}
                    </td>
                    <td
                      className={getCellClass(row, searchRow, "procedureType")}
                    >
                      {getStartCase(row.procedureType)}
                    </td>
                    <td className={getCellClass(row, searchRow, "side")}>
                      {getStartCase(row.side)}
                    </td>
                    <td className={getCellClass(row, searchRow, "surgeon")}>
                      {row.surgeon}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {row.sourceId}
                        {row.revision && (
                          <Badge color={"danger"} title={"Procedure Type"}>
                            Revision
                          </Badge>
                        )}
                        {!row.revision && (
                          <Badge color={"primary"} title={"Procedure Type"}>
                            Primary
                          </Badge>
                        )}
                      </div>
                    </td>
                    <td
                      className={
                        Moment(row.procedureDate).isBefore(
                          Moment(searchRow.procedureRegistered),
                        )
                          ? "highlight-cell"
                          : null
                      }
                    >
                      {Moment(row.procedureDate).format("DD/MM/YYYY")}
                    </td>
                    <td className={row.score < 80 ? "highlight-cell" : null}>
                      <span
                        title={
                          "Fuzzy match score to target PROMs Procedure ID: " +
                          searchRow.idProms
                        }
                      >
                        {row.score}
                      </span>
                    </td>
                    <td>
                      <Button
                        className={"action-button"}
                        outline
                        color={"primary"}
                        title={"Confirm this match"}
                        onClick={() =>
                          saveCallback(row.sourceId, searchRow.idProms)
                        }
                      >
                        <FaCheck />
                      </Button>
                      <Button
                        className={"action-button"}
                        outline
                        color={"danger"}
                        title={
                          "Add the PROMs procedure as a never match to this registry procedure"
                        }
                        onClick={() =>
                          saveNeverMatch(row.sourceId, searchRow.idProms)
                        }
                      >
                        <FaTimes />
                      </Button>
                      {checkMatchStatus(row.neverMatch, row.alreadyMatched)}
                    </td>
                  </tr>,
                );
                if (alreadyMatched) {
                  tds.push(
                    <tr key={`proms-row-${i}`} className={"stripe-row"}>
                      <td>{getStartCase(row.firstNameProms)}</td>
                      <td>{getStartCase(row.middleNamesProms)}</td>
                      <td>{getStartCase(row.lastNameProms)}</td>
                      <td>
                        {Moment(row.dateOfBirthProms).format("DD/MM/YYYY")}
                      </td>
                      <td>{row.postcodeProms}</td>
                      <td>{row.hospitalProms}</td>
                      <td>{getStartCase(row.procedureTypeProms)}</td>
                      <td>{getStartCase(row.sideProms)}</td>
                      <td>{row.surgeonProms}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {row.idProms}
                          {row.studies.map((study, studyIndex) => {
                            return (
                              <Badge
                                key={`study-badge-${studyIndex}`}
                                color={"primary"}
                                title={"Enrolled Study"}
                                className={"me-2"}
                              >
                                {study}
                              </Badge>
                            );
                          })}
                        </div>
                      </td>
                      <td>
                        {Moment(row.procedureRegistered).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        <span
                          title={`Current match score to PROMs Procedure ID: ${row.idProms}`}
                        >
                          {row.currentMatchScore}
                        </span>
                      </td>
                      <td>
                        <Button
                          className={"action-button"}
                          outline
                          color={"primary"}
                          title={"Unmatch these two procedures"}
                          onClick={() =>
                            unmatchProcedures(row.idRegistry, row.idProms)
                          }
                        >
                          <FaUnlink />
                        </Button>
                      </td>
                    </tr>,
                  );
                }

                return (
                  <Fragment key={`row-fragment-${i}`}>{[...tds]}</Fragment>
                );
              })}
            </tbody>
          </Table>
        </Row>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Pagination
            activePage={state.activePage}
            totalItemsCount={state.totalItemsCount}
            itemsCountPerPage={state.itemsCountPerPage}
            pageRangeDisplayed={state.pageRangeDisplayed}
            onChange={changePage}
            hideDisabled
            innerClass={"pagination pagination-sm"}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        </div>
      </Fragment>
    );
  };

  const saveNeverMatch = (sourceId, idProms) => {
    dispatch(startLoading());
    addNeverMatch({ sourceId: sourceId, procedureId: idProms })
      .then((response) => {
        if (response.data) {
          toast.success("Never match saved");
          fetchData(state.payload);
        }
      })
      .catch((error) => {
        let msg =
          "An error occurred while saving the never match. Please try again.";
        apiErrorResponse(error, msg);
      })
      .finally(() => dispatch(finishLoading()));
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  const getCellClass = (registry, proms, key, valuesAreDates) => {
    if (valuesAreDates) {
      return Moment(registry[key]).format("DD/MM/YYYY") ===
        Moment(proms[key + "Proms"]).format("DD/MM/YYYY")
        ? null
        : "highlight-cell";
    } else if (
      isEmptyOrNull(registry[key]) ||
      isEmptyOrNull(proms[key + "Proms"])
    ) {
      return "highlight-cell";
    } else {
      return registry[key].toLocaleUpperCase() ===
        proms[key + "Proms"].toLocaleUpperCase()
        ? null
        : "highlight-cell";
    }
  };

  const fetchData = (input) => {
    dispatch(startLoading());
    let payload = JSON.parse(JSON.stringify(input));
    payload.page = state.activePage - 1;
    payload.size = state.itemsCountPerPage;
    payload.promsProcedureId = searchRow.idProms;
    payload.searchUnmatched = true;
    NJRRProcedureSearch(payload)
      .then((response) => {
        setState({
          data: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
          payload: payload,
        });
      })
      .catch((error) => {
        let msg =
          "An error occurred while searching for NJRR procedures. Please try again.";
        apiErrorResponse(error, msg);
      })
      .finally(() => dispatch(finishLoading()));
  };

  const search = (payload) => {
    fetchData(payload);
    setState({ activePage: state.firstPage });
  };

  const unmatchProcedures = (idRegistry, idProms) => {
    dispatch(startLoading());
    unmatch(idRegistry, idProms)
      .then(() => {
        toast.success(
          <span>
            <FaCheck /> {"Procedures successfully unmatched"}
          </span>,
        );
        fetchData(state.payload);
      })
      .catch((error) => {
        let msg =
          "An error occurred while unmatching the procedures. Please try again.";
        apiErrorResponse(error, msg);
      })
      .finally(() => dispatch(finishLoading()));
  };

  return (
    <Modal
      isOpen={open}
      toggle={onExit}
      className={"search-fuzzy-match-modal"}
      keyboard={false}
    >
      <ModalHeader toggle={onExit}>Search For Registry Procedure</ModalHeader>
      <ModalBody>
        {getPromsProcedureCard()}
        <RegistryProcedureSearchForm
          searchCallback={(payload) => search(payload)}
        />
        {state.payload && getResultsTable()}
        <ButtonBar buttons={[<Button onClick={onExit}>Cancel</Button>]} />
      </ModalBody>
    </Modal>
  );
};

SearchFuzzyMatchModal.propTypes = {
  onExit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  searchRow: PropTypes.object,
  saveCallback: PropTypes.func.isRequired,
};

export default SearchFuzzyMatchModal;

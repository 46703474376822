import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import { loadSurgeons } from "../../actions/SurgeonActions";
import { UNKNOWN_SURGEON } from "../../Utils";
import { useEffect } from "react";

export const ALL_SURGEONS = {
  id: -1,
  firstName: "All Surgeons",
  lastName: "All Surgeons",
  role: "Role(id=2, name=Surgeon, emailSuffix=null)",
};

/**
 * Unlike SimpleSurgeonSelector, this selector is just a Select element and automatically loads/populates its values.
 */
const SurgeonSelector = ({
  clearable = true,
  disabled = false,
  showSurgeonCode = false,
  showUnknownValue = false,
  tabIndex = null,
  useAllSurgeons = true,
  value = ALL_SURGEONS,
  onChange,
  searchable,
}) => {
  const dispatch = useDispatch();
  const surgeons = useSelector((state) => state.surgeons);

  useEffect(() => {
    surgeons.length === 0 && dispatch(loadSurgeons());
  }, [dispatch]);

  const renderOption = (surgeon) => {
    if (surgeon.id === UNKNOWN_SURGEON.id) {
      return (
        <div>
          <i>{surgeon.firstName}</i>
        </div>
      );
    } else if (surgeon.id === ALL_SURGEONS.id) {
      return (
        <div>
          <b>{surgeon.firstName}</b>
        </div>
      );
    } else if (showSurgeonCode) {
      return (
        <div>
          {surgeon.firstName} {surgeon.lastName} ({surgeon.surgeonCode})
        </div>
      );
    } else {
      return (
        <div>
          {surgeon.firstName} {surgeon.lastName}
        </div>
      );
    }
  };

  const filterOption = (option, filter) => {
    let combined = option.data.firstName + " " + option.data.lastName;
    if (showSurgeonCode) {
      combined = combined + " " + option.data.surgeonCode;
    }
    return combined.toLowerCase().includes(filter.toLowerCase());
  };

  const getOptions = () => {
    let result = surgeons.slice();
    if (useAllSurgeons) {
      result.unshift(ALL_SURGEONS);
    }
    if (showUnknownValue) {
      result.unshift(UNKNOWN_SURGEON);
    }
    return result;
  };

  return (
    <div className={"surgeon-selector"}>
      <Select
        options={getOptions()}
        onChange={onChange}
        isClearable={clearable}
        isSearchable={searchable}
        getOptionLabel={(option) => renderOption(option)}
        getOptionValue={(option) => option.id}
        value={value}
        isDisabled={disabled}
        filterOption={filterOption}
        tabIndex={tabIndex}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
      />
    </div>
  );
};

SurgeonSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  showSurgeonCode: PropTypes.bool,
  showUnknownValue: PropTypes.bool,
  useAllSurgeons: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default SurgeonSelector;

import { get, post } from "./Api";

const BASE_URL = "/api/upload";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function saveUpload(request) {
  return post(
    url(`create`),
    request,
    "Unable to upload patient responses file at this time.",
  );
}

export function getUploads(payload) {
  return get(
    url(`all`),
    payload,
    "Unable to fetch patient responses at this time.",
  );
}

export function searchUploads(payload) {
  return get(
    url(`search`),
    payload,
    "Unable to search for patient responses at this time.",
  );
}

export function getAllUploadStatuses() {
  return get(
    url(`statuses`),
    null,
    "Unable to fetch upload statuses at this time.",
  );
}

export function getAllUploaders() {
  return get(url(`uploaders`), null, "Unable to fetch uploaders at this time.");
}

export function finaliseUpload(payload) {
  return post(
    url("finalise"),
    payload,
    "Unable to finalise upload at this time.",
  );
}

export function saveExternalResponseUpload(request) {
  return post(
    url(`external-responses`),
    request,
    "Unable to upload external patient responses file at this time.",
  );
}

export function getExternalResponseUploads(request) {
  return get(
    url(`external-responses`),
    request,
    "Unable to get all external patient response upload files at this time.",
  );
}

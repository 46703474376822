import LargeNavButton from "../dashboard/LargeNavButton";
import { FaBan } from "react-icons/fa";
import NeverMatchesModal from "./NeverMatchesModal";
import { useState } from "react";

const NeverMatches = () => {
  const [showNeverMatchModal, setShowNeverMatchModal] = useState(false);

  return (
    <div className={"never-matches"}>
      <LargeNavButton
        title={"Manage Never Matches"}
        icon={<FaBan />}
        columnPadding={false}
        to={null}
        onClick={() => setShowNeverMatchModal(!showNeverMatchModal)}
      />
      {showNeverMatchModal && (
        <NeverMatchesModal
          onExit={() => setShowNeverMatchModal(!showNeverMatchModal)}
          open={showNeverMatchModal}
        />
      )}
    </div>
  );
};

export default NeverMatches;

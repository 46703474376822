import { Fragment, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { FaEye } from "react-icons/fa";
import ResponsesView from "./ResponsesView";
import { isEmptyOrNull } from "../../Utils";
import "./Responses.css";
import { sendPreviousResponseEvent } from "../../api/Event";
import DOMPurify from "dompurify";
import ButtonBar from "../common/ButtonBar";

const ScoredInstruments = ({
  instruments = [],
  isOpen = false,
  toggle = () => null,
  procedureCollectionId,
}) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      viewResponses: false,
      filteredResponses: [],
      currentInstrument: null,
      tooltipIndexOpen: null,
    },
  );

  useEffect(() => {
    procedureCollectionId && sendPreviousResponseEvent(procedureCollectionId);
  }, [procedureCollectionId]);

  const toggleResultView = () => {
    setState({
      viewResponses: !state.viewResponses,
      currentInstrument: null,
    });
  };

  const showResults = (instrument) => {
    setState({ viewResponses: true, currentInstrument: instrument });
  };

  const getResponsesTitle = () => {
    if (state.currentInstrument == null) {
      return "View Instrument Response";
    } else {
      return `View Instrument Response - ${state.currentInstrument.name}`;
    }
  };

  const getScores = (totalScoreString, totalScoreInteger, subScores) => {
    if (isEmptyOrNull(totalScoreString)) {
      if (Object.keys(subScores).length > 0) {
        let result =
          totalScoreInteger == null
            ? "Total: N/A"
            : `Total: ${totalScoreInteger}`;

        Object.keys(subScores).forEach(function (key) {
          result =
            result +
            "<br/>" +
            key +
            ": " +
            (subScores[key] == null ? "N/A" : subScores[key]);
        });
        return (
          <span
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result) }}
          />
        );
      } else {
        return (
          <Fragment>
            {totalScoreInteger == null ? (
              "N/A"
            ) : (
              <span>{totalScoreInteger}</span>
            )}
          </Fragment>
        );
      }
    } else {
      return <Fragment>{totalScoreString}</Fragment>;
    }
  };

  const getInstrumentName = (name, scoreDescription) => {
    if (isEmptyOrNull(scoreDescription)) {
      return name;
    } else {
      let result = `${name}<br/><span className="score-description">${scoreDescription}</span>`;
      return (
        <span
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result) }}
        />
      );
    }
  };

  const shouldShowActionIcon = (instrument) => {
    return !!instrument && instrument.responses.length > 0;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={"lg"}
      className={"scored-instruments"}
      keyboard={false}
    >
      <ModalBody>
        <ModalHeader toggle={toggle}>Response Instruments</ModalHeader>
        <Table striped bordered size={"sm"} responsive>
          <thead>
            <tr>
              <th>Instrument</th>
              <th>Instrument Score</th>
              <th>Item Responses</th>
            </tr>
          </thead>
          <tbody>
            {instruments.map((instrument, i) => {
              return (
                <tr key={i}>
                  <td>
                    {getInstrumentName(
                      instrument.name,
                      instrument.scoreDescription,
                    )}
                  </td>
                  <td>
                    {getScores(
                      instrument.totalScoreString,
                      instrument.totalScoreInteger,
                      instrument.subScores,
                    )}
                  </td>
                  <td className={"centered"}>
                    {shouldShowActionIcon(instrument) && (
                      <FaEye
                        onClick={() => showResults(instrument)}
                        className={"hand"}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ResponsesView
          isOpen={state.viewResponses}
          responses={
            state.currentInstrument ? state.currentInstrument.responses : []
          }
          toggle={toggleResultView}
          patientView={false}
          title={getResponsesTitle()}
        />
        <ButtonBar
          buttons={[
            <Button
              key={"close-button"}
              color="primary"
              outline
              onClick={toggle}
            >
              Close
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

ScoredInstruments.propTypes = {
  procedureCollectionId: PropTypes.number,
  instruments: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ScoredInstruments;

import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import "../../patient/EnrolmentConfirmModal.css";
import {
  arraysAreEqual,
  isAoaUser,
  isEmptyOrNull,
  isHospitalAdministratorUser,
  isHospitalStudyCoordinatorUser,
  isSurgeonUser,
  UNKNOWN_SURGEON,
} from "../../../Utils";
import "./Procedures.css";
import Select from "react-select";
import { loadSurgeons } from "../../../actions/SurgeonActions";
import SimpleSideSelector from "../../common/SimpleSideSelector";
import {
  loadHospitals,
  loadProcedureTypes,
  loadSides,
  loadStudies,
} from "../../../actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { getLinkedStudies } from "../../../api/Study";
import { addAnotherProcedure } from "../../../api/Patient";
import ButtonBar from "../../common/ButtonBar";
import Moment from "moment";
import DatePicker from "react-datepicker";
import DatePickerIosInput from "../../common/DatePickerIosInput";
import SimpleProcedureTypeSelector from "../../common/SimpleProcedureTypeSelector";
import { useEffect, useReducer } from "react";
import { useOnUpdate } from "../../CustomHooks";

const dateFormatThree = "dd/MM/yyyy";
const dateFormat = "YYYY-MM-DD";
const dateFormatTwo = "DD/MM/YYYY";

const AddAnotherProcedureModal = ({
  open,
  patientId,
  confirmCallback,
  exitCallback,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const surgeons = useSelector((state) => state.surgeons);
  const hospitals = useSelector((state) => state.hospitals);
  const studies = useSelector((state) => state.studies);
  const procedureTypes = useSelector((state) => state.procedureTypes);
  const sides = useSelector((state) => state.sides);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      hospital: null,
      surgeon: null,
      procedureType: null,
      side: null,
      currentlySubmitting: "",
      selectedStudies: [],
      allStudies: [],
      saveProcedureError: null,
      // The below variable is to prevent the error message about a
      // common study from briefly appearing when new studies are being fetched
      fetchingStudies: false,
      scheduledProcedureDate: null,
      scheduledProcedureDateValid: true,
    },
  );

  useEffect(() => {
    surgeons.length === 0 && dispatch(loadSurgeons());
    hospitals.length === 0 && dispatch(loadHospitals());
    procedureTypes.length === 0 && dispatch(loadProcedureTypes());
    sides.length === 0 && dispatch(loadSides());
    state.allStudies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useEffect(() => {
    if (
      surgeons.length === 1 &&
      (isSurgeonUser(user) ||
        isHospitalAdministratorUser(user) ||
        isHospitalStudyCoordinatorUser(user))
    ) {
      setSurgeon(surgeons[0]);
    }
    if (hospitals.length === 1) {
      setHospital(hospitals[0]);
    }
    if (state.allStudies.length === 0) {
      let allowedLinkedStudies = studies;
      if (!isEmptyOrNull(user.hospitalStudies)) {
        allowedLinkedStudies = allowedLinkedStudies.filter((s) =>
          user.hospitalStudies.map((hs) => hs.studyId).includes(s.id),
        );
      }
      allowedLinkedStudies.forEach((study) =>
        state.allStudies.push({
          name: study.name,
          id: study.id,
        }),
      );
    }
  }, []);

  useOnUpdate(() => {
    fetchStudies();
  }, [state.procedureType, state.surgeon]);

  const getStudyIds = () => {
    let studyIds = [];
    let allFilteredStudyIds = [];
    state.allStudies.forEach((study) => allFilteredStudyIds.push(study.id));
    state.selectedStudies.forEach((value) => studyIds.push(value));
    if (arraysAreEqual(allFilteredStudyIds, studyIds)) return studyIds;
    else {
      return studyIds.filter((study) => allFilteredStudyIds.includes(study));
    }
  };

  const renderSurgeonName = (surgeon) => {
    if (surgeon.id !== UNKNOWN_SURGEON.id) {
      return (
        <div>
          {" "}
          Dr {surgeon.lastName}, {surgeon.firstName}
        </div>
      );
    } else {
      return (
        <div>
          <i>Unknown</i>
        </div>
      );
    }
  };

  const setSurgeon = (surgeon) => {
    if (surgeon == null || surgeon.id === UNKNOWN_SURGEON.id) {
      setState({ allStudies: studies, surgeon: UNKNOWN_SURGEON });
    }
    let procedureTypeIds = [];
    if (state.procedureType != null) {
      procedureTypeIds.push(state.procedureType.id);
    } else {
      procedureTypes.forEach((procedureType) =>
        procedureTypeIds.push(procedureType.id),
      );
    }

    if (surgeon && surgeon.id !== UNKNOWN_SURGEON.id) {
      if (!state.hospital) {
        setState({ surgeon: surgeon });
      } else {
        setState({ fetchingStudies: true });
        getLinkedStudies(surgeon.id, state.hospital.id, procedureTypeIds, false)
          .then((response) => {
            let allowedLinkedStudies = response.data;
            if (!isEmptyOrNull(user.hospitalStudies)) {
              allowedLinkedStudies = allowedLinkedStudies.filter((s) =>
                user.hospitalStudies.map((hs) => hs.studyId).includes(s.id),
              );
            }
            setState({
              allStudies: allowedLinkedStudies,
              surgeon: hasHospital(surgeon, state.hospital.id)
                ? surgeon
                : UNKNOWN_SURGEON,
            });
          })
          .finally(() => setState({ fetchingStudies: false }));
      }
    }
  };

  const hasHospital = (surgeon, hospitalId) => {
    if (!isEmptyOrNull(surgeon.hospitalStudies)) {
      return surgeon.hospitalStudies
        .map((hs) => hs.hospitalId)
        .includes(hospitalId);
    } else {
      return false;
    }
  };

  const getFilteredSurgeons = () => {
    let filtered = [];
    if (state.hospital != null) {
      filtered = surgeons.filter((surgeon) =>
        hasHospital(surgeon, state.hospital.id),
      );
      if (
        state.hospital.directLink ||
        (!isSurgeonUser(user) &&
          !(
            isHospitalAdministratorUser(user) ||
            isHospitalStudyCoordinatorUser(user)
          ))
      ) {
        filtered.push(UNKNOWN_SURGEON);
      }
    }
    return filtered;
  };

  const selectStudy = (values) => {
    let studyIds = [];
    if (values != null) {
      values.forEach((value) => studyIds.push(value.id));
      setState({ selectedStudies: studyIds });
    }
  };

  const fetchStudies = () => {
    let surgeonId = null;
    if (!!state.surgeon) {
      surgeonId =
        state.surgeon.id === UNKNOWN_SURGEON.id ? null : state.surgeon.id;
    }
    if (state.hospital != null && state.procedureType != null) {
      setState({ fetchingStudies: true });
      getLinkedStudies(
        surgeonId,
        state.hospital.id,
        [state.procedureType.id],
        false,
      )
        .then((response) => {
          let allowedLinkedStudies = response.data;
          if (!isEmptyOrNull(user.hospitalStudies)) {
            allowedLinkedStudies = allowedLinkedStudies.filter((s) =>
              user.hospitalStudies.map((hs) => hs.studyId).includes(s.id),
            );
          }
          setState({
            allStudies: allowedLinkedStudies,
            selectedStudies: allowedLinkedStudies
              .filter((study) => study.autoEnrolment)
              .map((study) => study.id),
          });
        })
        .finally(() => setState({ fetchingStudies: false }));
    } else {
      setState({ allStudies: [], selectedStudies: [] });
    }
  };

  const setProcedureType = (value) => {
    if (value !== null) {
      setState({ procedureType: value });
    }
  };

  const setSide = (value) => {
    if (value !== null) {
      setState({ side: value });
    }
  };

  const setHospital = (value) => {
    if (state.hospital == null || value.id !== state.hospital.id) {
      setState({ hospital: value });
      if (!isSurgeonUser(user)) {
        let newSurgeon = null;
        if (
          getFilteredSurgeons().length === 1 ||
          (hospitals.some((h) => !h.directLink) &&
            getFilteredSurgeons().length > 0)
        ) {
          newSurgeon = getFilteredSurgeons()[0];
        } else if (
          state.surgeon != null &&
          hasHospital(state.surgeon, value.id)
        ) {
          newSurgeon = state.surgeon;
        }
        setSurgeon(newSurgeon);
      } else {
        fetchStudies();
      }
    }
  };

  const addNewProcedureForThePatient = () => {
    let studies = getStudyIds();
    const payload = {
      hospitalId: state.hospital.id,
      studyIds: studies,
      surgeonId: state.surgeon.id,
      sideId: state.side.id,
      procedureTypeId: state.procedureType.id,
      scheduledProcedureDate: state.scheduledProcedureDate,
    };
    setState({ currentlySubmitting: true });
    addAnotherProcedure(patientId, payload)
      .then((response) => {
        if (response.data.success) {
          confirmCallback("New Procedure added successfully");
        } else {
          if (!isEmptyOrNull(response?.data?.message)) {
            setState({ saveProcedureError: response.data.message });
          } else {
            setState({
              saveProcedureError: "Error creating the new procedure",
            });
          }
        }
      })
      .finally(() => setState({ currentlySubmitting: false }));
  };

  const formValid = () => {
    return (
      state.hospital != null &&
      state.surgeon != null &&
      state.procedureType != null &&
      state.side != null &&
      !isEmptyOrNull(state.selectedStudies) &&
      state.scheduledProcedureDateValid
    );
  };

  const getFilteredHospitals = () => {
    if (isSurgeonUser(user) && surgeons.length === 1) {
      return hospitals.filter((hospital) =>
        hasHospital(surgeons[0], hospital.id),
      );
    } else {
      return hospitals;
    }
  };

  const getHospitalSelector = () => {
    return (
      <Select
        selected={state.hospital}
        options={getFilteredHospitals()}
        onChange={setHospital}
        isClearable={false}
        isSearchable={true}
        getOptionLabel={(hospital) =>
          `${hospital.name} (${hospital.state.shortName})`
        }
        getOptionValue={(option) => option.id}
        value={state.hospital === null ? "Select...." : state.hospital}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
        isDisabled={
          getFilteredHospitals().length === 1 && state.hospital != null
        }
      />
    );
  };

  const isScheduledProcedureDateInValidRange = (date) => {
    // Assumes date is valid and not null
    let oneYearHence = Moment().add(1, "year");
    let oneYearPrior = Moment().subtract(1, "year");
    let rangeOK = !Moment(date, dateFormatTwo, true).isAfter(oneYearHence);
    rangeOK =
      rangeOK && !Moment(date, dateFormatTwo, true).isBefore(oneYearPrior);
    return rangeOK;
  };

  const scheduledProcedureDateChanged = (value) => {
    // Have come from picked date thus assumes value is a valid date in correct range //
    setState({
      scheduledProcedureDate:
        value == null
          ? null
          : Moment(value, dateFormatTwo, true).format(dateFormat),
      scheduledProcedureDateValid: true,
    });
  };

  const handleChangeRawScheduledProcedureDate = (date, wasBlur) => {
    if (date === undefined) return;
    if (isEmptyOrNull(date)) {
      setState({
        scheduledProcedureDate: null,
        scheduledProcedureDateValid: true,
      });
      return;
    }
    if (Moment.utc(date, dateFormatTwo, true).isValid()) {
      let spdValid = isScheduledProcedureDateInValidRange(date);
      setState({
        scheduledProcedureDate: Moment(date, dateFormatTwo, true).format(
          dateFormat,
        ),
        scheduledProcedureDateValid: spdValid,
      });
    } else {
      if (wasBlur) {
        setState({
          scheduledProcedureDate: null,
          scheduledProcedureDateValid: false,
        });
      }
      setState({ scheduledProcedureDateValid: false });
    }
  };

  let _selectedStudies = [];
  if (state.selectedStudies !== null) {
    state.allStudies
      .filter((study) => state.selectedStudies.includes(study.id))
      .forEach((selectStudy) => _selectedStudies.push(selectStudy));
  }
  let spdIds = state.allStudies
    .filter((s) => s.showScheduledProcedureDate)
    .map((spds) => spds.id);
  let found = spdIds.filter((i) => state.selectedStudies.includes(i));
  let procHasShowScheduledProcedureDateStudies = !isEmptyOrNull(found);
  let isEditableScheduledProcedureDate =
    procHasShowScheduledProcedureDateStudies &&
    (isAoaUser(user) ||
      isHospitalAdministratorUser(user) ||
      isHospitalStudyCoordinatorUser(user) ||
      isSurgeonUser(user));
  return (
    <Modal isOpen={open}>
      <ModalHeader>Add Details For the New Procedure</ModalHeader>
      <ModalBody>
        <div className={"patient-add-new-procedure"}>
          <Form>
            <div className={"field-row"}>
              <em>Note: Fields marked with an * are required.</em>
            </div>
            <Row className={"field-row"}>
              <Col xs={12} sm={12} md={2} lg={2}>
                <Label className={"form-label"} for="hospital">
                  Hospital*
                </Label>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                {getHospitalSelector()}
              </Col>
            </Row>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={12} sm={12} md={2} lg={2}>
                  <Label className={"form-label"} for="surgeon">
                    Surgeon
                  </Label>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Select
                    options={getFilteredSurgeons()}
                    onChange={setSurgeon}
                    isClearable={false}
                    isSearchable={true}
                    getOptionLabel={(option) => renderSurgeonName(option)}
                    getOptionValue={(option) => option.id}
                    value={
                      state.surgeon == null ? UNKNOWN_SURGEON : state.surgeon
                    }
                    styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                    isDisabled={getFilteredSurgeons().length === 1}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={12} sm={12} md={2} lg={2}>
                  <Label className={"form-label"} for="procedureTypes">
                    Procedure Type*
                  </Label>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <SimpleProcedureTypeSelector
                    value={state.procedureType}
                    onChange={setProcedureType}
                    clearable={false}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={12} sm={12} md={2} lg={2}>
                  <Label className={"form-label"} for="sides">
                    Side*
                  </Label>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <SimpleSideSelector
                    value={state.side}
                    onChange={setSide}
                    clearable={false}
                    searchable={true}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={12} sm={12} md={2} lg={2}>
                  <Label for="studies" className={"form-label"}>
                    Studies*
                  </Label>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Select
                    id="studies"
                    placeholder="Enrol in studies..."
                    value={_selectedStudies}
                    options={state.allStudies}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(selected) =>
                      selectStudy(selected == null ? [] : selected)
                    }
                    isMulti
                    hideSelectedOptions
                    stayOpen={true}
                    styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                    isClearable={false}
                  />
                </Col>
              </Row>
            </FormGroup>
            {isEditableScheduledProcedureDate && (
              <FormGroup>
                <Row className={"field-row"}>
                  <Col xs={12} sm={12} md={2} lg={2}>
                    <Label for="studies" className={"form-label"}>
                      Scheduled Procedure Date
                    </Label>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8}>
                    <DatePicker
                      selected={
                        state.scheduledProcedureDate == null
                          ? null
                          : Moment(state.scheduledProcedureDate).utc().toDate()
                      }
                      onChange={(value) => scheduledProcedureDateChanged(value)}
                      onChangeRaw={(event) =>
                        handleChangeRawScheduledProcedureDate(
                          event.target.value,
                        )
                      }
                      onBlur={(event) =>
                        handleChangeRawScheduledProcedureDate(
                          event.target.value,
                          true,
                        )
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dateFormat={dateFormatThree}
                      strictParsing
                      minDate={Moment().subtract(1, "year").toDate()}
                      maxDate={Moment().add(1, "year").toDate()}
                      dropdownMode="scroll"
                      scrollableYearDropdown={true}
                      yearDropdownItemNumber={120}
                      popperPlacement={"bottom-start"}
                      className={
                        !state.scheduledProcedureDateValid
                          ? "border-danger"
                          : "scheduled-procedure-date"
                      }
                      customInput={<DatePickerIosInput />}
                    />
                  </Col>
                  {!state.scheduledProcedureDateValid && (
                    <Alert color={"danger"}>
                      Please use the DD/MM/YYYY format. Scheduled procedure date
                      must be within one year either side of today's date.
                    </Alert>
                  )}
                </Row>
              </FormGroup>
            )}
          </Form>
        </div>
        {state.allStudies.length === 0 &&
          state.procedureType != null &&
          state.surgeon != null &&
          state.hospital != null &&
          !state.fetchingStudies && (
            <Alert color={"danger"}>
              Hospital & Surgeon Need to participate in a Common Study!
            </Alert>
          )}
        {!isEmptyOrNull(state.saveProcedureError) && (
          <Alert color={"danger"}>{state.saveProcedureError}</Alert>
        )}
        <ButtonBar
          buttons={[
            <Button
              color="primary"
              onClick={addNewProcedureForThePatient}
              disabled={!formValid()}
            >
              Create
            </Button>,
            <Button onClick={exitCallback}>Cancel</Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

AddAnotherProcedureModal.propTypes = {
  patientId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  exitCallback: PropTypes.func.isRequired,
};

export default AddAnotherProcedureModal;

import {
  isHospitalAdministratorUser,
  isHospitalStudyCoordinatorUser,
  isStakeholderUser,
  isSurgeonUser,
} from "../../Utils";

/**
 * react-vis doesn't allow axis labels to be outside the plot. This helper method alleviates that.
 * See: https://github.com/uber/react-vis/issues/542#issuecomment-331724888
 */
export const CustomAxisLabel = (props) => {
  const yLabelOffset = {
    y:
      props.innerHeight / 2 +
      props.title.length * 3 +
      (props.topMargin ? props.topMargin : 0),
    x: 15,
  };
  const xLabelOffset = {
    x: props.innerWidth / 2,
    y: props.innerHeight + (props.topMargin ? props.topMargin : 0) + 40,
  };
  const transform = props.xAxis
    ? `translate(${xLabelOffset.x}, ${xLabelOffset.y})`
    : `translate(${yLabelOffset.x}, ${yLabelOffset.y}) rotate(-90)`;

  return (
    <g transform={transform}>
      <text>{props.title}</text>
    </g>
  );
};

CustomAxisLabel.displayName = "CustomAxisLabel";
CustomAxisLabel.requiresSVG = true;

/* Base procedure type colours */
export const hipReplacementBaseColour = "#e7af19";
export const kneeReplacementBaseColour = "#7cb03f";
export const shoulderReplacementBaseColour = "#d4755b";
export const kneeOsteotomyBaseColour = "#21b687";
/* Muted procedure type colours */
export const hipReplacementColour = "#e7c988";
export const kneeReplacementColour = "#9bb07f";
export const shoulderReplacementColour = "#d4a594";
export const kneeOsteotomyColour = "#5baf8b";
/* Colours from aoa.org.au */
export const aoaBlue = "#2d3884";
export const aoaLightBlue = "#859CC2";
export const aoaGrey = "#DADAD9";
export const aoaBrown = "#D3C2A0";
export const aoaTan = "#C1957B";
export const aoaGreen = "#739478";

export function getGraphColours() {
  return [aoaLightBlue, aoaBrown, aoaGrey, aoaTan, aoaGreen, aoaBlue];
}

export function getColourForProcedureType(type, base) {
  if (base) {
    if (type.toLowerCase() === "hip replacement") {
      return hipReplacementBaseColour;
    }
    if (type.toLowerCase() === "knee replacement") {
      return kneeReplacementBaseColour;
    }
    if (type.toLowerCase() === "shoulder replacement") {
      return shoulderReplacementBaseColour;
    }
    if (type.toLowerCase() === "knee osteotomy") {
      return kneeOsteotomyBaseColour;
    }
  } else {
    if (type.toLowerCase() === "hip replacement") {
      return hipReplacementColour;
    }
    if (type.toLowerCase() === "knee replacement") {
      return kneeReplacementColour;
    }
    if (type.toLowerCase() === "shoulder replacement") {
      return shoulderReplacementColour;
    }
    if (type.toLowerCase() === "knee osteotomy") {
      return kneeOsteotomyColour;
    }
  }
  return aoaBlue;
}

export function canShowHospitalSelector(user, hospitals) {
  if (!user || !hospitals) {
    return false;
  }
  if (isStakeholderUser(user)) {
    return hospitals.length > 0;
  } else if (isSurgeonUser(user) && hospitals.length === 0) {
    return false;
  } else
    return !(isHospitalAdministratorUser(user) && user.surgeonIds.length > 0);
}

export function hasAllStudyAccess(user) {
  return (
    !isStakeholderUser(user) &&
    !isSurgeonUser(user) &&
    !isHospitalAdministratorUser(user) &&
    !isHospitalStudyCoordinatorUser(user)
  );
}

export function getReportableStudies(studies, user) {
  let newShowReporting = studies.filter((study) =>
    study.studyReportableRoles.some(
      (reportableRole) => reportableRole.roleId === user.roleId,
    ),
  );
  if (hasAllStudyAccess(user)) {
    return studies
      .filter((study) => study.active && hasAllStudyAccess(user))
      .sort((a, b) => a.name.localeCompare(b.name));
  } else {
    return newShowReporting
      .filter((study) => study.active)
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}

export const getTotal = (data, matched) => {
  return Object.values(data).reduce((accumulator, currentValue) => {
    // If it is matched data, need to reduce the sub values
    if (matched) {
      return (
        accumulator +
        Object.values(currentValue).reduce((acc, curr) => acc + curr)
      );
    }
    return accumulator + currentValue;
  }, 0);
};

import Select from "react-select";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { loadRoles } from "../../actions/CommonActions";
import { useEffect } from "react";

const SimpleRoleSelector = ({
  clearable = true,
  disabled = false,
  showReadable = true,
  showWriteable = false,
  placeholder = "Select...",
  filter = null,
  value,
  onChange,
  searchable,
}) => {
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);

  useEffect(() => {
    common.roles.length === 0 && dispatch(loadRoles());
  }, [dispatch]);

  const getValues = () => {
    let result = common.roles;
    if (!!filter) {
      result = result.filter((role) => filter(role));
    } else {
      result = result.filter(
        (role) =>
          (showReadable && role.canRead) || (showWriteable && role.canWrite),
      );
    }
    return result.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <div className={"simple-role-selector"}>
      <Select
        options={getValues()}
        onChange={onChange}
        isClearable={clearable}
        isSearchable={searchable}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        value={value}
        isDisabled={disabled}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
        placeholder={placeholder}
      />
    </div>
  );
};

SimpleRoleSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  showReadable: PropTypes.bool,
  showWriteable: PropTypes.bool,
  placeholder: PropTypes.string,
  filter: PropTypes.func,
};

export default SimpleRoleSelector;

import { Button, Col, Row } from "reactstrap";
import "../hospital/SearchHospitals.css";
import { loadHospitals } from "../../../actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import GenericSelector from "../../common/GenericSelector";
import { isEmptyOrNull } from "../../../Utils";
import { useEffect, useReducer } from "react";
import { useOnUpdate } from "../../CustomHooks";

const PLACEHOLDER_STUDY = "Select Study";
const PLACEHOLDER_HOSPITAL = "Select Hospital";

const SearchHospitalStudies = ({ actionButtons = [], excludeLinks = [] }) => {
  const dispatch = useDispatch();
  const hospitals = useSelector((state) => state.hospitals);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      stateFilter: null,
      filteredHospitals: [],
      selectedHospital: {},
      selectedStudy: {},
      studyOptions: [],
    },
  );

  useEffect(() => {
    isEmptyOrNull(hospitals) && dispatch(loadHospitals());
  }, [dispatch]);

  useEffect(() => {
    let selectedHospitalId = state.selectedHospital.id;
    let selectStudyId = state.selectedStudy.studyId;
    let selectionAlreadyLinked = !isEmptyOrNull(
      excludeLinks.filter(
        (link) =>
          link.hospitalId === selectedHospitalId &&
          link.studyId === selectStudyId,
      ),
    );
    let remainingAvailableStudyOptions = state.studyOptions.filter(
      (s) => s.studyId !== selectStudyId,
    );
    if (
      selectionAlreadyLinked &&
      !isEmptyOrNull(remainingAvailableStudyOptions)
    ) {
      setState({
        studyOptions: remainingAvailableStudyOptions,
        selectedStudy: remainingAvailableStudyOptions[0],
      });
    } else if (
      selectionAlreadyLinked &&
      isEmptyOrNull(remainingAvailableStudyOptions)
    ) {
      setState({
        studyOptions: [],
        selectedStudy: PLACEHOLDER_STUDY,
        selectedHospital: PLACEHOLDER_HOSPITAL,
      });
    }
  }, [state.selectedStudy, state.studyOptions, state.selectedHospital]);

  // Any time the excluded links are updated (ie when a study has been linked to a user),
  // we need to remove them from the study options
  useOnUpdate(() => {
    filterOutAlreadyLinkedStudies(excludeLinks, state.selectedHospital);
  }, [excludeLinks]);

  const hospitalSelected = (value) => {
    // Assumes that a hospital could only have been selected if it had one or more studies not already linked
    setState({ selectedHospital: value });
    filterOutAlreadyLinkedStudies(excludeLinks, value, null);
  };

  const studySelected = (value) => {
    setState({ selectedStudy: value });
    filterOutAlreadyLinkedStudies(excludeLinks, state.selectedHospital, value);
  };

  const filterOutHospitalsWithAllStudiesAlreadyLinked = (
    excludeLinks,
    hospitals,
  ) => {
    // if a hospital has all its studies in the excludeLinks then filter it out
    return hospitals.filter((h) => {
      let studyIdsAlreadyLinkedToThisHospital = excludeLinks
        .filter((l) => l.hospitalId === h.id)
        .map((hEl) => hEl.studyId);
      // if all of h.studies are in studyIdsAlreadyLinkedToThisHospital then filter h out
      let allHStudyIds = h.studies.map((h) => h.id);
      return allHStudyIds.length !== studyIdsAlreadyLinkedToThisHospital.length;
    });
  };

  const filterOutAlreadyLinkedStudies = (
    excludeLinks,
    selectedHospital,
    selStudyVal,
  ) => {
    if (
      isEmptyOrNull(selectedHospital) ||
      (!isEmptyOrNull(selectedHospital) &&
        isEmptyOrNull(selectedHospital.studies))
    ) {
      return [];
    }
    let excludeStudies = excludeLinks
      .filter((l) => l.hospitalId === selectedHospital.id)
      .map((hEl) => hEl.studyId);
    let keepStudies = selectedHospital.studies.filter(
      (s) => !excludeStudies.includes(s.studyId),
    );
    if (isEmptyOrNull(keepStudies)) {
      setState({
        selectedStudy: PLACEHOLDER_STUDY,
        selectedHospital: PLACEHOLDER_HOSPITAL,
        studyOptions: [],
      });
    } else if (!isEmptyOrNull(selStudyVal)) {
      setState({
        studyOptions: keepStudies,
        selectedStudy: selStudyVal,
        selectedHospital: selectedHospital,
      });
    } else if (isEmptyOrNull(selStudyVal)) {
      setState({
        studyOptions: keepStudies,
        selectedStudy: keepStudies[0],
        selectedHospital: selectedHospital,
      });
    }
  };

  return (
    <div>
      <Row className={"hospital-search-filter"}>
        <Col xs={8} sm={4} md={4} lg={6}>
          <GenericSelector
            isMulti={false}
            selected={
              !isEmptyOrNull(state.selectedHospital)
                ? state.selectedHospital
                : PLACEHOLDER_HOSPITAL
            }
            options={filterOutHospitalsWithAllStudiesAlreadyLinked(
              excludeLinks,
              hospitals.filter((h) => h.studies.length > 0),
            )}
            renderer={(value) => `${value.name} (${value.state.shortName})`}
            searchable={true}
            idPropertyName={"id"}
            labelPropertyName={"name"}
            changeCallback={hospitalSelected}
            placeholder={PLACEHOLDER_HOSPITAL}
          />
        </Col>
        <Col xs={2} sm={4} md={4} lg={4}>
          <GenericSelector
            isMulti={false}
            selected={
              !isEmptyOrNull(state.selectedStudy)
                ? state.selectedStudy
                : PLACEHOLDER_STUDY
            }
            options={state.studyOptions}
            renderer={(value) => value.studyName}
            searchable={true}
            idPropertyName={"studyId"}
            labelPropertyName={"studyName"}
            changeCallback={studySelected}
            placeholder={PLACEHOLDER_STUDY}
          />
        </Col>
        <Col xs={2} sm={4} md={4} lg={2}>
          <span className={"action-buttons"}>
            {actionButtons.map((button) => (
              <Button
                key={`hospital-${state.selectedHospital.id}`}
                id={`hospital-${state.selectedHospital.id}`}
                className={"action-button"}
                outline
                title={button.title}
                onClick={() =>
                  button.action(state.selectedHospital, state.selectedStudy)
                }
                disabled={isEmptyOrNull(state.selectedHospital)}
              >
                {button.icon}
              </Button>
            ))}
          </span>
        </Col>
      </Row>
    </div>
  );
};

SearchHospitalStudies.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ),
  excludeLinks: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default SearchHospitalStudies;

import PropTypes from "prop-types";
import { Progress } from "reactstrap";
import Responsive from "react-responsive";
import classnames from "classnames";

const Header = ({
  current = 0,
  total = 10,
  side = null,
  procedureType = null,
  className = null,
}) => {
  const getSideProcedureTypeText = () => {
    let text =
      !!side && !!procedureType
        ? " - " + [side, procedureType].join(" ")
        : " - general health";
    return text.toLowerCase();
  };

  return (
    <div className={"header"}>
      <div className={"title-row"}>
        <Responsive maxWidth={576}>
          <h6 className={"title"}>Your progress{getSideProcedureTypeText()}</h6>
        </Responsive>
        <Responsive minWidth={577}>
          <h6 className={"title"}>Your progress{getSideProcedureTypeText()}</h6>
          <div>
            Question {current}/{total}
          </div>
        </Responsive>
      </div>
      <div className={"instrument-progress"}>
        <Responsive maxWidth={576}>
          <Progress multi>
            <Progress
              bar
              value={current}
              max={total}
              className={classnames("left-progress", className)}
            >
              {current / total >= 0.5 ? `${current}/${total}` : ""}
            </Progress>
            <Progress
              bar
              value={total - current}
              max={total}
              className={"right-progress"}
            >
              {current / total < 0.5 ? `${current}/${total}` : ""}
            </Progress>
          </Progress>
        </Responsive>
        <Responsive minWidth={577}>
          <Progress value={current} max={total} barClassName={className} />
        </Responsive>
      </div>
    </div>
  );
};

Header.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  side: PropTypes.string,
  procedureType: PropTypes.string,
  className: PropTypes.string,
};

export default Header;

import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Nav, Navbar, NavItem, Row } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import groupAoa from "../../images/aoa_logo_blue.png";
import groupSahmri from "../../images/group_sahmri_logo.png";
import { MdHelpOutline } from "react-icons/md";
import { FaHome, FaSignOutAlt } from "react-icons/fa";
import { userLogout } from "../../actions/UserActions";
import Responsive from "react-responsive";
import PropTypes from "prop-types";

const Header = (props) => {
  const { logoIds = [], helpToggleCallback } = props;

  const user = useSelector((state) => state.user);
  const patient = useSelector((state) => state.patient);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getGroupLogo = () => {
    const { roleName } = user;
    let logo = null;
    switch (roleName) {
      case "Administrator":
        logo = groupSahmri;
        break;
      case "SAHMRI":
        logo = groupSahmri;
        break;
      default:
        break;
    }
    return logo ? (
      <Link to="/dashboard">
        <img src={logo} alt={"User Group Logo"} className={"logo group"} />
      </Link>
    ) : null;
  };

  const getStakeholderLogos = () => {
    return (
      <Fragment>
        {logoIds.map((id) => {
          return (
            <Link key={id} to="/dashboard">
              <img
                src={`api/stakeholder-group/${id}/logo`}
                alt={"Stakeholder Group Logo"}
                className={"logo group"}
              />
            </Link>
          );
        })}
      </Fragment>
    );
  };

  const shouldShowLogout = () => {
    if (user.found) {
      return true;
    }
    return !!(
      patient.id &&
      (location.pathname.includes("dashboard") ||
        location.pathname.includes("patient/register") ||
        location.pathname.includes("compare"))
    );
  };

  const shouldShowHome = () => {
    if (patient.id && location.pathname.includes("compare")) {
      return true;
    }
  };

  const logoutHandler = () => {
    let isAzureUser =
      user.roleName === "Administrator" ||
      user.roleName === "SAHMRI" ||
      user.roleName === "AOA";
    if (isAzureUser) {
      window.location.replace(
        `https://login.microsoftonline.com/${user.tenantId}/oauth2/v2.0/logout?post_logout_redirect_uri=${user.logoutRedirectUri}`,
      );
    } else {
      dispatch(userLogout());
    }
  };

  return (
    <Row className={"header g-0"}>
      <Col className={"navbar-brand"}>
        <img src={groupAoa} alt={"PROMs logo"} className={"logo proms"} />
      </Col>
      <Responsive minWidth={577}>
        <Col className={"navbar-group-logo"}>
          <Navbar color="faded" light expand={true} style={{ padding: "0" }}>
            <Responsive minWidth={577}>
              {getGroupLogo()}
              {getStakeholderLogos()}
            </Responsive>
            <Responsive minWidth={800}>
              <Nav navbar>
                {shouldShowHome() && (
                  <NavItem
                    className={"nav-item-icon"}
                    onClick={() => navigate("/dashboard")}
                  >
                    <FaHome className={"icon"} />
                    <div className={"text"}>Home</div>
                  </NavItem>
                )}
                <NavItem
                  className={"nav-item-icon"}
                  onClick={helpToggleCallback}
                >
                  <MdHelpOutline className={"icon help"} />
                  <div className={"text"}>Help</div>
                </NavItem>
                {shouldShowLogout() && (
                  <NavItem className={"nav-item-icon"} onClick={logoutHandler}>
                    <FaSignOutAlt className={"icon"} />
                    <div className={"text"}>Logout</div>
                  </NavItem>
                )}
              </Nav>
            </Responsive>
          </Navbar>
        </Col>
      </Responsive>
    </Row>
  );
};

Header.propTypes = {
  helpToggleCallback: PropTypes.func.isRequired,
  logoIds: PropTypes.arrayOf(PropTypes.number),
};

export default Header;

import { handleActions } from "redux-actions";
import {
  clearNewProcedure,
  clearPatientData,
  hasAvailableCollections,
  loadPatientDetails,
  previousResponsesLoaded,
  setAutoLogonDetails,
  setPatientField,
  setPatientProcedureField,
  setProcedureHospital,
  setProcedureState,
  setProcedureSurgeon,
  setScheduledProcedureDate,
} from "../actions/PatientActions";

const NEW_PROCEDURE = {
  leftKneeReplacement: false,
  rightKneeReplacement: false,
  leftKneeOsteotomy: false,
  rightKneeOsteotomy: false,
  leftHipReplacement: false,
  rightHipReplacement: false,
  leftShoulderReplacement: false,
  rightShoulderReplacement: false,
  state: null,
  hospital: null,
  surgeon: null,
  scheduledProcedureDate: null,
};

const PREVIOUS_RESPONSES = [];

const INITIAL_STATE = {
  id: null,
  firstName: "",
  middleNames: "",
  lastName: "",
  day: "",
  month: "",
  year: "",
  dateOfBirth: "",
  postcode: "",
  found: false,
  homePhone: "",
  mobilePhone: "",
  email: "",
  detailsConfirmed: false,
  newProcedure: { ...NEW_PROCEDURE },
  procedures: [],
  hasAvailableCollections: false,
  previousResponses: [...PREVIOUS_RESPONSES],
  canRegisterNewProcedure: null,
  loginDetails: { id: null, token: null },
};

export default handleActions(
  {
    [setPatientField]: (state, { payload }) => ({ ...state, ...payload }),
    [loadPatientDetails]: (state, { payload }) => {
      return { ...state, ...payload.patient };
    },
    [setPatientProcedureField]: (state, { payload }) => {
      let newState = { ...state };
      let procedure = { ...state.newProcedure };
      procedure[payload] = !procedure[payload];
      newState.newProcedure = procedure;
      return newState;
    },
    [setProcedureState]: (state, { payload }) => {
      let newState = copyState(state);
      newState.newProcedure.state = payload;
      newState.newProcedure.hospital = null;
      newState.newProcedure.surgeon = null;
      return newState;
    },
    [setProcedureHospital]: (state, { payload }) => {
      let newState = copyState(state);
      newState.newProcedure.hospital = payload;
      newState.newProcedure.surgeon = null;
      return newState;
    },
    [setScheduledProcedureDate]: (state, { payload }) => {
      let newState = copyState(state);
      newState.newProcedure.scheduledProcedureDate = payload;
      return newState;
    },
    [setProcedureSurgeon]: (state, { payload }) => {
      let newState = copyState(state);
      newState.newProcedure.surgeon = payload;
      return newState;
    },
    [clearPatientData]: () => {
      return { ...INITIAL_STATE, newProcedure: { ...NEW_PROCEDURE } };
    },
    [hasAvailableCollections]: (state, { payload }) => {
      let newState = copyState(state);
      newState.hasAvailableCollections = payload;
      if (newState.canRegisterNewProcedure == null) {
        // Variable is uninitialised, therefore set it to the opposite of whether collections are available
        newState.canRegisterNewProcedure =
          !newState.hasAvailableCollections.hasAvailableCollections;
      } else if (
        newState.canRegisterNewProcedure === true &&
        newState.hasAvailableCollections.hasAvailableCollections
      ) {
        // If the patient has just registered a new procedure from the register new procedure button,
        // prevent that button from ever appearing again in this session
        newState.canRegisterNewProcedure = false;
      }
      return newState;
    },
    [clearNewProcedure]: (state) => {
      let newState = copyState(state);
      newState.newProcedure = { ...NEW_PROCEDURE };
      return newState;
    },
    [previousResponsesLoaded]: (state, { payload }) => {
      let newState = copyState(state);
      newState.previousResponses = payload;
      return newState;
    },
    [setAutoLogonDetails]: (state, { payload }) => {
      let newState = copyState(state);
      newState.loginDetails = payload;
      return newState;
    },
  },
  { ...INITIAL_STATE, newProcedure: { ...NEW_PROCEDURE } },
);

function copyState(state) {
  return { ...state, newProcedure: { ...state.newProcedure } };
}

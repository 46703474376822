import { handleActions } from "redux-actions";

const INITIAL_STATE = { timeUnits: [], roles: [] };

export default handleActions(
  {
    TIME_UNITS_LOADED: (state, { payload }) => {
      return { ...state, ...{ timeUnits: payload } };
    },
    ROLES_LOADED: (state, { payload }) => {
      return { ...state, ...{ roles: payload } };
    },
  },
  INITIAL_STATE,
);

import { Button, Card, CardBody, CardTitle, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaCheck, FaEdit } from "react-icons/fa";
import { loadUserCurrent } from "../../../actions/UserActions";
import { CREATE, EDIT, isAoaUser, VIEW } from "../../../Utils";
import HospitalModal from "./HospitalModal";
import SearchHospitals from "./SearchHospitals";
import { MdPageview } from "react-icons/md";
import { loadStudies } from "../../../actions/CommonActions";
import { useEffect, useReducer } from "react";

const HospitalManagement = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const studies = useSelector((state) => state.studies);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      showModal: false,
      modalOperation: "",
      searchVersion: 0,
      modalHospitalId: null,
    },
  );

  useEffect(() => {
    !user.found && dispatch(loadUserCurrent());
    studies.length === 0 && dispatch(loadStudies());
  }, []);

  const handleSuccess = (message) => {
    setState({
      showModal: false,
      searchVersion: state.searchVersion + 1,
    });
    toast.success(
      <span>
        <FaCheck /> {message}
      </span>,
    );
  };

  const handleCloseCreateHospitalModal = () => {
    setState({ showModal: false });
  };

  const showCreateHospitalDialog = () => {
    setState({
      showModal: true,
      modalOperation: CREATE,
    });
  };

  const showEditHospitalDialog = (hospital) => {
    setState({
      showModal: true,
      modalOperation: EDIT,
      modalHospitalId: hospital.id,
    });
  };

  const showViewHospitalDialog = (hospital) => {
    setState({
      showModal: true,
      modalOperation: VIEW,
      modalHospitalId: hospital.id,
    });
  };

  const getActionButtonsForUser = () => {
    let buttons = [];
    if (isAoaUser(user)) {
      buttons.push({
        icon: <FaEdit />,
        title: "Edit Hospital",
        action: showEditHospitalDialog,
      });
    } else {
      buttons.push({
        icon: <MdPageview />,
        title: "View Hospital",
        action: showViewHospitalDialog,
      });
    }
    return buttons;
  };

  return (
    <div>
      <Container>
        <h1>Hospital Management</h1>
        {isAoaUser(user) && (
          <Card>
            <CardBody>
              <CardTitle>Create Hospital</CardTitle>
              <CardBody>
                <Button onClick={showCreateHospitalDialog}>Create...</Button>
              </CardBody>
            </CardBody>
          </Card>
        )}
        <Card>
          <CardBody>
            <CardTitle>Current Hospitals</CardTitle>
            <CardBody>
              <SearchHospitals
                actionButtons={getActionButtonsForUser()}
                resultsVersion={state.searchVersion}
              />
            </CardBody>
          </CardBody>
        </Card>
      </Container>
      {state.showModal && (
        <HospitalModal
          onConfirm={handleSuccess}
          onExit={handleCloseCreateHospitalModal}
          operation={state.modalOperation}
          hospitalId={state.modalHospitalId}
        />
      )}
    </div>
  );
};

export default HospitalManagement;

export function getSortedQuestions(type) {
  if (type && type.questions) {
    return type.questions.sort((q1, q2) => q1.index - q2.index);
  } else {
    return [];
  }
}

export function getSortedChoices(question) {
  if (question && question.choices) {
    return question.choices.sort((c1, c2) => c1.index - c2.index);
  } else {
    return [];
  }
}

export function isAnotherCrfDateQuestionType(type) {
  return (
    type != null && type.id != null && isAnotherCrfDateQuestionTypeId(type.id)
  );
}

export function isAnotherCrfDateQuestionTypeId(typeId) {
  return typeId === 1;
}

export function isAnotherCrfDateTimeQuestionType(type) {
  return (
    type != null &&
    type.id != null &&
    isAnotherCrfDateTimeQuestionTypeId(type.id)
  );
}

export function isAnotherCrfDateTimeQuestionTypeId(typeId) {
  return typeId === 1;
}

export function isParentCrfDateQuestionType(type) {
  return (
    type != null && type.id != null && isParentCrfDateQuestionTypeId(type.id)
  );
}

export function isParentCrfDateQuestionTypeId(typeId) {
  return typeId === 2;
}

export function isParentCrfDateTimeQuestionType(type) {
  return (
    type != null &&
    type.id != null &&
    isParentCrfDateTimeQuestionTypeId(type.id)
  );
}

export function isParentCrfDateTimeQuestionTypeId(typeId) {
  return typeId === 2;
}

export function isMultipleChoiceCrfQuestionTypeId(typeId) {
  return typeId === 1 || typeId === 2;
}

export function isDateCrfQuestionTypeId(typeId) {
  return typeId === 4;
}

export function isDateTimeCrfQuestionTypeId(typeId) {
  return typeId === 9;
}

export function isFreeTextCrfQuestionTypeId(typeId) {
  return typeId === 3 || typeId === 6;
}

export function isIntegerCrfQuestionTypeId(typeId) {
  return typeId === 7;
}

export function isDecimalCrfQuestionTypeId(typeId) {
  return typeId === 8;
}

export function isDecimalValidationDecimalPointTypeId(typeId) {
  return typeId === 3;
}

/**
 *
 * @param expandedIds component state of which ids are currently expanded
 * @param id the id to toggle expanded state
 * @returns the updated array of expanded ids
 */
export const toggleExpand = (expandedIds, id) => {
  let updated = expandedIds.slice();
  if (updated.includes(id)) {
    updated = updated.filter((i) => i !== id);
  } else {
    updated.push(id);
  }
  return updated;
};

/**
 * Removes a question validation for a CRF type
 *
 * @param questionValidations array of validations for that question
 * @param expandedIds component state of which ids are currently expanded
 * @param idToRemove validation id to remove
 * @param changeCallback updates the question validations in parent component
 * @returns the updated array of expanded ids
 */
export const handleValidationRemoval = (
  questionValidations,
  expandedIds,
  idToRemove,
  changeCallback,
) => {
  let updatedExpandedIds = expandedIds.filter((i) => i !== idToRemove);
  let updated = questionValidations.slice();
  let index = updated.findIndex((validation) => validation.id === idToRemove);
  updated.splice(index, 1);
  changeCallback(updated);
  return updatedExpandedIds;
};

import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, CardText } from "reactstrap";
import "./LogonButton.css";
import classnames from "classnames";

export const USER_TYPES = {
  surgeon: "surgeon",
  patient: "patient",
  administrator: "administrator",
};

const LogonButton = ({ userType }) => {
  const getHeaderText = (userType) => {
    if (userType === "surgeon") {
      return "Surgeons";
    } else if (userType === "patient") {
      return "Patients";
    } else if (userType === "administrator") {
      return "Administrators";
    }
  };

  const getBlurbText = (userType) => {
    if (userType === "surgeon") {
      return "Surgeons that have an account in the system";
    } else if (userType === "patient") {
      return "New or existing patients";
    } else if (userType === "administrator") {
      return "Hospital administrators or stakeholders that are already registered in the system";
    }
  };

  const getLink = (userType) => {
    if (userType === "surgeon") {
      return "/surgeon/logon";
    } else if (userType === "patient") {
      return "/patient/logon";
    } else if (userType === "administrator") {
      return "/user/logon";
    }
  };

  return (
    <a
      className={"logon-button"}
      href={getLink(userType)}
      title={"Logon - " + userType}
    >
      <Card className={"border-normal"}>
        <CardHeader className={"header"}>{getHeaderText(userType)}</CardHeader>
        <CardBody className={classnames("blurb", userType)}>
          <CardText>{getBlurbText(userType)}</CardText>
        </CardBody>
      </Card>
    </a>
  );
};

LogonButton.userTypes = USER_TYPES;
LogonButton.propTypes = {
  userType: PropTypes.oneOf(Object.keys(USER_TYPES)),
};

export default LogonButton;

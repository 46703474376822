import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Container,
  Fade,
} from "reactstrap";
import { reloadPreviousResponses } from "../../actions/PatientActions";
import Header from "./Header";
import { toast } from "react-toastify";
import { MdErrorOutline } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import "./Instrument.css";
import MultipleChoice from "./MultipleChoice";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  collectionsCompleted,
  getCollectionQuestions,
  saveCollectionResults,
} from "../../api/Patient";
import {
  startScreenTransition,
  stopScreenTransition,
} from "../../actions/CommonActions";
import VisualAnalogueScale from "./VisualAnalogueScale";
import SimpleNumericInput from "../common/SimpleNumericInput";
import { isEmptyOrNull, replaceHyphens } from "../../Utils";
import scrollToComponent from "react-scroll-to-component";
import OptionalModal from "./OptionalModal";
import MultiSelectMultipleChoice from "./MultiSelectMultipleChoice";
import DOMPurify from "dompurify";
import { useEffect, useReducer, useRef } from "react";
import { useOnUpdate } from "../CustomHooks";
import { getIndexFromPath } from "../../Utils";

const MULTI_SELECT_MULTIPLE_CHOICE_TYPE = "Multi-Select Multiple Choice";
const MULTIPLE_CHOICE_TYPE = "Multiple Choice";
const SINGLE_VALUE_TYPE = "Single Value";
const INFORMATION_TYPE = "Information";
const YES_NO = "Yes/No";

const Instrument = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);

  const scrollPoint = useRef();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      questions: [], // Straight list of all questions in order
      index: 0, // position of current screen in the questions
      progressTotal: 0, // The total number of non-informational question screens in the instrument.
      progress: 0, // The current progress, ignoring information screens.
      answers: {},
      optionalProcedureIds: [], // The procedure IDs where the patient has agreed to answer optional questions
      showOptionalModal: false,
      optionalModalProcedureType: null,
      optionalModalSide: null,
      optionalModalQuestions: 0,
      optionalModalProcedureId: null,
      backClick: false,
      nextClick: false,
      showOptionalQuestions: true,
    },
  );

  useEffect(() => {
    getCollectionQuestions()
      .then((response) => {
        if (response.data.length > 0) {
          let answers = prepareAnswerState(response.data);
          setState({
            questions: response.data,
            answers: answers,
          });
        } else {
          navigate("/dashboard", { replace: true });
        }
      })
      .catch((error) => {
        let message = "We encountered a problem loading the questionnaire:";
        if (
          error.response.status === 400 &&
          error.response.data.message ===
            "Missing session attribute 'patientId' of type Integer"
        ) {
          message += " It appears your session has timed out.";
        }
        toast.error(
          <span>
            <MdErrorOutline />
            <br />
            <br />
            {message} {error.message}
          </span>,
        );
        if (
          error.response.status === 400 &&
          error.response.data.message ===
            "Missing session attribute 'patientId' of type Integer"
        ) {
          navigate("/", { replace: true });
        } else {
          navigate("/dashboard", { replace: true });
        }
      });
  }, []);

  /**
   * Used to make sure the following hook is only run once when the patient starts the questions
   * (either for the first time or resuming mid-way)
   *
   * Must be dependent on {state.questions} as they need to be loaded before running any of the proceeding logic
   */
  const patientResumed = useRef(false);
  useOnUpdate(() => {
    if (patientResumed.current) {
      return;
    }
    setProgressDetails(() => {
      let index = getIndexFromPath(location, "instrument");
      if (index < 0 || index >= state.questions.length) {
        navigate("/dashboard", { replace: true });
      }
      let idx = getIndexForResumingCollection();
      if (!idx.showOptionalModal) {
        setState({ index: idx.index + 1 });
        if (
          idx.index > 0 &&
          state.questions[idx.index - 1].type === INFORMATION_TYPE
        ) {
          navigate(`/instrument/${idx.index}`);
        } else {
          navigate(`/instrument/${idx.index + 1}`);
        }
      } else {
        let optionalQuestions = state.questions.filter(
          (question) =>
            question.procedureId ===
              state.questions[idx.index - 2].procedureId &&
            question.optional &&
            question.type !== INFORMATION_TYPE,
        );
        let numOfOptionalQuestions = optionalQuestions.length;
        if (
          idx.showOptionalModal &&
          !state.optionalProcedureIds.includes(optionalQuestions[0].procedureId)
        ) {
          navigate(`/instrument/${optionalQuestions[0].index}`);
          setState({
            showOptionalModal: true,
            index: optionalQuestions[0].index,
            optionalModalProcedureType: optionalQuestions[0].procedureType,
            optionalModalSide: optionalQuestions[0].side,
            optionalModalQuestions: numOfOptionalQuestions,
            optionalModalProcedureId: optionalQuestions[0].procedureId,
          });
        } else {
          navigate(`/instrument/${idx.index + 1}`);
        }
      }
    });
    patientResumed.current = true;
  }, [state.questions]);

  useEffect(() => {
    if (state.questions.length > 0) {
      setProgressDetails(() => {
        scrollToComponent(scrollPoint.current, {
          align: "top",
          duration: 500,
        });
      });
    }
  }, [location, state.questions]);

  useEffect(() => {
    // If the current question is an optional question that the patient hasn't opted into yet,
    // we need to populate the data required to show the optional modal
    if (
      state.index < state.questions.length &&
      state.questions[state.index].optional &&
      !state.optionalProcedureIds.includes(
        state.questions[state.index].procedureId,
      )
    ) {
      let numberOfQuestions = state.questions.filter(
        (question) =>
          question.procedureId === state.questions[state.index].procedureId &&
          question.optional &&
          question.type !== INFORMATION_TYPE,
      ).length;
      setState({
        showOptionalModal: true,
        optionalModalProcedureType: state.questions[state.index].procedureType,
        optionalModalSide: state.questions[state.index].side,
        optionalModalQuestions: numberOfQuestions,
        optionalModalProcedureId: state.questions[state.index].procedureId,
      });
    }
  }, [state.index]);

  // Effect for when the patient selects to answer the optional question - updates the total progress
  useOnUpdate(() => {
    setState({ progressTotal: getProgressableScreenCount(state.questions) });
  }, [state.optionalProcedureIds]);

  const isTheAnswerPresentForQuestion = (question) => {
    if (question.type !== MULTI_SELECT_MULTIPLE_CHOICE_TYPE) {
      let prevQuestionIsInfoOrOptional =
        !isEmptyOrNull(state.questions[question.index - 1]) &&
        state.questions[question.index - 1].type === INFORMATION_TYPE;
      let prevAnswerNoForLinked =
        !prevQuestionIsInfoOrOptional &&
        question.index > 0 &&
        ((state.questions[question.index - 1].answerScore === 0 &&
          state.questions[question.index - 1].linkedQuestionId ===
            question.questionId) ||
          (state.questions[question.index - 1].answerScore === null &&
            state.questions[question.index - 1].answerScores === null &&
            state.questions[question.index - 1].linkedQuestionId ===
              question.questionId));
      if (
        prevQuestionIsInfoOrOptional &&
        !prevAnswerNoForLinked &&
        !question.optional
      ) {
        return question.answerScore !== null;
      } else if (!prevQuestionIsInfoOrOptional && prevAnswerNoForLinked) {
        return true;
      } else if (
        question.optional &&
        (isEmptyOrNull(state.optionalProcedureIds) ||
          !state.optionalProcedureIds.includes(question.procedureId))
      ) {
        return question.optional;
      } else if (
        prevQuestionIsInfoOrOptional &&
        !prevAnswerNoForLinked &&
        isEmptyOrNull(state.optionalProcedureIds)
      ) {
        return question.answerScore !== null;
      } else {
        return question.answerScore !== null;
      }
    } else {
      let prevAnswer =
        state.questions[question.index - 1].answerScore === 0 &&
        state.questions[question.index - 1].linkedQuestionId ===
          question.questionId;
      if (!prevAnswer) {
        return !isEmptyOrNull(question.answerScores);
      } else {
        return prevAnswer;
      }
    }
  };

  const getIndexForResumingCollection = () => {
    let indexObject = {};
    let questions = state.questions;
    for (let i = 0; i < questions.length; i++) {
      if (
        questions[i].type !== INFORMATION_TYPE &&
        !isTheAnswerPresentForQuestion(questions[i])
      ) {
        if (i > 1 && questions[i - 1].type === INFORMATION_TYPE) {
          let previousToInfoScreen = i - 2;
          if (
            questions[i - 1].procedureId !== null &&
            ((questions[i - 1].procedureId ===
              questions[previousToInfoScreen].procedureId &&
              questions[previousToInfoScreen].optional) ||
              (questions[i - 1].instrumentProcedureType !== null &&
                questions[previousToInfoScreen] !== null &&
                questions[previousToInfoScreen].optional &&
                questions[previousToInfoScreen].procedureId !==
                  questions[i - 1].procedureId &&
                questions[previousToInfoScreen].instrumentProcedureType ===
                  null &&
                questions[i - 1].instrumentProcedureType ===
                  questions[previousToInfoScreen].instrumentProcedureType &&
                questions[i - 1].side !==
                  questions[previousToInfoScreen].side)) &&
            questions[i].answerScore == null &&
            questions[i].questionId !== 0
          ) {
            indexObject = {
              index: questions[i - 1].index,
              showOptionalModal: true,
            };
            return indexObject;
          } else if (
            questions[i - 1].procedureId !== null &&
            ((questions[i - 1].procedureId !==
              questions[previousToInfoScreen].procedureId &&
              questions[previousToInfoScreen].optional) ||
              (questions[previousToInfoScreen].optional &&
                questions[i - 1].instrumentProcedureType !== null &&
                questions[previousToInfoScreen] !== null &&
                questions[previousToInfoScreen].instrumentProcedureType ===
                  null &&
                questions[i - 1].instrumentProcedureType ===
                  questions[previousToInfoScreen].instrumentProcedureType &&
                questions[i - 1].side !==
                  questions[previousToInfoScreen].side)) &&
            questions[i].answerScore == null &&
            questions[i].questionId !== 0
          ) {
            indexObject = {
              index: questions[i - 1].index,
              showOptionalModal: true,
            };
            return indexObject;
          } else if (
            questions[i - 1].procedureId !== null &&
            (questions[i - 1].procedureId ===
              questions[previousToInfoScreen].procedureId ||
              (questions[i - 1].instrumentProcedureType !== null &&
                questions[previousToInfoScreen] !== null &&
                questions[i - 1].instrumentProcedureType ===
                  questions[previousToInfoScreen].instrumentProcedureType)) &&
            questions[i].answerScore == null &&
            questions[i].questionId !== 0
          ) {
            indexObject = {
              index: questions[i - 1].index,
              showOptionalModal: false,
            };
            return indexObject;
          } else if (
            questions[i - 1].procedureId !== null &&
            (questions[i - 1].procedureId ===
              questions[previousToInfoScreen].procedureId ||
              (questions[i - 1].instrumentProcedureType !== null &&
                questions[previousToInfoScreen] !== null &&
                questions[previousToInfoScreen].instrumentProcedureType ===
                  null)) &&
            questions[i].answerScore == null &&
            questions[i].questionId !== 0
          ) {
            indexObject = {
              index: questions[i - 1].index,
              showOptionalModal: false,
            };
            return indexObject;
          } else if (
            questions[i - 1].procedureId !== null &&
            (questions[i - 1].procedureId !==
              questions[previousToInfoScreen].procedureId ||
              (questions[previousToInfoScreen].instrumentProcedureType !==
                null &&
                questions[previousToInfoScreen] !== null &&
                questions[i - 1].instrumentProcedureType !==
                  questions[previousToInfoScreen].instrumentProcedureType)) &&
            questions[i].answerScore == null &&
            questions[i].questionId !== 0
          ) {
            indexObject = {
              index: questions[i - 1].index,
              showOptionalModal: true,
            };
            return indexObject;
          }
        }
        if (
          i > 0 &&
          questions[i - 1].procedureId !== null &&
          questions[i - 1].optional &&
          !state.optionalProcedureIds.includes(questions[i - 1].procedureId) &&
          questions[i].procedureId === null &&
          questions[i].answerScore === null &&
          questions[i].questionId === 0
        ) {
          indexObject = {
            index: questions[i].index,
            showOptionalModal: true,
          };
          return indexObject;
        }
        if (
          i > 0 &&
          questions[i - 1].optional &&
          state.optionalProcedureIds.includes(questions[i - 1].procedureId) &&
          !isTheAnswerPresentForQuestion(questions[i - 1])
        ) {
          indexObject = {
            index: questions[i - 1].index,
            showOptionalModal: true,
          };
          return indexObject;
        } else {
          indexObject = {
            index: questions[i].index,
            showOptionalModal: false,
          };
          return indexObject;
        }
      }
      if (
        questions[i].type === MULTIPLE_CHOICE_TYPE &&
        questions[i].questionId === -1
      ) {
        indexObject = {
          index: questions[i].index,
          showOptionalModal: false,
        };
        return indexObject;
      }
    }
  };

  const setProgressDetails = (callback) => {
    let progressTotal = getProgressableScreenCount(state.questions);
    let index = getIndexFromPath(location, "instrument") || 0;
    let progress = getCurrentProgress(index, state.questions);
    setState({
      progressTotal: progressTotal,
      progress: progress,
      index: index,
    });
    !!callback && callback();
  };

  const prepareAnswerState = (questions) => {
    let answers = {};
    let value = null;
    questions.forEach((question, i) => {
      if (question.type !== INFORMATION_TYPE) {
        if (question.answerScore !== null || question.answerScores !== null) {
          if (question.type === SINGLE_VALUE_TYPE) {
            value = question.answerScore;
            let valid = false;
            if (
              question.singleValueLimit == null ||
              (value >= question.singleValueLimit.minValue &&
                value <= question.singleValueLimit.maxValue)
            ) {
              valid = true;
            }
            answers[i] = { id: null, value: value, valid: valid };
          } else if (
            question.type === MULTIPLE_CHOICE_TYPE ||
            question.type === YES_NO
          ) {
            let idx = null;
            for (let k = 0; k < question.choices.length; k++) {
              if (question.choices[k].choiceScore === question.answerScore) {
                idx = k;
              }
            }
            answers[i] = {
              allowFreeText: null,
              choiceScore: question.answerScore,
              id: question.choices[idx].id,
              index: question.choices[idx].index,
              shortText: null,
              choiceText: question.choices[idx].choiceText,
              freeText: question.freeText,
            };
          } else if (question.type === MULTI_SELECT_MULTIPLE_CHOICE_TYPE) {
            if (
              question.answerScore !== null &&
              question.answerScores === null
            ) {
              // New array with one value
              answers[i] = question.choices[question.answerScore];
            } else if (
              question.answerScore === null &&
              question.answerScores !== null
            ) {
              answers[i] = [];
              question.answerScores.forEach((score, _) => {
                value = {
                  allowFreeText: null,
                  choiceScore: question.choices.filter(
                    (choice) => choice.index === score,
                  )[0].choiceScore,
                  id: question.choices.filter(
                    (choice) => choice.index === score,
                  )[0].id,
                  index: question.choices.filter(
                    (choice) => choice.index === score,
                  )[0].index,
                  shortText: null,
                  choiceText: question.choices.filter(
                    (choice) => choice.index === score,
                  )[0].choiceText,
                };
                answers[i].push(value);
              });
            }
          }
        } else {
          answers[i] = null;
        }
      }
      if (
        question.optional &&
        (question.answerScore !== null || !isEmptyOrNull(question.answerScores))
      ) {
        let optionalIdsYes = state.optionalProcedureIds.slice();
        optionalIdsYes.push(question.procedureId);
        setState({
          optionalProcedureIds: optionalIdsYes,
        });
      } else if (question.type === INFORMATION_TYPE) {
        answers[i] = null;
      }
    });
    return answers;
  };

  const getAnswerForQuestion = (index) => {
    return state.answers[index];
  };

  const buildAnswer = (value, freeText, question) => {
    if (value === "" || value === null) {
      value = null;
    } else if (question.type === SINGLE_VALUE_TYPE) {
      value = parseInt(value, 10);
      let valid = false;
      if (
        question.singleValueLimit == null ||
        (value >= question.singleValueLimit.minValue &&
          value <= question.singleValueLimit.maxValue)
      ) {
        valid = true;
      }
      value = { id: null, value: value, valid: valid, saved: false };
    } else if (
      question.type === MULTIPLE_CHOICE_TYPE ||
      question.type === YES_NO
    ) {
      value.saved = false;
      value.freeText = freeText;
    } else {
      value.saved = false;
    }
    return value;
  };

  // Used for those components that need to record an answer, but the actual save mechanism is when
  // the user presses a next or back button.
  const recordAnswer = (value, question, index) => {
    let answers = { ...state.answers };
    answers[index] = buildAnswer(value, null, question);
    setState({ answers: answers });
  };

  // Same as the above recordAnswer method, but used for components that have multiple answers.
  const recordAnswers = (value, question, index) => {
    let answers = { ...state.answers };
    if (answers[index] == null) {
      // New array with one value
      answers[index] = [value];
    } else {
      if (answers[index].map((item) => item.id).includes(value.id)) {
        // existing array and the value is being un-chosen as it already exists in the answers, so remove it
        answers[index] = answers[index].filter((item) => item.id !== value.id);
      } else {
        // add value to answers
        answers[index].push(value);
      }
    }
    setState({ answers: answers });
  };

  const saveAnswer = (value, freeText, question, link) => {
    let hasAnswerChanged = false;
    let answers = { ...state.answers };
    dispatch(startScreenTransition());
    // Set the next progress step here, so we get the bar smoothly growing instead of jumping.
    let progress = getCurrentProgress(state.index + 1, state.questions);
    // massage the return value for numeric input...
    let answer = buildAnswer(value, freeText, question);
    if (
      answers[state.index] === null ||
      (answers[state.index] != null && answers[state.index] !== answer)
    ) {
      hasAnswerChanged = true;
    }

    answers[question.index] = answer;
    setState({
      answers: answers,
      index: question.index,
      saving: true,
      progress: progress,
    });
    if (shouldAutoAdvance(question, answer)) {
      if (hasAnswerChanged) {
        link = getLinkNext();
      }
      setTimeout(() => submitAnswer(answer, question, link), 750);
    } else {
      setState({ saving: false });
    }
  };

  const submitAnswer = (answer, question, link) => {
    if (
      !!answer &&
      (!answer.saved || question.type === MULTI_SELECT_MULTIPLE_CHOICE_TYPE)
    ) {
      let _score = null;
      if (question.type !== MULTI_SELECT_MULTIPLE_CHOICE_TYPE) {
        if (answer.value != null) {
          _score = answer.value;
        } else {
          _score = answer.choiceScore;
        }
      }
      saveCollectionResults({
        linkedQuestionId: question.linkedQuestionId,
        studyCollectionId: question.studyCollectionId,
        procedureId: question.procedureId,
        procedureCollectionId: question.procedureCollectionId,
        linkedProcedureCollectionIds: question.linkedProcedureCollectionIds,
        questionId: question.questionId,
        score: _score,
        scores:
          question.type === MULTI_SELECT_MULTIPLE_CHOICE_TYPE
            ? answer.map((value) => value.choiceScore)
            : null,
        freeTextInput: answer.freeText ? answer.freeText : null,
      })
        .then(() => {
          answer.saved = true;
          let answers = { ...state.answers };
          answers[question.index] = answer;
          setState({
            answers: answers,
            saving: false,
            backClick: false,
            nextClick: false,
          });

          dispatch(stopScreenTransition());
          if (link != null) {
            navigate(link);
          }
        })
        .catch((error) => {
          setState({ saving: false, backClick: false, nextClick: false });
          dispatch(stopScreenTransition());
          if (error?.response?.data?.message) {
            toast.error(
              "Sorry, we were unable to save your results at this time. Please log out and log back in to complete your survey.",
            );
          } else {
            toast.error(
              "Sorry, we were unable to save your results at this time. Please check your network connection.",
            );
          }
        });
    } else {
      dispatch(stopScreenTransition());
      setState({ nextClick: false, backClick: false, saving: false });
      if (link != null) {
        navigate(link);
      }
    }
  };

  const shouldAutoAdvance = (question, answer) => {
    if (question.type === MULTI_SELECT_MULTIPLE_CHOICE_TYPE) {
      return false;
    }
    if (question.type === MULTIPLE_CHOICE_TYPE || question.type === YES_NO) {
      return !answerRequiresFreeText(question, answer);
    }
    return false;
  };

  const getCurrentProgress = (index, questions) => {
    let progress = 0;
    // For progress bar, we only count questions that have answer potential... i.e. if a question doesn't have a potential
    // answer, it's an information screen and shouldn't be included in the progress display.
    for (let i = 0; i <= index; i++) {
      let question = questions[i];
      if (shouldQuestionCountAsProgress(question)) {
        progress++;
      }
    }
    return progress;
  };

  const shouldQuestionCountAsProgress = (question) => {
    return (
      !!question &&
      question.type !== INFORMATION_TYPE &&
      (!question.optional ||
        state.optionalProcedureIds.includes(question.procedureId))
    );
  };

  const getProgressableScreenCount = (questions) => {
    return questions.filter((q) => shouldQuestionCountAsProgress(q)).length;
  };

  const answerRequiresFreeText = (question, answer) => {
    if (answer == null) {
      return false;
    }
    if (
      question.type !== MULTIPLE_CHOICE_TYPE &&
      question.type !== YES_NO &&
      question.type !== MULTI_SELECT_MULTIPLE_CHOICE_TYPE
    ) {
      return false;
    }
    return (
      question.choices.filter(
        (c) => c.allowFreeText === true && c.choiceScore === answer.choiceScore,
      ).length > 0
    );
  };

  const getButtonRow = () => {
    const isLastQuestion = state.index === state.questions.length - 1;
    let nextButtonContent = isLastQuestion ? "Finish" : "Next";
    let backButtonContent = "Back";
    let disabled = false;
    if (state.saving) {
      nextButtonContent = <FaSpinner className="wait-spin" />;
      backButtonContent = <FaSpinner className="wait-spin" />;
      disabled = true;
    }
    let nextAction = () => {
      dispatch(startScreenTransition());
      setState({
        progress: getCurrentProgress(state.index + 1, state.questions),
        nextClick: true,
        saving: true,
      });
      setTimeout(
        () =>
          submitAnswer(
            state.answers[state.index],
            state.questions[state.index],
            getLinkNext(),
          ),
        500,
      );
    };
    let backAction = () => {
      dispatch(startScreenTransition());
      setState({
        progress: getCurrentProgress(state.index - 1, state.questions),
        backClick: true,
      });
      setTimeout(
        () =>
          submitAnswer(
            state.answers[state.index],
            state.questions[state.index],
            getLinkBack(),
          ),
        500,
      );
    };
    let finishAction = () => {
      dispatch(startScreenTransition());
      setState({ nextClick: true });
      let procedureCollections = [];
      state.questions.forEach((value) => {
        if (value.procedureCollectionId != null) {
          procedureCollections.push(value.procedureCollectionId);
        }
        if (value.linkedProcedureCollectionIds != null) {
          value.linkedProcedureCollectionIds.forEach((lpci) => {
            if (lpci != null) {
              procedureCollections.push(lpci);
            }
          });
        }
      });

      let payload = Array.from(new Set(procedureCollections));
      collectionsCompleted({
        completedProcedureCollectionIds: payload,
      })
        .then(() => {
          // This is just to send an email thank you, so isn't very important
        })
        .catch(() => {
          // This is just to send an email thank you, so isn't very important
        })
        .then(() => {
          // This block is always executed
          dispatch(stopScreenTransition());
          dispatch(reloadPreviousResponses());
          setState({ backClick: false, nextClick: false });
          navigate(getLinkNext());
        });
    };

    return (
      <div className={"button-row"}>
        <Button
          color="primary"
          disabled={disabled || state.index === 0}
          outline
          className={state.backClick ? "force-hover" : null}
          onClick={backAction}
        >
          {backButtonContent}
        </Button>
        {canHaveNextButton() && (
          <Button
            color="primary"
            disabled={disabled}
            outline
            className={state.nextClick ? "force-hover" : null}
            onClick={isLastQuestion ? finishAction : nextAction}
          >
            {nextButtonContent}
          </Button>
        )}
      </div>
    );
  };

  const canHaveNextButton = () => {
    const question = state.questions[state.index];
    const answer = state.answers[state.index];
    if (
      !isEmptyOrNull(question) &&
      shouldAutoAdvance(question) &&
      !answerRequiresFreeText(question, answer)
    ) {
      return false;
    }
    if (
      !isEmptyOrNull(question) &&
      answerRequiresFreeText(question, answer) &&
      answer.freeText &&
      !isEmptyOrNull(answer.freeText)
    ) {
      return true;
    }
    return (
      (!isEmptyOrNull(question) && question.type === INFORMATION_TYPE) ||
      (question.type === SINGLE_VALUE_TYPE && answer != null && answer.valid) ||
      (question.type === MULTI_SELECT_MULTIPLE_CHOICE_TYPE &&
        state.answers[state.index] != null &&
        state.answers[state.index].length > 0)
    );
  };

  const getLinkNext = () => {
    let question;
    if (
      state.index === 0 ||
      state.questions[state.index] === INFORMATION_TYPE
    ) {
      return state.index === state.questions.length - 1
        ? "/dashboard"
        : `/instrument/${state.index + 2}`;
    } else if (
      state.answers[state.index] != null &&
      state.answers[state.index] !== undefined &&
      state.questions[state.index].type === YES_NO &&
      state.answers[state.index].choiceText === "No" &&
      state.answers[state.index].choiceScore === 0 &&
      state.questions[state.index].linkedQuestionId !== null
    ) {
      question =
        state.questions[
          state.questions
            .slice(state.index + 1)
            .find((q) => q.linkedQuestionId === null).index + 1
        ];
      let copiedAnswers = Object.assign({}, state.answers);
      for (let i = state.index + 1; i < question.index; i++) {
        copiedAnswers[i] = null;
      }
      setState({
        answers: copiedAnswers,
        index: question.index + 1,
      });
      return state.index === state.questions.length - 1
        ? "/dashboard"
        : `/instrument/${question.index + 1}`;
    } else {
      return state.index === state.questions.length - 1
        ? "/dashboard"
        : `/instrument/${state.index + 2}`;
    }
  };

  const getLinkBack = () => {
    let copiedAnswers = Object.assign({}, state.answers);
    let backIndex;
    for (let k = state.index - 1; k >= 0; k--) {
      if (copiedAnswers[k] !== null) {
        backIndex = k;
        break;
      }
    }
    if (
      state.answers[state.index - 1] !== undefined &&
      state.answers[state.index - 1] !== null &&
      state.backClick === true
    ) {
      return state.index === 0 ? "/dashboard" : `/instrument/${state.index}`;
    } else if (
      !isEmptyOrNull(state.answers[backIndex]) &&
      state.answers[backIndex] !== "undefined"
    ) {
      setState({
        index: backIndex + 1,
      });
      return `/instrument/${backIndex + 1}`;
    } else if (state.index === 1) {
      return `/instrument/${state.index}`;
    }
  };

  const optionalModalYes = (procedureId) => {
    let updated = state.optionalProcedureIds.slice();
    updated.push(procedureId);
    setState({
      showOptionalModal: false,
      optionalProcedureIds: updated,
      progress: getCurrentProgress(state.index, state.questions),
    });
  };

  const optionalModalNo = (procedureId) => {
    let updated = state.questions;
    // Purge questions that have just been essentially declined to be answered
    updated = updated.filter(
      (question) =>
        !(question.optional && question.procedureId === procedureId),
    );
    setState({
      showOptionalModal: false,
      questions: updated,
      showOptionalQuestions: false,
      progress: getCurrentProgress(state.index, updated),
    });
    updateQuestionIndices(state.index, updated);
  };

  const updateQuestionIndices = (index, questions) => {
    questions.forEach((question, j) => {
      if (question.index !== j) {
        let idx = question.index;
        question.index = j;
        state.answers[j] = state.answers[idx];
      }
    });
  };

  const renderQuestionType = (question, index) => {
    let answer = getAnswerForQuestion(index);
    switch (question.type) {
      case MULTIPLE_CHOICE_TYPE:
      case YES_NO:
        return (
          <MultipleChoice
            options={question.choices}
            value={answer}
            responseAction={(value, freeText) =>
              saveAnswer(value, freeText, question, getLinkNext())
            }
            transitioning={app.isTransitioning}
          />
        );
      case MULTI_SELECT_MULTIPLE_CHOICE_TYPE:
        return (
          <MultiSelectMultipleChoice
            options={question.choices}
            values={answer}
            responseAction={(value) => recordAnswers(value, question, index)}
            transitioning={app.isTransitioning}
          />
        );
      case SINGLE_VALUE_TYPE:
        if (!!question.singleValueLimit && question.singleValueLimit.useVas) {
          return (
            <VisualAnalogueScale
              {...question}
              value={answer ? answer.value : null}
              updateAction={(value) => recordAnswer(value, question, index)}
              valid={answer ? answer.valid : true}
              transitioning={app.isTransitioning}
            />
          );
        } else {
          return (
            <SimpleNumericInput
              updateAction={(value) => recordAnswer(value, question, index)}
              value={
                answer !== null && answer !== undefined
                  ? answer.value.toString()
                  : null
              }
              field={null}
              length={question.singleValueLimit.maxValue.toString().length}
              invalid={answer ? !answer.valid : false}
              invalidNotice={`Answer must be in the range of ${question.singleValueLimit.minValue} to ${question.singleValueLimit.maxValue}`}
            />
          );
        }
      case INFORMATION_TYPE:
      default:
        return <div />;
    }
  };

  return (
    <Container className={"instrument"}>
      <Routes>
        {state.questions.map((question, i) => {
          let isVas =
            !!question.singleValueLimit && !!question.singleValueLimit.useVas;
          return (
            <Route
              path={`${i + 1}`}
              key={i}
              element={
                <Card className={question.instrumentProcedureType}>
                  <CardBody>
                    <div ref={scrollPoint} />
                    <Header
                      current={state.progress}
                      total={state.progressTotal}
                      procedureType={question.instrumentProcedureType}
                      side={question.side}
                      className={question.instrumentProcedureType}
                    />
                    {!!question.showName && (
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            replaceHyphens(question.name),
                          ),
                        }}
                      />
                    )}
                    {!!question.showHeader && (
                      <CardTitle>{question.header}</CardTitle>
                    )}
                    {!isVas && (
                      <Fade
                        in={
                          !app.isTransitioning ||
                          question.type !== "Information"
                        }
                        style={{ width: "100%" }}
                      >
                        <CardText className={"question-text"}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                replaceHyphens(question.text),
                              ),
                            }}
                          />
                        </CardText>
                      </Fade>
                    )}
                    <CardBody className={"inner"}>
                      {renderQuestionType(question, i)}
                    </CardBody>
                    {!!question.showFooter && (
                      <div
                        className="footer"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(question.footer),
                        }}
                      />
                    )}
                    {getButtonRow()}
                  </CardBody>
                  <OptionalModal
                    procedureType={state.optionalModalProcedureType}
                    side={state.optionalModalSide}
                    isOpen={state.showOptionalModal}
                    numberOfQuestions={state.optionalModalQuestions}
                    procedureId={state.optionalModalProcedureId}
                    noCallback={optionalModalNo}
                    yesCallback={optionalModalYes}
                  />
                </Card>
              }
            />
          );
        })}
      </Routes>
    </Container>
  );
};

export default Instrument;

import { handleActions } from "redux-actions";
import { sendMfaCodeError, sendMfaCodeSuccess } from "../actions/UserActions";

const INITIAL_STATE = {
  firstName: null,
  lastName: null,
  roleId: null,
  roleName: null,
  email: null,
  mobile: null,
  found: false,
  majorProcedureTypeId: null,
  hospitalIds: [],
  tenantId: null,
  logoutRedirectUri: null,
  codeError: null,
  codeSuccess: null,
};

export default handleActions(
  {
    USER_CURRENT_LOADED: (state, { payload }) => ({ ...state, ...payload }),
    USER_LOGOUT: () => INITIAL_STATE,
    [sendMfaCodeError]: (state, { payload }) => ({
      ...state,
      ...{ codeError: payload },
    }),
    [sendMfaCodeSuccess]: (state, { payload }) => ({
      ...state,
      ...{ codeSuccess: payload },
    }),
  },
  INITIAL_STATE,
);

import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";

const SimpleSurgeonSelector = ({
  clearable = true,
  disabled = false,
  searchable,
  value,
  onChange,
}) => {
  const surgeons = useSelector((state) => state.surgeons);

  const renderOption = (surgeon) => {
    return surgeon.id === 0 ? (
      <div>
        <i>{surgeon.firstName}</i>
      </div>
    ) : (
      <div>
        Dr {surgeon.lastName}, {surgeon.firstName}
      </div>
    );
  };

  const filterOption = (option, filter) => {
    let firstLast = `${option.data.firstName} ${option.data.lastName} ${option.data.surgeonCode}`;
    return firstLast.toLowerCase().includes(filter.toLowerCase());
  };

  return (
    <div className={"simple-surgeon-selector"}>
      <Row>
        <Col xs={1} sm={1} md={1} lg={2} xl={3} />
        <Col>
          <Select
            options={surgeons}
            onChange={onChange}
            isClearable={clearable}
            isSearchable={searchable}
            getOptionLabel={(option) => renderOption(option)}
            getOptionValue={(option) => option.id}
            value={value}
            isDisabled={disabled}
            tabIndex={1}
            autoFocus
            styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            filterOption={filterOption}
          />
          <div className={"subdued"}>
            If you do not know your surgeon’s name, please leave this box blank.
            If your surgeon is not listed, please{" "}
            <a href={"/#contact"} target={"_blank"}>
              contact
            </a>{" "}
            the AOANJRR Project Coordinator.
          </div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={2} xl={3} />
      </Row>
    </div>
  );
};

SimpleSurgeonSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
};

export default SimpleSurgeonSelector;

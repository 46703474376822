import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { memo } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarChart = memo(({ dataset, stacked, mapColours, xAxisTicks = 8 }) => {
  /** Custom positioner to fix tooltip to the top of the chart */
  Tooltip.positioners.fixedY = function (items) {
    const pos = Tooltip.positioners.average(items);

    // Happens when nothing is found
    if (pos === false) {
      return false;
    }

    // Change the offset dependent on how many items shown in the tooltip,
    // so it displays effectively the same distance away from the top of the chart
    const yOffset = {
      2: 75,
      3: 90,
      4: 108,
    };

    const chart = this.chart;
    return {
      x: pos.x,
      y: chart.chartArea.top + yOffset[items.length],
      xAlign: "center",
      yAlign: "bottom",
    };
  };

  const formatTick = (date) => {
    if (dataset.isMonthlyData) {
      return new Date(date).toLocaleDateString("en-AU", {
        month: "long",
        year: "numeric",
      });
    } else {
      return new Date(date).toLocaleDateString("en-AU");
    }
  };

  const getXAxisTicks = () => {
    let minimum = 0;
    Object.keys(dataset.data)
      .filter((key) => dataset.data[key].length > 0)
      .forEach((key) => {
        if (
          minimum === 0 ||
          dataset.data[key].filter((value) => value != null).length < minimum
        ) {
          minimum = dataset.data[key].filter((value) => value != null).length;
        }
      });
    return Math.min(minimum, xAxisTicks);
  };

  const options = {
    plugins: {
      title: { display: false },
      tooltip: {
        mode: "x",
        intersect: false,
        position: "fixedY",
        yAlign: "bottom",
      },
    },
    hover: { mode: "x" },
    scales: {
      x: {
        stacked: stacked,
        ticks: { maxTicksLimit: getXAxisTicks() },
        grid: { drawOnChartArea: false },
        title: { display: dataset.xAxisLabel, text: dataset.xAxisLabel },
      },
      y: {
        stacked: stacked,
        ticks: { maxTicksLimit: 20 },
        title: { display: dataset.yAxisLabel, text: dataset.yAxisLabel },
      },
    },
  };

  const data = {
    labels: Object.values(dataset.data)[0].map((d) => formatTick(d.x)),
    datasets: Object.keys(dataset.data).map((key, index) => {
      return {
        label: key,
        data: dataset.data[key].map((d) => d.y),
        backgroundColor: () => mapColours(key, index),
      };
    }),
  };

  return <Bar options={options} data={data} />;
});

export default BarChart;

import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getImpersonationToken } from "../../../api/Patient";
import { logout } from "../../../api/User";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading, startLoading } from "../../../actions/CommonActions";
import { isEmptyOrNull } from "../../../Utils";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ButtonBar from "../../common/ButtonBar";
import { useEffect, useState } from "react";

const getUrl = (patientId, token, user) => {
  let result = `/patient/${patientId}/impersonate/${user.id}/${token}`;
  if (window.location.port) {
    result = `:${window.location.port}${result}`;
  }
  if (window.location.hostname) {
    result = `${window.location.hostname}${result}`;
  }
  if (window.location.protocol) {
    result = `${window.location.protocol}//${result}`;
  }
  return result;
};

const ImpersonationTokenRequest = ({
  cancelCallback,
  clipboardSaveCallback,
  patientId,
  patientName,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [token, setToken] = useState(null);

  useEffect(() => {
    dispatch(startLoading());
    getImpersonationToken(patientId)
      .then((response) => setToken(response.data))
      .finally(() => dispatch(finishLoading()));
  }, []);

  const handleImpersonatePatient = (patientId) => {
    if (isEmptyOrNull(token)) {
      toast.error("Patient logon URL not found");
    } else {
      logout().finally(() => {
        window.location.replace(getUrl(patientId, token, user));
      });
    }
  };

  const buttons = [
    <Button
      color={"primary"}
      onClick={() => handleImpersonatePatient(patientId)}
    >
      Logout and back in as patient
    </Button>,
    <CopyToClipboard
      text={getUrl(patientId, token, user)}
      onCopy={() => clipboardSaveCallback()}
    >
      <Button>Copy logon URL to clipboard</Button>
    </CopyToClipboard>,
    <Button onClick={() => cancelCallback()}>Cancel</Button>,
  ];

  return (
    <Modal isOpen={true} size={"lg"} toggle={() => cancelCallback()}>
      <ModalHeader>Impersonate {patientName}?</ModalHeader>
      <ModalBody>
        Do you wish to impersonate <b>{` ${patientName} `}</b> in your current
        browser (you will be logged out and will need to login again to log the
        phone call you have made), or do you wish to copy the logon URL to your
        clipboard so you can use a different browser?{" "}
        <b>Note that logon URLs expire after 24 hours and cannot be re-used.</b>
        <ButtonBar buttons={buttons} />
      </ModalBody>
    </Modal>
  );
};

ImpersonationTokenRequest.propTypes = {
  patientId: PropTypes.number.isRequired,
  patientName: PropTypes.string.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  clipboardSaveCallback: PropTypes.func.isRequired,
};

export default ImpersonationTokenRequest;

import PropTypes from "prop-types";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { getStartCase } from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { optBackInPatient } from "../../api/Patient";
import "./ConfirmPatientDetailsModal.css";
import { userLogout } from "../../actions/UserActions";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";

const OptBackInModal = ({ isOpen, callBack }) => {
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patient);

  const saveDetails = () => {
    dispatch(startLoading());
    optBackInPatient(patient.id)
      .then((response) => {
        if (response.data) {
          toast.success(
            <span>
              <FaCheck /> You have successfully opted back into the study.
            </span>,
          );
          callBack();
        } else {
          toast.error("An error occurred opting you back in.");
        }
      })
      .finally(() => dispatch(finishLoading()));
  };

  let optOuts = patient.procedures.filter((p) => !!p.optOutDate);
  return (
    <Modal className={"opt-back-in-modal"} isOpen={isOpen}>
      <ModalBody>
        <div>
          <div className={"opt-back-in-blurb"}>
            <h5 className={"opt-back-in-welcome"}>
              Welcome back {patient.firstName}
            </h5>
            <p>
              You had previously contacted us asking to be removed from the
              study for the following procedure{optOuts.length > 1 ? "s" : ""}:
            </p>
            <ul>
              {" "}
              {optOuts.map((op) => {
                return (
                  <li>
                    {getStartCase(op.side)} {getStartCase(op.procedureType)} at{" "}
                    {op.hospital.name}
                  </li>
                );
              })}
            </ul>
            <p>
              If you have changed your mind and wish to participate, please
              press the{" "}
              <b>
                <i>I now wish to participate</i>
              </b>{" "}
              button.
            </p>
            <p>
              Otherwise, please choose the{" "}
              <b>
                <i>I do not wish to participate</i>
              </b>{" "}
              button.
            </p>
          </div>
        </div>
        <Row className={"opt-back-in-button-bar-row"}>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <Button
              color={"danger"}
              className={"float-left"}
              onClick={dispatch(userLogout)}
            >
              I do not wish to participate
            </Button>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} />
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <Button
              color={"success"}
              className={"float-right"}
              onClick={saveDetails}
            >
              I now wish to participate
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

OptBackInModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  callBack: PropTypes.func.isRequired,
};

export default OptBackInModal;

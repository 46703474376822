import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from "react";
import { Button, Container } from "reactstrap";
import "./NewPatient.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  autoLogonPatient,
  setPatientField,
} from "../../actions/PatientActions";
import SimpleNumericInput from "../common/SimpleNumericInput";
import SimpleMonthInput from "../common/SimpleMonthInput";
import SimpleCalendarInput from "../common/SimpleCalendarInput";
import ReviewDob from "./ReviewDob";
import Moment from "moment";
import { isEmptyOrNull, validateLength } from "../../Utils";
import {
  generateLinkButton,
  NEXT,
  PREVIOUS,
  renderRoute,
  validateDobMandatory,
  validateDobMonth,
  validateDobYear,
} from "./Utils";
import SimpleLogoHeader from "../common/SimpleLogoHeader";
import scrollToComponent from "react-scroll-to-component";

const PatientDobConfirm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const scrollPoint = useRef();

  const patient = useSelector((state) => state.patient);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      year: false,
      month: false,
      day: false,
    },
  );

  useEffect(() => {
    let locations = location.pathname.match(/^\/patient\/logon-sms\/(.+)$/);
    if (
      (!!locations && locations.length > 0 && locations[1] !== "year") ||
      patient.loginDetails.id == null
    ) {
      // The only valid entry point for this screen is... the first one. Anything later, redirect back to the start.
      navigate("/", { replace: true });
    }
    setState(validatePatientData(patient));
  }, []);

  useLayoutEffect(() => {
    setState(validatePatientData(patient));
    scrollToComponent(scrollPoint.current, {
      align: "top",
      duration: 500,
    });
  }, [patient]);

  const validatePatientData = (patient) => {
    return {
      year: validateDobYear(patient.year),
      month: validateDobMonth(patient.year, patient.month),
      day: validateDobMandatory(patient.year, patient.month, patient.day),
    };
  };

  /**
   * Wrapper function for the useNavigate hook that appends the route with ../ to have relative path routing
   * This way the route is matched on .../patient/logon-sms/ and not .../patient/logon-sms/*previous route*
   *
   * This function must be in this component as the useNavigate hook can only be called from a React component
   *
   * @param route where to navigate to
   * @private
   */
  const _navigate = (route) => {
    navigate(`../${route}`, { relative: "path" });
  };

  /**
   * Validates the current state which is also the current url path and navigates to the next route
   *
   * This function must be in this component as the useNavigate hook can only be called from a React component
   *
   * @param current
   * @param next
   */
  const advanceOnEnter = (current, next) => {
    state[current] && _navigate(next);
  };

  const submitSmsAutoLogon = () => {
    if (isEmptyOrNull(patient.loginDetails.id)) {
      return;
    }
    let dob = Moment(
      `${patient.year}-${patient.month}-${patient.day}`,
      "YYYY-MMMM-Do",
    ).format("YYYY-MM-DD");
    const payload = {
      id: patient.loginDetails.id,
      token: patient.loginDetails.token,
      dateOfBirth: dob,
    };
    dispatch(autoLogonPatient(payload));
  };

  const renderProgressButtons = () => {
    const getProgressButtonColor = (current) => {
      if (isEmptyOrNull(patient[current])) {
        return "secondary";
      }
      if (state[current]) {
        return "primary";
      }
      return "danger";
    };

    let yearColor = getProgressButtonColor("year");
    if (
      isEmptyOrNull(patient.year) &&
      (!isEmptyOrNull(patient.month) || !isEmptyOrNull(patient.day))
    ) {
      yearColor = "danger";
    }

    return (
      <Fragment>
        <Button tabIndex={-1} color={yearColor} />
        <Button tabIndex={-1} color={getProgressButtonColor("month")} />
        <Button tabIndex={-1} color={getProgressButtonColor("day")} />
      </Fragment>
    );
  };

  return (
    <div>
      <SimpleLogoHeader link={true} />
      <Container className="patient-logon">
        <div className={"header"}>
          <h2 className={"welcome"}>Welcome!</h2>
          <div className="header-paragraph">
            Before we get started, would you mind please confirming your date of
            birth?
          </div>
          <div ref={scrollPoint} />
          <div className={"progress-buttons"}>{renderProgressButtons()}</div>
        </div>
        <Routes>
          {renderRoute(
            "year",
            <SimpleNumericInput
              field={"year"}
              length={4}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.year}
              invalid={validateLength(patient.year, 4) ? !state.year : false}
              invalidNotice={
                "Sorry, you must be between 16 and 118 years old to use this service."
              }
              hintText={"Please enter a 4-digit year (e.g. 1950)"}
              enterAction={() => advanceOnEnter("year", "month")}
            />,
            <div className={"standout"}>In which YEAR were you born?</div>,
            generateLinkButton(2, NEXT, !state.year, () => _navigate("month")),
            null,
          )}
          {renderRoute(
            "month",
            <SimpleMonthInput
              field={"month"}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.month}
              invalid={!isEmptyOrNull(patient.month) && !state.month}
              invalidNotice={
                "Sorry, you must be between 16 and 118 years old to use this service."
              }
              enterAction={() => advanceOnEnter("month", "day")}
            />,
            <div className={"standout"}>In which MONTH were you born?</div>,
            generateLinkButton(3, PREVIOUS, null, () => _navigate("year")),
            generateLinkButton(2, NEXT, !state.month, () => _navigate("day")),
          )}
          {renderRoute(
            "day",
            <SimpleCalendarInput
              field={"day"}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.day}
              month={patient.month}
              year={patient.year}
              invalid={!isEmptyOrNull(patient.day) && !state.day}
              invalidNotice={
                "Sorry, you must be between 16 and 118 years old to use this service."
              }
              enterAction={() => advanceOnEnter("day", "review")}
            />,
            <div className={"standout"}>On which DATE were you born?</div>,
            generateLinkButton(3, PREVIOUS, null, () => _navigate("month")),
            generateLinkButton(2, NEXT, !state.day, () => _navigate("review")),
          )}
          {renderRoute(
            "review",
            <ReviewDob patient={patient} />,
            <div className={"standout"}>Review your details</div>,
            generateLinkButton(2, PREVIOUS, !state.day, () => _navigate("day")),
            <Button
              tabIndex={1}
              onClick={() => submitSmsAutoLogon()}
              color={validatePatientData(patient) ? "primary" : "secondary"}
              disabled={!validatePatientData(patient)}
              autoFocus
              outline={!validatePatientData(patient)}
            >
              Submit
            </Button>,
          )}
          {/* Would like to have used /patient/logon/sms/year but that didn't work.
               went to the parent /patient/logon */}
          <Route
            path="*"
            element={<Navigate to="/patient/logon-sms/year" replace />}
          />
        </Routes>
      </Container>
    </div>
  );
};

export default PatientDobConfirm;

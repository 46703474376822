import { Fragment, useEffect, useReducer, useRef } from "react";
import "./Procedures.css";
import "react-datepicker/dist/react-datepicker.css";
import { loadSurgeons } from "../../../actions/SurgeonActions";
import {
  EDIT,
  getStartCase,
  isAdministratorUser,
  isAoaUser,
  isEmptyOrNull,
  isFollowUpUser,
  isHospitalAdministratorUser,
  isHospitalStudyCoordinatorUser,
  isSahmriUser,
  isSurgeonUser,
  isUserInAdminGroup,
  LAUNCH_DATE,
  UNKNOWN_SURGEON,
  VIEW,
} from "../../../Utils";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import Select from "react-select";
import {
  getProcedures,
  getRegistryProcedures,
  updateProcedureDetails,
} from "../../../api/Patient";
import { getLinkedStudies } from "../../../api/Study";
import { deleteProcedure } from "../../../api/Procedures";
import Moment from "moment";
import { addNeverMatch, unmatch } from "../../../api/Matching";
import AddAnotherProcedureModal from "./AddAnotherProcedureModal";
import ProcedureStudyWithdrawalConfirmationModal from "./ProcedureStudyWithdrawalConfirmationModal";
import ConfirmPastSchdProcDateModal from "./ConfirmPastSchdProcDateModal";
import {
  finishLoading,
  loadHospitals,
  loadProcedureTypes,
  loadSides,
  loadStudies,
  startLoading,
} from "../../../actions/CommonActions";
import SimpleSideSelector from "../../common/SimpleSideSelector";
import PatientProcedureSelector from "../../common/PatientProcedureSelector";
import DatePickerIosInput from "../../common/DatePickerIosInput";
import DatePicker from "react-datepicker";
import { getDataQueryFlags } from "../../data-query/Utils";
import CreateDataQuery, { TYPE } from "../../data-query/CreateDataQuery";
import ViewDataQuery from "../../data-query/ViewDataQuery";
import ButtonBar from "../../common/ButtonBar";
import { apiErrorResponse } from "../../matching/Utils";
import SimpleProcedureTypeSelector from "../../common/SimpleProcedureTypeSelector";
import { useOnUpdate, usePrevious } from "../../CustomHooks";

const dateFormat = "YYYY-MM-DD";
const dateFormatTwo = "DD/MM/YYYY";
// react-datepicker doesn't use moment.js (anymore) for date parsing (it uses dd for days)
const dateFormatThree = "dd/MM/yyyy";

const Procedures = ({
  studyWithdrawalReasonOptions = [
    { id: 1, name: "Ineligible" },
    { id: 2, name: "Hospital Changed" },
    { id: 3, name: "Surgeon Changed" },
    { id: 5, name: "Procedure Type Changed" },
    { id: 6, name: "Other (specify)" },
  ],
  onConfirm,
  onExit,
  patientId,
  operation,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const surgeons = useSelector((state) => state.surgeons);
  const hospitals = useSelector((state) => state.hospitals);
  const studies = useSelector((state) => state.studies);
  const procedureTypes = useSelector((state) => state.procedureTypes);
  const sides = useSelector((state) => state.sides);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      hospital: null,
      surgeon: null,
      procedureFilter: null,
      procedureType: null,
      side: null,
      optOutDate: null,
      optOutReason: "",
      noFollowUpDate: null,
      noFollowUpReason: "",
      isHospitalSelected: false,
      validation: "",
      loadProcedureError: "",
      saveProcedureError: "",
      currentlySubmitting: "",
      procedureId: "",
      procedures: [],
      selectedStudies: [],
      procedureStudies: [],
      allStudies: [],
      deleteActive: false,
      deleteConfirmation: "",
      registryProcedures: [],
      registryProcedureFilter: null,
      registryProcedureSide: "",
      registryProcedureSurgeonName: "",
      registryProcedureHospital: "",
      registryProcedureProcedureType: "",
      registryProcedure: null,
      registryProcedureSurgeonId: null,
      registryProcedureProcedureTypeId: null,
      registryProcedureSideId: null,
      registryProcedureHospitalId: null,
      registryProcedureDate: null,
      revision: null,
      dateOfDeath: null,
      registryProcedureSourceId: null,
      unMatchSuccessful: false,
      unMatchErrorMessage: null,
      unMatchSuccessMessage: null,
      registeredByName: null,
      registeredByEmail: null,
      showNewProcedureDialog: false,
      dataQueries: [],
      viewDataQueryId: null,
      createDataQuery: null,
      edited: false,
      scheduledProcedureDateValid: true,
      pendingWithdrawal: null,
      showWithdrawStudyConfirmationModal: false,
      selectedStudiesCandidates: null, // used to cache the new selected studies list pending removal confirmation
      generatedStudyChangeReasonId: null,
      haveSetSpdToThePast: false,
      originalSpd: null,
    },
  );

  useEffect(() => {
    surgeons.length === 0 && dispatch(loadSurgeons());
    hospitals.length === 0 && dispatch(loadHospitals());
    sides.length === 0 && dispatch(loadSides());
    procedureTypes.length === 0 && dispatch(loadProcedureTypes());
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useEffect(() => {
    if (hospitals.length === 1) {
      setHospital(hospitals[0]);
    }
    studies.forEach((study) =>
      state.allStudies.push({
        name: study.name,
        id: study.id,
      }),
    );
    loadData();
  }, [procedureTypes, surgeons]);

  // Re-render whenever the registryProcedures state has changed
  useEffect(() => {}, [state.registryProcedures]);

  const prevSelectedProcedureId = usePrevious(state.procedureId);
  useEffect(() => {
    setRegistryDetails(
      state.registryProcedures.filter(
        (registryProcedure) =>
          registryProcedure.sourceId ===
          state.procedureFilter.registryProcedureSourceId,
      )[0],
    );
    // Only update the available studies when the selected procedure has changed
    // Otherwise it will update when the selected studies have changed
    if (prevSelectedProcedureId !== state.procedureId) {
      updateAvailableStudies();
    }
  }, [state.procedureFilter]);

  useEffect(() => {
    updateAvailableStudies();
  }, [state.procedureType, state.surgeon, state.hospital]);

  useOnUpdate(() => {
    if (state.edited) {
      let newSurgeon = null;
      if (
        getFilteredSurgeons().length === 1 ||
        (hospitals.some((h) => !h.directLink) &&
          getFilteredSurgeons().length > 0)
      ) {
        newSurgeon = getFilteredSurgeons()[0];
      } else if (
        state.surgeon != null &&
        hasHospital(state.surgeon, state.hospital.id)
      ) {
        newSurgeon = state.surgeon;
      }
      setSurgeon(newSurgeon);
    }
  }, [state.hospital]);

  const loadData = () => {
    getProcedures(patientId)
      .then((response) => {
        let initialSelection =
          response.data.length > 0 ? response.data[0] : null;
        setState({ procedures: response.data });
        setProceduresFilter(initialSelection);
      })
      .catch((error) => {
        if (error.response && isEmptyOrNull(error.response)) {
          setState({ loadProcedureError: "Error loading procedures" });
        } else {
          setState({
            loadProcedureError: `Error loading procedures. ${error.response}`,
          });
        }
      });
    if (isUserInAdminGroup(user)) {
      getRegistryProcedures(patientId)
        .then((response) => {
          setState({ registryProcedures: response.data });
          setRegistryProceduresFilter(response.data);
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response)) {
            setState({
              loadProcedureError: "Error loading registry procedures",
            });
          } else {
            setState({
              loadProcedureError: `Error loading registry procedures. ${error.response}`,
            });
          }
        });
    }
  };

  const setProcedureType = (value) => {
    if (value !== null) {
      setState({
        procedureType: value,
        edited: true,
        generatedStudyChangeReasonId: !state.generatedStudyChangeReasonId
          ? 5
          : state.generatedStudyChangeReasonId,
        procedureFilter: isEmptyOrNull(state.procedureFilter)
          ? state.procedureFilter
          : {
              ...state.procedureFilter,
              withdrawnStudyHistory:
                state.procedureFilter.withdrawnStudyHistory == null
                  ? []
                  : state.procedureFilter.withdrawnStudyHistory.filter(
                      (ws) => ws.id > 0,
                    ),
            },
      });
    }
  };

  const setSide = (value) => {
    if (value !== null) {
      setState({
        side: value,
        edited: true,
      });
    }
  };

  const setHospital = (value) => {
    if (state.hospital == null || value.id !== state.hospital.id) {
      setState({
        hospital: value,
        edited: true,
        generatedStudyChangeReasonId: !state.generatedStudyChangeReasonId
          ? 2
          : state.generatedStudyChangeReasonId,
        procedureFilter:
          state.procedureFilter == null
            ? null
            : {
                ...state.procedureFilter,
                withdrawnStudyHistory:
                  state.procedureFilter.withdrawnStudyHistory == null
                    ? []
                    : state.procedureFilter.withdrawnStudyHistory.filter(
                        (ws) => ws.id > 0,
                      ),
              },
      });
    }
  };

  const hasHospital = (surgeon, hospitalId) => {
    if (!isEmptyOrNull(surgeon.hospitalStudies)) {
      return surgeon.hospitalStudies
        .map((hs) => hs.hospitalId)
        .includes(hospitalId);
    } else {
      return false;
    }
  };

  /**
   * Deal with the selected study.  When deleting, make a note of the selected study withdrawal candidate.
   * and enable a post the confirmation modal.  When that returns it will use the withdrawal candidate.
   * @param newSelectedStudies the new set of selected studies to be persisted on procedure save
   */
  const selectStudy = (newSelectedStudies) => {
    if (
      !isEmptyOrNull(state.selectedStudies) &&
      state.selectedStudies.length > newSelectedStudies.length
    ) {
      // REMOVING A STUDY //
      // Only gets the first study that matches the check,
      // hence why only allow one study to be removed at a time
      let selectedStudyRemoval = state.selectedStudies.find(
        (ss) => !newSelectedStudies.includes(ss),
      );
      let studyToWithdraw = state.procedureStudies.find(
        (ss) => ss.id === selectedStudyRemoval.id,
      );
      if (!isEmptyOrNull(studyToWithdraw)) {
        setState({
          pendingWithdrawal: studyToWithdraw,
          showWithdrawStudyConfirmationModal: true, // show the confirmation modal
          selectedStudiesCandidates: newSelectedStudies,
        });
      } else {
        setState({
          selectedStudies: newSelectedStudies,
        });
      }
    } else if (state.procedureFilter != null && newSelectedStudies != null) {
      // ADDING A STUDY //
      // the compliment of both lists will be the added study
      let studyToAdd = newSelectedStudies.find(
        (s) => !state.selectedStudies.includes(s),
      );
      // create a new list re-adding the non-pending, and removing any pending if the study is being re-added
      let newWithdrawnStudyHistory =
        state.procedureFilter.withdrawnStudyHistory == null
          ? []
          : state.procedureFilter.withdrawnStudyHistory.filter(
              (sr) => sr.id > 0 || sr.studyId !== studyToAdd.id,
            );
      setState({
        selectedStudies: newSelectedStudies,
        procedureFilter: isEmptyOrNull(state.procedureFilter)
          ? state.procedureFilter
          : {
              ...state.procedureFilter,
              withdrawnStudyHistory: newWithdrawnStudyHistory,
            },
        edited: true,
      });
    }
  };

  const setSurgeon = (surgeon) => {
    if (surgeon == null || surgeon.id === UNKNOWN_SURGEON.id) {
      setState({
        surgeon: UNKNOWN_SURGEON,
        edited: true,
        generatedStudyChangeReasonId: !state.generatedStudyChangeReasonId
          ? 3
          : state.generatedStudyChangeReasonId,
        procedureFilter: isEmptyOrNull(state.procedureFilter)
          ? state.procedureFilter
          : {
              ...state.procedureFilter,
              withdrawnStudyHistory:
                state.procedureFilter.withdrawnStudyHistory == null
                  ? []
                  : state.procedureFilter.withdrawnStudyHistory.filter(
                      (ws) => ws.id > 0,
                    ),
            },
      });
    }
    if (surgeon && surgeon.id !== UNKNOWN_SURGEON.id) {
      setState({
        surgeon: surgeon,
        edited: true,
        generatedStudyChangeReasonId: !state.generatedStudyChangeReasonId
          ? 3
          : state.generatedStudyChangeReasonId,
      });
    }
  };

  /**
   * Responsible for fetching the allowable studies for the procedure context (surgeon, hospital, procedure type)
   * Also keeps the list of pending removals coherent with what is in the selected studies list (awaiting save)
   */
  const updateAvailableStudies = () => {
    if (
      state.surgeon == null ||
      state.hospital == null ||
      state.procedureType == null
    ) {
      setState({ allStudies: [] });
    } else {
      let procedureTypeIds = [];
      procedureTypeIds.push(state.procedureType.id);
      getLinkedStudies(
        state.surgeon.id === UNKNOWN_SURGEON.id ? null : state.surgeon.id,
        state.hospital.id,
        procedureTypeIds,
        true,
        true,
      ).then((response) => {
        let allowedLinkedStudies = response.data;
        /** For an HSC role, filter the allowed studies to those linked to the HSC's hospital studies **/
        if (!isEmptyOrNull(user.hospitalStudies)) {
          allowedLinkedStudies = allowedLinkedStudies.filter((s) =>
            user.hospitalStudies.map((hs) => hs.studyId).includes(s.id),
          );
        }
        let stillAllowed = [];
        if (!isEmptyOrNull(state.procedureStudies)) {
          // The procedure is linked to studies
          // make a list of no-longer-allowed studies by taking the original procedure studies and filter out those that now ARE ALLOWED
          let noLongerAllowed = state.procedureStudies.filter(
            (ss) => !allowedLinkedStudies.map((als) => als.id).includes(ss.id),
          );
          // make a list of still-allowed studies by taking the original procedure studies and filter out those that are now NOT ALLOWED
          stillAllowed = state.procedureStudies.filter((ps) =>
            allowedLinkedStudies.map((als) => als.id).includes(ps.id),
          );
          if (!isAoaUser(user)) {
            // not AOA then don't allow enrolment in hiddenUntilPatientAgreed studies
            allowedLinkedStudies = allowedLinkedStudies.filter(
              (s) =>
                state.procedureStudies.map((ps) => ps.id).includes(s.id) ||
                !s.hiddenUntilPatientAgreed,
            );
          }
          if (state.generatedStudyChangeReasonId != null) {
            generateStudyWithdrawalHistory(
              studyWithdrawalReasonOptions[
                state.generatedStudyChangeReasonId - 1
              ], // index of reasonId
              noLongerAllowed,
              stillAllowed,
              state.generatedStudyChangeReasonId === 5
                ? "Procedure Type was not compatible with the study's protocol"
                : null,
            );
          }
        }
        setState({
          allStudies: allowedLinkedStudies,
          selectedStudies: stillAllowed,
          generatedStudyChangeReasonId: null,
        });
      });
    }
  };

  const setProceduresFilter = (procedure) => {
    if (procedure !== null) {
      let side = null;
      if (sides.filter((side) => side.id === procedure.sideId).length > 0) {
        side = sides.filter((side) => side.id === procedure.sideId)[0];
      }

      let procedureType = null;
      if (
        procedureTypes.filter(
          (procedureType) => procedureType.id === procedure.procedureTypeId,
        ).length > 0
      ) {
        procedureType = procedureTypes.filter(
          (procedureType) => procedureType.id === procedure.procedureTypeId,
        )[0];
      }

      let hospital = null;
      if (
        hospitals.filter((hospital) => hospital.id === procedure.hospitalId)
          .length > 0
      ) {
        hospital = hospitals.filter(
          (hospital) => hospital.id === procedure.hospitalId,
        )[0];
      }
      let selectedStudies = studies.filter(
        (study) =>
          !isEmptyOrNull(procedure.studyIds) &&
          procedure.studyIds.includes(study.id),
      );
      setState({
        procedureFilter: procedure,
        side: side,
        procedureType: procedureType,
        hospital: hospital,
        surgeon:
          procedure.surgeonId == null
            ? UNKNOWN_SURGEON
            : findMatchingSurgeon(procedure.surgeonId),
        procedureId: procedure.procedureId,
        selectedStudies: selectedStudies,
        registeredByName: procedure.registeredByName,
        registeredByEmail: procedure.registeredByEmail,
        optOutDate: procedure.optOutDate,
        optOutReason: procedure.optOutReason,
        noFollowUpDate: procedure.noFollowUpDate,
        noFollowUpReason: procedure.noFollowUpReason,
        dataQueries: procedure.dataQueries,
        procedureStudies: selectedStudies,
      });
    }
  };

  const buildDate = (value) => {
    let dateVal = Moment.utc(value, dateFormat, true);
    if (dateVal.isValid()) {
      return dateVal.toDate();
    } else {
      return null;
    }
  };

  const handleOptOutDateChange = (value) => {
    let dateVal = Moment(value, dateFormatTwo, true);
    setState({
      optOutDate:
        value == null ||
        !dateVal.isValid() ||
        dateVal.isBefore(LAUNCH_DATE) ||
        dateVal.isAfter(Moment().locale("en-au").add(1, "hour").toDate())
          ? null
          : dateVal.format(dateFormat),
      edited: true,
    });
  };

  const handleNoFollowUpDateChange = (value) => {
    let dateVal = Moment(value, dateFormatTwo, true);
    setState({
      noFollowUpDate:
        value == null ||
        !dateVal.isValid() ||
        dateVal.isBefore(LAUNCH_DATE) ||
        dateVal.isAfter(Moment().locale("en-au").add(1, "hour").toDate())
          ? null
          : dateVal.format(dateFormat),
      edited: true,
    });
  };

  const validateDate = (value) => {
    let now = Moment().add(1, "day");
    if (value !== null && Moment(value, dateFormat, true).isValid()) {
      return now.isAfter(Moment(value, dateFormat, true));
    } else {
      return false;
    }
  };

  const getRegistryProcedureSurgeonName = (registryProcedure) => {
    if (
      registryProcedure.surgeonFirstName !== null &&
      registryProcedure.surgeonLastName !== null
    )
      return `Dr. ${registryProcedure.surgeonLastName}, ${registryProcedure.surgeonFirstName}`;
    if (
      registryProcedure.surgeonFirstName !== null &&
      registryProcedure.surgeonLastName === null
    )
      return `Dr. ${registryProcedure.surgeonFirstName}`;
    if (
      registryProcedure.surgeonFirstName === null &&
      registryProcedure.surgeonLastName !== null
    )
      return `Dr. ${registryProcedure.surgeonLastName}`;
    return "Unknown";
  };

  const setRegistryProceduresFilter = (registryProcedures) => {
    if (registryProcedures !== null && registryProcedures !== "undefined") {
      setRegistryDetails(registryProcedures[0]);
    }
  };

  const setRegistryDetails = (registryProcedure) => {
    if (
      registryProcedure != null &&
      sides.length > 0 &&
      procedureTypes.length > 0 &&
      hospitals.length > 0
    ) {
      setState({
        registryProcedure: registryProcedure,
        registryProcedureSide:
          sides.length === 0
            ? null
            : getStartCase(
                sides.filter((side) => side.id === registryProcedure.side)[0]
                  .name,
              ),
        registryProcedureProcedureType:
          procedureTypes.length === 0
            ? null
            : getStartCase(
                procedureTypes.filter(
                  (procedureType) =>
                    procedureType.id === registryProcedure.procedureType,
                )[0].displayName,
              ),
        registryProcedureHospital:
          hospitals.length === 0
            ? null
            : renderHospitalOption(
                hospitals.filter(
                  (hospital) => hospital.id === registryProcedure.hospital,
                )[0],
              ),
        registryProcedureSurgeonName:
          getRegistryProcedureSurgeonName(registryProcedure),
        registryProcedureSurgeonId: registryProcedure.surgeonId,
        registryProcedureSideId: registryProcedure.side,
        registryProcedureHospitalId: registryProcedure.hospital,
        registryProcedureProcedureTypeId: registryProcedure.procedureType,
        registryProcedureDate: registryProcedure.procedureDate,
        registryProcedureSourceId: registryProcedure.sourceId,
        revision: registryProcedure.revision,
        dateOfDeath: registryProcedure.dateOfDeath,
      });
    } else {
      setState({
        registryProcedure: null,
        registryProcedureSideId: null,
        registryProcedureProcedureTypeId: null,
        registryProcedureHospitalId: null,
        registryProcedureSurgeonName: null,
        registryProcedureSide: null,
        registryProcedureProcedureType: null,
        registryProcedureSurgeonId: null,
        registryProcedureHospital: null,
        registryProcedureSourceId: null,
        registryProcedureDate: null,
        dateOfDeath: null,
        revision: null,
      });
    }
  };

  const toggleHiddenFromPatient = () => {
    if (!!state.procedureFilter) {
      setState({
        procedureFilter: {
          ...state.procedureFilter,
          hiddenFromPatient: !state.procedureFilter.hiddenFromPatient,
        },
      });
    }
  };

  const handleDelete = () => {
    if (state.deleteActive) {
      setState({ currentlySubmitting: true });
      deleteProcedure(state.procedureFilter.procedureId)
        .then((response) => {
          if (response && response.data && response.data.success) {
            onConfirm(
              "Procedure with id: " +
                state.procedureFilter.procedureId +
                " deleted successfully",
            );
          } else {
            let message = "Error deleting the procedure";
            if (response?.data?.message?.length > 1) {
              message = response.data.message;
            }
            setState({ saveProcedureError: message });
          }
        })
        .catch((error) => {
          if (isEmptyOrNull(error?.response?.data?.message)) {
            setState({ saveProcedureError: "Error deleting the procedure" });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    } else {
      setState({ deleteActive: true });
    }
  };

  const handleSubmit = () => {
    const payload = {
      hospitalId: state.hospital.id,
      studyIds: state.selectedStudies.map((study) => study.id),
      surgeonId: state.surgeon.id,
      sideId: state.side.id,
      procedureTypeId: state.procedureType.id,
      procedureId: state.procedureFilter.procedureId,
      scheduledProcedureDate: state.procedureFilter.scheduledProcedureDate,
      noFollowUpDate: state.noFollowUpDate,
      noFollowUpReason: state.noFollowUpReason,
      withdrawnStudyHistory: state.procedureFilter.withdrawnStudyHistory,
      optOutDate: state.optOutDate,
      optOutReason: state.optOutReason,
      hiddenFromPatient: state.procedureFilter.hiddenFromPatient,
    };
    if (operation === EDIT || isSahmriUser(user)) {
      updateProcedureDetails(patientId, payload)
        .then(() => {
          setState({
            isOpen: false,
            open: false,
            modalPatientId: patientId,
            showModal: false,
            withdrawnStudyHistory: null,
          });
          onConfirm("Procedure updated successfully");
        })
        .catch((error) => {
          if (isEmptyOrNull(error?.response?.data)) {
            setState({ saveProcedureError: "Error updating the procedure" });
          } else {
            setState({ saveProcedureError: error.response.data });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    }
  };

  const findMatchingSurgeon = (id) => {
    let filtered = surgeons.filter((surgeon) => surgeon.id === id);
    if (filtered.length === 1) {
      return filtered[0];
    } else {
      return null;
    }
  };

  const formInvalid = () => {
    if (operation === EDIT || isSahmriUser(user)) {
      const noFollowUpDateInvalid =
        !isEmptyOrNull(state.noFollowUpDate) &&
        !validateDate(state.noFollowUpDate);
      const optOutDateInvalid =
        !isEmptyOrNull(state.optOutDate) && !validateDate(state.optOutDate);
      return (
        noFollowUpDateInvalid ||
        optOutDateInvalid ||
        state.procedureType == null ||
        state.hospital == null ||
        state.side == null ||
        state.surgeon == null ||
        isEmptyOrNull(state.procedureFilter) ||
        isEmptyOrNull(state.allStudies) ||
        !state.scheduledProcedureDateValid
      );
    } else {
      return true;
    }
  };

  const getFilteredSurgeons = () => {
    let filtered = surgeons.slice();
    if (state.hospital != null) {
      filtered = filtered.filter((surgeon) =>
        hasHospital(surgeon, state.hospital.id),
      );
    }
    // Only permit the Unknown Surgeon option if the current user is not a surgeon and they are directly linked
    if (!isSurgeonUser(user)) {
      if (
        isHospitalAdministratorUser(user) ||
        isHospitalStudyCoordinatorUser(user)
      ) {
        if (state.hospital && state.hospital.directLink) {
          filtered.push(UNKNOWN_SURGEON);
        }
      } else if (operation === EDIT || isSahmriUser(user)) {
        filtered.push(UNKNOWN_SURGEON);
      }
    }
    return filtered;
  };

  const renderSurgeonName = (surgeon) => {
    if (surgeon.id !== UNKNOWN_SURGEON.id) {
      return `Dr ${surgeon.lastName}, ${surgeon.firstName}`;
    } else {
      return "Unknown";
    }
  };

  const getFilteredHospitals = () => {
    if (isSurgeonUser(user) && surgeons.length === 1) {
      return hospitals.filter((hospital) =>
        hasHospital(surgeons[0], hospital.id),
      );
    } else {
      return hospitals;
    }
  };

  const renderHospitalOption = (hospital) => {
    if (hospital == null) {
      return null;
    }
    return `${hospital.name} (${hospital.state.shortName})`;
  };

  const canAddNewProcedure = (user) => {
    return (
      isSurgeonUser(user) ||
      isHospitalAdministratorUser(user) ||
      isHospitalStudyCoordinatorUser(user)
    );
  };

  const handleUnmatch = () => {
    setState({ unMatchErrorMessage: null });
    dispatch(startLoading());
    unmatch(state.registryProcedureSourceId, state.procedureId)
      .then(() => {
        setState({
          isOpen: false,
          open: false,
          showModal: false,
        });
        onConfirm(
          "Registry Procedure " +
            state.registryProcedureSourceId +
            " has been unmatched successfully",
        );
      })
      .catch((error) => {
        if (!isEmptyOrNull(error?.response?.data)) {
          setState({
            unMatchErrorMessage: error.response.data.message,
          });
        } else {
          setState({
            unMatchErrorMessage: "Error while unmatching the procedure",
          });
        }
      })
      .finally(() => {
        dispatch(finishLoading());
      });
  };

  const handleAddNeverMatch = () => {
    dispatch(startLoading());
    addNeverMatch({
      sourceId: state.registryProcedureSourceId,
      procedureId: state.procedureId,
    })
      .then(() => {
        setState({
          isOpen: false,
          open: false,
          showModal: false,
        });
        onConfirm(
          "Registry Procedure " +
            state.registryProcedureSourceId +
            " and PROMs Procedure " +
            state.procedureId +
            " have been successfully added as a Never Match",
        );
      })
      .catch((error) => {
        let msg =
          "An error occurred while saving the never match. Please try again.";
        apiErrorResponse(error, msg);
      })
      .finally(() => dispatch(finishLoading()));
  };

  const getButtonRow = () => {
    const buttons = [];

    if (
      operation !== VIEW &&
      isEmptyOrNull(state.loadProcedureError) &&
      !isFollowUpUser(user)
    ) {
      buttons.push(
        <Button color="primary" disabled={formInvalid()} onClick={handleSubmit}>
          Save
        </Button>,
      );
    }

    if (
      isSahmriUser(user) &&
      isEmptyOrNull(state.loadProcedureError) &&
      !state.deleteActive
    ) {
      buttons.push(
        <Button color="primary" disabled={formInvalid()} onClick={handleSubmit}>
          Save
        </Button>,
      );
    }

    if (isSahmriUser(user)) {
      if (state.deleteActive) {
        buttons.push(
          <Fragment>
            <Input
              required
              autoFocus
              type="text"
              size={6}
              value={state.deleteConfirmation}
              valid={
                state.deleteConfirmation ===
                "DELETE " + state.procedureFilter.procedureId
              }
              onChange={(event) =>
                setState({ deleteConfirmation: event.target.value })
              }
            />
            <FormText>
              {"Type 'DELETE " +
                state.procedureFilter.procedureId +
                "' into the above box to confirm the deletion of this procedure"}
            </FormText>
          </Fragment>,
        );
      }
      buttons.push(
        <Button
          color={"danger"}
          onClick={handleDelete}
          disabled={
            state.procedures.length <= 1 ||
            (state.deleteActive &&
              state.deleteConfirmation !==
                `DELETE ${state.procedureFilter.procedureId}`) ||
            state.currentlySubmitting
          }
          title={
            state.procedures.length <= 1
              ? "Cannot delete the only procedure for a patient"
              : "Delete procedure"
          }
        >
          Delete
        </Button>,
      );
    }

    if (canAddNewProcedure(user)) {
      buttons.push(
        <Button
          color="primary"
          onClick={() => setState({ showNewProcedureDialog: true })}
        >
          Add Another Procedure
        </Button>,
      );
    }

    buttons.push(<Button onClick={onExit}>Cancel</Button>);
    return <ButtonBar buttons={buttons} />;
  };

  const getHospitalSelector = () => {
    return (
      <Select
        options={getFilteredHospitals()}
        onChange={setHospital}
        isClearable={false}
        isSearchable={true}
        getOptionLabel={(option) => renderHospitalOption(option)}
        getOptionValue={(option) => option.id}
        placeholder={"Select..."}
        value={state.hospital}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
        isDisabled={
          (getFilteredHospitals().length === 1 && state.hospital != null) ||
          operation === VIEW
        }
      />
    );
  };

  const canSeeNumberOfResponses = () => {
    return isSahmriUser(user) || isAdministratorUser(user);
  };

  const canSeeMatchingInfo = () => {
    return isSahmriUser(user) || isAdministratorUser(user) || isAoaUser(user);
  };

  const canSeeHiddenFromPatientCheckbox = () => {
    return isSahmriUser(user) || isAdministratorUser(user) || isAoaUser(user);
  };

  const getPatientProcedureSelectorRenderer = (procedure) => {
    if (canSeeNumberOfResponses()) {
      return (
        getStartCase(procedure.procedureName) +
        ` (ID:${procedure.procedureId} - Responses: ${procedure.procedureCollectionsSize})`
      );
    } else {
      return (
        getStartCase(procedure.procedureName) + ` (ID:${procedure.procedureId})`
      );
    }
  };

  const getDataQueryFlagColumn = (fieldName, fieldValue) => {
    return getDataQueryFlags(
      state.dataQueries,
      fieldName,
      fieldValue,
      user,
      state.edited,
      (id) => setState({ viewDataQueryId: id }),
      (fieldName, fieldValue) =>
        setState({
          createDataQuery: {
            fieldName: fieldName,
            fieldValue: fieldValue,
          },
        }),
      "text-end",
    );
  };

  const scheduledProcedureDateChanged = (value) => {
    // Have come from picked date thus assumes value is null or valid date in correct range //
    let todayStart = Moment().startOf("day");
    let haveSetSpdToThePast =
      Moment(value, dateFormatTwo, true).format(dateFormat) !==
        state.procedureFilter.scheduledProcedureDate &&
      Moment(value).isBefore(todayStart);
    setState({
      haveSetSpdToThePast: haveSetSpdToThePast,
      originalSpd: state.procedureFilter.scheduledProcedureDate,
      procedureFilter: {
        ...state.procedureFilter,
        scheduledProcedureDate:
          value == null
            ? null
            : Moment(value, dateFormatTwo, true).format(dateFormat),
      },
      scheduledProcedureDateValid: true,
    });
  };

  const isScheduledProcedureDateInValidRange = (date) => {
    // assumes: date is valid and not null //
    let oneYearHence = Moment().add(1, "year").endOf("day");
    let oneYearPrior = Moment().subtract(1, "year").startOf("day");
    let rangeOK = !Moment(date, dateFormatTwo, true).isAfter(oneYearHence);
    rangeOK =
      rangeOK && !Moment(date, dateFormatTwo, true).isBefore(oneYearPrior);
    return rangeOK;
  };

  const handleChangeRawScheduledProcedureDate = (date) => {
    if (isEmptyOrNull(date)) {
      setState({ scheduledProcedureDateValid: true });
      return;
    }
    let spdValid = false;
    if (Moment.utc(date, dateFormatTwo, true).isValid()) {
      spdValid = isScheduledProcedureDateInValidRange(date);
      if (spdValid) {
        scheduledProcedureDateChanged(date);
        return;
      }
    }
    setState({
      scheduledProcedureDateValid: false,
      haveSetSpdToThePast: false,
    });
  };

  const confirmSpdInThePast = (confirm) => {
    if (confirm) {
      setState({ haveSetSpdToThePast: false });
    } else {
      setState({
        haveSetSpdToThePast: false,
        procedureFilter: {
          ...state.procedureFilter,
          scheduledProcedureDate: state.originalSpd,
        },
        scheduledProcedureDateValid: true,
      });
    }
  };

  /**
   * called by ProcedureStudyWithdrawalConfirmationModal
   * @param reason
   * @param otherReasonText
   */
  const confirmStudyRemovalCallback = (reason, otherReasonText) => {
    if (state.procedureFilter != null) {
      let newRemoval = {
        id: 0,
        studyId: state.pendingWithdrawal.id,
        withdrawnBy: user.email,
        reasonId: reason.id,
        dateWithdrawn: null,
        otherReasonText: otherReasonText,
      };

      setState({
        pendingWithdrawal: null, // wipe candidate as removal now confirmed
        showWithdrawStudyConfirmationModal: false,
        edited: true,
        selectedStudies: state.selectedStudiesCandidates,
        selectedStudiesCandidates: null,
        procedureFilter: {
          ...state.procedureFilter,
          withdrawnStudyHistory: isEmptyOrNull(
            state.procedureFilter.withdrawnStudyHistory,
          )
            ? [newRemoval]
            : [...state.procedureFilter.withdrawnStudyHistory, newRemoval],
        },
      });
    }
  };

  /**
   * When a procedure type change stages existing linked studies for removal there may be more than one to ejected
   * @param reason
   * @param pendingWithdrawals the list of existing linked studies being ejected
   * @param selectedStudies
   * @param otherReasonText
   */
  const generateStudyWithdrawalHistory = (
    reason,
    pendingWithdrawals,
    selectedStudies,
    otherReasonText,
  ) => {
    let newRemovals = [];
    if (state.procedureFilter != null) {
      newRemovals = pendingWithdrawals.map((c) => {
        return {
          id: 0,
          studyId: c.id,
          withdrawnBy: user.email,
          reasonId: reason.id,
          dateWithdrawn: null,
          otherReasonText: otherReasonText,
        };
      });

      /** Firstly collect together all the persisted and, existing pending, and new pending withdrawals removing any
       *  pending duplicates
       ***/
      let accumulatedHistory = [];
      // firstly get all those from the current list of persisted and pending for the procedure
      if (!isEmptyOrNull(state.procedureFilter.withdrawnStudyHistory)) {
        state.procedureFilter.withdrawnStudyHistory.forEach((h) =>
          accumulatedHistory.push(h),
        );
      }
      // then, for each new pending only add to collect if it is not already in there
      newRemovals.forEach((wp) => {
        if (
          !accumulatedHistory.find(
            (c) => c.id === 0 && c.studyId === wp.studyId,
          )
        )
          accumulatedHistory.push(wp);
      });
      accumulatedHistory = accumulatedHistory.filter(
        (c) =>
          c.id !== 0 || !selectedStudies.map((s) => s.id).includes(c.studyId),
      );
      setState({
        pendingWithdrawal: null, // wipe candidate as removal now confirmed
        showWithdrawStudyConfirmationModal: false,
        selectedStudiesCandidates: null,
        generatedStudyChangeReasonId: null,
        procedureFilter: isEmptyOrNull(state.procedureFilter)
          ? state.procedureFilter
          : {
              ...state.procedureFilter,
              withdrawnStudyHistory: accumulatedHistory,
            },
      });
    }
  };

  const getWithdrawnStudyHistoryTable = () => {
    if (
      state.procedureFilter == null ||
      state.procedureFilter.withdrawnStudyHistory == null
    ) {
      return null;
    }
    return (
      <Container>
        <Fragment>
          <Card>
            <CardBody>
              <CardTitle>Withdrawn Studies</CardTitle>
              <Table
                bordered
                striped
                responsive
                className={"withdrawn-studies-table"}
                size={"sm"}
              >
                <thead>
                  <tr>
                    <th>Study</th>
                    <th>Date</th>
                    <th>By</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {state.procedureFilter.withdrawnStudyHistory.map((sr) => {
                    let applyStrikeout =
                      state.procedureFilter.studyIds.includes(sr.studyId);
                    return (
                      <tr
                        key={sr.id}
                        className={
                          applyStrikeout && sr.id !== 0 ? "strikeout" : null
                        }
                      >
                        <td>
                          {studies.filter((s) => s.id === sr.studyId)[0].name}
                        </td>
                        <td className={sr.id === 0 ? "pending" : null}>
                          {sr.id === 0
                            ? "Pending"
                            : Moment(sr.dateWithdrawn).format("DD/MM/YYYY")}
                        </td>
                        <td>{sr.withdrawnBy}</td>
                        <td>
                          <span>
                            {sr.reasonId === 6
                              ? sr.otherReasonText
                              : studyWithdrawalReasonOptions.find(
                                  (o) => o.id === sr.reasonId,
                                ).name}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Fragment>
      </Container>
    );
  };

  let optOutDateDb =
    state.optOutDate !== null ? buildDate(state.optOutDate) : null;
  let noFollowUpDateDb =
    state.optOutDate !== null ? buildDate(state.noFollowUpDate) : null;
  let selectedNoFollowUpDate = isEmptyOrNull(state.noFollowUpDate)
    ? noFollowUpDateDb
    : buildDate(state.noFollowUpDate);
  let procHasShowScheduledProcedureDateStudies =
    !isEmptyOrNull(state.selectedStudies) &&
    state.selectedStudies.some((s) => s.showScheduledProcedureDate);
  let isEditableScheduledProcedureDate =
    procHasShowScheduledProcedureDateStudies &&
    (isAoaUser(user) ||
      isHospitalAdministratorUser(user) ||
      isHospitalStudyCoordinatorUser(user) ||
      isSurgeonUser(user)) &&
    state.procedureFilter != null &&
    state.procedureFilter.registryProcedureDate == null;
  let scheduledProcedureDateIsSet =
    procHasShowScheduledProcedureDateStudies &&
    state.procedureFilter != null &&
    state.procedureFilter.scheduledProcedureDate != null;

  return (
    <div className={"patient-procedures"}>
      <Row className={"my-3"}>
        <Col
          xs={12}
          sm={12}
          md={canSeeNumberOfResponses() ? 3 : 4}
          lg={3}
          className={"my-auto text-end"}
        >
          Procedure(s)
        </Col>
        <Col
          xs={12}
          sm={12}
          md={canSeeNumberOfResponses() ? 9 : 8}
          lg={canSeeNumberOfResponses() ? 7 : 5}
        >
          <PatientProcedureSelector
            placeholder={"Select...."}
            clearable={false}
            options={state.procedures}
            value={state.procedureFilter}
            onChange={setProceduresFilter}
            optionRenderer={(procedure) =>
              getPatientProcedureSelectorRenderer(procedure)
            }
          />
        </Col>
      </Row>
      <Row className={"my-3"}>
        <Col xs={6} sm={6} md={4} lg={3} className={"text-end"}>
          Registered Date
        </Col>
        <Col xs={6} sm={6} md={8} lg={3}>
          {state.procedureFilter == null
            ? null
            : Moment(state.procedureFilter.dateCreated).format(dateFormatTwo)}
        </Col>
        {isUserInAdminGroup(user) && (
          <Col xs={12} sm={12} md={12} lg={6}>
            Registered by{" "}
            {state.registeredByName != null &&
            state.registeredByEmail != null ? (
              <a href={"mailto:" + state.registeredByEmail}>
                {state.registeredByName}
              </a>
            ) : (
              /* We say Unknown instead of Self-Registered here because it could have no creator because it's part of a merge */
              <i>N/A</i>
            )}
          </Col>
        )}
      </Row>
      {procHasShowScheduledProcedureDateStudies && (
        <Row className={"my-3"}>
          <Col xs={6} sm={6} md={4} lg={3} className={"text-end"}>
            Scheduled Procedure Date
          </Col>
          {!isEditableScheduledProcedureDate && (
            <Col xs={6} sm={6} md={8} lg={3}>
              {!scheduledProcedureDateIsSet
                ? "N/A"
                : Moment(state.procedureFilter.scheduledProcedureDate).format(
                    dateFormatTwo,
                  )}
            </Col>
          )}
          {isEditableScheduledProcedureDate && (
            <Col xs={6} sm={6} md={8} lg={3}>
              <DatePicker
                selected={
                  !scheduledProcedureDateIsSet
                    ? null
                    : Moment(state.procedureFilter.scheduledProcedureDate)
                        .utc()
                        .toDate()
                }
                onChangeRaw={(event) =>
                  handleChangeRawScheduledProcedureDate(event.target.value)
                }
                onChange={(value) => scheduledProcedureDateChanged(value)}
                onBlur={(event) =>
                  handleChangeRawScheduledProcedureDate(event.target.value)
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dateFormat={dateFormatThree}
                strictParsing
                minDate={Moment().subtract(1, "year").toDate()}
                maxDate={Moment().add(1, "year").toDate()}
                dropdownMode="scroll"
                scrollableYearDropdown={true}
                yearDropdownItemNumber={120}
                popperPlacement={"bottom-start"}
                className={
                  !state.scheduledProcedureDateValid
                    ? "border-danger"
                    : "scheduled-procedure-date"
                }
                customInput={<DatePickerIosInput />}
              />
            </Col>
          )}
        </Row>
      )}
      <div>
        <em>Note: Fields marked with an * are required.</em>
        {canSeeMatchingInfo() && state.registryProcedureSourceId && (
          <Fragment>
            <br />
            <em>
              Fields in Blue/Red represent matching/non-matching information
              from the registry procedures
            </em>
          </Fragment>
        )}
      </div>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={2} lg={2} className={"my-auto text-end"}>
          Hospital*
        </Col>
        <Col xs={12} sm={12} md={5} lg={5}>
          {getHospitalSelector()}
        </Col>
        <Col xs={12} sm={12} md={5} lg={4} className={"my-auto"}>
          <span
            className={
              state.hospital != null &&
              state.registryProcedureHospitalId === state.hospital.id
                ? "registry-label"
                : "non-matching-label"
            }
          >
            {state.registryProcedureHospital}
          </span>
        </Col>
        {getDataQueryFlagColumn(
          "Hospital",
          state.hospital == null ? null : state.hospital.name,
        )}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={2} lg={2} className={"my-auto text-end"}>
          Surgeon
        </Col>
        <Col xs={12} sm={12} md={6} lg={5}>
          <Select
            options={getFilteredSurgeons()}
            onChange={setSurgeon}
            getOptionLabel={(option) => renderSurgeonName(option)}
            getOptionValue={(option) => option.id}
            isClearable={false}
            isSearchable={true}
            value={state.surgeon == null ? UNKNOWN_SURGEON : state.surgeon}
            styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            isDisabled={
              getFilteredSurgeons().length === 1 || operation === VIEW
            }
          />
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className={"my-auto"}>
          <span
            className={
              state.surgeon != null &&
              state.registryProcedureSurgeonId === state.surgeon.id
                ? "registry-label"
                : "non-matching-label"
            }
          >
            {state.registryProcedureSurgeonName}
          </span>
        </Col>
        {getDataQueryFlagColumn(
          "Surgeon",
          state.surgeon == null ? null : renderSurgeonName(state.surgeon),
        )}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={2} lg={2} className={"my-auto text-end"}>
          Procedure Type*
        </Col>
        <Col xs={12} sm={12} md={6} lg={3}>
          <SimpleProcedureTypeSelector
            value={state.procedureType}
            onChange={setProcedureType}
            clearable={false}
            disabled={operation === VIEW}
          />
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className={"my-auto"}>
          <span
            className={
              state.procedureType != null &&
              state.registryProcedureProcedureTypeId === state.procedureType.id
                ? "registry-label"
                : "non-matching-label"
            }
          >
            {state.registryProcedureProcedureType}
          </span>
        </Col>
        {getDataQueryFlagColumn(
          "Procedure Type",
          state.procedureType == null
            ? null
            : getStartCase(state.procedureType.displayName),
        )}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={2} lg={2} className={"my-auto text-end"}>
          Side*
        </Col>
        <Col xs={12} sm={12} md={6} lg={3}>
          <SimpleSideSelector
            value={state.side}
            onChange={setSide}
            clearable={false}
            searchable={true}
            disabled={operation === VIEW}
          />
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className={"my-auto"}>
          <span
            className={
              state.side != null &&
              state.registryProcedureSideId === state.side.id
                ? "registry-label"
                : "non-matching-label"
            }
          >
            {state.registryProcedureSide}
          </span>
        </Col>
        {getDataQueryFlagColumn(
          "Side",
          state.side == null ? null : getStartCase(state.side.name),
        )}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={2} lg={2} className={"my-auto text-end"}>
          Studies*
        </Col>
        <Col xs={12} sm={12} md={9} lg={9}>
          <Select
            id="studies"
            placeholder="Enrol in studies..."
            value={state.selectedStudies}
            options={state.allStudies}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(selected) =>
              selectStudy(selected == null ? [] : selected)
            }
            isMulti
            hideSelectedOptions
            stayOpen={true}
            isDisabled={operation === VIEW || !isAoaUser(user)}
            styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            isClearable={false} // only allow one study to be removed at a time
          />
        </Col>
        {getDataQueryFlagColumn(
          "Studies",
          state.selectedStudies.map((s) => s.name).join(", "),
        )}
      </Row>
      <Row className={"my-3"}>
        <Col xs={12} sm={12} md={6} lg={2} className={"my-auto text-end"}>
          Opt-Out Date
        </Col>
        <Col xs={12} sm={12} md={6} lg={2}>
          <DatePicker
            name="optOutDate"
            for="optOutDate"
            selected={optOutDateDb}
            onChange={(value) => handleOptOutDateChange(value)}
            peekNextMonth
            dateFormat={dateFormatThree}
            showMonthDropdown
            showYearDropdown
            minDate={Moment(LAUNCH_DATE, dateFormat).toDate()}
            maxDate={Moment().locale("en-au").add(1, "hour").toDate()}
            disabled={!isAoaUser(user)}
            dropdownMode="scroll"
            readOnly={operation === VIEW}
            scrollableYearDropdown={true}
            yearDropdownItemNumber={120}
            popperPlacement={
              "top-start"
            } /* Leave top, to avoid overlap with small screen keyboards */
            style={{ align: "left" }}
            className={"opt-out-date"}
            customInput={<DatePickerIosInput />}
          />
        </Col>
        {getDataQueryFlagColumn(
          "Opt-Out Date",
          state.optOutDate == null
            ? null
            : Moment(state.optOutDate).format(dateFormatTwo),
        )}
        <Col xs={12} sm={12} md={6} lg={3} className={"my-auto text-end"}>
          No Follow-Up Date
        </Col>
        <Col xs={12} sm={12} md={6} lg={2}>
          <DatePicker
            name="noFollowUpDate"
            for="noFollowUpDate"
            selected={selectedNoFollowUpDate}
            onChange={(value) => handleNoFollowUpDateChange(value)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dateFormat={dateFormatThree}
            minDate={Moment(LAUNCH_DATE, dateFormat).toDate()}
            maxDate={Moment().locale("en-au").add(1, "hour").toDate()}
            disabled={!isAoaUser(user) && !isSahmriUser(user)}
            dropdownMode="scroll"
            readOnly={!isAoaUser(user) && !isSahmriUser(user)}
            scrollableYearDropdown={true}
            yearDropdownItemNumber={120}
            popperPlacement={
              "top-start"
            } /* Leave top, to avoid overlap with small screen keyboards */
            style={{ align: "left" }}
            className={"no-follow-up-date"}
            customInput={<DatePickerIosInput />}
          />
        </Col>
        {getDataQueryFlagColumn(
          "No Follow-Up Date",
          selectedNoFollowUpDate == null
            ? null
            : Moment(selectedNoFollowUpDate).format(dateFormatTwo),
        )}
      </Row>
      {(optOutDateDb != null || selectedNoFollowUpDate != null) && (
        <Row className={"my-3"}>
          {optOutDateDb == null && (
            <Fragment>
              <Col xs={12} sm={12} md={6} lg={2} />
              <Col xs={12} sm={12} md={6} lg={2} />
            </Fragment>
          )}
          {optOutDateDb != null && (
            <Fragment>
              <Col xs={12} sm={12} md={6} lg={2} className={"my-auto text-end"}>
                Reason
              </Col>
              <Col>
                <Input
                  type={"text"}
                  value={state.optOutReason}
                  disabled={!isAoaUser(user)}
                  onChange={(e) => setState({ optOutReason: e.target.value })}
                />
              </Col>
            </Fragment>
          )}
          {selectedNoFollowUpDate == null && (
            <Fragment>
              <Col xs={12} sm={12} md={6} lg={3} />
              <Col xs={12} sm={12} md={6} lg={2} />
            </Fragment>
          )}
          {selectedNoFollowUpDate != null && (
            <Fragment>
              <Col xs={12} sm={12} md={6} lg={3} className={"my-auto text-end"}>
                Reason
              </Col>
              <Col>
                <Input
                  type={"text"}
                  value={state.noFollowUpReason}
                  disabled={!isAoaUser(user) && !isSahmriUser(user)}
                  onChange={(e) =>
                    setState({ noFollowUpReason: e.target.value })
                  }
                />
              </Col>
            </Fragment>
          )}
        </Row>
      )}
      {canSeeHiddenFromPatientCheckbox() && (
        <Row className={"my-3"}>
          <Col lg={3} className={"my-auto offset-lg-2"}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  disabled={!isAoaUser(user)}
                  checked={
                    !!state.procedureFilter &&
                    state.procedureFilter.hiddenFromPatient
                  }
                  onChange={toggleHiddenFromPatient}
                />{" "}
                Hidden From Patient
              </Label>
            </FormGroup>
          </Col>
        </Row>
      )}
      {!state.scheduledProcedureDateValid && (
        <Row className={"my-3"}>
          <Col>
            <Alert color={"danger"}>
              Please use the DD/MM/YYYY format. Scheduled procedure date must be
              within one year either side of today's date.
            </Alert>
          </Col>
        </Row>
      )}
      {(isSahmriUser(user) || isAoaUser(user) || isAdministratorUser(user)) &&
        !isEmptyOrNull(state.procedureFilter) &&
        !isEmptyOrNull(state.procedureFilter.withdrawnStudyHistory) &&
        !isEmptyOrNull(state.procedureFilter.withdrawnStudyHistory) &&
        getWithdrawnStudyHistoryTable()}
      {state.registryProcedureSourceId != null && (
        <Container>
          <Card>
            <CardBody>
              <CardTitle>
                Matching - Source ID: {state.registryProcedureSourceId} -{" "}
                {state.revision == null
                  ? "N/A"
                  : state.revision
                    ? "Revision"
                    : "Primary"}{" "}
                Procedure
              </CardTitle>
              <Row>
                <Col xs={12} sm={6} md={6} lg={3}>
                  <Label for="procedureDate">Procedure Date</Label>
                </Col>
                <Col xs={12} sm={6} md={6} lg={3}>
                  <Label className={"registry-label"} for={"procedureDate"}>
                    {state.registryProcedureDate != null
                      ? Moment(state.registryProcedureDate).format(
                          dateFormatTwo,
                        )
                      : "N/A"}
                  </Label>
                </Col>
                <Col xs={12} sm={6} md={6} lg={3}>
                  <Label for="dateOfDeath">Date of Death</Label>
                </Col>
                <Col xs={12} sm={6} md={6} lg={3}>
                  <Label className={"registry-label"} for={"dateOfDeath"}>
                    {state.dateOfDeath != null
                      ? Moment(state.dateOfDeath).format(dateFormatTwo)
                      : "N/A"}
                  </Label>
                </Col>
                {isSahmriUser(user) && (
                  <Col xl={2}>
                    <Button
                      color={"primary"}
                      onClick={handleUnmatch}
                      className={"float-right"}
                    >
                      Unmatch
                    </Button>
                  </Col>
                )}
                {isSahmriUser(user) && (
                  <Col xl={2}>
                    <Button
                      color={"danger"}
                      onClick={handleAddNeverMatch}
                      className={"float-right"}
                    >
                      Never Match
                    </Button>
                  </Col>
                )}
              </Row>
              {state.unMatchErrorMessage && (
                <Alert style={{ marginTop: "1em" }} color={"danger"}>
                  {state.unMatchErrorMessage}
                </Alert>
              )}
            </CardBody>
          </Card>
        </Container>
      )}
      {state.selectedStudies.length === 0 && state.edited && (
        <Alert color={"danger"}>
          Saving these changes will leave the procedure with no linked studies!
        </Alert>
      )}
      {state.allStudies.length === 0 && state.surgeon != null && (
        <Alert color={"danger"}>
          Hospital & Surgeon Need to participate in a Common Study!
        </Alert>
      )}

      {!isEmptyOrNull(state.saveProcedureError) && (
        <Alert color={"danger"}>{state.saveProcedureError}</Alert>
      )}
      {getButtonRow()}
      {state.showNewProcedureDialog && (
        <AddAnotherProcedureModal
          patientId={patientId}
          open={state.showNewProcedureDialog}
          confirmCallback={onConfirm}
          exitCallback={() => setState({ showNewProcedureDialog: false })}
        />
      )}
      {state.createDataQuery != null && (
        <CreateDataQuery
          fieldName={state.createDataQuery.fieldName}
          fieldValue={state.createDataQuery.fieldValue}
          referencedId={state.procedureFilter.procedureId}
          type={TYPE.PROCEDURE}
          cancelCallback={() => setState({ createDataQuery: null })}
          createCallback={() => {
            setState({ createDataQuery: null });
            loadData(state.procedureFilter.procedureId);
          }}
        />
      )}
      {state.viewDataQueryId != null && (
        <ViewDataQuery
          dataQueryId={state.viewDataQueryId}
          cancelCallback={() => setState({ viewDataQueryId: null })}
          saveCallback={() => {
            setState({ viewDataQueryId: null });
            loadData(state.procedureFilter.procedureId);
          }}
        />
      )}
      <ProcedureStudyWithdrawalConfirmationModal
        showModal={state.showWithdrawStudyConfirmationModal}
        studyWithdrawalReasonOptions={studyWithdrawalReasonOptions}
        studyRemovalCandidate={
          state.pendingWithdrawal != null ? state.pendingWithdrawal.name : "N/A"
        }
        confirmCallback={confirmStudyRemovalCallback}
        exitCallback={() => {
          setState({ showWithdrawStudyConfirmationModal: false });
        }}
      />
      {!isEmptyOrNull(state.procedureFilter) &&
        !isEmptyOrNull(state.procedureFilter.scheduledProcedureDate) && (
          <ConfirmPastSchdProcDateModal
            showModal={state.haveSetSpdToThePast}
            confirmCallback={confirmSpdInThePast}
            dateToConfirm={state.procedureFilter.scheduledProcedureDate}
          />
        )}
    </div>
  );
};

Procedures.propTypes = {
  onConfirm: PropTypes.func,
  onExit: PropTypes.func,
  patientId: PropTypes.number.isRequired,
  operation: PropTypes.string.isRequired,
};

export default Procedures;

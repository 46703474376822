import PropTypes from "prop-types";
import { getCrfProcedureStudies, getCrfs, getOutstanding } from "../../api/Crf";
import {
  getStartCase,
  isAdministratorUser,
  isAoaUser,
  isEmptyOrNull,
  isSahmriUser,
  isStudyCoordinatorUser,
  isSurgeonUser,
} from "../../Utils";
import Moment from "moment";
import "./CrfPatientTab.css";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import Crf from "./Crf";
import { toast } from "react-toastify";
import {
  FaCheck,
  FaEdit,
  FaExclamationCircle,
  FaEye,
  FaPlusCircle,
  FaTrash,
} from "react-icons/fa";
import DeleteCrf from "./DeleteCrf";
import { hasCrfWriteAccess } from "./Utils";
import { useEffect, useReducer } from "react";
import { useOnUpdate } from "../CustomHooks";

const CrfPatientTab = ({ initialProcedureId = null, patientId = null }) => {
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      procedures: [],
      procedureFilter: null,
      studyFilter: null,
      showCrfModal: false,
      showDeleteCrfModal: false,
      existingCrfs: [],
      outstandingCrfTypes: [],
      selectedCrf: null,
      selectedType: null,
      isProcedureOptOut: false,
      hasOpenDataQuery: [],
      toolTipId: null,
    },
  );

  useEffect(() => {
    if (patientId != null) {
      fetchCrfProcedureStudies(patientId);
    }
  }, [patientId]);

  useOnUpdate(() => {
    if (state.procedures.length > 0) {
      if (initialProcedureId == null) {
        setProceduresFilter(state.procedures[0]);
      } else {
        let index = state.procedures.findIndex(
          (procedure) => procedure.procedureId === initialProcedureId,
        );
        if (index >= 0) {
          setProceduresFilter(state.procedures[index]);
        }
      }
    }
  }, [state.procedures]);

  useEffect(() => {
    setStudyFilter(
      getStudiesForProcedure(state.procedureFilter).length > 0
        ? getStudiesForProcedure(state.procedureFilter)[0]
        : null,
    );
  }, [state.procedureFilter, state.isProcedureOptOut]);

  useEffect(() => {
    fetchExistingCrfs();
    fetchOutstandingCrfTypes();
    fetchExistingCrfs();
    fetchOutstandingCrfTypes();
  }, [
    state.studyFilter,
    state.showCrfModal,
    state.selectedCrf,
    state.selectedType,
  ]);

  useEffect(() => {
    highlightAllCrfWithOpenDataQueries(state.existingCrfs);
  }, [state.existingCrfs]);

  const canShowCrfId = () => {
    return !isStudyCoordinatorUser(user);
  };

  const fetchCrfProcedureStudies = (patientId) => {
    getCrfProcedureStudies(patientId).then((response) => {
      setState({
        procedures: response.data,
      });
    });
  };

  const getPatientProcedureSelectorRenderer = (procedure) => {
    return (
      getStartCase(procedure.side) +
      " " +
      getStartCase(procedure.procedureType) +
      " (" +
      Moment(procedure.dateCreated).format("DD/MM/YYYY") +
      ")"
    );
  };

  const setProceduresFilter = (procedure) => {
    setState({
      procedureFilter: procedure,
      isProcedureOptOut: procedure.optOutDate !== null,
    });
  };

  const setStudyFilter = (study) => {
    setState({
      studyFilter: study,
    });
  };

  const highlightAllCrfWithOpenDataQueries = (existingCrfs) => {
    let hasOpenQuery = [];
    existingCrfs.map((crf, index) => {
      let hasOpenChildQuery = [];
      let anyOpenChildDataQueryPresent = false;
      hasOpenQuery[index] = false;
      let hasAnyOpenDataQuery = false;
      if (!isEmptyOrNull(crf.childCrfs)) {
        let hasAnyOpenChildDataQuery = false;
        crf.childCrfs.map((childCrf, idx) => {
          let openChildDataQueries = childCrf.dataQueries.filter(
            (query) => query.statusId !== 3,
          );
          hasAnyOpenChildDataQuery =
            !isEmptyOrNull(openChildDataQueries) &&
            openChildDataQueries.length >= 1;
          hasOpenChildQuery[idx] = hasAnyOpenChildDataQuery;
        });
        let shouldCountInOpen = hasOpenChildQuery.filter((val) => val === true);
        anyOpenChildDataQueryPresent =
          !isEmptyOrNull(shouldCountInOpen) && shouldCountInOpen.length >= 1;
      }
      if (!isEmptyOrNull(crf.dataQueries)) {
        let openDataQueries = crf.dataQueries.filter(
          (query) => query.statusId !== 3,
        );
        hasAnyOpenDataQuery =
          !isEmptyOrNull(openDataQueries) && openDataQueries.length >= 1;
      }
      hasOpenQuery[index] = hasAnyOpenDataQuery || anyOpenChildDataQueryPresent;
    });
    setState({
      hasOpenDataQuery: hasOpenQuery,
    });
  };

  const fetchExistingCrfs = () => {
    if (
      state.procedureFilter?.procedureId != null &&
      state.studyFilter != null &&
      state.studyFilter.studyId != null
    ) {
      getCrfs(
        state.procedureFilter.procedureId,
        state.studyFilter.studyId,
      ).then((response) => {
        setState({ existingCrfs: response.data });
      });
    } else {
      setState({ existingCrfs: [] });
    }
  };

  const fetchOutstandingCrfTypes = () => {
    if (!isSurgeonUser(user)) {
      if (
        state.procedureFilter?.procedureId != null &&
        state.studyFilter != null &&
        state.studyFilter.studyId != null
      ) {
        getOutstanding(
          state.procedureFilter.procedureId,
          state.studyFilter.studyId,
        ).then((response) => {
          setState({ outstandingCrfTypes: response.data });
        });
      } else {
        setState({ outstandingCrfTypes: [] });
      }
    }
  };

  const getStudiesForProcedure = (procedureFilter) => {
    if (procedureFilter == null || procedureFilter.studies == null) {
      return [];
    } else {
      return procedureFilter.studies;
    }
  };

  const crfSaved = (message) => {
    toast.success(
      <span>
        <FaCheck /> {message}
      </span>,
    );
    setState({ showCrfModal: false, selectedCrf: null, selectedType: null });
  };

  const crfDeleted = () => {
    setState({
      deleteCrfId: null,
      showDeleteCrfModal: false,
      selectedCrf: null,
    });
  };

  const getExistingActionButtons = (row, i) => {
    let buttons = [];
    let openCrf = state.hasOpenDataQuery[i] && (
      <span id={`p-open-data-query-span-${row.id}`}>
        <Button
          id={`c-open-data-query-span-${row.id}`}
          className={"action-button"}
          color={"#dc3545"}
          key={`open-data-query-span-${row.id}`}
        >
          <a id={`open-data-query-link-${row.id}`} href={"#"}>
            <FaExclamationCircle
              key={`icon-open-dq-${row.id}`}
              color={"#dc3545"}
            />
          </a>
        </Button>
        <Tooltip
          key={`tooltip-open-dq-${row.id}`}
          placement="bottom"
          isOpen={state.toolTipId === row.id}
          target={`open-data-query-link-${row.id}`}
          toggle={() =>
            setState({ toolTipId: state.toolTipId === row.id ? null : row.id })
          }
        >
          There is a data query against this CRF
        </Tooltip>
      </span>
    );
    if (hasCrfWriteAccess(user, row.completableRoles)) {
      buttons.push(
        <Button
          key={"edit-button-" + row.id}
          className={"action-button"}
          outline
          title={"Edit CRF"}
          onClick={() =>
            setState({
              selectedCrf: row,
              showCrfModal: true,
            })
          }
        >
          <FaEdit />
        </Button>,
      );
    }
    if (isAoaUser(user)) {
      buttons.push(
        <Button
          key={`delete-button-${row.id}`}
          color={"danger"}
          className={"action-button"}
          outline
          title={"Delete CRF"}
          onClick={() =>
            setState({ selectedCrf: row, showDeleteCrfModal: true })
          }
        >
          <FaTrash />
        </Button>,
      );
    }
    if (isSahmriUser(user) || isAdministratorUser(user)) {
      buttons.push(
        <Button
          key={`view-button-${row.id}`}
          className={"action-button"}
          outline
          title={"View CRF"}
          onClick={() =>
            setState({
              selectedCrf: row,
              showCrfModal: true,
            })
          }
        >
          <FaEye />
        </Button>,
      );
    }
    buttons.push(openCrf);
    return buttons;
  };

  const getOutstandingActionButtons = (row) => {
    let buttons = [];
    if (hasCrfWriteAccess(user, row.completableRoles)) {
      buttons.push(
        <Button
          key={`create-button-${row.id}`}
          className={"action-button"}
          outline
          title={"Create CRF"}
          onClick={() => setState({ showCrfModal: true, selectedType: row })}
        >
          <FaPlusCircle />
        </Button>,
      );
    }
    return buttons;
  };

  const getCompletableRoles = () => {
    if (!isEmptyOrNull(state.selectedType)) {
      return state.selectedType.completableRoles;
    }
    if (!isEmptyOrNull(state.selectedCrf)) {
      return state.selectedCrf.completableRoles;
    }
    return [];
  };

  return (
    <div className={"crf-patient-tab"}>
      <Row className={"first-row"}>
        <Col xs={2} className={"align-self-center"}>
          Procedure
        </Col>
        <Col xs={5}>
          <Select
            options={state.procedures}
            onChange={setProceduresFilter}
            isClearable={false}
            isSearchable={false}
            getOptionLabel={(option) =>
              getPatientProcedureSelectorRenderer(option)
            }
            getOptionValue={(option) => option.procedureId}
            value={state.procedureFilter}
            styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            isDisabled={state.procedures.length <= 1}
          />
        </Col>
        <Col xs={1} className={"align-self-center"}>
          Study
        </Col>
        <Col xs={4}>
          {/* Not using SimpleStudySelector as we don't want all studies shown - only those retrieved via the DTO */}
          <Select
            options={getStudiesForProcedure(state.procedureFilter)}
            onChange={setStudyFilter}
            isClearable={false}
            isSearchable={false}
            getOptionLabel={(option) => option.studyName}
            getOptionValue={(option) => option.studyId}
            value={state.studyFilter}
            styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            isDisabled={
              getStudiesForProcedure(state.procedureFilter) == null ||
              getStudiesForProcedure(state.procedureFilter).length <= 1
            }
          />
        </Col>
      </Row>
      {state.studyFilter && state.studyFilter.dateConsented == null && (
        <Alert color={"primary"}>
          No CRFs can be created until the patient has consented to this study
        </Alert>
      )}
      {state.isProcedureOptOut && (
        <Alert color={"primary"}>
          No new CRFs can be created for this procedure because the patient has
          opted out.
        </Alert>
      )}
      {hasCrfWriteAccess(user, []) && !isSurgeonUser(user) && (
        <Button
          color={"primary"}
          disabled={
            state.procedureFilter == null ||
            state.studyFilter == null ||
            state.studyFilter.dateConsented == null ||
            state.isProcedureOptOut
          }
          onClick={() => setState({ showCrfModal: true })}
        >
          Create New...
        </Button>
      )}
      <Card className={"existing-card"}>
        <CardBody>
          <CardTitle>Existing</CardTitle>
          <Table className={"existing-crfs-table"} bordered responsive striped>
            <thead>
              <tr>
                {canShowCrfId() && <th>ID</th>}
                <th>Type</th>
                <th>Created By</th>
                <th>Created Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className={"table-body"}>
              {state.existingCrfs.map((row, i) => {
                return (
                  <tr key={`existing-crf-row-${row.id}`}>
                    {canShowCrfId() && <td>{row.id}</td>}
                    <td>{row.typeName}</td>
                    <td>{row.createdBy}</td>
                    <td>{Moment(row.dateCreated).format("DD/MM/YYYY")}</td>
                    <td>
                      {getExistingActionButtons(row, i).map((button) => button)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {state.outstandingCrfTypes && state.outstandingCrfTypes.length > 0 && (
        <Card className={"outstanding-card"}>
          <CardBody>
            <CardTitle>Outstanding</CardTitle>
            <Table bordered responsive striped>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Collection</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className={"table-body"}>
                {state.outstandingCrfTypes.map((row) => {
                  return (
                    <tr key={`outstanding-crf-row-${row.id}`}>
                      <td>{row.crfType.name}</td>
                      <td>{row.collection}</td>
                      <td>
                        {getOutstandingActionButtons(row.crfType).map(
                          (button) => button,
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      )}
      {/* These are all the modals that have this component as a parent */}
      {state.showCrfModal && (
        <Crf
          procedure={state.procedureFilter}
          study={state.studyFilter}
          existing={state.selectedCrf}
          preSelectedType={state.selectedType}
          onExit={() =>
            setState({
              showCrfModal: false,
              selectedCrf: null,
              selectedType: null,
            })
          }
          onConfirm={crfSaved}
          readOnly={!hasCrfWriteAccess(user, getCompletableRoles())}
        />
      )}
      {state.showDeleteCrfModal && (
        <DeleteCrf crf={state.selectedCrf} onExit={crfDeleted} />
      )}
    </div>
  );
};

CrfPatientTab.propTypes = {
  patientId: PropTypes.number.isRequired,
  initialProcedureId: PropTypes.number,
};

export default CrfPatientTab;

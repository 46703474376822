import PropTypes from "prop-types";
import { FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import "./CrfType.css";
import { getSortedChoices } from "../management/crf-type/Utils";
import CrfBaseQuestion from "./CrfBaseQuestion";

const CrfCheckboxQuestion = ({
  question = null,
  answers = [],
  onChange = () => null,
  disabled = true,
  showValidationErrors = false,
  dataQueries,
  crfId,
  crfProcId,
  crfType,
  index,
  onConfirm,
}) => {
  const isCheckboxChoiceSelected = (questionId, choiceId) => {
    const currentAnswers = answers.filter(
      (answer) => answer.questionId === questionId,
    );
    return (
      currentAnswers.filter(
        (currentAnswer) => currentAnswer.choiceId === choiceId,
      ).length === 1
    );
  };

  const isAnyCheckboxChoiceSelected = (question) => {
    if (question != null) {
      let index = 0;
      for (; index < question.choices.length; index++) {
        if (isCheckboxChoiceSelected(question.id, question.choices[index].id)) {
          return true;
        }
      }
    }
    return false;
  };

  const shouldRenderAsInvalid = (question, showValidationErrors) => {
    return (
      question?.required &&
      showValidationErrors &&
      !isAnyCheckboxChoiceSelected(question)
    );
  };

  const parseFieldValue = (answer, question) => {
    let selectedAnswers = "";
    let currentAnswerIds = [];
    answer.forEach((answer) => currentAnswerIds.push(answer.choiceId));
    let tempArr;
    tempArr = question.choices.filter(function (item) {
      return currentAnswerIds.includes(item.id);
    });
    for (let i = 0; i < tempArr.length; i++) {
      selectedAnswers = selectedAnswers.concat(tempArr[i].choiceText);
      if (i !== tempArr.length - 1) {
        selectedAnswers = selectedAnswers.concat(",");
      }
    }
    return selectedAnswers;
  };

  const crfComponent = () => {
    return (
      <FormGroup tag="fieldset">
        {getSortedChoices(question).map((choice, i) => {
          return (
            <Row
              key={`choice-row-${question.id}-${choice.id}`}
              className={"crf-question g-0"}
            >
              <FormGroup
                check
                key={`${index}-choices-${question.id}-${choice.id}`}
              >
                <Label
                  className={
                    shouldRenderAsInvalid(question, showValidationErrors)
                      ? "invalid"
                      : null
                  }
                  check
                >
                  <Input
                    type="checkbox"
                    name={
                      index +
                      "-choices-" +
                      question.id +
                      "-" +
                      choice.id +
                      "-" +
                      i
                    }
                    disabled={disabled}
                    onChange={() => onChange(question.id, choice.id)}
                    checked={isCheckboxChoiceSelected(question.id, choice.id)}
                  />
                  {" " + choice.choiceText}
                </Label>
              </FormGroup>
            </Row>
          );
        })}
        {shouldRenderAsInvalid(question, showValidationErrors) && (
          <FormFeedback style={{ display: "block" }}>
            This field is required
          </FormFeedback>
        )}
      </FormGroup>
    );
  };

  return (
    <CrfBaseQuestion
      question={question}
      onConfirm={onConfirm}
      answers={answers}
      index={index}
      onChange={onChange}
      crfProcId={crfProcId}
      dataQueries={dataQueries}
      crfType={crfType}
      crfId={crfId}
      parseFieldValue={parseFieldValue}
      crfComponent={crfComponent()}
    ></CrfBaseQuestion>
  );
};
CrfCheckboxQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  showValidationErrors: PropTypes.bool,
  dataQueries: PropTypes.arrayOf(PropTypes.object),
  crfId: PropTypes.number,
  onConfirm: PropTypes.func.isRequired,
  crfType: PropTypes.object,
};

export default CrfCheckboxQuestion;

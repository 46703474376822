import { Fragment } from "react";
import { Badge, Button, Row, Table, UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";
import "./FuzzyMatchingModal.css";
import Pagination from "react-js-pagination";
import { getStartCase, isEmptyOrNull } from "../../Utils";
import Moment from "moment";
import {
  FaCheck,
  FaExclamationCircle,
  FaSearch,
  FaTimes,
} from "react-icons/fa";

const FuzzyMatchingTable = ({
  data = [],
  itemsCountPerPage = 10,
  searchCallback = () => null,
  saveCallback = () => null,
  changePageCallback = () => null,
  neverMatchCallback = () => null,
  showSaveButton = true,
  showSearchButton = true,
  showNeverMatchButton = true,
  totalItemsCount = 0,
  activePage = 1,
  pageRangeDisplayed = 5,
}) => {
  const getCellClass = (data, key, valuesAreDates) => {
    if (valuesAreDates) {
      return Moment(data[key + "Registry"]).format("DD/MM/YYYY") ===
        Moment(data[key + "Proms"]).format("DD/MM/YYYY")
        ? null
        : "highlight-cell";
    } else if (
      isEmptyOrNull(data[key + "Registry"]) ||
      isEmptyOrNull(data[key + "Proms"])
    ) {
      return "highlight-cell";
    } else {
      return getStartCase(data[key + "Registry"]) ===
        getStartCase(data[key + "Proms"])
        ? null
        : "highlight-cell";
    }
  };

  const getActionButtons = (row, index) => {
    return (
      <Fragment>
        {showSaveButton && (
          <Button
            className={"action-button"}
            outline
            color={"primary"}
            title={"Confirm this match"}
            onClick={() => saveCallback(row.idRegistry, row.idProms)}
          >
            <FaCheck />
          </Button>
        )}
        {showSearchButton && (
          <Button
            className={"action-button"}
            outline
            color={"secondary"}
            title={"Search for another match"}
            onClick={() => searchCallback(row)}
          >
            <FaSearch />
          </Button>
        )}
        {showNeverMatchButton && (
          <Button
            className={"action-button"}
            outline
            color={"danger"}
            title={"Add these two procedures to never matches"}
            onClick={() => neverMatchCallback(row.sourceId, row.idProms)}
          >
            <FaTimes />
          </Button>
        )}
        {!isEmptyOrNull(row.maybeBilateral) && (
          <span>
            <FaExclamationCircle
              id={`bilateral-result-${index}`}
              className={"tooltip-icon"}
            />
            <UncontrolledTooltip
              placement="top"
              target={`bilateral-result-${index}`}
            >
              This patient may have a bilateral PROMs procedure - Procedure ID:{" "}
              {row.maybeBilateral}
            </UncontrolledTooltip>
          </span>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Row className={"fuzzy-matching-table"}>
        <Table size={"sm"} bordered responsive>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Middle Name(s)</th>
              <th>Last Name</th>
              <th>DOB</th>
              <th>Postcode</th>
              <th>Hospital</th>
              <th>Procedure Type</th>
              <th>Side</th>
              <th>Surgeon</th>
              <th>Source Id/Procedure Id</th>
              <th>Procedure/Registered Date</th>
              <th>Studies</th>
              <th>Score</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className={"table-body"}>
            {data.map((row, i) => {
              return (
                <Fragment key={`row-fragment-${i}`}>
                  <tr
                    key={`registry-row-${i}`}
                    className={i % 2 === 0 ? "stripe-row" : null}
                  >
                    <td className={getCellClass(row, "firstName")}>
                      {getStartCase(row.firstNameRegistry)}
                    </td>
                    <td className={getCellClass(row, "middleNames")}>
                      {getStartCase(row.middleNamesRegistry)}
                    </td>
                    <td className={getCellClass(row, "lastName")}>
                      {getStartCase(row.lastNameRegistry)}
                    </td>
                    <td className={getCellClass(row, "dateOfBirth", true)}>
                      {Moment(row.dateOfBirthRegistry).format("DD/MM/YYYY")}
                    </td>
                    <td className={getCellClass(row, "postcode")}>
                      {row.postcodeRegistry}
                    </td>
                    <td className={getCellClass(row, "hospital")}>
                      {row.hospitalRegistry}
                    </td>
                    <td className={getCellClass(row, "procedureType")}>
                      {getStartCase(row.procedureTypeRegistry)}
                    </td>
                    <td className={getCellClass(row, "side")}>
                      {getStartCase(row.sideRegistry)}
                    </td>
                    <td className={getCellClass(row, "surgeon")}>
                      {row.surgeonRegistry}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {row.sourceId}
                        {row.revision && (
                          <Badge color={"danger"}>Revision</Badge>
                        )}
                        {!row.revision && (
                          <Badge color={"primary"}>Primary</Badge>
                        )}
                      </div>
                    </td>
                    <td
                      className={
                        Moment(row.procedureDate).isBefore(
                          Moment(row.procedureRegistered),
                        )
                          ? "highlight-cell"
                          : null
                      }
                    >
                      {Moment(row.procedureDate).format("DD/MM/YYYY")}
                    </td>
                    <td rowSpan={2}>
                      {row.studies.map((study, studyIndex) => {
                        return (
                          <Badge
                            key={`study-badge-${studyIndex}`}
                            color={"primary"}
                            className={"me-2"}
                          >
                            {study}
                          </Badge>
                        );
                      })}
                    </td>
                    <td
                      rowSpan={2}
                      className={row.fuzzyScore < 80 ? "highlight-cell" : null}
                    >
                      {row.fuzzyScore}
                    </td>
                    <td rowSpan={2}>{getActionButtons(row, i)}</td>
                  </tr>
                  <tr
                    key={`proms-row-${i}`}
                    className={i % 2 === 0 ? "stripe-row" : null}
                  >
                    <td>{getStartCase(row.firstNameProms)}</td>
                    <td>{getStartCase(row.middleNamesProms)}</td>
                    <td>{getStartCase(row.lastNameProms)}</td>
                    <td>{Moment(row.dateOfBirthProms).format("DD/MM/YYYY")}</td>
                    <td>{row.postcodeProms}</td>
                    <td>{row.hospitalProms}</td>
                    <td>{getStartCase(row.procedureTypeProms)}</td>
                    <td>{getStartCase(row.sideProms)}</td>
                    <td>{row.surgeonProms}</td>
                    <td>{row.idProms}</td>
                    <td>
                      {Moment(row.procedureRegistered).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </Row>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Pagination
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={changePageCallback}
          hideDisabled
          innerClass={"pagination pagination-sm"}
          itemClass={"page-item"}
          linkClass={"page-link"}
        />
      </div>
    </Fragment>
  );
};

FuzzyMatchingTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  itemsCountPerPage: PropTypes.number,
  totalItemsCount: PropTypes.number,
  activePage: PropTypes.number,
  searchCallback: PropTypes.func,
  saveCallback: PropTypes.func,
  neverMatchCallback: PropTypes.func,
  changePageCallback: PropTypes.func,
  showSaveButton: PropTypes.bool,
  showSearchButton: PropTypes.bool,
  showNeverMatchButton: PropTypes.bool,
  pageRangeDisplayed: PropTypes.number,
};

export default FuzzyMatchingTable;

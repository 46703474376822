import { Fragment, useEffect, useReducer } from "react";
import { Alert, Button, Col, Input, Row, Table, Tooltip } from "reactstrap";
import "./CallList.css";
import {
  assignPatients,
  getAssigned,
  getAssignedToOthers,
  getUnassigned,
  unassignPatients,
} from "../../api/Reminder";
import {
  finishLoading,
  loadStudies,
  startLoading,
} from "../../actions/CommonActions";
import TabbedPatientModal from "../management/patient/TabbedPatientModal";
import { useDispatch, useSelector } from "react-redux";
import {
  FaCheck,
  FaEdit,
  FaPaperPlane,
  FaPhone,
  FaQuestionCircle,
  FaTasks,
  FaUserSecret,
} from "react-icons/fa";
import { EDIT, isEmptyOrNull } from "../../Utils";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import LogCall from "./LogCall";
import Moment from "moment";
import MultiStudySelector from "../common/MultiStudySelector";
import ImpersonationTokenRequest from "../management/patient/ImpersonationTokenRequest";
import classnames from "classnames";
import PatientSearchTextField from "../management/patient/PatientSearchTextField";
import GenericSelector from "../common/GenericSelector";

export const TYPE_ME = 1;
export const TYPE_OTHERS = 2;
export const TYPE_UNASSIGNED = 3;

const PhoneListTable = ({
  itemsCountPerPage = 10,
  pageRangeDisplayed = 5,
  type,
  countChangedCallback,
  dataChangedCallback,
  resultsVersion,
}) => {
  const dispatch = useDispatch();
  const studies = useSelector((state) => state.studies);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      list: [],
      selectedIds: [],
      modalEditPatientId: null,
      impersonatePatient: null,
      logPhoneCallPatient: null,
      totalItemsCount: 0,
      activePage: 1,
      toolTipId: null,
      selectedStudies: [],
      selectedCollection: null,
      disableAllButtons: false,
      textFilter: {
        value: null,
        isPostcode: false,
        isMaybePostcode: false,
        isDateOfBirth: false,
        isMaybeDateOfBirth: false,
        isName: false,
        isMaybeName: false,
        isPatientId: false,
        isMaybePatientId: false,
      },
    },
  );

  useEffect(() => {
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useEffect(() => {
    refreshList();
  }, [
    resultsVersion,
    state.activePage,
    state.selectedStudies,
    state.selectedCollection,
    studies,
  ]);

  useEffect(() => {
    countChangedCallback(state.totalItemsCount);
  }, [state.totalItemsCount]);

  const searchDisabled = () => {
    return (
      state.textFilter.isMaybeDateOfBirth ||
      state.textFilter.isMaybeName ||
      state.textFilter.isMaybePostcode ||
      state.textFilter.isMaybePatientId ||
      state.textFilter.isMaybePhoneNumber
    );
  };

  const refreshList = () => {
    setState({ selectedIds: [] });
    dispatch(startLoading());
    let payload = {
      page: state.activePage - 1,
      size: itemsCountPerPage,
      studyIds: state.selectedStudies.map((study) => study.id).join(),
      studyCollectionId:
        state.selectedCollection == null ? null : state.selectedCollection.id,
      dateOfBirth: state.textFilter.isDateOfBirth
        ? state.textFilter.value
        : null,
      postcode: state.textFilter.isPostcode ? state.textFilter.value : null,
      name: state.textFilter.isName ? state.textFilter.value : null,
      patientId: state.textFilter.isPatientId
        ? state.textFilter.value.trim().toLocaleLowerCase().split("id:")[1]
        : null,
      email: state.textFilter.isEmail ? state.textFilter.value : null,
      phoneNumber: state.textFilter.isPhoneNumber
        ? state.textFilter.value
        : null,
    };

    let endpoint = (payload) => null;
    if (type === TYPE_ME) {
      endpoint = getAssigned;
    } else if (type === TYPE_OTHERS) {
      endpoint = getAssignedToOthers;
    } else if (type === TYPE_UNASSIGNED) {
      endpoint = getUnassigned;
    }

    endpoint(payload)
      .then((response) => {
        // if the number of pages from the search is less than what we requested, reset the active page and try again
        if (
          response.data.totalElements > 0 &&
          response.data.totalPages < state.activePage
        ) {
          setState({ activePage: 1 });
        } else {
          setState({
            list: response.data.content,
            activePage: response.data.number + 1,
            totalItemsCount: response.data.totalElements,
          });
        }
      })
      .catch(() => setState({ list: [] }))
      .finally(() => {
        setState({ disableAllButtons: false });
        dispatch(finishLoading());
      });
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  const toggleSelect = (patientId) => {
    if (state.selectedIds.includes(patientId)) {
      let filtered = state.selectedIds.filter((id) => id !== patientId);
      setState({ selectedIds: filtered });
    } else {
      setState({ selectedIds: [...state.selectedIds, patientId] });
    }
  };

  const toggleSelectAll = () => {
    const patientIds = state.list
      .filter((patient) => filterPatient(patient))
      .map((patient) => patient.id);
    let selected = [];
    if (patientIds.length === state.selectedIds.length) {
      selected = [];
    } else {
      patientIds.forEach((id) => {
        selected.push(id);
      });
    }
    setState({ selectedIds: selected });
  };

  const areAllSelected = () => {
    const patientIds = state.list
      .filter((patient) => filterPatient(patient))
      .map((patient) => patient.id);
    let selected = state.selectedIds.slice();
    return patientIds.length === selected.length;
  };

  const handleModalSuccess = (message) => {
    if (!isEmptyOrNull(message)) {
      toast.success(
        <span>
          <FaCheck /> {message}
        </span>,
      );
      setState({ modalEditPatientId: null });
      refreshList();
    }
  };

  const handleImpersonatePatient = (patient) => {
    setState({ disableAllButtons: true, impersonatePatient: patient });
  };

  const handleLogonUrlCopied = () => {
    setState({ disableAllButtons: false, impersonatePatient: null });
    toast.success("Logon URL copied to clipboard");
  };

  const handlePhoneCallSaved = () => {
    setState({ disableAllButtons: true, logPhoneCallPatient: null });
    toast.success("Phone call logged successfully");
    refreshList();
  };

  const handleUnassign = (patientIds) => {
    setState({ disableAllButtons: true });
    dispatch(startLoading());
    unassignPatients(patientIds)
      .catch((error) => {
        if (error?.response?.data && !isEmptyOrNull(error.response.data)) {
          toast.error(error.response.data);
        }
        refreshList();
      })
      .finally(() => {
        dispatch(finishLoading());
        dataChangedCallback();
      });
  };

  const handleAssignPatient = (patientIds) => {
    setState({ disableAllButtons: true });
    dispatch(startLoading());
    assignPatients(patientIds)
      .catch((error) => {
        if (error?.response?.data && !isEmptyOrNull(error.response.data)) {
          toast.error(error.response.data);
        }
      })
      .finally(() => {
        dispatch(finishLoading());
        dataChangedCallback();
        setState({ disableAllButtons: true });
      });
  };

  const handleStudySelectionChanged = (values) => {
    setState({
      selectedStudies: values,
      // If we now have not one study selected now, clear the selected collection
      selectedCollection: values.length === 1 ? state.selectedCollection : null,
      disableAllButtons: true,
    });
  };

  const handleCollectionSelectionChanged = (value) => {
    setState({ selectedCollection: value });
  };

  const filterPatient = (row) => {
    // We don't need to do this kind of filtering for the others tab, because patients can't be re-assigned to the
    // current user without first passing through the unassigned tab (and they can be filtered out there)
    if (type === TYPE_OTHERS) {
      return true;
    }
    // Last-chance filtering of patient from table
    let patientStudyIds = row.available.procedures.flatMap((procedure) =>
      procedure.studyCollections.map(
        (studyCollections) => studyCollections.studyId,
      ),
    );
    // Patient passes the filter only if every collection that's outstanding is for a study that the current user can see
    return patientStudyIds.every((id) =>
      studies.map((study) => study.id).includes(id),
    );
  };

  const getActionButtons = (row) => {
    const disableActionButton =
      state.disableAllButtons || state.selectedIds.length > 0;

    if (type === TYPE_ME) {
      return (
        <Fragment>
          <Button
            outline
            className={"action-button"}
            title={"Edit Patient"}
            disabled={disableActionButton}
            onClick={() => setState({ modalEditPatientId: row.id })}
          >
            <FaEdit />
          </Button>
          {!!row.available.hasAvailableCollections && (
            <Button
              outline
              color={"primary"}
              className={"action-button"}
              title={"Impersonate Patient"}
              disabled={disableActionButton}
              onClick={() => handleImpersonatePatient(row)}
            >
              <FaUserSecret />
            </Button>
          )}
          <Button
            outline
            color={"secondary"}
            className={"action-button"}
            title={"Log Phone Call"}
            disabled={disableActionButton}
            onClick={() => setState({ logPhoneCallPatient: row })}
          >
            <FaPhone />
          </Button>
          <Button
            outline
            color={"primary"}
            className={"action-button"}
            title={"Return to Unassigned queue"}
            disabled={disableActionButton}
            onClick={() => handleUnassign([row.id])}
          >
            <FaTasks />
          </Button>
        </Fragment>
      );
    } else if (type === TYPE_OTHERS) {
      return (
        <Fragment>
          <Button
            outline
            className={"action-button"}
            title={"Edit Patient"}
            disabled={disableActionButton}
            onClick={() => setState({ modalEditPatientId: row.id })}
          >
            <FaEdit />
          </Button>
          <Button
            outline
            color={"primary"}
            className={"action-button"}
            title={"Return to Unassigned queue"}
            disabled={disableActionButton}
            onClick={() => handleUnassign([row.id])}
          >
            <FaTasks />
          </Button>
        </Fragment>
      );
    } else if (type === TYPE_UNASSIGNED) {
      return (
        <Fragment>
          <Button
            outline
            className={"action-button"}
            title={"Edit Patient"}
            disabled={disableActionButton}
            onClick={() => setState({ modalEditPatientId: row.id })}
          >
            <FaEdit />
          </Button>
          <Button
            outline
            color={"primary"}
            className={"action-button"}
            title={"Assign To Me"}
            disabled={disableActionButton}
            onClick={() => handleAssignPatient([row.id])}
          >
            <FaPaperPlane />
          </Button>
        </Fragment>
      );
    }
  };

  const getBulkActionButton = () => {
    if (type === TYPE_ME || type === TYPE_OTHERS) {
      return (
        <Button
          outline
          color={"primary"}
          title={"Bulk Return To Unassigned Queue"}
          disabled={state.disableAllButtons}
          onClick={() => handleUnassign(state.selectedIds)}
        >
          <FaTasks />
          {"   "} Return all selected patients to Unassigned queue
        </Button>
      );
    } else if (type === TYPE_UNASSIGNED) {
      return (
        <Button
          outline
          color={"primary"}
          title={"Bulk Assign To Me"}
          disabled={state.disableAllButtons}
          onClick={() => handleAssignPatient(state.selectedIds)}
        >
          <FaPaperPlane />
          {"   "} Assign all selected patients to me
        </Button>
      );
    }
  };

  const getStudyAndStudyCollectionSelectors = () => {
    if (type !== TYPE_UNASSIGNED) {
      return null;
    }

    if (state.selectedStudies.length === 1) {
      const selectedStudy = state.selectedStudies[0];
      return (
        <Row className={"my-3"}>
          <Col xs={2} className={"my-auto text-end"}>
            <b>Studies</b>
          </Col>
          <Col xs={4}>
            <MultiStudySelector
              values={state.selectedStudies}
              onChange={handleStudySelectionChanged}
            />
          </Col>
          <Col xs={2} className={"my-auto text-end"}>
            <b>Collection</b>
          </Col>
          <Col xs={4}>
            <GenericSelector
              options={selectedStudy.studyCollections}
              clearable={true}
              searchable={false}
              selected={state.selectedCollection}
              changeCallback={handleCollectionSelectionChanged}
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <Row className={"my-3"}>
          <Col xs={2} className={"my-auto text-end"}>
            <b>Studies</b>
          </Col>
          <Col xs={10}>
            <MultiStudySelector
              values={state.selectedStudies}
              onChange={handleStudySelectionChanged}
            />
          </Col>
        </Row>
      );
    }
  };

  return (
    <div className={"phone-list-table"}>
      {getStudyAndStudyCollectionSelectors()}
      <Row className={"my-3 align-items-end"}>
        <Col xs={10}>
          <PatientSearchTextField
            doSearchCallback={() => refreshList()}
            updateCallback={(value) => setState({ textFilter: value })}
          />
        </Col>
        <Col xs={2}>
          <Button
            color={"primary"}
            onClick={() => refreshList()}
            disabled={searchDisabled()}
          >
            Search
          </Button>
        </Col>
      </Row>
      {state.selectedIds.length > 0 && (
        <Alert color={"primary"}>
          <Row>
            <Col sm={2} className={"text-end my-auto"}>
              <b>Bulk Actions:</b>
            </Col>
            <Col>{getBulkActionButton()}</Col>
          </Row>
        </Alert>
      )}
      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>
              <Input
                type={"checkbox"}
                className={"select-checkbox"}
                title={"Select/De-select all patients on this page"}
                onChange={() => toggleSelectAll()}
                checked={areAllSelected()}
              />
            </th>
            <th>ID</th>
            <th>Name</th>
            {type === TYPE_ME && <th>Contact</th>}
            <th>Days Overdue</th>
            {type !== TYPE_OTHERS && (
              <Fragment>
                <th>Procedure(s)</th>
                <th>Collection(s)</th>
              </Fragment>
            )}
            {type === TYPE_OTHERS && (
              <Fragment>
                <th>Assigned To</th>
                <th>Date Assigned</th>
              </Fragment>
            )}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {state.list.map((row, index) => {
            if (!filterPatient(row)) {
              return null;
            }

            const studyCollections = [];
            if (type !== TYPE_OTHERS) {
              row.available.procedures.forEach((procedure) => {
                procedure.studyCollections.forEach((studyCollection) => {
                  if (
                    !studyCollections
                      .map((sc) => sc.studyCollectionId)
                      .includes(studyCollection.studyCollectionId)
                  ) {
                    studyCollections.push(studyCollection);
                  }
                });
              });
            }
            return (
              <tr
                key={index}
                className={classnames({
                  "selected-row": state.selectedIds.includes(row.id),
                })}
              >
                <td>
                  <Input
                    type={"checkbox"}
                    className={"select-checkbox"}
                    title={"Select/De-select this patient"}
                    onChange={() => toggleSelect(row.id)}
                    checked={state.selectedIds.includes(row.id)}
                  />
                </td>
                <td>{row.id}</td>
                <td>{row.name}</td>
                {type === TYPE_ME && (
                  <td>
                    {!isEmptyOrNull(row.mobilePhone) && (
                      <div>{row.mobilePhone}</div>
                    )}
                    {!isEmptyOrNull(row.homePhone) && (
                      <div>{row.homePhone}</div>
                    )}
                  </td>
                )}
                <td>{row.daysOverdue}</td>
                {type !== TYPE_OTHERS && (
                  <Fragment>
                    <td>
                      {row.available.procedures.length > 0 &&
                        row.available.hasAvailableCollections && (
                          <Fragment>
                            {row.available.procedures.map((procedure, i) => {
                              return (
                                <div
                                  key={
                                    "procedure-div-" + i + "-" + procedure.id
                                  }
                                >
                                  {procedure.name}
                                  <FaQuestionCircle
                                    id={"procedures-" + row.id + "-" + i}
                                    className={"tooltip-icon"}
                                  />
                                  <Tooltip
                                    placement={"right"}
                                    target={"procedures-" + row.id + "-" + i}
                                    isOpen={
                                      state.toolTipId ===
                                      "procedures-" + row.id + "-" + i
                                    }
                                    toggle={() =>
                                      setState({
                                        toolTipId:
                                          state.toolTipId ===
                                          "procedures-" + row.id + "-" + i
                                            ? null
                                            : "procedures-" + row.id + "-" + i,
                                      })
                                    }
                                  >
                                    {procedure.hospital}
                                  </Tooltip>
                                </div>
                              );
                            })}
                          </Fragment>
                        )}
                    </td>
                    <td>
                      {studyCollections.length > 0 &&
                        row.available.hasAvailableCollections && (
                          <Fragment>
                            {studyCollections.map((collection, i) => {
                              return (
                                <div key={"collection-div-" + i}>
                                  {collection.name}
                                </div>
                              );
                            })}
                          </Fragment>
                        )}
                    </td>
                  </Fragment>
                )}
                {type === TYPE_OTHERS && (
                  <Fragment>
                    <td>{row.assignedTo}</td>
                    <td>
                      <span
                        className={"hover-link"}
                        title={Moment(row.dateAssigned).format(
                          "DD/MM/YYYY @ h:mm A",
                        )}
                      >
                        {Moment(row.dateAssigned).format("DD/MM/YYYY")}
                      </span>
                    </td>
                  </Fragment>
                )}
                <td>{getActionButtons(row)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Pagination
          activePage={state.activePage}
          totalItemsCount={state.totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={changePage}
          hideDisabled
          innerClass={"pagination pagination-sm"}
          itemClass={"page-item"}
          linkClass={"page-link"}
        />
      </div>
      {state.modalEditPatientId != null && (
        <TabbedPatientModal
          open={true}
          modalOperation={EDIT}
          patientId={state.modalEditPatientId}
          onConfirm={(message) => handleModalSuccess(message)}
          onExit={() => setState({ modalEditPatientId: null })}
        />
      )}
      {state.logPhoneCallPatient != null && (
        <LogCall
          patient={state.logPhoneCallPatient}
          cancelCallback={() => setState({ logPhoneCallPatient: null })}
          saveCallback={handlePhoneCallSaved}
        />
      )}
      {state.impersonatePatient != null && (
        <ImpersonationTokenRequest
          patientId={state.impersonatePatient.id}
          patientName={state.impersonatePatient.name}
          cancelCallback={() =>
            setState({
              impersonatePatient: null,
              disableAllButtons: false,
            })
          }
          clipboardSaveCallback={handleLogonUrlCopied}
        />
      )}
    </div>
  );
};

PhoneListTable.propTypes = {
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  dataChangedCallback: PropTypes.func.isRequired,
  resultsVersion: PropTypes.number.isRequired,
  countChangedCallback: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
};

export default PhoneListTable;

import { combineReducers } from "redux";

import PatientDetailsReducer from "./PatientDetailsReducer";
import StatesReducer from "./StatesReducer";
import HospitalsReducer from "./HospitalsReducer";
import PatientDeleteReasonsReducer from "./PatientDeleteReasonsReducer";
import SurgeonsReducer from "./SurgeonsReducer";
import AppReducer from "./AppReducer";
import UserReducer from "./UserReducer";
import SurgeonLogonReducer from "./SurgeonLogonReducer";
import StudyReducer from "./StudyReducer";
import SidesReducer from "./SidesReducer";
import JointsReducer from "./JointsReducer";
import StakeholderGroupsReducer from "./StakeholderGroupsReducer";
import InstrumentReportReducer from "./InstrumentReportReducer";
import InstrumentManagementReducer from "./InstrumentManagementReducer";
import StudyManagementReducer from "./StudyManagementReducer";
import CrfTypeManagementReducer from "./CrfTypeManagementReducer";
import CommonReducer from "./CommonReducer";
import DataQueryReducer from "./DataQueryReducer";
import ProcedureTypesReducer from "./ProcedureTypesReducer";

const appReducer = combineReducers({
  patient: PatientDetailsReducer,
  states: StatesReducer,
  hospitals: HospitalsReducer,
  surgeons: SurgeonsReducer,
  app: AppReducer,
  user: UserReducer,
  sendMfaCode: UserReducer,
  sendSurgeonSms: SurgeonLogonReducer,
  studies: StudyReducer,
  sides: SidesReducer,
  procedureTypes: ProcedureTypesReducer,
  joints: JointsReducer,
  stakeholderGroups: StakeholderGroupsReducer,
  instrumentReports: InstrumentReportReducer,
  instrumentManagement: InstrumentManagementReducer,
  studyManagement: StudyManagementReducer,
  crfTypeManagement: CrfTypeManagementReducer,
  common: CommonReducer,
  dataQueries: DataQueryReducer,
  patientHardDeleteReasons: PatientDeleteReasonsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

import { Button, Col, Label, Row, Table } from "reactstrap";
import { FaLink, FaUnlink } from "react-icons/fa";
import "./Linking.css";
import PropTypes from "prop-types";
import StudySearch from "./Search";

const Linking = ({
  links = [],
  readOnly = true,
  onLinkChange,
  sourceObjectName,
}) => {
  const handleUnlink = (study) => {
    let removeId = study.id;
    let filtered = links.filter((study) => study.id !== removeId);
    onLinkChange(filtered);
  };

  const handleLink = (study) => {
    let newLinks = links.slice();
    newLinks.push(study);
    onLinkChange(newLinks);
  };

  return (
    <div>
      <Row>
        <Col sm={3}>
          <Label className={"section-text"}>Linked Studies</Label>
        </Col>
        <Col sm={9}>
          <Table striped>
            <tbody>
              {links.length > 0 ? (
                links.map((study) => (
                  <tr key={"study-key-" + study.id}>
                    <td className={"study-entry"}>
                      <span className={"study-text"}>{study.name}</span>
                    </td>
                    <td>
                      {!readOnly && (
                        <span className={"unlink-button"}>
                          <Button
                            className={"action-button"}
                            outline
                            title={
                              "Unlink " +
                              sourceObjectName.toLowerCase() +
                              " from study"
                            }
                            onClick={() => handleUnlink(study)}
                          >
                            <FaUnlink />
                          </Button>
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <em>No linked studies set</em>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {!readOnly && (
        <div>
          <hr />
          <Row>
            <Col sm={12}>
              <Label className={"section-text"}>Available Studies</Label>
            </Col>
          </Row>
          <StudySearch
            excludeIds={links.map(function (study) {
              return study.id;
            })}
            actionButtons={[
              {
                icon: <FaLink onClick={() => handleLink} />,
                title: "Link " + sourceObjectName.toLowerCase() + " to study",
                action: handleLink,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

Linking.propTypes = {
  onLinkChange: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  sourceObjectName: PropTypes.string.isRequired,
};

export default Linking;

import "./Main.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { loadUserCurrent } from "./actions/UserActions";
import "react-toastify/dist/ReactToastify.min.css";
import ReactGA from "react-ga4";
import { router } from "./components/app/Router";

// If using the production URL, inject our Google Analytics ID
if (window?.location?.hostname === "aoanjrrtrials.sahmri.com") {
  ReactGA.initialize("G-LDHZVELJ2P");
}

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    !user.found && dispatch(loadUserCurrent());
  }, []);

  return <RouterProvider router={router} />;
};

export default App;

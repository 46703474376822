import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import PropTypes from "prop-types";
import "./UnmatchedPromsTable.css";
import { addNeverMatch, saveFuzzyMatch } from "../../api/Matching";
import { toast } from "react-toastify";
import FuzzyMatchingTable from "./FuzzyMatchingTable";
import { getFuzzyMatches } from "../../api/RegistryProcedure";
import classnames from "classnames";
import { getUnmatchedPromsProcedures } from "../../api/Procedures";
import UnmatchedPromsTable from "./UnmatchedPromsTable";
import SearchFuzzyMatchModal from "./SearchFuzzyMatchModal";
import ButtonBar from "../common/ButtonBar";
import { apiErrorResponse } from "./Utils";
import { useEffect, useReducer } from "react";

const FuzzyMatchingModal = ({
  onExit = () => null,
  open = false,
  itemsCountPerPage = 10,
}) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      totalItemsCount: 0,
      activePage: 1,
      data: [],
      unmatched: [],
      searchRow: null,
      activeTab: "1",
      unmatchedItemsCount: 0,
      activeUnmatchedPage: 1,
      fuzzyProcedureId: "",
      unmatchedProcedureId: "",
    },
  );

  useEffect(() => {
    loadUnmatchedPromsProcedures();
  }, [state.activeUnmatchedPage]);

  useEffect(() => {
    loadFuzzyMatches();
  }, [state.activePage]);

  const setActiveTab = (tab) => {
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      });
    }
  };

  const loadFuzzyMatches = () => {
    let payload = {
      page: state.activePage - 1,
      size: itemsCountPerPage,
    };
    getFuzzyMatches(payload)
      .then((response) => {
        setState({
          data: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
        });
      })
      .catch((error) => {
        let msg = "An error occurred while loading the fuzzy matches.";
        apiErrorResponse(error, msg);
      });
  };

  const loadUnmatchedPromsProcedures = () => {
    let payload = {
      page: state.activeUnmatchedPage - 1,
      size: itemsCountPerPage,
    };
    getUnmatchedPromsProcedures(payload)
      .then((response) => {
        setState({
          unmatched: response.data.content,
          activeUnmatchedPage: response.data.number + 1,
          unmatchedItemsCount: response.data.totalElements,
        });
      })
      .catch((error) => {
        let msg =
          "An error occurred while loading the unmatched PROMs procedures.";
        apiErrorResponse(error, msg);
      });
  };

  const saveMatch = (idRegistry, idProms) => {
    saveFuzzyMatch(idRegistry, idProms)
      .then((response) => {
        if (response.data) {
          toast.success("Fuzzy match saved");
          loadFuzzyMatches();
        }
      })
      .catch((error) => {
        let msg =
          "An error occurred while saving the fuzzy match. Please try again.";
        apiErrorResponse(error, msg);
      });
  };

  const saveManualMatch = (idRegistry, idProms) => {
    saveFuzzyMatch(idRegistry, idProms)
      .then((response) => {
        if (response.data) {
          toast.success("Fuzzy match saved");
          loadFuzzyMatches();
          loadUnmatchedPromsProcedures();
          setState({
            searchRow: null,
          });
        }
      })
      .catch((error) => {
        let msg =
          "An error occurred while saving the fuzzy match. Please try again.";
        apiErrorResponse(error, msg);
      });
  };

  const saveNeverMatch = (sourceId, idProms) => {
    const payload = {
      sourceId: sourceId,
      procedureId: idProms,
    };
    addNeverMatch(payload)
      .then((response) => {
        if (response.data) {
          toast.success("Never match saved");
          loadFuzzyMatches();
        }
      })
      .catch((error) => {
        let msg =
          "An error occurred while saving the never match. Please try again.";
        apiErrorResponse(error, msg);
      });
  };

  const filterFuzzyMatches = (target) => {
    const procedureId =
      target.validity.valid && target.value.length <= 10
        ? target.value
        : state.fuzzyProcedureId;
    setState({ fuzzyProcedureId: procedureId });
    let payload = {
      page: 0,
      size: itemsCountPerPage,
      procedureId: procedureId,
    };
    getFuzzyMatches(payload)
      .then((response) => {
        setState({
          data: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
        });
      })
      .catch((error) => {
        let msg = "An error occurred while loading the fuzzy matches.";
        apiErrorResponse(error, msg);
      });
  };

  const filterUnmatched = (target) => {
    const procedureId =
      target.validity.valid && target.value.length <= 10
        ? target.value
        : state.unmatchedProcedureId;
    setState({ unmatchedProcedureId: procedureId });
    let payload = {
      page: 0,
      size: itemsCountPerPage,
      procedureId: procedureId,
    };
    getUnmatchedPromsProcedures(payload)
      .then((response) => {
        setState({
          unmatched: response.data.content,
          activeUnmatchedPage: response.data.number + 1,
          unmatchedItemsCount: response.data.totalElements,
        });
      })
      .catch((error) => {
        let msg =
          "An error occurred while loading the unmatched PROMs procedures.";
        apiErrorResponse(error, msg);
      });
  };

  return (
    <Modal
      isOpen={open}
      toggle={onExit}
      size={"lg"}
      autoFocus={true}
      className={"fuzzy-matching-modal"}
      keyboard={false}
    >
      <ModalHeader toggle={onExit}>Unmatched PROMs Procedures</ModalHeader>
      <ModalBody>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: state.activeTab === "1" })}
                onClick={() => {
                  setActiveTab("1");
                }}
              >
                Fuzzy Matches
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: state.activeTab === "2" })}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                Unmatched PROMs
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={state.activeTab}>
            <TabPane tabId="1">
              <Input
                id={"procedure-id-filter"}
                type={"text"}
                pattern={"[0-9]*"}
                value={state.fuzzyProcedureId}
                placeholder={"Filter by Procedure ID"}
                onChange={(event) => filterFuzzyMatches(event.target)}
              />
              <div id={"table-info-text"}>
                <i>
                  Top row is the registry procedure, bottom row is the PROMs
                  procedure
                </i>
              </div>
              <SearchFuzzyMatchModal
                searchRow={state.searchRow}
                onExit={() => setState({ searchRow: null })}
                open={state.searchRow != null}
                saveCallback={saveManualMatch}
              />
              <FuzzyMatchingTable
                data={state.data}
                saveCallback={saveMatch}
                activePage={state.activePage}
                totalItemsCount={state.totalItemsCount}
                searchCallback={(row) => setState({ searchRow: row })}
                neverMatchCallback={saveNeverMatch}
                changePageCallback={(page) => setState({ activePage: page })}
              />
            </TabPane>
            <TabPane tabId="2">
              <Input
                id={"unmatched-procedure-id-filter"}
                type={"text"}
                pattern={"[0-9]*"}
                value={state.unmatchedProcedureId}
                placeholder={"Filter by Procedure ID"}
                onChange={(event) => filterUnmatched(event.target)}
              />
              <SearchFuzzyMatchModal
                searchRow={state.searchRow}
                onExit={() => setState({ searchRow: null })}
                open={state.searchRow != null}
                saveCallback={saveManualMatch}
              />
              <UnmatchedPromsTable
                data={state.unmatched}
                activePage={state.activeUnmatchedPage}
                totalItemsCount={state.unmatchedItemsCount}
                searchCallback={(row) => setState({ searchRow: row })}
                changePageCallback={(page) =>
                  setState({ activeUnmatchedPage: page })
                }
              />
            </TabPane>
          </TabContent>
        </div>
        <ButtonBar
          buttons={[
            <Button color={"secondary"} onClick={() => onExit()}>
              Close
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

FuzzyMatchingModal.propTypes = {
  onExit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  itemsCountPerPage: PropTypes.number,
};

export default FuzzyMatchingModal;

import { handleActions } from "redux-actions";
import {
  sendSurgeonSmsError,
  sendSurgeonSmsSuccess,
} from "../actions/SurgeonActions";

const INITIAL_STATE = {
  smsError: null,
  smsSuccess: null,
};

export default handleActions(
  {
    [sendSurgeonSmsError]: (state, { payload }) => ({
      ...state,
      ...{ smsError: payload },
    }),
    [sendSurgeonSmsSuccess]: (state, { payload }) => ({
      ...state,
      ...{ smsSuccess: payload },
    }),
  },
  INITIAL_STATE,
);

import { Button, Col, FormFeedback, FormText, Input, Row } from "reactstrap";
import { MdBackspace } from "react-icons/md";
import PropTypes from "prop-types";
import { supportsTouchEvents } from "detect-it";
import "./SimpleNumericInput.css";
import { useEffect, useRef, useState } from "react";
import { handleEnterKeyPress } from "../../Utils";

export const BACKSPACE = "BACKSPACE";
const RE_DIGIT = RegExp("^\\d*$");

const SimpleNumericInput = ({
  updateAction = (value) => null,
  field = null,
  value = "",
  length = 0,
  invalid = false,
  invalidNotice = "That does not look like a number.",
  hintText = null,
  enterAction = () => null,
}) => {
  const numericInput = useRef(null);

  const [selectedKeys, setSelectedKeys] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    0: false,
    BACKSPACE: false,
  });

  useEffect(() => {
    numericInput.current.focus();
  }, [field]);

  const flashKey = (value) => {
    let keys = { ...selectedKeys };
    keys[value] = true;
    setSelectedKeys(keys);
    setTimeout(() => {
      let nextKeys = { ...selectedKeys };
      nextKeys[value] = false;
      setSelectedKeys(nextKeys);
    }, 100);
  };

  const updateValue = (newValue) => {
    // Force flash of pressed key... some iOS devices hate :active and :hover.
    flashKey(newValue);

    let oldValue = value;
    if (oldValue === null || oldValue === undefined) {
      oldValue = "";
    }

    if (newValue === BACKSPACE) {
      newValue = oldValue.slice(0, -1);
    } else if (oldValue.length < length) {
      newValue = oldValue + newValue + "";
    } else {
      return;
    }
    doUpdate(newValue);
  };

  const updateKeypress = (newValue) => {
    if (RE_DIGIT.test(newValue) && newValue.length <= length) {
      doUpdate(newValue);
    }
  };

  const doUpdate = (value) => {
    if (!!field) {
      updateAction({ [field]: value });
    } else {
      updateAction(value);
    }
  };

  const createButton = (key) => {
    return (
      <Col xs={4} key={key}>
        <Button
          color={"primary"}
          className={"no-background-while-focused"}
          outline={!selectedKeys[key]}
          onClick={() => updateValue(key)}
          tabIndex={-1}
        >
          {key}
        </Button>
      </Col>
    );
  };

  let update = supportsTouchEvents ? null : updateKeypress;
  return (
    <div className={"simple-input-wrapper"}>
      <Row>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
        <Col>
          <Input
            type={"text"}
            value={value || ""}
            readOnly={supportsTouchEvents}
            disabled={supportsTouchEvents}
            onChange={(e) => update(e.target.value)}
            style={{ backgroundColor: "#fff" }}
            bsSize={"lg"}
            innerRef={(input) => {
              numericInput.current = input;
            }}
            tabIndex={1}
            className={invalid ? "is-invalid" : null}
            onKeyPress={(event) => handleEnterKeyPress(event, enterAction)}
          />
          <FormFeedback>{invalidNotice}</FormFeedback>
          {!invalid && hintText && (
            <FormText color="muted">{hintText}</FormText>
          )}
        </Col>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
      </Row>
      {/*Number Pad time*/}
      <Row>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
        <Col className={"keypad numeric"}>
          <Row>
            {[7, 8, 9].map((i) => {
              return createButton(i);
            })}
          </Row>
          <Row>
            {[4, 5, 6].map((i) => {
              return createButton(i);
            })}
          </Row>
          <Row>
            {[1, 2, 3].map((i) => {
              return createButton(i);
            })}
          </Row>
          <Row>
            <Col xs={8}>
              <Button
                className={"no-background-while-focused"}
                color={"primary"}
                outline={!selectedKeys["0"]}
                style={{ flexGrow: 2 }}
                tabIndex={-1}
                onClick={() => updateValue(0)}
              >
                0
              </Button>
            </Col>
            <Col xs={4}>
              <Button
                className={"no-background-while-focused"}
                color={"primary"}
                outline={!selectedKeys["BACKSPACE"]}
                tabIndex={-1}
                onClick={() => updateValue(BACKSPACE)}
              >
                <div>
                  <span className={"backspace-icon"}>
                    <MdBackspace />
                  </span>
                  <span className={"backspace-short-word"}>DEL</span>
                  <span className={"backspace-long-word"}> DELETE</span>
                </div>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
      </Row>
    </div>
  );
};

SimpleNumericInput.propTypes = {
  updateAction: PropTypes.func.isRequired,
  field: PropTypes.string,
  value: PropTypes.string,
  length: PropTypes.number.isRequired,
  invalid: PropTypes.bool,
  invalidNotice: PropTypes.string,
  hintText: PropTypes.string,
  enterAction: PropTypes.func,
};

export default SimpleNumericInput;

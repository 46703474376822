import { lazy, useState } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverBody } from "reactstrap";
import { WaitingComponent } from "../../Utils";

const THANKS_CGA = "#F0F";

const ColourPicker = ({
  handleChangeComplete = () => null,
  color = THANKS_CGA,
  id = "ColourPicker_" + `${Math.random() * 1000000}`.slice(0, 5),
}) => {
  const [open, setOpen] = useState(false);

  const LazySketchPicker = WaitingComponent(
    lazy(() => import("react-color/lib/components/sketch/Sketch")),
  );
  return (
    <div>
      <div
        style={{
          height: 22,
          width: 22,
          background: "#fff",
          borderRadius: 1,
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: 18,
            width: 18,
            borderRadius: 2,
            backgroundColor: color || THANKS_CGA,
            alignSelf: "center",
          }}
          onClick={() => setOpen(!open)}
          id={id}
        />
      </div>
      <Popover
        placement="bottom"
        isOpen={open}
        target={id}
        toggle={() => setOpen(true)}
      >
        <PopoverBody>
          <LazySketchPicker
            color={color || THANKS_CGA}
            onChangeComplete={handleChangeComplete}
            presetColors={[
              { title: "AOA Blue", color: "#2d3884" },
              { title: "AOA LightBlue", color: "#859CC2" },
              { title: "AOA Grey", color: "#DADAD9" },
              { title: "AOA Brown", color: "#D3C2A0" },
              { title: "AOA Tan", color: "#C1957B" },
              { title: "AOA Green", color: "#739478" },
              { title: "Hip", color: "#e7af19" },
              { title: "Knee", color: "#7cb03f" },
              { title: "Shoulder", color: "#d4755b" },
              { title: "Hip (muted)", color: "#e7c988" },
              { title: "Knee (muted)", color: "#9bb07f" },
              { title: "Shoulder (muted)", color: "#d4a594" },
            ]}
          />
        </PopoverBody>
      </Popover>
    </div>
  );
};

ColourPicker.propTypes = {
  handleChangeComplete: PropTypes.func,
  color: PropTypes.string,
  id: PropTypes.string,
};

export default ColourPicker;

import React, { Component } from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import PropTypes from "prop-types";
import Inlinese from "react-inlinese";
import { connect } from "react-redux";
import ConfigureScreen from "./ConfigureScreen";
import { FaCopy, FaPlusSquare } from "react-icons/fa";
import {
  addReportRole,
  duplicateReport,
  newScreen,
  removeReportRole,
  updateReport,
} from "../../actions/InstrumentReportActions";
import Select from "react-select";

const EMPTY_FIELD = "...";

class ConfigureReport extends Component {
  static defaultProps = {
    report: null,
  };

  static propTypes = {
    report: PropTypes.object,
  };

  saveField = (field, value) => {
    const { updateReport, viewingRole } = this.props;
    if (value.length === 0) {
      return;
    }
    let { report } = this.props;
    report[field] = value;
    updateReport({ report, roleId: viewingRole.id });
  };

  createNewScreen = () => {
    const { report, newScreen } = this.props;
    newScreen({ index: report.screens.length, report: report });
  };

  selectRoles = (roles) => {
    const { report, addReportRole, removeReportRole } = this.props;
    if (roles.length > report.roles.length) {
      // Added a role
      const existing = new Set([...report.roles]);
      let added = roles.filter((r) => !existing.has(r))[0];
      addReportRole({ reportId: report.id, role: added });
    } else {
      // Removed a role. No empty role definitions allowed - a report always has to be associated with at least one role!
      if (report.roles.length > 1) {
        const newRoles = new Set([...roles]);
        let removed = report.roles.filter((r) => !newRoles.has(r))[0];
        removeReportRole({ reportId: report.id, role: removed });
      }
    }
  };

  render() {
    const { report, duplicateReport, roles, viewingRole } = this.props;

    if (!report) {
      return null;
    }

    return (
      <Card style={{ padding: 10 }}>
        <CardTitle style={{ display: "flex", justifyContent: "space-between" }}>
          <Inlinese
            placeholder="Report Title"
            value={report.name}
            onSubmit={(v) => this.saveField("name", v)}
            showButtons={false}
            roundness=".25rem"
          >
            {report.name || EMPTY_FIELD} [{report.id || EMPTY_FIELD}]
          </Inlinese>
          <FaCopy
            onClick={() =>
              duplicateReport({ id: report.id, roleId: viewingRole.id })
            }
          />
        </CardTitle>
        <CardBody style={{ padding: 0 }}>
          <Row className={"g-0"}>
            <Inlinese
              placeholder="Report Description"
              value={report.description}
              onSubmit={(v) => this.saveField("description", v)}
              showButtons={false}
              roundness=".25rem"
              style={{ width: "100%", backgroundColor: "green" }}
            >
              {report.description || EMPTY_FIELD}
            </Inlinese>
          </Row>
          <Row className="roles g-0">
            <Select
              options={roles}
              value={report.roles}
              isClearable={false}
              isMulti
              onChange={(selected) =>
                this.selectRoles(selected == null ? [] : selected)
              }
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              styles={{
                menu: (base) => ({ ...base, zIndex: 2, width: "100%" }),
              }}
            />
          </Row>
          <Row className={"g-0"}>
            {report.screens
              .sort((s1, s2) =>
                s1.index > s2.index ? 1 : s1.index < s2.index ? -1 : 0,
              )
              .map((s, i) => (
                <ConfigureScreen key={i} screen={s} reportId={report.id} />
              ))}
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
            className={"g-0"}
          >
            <span className="add-button">Add Screen</span>
            <FaPlusSquare onClick={this.createNewScreen} />
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  (state) => ({
    studies: state.studies,
    ...state.instrumentReports,
  }),
  {
    newScreen: newScreen,
    updateReport: updateReport,
    duplicateReport: duplicateReport,
    addReportRole: addReportRole,
    removeReportRole: removeReportRole,
  },
)(ConfigureReport);

import React, { lazy, Suspense } from "react";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  FaChartArea,
  FaChartBar,
  FaClipboardCheck,
  FaClipboardList,
  FaHeartbeat,
  FaHospital,
  FaMedkit,
  FaQuestion,
  FaServer,
  FaSignOutAlt,
  FaTable,
  FaUpload,
  FaUserFriends,
  FaUsers,
} from "react-icons/fa";
import DataManagement from "../administration/DataManagement";
import HospitalManagement from "../management/hospital/HospitalManagement";
import Reporting from "../reporting/Reporting";
import UserManagement from "../management/user/UserManagement";
import Uploads from "../uploads/Uploads";
import NavButton from "./NavButton";
import Administration from "../administration/Administration";
import PatientManagement from "../management/patient/PatientManagement";
import StakeholderManagement from "../management/stakeholder/StakeholderManagement";
import NonPatientView from "../compare/NonPatientView";
import { MdMessage, MdQuestionAnswer } from "react-icons/md";
import Tasks from "../task/Tasks";
import DataQueries from "../data-query/DataQueries";
import { PageTracking } from "../app/PageTracking";

export const Administrator = (
  menuOpen,
  toggleMenuCb,
  userLogoutCb,
  helpToggleCallback,
  showResults,
) => {
  const menu = (sidebarMenu, showHelpAndLogout) => (
    <Navbar
      className={`vertical-menu ${sidebarMenu ? "header" : ""}`}
      light
      expand={!sidebarMenu}
    >
      <NavbarToggler onClick={toggleMenuCb} children={<span>MENU</span>} />
      <Collapse isOpen={sidebarMenu ? menuOpen : false} navbar>
        <Nav className="me-auto vert-menu-holder" vertical>
          <NavButton
            to={"/administration"}
            icon={<FaServer />}
            text={"Administration"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/crf/management"}
            icon={<FaClipboardList />}
            text={"CRF Types"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/data-management"}
            icon={<FaTable />}
            text={"Data Management"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/data-queries"}
            icon={<MdMessage />}
            text={"Data Queries"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/hospital/management"}
            icon={<FaHospital />}
            text={"Hospitals"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/instrument/management"}
            icon={<MdQuestionAnswer />}
            text={"Instruments"}
            okClick={toggleMenuCb}
          />
          <NavButton
            to={"/patient/management"}
            icon={<FaMedkit />}
            text={"Patients"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/reporting"}
            icon={<FaChartBar />}
            text={"Reporting"}
            onClick={toggleMenuCb}
          />
          {showResults && (
            <NavButton
              to={"/compare"}
              icon={<FaChartArea />}
              text={"Results"}
              onClick={toggleMenuCb}
            />
          )}
          <NavButton
            to={"/stakeholder-groups"}
            icon={<FaUserFriends />}
            text={"Stakeholders"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/study/management"}
            icon={<FaHeartbeat />}
            text={"Studies"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/tasks"}
            icon={<FaClipboardCheck />}
            text={"Tasks"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/user/management"}
            icon={<FaUsers />}
            text={"Users"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/uploads"}
            icon={<FaUpload />}
            text={"Uploads"}
            onClick={toggleMenuCb}
          />
          {showHelpAndLogout && (
            <>
              <hr />
              <NavButton
                to={null}
                icon={<FaQuestion className={"icon help"} />}
                text={"Help"}
                onClick={helpToggleCallback}
              />
              <NavButton
                to={"/administration"}
                icon={<FaSignOutAlt className={"icon"} />}
                text={"Logout"}
                onClick={userLogoutCb}
              />
            </>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );

  const LazyStudyManagement = lazy(
    () => import("../management/study/StudyManagement"),
  );
  const LazyCrfTypeManagement = lazy(
    () => import("../management/crf-type/CrfTypeManagement"),
  );
  const LazyInstrumentManagement = lazy(
    () => import("../management/instrument/InstrumentManagement"),
  );

  const switchRoute = (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path="/administration"
          element={
            <PageTracking>
              <Administration />
            </PageTracking>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PageTracking>
              <Tasks />
            </PageTracking>
          }
        />
        <Route
          path="/crf/management"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <PageTracking>
                <LazyCrfTypeManagement />
              </PageTracking>
            </Suspense>
          }
        />
        <Route
          path="/data-management"
          element={
            <PageTracking>
              <DataManagement />
            </PageTracking>
          }
        />
        <Route
          path="/data-queries"
          element={
            <PageTracking>
              <DataQueries />
            </PageTracking>
          }
        />
        <Route
          path="/hospital/management"
          element={
            <PageTracking>
              <HospitalManagement />
            </PageTracking>
          }
        />
        <Route
          path="/instrument/management"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <PageTracking>
                <LazyInstrumentManagement />
              </PageTracking>
            </Suspense>
          }
        />
        <Route
          path="/patient/management"
          element={
            <PageTracking>
              <PatientManagement />
            </PageTracking>
          }
        />
        <Route
          path="/reporting"
          element={
            <PageTracking>
              <Reporting />
            </PageTracking>
          }
        />
        <Route
          path="/compare"
          element={
            <PageTracking>
              <NonPatientView />
            </PageTracking>
          }
        />
        <Route
          path="/stakeholder-groups"
          element={
            <PageTracking>
              <StakeholderManagement />
            </PageTracking>
          }
        />
        <Route
          path="/study/management"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <PageTracking>
                <LazyStudyManagement />
              </PageTracking>
            </Suspense>
          }
        />
        <Route
          path="/tasks"
          element={
            <PageTracking>
              <Tasks />
            </PageTracking>
          }
        />
        <Route
          path="/uploads"
          element={
            <PageTracking>
              <Uploads />
            </PageTracking>
          }
        />
        <Route
          path="/user/management"
          element={
            <PageTracking>
              <UserManagement />
            </PageTracking>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Suspense>
  );

  return {
    switchRoute: switchRoute,
    menu: menu(),
    menuClosing: menu(true, false),
    menuCombined: menu(true, true),
  };
};

export default Administrator;

import { Alert, Col, Container, Row } from "reactstrap";
import Recruitment from "./Recruitment";
import { useDispatch, useSelector } from "react-redux";
import StudyCollections from "./StudyCollections";
import {
  isAdministratorUser,
  isAoaUser,
  isEmptyOrNull,
  isHospitalAdministratorUser,
  isHospitalStudyCoordinatorUser,
  isSahmriUser,
  isStakeholderUser,
  isSurgeonUser,
} from "../../Utils";
import { loadHospitals, loadStudies } from "../../actions/CommonActions";
import { loadSurgeons } from "../../actions/SurgeonActions";
import "./Reporting.css";
import MatchedProcedures from "./MatchedProcedures";
import { sendViewDashboardEvent } from "../../api/Event";
import { useEffect } from "react";
import { useOnUpdate } from "../CustomHooks";
import {
  getPatientAgeDemographics,
  getProcedureTypes,
} from "../../api/Reporting";
import MultiRingReport from "./MultiRingReport";
import { getColourForProcedureType } from "./Utils";
import PieReport from "./PieReport";

const Reporting = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const hospitals = useSelector((state) => state.hospitals);
  const studies = useSelector((state) => state.studies);
  const surgeons = useSelector((state) => state.surgeons);

  useEffect(() => {
    hospitals.length === 0 && dispatch(loadHospitals());
    studies.length === 0 && dispatch(loadStudies());
    if (surgeons.length === 0 && !isStakeholderUser(user)) {
      dispatch(loadSurgeons());
    }
  }, [dispatch]);

  useOnUpdate(() => {
    sendViewDashboardEvent();
  }, []);

  const showRings =
    hospitals.length > 0 &&
    (!isHospitalAdministratorUser(user) || isEmptyOrNull(user.surgeonIds));
  if (studies.length === 0) {
    return null;
  } else {
    return (
      <Container className={"reporting"}>
        {hospitals.length === 0 && isStakeholderUser(user) && (
          <Alert color={"primary"} className={"aggregate-data-alert"}>
            Aggregated data for all hospitals contributing to the{" "}
            {studies.map((study) => study.id).includes(1) ? "pilot" : "trial"}
          </Alert>
        )}
        <Row>
          <Col>
            <Recruitment />
          </Col>
        </Row>
        <Row>
          <Col>
            <StudyCollections />
          </Col>
        </Row>
        {/* Only include if the user is either a non-stakeholder/surgeon or someone that is linked to (at least) PROMs */}
        {((!isStakeholderUser(user) &&
          !isSurgeonUser(user) &&
          !isHospitalAdministratorUser(user) &&
          !isHospitalStudyCoordinatorUser(user)) ||
          studies.map((study) => study.id).includes(1)) && (
          <Row>
            <Col>
              <MatchedProcedures
                useStudySelector={
                  isAoaUser(user) ||
                  isAdministratorUser(user) ||
                  isSahmriUser(user) ||
                  isHospitalAdministratorUser(user)
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12} md={6}>
            {showRings ? (
              <MultiRingReport
                fetchData={getPatientAgeDemographics}
                id={"patient-ages-rings"}
              />
            ) : (
              <PieReport
                fetchData={getPatientAgeDemographics}
                id={"patient-ages-pie"}
              />
            )}
          </Col>
          <Col sm={12} md={6}>
            {showRings ? (
              <MultiRingReport
                fetchData={getProcedureTypes}
                mapColours={(name) => getColourForProcedureType(name)}
                id={"procedure-types-rings"}
              />
            ) : (
              <PieReport
                fetchData={getProcedureTypes}
                mapColours={(name) => getColourForProcedureType(name)}
                id={"procedure-types-pie"}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
};

export default Reporting;

import PropTypes from "prop-types";
import Select from "react-select";
import { getStartCase } from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { loadSides } from "../../actions/CommonActions";
import { useEffect } from "react";

const SimpleSideSelector = ({
  placeholder = "Select...",
  clearable = false,
  disabled = false,
  searchable = false,
  autoFocus = true,
  tabIndex = null,
  onChange,
  value,
}) => {
  const dispatch = useDispatch();
  const sides = useSelector((state) => state.sides);

  useEffect(() => {
    dispatch(loadSides());
  }, [dispatch]);

  const renderOption = (option) => {
    return getStartCase(option.name ? option.name : null);
  };

  return (
    <div>
      <Select
        options={sides}
        value={value}
        getOptionLabel={(option) => renderOption(option)}
        getOptionValue={(option) => option.id}
        autoFocus={autoFocus}
        isClearable={clearable}
        isSearchable={searchable}
        onChange={(side) => onChange(side)}
        placeholder={placeholder}
        isDisabled={disabled}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
        tabIndex={tabIndex}
      />
    </div>
  );
};

SimpleSideSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default SimpleSideSelector;

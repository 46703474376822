import { del, get, post, put } from "./Api";

const BASE_URL = "/api/study";

export function getStudies() {
  return get(`${BASE_URL}/all`, null, "Unable to load studies at this time.");
}

export function getStudyManagementStudies() {
  return get(
    `${BASE_URL}/management`,
    null,
    "Unable to load studies at this time.",
  );
}

export function getLinkedStudies(
  userId,
  hospitalId,
  procedureTypeIds,
  includeHistoric,
  includeHidden,
) {
  return get(
    `${BASE_URL}/linked`,
    {
      hospitalId: hospitalId,
      userId: userId,
      procedureTypeIds: procedureTypeIds.join(),
      includeHistoric: includeHistoric,
      includeHiddenUntilPatientAgreedStudies: includeHidden,
    },
    "Unable to load studies at this time.",
  );
}

export function getReportsForStudyAndProcedureType(
  studyId,
  procedureTypeId,
  hospitalIds,
) {
  return post(
    `${BASE_URL}/reports`,
    {
      studyId: studyId,
      procedureTypeId: procedureTypeId,
      hospitalIds: hospitalIds,
    },
    "Unable to load reports at this time.",
  );
}

export function getComparisonReport(request) {
  return post(
    `${BASE_URL}/compare`,
    request,
    "Unable to compare your results at this time.",
  );
}

export function updateStudy(payload) {
  return put(`${BASE_URL}`, payload, "Unable to save study at this time.");
}

export function createStudy(payload) {
  return post(`${BASE_URL}`, payload, "Unable to create study at this time.");
}

export function importStudy(payload) {
  return post(
    `${BASE_URL}/import`,
    payload,
    "Unable to import study at this time.",
  );
}

export function deleteStudy(id) {
  return del(
    `${BASE_URL}?id=${id}`,
    "Unable to delete Study at this time.",
  );
}

import { Fragment, useEffect, useReducer } from "react";
import "./PatientReminders.css";
import PropTypes from "prop-types";
import { Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import Responsive from "react-responsive";
import { getProcedures } from "../../../api/Patient";
import { getStartCase, isEmptyOrNull } from "../../../Utils";
import PatientProcedureSelector from "../../common/PatientProcedureSelector";
import { getProcedureReminders } from "../../../api/Procedures";

const PatientReminders = ({ patientId }) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      modalPatientId: "",
      loadPatientError: "",
      patient: null,
      reminders: [],
      reminder: null,
      procedures: [],
      procedureFilter: null,
      procedureId: null,
    },
  );

  useEffect(() => {
    getProcedures(patientId)
      .then((response) => {
        setState({
          procedures: response.data,
          procedureFilter: response.data.length > 0 ? response.data[0] : null,
        });
      })
      .catch((error) => {
        if (isEmptyOrNull(error?.response)) {
          setState({ loadPatientError: "Error loading patient" });
        } else {
          setState({
            loadPatientError: `Error loading patient. ${error.response}`,
          });
        }
      });
  }, []);

  useEffect(() => {
    setProceduresFilter(state.procedureFilter);
  }, [state.procedureFilter]);

  const renderProcedureOptions = (procedure) => {
    return `${getStartCase(procedure.procedureName)} (ID: ${
      procedure.procedureId
    })`;
  };

  const setProceduresFilter = (procedure) => {
    if (procedure != null) {
      setState({
        procedureFilter: procedure,
        procedureId: procedure.procedureId,
      });
      getProcedureReminders(procedure.procedureId)
        .then((response) => {
          setState({ reminders: response.data });
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({
              loadPatientError:
                "Error loading reminder details for the Patient",
            });
          } else {
            setState({
              loadPatientError: `Error loading reminder details for the patient. ${error.response.data}`,
            });
          }
        });
    }
  };

  const getResultsTable = () => {
    return (
      <Fragment>
        <Responsive maxWidth={767}>
          <Table
            className={"patient-reminders-table"}
            size={"sm"}
            bordered
            striped
            responsive
          >
            <tbody>
              {(state.reminders !== null || state.reminders !== "undefined") &&
                state.reminders.map((reminder) => {
                  return (
                    <tr key={reminder.id}>
                      <td>
                        <Row className={"g-0"}>
                          <Col xs={5} className={"td-label"}>
                            Date:
                          </Col>
                          <Col xs={7}>{reminder.dateSent}</Col>
                        </Row>
                        <Row className={"g-0"}>
                          <Col xs={5} className={"td-label"}>
                            Type:
                          </Col>
                          <Col xs={7}>{reminder.reminderType}</Col>
                        </Row>
                        <Row className={"g-0"}>
                          <Col xs={5} className={"td-label"}>
                            Study:
                          </Col>
                          <Col xs={7}>{reminder.study}</Col>
                        </Row>
                        <Row className={"g-0"}>
                          <Col xs={5} className={"td-label"}>
                            Survey Type:
                          </Col>
                          <Col xs={7}>{reminder.surveyType}</Col>
                        </Row>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={768}>
          <Table className={"patient-reminders-table"}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Study</th>
                <th>Survey Type</th>
              </tr>
            </thead>
            <tbody>
              {state.reminders.map((reminder) => {
                return (
                  <tr key={reminder.id}>
                    <td>{reminder.dateSent}</td>
                    <td>{reminder.reminderType}</td>
                    <td>{reminder.study}</td>
                    <td>{reminder.surveyType}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
      </Fragment>
    );
  };

  return (
    <div className="patient-landing">
      <div>
        <Form>
          <FormGroup>
            <Row className={"first-row"}>
              <Col xs={12} sm={12} md={4} lg={3}>
                <Label
                  className={"form-label procedures-label"}
                  for="procedures"
                >
                  Procedure(s)
                </Label>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={5}
                className={"patient-procedure-id"}
              >
                <PatientProcedureSelector
                  placeholder={"Select...."}
                  clearable={false}
                  options={state.procedures}
                  value={state.procedureFilter}
                  valueRenderer={(value) => renderProcedureOptions(value)}
                  optionRenderer={(value) => renderProcedureOptions(value)}
                  onChange={setProceduresFilter}
                />
              </Col>
            </Row>
          </FormGroup>
        </Form>
        {getResultsTable()}
      </div>
    </div>
  );
};

PatientReminders.propTypes = {
  patientId: PropTypes.number,
};

export default PatientReminders;

import { Fragment, useReducer } from "react";
import { Row, Table } from "reactstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ExternalResponseUploads from "./ExternalResponseUploads";
import Pagination from "react-js-pagination";
import { isSahmriUser } from "../../Utils";
import "./UploadTable.css";
import { formatDate, formatDateTime } from "./Utils";

const ExternalResponseUploadTable = ({
  responseUploads = [],
  itemsCountPerPage = 10,
  pageRangeDisplayed = 5,
  totalItemsCount = 0,
  activePage = 1,
  changePageCallback,
}) => {
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      rejectUpload: null,
      toolTipId: null,
      showUploadDialog: false,
      upload: null,
    },
  );

  const closeCallBack = () => {
    setState({
      showUploadDialog: false,
    });
  };

  return (
    <Fragment>
      <Row>
        <Table
          className={"upload-table"}
          size={"sm"}
          bordered
          striped
          responsive
        >
          <thead>
            <tr>
              {isSahmriUser(user) && <th>Uploader</th>}
              <th>Date Uploaded</th>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {responseUploads.map((row) => {
              return (
                <tr
                  key={`upload-table-row-${row.description}-${row.dateUploaded}-${row.uploadedBy.firstName}`}
                >
                  {isSahmriUser(user) && (
                    <td>
                      {row.uploadedBy.firstName + " " + row.uploadedBy.lastName}
                    </td>
                  )}
                  <td>
                    {
                      <span title={formatDateTime(row.dateUploaded)}>
                        {formatDate(row.dateUploaded)}
                      </span>
                    }
                  </td>
                  <td>{row.name}</td>
                  <td>{row.status}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Pagination
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={changePageCallback}
          hideDisabled
          innerClass={"pagination pagination-sm"}
          itemClass={"page-item"}
          linkClass={"page-link"}
        />
      </div>
      {state.showUploadDialog && (
        <ExternalResponseUploads
          open={state.showUploadDialog}
          exitCallback={closeCallBack}
          upload={state.upload}
        />
      )}
    </Fragment>
  );
};

ExternalResponseUploadTable.propTypes = {
  responseUploads: PropTypes.arrayOf(PropTypes.object),
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  totalItemsCount: PropTypes.number,
  activePage: PropTypes.number,
};

export default ExternalResponseUploadTable;

import { useState } from "react";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import StakeholderGroupSelector from "../common/StakeholderGroupSelector";
import FileUploader from "../common/FileUploader";
import "./StakeholderGroupLogos.css";
import {
  deleteStakeholderGroupLogo,
  hasStakeholderLogo,
  saveStakeholderGroupLogo,
} from "../../api/StakeholderGroup";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import { useDispatch } from "react-redux";
import { MdErrorOutline } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";

const StakeholderGroupLogos = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [hasLogo, setHasLogo] = useState(false);

  const dispatch = useDispatch();

  const selectorChanged = (value) => {
    let newId = value && value.id ? value.id : null;
    setSelectedId(newId);
    setNewLogo(null);
    setHasLogo(false);
    if (selectedId) {
      hasStakeholderLogo(value.id).then(() => {
        setHasLogo(true);
      });
    }
  };

  const deleteLogo = () => {
    dispatch(startLoading());
    deleteStakeholderGroupLogo(selectedId)
      .then(() => {
        setNewLogo(null);
        setHasLogo(false);
        toast.success(
          <span>
            <FaCheck /> Stakeholder Group logo successfully deleted
          </span>,
        );
      })
      .catch(() => {
        toast.error(
          <span>
            <MdErrorOutline /> Unable to delete logo for Stakeholder Group ID:{" "}
            {selectedId}.
          </span>,
        );
      })
      .finally(dispatch(finishLoading()));
  };

  const uploadLogo = () => {
    dispatch(startLoading());
    const data = new FormData();
    data.append("file", newLogo);
    saveStakeholderGroupLogo(selectedId, data)
      .then(() => {
        setNewLogo(null);
        setHasLogo(true);
        toast.success(
          <span>
            <FaCheck /> Stakeholder Group logo successfully uploaded
          </span>,
        );
      })
      .catch(() => {
        toast.error(
          <span>
            <MdErrorOutline /> Error uploading new logo for Stakeholder Group
            ID: {selectedId}.
          </span>,
        );
      })
      .finally(dispatch(finishLoading()));
  };

  return (
    <Container className={"stakeholder-group-logos"}>
      <h2>Stakeholder Group Logos</h2>
      <Card>
        <CardBody>
          <Row>
            <Col xl={3} className={"text-end"}>
              <Label>Stakeholder Group:</Label>
            </Col>
            <Col xl={9}>
              <StakeholderGroupSelector onChange={selectorChanged} />
            </Col>
          </Row>
          {hasLogo && selectedId && (
            <Row className={"d-flex align-items-center"}>
              <Col xl={2}>Current Logo:</Col>
              <Col xl={6}>
                <img
                  src={"api/stakeholder-group/" + selectedId + "/logo"}
                  alt="Stakeholder Group Logo"
                />
              </Col>
              <Col xl={4}>
                <Button color={"danger"} onClick={deleteLogo}>
                  Delete
                </Button>
              </Col>
            </Row>
          )}
          {selectedId && (
            <div>
              <FileUploader
                acceptedFileTypes={{ "image/png": [".png"] }}
                instructionText={
                  "Upload a new/replacement PNG logo (200KB max) here"
                }
                maxFileSizeBytes={200000}
                onFileAccepted={(logo) => setNewLogo(logo)}
              />
              {newLogo && (
                <Row>
                  <Col xl={3}>
                    Confirm upload of <i>{newLogo.name}</i>
                  </Col>
                  <Col>
                    <Button color={"primary"} onClick={uploadLogo}>
                      Upload
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default StakeholderGroupLogos;

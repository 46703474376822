import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import ButtonBar from "../common/ButtonBar";

const DeclineModal = ({ cancelCallback, open, confirmCallback, studyName }) => {
  return (
    <Modal isOpen={open}>
      <ModalHeader>
        Decline Study {studyName !== null ? studyName : ""}
      </ModalHeader>
      <ModalBody>
        <p>
          You have selected that you no longer wish to participate in the
          Registry project. We appreciate you taking the time to consider the
          project.
        </p>
        <p>
          Please select ‘Confirm’ to continue or ‘Cancel’ to change your
          selection.
        </p>
        <ButtonBar
          buttons={[
            <Button
              key={"confirm-button"}
              color="primary"
              onClick={confirmCallback}
            >
              Confirm
            </Button>,
            <Button
              key={"cancel-button"}
              color="secondary"
              onClick={cancelCallback}
            >
              Cancel
            </Button>,
          ]}
        />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

DeclineModal.propTypes = {
  open: PropTypes.bool.isRequired,
  studyName: PropTypes.string,
  confirmCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
};

export default DeclineModal;

import { createAction } from "redux-actions";
import * as Types from "./Types";

export const loadUserCurrent = createAction(
  Types.LOAD_USER_CURRENT,
  (payload) => payload,
);
export const userCurrentLoaded = createAction(
  Types.USER_CURRENT_LOADED,
  (payload) => payload,
);
export const userLogout = createAction(Types.USER_LOGOUT, (payload) => payload);
export const userLogon = createAction(Types.USER_LOGON, (payload) => payload);
export const logonError = createAction(Types.LOGON_ERROR, (payload) => payload);
export const sendMfaCode = createAction(
  Types.SEND_MFA_CODE,
  (payload) => payload,
);
export const sendMfaCodeSuccess = createAction(
  Types.SEND_MFA_CODE_SUCCESS,
  (payload) => payload,
);
export const sendMfaCodeError = createAction(
  Types.SEND_MFA_CODE_ERROR,
  (payload) => payload,
);

import { Fragment, useEffect, useReducer } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import "./ProcedureTypePicker.css";
import PropTypes from "prop-types";
import { useOnUpdate, usePrevious } from "../CustomHooks";

// TODO clean up
const ProcedureTypePicker = ({
  compact = false,
  showProcedureTypes = [1, 2, 3, 4],
  resetVersion = 0,
  newProcedure,
  updateAction,
  bottomText,
}) => {
  const defaultState = {
    shoulderReplacement: {
      checked: false,
      previousLeft: false,
      previousRight: false,
    },
    hipReplacement: {
      checked: false,
      previousLeft: false,
      previousRight: false,
    },
    kneeReplacement: {
      checked: false,
      previousLeft: false,
      previousRight: false,
    },
    kneeOsteotomy: {
      checked: false,
      previousLeft: false,
      previousRight: false,
    },
  };

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    defaultState,
  );

  const previousResetVersion = usePrevious(resetVersion);
  const previousShowProcedureTypes = usePrevious(showProcedureTypes);

  useEffect(() => {
    if (previousResetVersion > resetVersion) {
      setState(defaultState);
    }
  }, [resetVersion]);

  // Disabled state of the checkboxes is maintained in this component, but the checked values
  // are part of the application state. We need to set the disabled state when new props are passed
  // in to the component to support resetting the component.
  useOnUpdate(() => {
    if (
      previousShowProcedureTypes.includes(1) &&
      !showProcedureTypes.includes(1)
    ) {
      setState({ shoulderReplacement: defaultState.shoulderReplacement });
      if (newProcedure.leftShoulderReplacement) {
        updateAction("leftShoulderReplacement");
      }
      if (newProcedure.rightShoulderReplacement) {
        updateAction("rightShoulderReplacement");
      }
    }

    if (
      previousShowProcedureTypes.includes(2) &&
      !showProcedureTypes.includes(2)
    ) {
      setState({ hipReplacement: defaultState.hipReplacement });
      if (newProcedure.leftHipReplacement) {
        updateAction("leftHipReplacement");
      }
      if (newProcedure.rightHipReplacement) {
        updateAction("rightHipReplacement");
      }
    }

    if (
      previousShowProcedureTypes.includes(3) &&
      !showProcedureTypes.includes(3)
    ) {
      setState({ kneeReplacement: defaultState.kneeReplacement });
      if (newProcedure.leftKneeReplacement) {
        updateAction("leftKneeReplacement");
      }
      if (newProcedure.rightKneeReplacement) {
        updateAction("rightKneeReplacement");
      }
    }

    if (
      previousShowProcedureTypes.includes(4) &&
      !showProcedureTypes.includes(4)
    ) {
      setState({ kneeOsteotomy: defaultState.kneeOsteotomy });
      if (newProcedure.leftKneeOsteotomy) {
        updateAction("leftKneeOsteotomy");
      }
      if (newProcedure.rightKneeOsteotomy) {
        updateAction("rightKneeOsteotomy");
      }
    }
  }, [showProcedureTypes]);

  const handleClick = (procedureType) => {
    if (updateAction !== null) {
      updateAction(procedureType);
    }
  };

  const toggleRow = (procedureType) => {
    let def = { ...state[procedureType] };
    let leftProcedureType = `left${procedureType
      .charAt(0)
      .toUpperCase()}${procedureType.slice(1)}`;
    let rightProcedureType = `right${procedureType
      .charAt(0)
      .toUpperCase()}${procedureType.slice(1)}`;

    let newState = {
      checked: !def.checked,
      previousLeft: newProcedure[leftProcedureType],
      previousRight: newProcedure[rightProcedureType],
    };
    setState({ [procedureType]: newState });

    let updateState = !def.checked ? def : newState;

    if (updateState.previousLeft) {
      updateAction(leftProcedureType);
    }

    if (updateState.previousRight) {
      updateAction(rightProcedureType);
    }
  };

  const generateButton = (procedureType, text, size, tab) => {
    let solid = newProcedure[procedureType] ? null : "outline";
    let color = solid ? "secondary" : "primary";
    return (
      <Button
        size={size}
        onClick={() => handleClick(procedureType)}
        color={color}
        outline={!!solid}
        tabIndex={tab}
      >
        {text}
      </Button>
    );
  };

  const leftButtons = (size) => {
    return (
      <Fragment>
        {generateButton(
          "leftShoulderReplacement",
          "Left Shoulder Replacement",
          size,
          2,
        )}
        {generateButton("leftHipReplacement", "Left Hip Replacement", size, 4)}
        {generateButton(
          "leftKneeReplacement",
          "Left Knee Replacement",
          size,
          6,
        )}
        {generateButton("leftKneeOsteotomy", "Left Knee Osteotomy", size, 8)}
      </Fragment>
    );
  };

  const rightButtons = (size) => {
    return (
      <Fragment>
        {generateButton(
          "rightShoulderReplacement",
          "Right Shoulder Replacement",
          size,
          1,
        )}
        {generateButton(
          "rightHipReplacement",
          "Right Hip Replacement",
          size,
          3,
        )}
        {generateButton(
          "rightKneeReplacement",
          "Right Knee Replacement",
          size,
          5,
        )}
        {generateButton("rightKneeOsteotomy", "Right Knee Osteotomy", size, 8)}
      </Fragment>
    );
  };

  const renderNonCompact = () => {
    return (
      <div className={"procedure-type-picker"}>
        <Row className={"buttons bottom"}>
          <Col>{leftButtons("lg")}</Col>
          <Col>{rightButtons("lg")}</Col>
        </Row>
        <div className={"subdued"}>{bottomText}</div>
      </div>
    );
  };

  const renderCompact = () => {
    return (
      <div className={"procedure-type-picker compact"}>
        <Row className={"g-0"}>
          <p>
            <b>Replacement</b>
          </p>
        </Row>
        <Row className={"check-row g-0"}>
          <Col xs={4}>
            <FormGroup check>
              <Label
                check
                className={
                  !showProcedureTypes.includes(1) ? "disabled-text" : ""
                }
              >
                <Input
                  type="checkbox"
                  checked={state.shoulderReplacement.checked}
                  disabled={!showProcedureTypes.includes(1)}
                  onChange={() => toggleRow("shoulderReplacement")}
                />{" "}
                Shoulder Replacement
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} className={"text-end"}>
            <FormGroup check>
              <Label
                check
                className={
                  !state.shoulderReplacement.checked ? "disabled-text" : ""
                }
              >
                <Input
                  type="checkbox"
                  checked={newProcedure.leftShoulderReplacement}
                  onChange={() => handleClick("leftShoulderReplacement")}
                  disabled={
                    !state.shoulderReplacement.checked &&
                    !(
                      newProcedure.leftShoulderReplacement ||
                      newProcedure.rightShoulderReplacement
                    )
                  }
                />{" "}
                Left
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} className={"text-end"}>
            <FormGroup check>
              <Label
                check
                className={
                  !state.shoulderReplacement.checked ? "disabled-text" : ""
                }
              >
                <Input
                  type="checkbox"
                  checked={newProcedure.rightShoulderReplacement}
                  onChange={() => handleClick("rightShoulderReplacement")}
                  disabled={!state.shoulderReplacement.checked}
                />{" "}
                Right
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className={"check-row g-0"}>
          <Col xs={4}>
            <FormGroup check>
              <Label
                check
                className={
                  !showProcedureTypes.includes(2) ? "disabled-text" : ""
                }
              >
                <Input
                  type="checkbox"
                  checked={state.hipReplacement.checked}
                  disabled={!showProcedureTypes.includes(2)}
                  onChange={() => toggleRow("hipReplacement")}
                />{" "}
                Hip Replacement
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} className={"text-end"}>
            <FormGroup check>
              <Label
                check
                className={!state.hipReplacement.checked ? "disabled-text" : ""}
              >
                <Input
                  type="checkbox"
                  checked={newProcedure.leftHipReplacement}
                  onChange={() => handleClick("leftHipReplacement")}
                  disabled={!state.hipReplacement.checked}
                />{" "}
                Left
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} className={"text-end"}>
            <FormGroup check>
              <Label
                check
                className={!state.hipReplacement.checked ? "disabled-text" : ""}
              >
                <Input
                  type="checkbox"
                  checked={newProcedure.rightHipReplacement}
                  onChange={() => handleClick("rightHipReplacement")}
                  disabled={!state.hipReplacement.checked}
                />{" "}
                Right
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className={"check-row g-0"}>
          <Col xs={4}>
            <FormGroup check>
              <Label
                check
                className={
                  !showProcedureTypes.includes(3) ? "disabled-text" : ""
                }
              >
                <Input
                  type="checkbox"
                  checked={state.kneeReplacement.checked}
                  disabled={!showProcedureTypes.includes(3)}
                  onChange={() => toggleRow("kneeReplacement")}
                />{" "}
                Knee Replacement
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} className={"text-end"}>
            <FormGroup check>
              <Label
                check
                className={
                  !state.kneeReplacement.checked ? "disabled-text" : ""
                }
              >
                <Input
                  type="checkbox"
                  checked={newProcedure.leftKneeReplacement}
                  onChange={() => handleClick("leftKneeReplacement")}
                  disabled={!state.kneeReplacement.checked}
                />{" "}
                Left
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} className={"text-end"}>
            <FormGroup check>
              <Label
                check
                className={
                  !state.kneeReplacement.checked ? "disabled-text" : ""
                }
              >
                <Input
                  type="checkbox"
                  checked={newProcedure.rightKneeReplacement}
                  onChange={() => handleClick("rightKneeReplacement")}
                  disabled={!state.kneeReplacement.checked}
                />{" "}
                Right
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ paddingTop: 15 }} className={"g-0"}>
          <p>
            <b>Osteotomy</b>
          </p>
        </Row>
        <Row className={"check-row g-0"}>
          <Col xs={4}>
            <FormGroup check>
              <Label
                check
                className={
                  !showProcedureTypes.includes(4) ? "disabled-text" : ""
                }
              >
                <Input
                  type="checkbox"
                  checked={state.kneeOsteotomy.checked}
                  disabled={!showProcedureTypes.includes(4)}
                  onChange={() => toggleRow("kneeOsteotomy")}
                />{" "}
                Knee Osteotomy
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} className={"text-end"}>
            <FormGroup check>
              <Label
                check
                className={!state.kneeOsteotomy.checked ? "disabled-text" : ""}
              >
                <Input
                  type="checkbox"
                  checked={newProcedure.leftKneeOsteotomy}
                  onChange={() => handleClick("leftKneeOsteotomy")}
                  disabled={!state.kneeOsteotomy.checked}
                />{" "}
                Left
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} className={"text-end"}>
            <FormGroup check>
              <Label
                check
                className={!state.kneeOsteotomy.checked ? "disabled-text" : ""}
              >
                <Input
                  type="checkbox"
                  checked={newProcedure.rightKneeOsteotomy}
                  onChange={() => handleClick("rightKneeOsteotomy")}
                  disabled={!state.kneeOsteotomy.checked}
                />{" "}
                Right
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  return compact ? renderCompact() : renderNonCompact();
};

ProcedureTypePicker.propTypes = {
  updateAction: PropTypes.func.isRequired,
  newProcedure: PropTypes.object.isRequired,
  showProcedureTypes: PropTypes.arrayOf(PropTypes.number),
  bottomText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  compact: PropTypes.bool,
  resetVersion: PropTypes.number,
};

export default ProcedureTypePicker;

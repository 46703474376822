import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import ButtonBar from "../../common/ButtonBar";
import { isEmptyOrNull, validateMobile } from "../../../Utils";
import "./AddMobileNumberModal.css";
import {
  confirmMobileWithCode,
  sendMobileConfirmationSms,
} from "../../../api/User";
import { toast } from "react-toastify";
import { useReducer } from "react";

const AddMobileNumberModal = ({ isOpen, saveCallback }) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      mobile: null,
      otpCode: null,
      confirmMobile: false,
      isLoading: false,
      open: true,
    },
  );

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === "mobile") {
      if (value.length >= 1 && !value.startsWith("0")) {
        return;
      }
      if (value.length >= 2 && !value.startsWith("04")) {
        return;
      }
      if (value.length > 10) {
        return;
      }
    } else if (id === "otpCode") {
      if (value.length > 6) {
        return;
      }
    }
    let input = value;
    // Prevent text field entry that consists only of spaces
    if (
      (typeof input === "string" || input instanceof String) &&
      input.trim().length === 0
    ) {
      input = "";
    }
    setState({ [id]: input });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!state.confirmMobile) {
        sendCode();
      } else {
        checkCode();
      }
    }
  };

  const getButtons = () => {
    const buttons = [];

    if (state.confirmMobile === false) {
      buttons.push(
        <Button
          color={"primary"}
          outline={!detailsValid()}
          disabled={!detailsValid() || state.isLoading}
          onClick={sendCode}
        >
          Next
        </Button>,
      );
    } else {
      buttons.push(
        <Button
          color={"primary"}
          outline={!detailsValid()}
          disabled={!detailsValid() || state.isLoading}
          onClick={checkCode}
        >
          Confirm
        </Button>,
        <Button
          color={"primary"}
          outline={false}
          disabled={state.isLoading}
          onClick={sendCode}
        >
          Resend Code
        </Button>,
        <Button color={"primary"} outline={false} onClick={editMobile}>
          Back
        </Button>,
      );
    }
    return buttons;
  };

  const detailsValid = () => {
    let validCode = true;
    if (state.confirmMobile) {
      validCode = !isEmptyOrNull(state.otpCode) && state.otpCode.length === 6;
    }
    return (
      !isEmptyOrNull(state.mobile) && validateMobile(state.mobile) && validCode
    );
  };

  const sendCode = () => {
    setState({ isLoading: true });
    sendMobileConfirmationSms(state.mobile)
      .then(() => {
        toast.success(
          "An SMS with the six digit code has been sent to your mobile.",
        );
        setState({ confirmMobile: true });
      })
      .catch((error) => {
        if (!error.response.data || isEmptyOrNull(error.response.data)) {
          toast.error(
            "There was an issue sending you a code. Please try again later.",
          );
        } else {
          toast.error(error.response.data);
        }
      })
      .finally(() => setState({ isLoading: false }));
  };

  const checkCode = () => {
    if (isEmptyOrNull(state.otpCode)) {
      toast.error("No code has been entered");
    } else {
      setState({ isLoading: true });
      const payload = { mobile: state.mobile, code: state.otpCode };
      confirmMobileWithCode(payload)
        .then(() => {
          toast.success("Mobile number has been saved successfully.");
          setState({ open: false });
          saveCallback();
        })
        .catch((error) => {
          if (!error.response.data || isEmptyOrNull(error.response.data)) {
            toast.error("Could not confirm mobile number. Please try again.");
          } else {
            toast.error(error.response.data);
          }
        })
        .finally(() => setState({ isLoading: false }));
    }
  };

  const editMobile = () => {
    setState({ confirmMobile: false, otpCode: null });
  };

  return (
    <Modal
      className={"add-mobile-number-modal"}
      isOpen={isOpen && state.open}
      size={"lg"}
    >
      <ModalBody>
        <div className={"blurb"}>
          <h5>Important Update</h5>
          <p>
            {" "}
            Multi-factor authentication is being implemented to improve account
            security. Your mobile number is required to set up the additional
            method of authentication.
          </p>
          <p>
            {" "}
            Going forward, a six-digit code will be sent via SMS to the supplied
            mobile number when logging in. You will be required to enter the
            code to complete the login.
          </p>
          {!state.confirmMobile && (
            <p>
              <i>Please enter your mobile number and click Next.</i>
            </p>
          )}
          {state.confirmMobile && (
            <p>
              <i>
                An SMS with a six-digit code has been sent to the mobile number
                provided. Enter the code below and click Confirm to save your
                mobile number.
              </i>
            </p>
          )}
        </div>
        <div>
          <Form>
            <FormGroup>
              <Row className={"form-row"}>
                <Col xs={12} sm={12} md={3} lg={2} className={"my-auto"}>
                  <Label className={"form-label"} for="mobile">
                    Mobile
                  </Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className={"my-auto"}>
                  <Input
                    required
                    type="text"
                    id="mobile"
                    value={state.mobile}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    disabled={state.confirmMobile}
                  />
                </Col>
                <FormFeedback>
                  Mobile must be in the format 04########.
                </FormFeedback>
              </Row>
              {state.confirmMobile && (
                <Row className={"form-row"}>
                  <Col xs={12} sm={12} md={3} lg={2} className={"my-auto"}>
                    <Label className={"form-label"} for="otpCode">
                      Code
                    </Label>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className={"my-auto"}>
                    <Input
                      required
                      type="text"
                      id="otpCode"
                      inputMode="numeric"
                      autoComplete="one-time-code"
                      value={state.otpCode}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      pattern="\d{6}"
                    />
                  </Col>
                  <FormFeedback>The code entered is not valid.</FormFeedback>
                </Row>
              )}
            </FormGroup>
          </Form>
        </div>
        <ButtonBar buttons={getButtons()} reversed={true} />
      </ModalBody>
    </Modal>
  );
};

AddMobileNumberModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  saveCallback: PropTypes.func.isRequired,
};

export default AddMobileNumberModal;

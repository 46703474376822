import PropTypes from "prop-types";
import "./CrfPatientTab.css";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  CREATE,
  getStartCase,
  isEmptyOrNull,
  isStudyCoordinatorUser,
  SAVE,
} from "../../Utils";
import { createCrf, updateCrf } from "../../api/Crf";
import { getCrfTypes } from "../../api/CrfType";
import Select from "react-select";
import "./Crf.css";
import Moment from "moment";
import CrfType from "./CrfType";
import { crfIsValid, YEAR_FIRST_MERIDIAN_DATE_TIME_FORMAT } from "./Utils";
import ButtonBar from "../common/ButtonBar";
import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { useOnUpdate } from "../CustomHooks";

const Crf = ({
  onExit = () => null,
  onConfirm = () => null,
  existing = null,
  readOnly = true,
  preSelectedType = null,
  renderAsModal = true,
  study,
  procedure,
}) => {
  const user = useSelector((state) => state.user);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      types: [],
      selectedType: null,
      answers: [],
      errorText: null,
      childCrfs: [],
      showValidationErrors: false,
      dataQueries: [],
      crfId: null,
      crfTypeId: null,
      studyId: null,
    },
  );

  useEffect(() => {
    getCrfTypes(
      preSelectedType == null ? study.studyId : preSelectedType.studyId,
    ).then((response) => {
      let types = response.data;
      types.sort((t1, t2) => t1.name.localeCompare(t2.name));
      types.forEach((type) => {
        type.questions.sort((q1, q2) => q1.index - q2.index);
        type.questions.forEach((q) => {
          if (q.choices) {
            q.choices.sort((c1, c2) => c1.index - c2.index);
          }
        });
      });
      if (existing != null) {
        setState({
          types: types,
          dataQueries: existing.dataQueries,
          crfId: existing.id,
        });
      } else if (preSelectedType != null) {
        let index = response.data.findIndex(
          (type) => type.id === preSelectedType.id,
        );
        if (index >= 0) {
          setState({ selectedType: response.data[index] });
        }
      } else {
        setState({ types: types });
      }
    });
  }, []);

  useOnUpdate(() => {
    if (existing != null) {
      const existingTypeArray = state.types.filter(
        (type) => type.id === existing.typeId,
      );
      if (existingTypeArray.length === 0) {
        setState({ errorText: "Unable to load CRF Type" });
      } else {
        setState({
          selectedType: existingTypeArray[0],
          answers: existing.answers,
          childCrfs: existing.childCrfs,
          crfId: existing.id,
          crfTypeId: existing.typeId,
          studyId: existing.studyId,
        });
      }
    }
  }, [state.dataQueries]);

  const getModalHeader = (existing, procedure, study, readOnly) => {
    let prefix;
    if (existing == null) {
      prefix = "Create ";
    } else if (readOnly) {
      prefix = "View ";
    } else {
      prefix = "Edit ";
    }
    return (
      prefix +
      study.studyName +
      " CRF - " +
      getStartCase(procedure.side) +
      " " +
      getStartCase(procedure.procedureType) +
      " (" +
      Moment(procedure.dateCreated).format("DD/MM/YYYY") +
      ")" +
      (existing == null || isStudyCoordinatorUser(user)
        ? ""
        : " - ID: " + existing.id)
    );
  };

  const saveCrf = () => {
    let valid = crfIsValid(
      state.selectedType,
      state.answers,
      getChildTypes(state.selectedType),
      state.childCrfs,
    );
    if (valid) {
      setState({ showValidationErrors: false });
      const childPayload = [];
      state.childCrfs.forEach((childCrf) => {
        childPayload.push({
          id: childCrf.id,
          typeId: childCrf.typeId,
          // Since the server interprets these values as LocalDateTime (with no time-zone),
          // we have to translate these to values that don't have timezone representation.
          answers: childCrf.answers.map((a) => {
            if (a.dateTimeValue) {
              return {
                ...a,
                dateTimeValue: Moment.parseZone(
                  a.dateTimeValue,
                  YEAR_FIRST_MERIDIAN_DATE_TIME_FORMAT,
                ).toDate(),
              };
            }
            return a;
          }),
          procedureId: procedure.id || procedure.procedureId,
          studyId: study.id || study.studyId,
        });
      });
      const payload = {
        typeId: state.selectedType.id,
        procedureId: procedure.id || procedure.procedureId,
        studyId: study.id || study.studyId,
        answers: state.answers.map((a) => {
          if (a.dateTimeValue) {
            return {
              ...a,
              dateTimeValue: Moment.parseZone(
                a.dateTimeValue,
                YEAR_FIRST_MERIDIAN_DATE_TIME_FORMAT,
              ).toDate(),
            };
          }
          return a;
        }),
        id: existing == null ? null : existing.id,
        childCrfs: childPayload,
      };

      if (existing == null) {
        createCrf(payload)
          .then(() => {
            onConfirm("CRF successfully created");
          })
          .catch((error) => {
            if (error.response?.data && !isEmptyOrNull(error.response.data)) {
              setState({ errorText: error.response.data });
            } else {
              setState({
                errorText: "An error occurred while creating the CRF",
              });
            }
          });
      } else {
        updateCrf(existing.id, payload)
          .then(() => {
            onConfirm("CRF successfully updated");
          })
          .catch((error) => {
            if (error.response?.data && !isEmptyOrNull(error.response.data)) {
              setState({ errorText: error.response.data });
            } else {
              setState({
                errorText: "An error occurred while creating the CRF",
              });
            }
          });
      }
    } else {
      setState({ showValidationErrors: true });
    }
  };

  const getChildTypes = (parentType) => {
    return state.types.filter((type) => {
      return type.parentCrfTypeId === parentType.id;
    });
  };

  const handleAnswersUpdated = (answers) => {
    setState({
      showValidationErrors: false,
      // If none of the answer value fields are populated, remove answer
      answers: answers.filter(
        (answer) =>
          !isEmptyOrNull(answer.choiceId) ||
          !isEmptyOrNull(answer.textValue) ||
          !isEmptyOrNull(answer.dateValue) ||
          !isEmptyOrNull(answer.dateTimeValue) ||
          !isEmptyOrNull(answer.integerValue) ||
          !isEmptyOrNull(answer.decimalValue),
      ),
    });
  };

  const handleChildCrfsUpdated = (childCrfs) => {
    setState({ childCrfs: childCrfs, showValidationErrors: false });
  };

  const renderModal = () => {
    let crfTypeToUse = !isEmptyOrNull(existing)
      ? state.types.find((t) => t.id === existing.typeId)
      : state.selectedType;
    return (
      <Modal className={"create-crf-modal"} isOpen={true} size={"lg"}>
        <ModalHeader>
          {getModalHeader(existing, procedure, study, readOnly)}
        </ModalHeader>
        <ModalBody>
          {!isEmptyOrNull(state.errorText) && (
            <Alert color={"danger"}>{state.errorText}</Alert>
          )}
          <Row className={"first-row"}>
            <Col xs={2} className={"align-self-center"}>
              Form Type
            </Col>
            <Col xs={10}>
              <Select
                options={state.types.filter((type) => {
                  // Hide child CRFs from the selection
                  return type.parentCrfTypeId == null;
                })}
                onChange={(type) => {
                  setState({
                    selectedType: type,
                    answers: [],
                    showValidationErrors: false,
                  });
                }}
                isClearable={false}
                isSearchable={false}
                isDisabled={existing != null || preSelectedType != null}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                value={state.selectedType}
              />
            </Col>
          </Row>
          {state.selectedType != null && (
            <CrfType
              type={state.selectedType}
              childTypes={getChildTypes(state.selectedType)}
              answers={state.answers}
              childCrfs={state.childCrfs}
              updateAnswersCallback={handleAnswersUpdated}
              updateChildCrfsCallback={handleChildCrfsUpdated}
              readOnly={readOnly}
              showValidationErrors={state.showValidationErrors}
              dataQueries={state.dataQueries}
              crfId={state.crfId}
              crfType={crfTypeToUse}
              onExit={onExit}
              onConfirm={onConfirm}
              studyId={state.studyId}
              crfProcId={procedure.procedureId}
            />
          )}
          {state.showValidationErrors && (
            <Alert color={"danger"}>
              Please fix the validation errors in order to save the CRF
            </Alert>
          )}
          <ButtonBar buttons={getButtons()} />
        </ModalBody>
      </Modal>
    );
  };

  const getButtons = () => {
    const buttons = [];
    if (!readOnly) {
      buttons.push(
        <Button
          color={"primary"}
          onClick={saveCrf}
          disabled={state.selectedType == null}
        >
          {existing == null ? CREATE : SAVE}
        </Button>,
      );
    }
    buttons.push(
      <Button color={"secondary"} onClick={onExit}>
        Cancel
      </Button>,
    );
    return buttons;
  };

  const renderCard = () => {
    let crfTypeToUse = !isEmptyOrNull(existing)
      ? state.types.find((t) => t.id === existing.typeId)
      : state.selectedType;

    return (
      <Card className={"create-crf-card"}>
        <CardBody>
          {!isEmptyOrNull(state.errorText) && (
            <Alert color={"danger"}>{state.errorText}</Alert>
          )}
          <CrfType
            type={preSelectedType}
            childTypes={getChildTypes(preSelectedType)}
            answers={state.answers}
            childCrfs={state.childCrfs}
            updateAnswersCallback={handleAnswersUpdated}
            updateChildCrfsCallback={handleChildCrfsUpdated}
            readOnly={readOnly}
            showValidationErrors={state.showValidationErrors}
            dataQueries={state.dataQueries}
            crfId={state.crfId}
            crfType={crfTypeToUse}
            studyId={state.studyId}
            onExit={onExit}
            onConfirm={onConfirm}
            crfProcId={procedure.procedureId}
          />

          {state.showValidationErrors && (
            <Alert color={"danger"}>
              Please fix the validation errors in order to save the CRF
            </Alert>
          )}
          <ButtonBar buttons={getButtons()} />
        </CardBody>
      </Card>
    );
  };

  if (renderAsModal === true) {
    return renderModal();
  } else {
    return renderCard();
  }
};

Crf.propTypes = {
  procedure: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  existing: PropTypes.object,
  readOnly: PropTypes.bool,
  preSelectedType: PropTypes.object,
  renderAsModal: PropTypes.bool,
};

export default Crf;

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { FaEdit } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import SimpleLogoHeader from "../common/SimpleLogoHeader";
import scrollToComponent from "react-scroll-to-component";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const scrollPoint = useRef();

  useEffect(() => {
    scrollToComponent(scrollPoint.current, {
      align: "top",
      duration: 500,
      registerClick: false,
      retryClick: false,
    });
  }, []);

  const generateTile = (index, to, icon, title, text) => {
    return (
      <Button
        color="primary"
        className={"landing-tile"}
        outline
        tabIndex={index}
        onClick={() => navigate(to)}
      >
        <Row>
          <Col xs={3} sm={3} md={3} lg={2} xl={2}>
            {icon}
          </Col>
          <Col xs={9} sm={9} md={9} lg={10} xl={10} className={"right"}>
            <div className={"title"}>{title}</div>
            <div className={"text"}>{text}</div>
          </Col>
        </Row>
      </Button>
    );
  };

  return (
    <div className="patient-landing">
      <SimpleLogoHeader link={true} />
      <Container>
        <div ref={scrollPoint} />
        <Card inverse color="primary">
          <CardBody>
            <div className={"not-found-text"}>
              <p>
                From the information you provided we could not locate you in the
                system.
              </p>
              <p>
                If this is your first time using this site then please select
                the{" "}
                <i>
                  <b>REGISTER</b>
                </i>{" "}
                button below to continue.
              </p>
              <p>
                If you have previously registered and logged in to the system,
                please select the{" "}
                <i>
                  <b>RETRY</b>
                </i>{" "}
                button and ensure that you enter your information exactly as you
                did when you first registered.
              </p>
              <p>
                If you would like assistance, please{" "}
                <a href="/#contact">contact the AOANJRR project coordinator</a>.
              </p>
            </div>
          </CardBody>
        </Card>
        <div className={"buttons"}>
          <div className={"middle"}>
            {generateTile(
              1,
              "/patient/register/state",
              <FaEdit className={"icon"} />,
              "Register",
              "Click here to register your pre-operation survey.",
            )}
            {generateTile(
              2,
              "/patient/logon/firstname",
              <TiArrowBack className={"icon"} />,
              "Retry",
              "Click here and we'll try to find you again.",
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;

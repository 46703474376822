import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "reactstrap";
import { TiAnchor } from "react-icons/ti";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const LargeNavButton = (props) => {
  const {
    to = "/",
    tabIndex = -1,
    icon = <TiAnchor />,
    title = "Generic Button",
    subtitle = "",
    onClick = () => null,
    outline = true,
    extraPadding = false,
    standout = false,
    columnPadding = true,
  } = props;

  const [outlined, setOutlined] = useState(outline);
  const navigate = useNavigate();

  const clickHandler = () => {
    setOutlined(false);
    setTimeout(() => {
      if (!!onClick) {
        onClick();
      }
      if (!!to) {
        navigate(to);
      }
    }, 100);
    setOutlined(true);
  };

  return (
    <div className={"large-nav-button"}>
      <Row>
        {columnPadding && <Col xs={"1"} />}
        <Col xs={columnPadding ? "10" : "12"}>
          <Button
            className={"title"}
            outline={outlined && !standout}
            color="primary"
            tabIndex={tabIndex}
            onClick={clickHandler}
            style={{
              paddingTop: extraPadding ? "3em" : "inherit",
              paddingBottom: extraPadding ? "3em" : "inherit",
            }}
          >
            {standout && (
              <span className={"pulsate"}>
                <FaAngleDoubleRight />
              </span>
            )}
            {icon} <span className={"aoa-title"}> {title}</span>
            {standout && (
              <span className={"pulsate"}>
                <FaAngleDoubleLeft />
              </span>
            )}
            <div className={"subtitle"}>{subtitle}</div>
          </Button>
        </Col>
        {columnPadding && <Col xs={"1"} />}
      </Row>
    </div>
  );
};

LargeNavButton.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tabIndex: PropTypes.number,
  icon: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  standout: PropTypes.bool,
  extraPadding: PropTypes.bool,
  columnPadding: PropTypes.bool,
};

export default LargeNavButton;

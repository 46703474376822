import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { loadStudies } from "../../actions/CommonActions";
import Select from "react-select";
import { FaEdit, FaPlusSquare, FaTrash } from "react-icons/fa";
import ConfigureReport from "./ConfigureReport";
import "./Configuration.css";
import { connect } from "react-redux";
import {
  deleteReport,
  loadAllPositions,
  loadAllRoles,
  loadAllText,
  loadGraphTypes,
  loadInstrumentBins,
  loadInstrumentReports,
  loadSubscoreTypes,
  newReport,
  reportIndexChange,
  switchReportRole,
  updateReport,
} from "../../actions/InstrumentReportActions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  getDragAndDropItemStyle,
  getDragAndDropListStyle,
  jsonEquals,
} from "../../Utils";

const EMPTY_FIELD = "...";

class Configuration extends Component {
  static DUMMY_PATIENT_ROLE = {
    id: null,
    name: "Patient",
    index: null,
    roleId: 0,
    reportId: null,
  };

  state = {
    role: Configuration.DUMMY_PATIENT_ROLE,
    report: null,
  };

  constructor(props) {
    super(props);
    if (props.studies.length === 0) {
      props.loadStudies();
    }
    if (props.reports.length === 0) {
      props.loadInstrumentReports();
    }
    if (props.bins.length === 0) {
      props.loadInstrumentBins();
    }
    if (props.seriesTypes.length === 0) {
      props.loadGraphTypes();
    }
    if (props.subscores.length === 0) {
      props.loadSubscoreTypes();
    }
    if (props.roles.length === 0) {
      props.loadAllRoles();
    }
    if (props.texts.length === 0) {
      props.loadAllText();
    }
    if (props.positions.length === 0) {
      props.loadAllPositions();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!this.props.reports && !!prevState.report) {
      const report = this.props.reports.filter(
        (r) => r.id === prevState.report.id,
      )[0];
      if (!jsonEquals(report, prevState.report)) {
        this.setState({ report });
      }
    }
    if (!!this.props.roles && !this.state.role) {
      this.setState({ role: this.props.roles[0] });
    }
  }

  selectReport = (report) => {
    this.setState({ report });
  };

  selectRole = (role) => {
    const { switchReportRole } = this.props;
    switchReportRole(role);
  };

  createNewReport = () => {
    this.props.newReport({ index: this.props.reports.length });
  };

  onDragEnd = ({ draggableId, source, destination }) => {
    const { reports, reportIndexChange, viewingRole } = this.props;
    let srcReport = reports[source.index];
    let oldIndex = reports
      .filter((r) => "configuration-report-" + r.id === draggableId)[0]
      .roles.filter((rr) => rr.roleId === viewingRole.roleId)[0].index;
    if (!!destination && oldIndex !== destination.index) {
      reportIndexChange({ report: srcReport, newIndex: destination.index });
    }
  };

  render() {
    const { reports, roles, deleteReport, viewingRole } = this.props;
    const { report } = this.state;

    if (!reports) {
      return null;
    }

    return (
      <Container className="report-configuration">
        <h2 className="container-title">Configure Comparison Reports</h2>
        <Card>
          <CardBody>
            <Row>
              <Col xs={6} className={"text-end"}>
                Reports for Role:
              </Col>
              <Col xs={6}>
                <Select
                  options={roles}
                  value={viewingRole}
                  onChange={this.selectRole}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Patient"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
                />
              </Col>
            </Row>
            <Row>
              <Table
                size="sm"
                bordered
                striped
                responsive
                className="report-table"
              >
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <DragDropContext
                  onDragEnd={this.onDragEnd}
                  onDragStart={this.onDragStart}
                >
                  <Droppable droppableId="droppable">
                    {(droppableProvided, droppableSnapshot) => (
                      <tbody
                        ref={droppableProvided.innerRef}
                        style={getDragAndDropListStyle(
                          droppableSnapshot.isDraggingOver,
                        )}
                      >
                        {reports.map((report, i) => {
                          return (
                            <Draggable
                              key={"configuration-report-" + report.id}
                              draggableId={"configuration-report-" + report.id}
                              index={i}
                            >
                              {(draggableProvided, draggableSnapshot) => (
                                <tr
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  style={getDragAndDropItemStyle(
                                    draggableSnapshot.isDragging,
                                    draggableProvided.draggableProps.style,
                                  )}
                                >
                                  <td {...draggableProvided.dragHandleProps}>
                                    {report.id || EMPTY_FIELD}
                                  </td>
                                  <td {...draggableProvided.dragHandleProps}>
                                    {report.name || EMPTY_FIELD}
                                  </td>
                                  <td
                                    {...draggableProvided.dragHandleProps}
                                    className="no-overflow"
                                  >
                                    {report.description || EMPTY_FIELD}
                                  </td>
                                  <td>
                                    <div className="action-buttons">
                                      <FaEdit
                                        onClick={() =>
                                          this.selectReport(report)
                                        }
                                      />
                                      <FaTrash
                                        onClick={() =>
                                          deleteReport({ report: report })
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          );
                        })}
                        {droppableProvided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <span className="add-button">Add Report</span>
                <FaPlusSquare onClick={this.createNewReport} />
              </div>
            </Row>
            {!!report && <ConfigureReport report={report} />}
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    studies: state.studies,
    ...state.instrumentReports,
  }),
  {
    loadInstrumentReports: loadInstrumentReports,
    loadStudies: loadStudies,
    loadInstrumentBins: loadInstrumentBins,
    loadGraphTypes: loadGraphTypes,
    loadSubscoreTypes: loadSubscoreTypes,
    loadAllRoles: loadAllRoles,
    loadAllText: loadAllText,
    reportIndexChange: reportIndexChange,
    updateReport: updateReport,
    newReport: newReport,
    deleteReport: deleteReport,
    loadAllPositions: loadAllPositions,
    switchReportRole: switchReportRole,
  },
)(Configuration);
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import Moment from "moment";
import "./PhoneCalls.css";
import {
  declinePatientOnTheirBehalf,
  getCurrentAndPendingConsents,
} from "../../../api/Patient";
import { useDispatch, useSelector } from "react-redux";
import {
  finishLoading,
  loadStudies,
  startLoading,
} from "../../../actions/CommonActions";
import { isAoaUser } from "../../../Utils";
import "./ConsentTab.css";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import { useEffect, useReducer } from "react";

const ConsentTab = ({ patientId, patientPurgedCallback }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const studies = useSelector((state) => state.studies);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      consented: [],
      pending: [],
      decliningStudyId: null,
    },
  );

  useEffect(() => {
    studies.length === 0 && dispatch(loadStudies());
  }, [dispatch]);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    if (patientId != null) {
      getCurrentAndPendingConsents(patientId).then((response) => {
        setState({
          consented: response.data.filter((item) => item.dateConsented != null),
          pending: response.data.filter((item) => item.dateConsented == null),
        });
      });
    }
  };

  const decline = (decliningStudyId) => {
    dispatch(startLoading());
    if (decliningStudyId != null) {
      declinePatientOnTheirBehalf(patientId, decliningStudyId)
        .then((response) => {
          if (response.data.success === true) {
            setState({ decliningStudyId: null });
            refreshData();
            patientPurgedCallback("Study declined successfully");
            if (response.data.patientPurged === true) {
              toast.success(
                <span>
                  <FaCheck /> Patient removed as they have declined all
                  procedures
                </span>,
              );
            }
          } else {
            toast.error(
              `${response.data.message}. If declining multiple studies, decline any child studies first.`,
            );
          }
        })
        .finally(() => dispatch(finishLoading()));
    }
  };

  const getStudyNameFromId = (studyId) => {
    let index = studies.findIndex((study) => study.id === studyId);
    if (index < 0) {
      return "";
    } else {
      return studies[index].name;
    }
  };

  const getActionButtons = (studyId) => {
    return (
      <span className={"action-buttons pr-3"}>
        <Button
          color={"danger"}
          title={"Decline the patient from this study on their behalf"}
          onClick={() => setState({ decliningStudyId: studyId })}
        >
          Decline
        </Button>
      </span>
    );
  };

  const getConsentedTable = (consented) => {
    return (
      <Table
        striped
        bordered
        size={"sm"}
        responsive
        className={"consented-table"}
      >
        <thead>
          <tr>
            <th>Study</th>
            <th>Date Consented</th>
            <th>Consented By</th>
          </tr>
        </thead>
        <tbody>
          {consented.length > 0 &&
            consented.map((item, index) => {
              return (
                <tr key={`consented-table-tr-${index}`}>
                  <td>{getStudyNameFromId(item.studyId)}</td>
                  <td
                    title={Moment(item.dateConsented).format(
                      "DD/MM/YYYY @ h:mm A",
                    )}
                  >
                    {Moment(item.dateConsented).format("DD/MM/YYYY")}
                  </td>
                  <td>{item.consentedBy}</td>
                </tr>
              );
            })}
          {consented.length === 0 && (
            <tr>
              <td colSpan={3}>
                <i>None</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  const getPendingTable = (pending) => {
    return (
      <Table
        striped
        bordered
        size={"sm"}
        responsive
        className={"pending-table"}
      >
        <thead>
          <tr>
            <th>Study</th>
            {isAoaUser(user) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {pending.length > 0 &&
            pending.map((item, index) => {
              return (
                <tr key={`consented-table-tr-${index}`}>
                  <td>{getStudyNameFromId(item.studyId)}</td>
                  {isAoaUser(user) && <td>{getActionButtons(item.studyId)}</td>}
                </tr>
              );
            })}
          {pending.length === 0 && (
            <tr>
              <td colSpan={isAoaUser(user) ? 2 : 1}>
                <i>None</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  const getConfirmModal = (decliningStudyId) => {
    let studyName = getStudyNameFromId(decliningStudyId);
    let header = `Confirm decline from ${studyName}`;
    return (
      <Modal isOpen={true}>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>
          Are you sure you want to decline {studyName} on behalf of the patient?
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }}>
          <Button
            color={"secondary"}
            onClick={() => setState({ decliningStudyId: null })}
          >
            Cancel
          </Button>
          <Button color={"danger"} onClick={() => decline(decliningStudyId)}>
            Decline
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <div className={"consent-tab"}>
      <h6 className={"mt-3"}>Consented</h6>
      {getConsentedTable(state.consented)}
      <h6 className={"mt-3"}>Pending</h6>
      {getPendingTable(state.pending)}
      {state.decliningStudyId != null &&
        getConfirmModal(state.decliningStudyId)}
    </div>
  );
};

ConsentTab.propTypes = {
  patientId: PropTypes.number.isRequired,
  patientPurgedCallback: PropTypes.func.isRequired,
};

export default ConsentTab;

import { Fragment, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Input,
  InputGroup,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleRoleSelector from "../../common/SimpleRoleSelector";
import Pagination from "react-js-pagination";
import { searchUsers } from "../../../api/User";
import { FaBan, FaEdit, FaLock } from "react-icons/fa";
import { SiMicrosoftazure } from "react-icons/si";
import { MdPageview } from "react-icons/md";
import "./Search.css";
import Responsive from "react-responsive";
import { isEmptyOrNull } from "../../../Utils";
import Select from "react-select";
import { loadHospitals } from "../../../actions/CommonActions";
import { getReportableStudies } from "../../reporting/Utils";
import MyStudiesSelector, { ALL_STUDIES } from "../../common/MyStudiesSelector";
import { handleEnterKeyPress } from "../../../Utils";

const Search = ({
  itemsCountPerPage = 10,
  pageRangeDisplayed = 5,
  resultsVersion = 0,
  editCallback,
  viewCallback,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const studies = useSelector((state) => state.studies);
  const hospitals = useSelector((state) => state.hospitals);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedRole: null,
      totalItemsCount: 0,
      activePage: 1,
      selectedUser: null,
      selectedHospital: null,
      users: [],
      version: 0,
      searchFilter: "",
      selectedStudy: ALL_STUDIES,
    },
  );

  useEffect(() => {
    hospitals.length === 0 && dispatch(loadHospitals());
  }, [dispatch]);

  useEffect(() => {
    resultsVersion > state.version && setState({ version: resultsVersion });
  }, [resultsVersion, state.version]);

  useEffect(() => {
    loadUsers();
  }, [state.version, state.activePage]);

  const loadUsers = () => {
    let surgeonCode = null;
    let email = null;
    let name = null;
    if (!!state.searchFilter && !isNaN(state.searchFilter)) {
      surgeonCode = Number(state.searchFilter);
    }
    if (!!state.searchFilter && isNaN(state.searchFilter)) {
      if (state.searchFilter.includes("@")) email = state.searchFilter;
      else name = state.searchFilter;
    } else name = state.searchFilter;
    let payload = {
      page: state.activePage - 1,
      size: itemsCountPerPage,
      email: email,
      name: name,
      hospitalId: !!state.selectedHospital ? state.selectedHospital.id : null,
      roleIds: state.selectedRole ? state.selectedRole.id : null,
      surgeonCode: surgeonCode,
      studyId: state.selectedStudy.id,
    };

    searchUsers(payload).then((response) => {
      setState({
        users: response.data.content,
        activePage: response.data.number + 1,
        totalItemsCount: response.data.totalElements,
      });
    });
  };

  const clearSearch = () => {
    setState({ searchFilter: "", activePage: 0 });
  };

  const handleRoleSelection = (value) => {
    setState({ selectedRole: value, activePage: 0 });
  };

  const handleHospitalSelection = (value) => {
    setState({ selectedHospital: value, activePage: 0 });
  };

  const handleSearchFilter = (event) => {
    setState({ searchFilter: event.target.value });
  };

  const studyChanged = (study) => {
    setState({ activePage: 0, selectedStudy: study });
  };

  const handleSearchButton = () => {
    setState({ activePage: 0 });
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  const getActionButtons = (_user) => {
    let editView = !!_user.canEdit ? (
      <Button
        color={"primary"}
        outline
        onClick={() => editCallback(_user)}
        title={"Edit User"}
      >
        <FaEdit className={"action-icon"} />
      </Button>
    ) : (
      <Button
        color={"primary"}
        outline
        onClick={() => viewCallback(_user)}
        title={"View User"}
      >
        <MdPageview className={"action-icon"} />
      </Button>
    );

    return (
      <div className={"action-icons"}>
        {["AOA", "SAHMRI", "Administrator"].includes(_user.role) && (
          <SiMicrosoftazure
            size={"0.6em"}
            className={"azure"}
            title={"Azure Account"}
          />
        )}
        {_user.dateDisabled && (
          <Fragment>
            <FaBan
              size={"0.6em"}
              id={"account-disabled"}
              className={"alert-icon"}
              title={"Account is disabled"}
            />
            {["AOA", "SAHMRI", "Administrator"].includes(_user.role) && (
              <UncontrolledTooltip placement="top" target="account-disabled">
                {" "}
                This account can only be re-enabled via Azure
              </UncontrolledTooltip>
            )}
          </Fragment>
        )}
        {_user.dateLocked && (
          <FaLock
            size={"0.6em"}
            className={"alert-icon"}
            title={"Account is locked"}
          />
        )}
        {editView}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <Fragment>
        <Responsive maxWidth={559}>
          <Table size={"sm"} bordered striped responsive>
            <tbody>
              {state.users.map((u, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Row className={"g-0"}>
                        <Col xs={3} className={"td-label"}>
                          Name:
                        </Col>
                        <Col xs={9}>
                          {" "}
                          {u.firstName} {u.lastName}
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={3} className={"td-label"}>
                          Email:
                        </Col>
                        <Col xs={9}> {u.email}</Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col xs={3} className={"td-label"}>
                          Role:
                        </Col>
                        <Col xs={9}> {u.role}</Col>
                      </Row>
                      {!!u.surgeonCode && (
                        <Row className={"g-0"}>
                          <Col xs={3} className={"td-label"}>
                            SurgeonCode:
                          </Col>{" "}
                          <Col xs={9}>{u.surgeonCode}</Col>
                        </Row>
                      )}
                      <Row className={"g-0"}>{getActionButtons(u)}</Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={560} maxWidth={767}>
          <Table size={"sm"} bordered striped responsive>
            <tbody>
              {state.users.map((u, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Row className={"g-0"}>
                        <Col sm={6}>
                          <span className={"td-label"}>Name: </span>
                          {u.firstName} {u.lastName}
                        </Col>
                        <Col sm={6}>
                          <span className={"td-label"}>Email: </span>
                          {u.email}
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col sm={6}>
                          <span className={"td-label"}>Role: </span>
                          {u.roleName}
                        </Col>
                        <Col sm={6}>
                          <span className={"td-label"}>Surgeon Code: </span>
                          {u.surgeonCode}
                        </Col>
                      </Row>
                      <Row className={"g-0"}>
                        <Col sm={12}>{getActionButtons(u)}</Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
        <Responsive minWidth={768}>
          <Table size={"sm"} bordered striped responsive>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Surgeon Code</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {state.users.map((u, i) => {
                return (
                  <tr key={i}>
                    <td>{u.firstName}</td>
                    <td>{u.lastName}</td>
                    <td>{u.email}</td>
                    <td>{u.surgeonCode}</td>
                    <td>{u.role}</td>
                    <td>{getActionButtons(u)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Responsive>
      </Fragment>
    );
  };

  return (
    <div className={"user-search"}>
      <Row className={"tools"}>
        <Col md={6} xs={12}>
          <SimpleRoleSelector
            value={state.selectedRole}
            onChange={handleRoleSelection}
            clearable={true}
            showReadable={true}
            showWriteable={false}
            placeholder="Filter by role..."
          />
        </Col>
        <Col md={6} xs={12}>
          <MyStudiesSelector
            options={getReportableStudies(
              studies,
              user,
              state.selectedHospital,
            )}
            value={state.selectedStudy}
            useAllStudies={true}
            onChange={(value) => studyChanged(value)}
          />
        </Col>
      </Row>
      <Row className={"tools"}>
        <Col md={6} xs={12}>
          <Select
            id="hospital"
            placeholder="Choose Hospital..."
            value={state.selectedHospital}
            options={hospitals}
            getOptionLabel={(option) =>
              `${option.name} (${option.state.shortName})`
            }
            getOptionValue={(option) => option.id}
            onChange={handleHospitalSelection}
            styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            isClearable={true}
          />
        </Col>
        <Col md={6} xs={12}>
          <InputGroup>
            <Input
              type="text"
              bsSize="small"
              placeholder="Search users..."
              value={state.searchFilter}
              onChange={(e) => handleSearchFilter(e)}
              onBlur={() => handleSearchButton()}
              className={"search-term-input"}
              onKeyPress={(event) =>
                handleEnterKeyPress(event, handleSearchButton)
              }
              style={{ paddingRight: "35px" }}
            />
            {!isEmptyOrNull(state.searchFilter) && (
              <a
                style={{
                  position: "absolute",
                  right: "60px",
                  bottom: "8px",
                  zIndex: 1000,
                  cursor: "pointer",
                }}
                onClick={() => clearSearch()}
              >
                🗙
              </a>
            )}
            <Button
              onClick={() => handleSearchButton()}
              style={{ border: "1px solid lightgray" }}
              outline
            >
              🔍
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className={"user-table"}>{renderTable()}</Row>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Pagination
          activePage={state.activePage}
          totalItemsCount={state.totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={changePage}
          hideDisabled
          innerClass={"pagination pagination-sm"}
          itemClass={"page-item"}
          linkClass={"page-link"}
        />
      </div>
    </div>
  );
};

Search.propTypes = {
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  resultsVersion: PropTypes.number,
  editCallback: PropTypes.func,
  viewCallback: PropTypes.func,
};

export default Search;

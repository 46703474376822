import { FormGroup, Input, Label, Tooltip } from "reactstrap";
import { FaQuestionCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import "./Reporting.css";
import { useState } from "react";

const ReportingCheckbox = ({
  checked = false,
  callback = () => null,
  id,
  tooltip,
  label,
}) => {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <FormGroup check style={{ marginBottom: "1em" }}>
      <Label check>
        <Input type="checkbox" checked={checked} onChange={callback} /> {label}
      </Label>
      <Tooltip
        placement="top"
        isOpen={showToolTip}
        target={`${id}-tooltip`}
        toggle={() => setShowToolTip(!showToolTip)}
      >
        {tooltip}
      </Tooltip>
      <span id={`${id}-tooltip`} style={{ cursor: "pointer" }}>
        <FaQuestionCircle className={"tooltip-icon"} />
      </span>
    </FormGroup>
  );
};

ReportingCheckbox.propTypes = {
  checked: PropTypes.bool,
  callback: PropTypes.func,
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  label: PropTypes.string,
};

export default ReportingCheckbox;

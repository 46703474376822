import { del, get, post, put } from "./Api";

const BASE_URL = "/api/crf";

function url(path) {
  return `${BASE_URL}/${path}`;
}

export function getCrfProcedureStudies(patientId) {
  return get(
    url(`procedure-studies?patientId=${patientId}`),
    null,
    "Unable to get CRF Procedure Studies at this time.",
  );
}

export function createCrf(request) {
  return post(url(`create`), request, "Unable to create CRF at this time.");
}

export function getCrfs(procedureId, studyId) {
  return get(
    url(`all?procedureId=${procedureId}&studyId=${studyId}`),
    null,
    "Unable to get existing CRFs at this time.",
  );
}

export function deleteCrf(crfId) {
  return del(url(`${crfId}`), "Unable to delete CRF at this time.");
}

export function updateCrf(crfId, payload) {
  return put(url(`${crfId}`), payload, "Unable to update CRF at this time.");
}

export function getOutstanding(procedureId, studyId) {
  return get(
    url(`outstanding?procedureId=${procedureId}&studyId=${studyId}`),
    null,
    "Unable to get outstanding CRFs at this time.",
  );
}

export function getSurgeonCrfForm(procedureId, crfTypeId) {
  return get(
    `${BASE_URL}/surgeon-form?procedureId=${procedureId}&crfTypeId=${crfTypeId}`,
    null,
    "Unable to fetch CRF at this time.",
  );
}

export function getDataQueriesForCrf(crfId) {
  return get(
    `${BASE_URL}/data-queries/${crfId}`,
    null,
    "Unable to fetch data queries for CRF at this time.",
  );
}

import { get, post, put } from "./Api";

const BASE_URL = "/api/user";

export function activateUser(request) {
  return post(
    `${BASE_URL}/activate`,
    request,
    "Unable to activate user at this time.",
  );
}

export function checkToken(request) {
  return get(
    `${BASE_URL}/check-token`,
    request,
    "Unable to verify token at this time.",
  );
}

export function logon(request) {
  return post(`${BASE_URL}/logon`, request, "Unable to logon at this time.");
}

export function logout() {
  return post(
    `${BASE_URL}/logout`,
    null,
    "An error occurred while logging out.",
  );
}

export function createUser(request) {
  return post(
    `${BASE_URL}/create`,
    request,
    "Unable to create user at this time.",
  );
}

// This function handles an optional callback. This allows for more fine grained chaining of processes.
export function currentUser(callback) {
  return get(`${BASE_URL}/current`).then((response) =>
    callback ? callback(response) : response,
  );
}

export function searchUsers(request) {
  return get(
    `${BASE_URL}/search`,
    request,
    "Unable to search users at this time.",
  );
}

export function getUser(userId) {
  return get(
    `${BASE_URL}/${userId}`,
    null,
    "Unable to load user at this time.",
  );
}

export function updateUser(userId, request) {
  return put(
    `${BASE_URL}/${userId}`,
    request,
    "Unable to update user at this time.",
  );
}

export function resetPassword(userId) {
  return post(
    `${BASE_URL}/${userId}/reset-password`,
    null,
    "An error occurred resetting the password.",
  );
}

export function disableAccount(userId, request) {
  return post(
    `${BASE_URL}/${userId}/disable`,
    request,
    "An error occurred disabling the account.",
  );
}

export function enableAccount(userId) {
  return post(
    `${BASE_URL}/${userId}/enable`,
    null,
    "An error occurred enabling the account.",
  );
}

export function unlockAccount(userId) {
  return post(
    `${BASE_URL}/${userId}/unlock`,
    null,
    "An error occurred unlocking the account.",
  );
}

export function resetPasswordByUser(request) {
  return post(
    `${BASE_URL}/reset-password`,
    request,
    "Unable to reset password.",
  );
}

export function sendMobileConfirmationSms(mobile) {
  return post(
    `${BASE_URL}/send-mobile-confirmation-sms?mobile=${mobile}`,
    null,
    "Unable to send SMS at this time.",
  );
}

export function confirmMobileWithCode(payload) {
  return post(
    `${BASE_URL}/confirm-mobile-with-code`,
    payload,
    "Unable to confirm mobile number.",
  );
}

export function userLogonType(username) {
  return get(
    `${BASE_URL}/get-user-logon-type?username=${username}`,
    null,
    "Unable to verify user type at this time.",
  );
}

export function sendUserSms(payload) {
  return post(`${BASE_URL}/send-mfa-code`, payload);
}

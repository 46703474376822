import { Col, Container, Row } from "reactstrap";
import LargeNavButton from "./LargeNavButton";
import {
  FaChartArea,
  FaChartBar,
  FaMedkit,
  FaUpload,
  FaWheelchair,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Tasks from "../task/Tasks";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { loadUserCurrent } from "../../actions/UserActions";

const HospitalAdministratorHome = ({
  showReporting = false,
  showResults = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadUserCurrent());
  }, [dispatch]);

  return (
    <Container>
      <Row>
        <Col xs={"1"} />
        <Col xs={10} className={"welcome-text aoa-title"}>
          Welcome {user.found ? user.firstName : ""}
        </Col>
        <Col xs={"1"} />
      </Row>
      <Tasks />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <LargeNavButton
            to={"/patient/enrolment"}
            icon={<FaWheelchair />}
            title={"Patient Enrolment"}
            subtitle={"Click here to enrol a new patient into the system"}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <LargeNavButton
            to={"/patient/management"}
            icon={<FaMedkit />}
            title={"Existing Patients"}
            subtitle={
              "Click here to manage existing patients already in the system"
            }
          />
        </Col>
      </Row>
      {showReporting && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <LargeNavButton
              to={"/reporting"}
              icon={<FaChartBar />}
              title={"Reporting"}
              subtitle={"Click here to view recruitment data"}
            />
          </Col>
        </Row>
      )}
      {showResults && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <LargeNavButton
              to={"/compare"}
              icon={<FaChartArea />}
              title={"How Do My Patients Compare"}
              subtitle={
                "Click here to review patients enrolled at your hospital in PROMs compared to National Results"
              }
            />
          </Col>
        </Row>
      )}
      {user.uploader && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <LargeNavButton
              to={"/uploads"}
              icon={<FaUpload />}
              title={"Upload Patient Responses"}
              subtitle={
                "Click here to upload patient responses you have collected externally"
              }
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

HospitalAdministratorHome.propTypes = {
  showReporting: PropTypes.bool,
};

export default HospitalAdministratorHome;

import { Fragment } from "react";
import { Col, Row } from "reactstrap";
import Moment from "moment";
import * as PatientUtils from "./Utils";
import { newProcedureFieldsToExclude } from "./Utils";
import { getProcedureJointFromProcedureType } from "../../Utils";

const Review = ({ patient }) => {
  const renderPatientDetails = () => {
    const name = [
      patient.firstName,
      patient.middleNames,
      patient.lastName,
    ].join(" ");
    let dob;
    // If a brand-new patient this will be true
    if (patient.dateOfBirth == null) {
      dob = Moment(
        patient.year + patient.month + patient.day,
        "YYYYMMMMDo",
      ).format("Do [of] MMMM[,] YYYY");
    } else {
      dob = Moment(patient.dateOfBirth).format("Do [of] MMMM[,] YYYY");
    }

    return (
      <div className={"detail-line"}>
        <div>
          You, <span className={"standout"}>{name}</span>, were born on the{" "}
          <span className={"standout"}>{dob}</span> and reside in the{" "}
          <span className={"standout"}>{patient.postcode}</span> postcode.
        </div>
      </div>
    );
  };

  const renderProcedureJoints = (newProcedure) => {
    return (
      <ul>
        {Object.keys(newProcedure).map((key) => {
          if (!newProcedureFieldsToExclude.includes(key) && newProcedure[key]) {
            return <li key={key}>{getProcedureJointFromProcedureType(key)}</li>;
          }
        })}
      </ul>
    );
  };

  const renderProcedureDetails = () => {
    const { newProcedure } = patient;
    if (!PatientUtils.hasProcedureDetails(newProcedure)) {
      return null;
    }
    const surgeon =
      newProcedure.surgeon && newProcedure.surgeon.id !== 0
        ? [
            "Dr ",
            newProcedure.surgeon.firstName,
            newProcedure.surgeon.lastName,
          ].join(" ")
        : null;
    return (
      <div className={"detail-line"}>
        <div>
          {surgeon && (
            <Fragment>
              <span className={"standout"}> {surgeon}</span> will operate on
              your
            </Fragment>
          )}
          {!surgeon && (
            <Fragment>You will be having these joints operated on</Fragment>
          )}
        </div>
        <div>{renderProcedureJoints(newProcedure)}</div>
        <div>
          at the{" "}
          <span className={"standout"}>{newProcedure.hospital.name}</span> in{" "}
          <span className={"standout"}>
            {" "}
            {newProcedure.hospital.state.name}
          </span>
          .
        </div>
      </div>
    );
  };

  const renderContactDetails = () => {
    const { homePhone, mobilePhone, email } = patient;
    if (!PatientUtils.patientHasContact(patient)) {
      return null;
    }
    return (
      <div className={"detail-line"} style={{ textAlign: "left" }}>
        We will remind you of studies to complete via
        <ul>
          {/*These tags prevent MS Edge from automatically linking them as phone numbers*/}
          {homePhone && (
            <li x-ms-format-detection="none">
              phone on <span className={"standout"}>{homePhone}</span>
            </li>
          )}
          {mobilePhone && (
            <li x-ms-format-detection="none">
              mobile on <span className={"standout"}>{mobilePhone}</span>
            </li>
          )}
          {email && (
            <li>
              email at <span className={"standout"}>{email}</span>
            </li>
          )}
        </ul>
      </div>
    );
  };

  return (
    <div className={"review"}>
      <Row>
        <Col xs={0} sm={1} md={1} lg={2} xl={3} />
        <Col className={"content"}>
          {renderPatientDetails()}
          {renderContactDetails()}
          {renderProcedureDetails()}
        </Col>
        <Col xs={0} sm={1} md={1} lg={2} xl={3} />
      </Row>
      <div className={"instruction-line"}>
        Please carefully review your details listed above. If they are correct,
        please select the <i>SUBMIT</i> button to continue. If anything is
        incorrect you may select the <i>PREVIOUS</i> button to correct any
        details.
      </div>
    </div>
  );
};

export default Review;

import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import NavButton from "./NavButton";
import {
  FaChartArea,
  FaChartBar,
  FaClipboardCheck,
  FaDownload,
  FaMedkit,
  FaQuestion,
  FaRegIdCard,
  FaSignOutAlt,
  FaUpload,
  FaWheelchair,
} from "react-icons/fa";
import { Navigate, Route, Routes } from "react-router-dom";
import SurgeonHome from "./SurgeonHome";
import Enrolment from "../patient/Enrolment";
import Patients from "../management/patient/PatientManagement";
import Reporting from "../reporting/Reporting";
import NonPatientView from "../compare/NonPatientView";
import Uploads from "../uploads/Uploads";
import SurgeonDownload from "../surgeon/SurgeonDownload";
import SurgeonQP from "../surgeon/SurgeonQP";
import Tasks from "../task/Tasks";
import SurgeonCrf from "../surgeon/SurgeonCrf";
import { PageTracking } from "../app/PageTracking";

export const Surgeon = (
  menuOpen,
  toggleMenuCb,
  userLogoutCb,
  helpToggleCallback,
  showResults,
  showReporting,
  showUploads,
) => {
  const menu = (sidebarMenu, showHelpAndLogout) => {
    return (
      <Navbar
        className={`vertical-menu ${sidebarMenu ? "header" : ""}`}
        light
        expand={!sidebarMenu}
      >
        <NavbarToggler onClick={toggleMenuCb} children={<span>MENU</span>} />
        <Collapse isOpen={sidebarMenu ? menuOpen : false} navbar>
          <Nav className="me-auto  vert-menu-holder" vertical>
            <NavButton
              to={"/surgeon/QP"}
              icon={<FaRegIdCard />}
              text={"Qualified Privilege"}
              onClick={toggleMenuCb}
            />
            <NavButton
              to={"/patient/enrolment"}
              icon={<FaWheelchair />}
              text={"Patient Enrolment"}
              onClick={toggleMenuCb}
            />
            <NavButton
              to={"/patient/management"}
              icon={<FaMedkit />}
              text={"Existing Patients"}
              onClick={toggleMenuCb}
            />
            <NavButton
              to={"/surgeon/download"}
              icon={<FaDownload />}
              text={"Surgeon Downloads"}
              onClick={toggleMenuCb}
            />
            <NavButton
              to={"/tasks"}
              icon={<FaClipboardCheck />}
              text={"Tasks"}
              onClick={toggleMenuCb}
            />
            {showReporting && (
              <NavButton
                to={"/reporting"}
                icon={<FaChartBar />}
                text={"Reporting"}
                onClick={toggleMenuCb}
              />
            )}
            {showResults && (
              <NavButton
                to={"/compare"}
                icon={<FaChartArea />}
                text={"Compare Results"}
                onClick={toggleMenuCb}
              />
            )}
            {showUploads && (
              <NavButton
                to={"/uploads"}
                icon={<FaUpload />}
                text={"Response Uploads"}
                onClick={toggleMenuCb}
              />
            )}
            {showHelpAndLogout && (
              <>
                <hr />
                <NavButton
                  to={null}
                  icon={<FaQuestion className={"icon help"} />}
                  text={"Help"}
                  onClick={helpToggleCallback}
                />
                <NavButton
                  to={"/administration"}
                  icon={<FaSignOutAlt className={"icon"} />}
                  text={"Logout"}
                  onClick={userLogoutCb}
                />
              </>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    );
  };

  const switchRoute = (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <PageTracking>
            <SurgeonHome
              showResults={showResults}
              showReporting={showReporting}
            />
          </PageTracking>
        }
      />
      <Route
        path="/patient/enrolment"
        element={
          <PageTracking>
            <Enrolment />
          </PageTracking>
        }
      />
      <Route
        path="/patient/management"
        element={
          <PageTracking>
            <Patients />
          </PageTracking>
        }
      />
      <Route
        path="/reporting"
        element={
          <PageTracking>
            <Reporting />
          </PageTracking>
        }
      />
      <Route
        path="/compare"
        element={
          <PageTracking>
            <NonPatientView />
          </PageTracking>
        }
      />
      <Route
        path="/tasks"
        element={
          <PageTracking>
            <Tasks />
          </PageTracking>
        }
      />
      <Route
        path="/uploads"
        element={
          <PageTracking>
            <Uploads />
          </PageTracking>
        }
      />
      <Route
        path="/surgeon/crf/:procedureId/:crfTypeId"
        element={
          <PageTracking>
            <SurgeonCrf />
          </PageTracking>
        }
      />
      <Route
        path="/surgeon/download"
        element={
          <PageTracking>
            <SurgeonDownload />
          </PageTracking>
        }
      />
      <Route
        path="/surgeon/QP"
        element={
          <PageTracking>
            <SurgeonQP />
          </PageTracking>
        }
      />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );

  return {
    switchRoute: switchRoute,
    menu: menu(),
    menuClosing: menu(true, false),
    menuCombined: menu(true, true),
  };
};

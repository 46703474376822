import { Fragment, useEffect, useReducer } from "react";
import { Button, ButtonGroup, Col, Input, Label, Row, Table } from "reactstrap";
import "./Search.css";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import { stakeholderGroupSearch } from "../../../api/StakeholderGroup";
import {
  arraysAreEqual,
  getStartCase,
  isEmptyOrNull,
  PRIVATE,
  PUBLIC,
} from "../../../Utils";
import SimpleStateSelector from "../../common/SimpleStateSelector";
import { useOnUpdate, usePrevious } from "../../CustomHooks";

const Search = ({
  excludeIds = [],
  publicPrivateStatus = null,
  includeState = null,
  itemsCountPerPage = 10,
  pageRangeDisplayed = 5,
  resultsVersion,
  actionButtons,
}) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      totalItemsCount: 0,
      activePage: 1,
      stakeholderGroups: [],
      publicPrivateStatus: publicPrivateStatus,
      name: "",
      state: includeState,
    },
  );

  const prevActivePage = usePrevious(state.activePage);

  useEffect(() => {
    if (prevActivePage !== state.activePage) {
      loadStakeholderGroups();
    }
  }, [resultsVersion, state.activePage, excludeIds]);

  const toggleStakeholderStatus = (status) => {
    if (state.publicPrivateStatus === status) {
      setState({
        publicPrivateStatus: null,
        activePage: 0,
      });
    } else {
      setState({
        publicPrivateStatus: status,
        state: null,
        activePage: 0,
      });
    }
  };

  const loadStakeholderGroups = () => {
    let payload = {
      sort: "name",
      page: state.activePage - 1,
      size: itemsCountPerPage,
      isPrivate: isEmptyOrNull(state.publicPrivateStatus)
        ? null
        : state.publicPrivateStatus === PRIVATE,
      name: isEmptyOrNull(state.name) ? null : state.name,
      stateId: state.state == null ? null : state.state.id,
      excludeIds: excludeIds.length === 0 ? null : excludeIds.join(),
    };
    stakeholderGroupSearch(payload).then((response) => {
      setState({
        stakeholderGroups: response.data.content,
        activePage: response.data.number + 1,
        totalItemsCount: response.data.totalElements,
      });
    });
  };

  const getStatus = (stakeholderGroup) => {
    return getStartCase(stakeholderGroup.isPrivate ? PRIVATE : PUBLIC);
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  const handleSearchFilter = (event) => {
    setState({ name: event.target.value, activePage: 0 });
  };

  const handleStateFilter = (value) => {
    setState({ state: value, activePage: 0 });
  };

  return (
    <div className={"stakeholder-group-search"}>
      <Row className={"search-stakeholder-group-field-row"}>
        {isEmptyOrNull(publicPrivateStatus) ? (
          <Fragment>
            <Col sm={2} md={1} lg={1} className={"text-right"}>
              <Label className={"label"}>Type</Label>
            </Col>
            <Col sm={10} md={3} lg={3} className={"form-column"}>
              <ButtonGroup>
                <Button
                  className={"private-button"}
                  color={"secondary"}
                  outline
                  onClick={() => toggleStakeholderStatus(PRIVATE)}
                  active={state.publicPrivateStatus === PRIVATE}
                >
                  Private
                </Button>
                <Button
                  color={"secondary"}
                  outline
                  onClick={() => toggleStakeholderStatus(PUBLIC)}
                  active={state.publicPrivateStatus === PUBLIC}
                >
                  Public
                </Button>
              </ButtonGroup>
            </Col>
          </Fragment>
        ) : (
          <Col sm={7} md={4} lg={4} />
        )}
        {includeState == null ? (
          <Fragment>
            <Col sm={2} md={1} lg={1} className={"text-right"}>
              <Label className={"label"}>State</Label>
            </Col>
            <Col sm={10} md={3} lg={3} className={"form-column"}>
              <SimpleStateSelector
                usePadding={false}
                onlyThoseWithHospitals={false}
                searchable={true}
                clearable={true}
                useShortNames={true}
                onChange={handleStateFilter}
                disabled={state.publicPrivateStatus === PRIVATE}
                value={state.state}
                autoFocus={false}
              />
            </Col>
          </Fragment>
        ) : (
          <Col sm={12} md={4} lg={4} />
        )}
        <Col sm={2} md={1} lg={1} className={"text-right"}>
          <Label for={"name"} className={"label"}>
            Name
          </Label>
        </Col>
        <Col sm={10} md={3} lg={3} className={""}>
          <Input
            type="text"
            id="name"
            placeholder="Search name..."
            value={state.name}
            onChange={(e) => handleSearchFilter(e)}
          />
        </Col>
      </Row>
      <Row>
        <Table striped className={"search-table"}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>State</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {state.stakeholderGroups && state.stakeholderGroups.length > 0 ? (
              state.stakeholderGroups.map((stakeholderGroup) => (
                <tr key={stakeholderGroup.id}>
                  <td>
                    <span>{stakeholderGroup.name}</span>
                  </td>
                  <td>
                    <span>{getStatus(stakeholderGroup)}</span>
                  </td>
                  <td>
                    <span>
                      {stakeholderGroup.state === null
                        ? ""
                        : stakeholderGroup.state}
                    </span>
                  </td>
                  <td>
                    <span className={"action-buttons"}>
                      {actionButtons.map((button) => (
                        <Button
                          key={`button-${stakeholderGroup.id}`}
                          className={"action-button"}
                          outline
                          title={button.title}
                          onClick={() => button.action(stakeholderGroup)}
                        >
                          {button.icon}
                        </Button>
                      ))}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  <em>No stakeholder groups to display.</em>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Pagination
          activePage={state.activePage}
          totalItemsCount={state.totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={changePage}
          hideDisabled
          innerClass={"pagination pagination-sm"}
          itemClass={"page-item"}
          linkClass={"page-link"}
        />
      </div>
    </div>
  );
};

Search.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ),
  excludeIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  resultsVersion: PropTypes.number,
  publicPrivateStatus: PropTypes.string,
  includeState: PropTypes.object,
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
};

export default Search;

import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import "./UserModal.css";
import PropTypes from "prop-types";
import { createUser, getUser, updateUser } from "../../../api/User";
import {
  CREATE,
  EDIT,
  isAdministratorRoleId,
  isAoaRoleId,
  isEmptyOrNull,
  isFollowUpRoleId,
  isHospitalAdministratorRoleId,
  isHospitalStudyCoordinatorRoleId,
  isSahmriRoleId,
  isStakeholderRoleId,
  isStudyCoordinatorRoleId,
  isSurgeonRoleId,
  SAVE,
  validateEmailAddress,
  validateMobile,
  validateSurgeonCode,
  validName,
  VIEW,
} from "../../../Utils";
import isInt from "validator/lib/isInt";
import classnames from "classnames";
import {
  finishLoading,
  loadHospitals,
  loadStakeholderGroups,
  loadStudies,
  startLoading,
} from "../../../actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { getHospitals } from "../../../api/Hospital";
import { getStudies } from "../../../api/Study";
import Details from "./Details";
import Account from "./Account";
import { getAllStakeholderGroups } from "../../../api/StakeholderGroup";
import { loadSurgeons } from "../../../actions/SurgeonActions";
import { getSurgeons } from "../../../api/Surgeon";
import ButtonBar from "../../common/ButtonBar";
import { useEffect, useReducer } from "react";

const UserModal = ({
  userId,
  emailSuffix,
  onConfirm,
  roleId,
  operation,
  roleName,
  onExit,
}) => {
  const dispatch = useDispatch();

  const hospitals = useSelector((state) => state.hospitals);
  const studies = useSelector((state) => state.studies);
  const stakeholderGroups = useSelector((state) => state.stakeholderGroups);
  const surgeons = useSelector((state) => state.surgeons);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      surgeonCode: "",
      hospitalLinks: [],
      hospitalStudies: [],
      studyLinks: [],
      surgeonLinks: [],
      stakeholderGroupLinks: [],
      uploader: null,
      patientResponseAccess: null,
      datePasswordSet: "",
      dateLastLogin: "",
      disabledBy: null,
      disabledReason: "",
      dateDisabled: "",
      lockReason: "",
      dateLocked: "",
      tokenExpiry: "",
      saveUserError: "",
      currentlySubmitting: false,
      activeTab: "1",
      loadUserError: "",
    },
  );

  useEffect(() => {
    if (operation === EDIT || operation === VIEW) {
      if (userId !== null) {
        dispatch(startLoading());
        setState({ loadUserError: "" });
        getUser(userId)
          .then((response) => {
            setState({
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              email: isEmptyOrNull(emailSuffix)
                ? response.data.email
                : response.data.email.replace("@" + emailSuffix, ""),
              mobile: response.data.mobile,
              surgeonCode: response.data.surgeonCode,
              datePasswordSet: response.data.datePasswordSet,
              dateLastLogin: response.data.dateLastLogin,
              disabledBy: response.data.disabledBy,
              disabledReason: response.data.disabledReason,
              dateDisabled: response.data.dateDisabled,
              lockReason: response.data.lockReason,
              dateLocked: response.data.dateLocked,
              tokenExpiry: response.data.tokenExpiry,
              uploader: response.data.uploader,
              patientResponseAccess: response.data.patientResponseAccess,
              hospitalStudies: response.data.hospitalStudies,
            });
            // Make sure we know about all hospitals that are linked
            let haveAllHospitals = true;
            for (const incomingHospital of response.data.hospitals) {
              if (
                hospitals.filter((hospital) => hospital.id === incomingHospital)
                  .length === 0
              ) {
                haveAllHospitals = false;
                break;
              }
            }
            if (haveAllHospitals) {
              // I know about all the hospitals that are linked to this user - just set them in the component state
              let hospitalLinks = [];
              for (const incomingHospital of response.data.hospitals) {
                hospitalLinks.push(
                  hospitals.filter(
                    (hospital) => hospital.id === incomingHospital,
                  )[0],
                );
              }
              setState({
                hospitalLinks: hospitalLinks.sort((a, b) =>
                  a.name.localeCompare(b.name),
                ),
              });
            } else {
              // We should indicate that the list of hospitals is stale - so let's make sure our redux state is current
              dispatch(loadHospitals());
              // But we also have to keep going, so we'll do the call directly and set the links in a callback
              getHospitals().then((innerResponse) => {
                let fresh = innerResponse.data;
                let hospitalLinks = [];
                for (const incomingHospital of response.data.hospitals) {
                  hospitalLinks.push(
                    fresh.filter(
                      (hospital) => hospital.id === incomingHospital,
                    )[0],
                  );
                }
                setState({
                  hospitalLinks: hospitalLinks.sort((a, b) =>
                    a.name.localeCompare(b.name),
                  ),
                });
              });
            }
            // Make sure we know about all studies that are linked
            let haveAllStudies = true;
            for (const incomingStudy of response.data.studies) {
              if (
                studies.filter((study) => study.id === incomingStudy).length ===
                0
              ) {
                haveAllStudies = false;
                break;
              }
            }
            if (haveAllStudies) {
              // I know about all the studies that are linked to this user - just set them in the component state
              let studyLinks = [];
              for (const incomingStudy of response.data.studies) {
                studyLinks.push(
                  studies.filter((study) => study.id === incomingStudy)[0],
                );
              }
              setState({
                studyLinks: studyLinks.sort((a, b) =>
                  a.name.localeCompare(b.name),
                ),
              });
            } else {
              // We should indicate that the list of studies is stale - so let's make sure our redux state is current
              dispatch(loadStudies());
              // But we also have to keep going, so we'll do the call directly and set the links in a callback
              getStudies().then((innerResponse) => {
                let fresh = innerResponse.data;
                let studyLinks = [];
                for (const incomingStudy of response.data.studies) {
                  studyLinks.push(
                    fresh.filter((study) => study.id === incomingStudy)[0],
                  );
                }
                setState({
                  studyLinks: studyLinks.sort((a, b) =>
                    a.name.localeCompare(b.name),
                  ),
                });
              });
            }

            // Make sure we know about all surgeons that are linked
            let haveAllSurgeons = true;
            for (const incomingSurgeon of response.data.surgeons) {
              if (
                surgeons.filter((surgeon) => surgeon.id === incomingSurgeon)
                  .length === 0
              ) {
                haveAllSurgeons = false;
                break;
              }
            }
            if (haveAllSurgeons) {
              // I know about all studies that are linked to this user - just set them in the component state
              let surgeonLinks = [];
              for (const incomingSurgeon of response.data.surgeons) {
                surgeonLinks.push(
                  surgeons.filter(
                    (surgeon) => surgeon.id === incomingSurgeon,
                  )[0],
                );
              }
              setState({
                surgeonLinks: surgeonLinks.sort((a, b) =>
                  a.lastName.localeCompare(b.lastName),
                ),
              });
            } else {
              // We should indicate that the list of surgeons is stale - so let's make sure our redux state is current
              dispatch(loadSurgeons());
              // But we also have to keep going, so we'll do the call directly and set the links in a callback
              getSurgeons().then((innerResponse) => {
                let fresh = innerResponse.data;
                let surgeonLinks = [];
                for (const incomingSurgeon of response.data.surgeons) {
                  surgeonLinks.push(
                    fresh.filter(
                      (surgeon) => surgeon.id === incomingSurgeon,
                    )[0],
                  );
                }
                setState({
                  surgeonLinks: surgeonLinks.sort((a, b) =>
                    a.lastName.localeCompare(b.lastName),
                  ),
                });
              });
            }

            // Make sure we know about all stakeholder groups that are linked
            let haveAllStakeholderGroups = true;
            if (
              response.data.stakeholderGroups !== null &&
              response.data.stakeholderGroups !== "undefined"
            ) {
              for (const incomingGroup of response.data.stakeholderGroups) {
                if (
                  stakeholderGroups.filter(
                    (group) => group.id === incomingGroup,
                  ).length === 0
                ) {
                  haveAllStakeholderGroups = false;
                  break;
                }
              }
              if (haveAllStakeholderGroups) {
                // I know about all the stakeholder groups that are linked to this user - just set them in the component state
                let stakeholderGroupLinks = [];
                for (const incomingGroup of response.data.stakeholderGroups) {
                  stakeholderGroupLinks.push(
                    stakeholderGroups.filter(
                      (group) => group.id === incomingGroup,
                    )[0],
                  );
                }
                setState({
                  stakeholderGroupLinks: stakeholderGroupLinks.sort((a, b) =>
                    a.name.localeCompare(b.name),
                  ),
                });
              } else {
                // We should indicate that the list of stakeholder groups is stale - so let's make sure our redux state is current
                dispatch(loadStakeholderGroups());
                // But we also have to keep going, so we'll do the call directly and set the links in a callback
                getAllStakeholderGroups().then((innerResponse) => {
                  let fresh = innerResponse.data;
                  let stakeholderGroupLinks = [];
                  for (const incomingGroup of response.data.stakeholderGroups) {
                    stakeholderGroupLinks.push(
                      fresh.filter((group) => group.id === incomingGroup)[0],
                    );
                  }
                  setState({
                    stakeholderGroupLinks: stakeholderGroupLinks.sort((a, b) =>
                      a.name.localeCompare(b.name),
                    ),
                  });
                });
              }
            }
          })
          .catch((error) => {
            if (error.response && isEmptyOrNull(error.response.data)) {
              setState({ loadUserError: "Error loading user" });
            } else {
              setState({
                loadUserError: `Error loading user. ${error.response.data}`,
              });
            }
          })
          .finally(() => dispatch(finishLoading()));
      }
    }
  }, []);

  const setActiveTab = (tab) => {
    if (state.activeTab !== tab) {
      setState({ activeTab: tab });
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === "surgeonCode") {
      // Don't update the field's state if it's not a valid integer or a single minus sign
      if (
        value !== "0" &&
        value !== "-" &&
        !isEmptyOrNull(value) &&
        !isInt(value, { allow_leading_zeroes: false })
      ) {
        return;
      }
    } else if (id === "mobile") {
      if (value.length >= 1 && !value.startsWith("0")) {
        return;
      }
      if (value.length >= 2 && !value.startsWith("04")) {
        return;
      }
      if (value.length > 10) {
        return;
      }
    } else if (id === "uploader") {
      setState({ uploader: !state.uploader });
      return;
    } else if (id === "patientResponseAccess") {
      setState({ patientResponseAccess: !state.patientResponseAccess });
      return;
    }

    let input = value;
    // Prevent text field entry that consists only of spaces
    if (
      (typeof input === "string" || input instanceof String) &&
      input.trim().length === 0
    ) {
      input = "";
    }

    setState({ [id]: input, saveUserError: "" });
  };

  const detailsFormValid = () => {
    const isSmsMfaUser =
      isStakeholderRoleId(roleId) ||
      isFollowUpRoleId(roleId) ||
      isHospitalStudyCoordinatorRoleId(roleId) ||
      isStudyCoordinatorRoleId(roleId);
    let surgeonChecks = true;
    let mfaUserCheck = true;
    if (isSurgeonRoleId(roleId)) {
      surgeonChecks =
        validateMobile(state.mobile) && validateSurgeonCode(state.surgeonCode);
    }
    if (isSmsMfaUser && !isEmptyOrNull(state.mobile)) {
      mfaUserCheck = validateMobile(state.mobile);
    }
    return (
      !isEmptyOrNull(state.firstName) &&
      validName(state.firstName) &&
      !isEmptyOrNull(state.lastName) &&
      validName(state.lastName) &&
      !isEmptyOrNull(state.email) &&
      validateEmailAddress(getEmail()) &&
      surgeonChecks &&
      mfaUserCheck
    );
  };

  const getEmail = () => {
    if (isEmptyOrNull(emailSuffix)) {
      return state.email;
    } else {
      return `${state.email}@${emailSuffix}`;
    }
  };

  const handleHospitalLinkChange = (links) => {
    setState({
      hospitalLinks: links.sort((a, b) => a.name.localeCompare(b.name)),
    });
  };

  const handleHospitalStudyLinkChange = (links) => {
    setState({ hospitalStudies: links });
  };

  const handleStudyLinkChange = (links) => {
    setState({
      studyLinks: links.sort((a, b) => a.name.localeCompare(b.name)),
    });
  };

  const handleStakeholderGroupLinkChange = (links) => {
    setState({
      stakeholderGroupLinks: links.sort((a, b) => a.name.localeCompare(b.name)),
    });
  };

  const handleSurgeonLinkChange = (links) => {
    setState({
      surgeonLinks: links.sort((a, b) => a.lastName.localeCompare(b.lastName)),
    });
  };

  const handleSubmit = () => {
    setState({ currentlySubmitting: true });

    const payload = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: getEmail(),
      mobile: state.mobile,
      roleId: roleId,
    };
    if (isSurgeonRoleId(roleId)) {
      payload.surgeonCode = state.surgeonCode;
      payload.hospitalStudies = state.hospitalStudies;
      payload.uploader = state.uploader;
    } else if (isHospitalAdministratorRoleId(roleId)) {
      payload.hospitals = state.hospitalLinks.map((hospital) => hospital.id);
      payload.surgeons = state.surgeonLinks.map((surgeon) => surgeon.id);
      payload.uploader = state.uploader;
      payload.patientResponseAccess = state.patientResponseAccess;
      payload.mobile = null;
    } else if (isStakeholderRoleId(roleId)) {
      payload.stakeholderGroups = state.stakeholderGroupLinks.map(
        (group) => group.id,
      );
    } else if (isStudyCoordinatorRoleId(roleId) || isFollowUpRoleId(roleId)) {
      payload.studies = state.studyLinks.map((study) => study.id);
    } else if (isHospitalStudyCoordinatorRoleId(roleId)) {
      payload.hospitalStudies = state.hospitalStudies;
      payload.uploader = state.uploader;
      payload.patientResponseAccess = state.patientResponseAccess;
    }
    if (operation === CREATE) {
      createUser(payload)
        .then(() => {
          onConfirm(
            `Successfully created ${state.firstName} ${state.lastName}`,
          );
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({
              saveUserError: "Error creating the user",
            });
          } else {
            setState({ saveUserError: error.response.data });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    } else if (operation === EDIT) {
      updateUser(userId, payload)
        .then(() => {
          onConfirm(
            `Successfully updated user ${state.firstName} ${state.lastName}`,
          );
        })
        .catch((error) => {
          if (error.response && isEmptyOrNull(error.response.data)) {
            setState({ saveUserError: "Error updating the user" });
          } else {
            setState({ saveUserError: error.response.data });
          }
        })
        .finally(() => setState({ currentlySubmitting: false }));
    }
  };

  const passwordResetSuccess = () => {
    onConfirm(
      `Password for ${state.firstName} ${state.lastName} successfully reset and activation email sent`,
    );
  };

  const handleActionSuccess = (action) => {
    onConfirm(
      `${state.firstName} ${state.lastName} was successfully ${action}`,
    );
  };

  const getButtons = () => {
    const buttons = [];
    if (
      state.activeTab === "1" &&
      operation !== VIEW &&
      isEmptyOrNull(state.loadUserError)
    ) {
      buttons.push(
        <Button
          color="primary"
          outline={!detailsFormValid()}
          disabled={!detailsFormValid() && !state.currentlySubmitting}
          onClick={handleSubmit}
        >
          {operation === CREATE ? CREATE : SAVE}
        </Button>,
      );
    }
    buttons.push(
      <Button onClick={onExit}>
        {operation === VIEW || state.activeTab === "2" ? "Close" : "Cancel"}
      </Button>,
    );
    return buttons;
  };

  const isAzureManagedUser =
    isAdministratorRoleId(roleId) ||
    isSahmriRoleId(roleId) ||
    isAoaRoleId(roleId);
  const isSurgeon = isSurgeonRoleId(roleId);
  const isHospitalAdministrator = isHospitalAdministratorRoleId(roleId);
  const isHospitalStudyCoordinator = isHospitalStudyCoordinatorRoleId(roleId);
  const isStakeholder = isStakeholderRoleId(roleId);
  const largeModal =
    isSurgeon ||
    isHospitalAdministrator ||
    isHospitalStudyCoordinator ||
    isStakeholder ||
    operation !== CREATE;
  return (
    <div>
      <Modal
        isOpen={true}
        toggle={onExit}
        onClosed={onExit}
        size={largeModal ? "lg" : "md"}
      >
        <ModalHeader toggle={onExit}>
          {operation === CREATE ? (
            <span>Create {roleName} User</span>
          ) : (
            <span>
              {operation} - {state.firstName} {state.lastName}
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          {operation === CREATE && (
            <Details
              userId={userId}
              emailSuffix={emailSuffix}
              email={state.email}
              firstName={state.firstName}
              lastName={state.lastName}
              mobile={state.mobile}
              uploader={state.uploader}
              patientResponseAccess={state.patientResponseAccess}
              surgeonCode={state.surgeonCode}
              roleId={roleId}
              operation={operation}
              changeCallback={handleChange}
              hospitalLinks={state.hospitalLinks}
              hospitalStudyLinks={state.hospitalStudies}
              hospitalStudyLinkCallback={handleHospitalStudyLinkChange}
              hospitalLinkCallback={handleHospitalLinkChange}
              studyLinks={state.studyLinks}
              studyLinkCallback={handleStudyLinkChange}
              stakeholderGroupLinks={state.stakeholderGroupLinks}
              stakeholderGroupLinkCallback={handleStakeholderGroupLinkChange}
              surgeonLinkCallback={handleSurgeonLinkChange}
              surgeonLinks={state.surgeonLinks}
            />
          )}
          {operation !== CREATE && (
            <div>
              <Alert
                color={"danger"}
                isOpen={!isEmptyOrNull(state.loadUserError)}
              >
                {state.loadUserError}
              </Alert>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "1",
                      })}
                      onClick={() => {
                        setActiveTab("1");
                      }}
                    >
                      Details
                    </NavLink>
                  </NavItem>
                  {!isAzureManagedUser && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: state.activeTab === "2",
                        })}
                        onClick={() => {
                          setActiveTab("2");
                        }}
                      >
                        Account
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={state.activeTab}>
                  <TabPane tabId="1">
                    <Details
                      userId={userId}
                      emailSuffix={emailSuffix}
                      email={state.email}
                      firstName={state.firstName}
                      lastName={state.lastName}
                      mobile={state.mobile}
                      uploader={state.uploader}
                      patientResponseAccess={state.patientResponseAccess}
                      surgeonCode={state.surgeonCode}
                      roleId={roleId}
                      operation={operation}
                      changeCallback={handleChange}
                      hospitalLinks={state.hospitalLinks}
                      hospitalStudyLinks={state.hospitalStudies}
                      hospitalStudyLinkCallback={handleHospitalStudyLinkChange}
                      hospitalLinkCallback={handleHospitalLinkChange}
                      studyLinks={state.studyLinks}
                      studyLinkCallback={handleStudyLinkChange}
                      stakeholderGroupLinks={state.stakeholderGroupLinks}
                      stakeholderGroupLinkCallback={
                        handleStakeholderGroupLinkChange
                      }
                      surgeonLinkCallback={handleSurgeonLinkChange}
                      surgeonLinks={state.surgeonLinks}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Account
                      operation={operation}
                      datePasswordSet={state.datePasswordSet}
                      email={getEmail()}
                      disabledBy={state.disabledBy}
                      dateDisabled={state.dateDisabled}
                      disabledReason={state.disabledReason}
                      lockReason={state.lockReason}
                      dateLocked={state.dateLocked}
                      tokenExpiry={state.tokenExpiry}
                      roleId={roleId}
                      userId={userId}
                      passwordResetCallback={passwordResetSuccess}
                      disableAccountCallback={() =>
                        handleActionSuccess("disabled")
                      }
                      enableAccountCallback={() =>
                        handleActionSuccess("enabled")
                      }
                      unlockAccountCallback={() =>
                        handleActionSuccess("unlocked")
                      }
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          )}
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Alert
                color="danger"
                isOpen={!isEmptyOrNull(state.saveUserError)}
              >
                {state.saveUserError}
              </Alert>
            </Col>
          </Row>
          <ButtonBar buttons={getButtons()} />
        </ModalBody>
      </Modal>
    </div>
  );
};

UserModal.propTypes = {
  roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  roleName: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  emailSuffix: PropTypes.string,
  userId: PropTypes.number,
  operation: PropTypes.string.isRequired,
};

export default UserModal;

import { getProcedureRecruitment } from "../../api/Reporting";
import BarReport from "./BarReport";
import { aoaGrey, aoaLightBlue, aoaTan } from "./Utils";

const Recruitment = () => {
  const mapColours = (series, index) => {
    if (index === 0) {
      return aoaLightBlue;
    } else if (index === 1) {
      return aoaGrey;
    } else {
      return aoaTan;
    }
  };

  return (
    <BarReport
      fetchData={getProcedureRecruitment}
      mapColours={mapColours}
      id={"procedure-recruitment"}
      legendHovers={{
        Registered:
          "Procedures in the system whose patients have either consented to participate or have yet to decide",
        "Opt-Out":
          "Procedures in the system whose patients who no longer wish to participate in the system",
        Declined:
          "Procedures whose patients who have declined to participate in the system",
      }}
    />
  );
};

export default Recruitment;

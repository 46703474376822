import {
  Alert,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { getProcedures } from "../../../api/Patient";
import { getStartCase, isEmptyOrNull } from "../../../Utils";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../../actions/CommonActions";
import PatientProcedureSelector from "../../common/PatientProcedureSelector";
import Moment from "moment";
import ButtonBar from "../../common/ButtonBar";
import { useEffect, useReducer } from "react";

const SendLogonLinkModal = ({
  validTransport,
  checkErrorCallback,
  confirmCallback,
  exitCallback,
  patientId,
}) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      procedures: [],
      procedureFilter: null,
      mode: validTransport.emailValid ? "email" : "sms",
    },
  );

  useEffect(() => {
    dispatch(startLoading());
    getProcedures(patientId)
      .then((response) => {
        setState({
          procedures: response.data,
          procedureFilter: response.data.length > 0 ? response.data[0] : null,
        });
      })
      .catch((error) => {
        if (isEmptyOrNull(error?.response)) {
          setState({ loadProcedureError: "Error loading procedures" });
        } else {
          setState({
            loadProcedureError: `Error loading procedures. ${error.response}`,
          });
        }
      })
      .finally(() => dispatch(finishLoading()));
  }, []);

  const getPatientProcedureSelectorRenderer = (procedure) => {
    return `${getStartCase(procedure.procedureName)} (${Moment(
      procedure.dateCreated,
    ).format("DD/MM/YYYY")})`;
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader>Select the procedure to send to patient</ModalHeader>
      <ModalBody className={"delete-confirm"}>
        <div>
          Please select the procedure that you wish to send to the patient.
        </div>
        <PatientProcedureSelector
          placeholder={"Select...."}
          clearable={false}
          options={state.procedures}
          value={state.procedureFilter}
          onChange={(procedure) => setState({ procedureFilter: procedure })}
          optionRenderer={(procedure) =>
            getPatientProcedureSelectorRenderer(procedure)
          }
        />
        <Row className={"with-margins my-3"}>
          <Col xs={5} className={"text-end my-auto"}>
            Send login details via{" "}
          </Col>
          <Col xs={2}>
            <Label check>
              <Input
                type="radio"
                name={"mode-" + state.mode}
                disabled={!validTransport.emailValid}
                onChange={() => setState({ mode: "email" })}
                checked={state.mode === "email" || !validTransport.mobileValid}
              />
              Email
            </Label>
          </Col>
          <Col xs={2}>
            <Label check>
              <Input
                type="radio"
                name={"mode-" + state.mode}
                disabled={!validTransport.mobileValid}
                onChange={() => setState({ mode: "sms" })}
                checked={state.mode === "sms" || !validTransport.emailValid}
              />
              SMS
            </Label>
          </Col>
        </Row>
        {checkErrorCallback != null && (
          <Row className={"with-margins my-3"}>
            <Col>
              <Alert color={"danger"}>{checkErrorCallback}</Alert>
            </Col>
          </Row>
        )}
        <ButtonBar
          buttons={[
            <Button
              color="primary"
              onClick={() =>
                confirmCallback(
                  patientId,
                  state.procedureFilter.procedureId,
                  state.mode,
                )
              }
            >
              Send
            </Button>,
            <Button onClick={() => exitCallback()}>Cancel</Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

SendLogonLinkModal.propTypes = {
  confirmCallback: PropTypes.func.isRequired,
  exitCallback: PropTypes.func.isRequired,
  checkErrorCallback: PropTypes.func,
  patientId: PropTypes.number.isRequired,
  validTransport: PropTypes.object.isRequired,
};

export default SendLogonLinkModal;

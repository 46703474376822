import { Card, CardBody, CardTitle, Col, Input, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import {
  getAllUploaders,
  getAllUploadStatuses,
  searchUploads,
} from "../../api/Upload";
import Select from "react-select";
import { isEmptyOrNull } from "../../Utils";
import UploadTable from "./UploadTable";
import "./UploadManagement.css";
import { useEffect, useReducer } from "react";

const UploadManagement = ({ resultsVersion = 0 }) => {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      uploads: [],
      statuses: [],
      uploaders: [],
      status: null,
      uploader: null,
      itemsCountPerPage: 10,
      pageRangeDisplayed: 5,
      totalItemsCount: 0,
      activePage: 1,
      description: "",
    },
  );

  useEffect(() => {
    refreshTable(true);
  }, [
    resultsVersion,
    state.activePage,
    state.status,
    state.uploader,
    state.description,
  ]);

  const refreshTable = (showSpinner) => {
    if (showSpinner) {
      dispatch(startLoading());
    }
    let payload = {
      page: state.activePage - 1,
      size: state.itemsCountPerPage,
      description: isEmptyOrNull(state.description) ? null : state.description,
      uploadUserId: state.uploader == null ? null : state.uploader.id,
      statusId: state.status == null ? null : state.status.id,
    };
    searchUploads(payload)
      .then((response) => {
        setState({
          uploads: response.data.content,
          activePage: response.data.number + 1,
          totalItemsCount: response.data.totalElements,
        });
      })
      .finally(() => dispatch(finishLoading()));

    getAllUploadStatuses().then((response) => {
      setState({ statuses: response.data });
    });

    getAllUploaders().then((response) => {
      setState({ uploaders: response.data });
    });
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  const renderUploader = (uploader) => {
    return uploader.firstName + " " + uploader.lastName;
  };

  return (
    <Card className={"upload-management"}>
      <CardBody>
        <CardTitle>Upload Management</CardTitle>
        <Row className={"field-row align-items-center"}>
          <Col sm={2} className={"text-end"}>
            <Label for={"status"}>Status</Label>
          </Col>
          <Col sm={3}>
            <Select
              options={state.statuses}
              onChange={(value) => setState({ status: value })}
              isClearable={true}
              isSearchable={true}
              value={state.status}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            />
          </Col>
          <Col sm={2} className={"text-end"}>
            <Label for={"uploader"}>Uploader</Label>
          </Col>
          <Col sm={5}>
            <Select
              options={state.uploaders}
              onChange={(value) => setState({ uploader: value })}
              isClearable={true}
              isSearchable={true}
              value={state.uploader}
              getOptionLabel={(option) => renderUploader(option)}
              getOptionValue={(option) => option.id}
              styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
            />
          </Col>
        </Row>
        <Row className={"field-row align-items-center"}>
          <Col sm={2} className={"text-end"}>
            <Label for={"description"}>Description</Label>
          </Col>
          <Col sm={10}>
            <Input
              type={"text"}
              value={state.description}
              onChange={(event) =>
                setState({ description: event.target.value })
              }
            />
          </Col>
        </Row>
        <UploadTable
          pageRangeDisplayed={state.pageRangeDisplayed}
          itemsCountPerPage={state.itemsCountPerPage}
          totalItemsCount={state.totalItemsCount}
          uploads={state.uploads}
          activePage={state.activePage}
          changePageCallback={changePage}
          tableChangeCallback={() => refreshTable(true)}
        />
      </CardBody>
    </Card>
  );
};

UploadManagement.propTypes = {
  resultsVersion: PropTypes.number,
};
export default UploadManagement;

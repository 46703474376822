import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, Container } from "reactstrap";
import Moment from "moment";
import {
  contactDetails,
  patientSearchRequest,
  procedureRegistrationRequest,
} from "../../middleware/PatientMiddleware";
import {
  isEmptyOrNull,
  validateEmailAddress,
  validateOptionalMobile,
  validateOptionalPhone,
} from "../../Utils";
import {
  clearNewProcedure,
  loadPatientDetails,
  setPatientField,
  setPatientProcedureField,
  setProcedureHospital,
  setProcedureState,
  setProcedureSurgeon,
} from "../../actions/PatientActions";
import SimpleStateSelector from "../common/SimpleStateSelector";
import SimpleHospitalSelector from "../common/SimpleHospitalSelector";
import SimpleSurgeonSelector from "../common/SimpleSurgeonSelector";
import SimpleTextInput from "../common/SimpleTextInput";
import Review from "./Review";
import {
  atLeastOneContact,
  generateLinkButton,
  hasAllDetails,
  hasDetailsForSelfRegistration,
  newProcedureTypeSelected,
  NEXT,
  PREVIOUS,
  renderRoute,
} from "./Utils";
import SimplePhoneNumberInput, {
  PHONE_NUMBER_INPUT_TYPES,
} from "../common/SimplePhoneNumberInput";
import SimpleLogoHeader from "../common/SimpleLogoHeader";
import { selfRegister } from "../../api/Patient";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import ProcedureTypePicker from "../common/ProcedureTypePicker";

const NewProcedure = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const patient = useSelector((state) => state.patient);

  const [submitting, setSubmitting] = useState(false);
  const [minAge, setMinAge] = useState(0);
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {
    let locations = location.pathname.match(/^\/patient\/register\/(.+)$/);
    let isStateScreen =
      !!locations && locations.length > 0 && locations[1] === "state";
    // The only valid entry point into this workflow is the first screen (i.e. state). Remember we're coming in
    // from new patient, so make sure we've got all the patient details too.
    if (
      !isStateScreen ||
      (isStateScreen && !hasAllDetails(patient) && patient.id == null)
    ) {
      navigate("/", { replace: true });
    }
    let now = Moment().add(1, "hour");
    let dob;
    if (patient.dateOfBirth == null) {
      dob = Moment(
        `${patient.year}-${patient.month}-${patient.day}`,
        "YYYY-MMMM-Do",
      );
    } else {
      dob = Moment(patient.dateOfBirth);
    }
    let diff = now.diff(dob, "years");
    setMinAge(diff);
  }, []);

  useEffect(() => {
    if (submitError && !location.pathname.match("/patient/register/review")) {
      setSubmitError(null);
    }
  }, [patient.email]);

  const renderProgressButtons = () => {
    return (
      <Fragment>
        <Button
          tabIndex={-1}
          color={
            isEmptyOrNull(patient.newProcedure.state) ? "secondary" : "primary"
          }
        />
        <Button
          tabIndex={-1}
          color={
            isEmptyOrNull(patient.newProcedure.hospital)
              ? "secondary"
              : "primary"
          }
        />
        <Button
          tabIndex={-1}
          color={
            isEmptyOrNull(patient.newProcedure.surgeon)
              ? "secondary"
              : "primary"
          }
        />
        <Button
          tabIndex={-1}
          color={
            !newProcedureTypeSelected(patient.newProcedure)
              ? "secondary"
              : "primary"
          }
        />
        <Button
          tabIndex={-1}
          color={
            isEmptyOrNull(patient.mobilePhone) ||
            !validateOptionalMobile(patient.mobilePhone)
              ? "secondary"
              : "primary"
          }
        />
        <Button
          tabIndex={-1}
          color={
            isEmptyOrNull(patient.homePhone) ||
            !validateOptionalPhone(patient.homePhone)
              ? "secondary"
              : "primary"
          }
        />
        <Button
          tabIndex={-1}
          color={
            isEmptyOrNull(patient.email) || !validateEmailAddress(patient.email)
              ? "secondary"
              : "primary"
          }
        />
      </Fragment>
    );
  };

  const contactDetailText = (question) => {
    return (
      <div>
        <div>
          Please provide your contact details.
          <span className={"shrink-on-small"}>
            <Alert color={"primary"}>
              Note: These contact details can be for a family member or a
              trusted friend if you would like them to assist you.
            </Alert>
          </span>
        </div>
        <div className={"standout"}>{question}</div>
      </div>
    );
  };

  const submitRegister = () => {
    setSubmitting(true);
    let dob;
    // If a brand-new patient this will be true
    if (patient.dateOfBirth == null) {
      dob = Moment(
        patient.year + patient.month + patient.day,
        "YYYYMMMMDo",
      ).format("YYYY-MM-DD");
    } else {
      dob = Moment(patient.dateOfBirth).format("YYYY-MM-DD");
    }
    callPatientApi({ ...patient, dateOfBirth: dob });
  };

  const callPatientApi = (payload) => {
    let request = {
      ...patientSearchRequest(payload),
      ...procedureRegistrationRequest(payload),
      ...contactDetails(payload),
    };
    selfRegister(request).then((response) => {
      const { data } = response;
      if (data.success && data.patient && data.patient.id) {
        // Successful response
        dispatch(startLoading());
        data.patient.dateOfBirth = Moment(data.patient.dateOfBirth);
        dispatch(loadPatientDetails(data));
        dispatch(clearNewProcedure());
        navigate("/", { replace: true });
        dispatch(finishLoading());
      } else {
        let message = "Network error.";
        if (data.message) {
          message = data.message;
        } else if (response.message) {
          message = response.message;
        }
        setSubmitError(message);
      }
      setSubmitting(false);
    });
  };

  /**
   * Wrapper function for the useNavigate hook that appends the route with ../ to have relative path routing
   * This way the route is matched on .../patient/register/ and not .../patient/register/*previous route*
   *
   This function must be in this component as the useNavigate hook can only be called from a React component
   *
   * @param route where to navigate to
   * @private
   */
  const _navigate = (route) => {
    navigate(`../${route}`, { relative: "path" });
  };

  let hidePreamble =
    window.location.pathname !== "patient/register/state"
      ? null
      : { display: "none" };
  return (
    <div>
      {patient.id == null && <SimpleLogoHeader link={true} />}
      <Container className={"patient-logon register"}>
        <div className={"header"}>
          {patient.id == null && (
            <div>
              <h2 className={"welcome"} style={hidePreamble}>
                Welcome
              </h2>
              <div className="header-paragraph" style={hidePreamble}>
                Please tell us a little bit about yourself before we get
                started.
              </div>
            </div>
          )}
          <div className={"progress-buttons"}>{renderProgressButtons()}</div>
        </div>
        <Routes>
          {renderRoute(
            "state",
            <SimpleStateSelector
              value={patient.newProcedure.state}
              onChange={(payload) => dispatch(setProcedureState(payload))}
              clearable={false}
              searchable={false}
              onlyThoseWithHospitals={true}
            />,
            <div className={"standout"}>What State is your hospital in?</div>,
            generateLinkButton(3, patient.id ? "Home" : PREVIOUS, null, () =>
              navigate(patient.id ? "/" : "/patient/notfound", {
                replace: true,
              }),
            ),
            generateLinkButton(
              2,
              NEXT,
              isEmptyOrNull(patient.newProcedure.state),
              () => _navigate("hospital"),
            ),
            "new-procedure",
          )}

          {renderRoute(
            "hospital",
            <SimpleHospitalSelector
              value={patient.newProcedure.hospital}
              onChange={(payload) => dispatch(setProcedureHospital(payload))}
              clearable={false}
              tabIndex={1}
              onlyThoseWithMinAge={true}
              minAge={minAge}
              stateId={
                patient.newProcedure.state
                  ? patient.newProcedure.state.id
                  : null
              }
            />,
            <div className={"standout"}>
              Where are you having your procedure?
            </div>,
            generateLinkButton(3, PREVIOUS, null, () => _navigate("state")),
            generateLinkButton(
              2,
              NEXT,
              isEmptyOrNull(patient.newProcedure.hospital),
              () => _navigate("surgeon"),
            ),
            "new-procedure",
          )}

          {renderRoute(
            "surgeon",
            <SimpleSurgeonSelector
              value={patient.newProcedure.surgeon}
              onChange={(payload) => dispatch(setProcedureSurgeon(payload))}
              clearable={true}
              searchable={true}
            />,
            <div className={"standout"}>Who is your surgeon?</div>,
            generateLinkButton(3, PREVIOUS, null, () => _navigate("hospital")),
            generateLinkButton(2, NEXT, null, () =>
              _navigate("procedureTypes"),
            ),
            "new-procedure",
          )}

          {renderRoute(
            "procedureTypes",
            <ProcedureTypePicker
              updateAction={(payload) =>
                dispatch(setPatientProcedureField(payload))
              }
              newProcedure={{ ...patient.newProcedure }}
              bottomText={
                <span>
                  *Pick all that apply.
                  <Alert color={"primary"}>
                    <p>
                      Please only select multiple procedures if they are both
                      being operated on during your next hospital admission.
                    </p>
                    <p>
                      If you are having multiple planned procedures i.e. one now
                      and one in the future, we will collect the information on
                      your second procedure before your next hospital admission.
                    </p>
                  </Alert>
                </span>
              }
            />,
            <div className={"standout"}>
              Select which procedure you are having (select multiple procedures
              if required)
            </div>,
            generateLinkButton(8, PREVIOUS, null, () => _navigate("surgeon")),
            generateLinkButton(
              7,
              NEXT,
              !newProcedureTypeSelected(patient.newProcedure),
              () => _navigate("mobilephone"),
            ),
            "new-procedure",
          )}

          {renderRoute(
            "mobilephone",
            <SimplePhoneNumberInput
              field={"mobilePhone"}
              length={10}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.mobilePhone}
              phoneNumberInputType={PHONE_NUMBER_INPUT_TYPES.mobile}
              invalid={
                !!patient.mobilePhone &&
                patient.mobilePhone.length === 12 &&
                !validateOptionalMobile(patient.mobilePhone)
              }
            />,
            contactDetailText("What is your Australian mobile phone number?"),
            generateLinkButton(3, PREVIOUS, null, () =>
              _navigate("procedureTypes"),
            ),
            generateLinkButton(
              2,
              NEXT,
              !validateOptionalMobile(patient.mobilePhone),
              () => _navigate("homephone"),
            ),
            "new-procedure",
          )}

          {renderRoute(
            "homephone",
            <SimplePhoneNumberInput
              field={"homePhone"}
              length={10}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.homePhone}
              phoneNumberInputType={PHONE_NUMBER_INPUT_TYPES.landline}
              invalid={
                !!patient.homePhone &&
                patient.homePhone.length === 12 &&
                !validateOptionalPhone(patient.homePhone)
              }
            />,
            contactDetailText(
              "What is your Australian home phone number (including area code)?",
            ),
            generateLinkButton(3, PREVIOUS, null, () =>
              _navigate("mobilephone"),
            ),
            generateLinkButton(
              2,
              NEXT,
              !validateOptionalPhone(patient.homePhone),
              () => _navigate("email"),
            ),
            "new-procedure",
          )}

          {renderRoute(
            "email",
            <SimpleTextInput
              field={"email"}
              length={255}
              updateAction={(payload) => dispatch(setPatientField(payload))}
              value={patient.email}
              type={"email"}
              invalid={
                !isEmptyOrNull(patient.email) &&
                !validateEmailAddress(patient.email)
              }
              invalidNotice={"That does not appear to be an email address."}
              placeholder={"___________@_____.___"}
            />,
            contactDetailText("What is your email address?"),
            generateLinkButton(3, PREVIOUS, null, () => _navigate("homephone")),
            generateLinkButton(
              2,
              NEXT,
              !hasDetailsForSelfRegistration(patient),
              () => _navigate("review"),
            ),
            "new-procedure",
            !atLeastOneContact(patient)
              ? "Please provide at least one form of contact information."
              : null,
          )}

          {renderRoute(
            "review",
            <Review patient={patient} />,
            "Review your details.",
            generateLinkButton(2, PREVIOUS, null, () => _navigate("email")),
            <Button
              tabIndex={1}
              onClick={submitRegister}
              color={
                hasDetailsForSelfRegistration(patient) ? "primary" : "secondary"
              }
              disabled={submitting || !hasDetailsForSelfRegistration(patient)}
              autoFocus
              outline={!hasDetailsForSelfRegistration(patient)}
            >
              Submit
            </Button>,
            "new-procedure",
            submitError ? submitError : "",
          )}
        </Routes>
      </Container>
    </div>
  );
};

export default NewProcedure;

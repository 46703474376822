import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getContactText } from "../../Utils";
import ButtonBar from "./ButtonBar";

const HelpModal = ({ closeCallback }) => {
  return (
    <Modal isOpen={true} toggle={closeCallback}>
      <ModalHeader>Help</ModalHeader>
      <ModalBody>
        {getContactText()}
        <ButtonBar
          buttons={[
            <Button color={"primary"} onClick={closeCallback}>
              Close
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

HelpModal.propTypes = {
  closeCallback: PropTypes.func.isRequired,
};

export default HelpModal;

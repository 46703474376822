import { get, post } from "./Api";

const BASE_URL = "/api/task";

function url(path) {
  if (path) {
    return `${BASE_URL}/${path}`;
  }
  return `${BASE_URL}`;
}

export function getTasks(payload) {
  return get(url(""), payload, "Unable to get outstanding tasks at this time.");
}

export function runTaskReminders() {
  return post(
    url("reminders"),
    null,
    "Unable to send task reminders at this time.",
  );
}

export function areTaskRemindersInProgress() {
  return get(
    url("reminders"),
    null,
    "Unable to check task reminders at this time.",
  );
}

import { lazy, Suspense } from "react";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  FaChartBar,
  FaHeartbeat,
  FaMedkit,
  FaQuestion,
  FaSignOutAlt,
} from "react-icons/fa";
import Patients from "../management/patient/PatientManagement";
import Reporting from "../reporting/Reporting";
import NavButton from "./NavButton";
import { PageTracking } from "../app/PageTracking";

export const StudyCoordinator = (
  menuOpen,
  toggleMenuCb,
  userLogoutCb,
  helpToggleCallback,
) => {
  const menu = (sidebarMenu, showHelpAndLogout) => (
    <Navbar
      className={`vertical-menu ${sidebarMenu ? "header" : ""}`}
      light
      expand={!sidebarMenu}
    >
      <NavbarToggler onClick={toggleMenuCb} children={<span>MENU</span>} />
      <Collapse isOpen={sidebarMenu ? menuOpen : false} navbar>
        <Nav className="me-auto  vert-menu-holder" vertical>
          <NavButton
            to={"/patient/management"}
            icon={<FaMedkit />}
            text={"Patients"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/reporting"}
            icon={<FaChartBar />}
            text={"Reporting"}
            onClick={toggleMenuCb}
          />
          <NavButton
            to={"/study/management"}
            icon={<FaHeartbeat />}
            text={"Studies"}
            onClick={toggleMenuCb}
          />
          {showHelpAndLogout && (
            <>
              {" "}
              <hr />
              <NavButton
                to={null}
                icon={<FaQuestion className={"icon help"} />}
                text={"Help"}
                onClick={helpToggleCallback}
              />
              <NavButton
                to={"/administration"}
                icon={<FaSignOutAlt className={"icon"} />}
                text={"Logout"}
                onClick={userLogoutCb}
              />
            </>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );

  const LazyStudyManagement = lazy(
    () => import("../management/study/StudyManagement"),
  );

  const switchRoute = (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/dashboard" element={null} />
        <Route
          path="/patient/management"
          element={
            <PageTracking>
              <Patients />
            </PageTracking>
          }
        />
        <Route
          path="/reporting"
          element={
            <PageTracking>
              <Reporting />
            </PageTracking>
          }
        />
        <Route
          path="/study/management"
          element={
            <PageTracking>
              <LazyStudyManagement />
            </PageTracking>
          }
        />
        <Route element={<Navigate to="/dashboard" />} />
      </Routes>
    </Suspense>
  );

  return {
    switchRoute: switchRoute,
    menu: menu(),
    menuClosing: menu(true, false),
    menuCombined: menu(true, true),
  };
};

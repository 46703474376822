import React, { Suspense } from "react";
import validator from "validator";
import { toast } from "react-toastify";
import { stringify } from "flatted/esm";

/**
 * Utilities that can be used throughout the whole project.
 */
export function isEmptyOrNull(value) {
  if (typeof value === "object" && value != null) {
    return Object.entries(value).length === 0;
  } else {
    return value === null || value === undefined || value.length === 0;
  }
}

export function arrayValuesAreNull(inputArray) {
  if (isEmptyOrNull(inputArray)) {
    return true;
  }
  for (let i = 0, len = inputArray.length; i < len; i += 1)
    if (inputArray[i] !== null) {
      return false;
    }
  return true;
}

export function noDigits(value) {
  return !/\d/.test(value);
}

/**
 * Validate value is a string and has the nominated length.
 */
export function validateLength(value, length) {
  if (typeof value === "string") {
    // eslint-disable-next-line
    return value != null && value != undefined && value.length === length;
  }
  return false;
}

/**
 * Validate value is a string and is less than or equal to the nominated length.
 */
export function validateLengthLessThanOrEqualTo(value, length) {
  if (typeof value === "string") {
    // eslint-disable-next-line
    return value != null && value != undefined && value.length <= length;
  }
  return false;
}

/**
 * Validate value is a recognised Australian postcode.
 * https://en.wikipedia.org/wiki/Postcodes_in_Australia
 */
export function validateAustralianPostcode(value) {
  // validator package is a little too simple here.
  if (value.length !== 4) {
    return false;
  }
  if (!/^[0-9]{4}$/.test(value)) {
    return false;
  }
  return (
    (value >= "0200" && value <= "0299") || (value >= "0800" && value <= "9000")
  );
}

/**
 * Validate value is an email address that loosely conforms to RFC3696.
 * Ignore backslash quoting and double-quote enclosed local part.
 */
export function validateEmailAddress(value) {
  let ok = false;
  if (value && value.length <= 254) {
    ok = validator.isEmail(value);
  }
  return ok;
}

export function validateMobile(value) {
  value = stripAllButDigits(value);
  let ok = false;
  if (value && value.length === 10) {
    ok = validator.isMobilePhone(value, "en-AU");
  }
  return ok;
}

export function validateOptionalMobile(value) {
  return isEmptyOrNull(value) || validateMobile(value);
}

export function validatePhone(value) {
  let ok = false;
  if (value) {
    ok = /^0[2378]\s{0,1}\d{4}\s{0,1}\d{4}$/.test(value);
  }
  return ok;
}

export function validateOptionalPhone(value) {
  return isEmptyOrNull(value) || validatePhone(value);
}

export function stripAllButDigits(input) {
  return input.replace(/[^\d]/g, "");
}

export function isAdministratorUser(user) {
  return user != null && user.roleId === 1;
}

export function isSahmriUser(user) {
  return user != null && user.roleId === 4;
}

export function isAoaUser(user) {
  return user != null && user.roleId === 3;
}

export function isSurgeonUser(user) {
  return user != null && user.roleId === 2;
}

export function isHospitalAdministratorUser(user) {
  return user != null && user.roleId === 5;
}

export function isFollowUpUser(user) {
  return user != null && user.roleId === 6;
}

export function isStakeholderUser(user) {
  return user != null && user.roleId === 7;
}

export function isStudyCoordinatorUser(user) {
  return user != null && user.roleId === 8;
}

export function isHospitalStudyCoordinatorUser(user) {
  return user != null && user.roleId === 9;
}

export function isAdministratorRoleId(roleId) {
  return roleId === 1 || roleId === "1";
}

export function isSurgeonRoleId(roleId) {
  return roleId === 2 || roleId === "2";
}

export function isAoaRoleId(roleId) {
  return roleId === 3 || roleId === "3";
}

export function isSahmriRoleId(roleId) {
  return roleId === 4 || roleId === "4";
}

export function isHospitalAdministratorRoleId(roleId) {
  return roleId === 5 || roleId === "5";
}

export function isFollowUpRoleId(roleId) {
  return roleId === 6 || roleId === "6";
}

export function isStakeholderRoleId(roleId) {
  return roleId === 7 || roleId === "7";
}

export function isStudyCoordinatorRoleId(roleId) {
  return roleId === 8 || roleId === "8";
}

export function isHospitalStudyCoordinatorRoleId(roleId) {
  return roleId === 9 || roleId === "9";
}

/**
 * Valid surgeon codes are positive or negative integers between 3 and 6 digits long
 */
export function validateSurgeonCode(value) {
  let testValue = parseInt(value, 10);
  if (isNaN(testValue)) {
    return false;
  }
  return testValue >= -999999 && testValue <= 999999 && testValue !== 0;
}

export function getContactAoanjrrComponent() {
  return (
    <span>
      Please contact the AOANJRR on{" "}
      <a href="mailto:admin@aoanjrr.org.au">admin@aoanjrr.org.au</a> or{" "}
      <a href="tel:0881284280">08 8128 4280</a> for assistance.
    </span>
  );
}

/**
 * Start-Case a string. See https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage
 *
 * @param value string to be start-cased
 * @returns {string} start-cased string
 */
export function getStartCase(value) {
  if (value == null || value.length === 0) {
    return value;
  } else if (value.length === 1) {
    return value.toUpperCase();
  }
  // Remove any double-spaces
  while (value.includes("  ")) {
    value = value.replace("  ", " ");
  }
  return value
    .trim()
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");
}

/**
 * Camel-case a string
 *
 * @param value string to be camel-cased
 * @returns {string} camel-cased string
 */
export const getCamelCase = (value) => {
  value = value.toLowerCase();
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

/**
 * Get the side and joint from a procedureType string
 * ie leftHipReplacement -> Left Hip
 *
 * @param procedureTypeName procedureType name to retrieve side and hip from
 * @returns {string} side and joint
 */
export const getProcedureJointFromProcedureType = (procedureTypeName) => {
  const displayName = getDisplayNameForProcedureType(procedureTypeName);
  const splitDisplayName = displayName.split(" ");
  return `${splitDisplayName[0]} ${splitDisplayName[1]}`;
};

/**
 * Transforms a procedureType name to its display name
 * ie leftHipReplacement -> Left Hip Replacement
 * Optional flag for if the side should be capitalised
 * ie leftHipReplacement -> LEFT Hip Replacement
 *
 * @param procedureTypeName procedureType name to be title-cased
 * @param capitaliseSide flag for capitalising the side
 * @returns {string} title-cased procedure type
 */
export const getDisplayNameForProcedureType = (
  procedureTypeName,
  capitaliseSide = false,
) => {
  if (procedureTypeName == null || procedureTypeName.length === 0) {
    return procedureTypeName;
  }
  const splitName = procedureTypeName.replace(/([A-Z])/g, " $1");
  const titleCase = splitName.charAt(0).toUpperCase() + splitName.slice(1);
  if (!capitaliseSide) {
    return titleCase;
  }
  const splitTitleCase = titleCase.split(" ");
  return (
    splitTitleCase.shift().toUpperCase() +
    ` ${splitTitleCase.toString().replace(",", " ")}`
  );
};

/**
 * Compares the two input arrays for equality. The arrays are sorted by the function prior to comparing them.
 *
 * @param first array
 * @param second array
 * @returns true if the arrays are equal
 */
export function arraysAreEqual(first, second) {
  let array1 = first.sort();
  let array2 = second.sort();
  return (
    array1.length === array2.length &&
    array1.every(function (v, i) {
      return v === array2[i];
    })
  );
}

/**
 * Get text blurb outlining contact details for AOANJRR.
 * @returns AOANJRR contact text.
 */
export function getContactText() {
  return (
    <span>
      If you require any assistance please contact the AOANJRR on{" "}
      <a className="no-break" href="mailto:admin@aoanjrr.org.au">
        admin@aoanjrr.org.au
      </a>{" "}
      or{" "}
      <a className="no-break" href="tel:0881284280">
        08 8128 4280
      </a>
      .
    </span>
  );
}

/**
 * Create a Toast error notification.
 * Default message is: We're sorry, it appears something has gone wrong.
 * @param message An optional message
 * @param timeout An optional display time in ms.
 */
export function toastError(message, timeout) {
  let text = message || "We're sorry, it appears something has gone wrong.";
  let ms = timeout || 5000;
  let content = (
    <div>
      <div>
        <b>{text}</b>
      </div>
    </div>
  );

  toast.error(content, ms);
}

/**
 * Replace hyphens that immediately precede a number with non-breaking unicode hyphens to prevent terms like "6-months'" breaking over two lines.
 * @param input string to replace
 * @returns string with hyphens replaced with unicode non-breaking hyphens
 */
export function replaceHyphens(input) {
  if (isEmptyOrNull(input)) {
    return input;
  }
  return input.replace(/(\d)(-)/g, function (match, p1, p2, offset, string) {
    return p1 + "\u2011";
  });
}

/**
 * A boolean function that tells the caller whether the input text is valid for a user or patient name.
 * @param input the string to check
 * @returns true if the string is valid, false otherwise
 */
export function validName(input) {
  if (isEmptyOrNull(input)) {
    return false;
  }
  let hasEmoji =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(
      input,
    );
  let hasBlacklistedSymbol = /[~`!@#$%^&*()_=+|\\[\]{};:"<>,.?/]/g.test(input);
  return noDigits(input) && !hasEmoji && !hasBlacklistedSymbol;
}

/**
 * A boolean function that tells the caller whether the input text is valid for a email suffix. "." is allowed in the email suffix and is a valid input.
 * @param input the string to check
 * @returns true if the string is valid, false otherwise
 */
export function validEmailSuffix(input) {
  if (isEmptyOrNull(input)) {
    return false;
  }
  let hasDotSymbol = /[.]/g.test(input);
  let hasEmoji =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(
      input,
    );
  let hasWhiteSpace = /^\s*$/.test(input);
  let hasBlacklistedSymbol = /[~`!@#$%^&*()_=+|\\[\]{};:"<>,?/]/g.test(input);
  return !hasEmoji && !hasBlacklistedSymbol && !hasWhiteSpace && hasDotSymbol;
}

export function isUserInAdminGroup(user) {
  return isAoaUser(user) || isAdministratorUser(user) || isSahmriUser(user);
}

export function getSentryUri(hostname) {
  if (hostname === "aoanjrrtrials.sahmri.com") {
    return "https://a43d7f5656654e2b99a65a560d0993cd@sentry.io/1295021";
  } else if (hostname === "aoanjrrtrials-dev.sahmri.com") {
    return "https://8bc4bf5ea2f04b5381307520cb921d72@sentry.io/1295029";
  } else if (hostname === "172.25.93.109") {
    return "https://cd53e79caa344fb7978803be4a7e0bbf@sentry.io/1418406";
  } else if (hostname === "localhost") {
    return "https://a00a7b39502143adab8c1619dd1c19f5@sentry.io/1505756";
  }
  return null;
}

export const UNKNOWN_SURGEON = {
  id: 0,
  firstName: "Unknown",
  lastName: "Surgeon",
  role: "Role(id=2, name=Surgeon, emailSuffix=null)",
};

export function isString(test) {
  return Object.prototype.toString.call(test) === "[object String]";
}

export function WaitingComponent(Component) {
  return (props) => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  );
}

export function jsonEquals(one, two) {
  return stringify(one) === stringify(two);
}

/**
 * Sort a given array by a given property (ascending)
 * @param array the array
 * @param property the property on the objects held in the array to sort on. Supports strings and numbers.
 * @returns the sorted array
 */
export function sortArrayByProperty(array, property) {
  if (!!array && array.length > 0) {
    if (
      typeof array[0].property === "string" ||
      array[0].property instanceof String
    ) {
      array.sort((a, b) => a[property].localeCompare(b[property]));
    } else {
      array.sort((a, b) => a[property] - b[property]);
    }
  }
  return array;
}

export function getDragAndDropItemStyle(isDragging, draggableStyle) {
  return {
    userSelect: "none",
    ...draggableStyle,
  };
}

export function getDragAndDropListStyle(isDraggingOver) {
  return {
    background: isDraggingOver ? "lightblue" : "inherit",
  };
}

/**
 * Get the currently-selected object from the given list from the given id, using the idAttributeName if necessary.
 *
 * @param id the identifier value to find
 * @param list the list of objects to search through
 * @param idAttributeName the name of the id attribute - defaults to 'id' if not supplied
 * @returns the value if found, otherwise null
 */
export function getValueFromArrayByAttribute(id, list, idAttributeName) {
  if (isEmptyOrNull(idAttributeName)) {
    idAttributeName = "id";
  }
  if (id == null) {
    return null;
  }
  let index = list.findIndex((type) => type[idAttributeName] === id);
  if (index < 0) {
    return null;
  } else {
    return list[index];
  }
}

export function getPaginationCounter(
  activePage,
  itemsCountPerPage,
  totalItemsCount,
) {
  const firstItem = 1 + (activePage - 1) * itemsCountPerPage;
  const lastItem = Math.min(activePage * itemsCountPerPage, totalItemsCount);
  return (
    <span>
      Showing <b>{firstItem}</b> to <b>{lastItem}</b> of{" "}
      <b>{totalItemsCount}</b>
    </span>
  );
}

/** Custom sort function to return the greater of two values */
export const customSort = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

/**
 * Common function to handle an enter key press
 *
 * @param event passed in from the DOM
 * @param callback function to execute when Enter key is pressed
 * @param condition optional condition to check in addition to Enter key
 */
export const handleEnterKeyPress = (event, callback, condition = true) => {
  if (event.key === "Enter" && condition) {
    callback();
  }
};

/**
 * Retrieves the index from url path
 *
 * @param location
 * @param component
 * @returns index
 */
export const getIndexFromPath = (location, component) => {
  let index;
  if (!!location && location.pathname != null) {
    let parts = location.pathname.match(RegExp(`^\/${component}\/(\\d+)$`));
    if (!!parts && parts.length === 2) {
      index = parseInt(parts[1], 10);
      index--;
    }
  }
  return index;
};

export const CREATE = "Create";
export const EDIT = "Edit";
export const VIEW = "View";
export const SAVE = "Save";
export const PRIVATE = "private";
export const PUBLIC = "public";
// System launch date
export const LAUNCH_DATE = "2018-07-30";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { loadSurgeons } from "../../../actions/SurgeonActions";
import { Button, Input, Table } from "reactstrap";
import { isEmptyOrNull } from "../../../Utils";
import Pagination from "react-js-pagination";
import { useEffect, useState } from "react";

const Search = ({
  actionButtons = [],
  excludeIds = [],
  itemsCountPerPage = 5,
  pageRangeDisplayed = 5,
}) => {
  const dispatch = useDispatch();
  const surgeons = useSelector((state) => state.surgeons);

  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    surgeons.length === 0 && dispatch(loadSurgeons());
  }, [dispatch]);

  const changePage = (pageNumber) => {
    if (pageNumber !== activePage) {
      setActivePage(pageNumber);
    }
  };

  const getSearchQuery = (value) => {
    if (value == null) {
      return "";
    } else if (value.trim().length === 0) {
      // Input is just one or more spaces: truncate the input
      return "";
    } else {
      return value;
    }
  };

  const filtered = isEmptyOrNull(search)
    ? []
    : surgeons.filter(
        (surgeon) =>
          !excludeIds.includes(surgeon.id) &&
          (surgeon.firstName
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
            surgeon.lastName
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()) ||
            surgeon.surgeonCode.toString() === search),
      );
  const filteredAndPaged = filtered.slice(
    (activePage - 1) * itemsCountPerPage,
    activePage * itemsCountPerPage,
  );
  return (
    <div>
      <Input
        placeholder={"Enter surgeon first name, last name or code"}
        value={search}
        onChange={(event) => {
          setSearch(getSearchQuery(event.target.value));
          setActivePage(1);
        }}
      />
      <Table striped className={"search-table"}>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Code</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndPaged.length > 0 ? (
            filteredAndPaged.map((surgeon) => (
              <tr key={`surgeon-key-${surgeon.id}`}>
                <td className={"surgeon-entry"}>
                  <span className={"surgeon-text"}>{surgeon.firstName}</span>
                </td>
                <td className={"surgeon-entry"}>
                  <span className={"surgeon-text"}>{surgeon.lastName}</span>
                </td>

                <td className={"surgeon-entry"}>
                  <span className={"surgeon-text"}>{surgeon.surgeonCode}</span>
                </td>
                <td>
                  <span className={"action-buttons"}>
                    {actionButtons.map((button) => (
                      <Button
                        key={`surgeon-${surgeon.id}`}
                        id={`surgeon-${surgeon.id}`}
                        className={"action-button"}
                        outline
                        title={button.title}
                        onClick={() => button.action(surgeon)}
                      >
                        {button.icon}
                      </Button>
                    ))}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>
                <em>No surgeons to display.</em>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Pagination
          activePage={activePage}
          totalItemsCount={filtered.length}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={changePage}
          hideDisabled
          innerClass={"pagination pagination-sm"}
          itemClass={"page-item"}
          linkClass={"page-link"}
        />
      </div>
    </div>
  );
};

Search.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ),
  excludeIds: PropTypes.arrayOf(PropTypes.number),
  itemsCountPerPage: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
};

export default Search;

import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import NavButton from "./NavButton";
import {
  FaChartArea,
  FaChartBar,
  FaQuestion,
  FaSignOutAlt,
} from "react-icons/fa";
import { Navigate, Route, Routes } from "react-router-dom";
import Reporting from "../reporting/Reporting";
import NonPatientView from "../compare/NonPatientView";
import { PageTracking } from "../app/PageTracking";

export const Stakeholder = (
  menuOpen,
  toggleMenuCb,
  userLogoutCb,
  helpToggleCallback,
  showResults,
) => {
  const menu = (sidebarMenu, showHelpAndLogout) => (
    <Navbar
      className={`vertical-menu ${sidebarMenu ? "header" : ""}`}
      light
      expand={!sidebarMenu}
    >
      <NavbarToggler onClick={toggleMenuCb} children={<span>MENU</span>} />
      <Collapse isOpen={sidebarMenu ? menuOpen : false} navbar>
        <Nav className="me-auto  vert-menu-holder" vertical>
          <NavButton
            to={"/reporting"}
            icon={<FaChartBar />}
            text={"Reporting"}
            onClick={toggleMenuCb}
          />
          {showResults && (
            <NavButton
              to={"/compare"}
              icon={<FaChartArea />}
              text={"Results"}
              onClick={toggleMenuCb}
            />
          )}
          {showHelpAndLogout && (
            <>
              <hr />
              <NavButton
                to={null}
                icon={<FaQuestion className={"icon help"} />}
                text={"Help"}
                onClick={helpToggleCallback}
              />
              <NavButton
                to={"/administration"}
                icon={<FaSignOutAlt className={"icon"} />}
                text={"Logout"}
                onClick={userLogoutCb}
              />
            </>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );

  const switchRoute = (
    <Routes>
      <Route
        path="/reporting"
        element={
          <PageTracking>
            <Reporting />
          </PageTracking>
        }
      />
      <Route
        path="/compare"
        element={
          <PageTracking>
            <NonPatientView />
          </PageTracking>
        }
      />
      <Route path="*" element={<Navigate to="/reporting" />} />
    </Routes>
  );

  return {
    switchRoute: switchRoute,
    menu: menu(),
    menuClosing: menu(true, false),
    menuCombined: menu(true, true),
  };
};

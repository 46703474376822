import TabbedPatientModal from "../management/patient/TabbedPatientModal";
import { canEditPatient } from "../patient/Utils";
import {
  EDIT,
  getStartCase,
  isHospitalStudyCoordinatorUser,
  isSurgeonUser,
  VIEW,
} from "../../Utils";
import ViewDataQuery from "../data-query/ViewDataQuery";
import { Navigate, Route } from "react-router-dom";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { MdFlightTakeoff } from "react-icons/md";
import { FaClipboardList, FaFlag, FaFolderOpen } from "react-icons/fa";

const ActionButton = ({
  action,
  actionClick,
  actionSuccessCallback,
  actionCancelCallback,
}) => {
  const user = useSelector((state) => state.user);

  let component, icon;
  // Define the component we'll render when the action button is clicked
  if (action.componentType === "PATIENT_MANAGEMENT") {
    let patientId =
      "DETAILS" === action.componentSubType
        ? action.ids.id
        : action.ids.patientId;
    component = (
      <TabbedPatientModal
        open
        modalOperation={canEditPatient(user) ? EDIT : VIEW}
        patientId={patientId}
        initialProcedureId={
          ["PROCEDURES", "CASE_REPORT_FORMS"].includes(action.componentSubType)
            ? action.ids.procedureId
            : null
        }
        initialTab={getStartCase(action.componentSubType.replace(/_/g, " "))}
        /* We need to trigger the success callback even on exit because creating a new CRF doesn't close the modal */
        onExit={actionSuccessCallback}
        onConfirm={actionSuccessCallback}
      />
    );
  } else if (action.componentType === "DATA_QUERY") {
    component = (
      <ViewDataQuery
        dataQueryId={action.ids.id}
        cancelCallback={actionCancelCallback}
        saveCallback={actionSuccessCallback}
      />
    );
  } else if (isSurgeonUser(user) && action.componentType === "SURGEON_CRF") {
    // This isn't a modal, but a whole different screen via react-router
    component = (
      <Navigate
        to={`/surgeon/crf/${action.ids.procedureId}/${action.ids.crfTypeId}`}
      />
    );
  } else if (
    isHospitalStudyCoordinatorUser(user) &&
    action.componentType === "SURGEON_CRF"
  ) {
    // This isn't a modal, but a whole different screen via react-router
    component = (
      <Navigate
        to={`hospitalStudyCoordinator/crf/${action.ids.procedureId}/${action.ids.crfTypeId}/${user.id}`}
      />
    );
  }

  // Set the icon for the action button
  if (action.componentType === "DATA_QUERY") {
    icon = <FaFlag />;
  } else if (
    action.componentType === "PATIENT_MANAGEMENT" &&
    [
      "DETAILS",
      "PROCEDURES",
      "PATIENTS",
      "RESPONSES",
      "CASE REPORT FORMS",
    ].includes(action.componentSubType)
  ) {
    icon = <FaFolderOpen />;
  } else if (
    action.componentSubType === "CASE_REPORT_FORMS" ||
    action.componentType === "SURGEON_CRF"
  ) {
    icon = <FaClipboardList />;
  } else {
    icon = <MdFlightTakeoff />;
  }

  return (
    <Button
      outline
      color={"primary"}
      className={"action-button"}
      title={action.label}
      disabled={!action.enabled}
      onClick={() => actionClick(component)}
    >
      {icon}
    </Button>
  );
};

ActionButton.propTypes = {
  action: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
    componentSubType: PropTypes.string,
  }).isRequired,
  actionClick: PropTypes.func.isRequired,
  actionCancelCallback: PropTypes.func.isRequired,
  actionSuccessCallback: PropTypes.func.isRequired,
};

export default ActionButton;

import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import "./AnnouncementDetailsModal.css";
import PropTypes from "prop-types";
import { isEmptyOrNull } from "../../../Utils";
import { createAnnouncement } from "../../../api/Announcement";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import "moment/locale/en-au";
import DatePickerIosInput from "../../common/DatePickerIosInput";
import DOMPurify from "dompurify";
import ButtonBar from "../../common/ButtonBar";
import { useReducer } from "react";

const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

const AnnouncementDetailsModal = ({ open, onConfirm, onExit }) => {
  Moment.locale("en-au");
  Moment.fn.toJSON = function () {
    return this.format();
  };

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      isOpen: open,
      startDate: null,
      finishDate: null,
      message: null,
      currentlySubmitting: false,
      saveAnnouncementError: null,
      error: null,
    },
  );

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState({ [id]: value });
  };

  const handleSubmit = () => {
    setState({ currentlySubmitting: true });
    const payload = {
      message: state.message,
      startDate: Moment.parseZone(state.startDate).format(DATE_FORMAT),
      finishDate: Moment.parseZone(state.finishDate).format(DATE_FORMAT),
    };
    createAnnouncement(payload)
      .then((response) => {
        onConfirm(response.data);
      })
      .catch((error) => {
        if (error.response && isEmptyOrNull(error.response.data)) {
          setState({
            saveAnnouncementError: "Error creating the Announcement",
          });
        } else {
          setState({
            saveAnnouncementError: error.response.data,
          });
        }
      })
      .finally(() => setState({ currentlySubmitting: false }));
  };

  const handleStartDateChange = (date) => {
    setState({ startDate: Moment(date) });
  };

  const handleFinishDateChange = (date) => {
    setState({ finishDate: Moment(date) });
  };

  const validateFinishDate = () => {
    return Moment(state.finishDate).isAfter(Moment(state.startDate));
  };

  const validateDate = (date) => {
    let now = Moment().add(2, "minutes");
    if (date !== null && Moment(date)) {
      return Moment(date).isAfter(now);
    } else {
      return false;
    }
  };

  const formValid = () => {
    let isValidMessage = !isEmptyOrNull(state.message);
    let isValidStartDate = !isEmptyOrNull(state.startDate);
    let isValidFinishDate =
      !isEmptyOrNull(state.finishDate) && validateDate(state.finishDate);
    let isFinishDateAfterStart =
      isValidStartDate && isValidFinishDate && validateFinishDate();
    return (
      isValidStartDate &&
      isValidFinishDate &&
      isValidMessage &&
      isFinishDateAfterStart
    );
  };

  const toggle = () => {
    setState({ isOpen: !state.isOpen });
  };

  return (
    <Modal isOpen={state.isOpen} toggle={toggle} onClosed={onExit} size={"lg"}>
      <ModalHeader toggle={toggle}>
        <span>Create Announcement</span>
      </ModalHeader>
      <ModalBody>
        <div className={"announcement-details-modal"}>
          <div className={"field-row"}>
            <em>Note: Fields marked with an * are required</em>
          </div>
          <Form>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Label for="startDate" className={"form-label"}>
                    Start Date*
                  </Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <DatePicker
                    name="startDate"
                    selected={
                      state.startDate == null ? null : state.startDate.toDate()
                    }
                    for="startDate"
                    onChange={(value) => handleStartDateChange(value)}
                    peekNextMonth
                    minDate={Moment().add(1, "hour").toDate()}
                    maxDate={Moment().year(2050).startOf("year").toDate()}
                    autoComplete="off"
                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy h:mm a"
                    timeCaption="Time"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="scroll"
                    scrollableYearDropdown={true}
                    yearDropdownItemNumber={120}
                    placeholderText={"Start Date..."}
                    popperPlacement={"top-start"}
                    style={{ align: "left" }}
                    className={"startDate"}
                    customInput={<DatePickerIosInput />}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Label for="finishDate" className={"form-label"}>
                    Finish Date*
                  </Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <DatePicker
                    name="finishDate"
                    selected={
                      state.finishDate == null
                        ? null
                        : state.finishDate.toDate()
                    }
                    for="finishDate"
                    onChange={(value) => handleFinishDateChange(value)}
                    peekNextMonth
                    minDate={Moment().add(1, "hour").toDate()}
                    maxDate={Moment().year(2050).startOf("year").toDate()}
                    autoComplete="off"
                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy h:mm a"
                    timeCaption="Time"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="scroll"
                    scrollableYearDropdown={true}
                    yearDropdownItemNumber={120}
                    placeholderText={"Finish Date..."}
                    popperPlacement={"top-start"}
                    style={{ align: "left" }}
                    className={"startDate"}
                    customInput={<DatePickerIosInput />}
                  />
                  {!isEmptyOrNull(state.finishDate) &&
                    !validateDate(state.finishDate.toDate()) && (
                      <FormFeedback style={{ display: "block" }}>
                        Finish date must be after the current date and time
                      </FormFeedback>
                    )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Label className={"form-label"} for="message">
                    Message*
                  </Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Input
                    required
                    type="textarea"
                    id="message"
                    placeholder={
                      "Enter the Announcement Message here (HTML is permitted)"
                    }
                    value={state.message == null ? "" : state.message}
                    onChange={handleChange}
                    className={"announcement-details-modal-message"}
                    rows={5}
                  />
                  <FormFeedback>Message is Mandatory!</FormFeedback>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className={"field-row"}>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Label className={"form-label"} for="message">
                    Preview
                  </Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Alert color={"danger"}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(state.message),
                      }}
                    />
                  </Alert>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <Alert color="danger" isOpen={!isEmptyOrNull(state.error)}>
                    {state.error}
                  </Alert>
                </Col>
              </Row>
            </FormGroup>
            <ButtonBar
              buttons={[
                <Button
                  color="primary"
                  disabled={!formValid() && !state.currentlySubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>,
                <Button onClick={onExit}>Cancel</Button>,
              ]}
            />
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

AnnouncementDetailsModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onExit: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default AnnouncementDetailsModal;

import { Col, FormFeedback, Input, Row } from "reactstrap";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { handleEnterKeyPress } from "../../Utils";

const SimpleTextInput = ({
  placeholder = null,
  invalid = false,
  maxLength = null,
  enterAction = () => {},
  text,
  type,
  invalidNotice,
  field,
  value,
  updateAction,
}) => {
  const textInput = useRef(null);

  useEffect(() => {
    textInput.current.focus();
  }, [field]);

  return (
    <div className={"simple-input-wrapper"}>
      <Row>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
        <Col>
          <Input
            type={type || "text"}
            value={value || ""}
            onChange={(event) => updateAction({ [field]: event.target.value })}
            bsSize={"lg"}
            innerRef={(input) => {
              textInput.current = input;
            }}
            tabIndex={1}
            className={invalid ? "is-invalid" : null}
            placeholder={placeholder}
            maxLength={maxLength == null ? null : maxLength}
            onKeyPress={(event) => handleEnterKeyPress(event, enterAction)}
            /* In-line style required in order to fix CSS precedence issues between IE11 and...everything else */
            style={{ fontSize: "xx-large" }}
          />
          <FormFeedback>{invalidNotice}</FormFeedback>
          <div className={"subdued"}>{text}</div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={3} xl={3} />
      </Row>
    </div>
  );
};

SimpleTextInput.propTypes = {
  updateAction: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  invalid: PropTypes.bool,
  invalidNotice: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  enterAction: PropTypes.func,
};

export default SimpleTextInput;

import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import PropTypes from "prop-types";
import Moment from "moment";
import DatePicker from "react-datepicker";
import DatePickerIosInput from "../../common/DatePickerIosInput";
import { isEmptyOrNull } from "../../../Utils";
import ButtonBar from "../../common/ButtonBar";
import { useEffect, useState } from "react";
import { useOnUpdate } from "../../CustomHooks";

const GovernanceDateModal = ({
  governanceStartDateProp,
  onCancel,
  onConfirm,
  recruitmentEndDateProp,
  study,
  hospitalName,
}) => {
  const [governanceStartDate, setGovernanceStartDate] = useState(
    governanceStartDateProp !== null ? governanceStartDateProp : new Date(),
  );
  const [recruitmentEndDate, setRecruitmentEndDate] = useState(
    recruitmentEndDateProp,
  );

  useEffect(() => {
    if (!isEmptyOrNull(governanceStartDateProp)) {
      setGovernanceStartDate(
        Moment(governanceStartDateProp, "YYYY-MM-DD").toDate(),
      );
      setRecruitmentEndDate(
        recruitmentEndDateProp == null
          ? null
          : Moment(recruitmentEndDateProp, "YYYY-MM-DD").toDate(),
      );
    }
  }, []);

  useOnUpdate(() => {
    // Prevent start date > end date
    if (
      governanceStartDate > recruitmentEndDate &&
      recruitmentEndDate !== null
    ) {
      setRecruitmentEndDate(governanceStartDate);
    }
  }, [governanceStartDate, recruitmentEndDate]);

  const dateIsValid = (value) => {
    return Moment(value).isValid();
  };

  const formValid = () => {
    return (
      (dateIsValid(governanceStartDate) && recruitmentEndDate == null) ||
      dateIsValid(recruitmentEndDate)
    );
  };

  return (
    <Modal isOpen={true} toggle={onCancel} onClosed={onCancel}>
      <ModalHeader toggle={onCancel}>Set Governance Date</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            Please set the governance and recruitment dates for{" "}
            <b>{study.name}</b> at <i>{hospitalName}</i>
          </Col>
        </Row>
        <Row className={"my-2"}>
          <Col sm={5} className={"my-auto text-end"}>
            Governance Start Date*
          </Col>
          <Col sm={6}>
            <DatePicker
              selected={governanceStartDate}
              onChange={(value) => {
                if (dateIsValid(value)) {
                  setGovernanceStartDate(value);
                }
              }}
              minDate={new Date(1990, 0)}
              maxDate={Moment().add(1, "years").toDate()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="scroll"
              dateFormat={"dd/MM/yyyy"}
              scrollableYearDropdown={true}
              placeholderText={"dd/mm/yyyy"}
              popperPlacement={"bottom-start"}
              customInput={<DatePickerIosInput />}
              className={"form-control"}
            />
          </Col>
        </Row>
        <Row className={"my-2"}>
          <Col sm={5} className={"my-auto text-end"}>
            Recruitment End Date
          </Col>
          <Col sm={6}>
            <DatePicker
              selected={recruitmentEndDate}
              onChange={(value) => {
                if (dateIsValid(value) || isEmptyOrNull(value)) {
                  setRecruitmentEndDate(value);
                }
              }}
              minDate={governanceStartDate}
              maxDate={Moment().add(1, "years").toDate()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="scroll"
              dateFormat={"dd/MM/yyyy"}
              scrollableYearDropdown={true}
              placeholderText={"dd/mm/yyyy"}
              popperPlacement={"bottom-start"}
              customInput={<DatePickerIosInput />}
              className={"form-control"}
            />
          </Col>
        </Row>
        <Row className={"mt-4"}>
          <Col>
            <i>
              Note: The hospital must be saved for governance/recruitment date
              changes to take effect.
            </i>
          </Col>
        </Row>
        <ButtonBar
          buttons={[
            <Button
              color="primary"
              disabled={!formValid()}
              onClick={() =>
                onConfirm(study, governanceStartDate, recruitmentEndDate)
              }
            >
              Continue
            </Button>,
            <Button onClick={onCancel}>Cancel</Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

GovernanceDateModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  study: PropTypes.object.isRequired,
  governanceStartDate: PropTypes.string,
  recruitmentEndDate: PropTypes.string,
};

export default GovernanceDateModal;

import { Fragment, useReducer } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import GenericSelector from "../common/GenericSelector";
import {
  isAdministratorRoleId,
  isAoaRoleId,
  isEmptyOrNull,
  isHospitalAdministratorRoleId,
  isHospitalStudyCoordinatorRoleId,
  isSahmriRoleId,
} from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading, startLoading } from "../../actions/CommonActions";
import {
  createDataQuery,
  getHospitalAdministrators,
  getHospitalStudyCoordinators,
  validateCrfs,
  validatePatients,
  validateProcedureCollections,
  validateProcedures,
} from "../../api/DataQuery";
import TextareaAutosize from "react-autosize-textarea";
import SimpleRoleSelector from "../common/SimpleRoleSelector";
import {
  assigneeNeedsUsers,
  canChooseAssignee,
  getDataQueryCrfsTable,
  getDataQueryPatientsTable,
  getDataQueryProcedureCollectionsTable,
  getDataQueryProceduresTable,
  userCanAssignTaskTo,
} from "./Utils";
import { toast } from "react-toastify";
import { FaCheck, FaQuestionCircle } from "react-icons/fa";
import Papa from "papaparse";
import DOMPurify from "dompurify";
import "./CreateMultiDataQuery.css";
import { useOnUpdate } from "../CustomHooks";

const PATIENTS = {
  id: 1,
  name: "Patients",
  singular: "Patient",
  table: "patients",
};
const PROCEDURES = {
  id: 2,
  name: "Procedures",
  singular: "Procedure",
  table: "procedures",
};
const CRFS = { id: 3, name: "CRFs", singular: "CRF", table: "crfs" };
const PROCEDURE_COLLECTIONS = {
  id: 4,
  name: "Procedure Collections",
  singular: "Procedure Collection",
  table: "procedure_collections",
};

const CreateMultiDataQuery = ({ createCallback }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  let resetState = {
    id1: "",
    id2: "",
    rawCsv: "",
    bulkIds: [],
    validated: null,
    error: null,
    comment: "",
    isPublic: false,
    assignTo: null,
    assigneeUsers: null,
  };

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ...resetState,
      mode: "SINGLE",
      multiDataEntity: PATIENTS,
    },
  );

  useOnUpdate(() => {
    state.assignTo !== null && checkAssigneeUsers();
  }, [state.assignTo]);

  const isSingleMode = () => {
    return state.mode === "SINGLE";
  };

  const isBulkMode = () => {
    return state.mode === "BULK";
  };

  const isPatientsQuery = () => {
    return !!state.multiDataEntity && state.multiDataEntity.id === PATIENTS.id;
  };

  const isCrfsQuery = () => {
    return !!state.multiDataEntity && state.multiDataEntity.id === CRFS.id;
  };

  const isProceduresQuery = () => {
    return (
      !!state.multiDataEntity && state.multiDataEntity.id === PROCEDURES.id
    );
  };

  const isProcedureCollectionsQuery = () => {
    return (
      !!state.multiDataEntity &&
      state.multiDataEntity.id === PROCEDURE_COLLECTIONS.id
    );
  };

  const getMultiDataEntities = () => {
    return [PATIENTS, PROCEDURES, CRFS, PROCEDURE_COLLECTIONS];
  };

  const canValidate = () => {
    if (isSingleMode()) {
      return (
        !isEmptyOrNull(state.id1) &&
        !isEmptyOrNull(state.id2) &&
        state.validated == null
      );
    } else if (isBulkMode()) {
      return !isEmptyOrNull(state.rawCsv) && state.validated == null;
    } else {
      return false;
    }
  };

  const canSubmit = () => {
    return (
      state.validated != null &&
      !isEmptyOrNull(state.comment) &&
      state.assignTo != null &&
      (!assigneeNeedsUsers(state.assignTo) ||
        (state.isPublic &&
          !!state.assigneeUsers &&
          state.assigneeUsers.length > 0))
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && canValidate()) {
      validate();
    }
  };

  const assignToChanged = (value) => {
    setState({ assignTo: value });
  };

  const checkAssigneeUsers = () => {
    if (
      (isHospitalAdministratorRoleId(state.assignTo.id) ||
        isHospitalStudyCoordinatorRoleId(state.assignTo.id)) &&
      canChooseAssignee(user)
    ) {
      dispatch(startLoading());
      let payload = {};
      if (isPatientsQuery()) {
        payload.patientIds = [state.id1, state.id2].join(",");
      } else if (isProceduresQuery()) {
        payload.procedureIds = [state.id1, state.id2].join(",");
      } else if (isCrfsQuery()) {
        payload.crfIds = [state.id1, state.id2].join(",");
      } else if (isProcedureCollectionsQuery()) {
        payload.procedureCollectionIds = [state.id1, state.id2].join(",");
        if (
          !isEmptyOrNull(state.validated) &&
          !isEmptyOrNull(state.validated.patient)
        ) {
          payload.patientIds = state.validated.patient.id;
        }
      }
      if (isHospitalAdministratorRoleId(state.assignTo.id)) {
        getHospitalAdministrators(payload)
          .then((response) =>
            setState({ assigneeUsers: response.data, error: null }),
          )
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            } else {
              setState({ error: "An error occurred" });
            }
          })
          .finally(() => dispatch(finishLoading()));
      } else if (isHospitalStudyCoordinatorRoleId(state.assignTo.id)) {
        getHospitalStudyCoordinators(payload)
          .then((response) =>
            setState({ assigneeUsers: response.data, error: null }),
          )
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            } else {
              setState({ error: "An error occurred" });
            }
          })
          .finally(() => dispatch(finishLoading()));
      }
    } else {
      setState({ assigneeUsers: null });
    }
  };

  const roleFilter = (role) => {
    if (isSingleMode()) {
      return userCanAssignTaskTo(user, role);
    } else {
      // We don't permit assigning bulk queries to hospital administrators as the logic would be too convoluted
      return (
        !isHospitalAdministratorRoleId(role.id) &&
        userCanAssignTaskTo(user, role)
      );
    }
  };

  const crfRoleFilter = (role) => {
    if (isSingleMode()) {
      return (
        isAoaRoleId(role.id) ||
        isSahmriRoleId(role.id) ||
        isAdministratorRoleId(role.id) ||
        (!isEmptyOrNull(state.crfRolesProvided) &&
          state.crfRolesProvided.find((r) => r.roleId === role.id))
      );
    } else {
      // We don't permit assigning bulk queries to hospital administrators as the logic would be too convoluted
      return (
        !(
          isHospitalAdministratorRoleId(role.id) ||
          isHospitalStudyCoordinatorRoleId(role.id)
        ) && userCanAssignTaskTo(user, role)
      );
    }
  };

  /**
   * filter the to only roles that are completable in all of the crfs
   * @param validatedCrfs
   * @returns {*|AsyncIterator|Iterator|any[]}
   */
  const buildCrfAssignableRolesUsingCrfTypes = (validatedCrfs) => {
    let allCompletableRoles = validatedCrfs.crfDtos.flatMap(
      (d) => d.completableRoles,
    );
    // initialise the roleCount for every completable role in the validated CRFs
    let roleCount = {};
    allCompletableRoles.forEach((r) => (roleCount[r.roleId] = 0));
    // create a count of each completable role as it is found in each CRF
    validatedCrfs.crfDtos.forEach((d) =>
      d.completableRoles.forEach((cr) => {
        roleCount[cr.roleId]++;
      }),
    );
    // roles to include will only be those that are present in every CRF
    let roleIdsToInclude = Object.keys(roleCount).filter(
      (k) => roleCount[k] === validatedCrfs.crfDtos.length,
    );
    let alreadySeen = [];
    // add the Administrator and SAHMRI roles as they are always assignable (even if they are not able to complete CRFs)
    let toReturn = [{ roleId: 1 }, { roleId: 4 }];
    // create a list containing one each of the roles to include (like doing a distinct)
    allCompletableRoles.forEach((cr) => {
      if (
        roleIdsToInclude.includes(cr.roleId.toString()) &&
        !alreadySeen.includes(cr.roleId)
      ) {
        toReturn.push(cr);
        alreadySeen.push(cr.roleId);
      }
    });
    return toReturn;
  };

  const validate = () => {
    if (isSingleMode()) {
      dispatch(startLoading());
      const payload = { ids: [[state.id1, state.id2]] };
      if (isPatientsQuery()) {
        validatePatients(payload)
          .then((response) => {
            setState({ error: null, validated: response.data[0] });
          })
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            }
          })
          .finally(() => dispatch(finishLoading()));
      } else if (isProceduresQuery()) {
        validateProcedures(payload)
          .then((response) => {
            setState({ error: null, validated: response.data[0] });
          })
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            }
          })
          .finally(() => dispatch(finishLoading()));
      } else if (isCrfsQuery()) {
        validateCrfs(payload)
          .then((response) => {
            let crfCompletableRolesByType =
              buildCrfAssignableRolesUsingCrfTypes(response.data[0]);
            setState({
              error: null,
              validated: response.data[0],
              crfRolesProvided: crfCompletableRolesByType,
            });
          })
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            }
          })
          .finally(() => dispatch(finishLoading()));
      } else if (isProcedureCollectionsQuery()) {
        validateProcedureCollections(payload)
          .then((response) => {
            setState({ error: null, validated: response.data[0] });
          })
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            }
          })
          .finally(() => dispatch(finishLoading()));
      }
    } else if (isBulkMode()) {
      dispatch(startLoading());
      const results = Papa.parse(state.rawCsv, {
        skipEmptyLines: true,
        dynamicTyping: true,
      });
      // Bail out if there are any errors
      if (results.errors.length > 0) {
        if (
          results.errors.some((error) => error.code === "UndetectableDelimiter")
        ) {
          setState({
            error:
              "Unable to detect a delimiter - do you have at least two comma-separated IDs in every row?",
          });
          dispatch(finishLoading());
        } else {
          setState({ error: JSON.stringify(results.errors) });
          dispatch(finishLoading());
        }
        return;
      }
      // Need to check that:
      // * There are two values in each row
      // * That they're both numbers
      // * That there are no duplicates
      const errors = [];
      const allNumbers = [];
      results.data.forEach((row, rowIndex) => {
        row.forEach((colValue, colIndex) => {
          if (typeof colValue !== "number") {
            if (colValue == null) {
              errors.push(
                "The ID Value in Row " +
                  (rowIndex + 1) +
                  ", Column " +
                  (colIndex + 1) +
                  " is empty/missing",
              );
            } else {
              errors.push(
                "The ID Value in Row " +
                  (rowIndex + 1) +
                  ", Column 1 (" +
                  (colIndex + 1) +
                  ") is not a number",
              );
            }
          } else if (allNumbers.includes(colValue)) {
            errors.push(
              "The ID Value in Row " +
                (rowIndex + 1) +
                ", Column 1 (" +
                (colIndex + 1) +
                ") is present more than once",
            );
          } else {
            allNumbers.push(colValue);
          }
        });
      });
      if (errors.length > 0) {
        setState({ error: errors.join("<br/>") });
        dispatch(finishLoading());

        return;
      }

      // We're ready to do server-side validation now
      let payload = { ids: results.data };
      if (isPatientsQuery()) {
        validatePatients(payload)
          .then((response) => {
            setState({ error: null, validated: response.data });
          })
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            }
          })
          .finally(() => dispatch(finishLoading()));
      } else if (isProceduresQuery()) {
        validateProcedures(payload)
          .then((response) => {
            setState({ error: null, validated: response.data });
          })
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            }
          })
          .finally(() => dispatch(finishLoading()));
      } else if (isCrfsQuery()) {
        validateCrfs(payload)
          .then((response) => {
            setState({ error: null, validated: response.data });
          })
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            }
          })
          .finally(() => dispatch(finishLoading()));
      } else if (isProcedureCollectionsQuery()) {
        validateProcedureCollections(payload)
          .then((response) => {
            setState({ error: null, validated: response.data });
          })
          .catch((error) => {
            if (
              error?.response?.data &&
              typeof error.response.data === "string"
            ) {
              setState({ error: error.response.data });
            }
          })
          .finally(() => dispatch(finishLoading()));
      }
    }
  };

  const reset = () => {
    setState({ ...resetState });
  };

  const create = () => {
    if (canSubmit()) {
      dispatch(startLoading());
      let payload;
      if (isSingleMode()) {
        payload = [
          {
            attribute: state.multiDataEntity.table,
            assignedToId: state.assignTo == null ? null : state.assignTo.id,
            comment: state.comment,
            public: state.isPublic,
            referencedIds: [state.id1, state.id2],
          },
        ];
      } else if (isBulkMode()) {
        let referIds = [];
        payload = [];
        state.validated.forEach((valid) => {
          if (isPatientsQuery()) {
            referIds = valid.map((patient) => patient.id);
          } else if (isProceduresQuery()) {
            referIds = valid.procedures.map((procedure) => procedure.id);
          } else if (isCrfsQuery()) {
            referIds = valid.crfDtos.map((crf) => crf.id);
          } else if (isProcedureCollectionsQuery()) {
            referIds = valid.procedureCollectionsDtos.map(
              (procColl) => procColl.id,
            );
          }
          payload.push({
            attribute: state.multiDataEntity.table,
            assignedToId: state.assignTo == null ? null : state.assignTo.id,
            comment: state.comment,
            referencedIds: referIds,
          });
        });
      }
      createDataQuery(payload)
        .then(() => {
          toast.success(
            <span>
              <FaCheck /> Data Query Created
            </span>,
          );
          reset();
          createCallback();
        })
        .catch((error) => {
          if (
            error?.response?.data &&
            typeof error.response.data === "string"
          ) {
            setState({ error: error.response.data });
          }
        })
        .finally(() => dispatch(finishLoading()));
    }
  };

  return (
    <Card className={"create-multi-data-query"}>
      <CardBody>
        <CardTitle>Create Duplicate Data Query</CardTitle>
        <Row className={"my-3"}>
          <Col xs={3} className={"text-end my-auto"}>
            Mode
          </Col>
          <Col xs={3}>
            <ButtonGroup>
              <Button
                color={isSingleMode() ? "primary" : "secondary"}
                disabled={state.validated != null}
                onClick={() => setState({ mode: "SINGLE" })}
              >
                Single
              </Button>
              <Button
                color={isBulkMode() ? "primary" : "secondary"}
                disabled={state.validated != null}
                onClick={() => setState({ mode: "BULK" })}
              >
                Bulk
              </Button>
            </ButtonGroup>
          </Col>
          <Col xs={3} className={"text-end my-auto"}>
            Type
          </Col>
          <Col xs={3}>
            <GenericSelector
              options={getMultiDataEntities()}
              selected={state.multiDataEntity}
              changeCallback={(value) => setState({ multiDataEntity: value })}
              readOnly={state.validated != null}
            />
          </Col>
        </Row>
        {isSingleMode() && (
          <Row className={"my-3"}>
            <Col xs={3} className={"text-end my-auto"}>
              {state.multiDataEntity.singular} ID #1:
            </Col>
            <Col xs={3}>
              <Input
                type={"text"}
                value={state.id1}
                disabled={state.validated != null}
                onKeyPress={handleKeyPress}
                onChange={(event) => setState({ id1: event.target.value })}
              />
            </Col>
            <Col xs={3} className={"text-end my-auto"}>
              {state.multiDataEntity.singular} ID #2:
            </Col>
            <Col xs={3}>
              <Input
                type={"text"}
                value={state.id2}
                disabled={state.validated != null}
                onKeyPress={handleKeyPress}
                onChange={(event) => setState({ id2: event.target.value })}
              />
            </Col>
          </Row>
        )}
        {isBulkMode() && (
          <Fragment>
            <Row>
              <Col>
                <FormText color="muted">
                  Please paste in a comma or tab-separated list of{" "}
                  {state.multiDataEntity.singular} IDs (one line per query) to
                  bulk-create duplicate data queries for:
                </FormText>
                <TextareaAutosize
                  type={"textarea"}
                  className={"free-text-multi"}
                  value={state.rawCsv}
                  disabled={state.validated != null}
                  onChange={(event) => setState({ rawCsv: event.target.value })}
                />
              </Col>
            </Row>
          </Fragment>
        )}
        {state.validated == null && !isEmptyOrNull(state.error) && (
          <Alert color={"danger"}>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(state.error),
              }}
            />
          </Alert>
        )}
        <Row className={"my-3"}>
          <Col>
            <Button
              color={"primary"}
              disabled={!canValidate()}
              onClick={validate}
            >
              Next
            </Button>
            <Button
              className={"ms-3"}
              color={"secondary"}
              disabled={state.validated == null}
              onClick={() =>
                setState({
                  validated: null,
                  error: null,
                  assignTo: null,
                  assigneeUsers: null,
                })
              }
            >
              Cancel
            </Button>
          </Col>
        </Row>
        {state.validated != null && (
          <Fragment>
            {isPatientsQuery() &&
              isSingleMode() &&
              getDataQueryPatientsTable(state.validated)}
            {isPatientsQuery() && isBulkMode() && (
              <Fragment>
                {state.validated.map((valid, index) => {
                  return (
                    <Row key={"bulk-validated-patient-" + index}>
                      <Col>{getDataQueryPatientsTable(valid)}</Col>
                    </Row>
                  );
                })}
              </Fragment>
            )}
            {isProceduresQuery() &&
              isSingleMode() &&
              getDataQueryProceduresTable(
                state.validated.patient,
                state.validated.procedures,
              )}
            {isProceduresQuery() && isBulkMode() && (
              <Fragment>
                {state.validated.map((valid, index) => {
                  return (
                    <Fragment key={"validated-result-" + index}>
                      <Row key={"bulk-validated-procedures-" + index}>
                        <Col>
                          {getDataQueryProceduresTable(
                            valid.patient,
                            valid.procedures,
                          )}
                        </Col>
                      </Row>
                      <hr />
                    </Fragment>
                  );
                })}
              </Fragment>
            )}
            {isCrfsQuery() &&
              isSingleMode() &&
              getDataQueryCrfsTable(
                state.validated.patient,
                state.validated.procedure,
                state.validated.crfDtos,
              )}
            {isCrfsQuery() && isBulkMode() && (
              <Fragment>
                {state.validated.map((valid, index) => {
                  return (
                    <Row key={"bulk-validated-crf-" + index}>
                      <Col>
                        {getDataQueryCrfsTable(
                          valid.patient,
                          valid.procedure,
                          valid.crfDtos,
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Fragment>
            )}
            {isProcedureCollectionsQuery() &&
              isSingleMode() &&
              getDataQueryProcedureCollectionsTable(
                state.validated.patient,
                state.validated.procedure,
                state.validated.procedureCollectionsDtos,
              )}
            {isProcedureCollectionsQuery() && isBulkMode() && (
              <Fragment>
                {state.validated.map((valid, index) => {
                  return (
                    <Row key={"bulk-validated-proc-coll-" + index}>
                      <Col>
                        {getDataQueryProcedureCollectionsTable(
                          valid.patient,
                          valid.procedure,
                          valid.procedureCollectionsDtos,
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Fragment>
            )}
            {!isEmptyOrNull(state.error) && (
              <Alert color={"danger"}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(state.error),
                  }}
                />
              </Alert>
            )}
            <Row className={"my-3"}>
              <Col xs={12}>Comment*</Col>
              <Col xs={12}>
                <TextareaAutosize
                  type={"textarea"}
                  className={"free-text-multi"}
                  value={state.comment}
                  onChange={(event) =>
                    setState({ comment: event.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name={"isPublic"}
                      onChange={() => setState({ isPublic: !state.isPublic })}
                      checked={state.isPublic}
                    />
                    {" Public"}
                  </Label>
                  <FaQuestionCircle
                    id={"is-public-icon"}
                    className={"tooltip-icon"}
                  />
                  <UncontrolledTooltip placement="top" target="is-public-icon">
                    Show comment to external users (e.g. Hospital
                    Administrators)
                  </UncontrolledTooltip>
                </FormGroup>
              </Col>
            </Row>
            {!!state.assignTo &&
              assigneeNeedsUsers(state.assignTo) &&
              !!state.assigneeUsers &&
              state.assigneeUsers.length > 0 && (
                <Fragment>
                  <Alert
                    color={"primary"}
                    className={"my-3 assignee-users-list"}
                  >
                    The following users will be assigned to this data query:
                    <ul>
                      {state.assigneeUsers.map((user, index) => {
                        return <li key={"assignee-user-" + index}>{user}</li>;
                      })}
                    </ul>
                  </Alert>
                  {!state.isPublic && (
                    <Alert color={"danger"}>
                      The creation comment must be public if assigning to an
                      external group
                    </Alert>
                  )}
                </Fragment>
              )}
            {!!state.assignTo &&
              assigneeNeedsUsers(state.assignTo) &&
              !!state.assigneeUsers &&
              state.assigneeUsers.length === 0 && (
                <Alert color={"danger"} className={"my-3"}>
                  At least one external user must be identified as having access
                  to the referenced {state.multiDataEntity.name} before it can
                  be assigned to this external group
                </Alert>
              )}
            <Row className={"my-auto"}>
              <Col xs={2} className={"text-end"}>
                Assign To*
              </Col>
              <Col xs={4}>
                <SimpleRoleSelector
                  value={state.assignTo}
                  clearable={false}
                  searchable={false}
                  onChange={assignToChanged}
                  filter={isCrfsQuery() ? crfRoleFilter : roleFilter}
                />
              </Col>
              <Col>
                <Button
                  color={"primary"}
                  disabled={!canSubmit()}
                  onClick={create}
                >
                  Create
                </Button>
              </Col>
            </Row>
          </Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default CreateMultiDataQuery;

import { isAdministratorUser, isAoaUser, isSahmriUser } from "../../Utils";

export const PATIENT_COMPARISON_TYPE = {
  NATIONAL: {
    field: "national",
    className: "national",
    title: "National results",
    legendText: "people having this operation",
    colour: "#00ccbc",
    label: "National",
    payload: {},
  },
  AGE: {
    field: "age",
    className: "age",
    title: "Your Age",
    legendText: "people your age having this operation",
    colour: "#2C7BB6",
    label: "Age",
    payload: { patientFilterAge: true },
    payloadNew: { patientAge: true },
  },
  SEX: {
    field: "sex",
    className: "gender",
    title: "Your Gender",
    legendText: "{{gender}}s having this operation",
    alternateLegendText: "patients with the same gender having this operation",
    colour: "#f9c09e",
    label: "Gender",
    payload: { patientFilterSex: true },
    payloadNew: { patientSex: true },
  },
  AGEANDSEX: {
    field: "ageAndSex",
    className: "agegender",
    title: "Your Age and Gender",
    legendText: "{{gender}}s your age having this operation",
    alternateLegendText: "patients with the same gender and age",
    colour: "#F37AF9",
    label: "Age & Gender",
    payload: { patientFilterAge: true, patientFilterSex: true },
    payloadNew: { patientAge: true, patientSex: true },
  },
};

export const STAKEHOLDER_COMPARISON_BASE = {
  field: "national",
  title: "National Results",
  filter: {},
};

export const STAKEHOLDER_COMPARISON_TYPE = [
  {
    field: "male",
    title: "Male",
    desc: "who are male",
    group: "a",
    filter: { sex: "M" },
  },
  {
    field: "female",
    title: "Female",
    desc: "who are female",
    group: "a",
    filter: { sex: "F" },
  },
  {
    field: "primary",
    title: "Primary procedure",
    desc: "for their primary procedure",
    group: "b",
    filter: { revision: false },
  },
  {
    field: "revision",
    title: "Revision procedure",
    desc: "for their procedure revision",
    group: "b",
    filter: { revision: true },
  },
  {
    field: "<55",
    title: "<55 Yrs.",
    desc: "aged <55 years",
    group: "c",
    filter: { ageBracket: "<55" },
  },
  {
    field: "55-64",
    title: "55-64 Yrs.",
    desc: "aged between 55-64 years",
    group: "c",
    filter: { ageBracket: "55-64" },
  },
  {
    field: "65-74",
    title: "65-74 Yrs.",
    desc: "aged between 65-74 years",
    group: "c",
    filter: { ageBracket: "65-74" },
  },
  {
    field: "≥75",
    title: "≥75 Yrs.",
    desc: "aged ≥75 years",
    group: "c",
    filter: { ageBracket: "≥75" },
  },
  {
    field: "matchedAgnostic",
    title: "Include unmatched procedures",
    desc: "include procedures regardless of their matched status",
    group: "d",
    filter: { matchedAgnostic: true },
  },
];

export function canCompareAllStudies(user) {
  return isAoaUser(user) || isSahmriUser(user) || isAdministratorUser(user);
}
import classnames from "classnames";
import {
  CREATE,
  isEmptyOrNull,
  isFollowUpRoleId,
  isHospitalAdministratorRoleId,
  isHospitalStudyCoordinatorRoleId,
  isStakeholderRoleId,
  isStudyCoordinatorRoleId,
  isSurgeonRoleId,
  validateEmailAddress,
  validateMobile,
  validateSurgeonCode,
  validName,
  VIEW,
} from "../../../Utils";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import PropTypes from "prop-types";
import UserHospitalLinking from "./UserHospitalLinking";
import UserHospitalStudyLinking from "./UserHospitalStudyLinking";
import StudyLinking from "../../study/Linking";
import StakeholderGroupLinking from "../stakeholder/Linking";
import UserSurgeonLinking from "../surgeon/Linking";
import { useEffect, useState } from "react";

const Details = ({
  operation = VIEW,
  firstName = "",
  lastName = "",
  email = "",
  emailSuffix = "",
  mobile = "",
  surgeonCode = "",
  hospitalLinks = [],
  studyLinks = [],
  surgeonLinks = [],
  stakeholderGroupLinks = [],
  uploader = false,
  patientResponseAccess = false,
  changeCallback,
  hospitalLinkCallback,
  hospitalStudyLinkCallback,
  userId,
  hospitalStudyLinks,
  stakeholderGroupLinkCallback,
  surgeonLinkCallback,
  roleId,
  studyLinkCallback,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [emailValidation, setEmailValidation] = useState("");

  useEffect(() => {
    validateEmail();
  }, [email, stakeholderGroupLinks]);

  const getEmail = () => {
    if (isEmptyOrNull(emailSuffix)) {
      return email;
    } else {
      return `${email}@${emailSuffix}`;
    }
  };

  const validateEmail = () => {
    const email = getEmail();
    let basicCheck = validateEmailAddress(email);
    if (basicCheck) {
      setEmailValidation("");
      stakeholderGroupLinks.forEach((link) => {
        let found = link.emailSuffixes.length === 0;
        link.emailSuffixes.forEach((suffix) => {
          if (email.endsWith("@" + suffix)) {
            found = true;
          }
        });
        if (!found) {
          setEmailValidation(
            'Stakeholder group "' +
              link.name +
              '" requires the user to have an email suffix of one of the following: ' +
              link.emailSuffixes.join(", "),
          );
        }
      });
    } else {
      setEmailValidation("Email must be in the format ____@_____.___");
    }
  };

  const isSurgeon = isSurgeonRoleId(roleId);
  const isHospitalStudyCoordinator = isHospitalStudyCoordinatorRoleId(roleId);
  const isStudyCoordinator = isStudyCoordinatorRoleId(roleId);
  const isHospitalAdministrator = isHospitalAdministratorRoleId(roleId);
  const isStakeholder = isStakeholderRoleId(roleId);
  const isFollowUp = isFollowUpRoleId(roleId);
  const isSmsMfaUser = isStakeholder || isStudyCoordinator || isFollowUp;
  const largeModal =
    isSurgeon || isHospitalAdministrator || isHospitalStudyCoordinator;
  return (
    <div>
      <div className={"field-row"}>
        <em>Note: Fields marked with an * are required</em>
      </div>
      <Form>
        <FormGroup>
          <Row className={"field-row"}>
            <Col xs={12} sm={6} md={largeModal ? 2 : 3}>
              <Label className={"form-label"} for="firstName">
                First Name*
              </Label>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={largeModal ? 4 : 9}
              className={classnames({ "field-row": !largeModal })}
            >
              <Input
                autoFocus
                required
                type="text"
                id="firstName"
                value={firstName}
                readOnly={operation === VIEW}
                onChange={changeCallback}
                valid={
                  isEmptyOrNull(firstName) && operation === CREATE
                    ? null
                    : validName(firstName)
                }
              />
              {!validName(firstName) && (
                <FormFeedback>
                  First name is required and cannot contain numbers or
                  non-standard symbols.
                </FormFeedback>
              )}
            </Col>
            <Col xs={12} sm={6} md={largeModal ? 2 : 3}>
              <Label className={"form-label"} for="lastName">
                Last Name*
              </Label>
            </Col>
            <Col xs={12} sm={6} md={largeModal ? 4 : 9}>
              <Input
                required
                type="text"
                id="lastName"
                value={lastName}
                onChange={changeCallback}
                readOnly={operation === VIEW}
                valid={
                  isEmptyOrNull(lastName) && operation === CREATE
                    ? null
                    : validName(lastName)
                }
              />
              {!validName(lastName) && (
                <FormFeedback>
                  Last name is required and cannot contain numbers or
                  non-standard symbols.
                </FormFeedback>
              )}
            </Col>
          </Row>
          <Row className={isSmsMfaUser ? null : "field-row"}>
            <Col xs={12} sm={6} md={largeModal ? 2 : 3}>
              <Label className={"form-label"} for="email">
                Email*
              </Label>
            </Col>
            <Col xs={12} sm={6} md={largeModal ? 6 : 9}>
              <FormGroup>
                <Input
                  required
                  type="text"
                  id="email"
                  value={getEmail()}
                  onChange={changeCallback}
                  readOnly={operation === VIEW}
                  valid={
                    isEmptyOrNull(email) && operation === CREATE
                      ? null
                      : isEmptyOrNull(emailValidation)
                  }
                />
                <FormFeedback>{emailValidation}</FormFeedback>
              </FormGroup>
            </Col>

            {isSurgeon && (
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id={"uploader"}
                      checked={uploader}
                      disabled={operation === VIEW}
                      onChange={changeCallback}
                    />{" "}
                    PROMs Uploader
                  </Label>
                </FormGroup>
              </Col>
            )}
          </Row>
          {isSmsMfaUser && (
            <Row className={"field-row"}>
              <Col xs={12} sm={6} md={largeModal ? 2 : 3}>
                <Label className={"form-label"} for="mobile">
                  Mobile
                </Label>
              </Col>
              <Col xs={12} sm={6} md={largeModal ? 6 : 9}>
                <FormGroup>
                  <Input
                    required
                    type="text"
                    id="mobile"
                    value={mobile}
                    onChange={changeCallback}
                    readOnly={operation === VIEW}
                    valid={
                      isEmptyOrNull(mobile) ? null : validateMobile(mobile)
                    }
                  />
                  <FormFeedback>
                    Mobile must be in the format 04########.
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          )}
          {(isHospitalAdministrator || isHospitalStudyCoordinator) && (
            <Row className={"field-row"}>
              <Col className={"text-end"}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id={"patientResponseAccess"}
                      checked={patientResponseAccess}
                      disabled={operation === VIEW}
                      onChange={changeCallback}
                    />{" "}
                    Patient Response Access
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id={"uploader"}
                      checked={uploader}
                      disabled={operation === VIEW}
                      onChange={changeCallback}
                    />{" "}
                    PROMs Uploader
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          )}
          {(isStudyCoordinator || isFollowUp) && (
            <div>
              <StudyLinking
                readOnly={operation === VIEW}
                links={studyLinks}
                sourceObjectName={"user"}
                onLinkChange={studyLinkCallback}
              />
            </div>
          )}
          {isSurgeon && (
            <div>
              <Row className={"field-row"}>
                <Col xs={12} md={2} lg={2}>
                  <Label className={"form-label"} for="mobile">
                    Mobile*
                  </Label>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <FormGroup>
                    <Input
                      required
                      type="text"
                      id="mobile"
                      value={mobile}
                      onChange={changeCallback}
                      readOnly={operation === VIEW}
                      valid={
                        isEmptyOrNull(mobile) && operation === CREATE
                          ? null
                          : validateMobile(mobile)
                      }
                    />
                    <FormFeedback>
                      Mobile must be in the format 04## ### ###.
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12} md={7} lg={4}>
                  <Label className={"form-label"} for="surgeonCode">
                    AOA/AOANJRR Surgeon Code*
                  </Label>
                </Col>
                <Col xs={12} md={5} lg={2}>
                  <FormGroup>
                    <Input
                      required
                      type="text"
                      id="surgeonCode"
                      readOnly={operation === VIEW}
                      valid={
                        isEmptyOrNull(surgeonCode) && operation === CREATE
                          ? null
                          : validateSurgeonCode(surgeonCode)
                      }
                      value={surgeonCode == null ? "" : surgeonCode}
                      onChange={changeCallback}
                    />
                    <FormFeedback>
                      This does not appear to be a valid Surgeon Code
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <UserHospitalStudyLinking
                      readOnly={operation === VIEW}
                      hospitalStudyLinks={hospitalStudyLinks}
                      userId={userId}
                      onLinkChange={hospitalStudyLinkCallback}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          )}
          {isHospitalStudyCoordinator && (
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    Hospital Studies
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <UserHospitalStudyLinking
                    readOnly={operation === VIEW}
                    hospitalStudyLinks={hospitalStudyLinks}
                    userId={userId}
                    onLinkChange={hospitalStudyLinkCallback}
                  />
                </TabPane>
              </TabContent>
            </div>
          )}

          {isHospitalAdministrator && (
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    Hospitals
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      setActiveTab("2");
                    }}
                  >
                    Surgeons
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <UserHospitalLinking
                    readOnly={operation === VIEW}
                    links={hospitalLinks}
                    onLinkChange={hospitalLinkCallback}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <UserSurgeonLinking
                    readOnly={operation === VIEW}
                    links={surgeonLinks}
                    onLinkChange={surgeonLinkCallback}
                    sourceObjectName={"user"}
                  />
                </TabPane>
              </TabContent>
            </div>
          )}

          {isStakeholder && (
            <StakeholderGroupLinking
              readOnly={operation === VIEW}
              links={stakeholderGroupLinks}
              onLinkChange={stakeholderGroupLinkCallback}
            />
          )}
        </FormGroup>
      </Form>
    </div>
  );
};

Details.propTypes = {
  operation: PropTypes.string,
  userId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  emailSuffix: PropTypes.string,
  roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobile: PropTypes.string,
  surgeonCode: PropTypes.string,
  changeCallback: PropTypes.func,
  hospitalLinkCallback: PropTypes.func,
  hospitalStudyLinkCallback: PropTypes.func,
  studyLinkCallback: PropTypes.func,
  surgeonLinkCallback: PropTypes.func,
  stakeholderGroupLinkCallback: PropTypes.func,
  hospitalLinks: PropTypes.arrayOf(PropTypes.object),
  hospitalStudyLinks: PropTypes.arrayOf(PropTypes.object),
  studyLinks: PropTypes.arrayOf(PropTypes.object),
  surgeonLinks: PropTypes.arrayOf(PropTypes.object),
  stakeholderGroupLinks: PropTypes.arrayOf(PropTypes.object),
  uploader: PropTypes.bool,
  patientResponseAccess: PropTypes.bool,
};

export default Details;

import { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import LargeNavButton from "./LargeNavButton";
import {
  FaChartLine,
  FaEdit,
  FaHistory,
  FaInfoCircle,
  FaPlusCircle,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCollectionsAvailable,
  loadPreviousResponses,
} from "../../actions/PatientActions";
import PatientInfoSheets from "../consent/PatientInfoSheets";
import StartSurveyModal from "../patient/StartSurveyModal";
import { getConsentedStudyList } from "../../api/Patient";
import CompletedCollections from "../responses/CompletedCollections";
import ConfirmPatientDetailsModal from "../patient/ConfirmPatientDetailsModal";
import OptBackInModal from "../patient/OptBackInModal";
import { useNavigate } from "react-router-dom";

const PatientHome = () => {
  const patient = useSelector((state) => state.patient);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showInfoSheetModal, setShowInfoSheetModal] = useState(false);
  const [showStartSurveyModal, setShowStartSurveyModal] = useState(false);
  const [infoSheetsAvailable, setInfoSheetsAvailable] = useState(false);
  const [hasPreviousResponses, setHasPreviousResponses] = useState(false);
  const [hasAvailableCollections, setHasAvailableCollections] = useState(
    patient.hasAvailableCollections.hasAvailableCollections,
  );
  const [canCompare, setCanCompare] = useState(false);
  const [showPreviousResponseModal, setShowPreviousResponseModal] =
    useState(false);
  const [ignoreOptOut, setIgnoreOptOut] = useState(false);
  const [typeOfCollection, setTypeOfCollection] = useState("");

  useEffect(() => {
    loadInitialData();
  }, [ignoreOptOut]);

  useEffect(() => {
    let _canCompare = patient.previousResponses.some((response) => {
      return response.instrumentScores.some((scores) => {
        return scores.okToDisplay;
      });
    });
    let _hasPreviousResponses = patient.previousResponses.length > 0;

    setCanCompare(_canCompare);
    setHasPreviousResponses(_hasPreviousResponses);
    setHasAvailableCollections(
      patient.hasAvailableCollections.hasAvailableCollections,
    );
    setTypeOfCollection(patient.hasAvailableCollections.typeOfCollection);
  }, [patient.hasAvailableCollections, patient.previousResponses]);

  const loadInitialData = () => {
    dispatch(loadPreviousResponses());
    dispatch(checkCollectionsAvailable());
    getConsentedStudyList().then((response) => {
      setInfoSheetsAvailable(response.data.length > 0);
    });
  };

  const getSubTitle = (typeOfCollection) => {
    if (typeOfCollection === "PostOp")
      return "Click here to complete your questions which are now due following your operation";
    else if (typeOfCollection === "PreOp-Outdated")
      return "Our records show you completed your responses over 6-months ago, please click here if you would like to re-do them.";
    else if (typeOfCollection === "PreOp")
      return "Click here to complete your next set of responses.";
    else if (typeOfCollection === "Resumable")
      return "Click here to complete your remaining questions.";
  };

  const ignoreOptOutCallback = () => {
    patient.procedures.forEach((proc) => (proc.optOutDate = null));
    setIgnoreOptOut(true);
  };

  const optOutFound = patient.procedures.every((p) => !!p.optOutDate);
  if (!ignoreOptOut && optOutFound) {
    return (
      <Container>
        <OptBackInModal callBack={ignoreOptOutCallback} isOpen={true} />
      </Container>
    );
  }
  return (
    <Container>
      {!!showStartSurveyModal && (
        <StartSurveyModal
          open={!!showStartSurveyModal}
          confirmCallback={() => navigate("/consent/1")}
          exitCallback={() => setShowStartSurveyModal(false)}
        />
      )}
      {!!showPreviousResponseModal && (
        <CompletedCollections
          previousResponses={patient.previousResponses}
          isOpen={showPreviousResponseModal}
          toggle={() =>
            setShowPreviousResponseModal(!showPreviousResponseModal)
          }
          responsesTableModal={true}
          instrumentTableModal={true}
          patientView={true}
        />
      )}

      {patient.detailsConfirmed === false && (
        <ConfirmPatientDetailsModal
          isOpen={patient.detailsConfirmed === false}
        />
      )}
      <Row>
        <Col xs={"1"} />
        <Col xs={10} className={"welcome-text aoa-title"}>
          Welcome {patient.firstName}
        </Col>
        <Col xs={"1"} />
      </Row>
      {hasAvailableCollections && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <LargeNavButton
              to={null}
              onClick={() => setShowStartSurveyModal(true)}
              icon={<FaEdit />}
              standout={true}
              title={"Start Questions Now"}
              extraPadding={true}
              subtitle={getSubTitle(typeOfCollection)}
            />
          </Col>
        </Row>
      )}
      {patient.canRegisterNewProcedure && !hasAvailableCollections && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <LargeNavButton
              to={"/patient/register/state"}
              icon={<FaPlusCircle />}
              title={"Register New Procedure"}
              subtitle={
                "Click here to register a new joint replacement procedure"
              }
            />
          </Col>
        </Row>
      )}
      {infoSheetsAvailable && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <LargeNavButton
              to={null}
              icon={<FaInfoCircle />}
              title={"Patient Information Sheets"}
              subtitle={
                "Click here to download patient information sheets for the studies that you have consented to"
              }
              onClick={() => setShowInfoSheetModal(true)}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {hasPreviousResponses && (
            <LargeNavButton
              to={null}
              icon={<FaHistory />}
              title={"My Previous Responses"}
              subtitle={
                "Click here to see how you have answered surveys in the past"
              }
              onClick={() => setShowPreviousResponseModal(true)}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {canCompare && (
            <LargeNavButton
              to={null}
              icon={<FaChartLine />}
              title={"How Do I Compare?"}
              subtitle={
                "Click here to see how your responses compare to other patients"
              }
              onClick={() => navigate("/compare")}
              standout={true}
            />
          )}
        </Col>
      </Row>
      {!!showInfoSheetModal && (
        <PatientInfoSheets
          open={!!showInfoSheetModal}
          toggle={() => setShowInfoSheetModal(false)}
        />
      )}
    </Container>
  );
};

export default PatientHome;

import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  dateValidationTypes: [],
  dateValidationOperators: [],
  dateTimeValidationTypes: [],
  dateTimeValidationOperators: [],
  questionTypes: [],
  stringValidationOperators: [],
  integerValidationTypes: [],
  decimalValidationTypes: [],
};

export default handleActions(
  {
    CRF_DATE_VALIDATION_TYPES_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ dateValidationTypes: payload } };
    },
    CRF_DATE_VALIDATION_OPERATORS_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ dateValidationOperators: payload } };
    },
    CRF_DATETIME_VALIDATION_TYPES_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ dateTimeValidationTypes: payload } };
    },
    CRF_DATETIME_VALIDATION_OPERATORS_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ dateTimeValidationOperators: payload } };
    },
    CRF_QUESTION_TYPES_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ questionTypes: payload } };
    },
    CRF_STRING_VALIDATION_OPERATORS_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ stringValidationOperators: payload } };
    },
    CRF_INTEGER_VALIDATION_TYPES_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ integerValidationTypes: payload } };
    },
    CRF_DECIMAL_VALIDATION_TYPES_LOADED: (state, { payload }) => {
      payload.sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, ...{ decimalValidationTypes: payload } };
    },
  },
  INITIAL_STATE,
);

import PropTypes from "prop-types";
import {
  ANSWER_FIELD_TYPES,
  isDateQuestion,
  isDateTimeQuestion,
  isDecimalQuestion,
  isFreeTextMultipleLinesQuestion,
  isFreeTextQuestion,
  isIntegerQuestion,
  isMultipleChoiceMultipleSelectionQuestion,
  isMultipleChoiceSingleSelectionQuestion,
  shouldShowBasedOnTrigger,
  updateAnswers,
  updateCheckboxAnswer,
  YEAR_FIRST_DATE_FORMAT,
  YEAR_FIRST_MERIDIAN_DATE_TIME_FORMAT,
} from "./Utils";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { isEmptyOrNull } from "../../Utils";
import "./CrfType.css";
import Moment from "moment";
import CrfDateQuestion from "./CrfDateQuestion";
import CrfCheckboxQuestion from "./CrfCheckboxQuestion";
import CrfRadioQuestion from "./CrfRadioQuestion";
import CrfFreeTextQuestion from "./CrfFreeTextQuestion";
import CrfNumberQuestion from "./CrfNumberQuestion";
import Responsive from "react-responsive";
import { getSortedQuestions } from "../management/crf-type/Utils";
import CrfDateTimeQuestion from "./CrfDateTimeQuestion";
import { Fragment, useEffect } from "react";

const ChildCrfType = ({
  childType = null,
  answers = [],
  index = 0,
  updateAnswersCallback = (index, childType, answers) => null,
  unmountCallback = (index) => null,
  readOnly = true,
  showValidationErrors = false,
  crfId,
  crfProcId,
  crfType,
  dataQueries,
  onConfirm,
  onExit,
}) => {
  useEffect(() => {
    return unmountCallback(index);
  }, []);

  const handleRadioChange = (questionId, choiceId) => {
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.RADIO,
      choiceId,
    );
    updateAnswersCallback(index, childType, updated);
  };

  const handleCheckboxChange = (questionId, choiceId) => {
    let updated = updateCheckboxAnswer(answers, questionId, choiceId);
    updateAnswersCallback(index, childType, updated);
  };

  const handleFreeTextChange = (questionId, event) => {
    const value = event?.target?.value ? event.target.value : null;
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.FREE_TEXT,
      value,
    );
    updateAnswersCallback(index, childType, updated);
  };

  const handleIntegerChange = (questionId, value) => {
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.INTEGER,
      value,
    );
    updateAnswersCallback(index, childType, updated);
  };

  const handleDecimalChange = (questionId, value) => {
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.DECIMAL,
      value,
    );
    updateAnswersCallback(index, childType, updated);
  };

  const handleDateChange = (questionId, value) => {
    let newValue =
      value !== null ? Moment(value).format(YEAR_FIRST_DATE_FORMAT) : value;
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.DATE,
      newValue,
    );
    updateAnswersCallback(index, childType, updated);
  };

  const handleDateTimeChange = (questionId, value) => {
    let newValue =
      value !== null
        ? Moment(value).format(YEAR_FIRST_MERIDIAN_DATE_TIME_FORMAT)
        : value;
    let updated = updateAnswers(
      answers,
      questionId,
      ANSWER_FIELD_TYPES.DATE_TIME,
      newValue,
    );
    updateAnswersCallback(index, childType, updated);
  };

  const getQuestion = (_question, _answers, _index) => {
    if (shouldShowBasedOnTrigger(_question, _answers)) {
      if (isMultipleChoiceSingleSelectionQuestion(_question)) {
        return (
          <CrfRadioQuestion
            key={`radio-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            showValidationErrors={showValidationErrors}
            onChange={handleRadioChange}
            disabled={readOnly}
            crfProcId={crfProcId}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
          />
        );
      } else if (isMultipleChoiceMultipleSelectionQuestion(_question)) {
        return (
          <CrfCheckboxQuestion
            key={`check-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            onChange={handleCheckboxChange}
            index={_index}
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
            crfProcId={crfProcId}
          />
        );
      } else if (isFreeTextQuestion(_question)) {
        return (
          <CrfFreeTextQuestion
            key={`free-text-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            onChange={handleFreeTextChange}
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            multiLine={isFreeTextMultipleLinesQuestion(_question)}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
            crfProcId={crfProcId}
          />
        );
      } else if (isDateQuestion(_question)) {
        return (
          <CrfDateQuestion
            key={`date-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            onChange={handleDateChange}
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
            crfProcId={crfProcId}
          />
        );
      } else if (isDateTimeQuestion(_question)) {
        return (
          <CrfDateTimeQuestion
            key={`date-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            onChange={handleDateTimeChange}
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            dataQueries={dataQueries}
            crfId={crfId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
            crfProcId={crfProcId}
          />
        );
      } else if (isIntegerQuestion(_question) || isDecimalQuestion(_question)) {
        return (
          <CrfNumberQuestion
            key={`number-question-${_question.id}-index-${_index}`}
            question={_question}
            answers={_answers}
            index={_index}
            onChange={
              isIntegerQuestion(_question)
                ? handleIntegerChange
                : handleDecimalChange
            }
            showValidationErrors={showValidationErrors}
            disabled={readOnly}
            dataQueries={dataQueries}
            crfId={crfId}
            crfProcId={crfProcId}
            onConfirm={onConfirm}
            onExit={onExit}
            crfType={crfType}
          />
        );
      }
    } else {
      return null;
    }
  };

  return (
    <Card key={`childType-${childType.id}-index-${index}`}>
      <CardBody>
        <Fragment>
          <Table bordered striped responsive>
            <thead>
              <tr key={`childTypeRow-${childType.id}-index-${index}`}>
                <td>
                  {childType && !isEmptyOrNull(childType.name) && (
                    <Responsive minWidth={375}>
                      <CardTitle>
                        {childType.name} {index + 1}
                      </CardTitle>
                    </Responsive>
                  )}
                </td>
              </tr>
            </thead>
            <tbody>
              {getSortedQuestions(childType).map((question, idx) => {
                if (!isEmptyOrNull(getQuestion(question, answers, index))) {
                  return (
                    <tr
                      key={`question-row-${childType.id}-idx-${idx}-index-${index}`}
                    >
                      <Responsive minWidth={375}>
                        <td className={"crf-type"}>
                          {getQuestion(question, answers, index)}
                        </td>
                      </Responsive>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </Fragment>
      </CardBody>
    </Card>
  );
};

ChildCrfType.propTypes = {
  childType: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateAnswersCallback: PropTypes.func.isRequired,
  unmountCallback: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  dataQueries: PropTypes.arrayOf(PropTypes.object),
  crfId: PropTypes.number,
  onExit: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  crfType: PropTypes.object,
  crfProcId: PropTypes.number,
};

export default ChildCrfType;

import { usePageTracking } from "../CustomHooks";

/**
 * React component to implement page tracking for routes
 *
 * @param children components to be rendered
 * @returns {*}
 * @constructor
 */
export function PageTracking({ children }) {
  usePageTracking();
  return children;
}

import { Col, Container, Row } from "reactstrap";

const SurgeonQP = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Qualified Privilege</h2>
        </Col>
      </Row>
      <Row className={"field-row"}>
        <Col>
          <p>
            The data provided via this portal is protected by Qualified
            Privilege under Part VC of the <i> Health Insurance Act 1973</i>,
            and is outlined in this excerpt from the Commonwealth Government’s
            Qualified Privilege Scheme (QPS) Information Brochure:
          </p>
          <ul>
            <p>
              <i>
                The Commonwealth Qualified Privilege Scheme provides important
                safeguards for health care professionals who engage in effective
                quality assurance activities. This is important for all
                Australians – health care providers, patients, their carers and
                the community, as it helps to ensure the highest quality of our
                health care system.
              </i>
            </p>
            <p>
              <i>
                The Commonwealth Qualified Privilege Scheme encourages health
                care professionals to participate in quality assurance
                activities by providing:
              </i>
            </p>
            <li>
              {"\u2022"}{" "}
              <i>
                for the confidentiality of most information that identifies
                individuals and which becomes known as a result of declared
                quality assurance activities; and{" "}
              </i>
            </li>
            <li>
              {"\u2022"}{" "}
              <i>
                protection from civil proceedings (apart from those relating to
                the rules of procedural fairness) for members of committees that
                assess or evaluate the quality of health services provided by
                others.
              </i>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={{ textAlign: "center", color: "red" }}>
            This data belongs to you and you are not required and cannot be
            compelled to share this data. The AOA encourages surgeons to use
            their data for improvement of clinical practice and awards CPD
            points when surgeons share their data for this purpose with another
            surgeon. However, it is advised that you <strong> only </strong>{" "}
            share this information with another surgeon who is also covered by
            Qualified Privilege.{" "}
          </p>
          <p style={{ textAlign: "center", color: "red" }}>
            The protection under the Qualified Privilege Scheme will cease to
            apply if this data is shared with others outside of the Qualified
            Privilege Scheme.
          </p>
          <p style={{ textAlign: "center" }}>
            More information can be found here:
          </p>
          <a
            style={{ textAlign: "left" }}
            href={
              "https://www1.health.gov.au/internet/main/publishing.nsf/Content/qps-overview"
            }
          >
            https://www1.health.gov.au/internet/main/publishing.nsf/Content/qps-overview{" "}
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default SurgeonQP;

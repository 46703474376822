import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import ButtonBar from "../../common/ButtonBar";

const ConfirmDeleteAnnouncementModal = ({
  isOpen = false,
  announcementId = 0,
  noCallback,
  yesCallback,
}) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Confirm Announcement Delete?</ModalHeader>
      <ModalBody>
        Are you sure you want to delete the announcement with ID:{" "}
        {announcementId}?
        <ButtonBar
          buttons={[
            <Button
              color={"primary"}
              onClick={() => yesCallback(announcementId)}
            >
              Yes
            </Button>,
            <Button
              color={"primary"}
              onClick={() => noCallback(announcementId)}
            >
              No
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

ConfirmDeleteAnnouncementModal.propTypes = {
  isOpen: PropTypes.bool,
  yesCallback: PropTypes.func.isRequired,
  noCallback: PropTypes.func.isRequired,
  announcementId: PropTypes.number,
};

export default ConfirmDeleteAnnouncementModal;

import PropTypes from "prop-types";
import { FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import "./CrfType.css";
import { getSortedChoices } from "../management/crf-type/Utils";
import CrfBaseQuestion from "./CrfBaseQuestion";
import { isEmptyOrNull } from "../../Utils";

const CrfRadioQuestion = ({
  question = null,
  answers = [],
  onChange = () => null,
  disabled = true,
  showValidationErrors = false,
  crfId,
  crfProcId,
  crfType,
  dataQueries,
  index,
  onConfirm,
}) => {
  const isRadioChoiceSelected = (questionId, choiceId) => {
    const currentAnswer = answers.filter(
      (answer) => answer.questionId === questionId,
    );
    if (currentAnswer.length === 1) {
      return currentAnswer[0].choiceId === choiceId;
    } else {
      return false;
    }
  };

  const isAnyRadioChoiceSelected = (question) => {
    if (question != null) {
      let index = 0;
      for (; index < question.choices.length; index++) {
        if (isRadioChoiceSelected(question.id, question.choices[index].id)) {
          return true;
        }
      }
    }
    return false;
  };

  const shouldRenderAsInvalid = (question, showValidationErrors) => {
    return (
      question?.required &&
      showValidationErrors &&
      !isAnyRadioChoiceSelected(question)
    );
  };

  const parseFieldValue = (answer, _question) => {
    let filteredChoices = _question.choices.filter(
      (choice) => choice.id === answer[0].choiceId,
    );
    return !isEmptyOrNull(filteredChoices)
      ? filteredChoices[0].choiceText
      : null;
  };

  const crfComponent = () => {
    return (
      <FormGroup tag="fieldset">
        {getSortedChoices(question).map((choice) => {
          return (
            <Row
              key={`row-choice-'${question.id}-${choice.id}`}
              className={"crf-question g-0"}
            >
              <FormGroup
                check
                key={`form-group-${index}-choices-${question.id}-${choice.id}`}
              >
                <Label
                  check
                  className={
                    shouldRenderAsInvalid(question, showValidationErrors)
                      ? "invalid"
                      : null
                  }
                >
                  <Input
                    type="radio"
                    name={`input-${index}-choices-${question.id}-${choice.id}`}
                    disabled={disabled}
                    onChange={() => onChange(question.id, choice.id)}
                    checked={isRadioChoiceSelected(question.id, choice.id)}
                  />
                  {" " + choice.choiceText}
                </Label>
              </FormGroup>
            </Row>
          );
        })}
        {shouldRenderAsInvalid(question, showValidationErrors) && (
          <FormFeedback style={{ display: "block" }}>
            This field is required
          </FormFeedback>
        )}
      </FormGroup>
    );
  };

  return (
    <CrfBaseQuestion
      question={question}
      onConfirm={onConfirm}
      answers={answers}
      index={index}
      onChange={onChange}
      crfProcId={crfProcId}
      dataQueries={dataQueries}
      crfType={crfType}
      crfId={crfId}
      parseFieldValue={parseFieldValue}
      crfComponent={crfComponent()}
    ></CrfBaseQuestion>
  );
};

CrfRadioQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  showValidationErrors: PropTypes.bool,
  dataQueries: PropTypes.arrayOf(PropTypes.object),
  crfId: PropTypes.number,
  onConfirm: PropTypes.func.isRequired,
  crfType: PropTypes.object,
  crfProcId: PropTypes.number,
};

export default CrfRadioQuestion;

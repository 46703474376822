import { Container } from "reactstrap";
import RegistryProceduresManagement from "../matching/RegistryProceduresManagement";
import { useSelector } from "react-redux";
import { isAdministratorUser, isSahmriUser } from "../../Utils";

const DataManagement = () => {
  const user = useSelector((state) => state.user);
  return (
    <Container className={"data-management"}>
      <h1>Data Management</h1>
      {(isSahmriUser(user) || isAdministratorUser(user)) && (
        <RegistryProceduresManagement />
      )}
    </Container>
  );
};

export default DataManagement;
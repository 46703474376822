import { Fragment } from "react";
import PropTypes from "prop-types";
import { getDateTimeValue } from "./Utils";
import { FormFeedback, Row } from "reactstrap";
import "./CrfType.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import classnames from "classnames";
import CrfBaseQuestion from "./CrfBaseQuestion";

const dateFormatTwo = "DD/MM/YYYY h:mm a";

const CrfDateTimeQuestion = ({
  question = null,
  answers = [],
  index = -1,
  onChange = () => null,
  disabled = true,
  showValidationErrors = false,
  crfId,
  crfProcId,
  crfType,
  onConfirm,
  dataQueries,
}) => {
  const dateIsValid = (value) => {
    return Moment(value).isValid();
  };

  const handleChangeRaw = (qId, value) => {
    if (dateIsValid(value)) {
      onChange(qId, value);
    }
  };

  const parseFieldValue = (answer) => {
    return Moment(answer[0].dateValue).format(dateFormatTwo);
  };

  const crfComponent = () => {
    const answersForThisQuestions = answers.filter(
      (answer) => answer.questionId === question.id,
    );
    return (
      <Fragment>
        <Row
          key={`choice-${question.id}-index-${index}`}
          className={"crf-type g-0"}
        >
          <DatePicker
            selected={
              answersForThisQuestions.length === 1
                ? getDateTimeValue(answersForThisQuestions[0].dateTimeValue)
                : null
            }
            onChange={(value) => {
              if (
                (!question.required && value === null) ||
                dateIsValid(value)
              ) {
                onChange(question.id, value);
              }
            }}
            onChangeRaw={(event) =>
              handleChangeRaw(question.id, event.target.value)
            }
            disabled={disabled}
            timeInputLabel="Time: "
            showTimeSelect={false}
            showTimeInput={true}
            timeIntervals={1}
            timeFormat="h:mm aa"
            timeCaption="Time"
            dateFormat={"dd/MM/yyyy h:mm aa"}
            popperPlacement={"top-start"}
            fixedHeight
            className={classnames("date-field", {
              invalid:
                showValidationErrors &&
                answersForThisQuestions.length === 0 &&
                question.required,
            })}
          />
        </Row>
        <Row
          key={`error-message-${question.id}-index-${index}`}
          className={"crf-type g-0"}
        >
          {showValidationErrors &&
            answersForThisQuestions.length === 0 &&
            question.required && (
              <FormFeedback style={{ display: "block" }}>
                This field is required
              </FormFeedback>
            )}
        </Row>
      </Fragment>
    );
  };

  return (
    <CrfBaseQuestion
      question={question}
      onConfirm={onConfirm}
      answers={answers}
      index={index}
      onChange={onChange}
      crfProcId={crfProcId}
      dataQueries={dataQueries}
      crfType={crfType}
      crfId={crfId}
      parseFieldValue={parseFieldValue}
      crfComponent={crfComponent()}
    ></CrfBaseQuestion>
  );
};

CrfDateTimeQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  dataQueries: PropTypes.arrayOf(PropTypes.object),
  crfId: PropTypes.number,
  onExit: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  crfType: PropTypes.object,
  crfProcId: PropTypes.number,
};

export default CrfDateTimeQuestion;

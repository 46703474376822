import { useDispatch, useSelector } from "react-redux";
import {
  loadStates,
  loadStatesWithHospitals,
} from "../../actions/CommonActions";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import { useEffect } from "react";

const SimpleStateSelector = ({
  clearable = true,
  disabled = false,
  usePadding = true,
  useShortNames = false,
  autoFocus = true,
  tabIndexFirst = true,
  onlyThoseWithHospitals = false,
  value,
  onChange,
  searchable,
}) => {
  const dispatch = useDispatch();
  const states = useSelector((state) => state.states.all);
  const statesWithHospitals = useSelector(
    (state) => state.states.withHospitals,
  );

  useEffect(() => {
    if (onlyThoseWithHospitals) {
      dispatch(loadStatesWithHospitals());
    } else {
      if (states == null || states.length === 0) {
        dispatch(loadStates());
      }
    }
  }, [dispatch]);

  const getSelect = () => {
    return (
      <Select
        options={onlyThoseWithHospitals ? statesWithHospitals : states}
        onChange={onChange}
        isClearable={clearable}
        isSearchable={searchable}
        getOptionLabel={(option) =>
          useShortNames ? option.shortName : option.name
        }
        getOptionValue={(option) => option.id}
        value={value}
        isDisabled={disabled}
        tabIndex={tabIndexFirst ? 1 : 0}
        styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
        autoFocus={autoFocus}
      />
    );
  };

  if (usePadding) {
    return (
      <div className={"simple-state-selector"}>
        <Row>
          <Col xs={1} sm={1} md={1} lg={2} xl={3} />
          <Col>{getSelect()}</Col>
          <Col xs={1} sm={1} md={1} lg={2} xl={3} />
        </Row>
      </div>
    );
  } else {
    return <div className={"simple-state-selector"}>{getSelect()}</div>;
  }
};

SimpleStateSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  usePadding: PropTypes.bool,
  useShortNames: PropTypes.bool,
  autoFocus: PropTypes.bool,
  tabIndexFirst: PropTypes.bool,
  onlyThoseWithHospitals: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SimpleStateSelector;

import { Button, Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import "./EnrolmentConfirmModal.css";
import ButtonBar from "../common/ButtonBar";

const StartSurveyModal = ({ open, confirmCallback, exitCallback }) => {
  return (
    <Modal isOpen={open}>
      <ModalBody>
        <p>
          Thank you for taking the time to complete the Registry project
          requirements.
        </p>
        <p>
          It is very important that we get a clear understanding of how you are
          feeling today therefore we would appreciate if you could complete the
          questions in one sitting. We think they will take you approximately 15
          minutes to answer.
        </p>
        <p>
          If you think you do not have time now to answer all the questions then
          please click{" "}
          <ins>
            <b>cancel</b>
          </ins>{" "}
          and come back at a more convenient time. The Registry will send you a
          reminder in a few days if you have not completed the requirements.
        </p>
        <p>
          If you do have time then please click{" "}
          <ins>
            <b>continue</b>
          </ins>{" "}
          to begin.
        </p>
        <ButtonBar
          buttons={[
            <Button onClick={exitCallback}>Cancel</Button>,
            <Button color="primary" onClick={confirmCallback}>
              Continue
            </Button>,
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

StartSurveyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  exitCallback: PropTypes.func.isRequired,
};

export default StartSurveyModal;

import { Fragment } from "react";
import PropTypes from "prop-types";
import { FormFeedback, Input, Row } from "reactstrap";
import TextareaAutosize from "react-autosize-textarea";
import "./CrfType.css";
import CrfBaseQuestion from "./CrfBaseQuestion";

const CrfFreeTextQuestion = ({
  question = null,
  answers = [],
  index = -1,
  onChange = () => null,
  disabled = true,
  showValidationErrors = false,
  multiLine = false,
  crfId,
  crfProcId,
  crfType,
  dataQueries,
  onConfirm,
}) => {
  const getSingleLineInput = (answersForThisQuestion) => {
    return (
      <Fragment>
        <Input
          type={"text"}
          value={
            answersForThisQuestion.length === 1
              ? answersForThisQuestion[0].textValue
              : ""
          }
          disabled={disabled}
          onChange={(event) => onChange(question.id, event)}
          valid={
            showValidationErrors &&
            answersForThisQuestion.length === 0 &&
            question.required
              ? false
              : null
          }
        />
        {showValidationErrors &&
          (answersForThisQuestion.length === 0 ||
            !answersForThisQuestion[0].textValue ||
            answersForThisQuestion[0].textValue.trim().length === 0) &&
          question.required && (
            <FormFeedback style={{ display: "block" }}>
              This field is required
            </FormFeedback>
          )}
      </Fragment>
    );
  };

  const getMultiLineInput = (answersForThisQuestion) => {
    return (
      <Fragment>
        <TextareaAutosize
          type={"textarea"}
          className={"free-text-multi"}
          value={
            answersForThisQuestion.length === 1
              ? answersForThisQuestion[0].textValue
              : ""
          }
          disabled={disabled}
          onChange={(event) => onChange(question.id, event)}
          valid={
            showValidationErrors &&
            answersForThisQuestion.length === 0 &&
            question.required
              ? false
              : null
          }
        />
        {showValidationErrors &&
          (answersForThisQuestion.length === 0 ||
            !answersForThisQuestion[0].textValue ||
            answersForThisQuestion[0].textValue.trim().length === 0) &&
          question.required && (
            <div className={"invalid-feedback"} style={{ display: "block" }}>
              This field is required
            </div>
          )}
      </Fragment>
    );
  };

  const parseFieldValue = (answer) => {
    return answer[0].textValue;
  };

  const crfComponent = () => {
    const answersForThisQuestion = answers.filter(
      (answer) => answer.questionId === question.id,
    );
    return (
      <Row
        key={`question-row-${question.id}-index-${index}`}
        className={"crf-question g-0"}
      >
        {multiLine
          ? getMultiLineInput(answersForThisQuestion)
          : getSingleLineInput(answersForThisQuestion)}
      </Row>
    );
  };

  return (
    <CrfBaseQuestion
      question={question}
      onConfirm={onConfirm}
      answers={answers}
      index={index}
      onChange={onChange}
      crfProcId={crfProcId}
      dataQueries={dataQueries}
      crfType={crfType}
      crfId={crfId}
      parseFieldValue={parseFieldValue}
      crfComponent={crfComponent()}
    ></CrfBaseQuestion>
  );
};

CrfFreeTextQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  multiLine: PropTypes.bool,
  dataQueries: PropTypes.arrayOf(PropTypes.object),
  crfId: PropTypes.number,
  onConfirm: PropTypes.func.isRequired,
  crfType: PropTypes.object,
  crfProcId: PropTypes.number,
};

export default CrfFreeTextQuestion;

import { Fragment, useEffect, useReducer } from "react";
import { Alert, Badge, Col, Container, Row, Table } from "reactstrap";
import { getTasks } from "../../api/Task";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  finishLoading,
  loadStudies,
  startLoading,
} from "../../actions/CommonActions";
import Pagination from "react-js-pagination";
import DOMPurify from "dompurify";
import Responsive from "react-responsive";
import MyStudiesSelector, { ALL_STUDIES } from "../common/MyStudiesSelector";
import { getPaginationCounter, isAoaUser, isSurgeonUser } from "../../Utils";
import GenericSelector from "../common/GenericSelector";
import ActionButton from "./ActionButton";
import "./Tasks.css";
import { useOnUpdate, usePrevious } from "../CustomHooks";

const itemsCountPerPage = 15;
const pageRangeDisplayed = 5;
const ALL_TYPES = { id: "All Types", name: "All Types" };
const ALL_ASSIGNEES = { id: "All Assignees", name: "All Assignees" };

const Tasks = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const studies = useSelector((state) => state.studies);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      tasks: null,
      taskSurgeons: null,
      taskTypes: null,
      totalItemsCount: null,
      activePage: 1,
      taskTypeFilter: ALL_TYPES,
      taskSurgeonFilter: ALL_ASSIGNEES,
      taskStudyFilter: ALL_STUDIES,
      actionComponent: null,
    },
  );

  const previousState = usePrevious({
    taskTypeFilter: state.taskTypeFilter,
    taskSurgeonFilter: state.taskTypeFilter,
    taskStudyFilter: state.taskStudyFilter,
  });

  useEffect(() => {
    studies.length === 0 && dispatch(loadStudies());
    refreshList(true);
  }, [dispatch]);

  useOnUpdate(() => {
    let taskTypeFilterChanged =
      state.taskTypeFilter !== previousState.taskTypeFilter;
    let taskSurgeonFilterChanged =
      state.taskSurgeonFilter !== previousState.taskSurgeonFilter;
    let taskStudyFilterChanged =
      state.taskStudyFilter !== previousState.taskStudyFilter;

    // Don't check selections when refreshing the list if taskSurgeonFilter has been changed
    // AND one of taskStudyFilter or taskTypeFilter has also been changed
    if (
      taskSurgeonFilterChanged &&
      (taskStudyFilterChanged || taskTypeFilterChanged)
    ) {
      refreshList(false);
    } else {
      refreshList(true);
    }
  }, [
    state.taskStudyFilter,
    state.taskTypeFilter,
    state.activePage,
    state.taskSurgeonFilter,
  ]);

  const refreshList = (_checkSelections) => {
    dispatch(startLoading());
    let payload = {
      studyId:
        state.taskStudyFilter.id === ALL_STUDIES.id
          ? null
          : state.taskStudyFilter.id,
      taskType:
        state.taskTypeFilter.id === ALL_TYPES.id
          ? null
          : state.taskTypeFilter.id,
      surgeonId:
        state.taskSurgeonFilter.id === ALL_ASSIGNEES.id
          ? null
          : state.taskSurgeonFilter.id,
      page: state.activePage - 1,
      size: itemsCountPerPage,
    };
    getTasks(payload)
      .then((response) => {
        setState({
          tasks: response.data.tasks.content,
          activePage: response.data.tasks.number + 1,
          totalItemsCount: response.data.tasks.totalElements,
          taskTypes: response.data.types,
          taskSurgeons: response.data.surgeons,
        });
      })
      .finally(() => {
        dispatch(finishLoading());
        if (_checkSelections) {
          checkSelections();
        }
      });
  };

  const checkSelections = () => {
    let updateRequired = false;
    let newTaskTypeFilter = state.taskTypeFilter;
    let newTaskSurgeonFilter = JSON.parse(
      JSON.stringify(state.taskSurgeonFilter),
    );
    let typeIndex = getTaskTypes().findIndex(
      (item) => item.id === state.taskTypeFilter.id,
    );
    if (typeIndex < 0) {
      newTaskTypeFilter = ALL_TYPES;
      updateRequired = true;
    }
    let surgeonIndex = getTaskSurgeons().findIndex(
      (item) => item.id === state.taskSurgeonFilter.id,
    );
    if (surgeonIndex < 0) {
      newTaskSurgeonFilter = ALL_ASSIGNEES;
      updateRequired = true;
    } else if (
      newTaskSurgeonFilter.count !== getTaskSurgeons()[surgeonIndex].count
    ) {
      newTaskSurgeonFilter = getTaskSurgeons()[surgeonIndex];
      updateRequired = true;
    }
    if (updateRequired) {
      setState({
        taskTypeFilter: newTaskTypeFilter,
        taskSurgeonFilter: newTaskSurgeonFilter,
      });
    }
  };

  const changePage = (pageNumber) => {
    if (pageNumber === state.activePage) {
      return;
    }
    setState({ activePage: pageNumber });
  };

  const resetActionComponent = () => {
    setState({ actionComponent: null });
  };

  const getActionButtons = (actions) => {
    return (
      <Fragment>
        {actions.map((action) => {
          return (
            <ActionButton
              key={`action-button-`}
              action={action}
              actionClick={(component) =>
                setState({ actionComponent: component })
              }
              actionCancelCallback={resetActionComponent}
              actionSuccessCallback={() => {
                resetActionComponent();
                refreshList(true);
              }}
            />
          );
        })}
      </Fragment>
    );
  };

  const getTaskTypes = () => {
    if (state.taskTypes == null) {
      return [];
    } else {
      // Shove our data into the format that react-select needs
      const types = state.taskTypes.map((type) => ({ id: type, name: type }));
      types.unshift(ALL_TYPES);
      return types;
    }
  };

  const getTaskSurgeons = () => {
    if (state.taskSurgeons == null) {
      return [];
    } else {
      let result = state.taskSurgeons.slice();
      result.unshift(ALL_ASSIGNEES);
      return result;
    }
  };

  const taskTypeRenderer = (value) => {
    if (value.id === ALL_TYPES.id) {
      return <b>{value.name}</b>;
    } else {
      return value.name;
    }
  };

  const taskSurgeonRenderer = (value) => {
    if (value.id === ALL_ASSIGNEES.id) {
      return <b>{value.name}</b>;
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{value.name}</span>
          <span>
            <Badge color={"primary"}>{value.count}</Badge>
          </span>
        </div>
      );
    }
  };

  const handleTaskSurgeonChange = (value) => {
    // If we don't do this, selecting a single surgeon will remove the remainder of the entries, making iterating
    // through the list of surgeons with tasks quite annoying.
    setState({ taskSurgeonFilter: value });
  };

  const handleTaskStudyChange = (value) => {
    // If we don't do this, selecting a single study will remove the remainder of the entries, making iterating
    // through the list of surgeons with tasks quite annoying.
    setState({ taskStudyFilter: value });
  };

  const showStudiesSelector = studies.length > 1;
  const showTaskTypesSelector = !!state.taskTypes && state.taskTypes.length > 1;
  const showTaskSurgeonsSelector =
    isAoaUser(user) && !!state.taskSurgeons && state.taskSurgeons.length > 0;

  return (
    <Container>
      <h1>Outstanding Tasks</h1>
      <Row>
        {showStudiesSelector && (
          <Col>
            <MyStudiesSelector
              useAllStudies={true}
              searchable={true}
              options={studies}
              value={state.taskStudyFilter}
              onChange={handleTaskStudyChange}
              autoSelectOnMount={false}
            />
          </Col>
        )}
        {showTaskTypesSelector && (
          <Col>
            <GenericSelector
              options={getTaskTypes()}
              selected={state.taskTypeFilter}
              renderer={taskTypeRenderer}
              changeCallback={(value) => setState({ taskTypeFilter: value })}
            />
          </Col>
        )}
        {showTaskSurgeonsSelector && (
          <Col>
            <GenericSelector
              options={getTaskSurgeons()}
              selected={state.taskSurgeonFilter}
              classNamePrefix={"task-surgeons"}
              renderer={taskSurgeonRenderer}
              changeCallback={handleTaskSurgeonChange}
            />
          </Col>
        )}
      </Row>
      {!!state.tasks && state.tasks.length === 0 && (
        <Alert color={"primary"}>
          <FaCheck className={"me-3"} />
          You have no tasks outstanding
        </Alert>
      )}
      {!!state.tasks && state.tasks.length > 0 && (
        <Fragment>
          <Table bordered striped responsive>
            <thead>
              <tr>
                {!isSurgeonUser(user) && (
                  <Responsive minWidth={375}>
                    <th>Name</th>
                  </Responsive>
                )}
                <Responsive maxWidth={450}>
                  <th>Desc.</th>
                </Responsive>
                <Responsive minWidth={451}>
                  <th>Description</th>
                </Responsive>
                <Responsive maxWidth={450}>
                  <th>Days Ago</th>
                </Responsive>
                <Responsive minWidth={451}>
                  <th>Days Elapsed</th>
                </Responsive>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {state.tasks.map((task) => {
                return (
                  <tr
                    key={`task-row-${task.name}-${task.description}-${task.daysElapsed}`}
                  >
                    {!isSurgeonUser(user) && (
                      <Responsive minWidth={375}>
                        <td>{task.name}</td>
                      </Responsive>
                    )}
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(task.description),
                        }}
                      />
                    </td>
                    <td>{task.daysElapsed}</td>
                    <td>{getActionButtons(task.actions)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {state.totalItemsCount > itemsCountPerPage && (
            <Row>
              <Col className={"text-center"}>
                <Pagination
                  activePage={state.activePage}
                  totalItemsCount={state.totalItemsCount}
                  itemsCountPerPage={itemsCountPerPage}
                  pageRangeDisplayed={pageRangeDisplayed}
                  onChange={changePage}
                  hideDisabled
                  innerClass={"pagination pagination-sm"}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                />
              </Col>
              <Col className={"text-end"}>
                {getPaginationCounter(
                  state.activePage,
                  itemsCountPerPage,
                  state.totalItemsCount,
                )}
              </Col>
            </Row>
          )}
        </Fragment>
      )}
      {state.actionComponent != null && state.actionComponent}
    </Container>
  );
};

export default Tasks;

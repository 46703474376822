import PropTypes from "prop-types";
import { CardText, Col, Container, Fade, Row } from "reactstrap";
import "./VisualAnalogueScale.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { replaceHyphens } from "../../Utils";
import DOMPurify from "dompurify";
import { handleRender } from "./TooltipSlider";

const VisualAnalogueScale = ({
  singleValueLimit = {
    minLabel: "Minimum response value",
    maxLabel: "Maximum response value",
    minValue: 0,
    maxValue: 100,
    useVas: true,
    transitioning: false,
  },
  updateAction = (value) => null,
  value = null,
  transitioning,
  text,
  name,
  instrumentJoint,
}) => {
  const generateMarks = (svl) => {
    const { minValue, maxValue } = svl;
    let range = maxValue - minValue;
    let midpoint = Math.floor(minValue + range / 2);
    let marks = {
      [minValue]: minValue,
      [midpoint]: midpoint,
      [maxValue]: maxValue,
    };

    if (range > 10) {
      let quarter = Math.floor(minValue + range / 4);
      let threeQuarter = Math.floor(minValue + (range / 4) * 3);
      marks[quarter] = quarter;
      marks[threeQuarter] = threeQuarter;
    }

    return marks;
  };

  return (
    <Container className={`vas ${instrumentJoint ? instrumentJoint : ""}`}>
      <Row className="g-0">
        <Col xs={7} className="left">
          <Fade in={!transitioning} style={{ width: "100%" }}>
            <div className="middle">
              <CardText>
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(replaceHyphens(text)),
                  }}
                />
              </CardText>
            </div>
          </Fade>
          <div className="bottom">
            {replaceHyphens(name)}: {value}
          </div>
        </Col>
        <Col xs={5} className="right">
          <Row className="top g-0">{singleValueLimit.maxLabel}</Row>
          <Row className="middle g-0">
            <Slider
              vertical
              min={singleValueLimit.minValue}
              max={singleValueLimit.maxValue}
              defaultValue={value}
              onChange={updateAction}
              marks={generateMarks(singleValueLimit)}
              value={value}
              handleRender={handleRender}
            />
          </Row>
          <Row className="bottom g-0">{singleValueLimit.minLabel}</Row>
        </Col>
      </Row>
    </Container>
  );
};

VisualAnalogueScale.propTypes = {
  singleValueLimit: PropTypes.shape({
    minLabel: PropTypes.string,
    maxLabel: PropTypes.string,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    useVas: PropTypes.bool,
  }),
  updateAction: PropTypes.func,
  value: PropTypes.number,
};

export default VisualAnalogueScale;
